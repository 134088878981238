import CONFIG from "@/config";
import { updateUser, logout } from "@/store/user/actions";
import axios from "axios";
import { showAlert } from "@/store/ui/actions";
import dayjs from "dayjs";

export const validateFields = (data, required, noScroll) => {
  let errors = {};

  for (let i = 0, len = required.length; i < len; i++) {
    if (!data.hasOwnProperty(required[i])) {
      continue;
    }

    const value = data[required[i]];

    if (
      (typeof value === "string" && value.trim() === "") ||
      (typeof value === "number" && value <= 0) ||
      (Array.isArray(value) && value.length === 0) ||
      (typeof value === "object" &&
        value !== null &&
        Object.keys(value).length === 0) ||
      (typeof value !== "string" && !value)
    ) {
      noScroll && window.scrollTo(0, 0);
      errors[required[i]] = `field is required`;
    }
  }

  return errors;
};

export const validate = (data, required, loadError) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      let isValid = true;

      for (var i = 0, len = required.length; i < len; i++) {
        if (!data.hasOwnProperty(required[i])) {
          continue;
        }
        if (!data[required[i]] || data[required[i]]?.length === 0) {
          window.scrollTo(0, 0);
          dispatch(
            loadError({ type: "error", message: `Please enter ${required[i]}` })
          );
          isValid = false;
          break;
        }

        continue;
      }

      isValid && resolve();
    });
  };
};

export const isPasswordEqual = (confirm, password) => {
  let errors = {};
  if (confirm === password) {
    return null;
  }
  errors.confirm = "passwords should be equal";
  return errors;
};

export const serializeErrors = (error) => {
  let errorObject = {};

  if (error && typeof error === "object") {
    error.forEach((error) => {
      errorObject[Object.values(error)[1]] = error[Object.keys(error)[0]];
    });
  }
  return errorObject;
};

export const currency = (value, shouldAbbreviate) => {
  let num = Number(value);
  if (isNaN(num) || num === 0) return "0.00";

  if (shouldAbbreviate && num >= 1e6) {
    return num >= 1e12
      ? `${(num / 1e12).toFixed(2)}t`
      : num >= 1e9
      ? `${(num / 1e9).toFixed(2)}b`
      : `${(num / 1e6).toFixed(2)}m`;
  }

  return `${num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
};

export const hasPermission = (adminPermissions, requiredPermission) => {
  if (adminPermissions.includes("super_admin")) return true;
  if (Array.isArray(requiredPermission)) {
    return requiredPermission.some((permission) =>
      adminPermissions.includes(permission)
    );
  }
  return adminPermissions.includes(requiredPermission);
};

export const checkFileType = (url) => {
  var extension = url.split(".").pop();
  if (["jpg", "JPG", "JPEG", "png", "PNG", "GIF", "svg"].includes(extension)) {
    return "image";
  }
  if (["pdf", "PDF", "docx", "xls"].includes(extension)) {
    return "file";
  }
  return "file";
};

export const checkUnread = (notifications) => {
  const notificationKeys = Object.keys(notifications);
  if (notificationKeys.length > 0) {
    for (let i = 0; i < notificationKeys.length; i++) {
      const findItem = notifications[notificationKeys[i]].find(
        (item) => !item.read
      );
      return findItem;
    }
  }
  return;
};

export const getLineChartLabels = (performace) => {
  return performace
    .map((item) => dayjs(item.month).format("MMM, YYYY"))
    .reverse();
};

export const getLineChartValues = (performance) => {
  return performance.map((item) => item.numberOfClicks).reverse();
};

export const camelCaseToString = (string) => {
  if (string === 0 || string === false) return "No";
  if (string === 1 || string === true) return "Yes";
  if (!string) return "_ _";
  if (typeof string !== "string") return string;
  //convert camelCased string to normal text
  return string.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1");
};

export const isNegative = (value) => {
  if (parseInt(value, 10) > 0) return false;
  if (parseInt(value, 10) === 0) return "neutral";
  return true;
};

export const portfolioPieData = (wallet, termed) => {
  const data = {
    Wallet: wallet,
    "Termed Investment": termed,
  };
  const labels = ["Wallet", "Termed Investment"];
  return {
    datasets: [
      {
        data: [wallet, termed],
        backgroundColor: ["#044472", "#3F9ADA", "#9DC6FB"],
      },
    ],
    labels: labels.map((label) => `${label}: ${formatCurrency(data[label])}`),
  };
};

export const portfolioPieOptions = {
  legend: {
    display: false,
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        let sum = 0;
        const dataArr = data.datasets[0].data;
        sum = dataArr.reduce((a, b) => a + b, 0);
        var label =
          data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] || "";
        const itemPercentage = (label / sum) * 100;
        var text = data.labels[tooltipItem.index];
        return `${text}: (${Number(itemPercentage).toFixed(2)}%)`;
      },
    },
  },
};

// this function handles API requests and dispatches neccesary actions if passed to it
// it accepts an object as parameter, `urlPath` is the only required property of the object
export const apiRequestHandler = ({
  fullUrlPath,
  urlPath,
  method,
  payload,
  headers,
  requestAction,
  successAction,
  errorAction,
  alertSuccessMessage,
}) => {
  return (dispatch, getState) => {
    requestAction && dispatch(requestAction());
    return new Promise((resolve) => {
      axios({
        method: method ?? "get",
        url: fullUrlPath ?? `${CONFIG.BASE_URL}/${urlPath}`,
        data: payload,
        headers: { Authorization: getState().user.token, ...headers },
      })
        .then((response) => {
          dispatch(
            updateUser({
              token: response.headers.authorization,
            })
          );
          if ([200, 201].includes(response?.status)) {
            successAction && dispatch(successAction());
            alertSuccessMessage &&
              dispatch(
                showAlert({ type: "success", message: alertSuccessMessage })
              );
            resolve(response.data);
          }
        })
        .catch(({ response }) => {
          // errorAction && dispatch(errorAction());
          // dispatch(
          //   updateUser({
          //     token: response?.headers.authorization,
          //   })
          // );
          if ([400, 404].includes(response?.status)) {
            return (
              response?.data.message &&
              dispatch(
                showAlert({ type: "error", message: response?.data.message })
              )
            );
          }
          if (response?.status === 403) {
            return dispatch(
              showAlert({
                type: "error",
                message: "Oops! you are unauthorised",
              })
            );
          }
          if (response?.status === 401) {
            dispatch(
              showAlert({ type: "error", message: "Your session has expired" })
            );
            return setTimeout(() => dispatch(logout()), 2000);
          }
          if (response?.status === 422) {
            return dispatch(
              showAlert({ type: "error", message: response?.data.message })
            );
          }
          dispatch(
            showAlert({
              type: "error",
              message: "Oops! something went wrong please try again.",
            })
          );
        });
    });
  };
};

// this function handles API requests to download modules
export const apiDownloadHandler = (fullUrlPath) => {
  return (dispatch, getState) => {
    dispatch({ type: "export/EXPORT_MODULE_REQUEST" });
    return new Promise((resolve) => {
      fetch(fullUrlPath, {
        method: "GET",
        headers: { Authorization: getState().user.token },
      })
        .then((response) => {
          if (response.status === 200) {
            return response.blob().then((blob) => {
              resolve(blob);
              dispatch({ type: "export/EXPORT_MODULE_SUCCESS" });
            });
          }
          throw response;
        })
        .catch((err) => {
          dispatch({ type: "export/EXPORT_MODULE_ERROR" });
          if (err.status === 401) {
            dispatch(
              showAlert({ type: "error", message: "Your session has expired" })
            );
            return setTimeout(() => dispatch(logout()), 2000);
          }
          if (err.status === 403) {
            dispatch(
              showAlert({
                type: "error",
                message: "Oops! you are unauthorised",
              })
            );
          }
          if ([400, 404].includes(err.status)) {
            err.json().then((e) => {
              dispatch(showAlert({ type: "error", message: e.message }));
            });
          }
          dispatch(
            showAlert({
              type: "error",
              message: "Oops! something wrong please try again.",
            })
          );
        });
    });
  };
};

export const formatCurrency = (amount) => {
  return Number(amount)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

export const industiesList = [
  "Agriculture",
  "Commerce",
  "Construction/Real Estate",
  "Consumer Goods",
  "Education",
  "Financial Services",
  "Healthcare",
  "Hospitality",
  "Industrial Goods",
  "Information & Communications Technology (ICT)",
  "Manufacturing",
  "Media",
  "Oil & Gas",
  "Postal",
  "Public Sector",
  "Services",
  "Shipping & Logistics",
  "Tourism",
  "Transportation",
  "Utilities",
];

export const getBarChartOptions = (yLabel, xLabel) => {
  return {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: function (value) {
              if (value % 1 === 0) {
                return value;
              }
            },
          },
          scaleLabel: {
            display: true,
            labelString: yLabel,
            fontStyle: "bold",
          },
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: xLabel,
            fontStyle: "bold",
          },
        },
      ],
    },
    legend: {
      display: false,
    },
  };
};
export const getTransactionChartOptions = (yLabel, xLabel) => {
  return {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: (label) => {
              if (label < 1e6 && label > 1000) {
                return label / 1000 + "k";
              } else return currency(label, true);
            },
          },
          scaleLabel: {
            display: true,
            labelString: yLabel,
            fontStyle: "bold",
          },
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: xLabel,
            fontStyle: "bold",
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    tooltips: {
      callbacks: {
        title: function (labels) {
          return "";
        },
        label: function (labels) {
          if (labels.value < 1e6 && labels.value > 1000) {
            return (labels.value / 1000).toFixed(2) + "k";
          } else return currency(labels.value, true);
        },
      },
    },
  };
};

export const pieOptions = (card, bank) => {
  return {
    legend: {
      display: true,
      position: "right",
      labels: {
        generateLabels: (chart) => {
          const datasets = chart.data.datasets;
          return datasets[0].data.map((data, i) => ({
            text: `${chart.data.labels[i]} ${
              data < 1e6 && data > 1000
                ? data / 1000 + "k"
                : currency(data, true)
            }`,
            fillStyle: datasets[0].backgroundColor[i],
          }));
        },
      },
    },
    tooltips: {
      callbacks: {
        title: function (tooltipItem, chartData) {
          return "";
        },
        label: function (tooltipItem, chartData) {
          if (
            chartData.datasets[tooltipItem.index].data < 1e6 &&
            chartData.data > 1000
          ) {
            return (
              (
                chartData.datasets[tooltipItem.index].data[tooltipItem.index] /
                1000
              ).toFixed(2) + "k"
            );
          } else
            return currency(
              chartData.datasets[tooltipItem.index].data[tooltipItem.index],
              true
            );
        },
      },
    },
  };
};

export const getSuccessChartOptions = (yLabel, xLabel) => {
  return {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: (label) => {
              // if (label < 100) {
              //   return (label / 100).toFixed(2) + "%";
              // } else return label + "%";
              if (Number.isInteger(label) === true) {
                return label + "%";
              } else return label.toFixed(2) + "%";
            },
          },
          scaleLabel: {
            display: true,
            labelString: yLabel,
            fontStyle: "bold",
          },
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: xLabel,
            fontStyle: "bold",
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    tooltips: {
      callbacks: {
        title: function (labels) {
          return "";
        },
        label: function (labels) {
          if (Number.isInteger(labels.value) === true) {
            return labels.value + "%";
          } else return Number(labels.value).toFixed(2) + "%";
        },
      },
    },
  };
};

export const sumArray = (arr) => {
  return arr.reduce((acc, curr) => {
    const num = Number(curr);
    return isNaN(num) ? acc : acc + num;
  }, 0);
};
