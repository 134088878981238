import React, { Component } from "react";
import "./style.scss";

class ImageUploadInput extends Component {
  state = {
    file: "",
    imagePreviewURL: "",
    selectedImage: "",
  };

  componentDidUpdate(prevProps) {
    if (this.props.removePrevie !== prevProps.removePrevie) {
      this.removePreview();
    }
  }

  onSelectImage = (e) => {
    const slectedImage = e.target.files[0];
    this.setState({
      file: slectedImage,
      selectedImage: URL.createObjectURL(slectedImage),
    });
    this.props.handleFile(slectedImage);
    URL.revokeObjectURL(slectedImage);
  };

  showImage = () => {
    const { selectedImage, imagePreviewURL } = this.state;
    if (selectedImage) return <img src={`${selectedImage}`} alt="uploaded" />;
    if (imagePreviewURL)
      return <img src={`${imagePreviewURL}`} alt="uploaded" />;
  };

  removePreview = () => {
    if (this.props.removePrevie) {
      this.setState({
        selectedImage: "",
        imagePreviewURL: "",
        file: "",
      });
    } else {
      this.setState({ imagePreviewURL: this.props.currentImageURL });
    }
  };

  render() {
    const { id, name = "file" } = this.props;
    const { file, imagePreviewURL } = this.state;

    return (
      <div className="icon-upload">
        <div className="icon-upload__preview w-30 h-30">{this.showImage()}</div>
        <input
          type="file"
          className="icon-upload__input"
          accept="image/png, image/jpeg"
          onChange={this.onSelectImage}
          id={id}
        />
        <div className="icon-upload__button-area">
          <label htmlFor={id}>
            {file || imagePreviewURL ? `Change ${name}` : `Upload ${name}`}
          </label>
          <span>{file ? file.name : "Upload JPEG, JPG or PNG"}</span>
        </div>
      </div>
    );
  }
}

export default ImageUploadInput;
