import React, { Component, Fragment } from "react";
import Modal from "@/components/cpModal";

class ConfirmationBox extends Component {
  state = {
    reason: "",
    error: false,
  };

  componentDidUpdate(prevProps) {
    // this is to clear the error and reason whenever the box closes so that when it is opened
    // again you dont see the previous error or reason.
    if (prevProps.open && !this.props.open) {
      this.setState({ error: false, reason: "" });
    }
  }

  onConfirm = () => {
    const { requiresReason, action } = this.props;
    if (!requiresReason) return action();
    if (this.state.reason.trim().length < 6) {
      return this.setState({ error: true });
    }
    this.setState({ error: false });
    action(this.state.reason.trim());
  };

  onReasonChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  close = () => {
    this.props.closeHandler();
  };

  render() {
    const { open, success, title, question, loading, requiresReason } =
      this.props;
    return (
      <Modal open={open} outsideClose={success} close={this.close}>
        <div
          className={`confirmation-modal ${
            !requiresReason || success ? "text-center" : ""
          }`}
        >
          {success ? (
            <Fragment>
              <img
                src={require("@/assets/icons/pace-success.svg").default}
                className="confirmation-modal__icon"
                alt="success icon"
              />
              <p className="text-small text-bold">Successful</p>
            </Fragment>
          ) : (
            <Fragment>
              <h2 className="confirmation-modal__heading">{title}</h2>
              {!requiresReason && (
                <p className="confirmation-modal__question">{question}</p>
              )}
              {requiresReason && (
                <textarea
                  name="reason"
                  value={this.state.reason}
                  onChange={this.onReasonChange}
                  placeholder={`what's your ${title}?`}
                  className={`form-control panel-input panel-text-area`}
                  cols="30"
                  rows="5"
                ></textarea>
              )}
              {this.state.error && (
                <p className="text-red text-small">please enter reason</p>
              )}
            </Fragment>
          )}
          {!success && (
            <div className="dual-button-box">
              <button
                className="cp-button-blue confirmation-modal__button"
                onClick={this.close}
              >
                Cancel
              </button>
              <button
                className="cp-button-blue confirmation-modal__button"
                onClick={this.onConfirm}
              >
                Proceed{" "}
                {loading && (
                  <div className="spinner-border spinner-border-white spinner-border-sm ml-2 mb-2"></div>
                )}
              </button>
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

export default ConfirmationBox;
