import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import dayjs from "dayjs";
import { fetchInvestmentTransactions } from "@/store/Transactions/investmentsAction";
import actionTypes from "@/store/Transactions/actionTypes";
import { getActionLoadingState } from "@/store/selectors";
import { currency } from "@/utils";
import { ReactComponent as BackArrrow } from "@/assets/icons/previous-page-arrow.svg";
import Emptystate from "@/components/Emptystate";
import Pagination from "@/components/Pagination";

class InvestmentTransactions extends Component {
  state = {
    transactions: [],
  };

  componentDidMount() {
    this.getInvestmentTransaction(1, 10);
  }

  getInvestmentTransaction = (page, limit) => {
    const { investmentsId } = this.props.match.params;
    this.props
      .fetchInvestmentTransactions(investmentsId, page, limit)
      .then((transactions) => {
        this.setPageData(transactions);
      });
  };

  setPageData = ({ lastPage, perPage, page, total, data: transactions }) => {
    this.setState({
      transactions,
      lastPage,
      perPage,
      page,
      total,
      searchTerm: "",
      shouldSearch: false,
    });
  };

  renderInvestments = () => {
    return (
      <table className="custum-table">
        <tbody>
          <tr>
            <th>Reference Number</th>
            <th>Cash In </th>
            <th>Cash Out</th>
            <th>Date of Payment</th>
            <th>Payment Method</th>
          </tr>
          {this.state.transactions.map((data) => {
            return (
              <tr
                key={data.id}
                onClick={(e) => this.onRowClick(e, data)}
                className="cursor-pointer"
              >
                <td style={{ whiteSpace: "normal", maxWidth: "24rem" }}>
                  {data.reference}
                </td>
                <td className="font-heading !text-green-500">
                  {["card", "wallet"].includes(data.channel)
                    ? `₦${currency(data.amount)}`
                    : "-"}
                </td>
                <td className="font-heading !text-red-500">
                  {["card", "wallet"].includes(data.channel)
                    ? "-"
                    : `₦${currency(data.amount)}`}
                </td>
                <td>{dayjs(data.paidAt).format("D MMM YYYY")}</td>
                <td>{data.channel}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  render() {
    const { fetching } = this.props;
    const { transactions, lastPage, perPage, page, total } = this.state;

    return (
      <div className="setup-inner">
        <div className="setup-inner__top">
          <BackArrrow
            className="cp-pagination__arrow--active"
            onClick={() => this.props.history.goBack()}
          />
          <span
            className="font-md mx-3 mr-auto font-weight-bold cp-pagination__arrow--active"
            onClick={() => this.props.history.goBack()}
          >
            Back
          </span>
        </div>
        {fetching ? (
          <div className="text-center text-primary">
            <div className="spinner-border" role="status"></div>
          </div>
        ) : (
          <div className="position-relative">
            {!transactions.length ? (
              <Emptystate
                title="No transaction"
                icon={require("@/assets/icons/info.svg").default}
              />
            ) : (
              <>
                <Pagination
                  totalPages={lastPage}
                  page={page}
                  limit={perPage}
                  changePageHandler={(page, limit) =>
                    this.getInvestmentTransaction(page, limit)
                  }
                />
                <div className="table-overflow">
                  <div className="setup-inner__main setup-inner__expand">
                    {this.renderInvestments()}
                  </div>
                  <div className="data-count">
                    Showing
                    <span className="font-weight-bold mx-2">{`${transactions.length} of ${total}`}</span>
                    Transactions
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fetching: getActionLoadingState(state, actionTypes.GET_INVESTMENTS_REQUEST),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchInvestmentTransactions: (id, page, limit) =>
      dispatch(fetchInvestmentTransactions(id, page, limit)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InvestmentTransactions)
);
