import * as actions from "./actionTypes";

export const showAlert= (message) => {
  return {
    type: actions.SHOW_ALERT,
    message
  }
}

const clearAlert = () => {
  return {
    type: actions.ALERT_CLEAR,
  }
}

export const removeAlert = () => {
  return (dispatch) => {
      dispatch(clearAlert())
  }
}

export const clearError = () => {
  return (dispatch) => {
      dispatch({type: actions.CLEAR_ERROR});
  }
}
