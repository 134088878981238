import React from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import htmlToDraft from "html-to-draftjs";
import { EditorState, ContentState } from "draft-js";
import { getActionLoadingState } from "@/store/selectors";
import { getCategories, editBlogPost, getSinglePost, postNewBlogPost } from "@/store/blog/actions";
import actionTypes from "@/store/blog/actionTypes";
import Back from '@/components/Back';
import TextEditor from '@/components/TextEditor';
import RightPanel from '@/components/RightPanel';
import { serializeErrors, validateFields } from '@/utils';
import Modal from '@/components/PopModal';
import './style.scss';

class EditBlog extends React.Component {
  imgRef = React.createRef();
  postRef = React.createRef();

  state = {
    post: '',
    title: '',
    openPanel: false,
    category: '',
    headerImage: null,
    fileName: '',
    errors: null,
    previewModal: false,
    saveLoading: false,
  }

  componentDidMount() {
    const { match: { params }, location: { state }, history } = this.props;
    if(!state) {
      return history.push('/dashboard/setup/blog');
    }
    else {
      this.updateDetails(state?.blogPost)
    }
    this.props.getCategories(1, 200).then(() => {
      this.props.getSinglePost(params.slug)
      .then(data => {
        this.updateDetails(data?.data)
      })
    });
  }

  updateDetails = (data) => {
    this.setState({
      title: data?.title,
      post: data?.content,
      category: data?.category?.id,
    })
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleCategoryChange = (event) => {
    const { value } = event.target
    this.setState({ category: parseInt(value,10) });
  }

  handleFileClick = (event) => {
    event.preventDefault();
    this.imgRef.current.click()
  }

  handleImageSelect = (event) => {
    const { target } = event;
    if (target.files[0]) {
      this.setState({ fileName: target.files[0].name, headerImage: target.files[0] })
    }
  }

  handleSubmitEditedPost = (event) => {
    event.preventDefault();
    const { editBlogPost, location: { state } } = this.props;
    const { title, post, headerImage, category } = this.state;

    const contentBlock = htmlToDraft(post);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks); 
    const editorState = EditorState.createWithContent(contentState);
    const description = editorState.getCurrentContent().getPlainText();

    const formData = new FormData();
    formData.append('title', title)
    formData.append('content', post)
    headerImage && formData.append('image', headerImage)
    formData.append('category', category)
    formData.append('description', description.substring(0, 200))
    formData.append('publish', true)

    editBlogPost(formData, state.blogPost.slug ).then(data => {
      this.setState(prevState => ({ openPanel: !prevState.openPanel }));
      this.props.history.push('/dashboard/setup/blog')
    });
  }

  savePost = (event) => {
    event.preventDefault();
    this.setState({ saveLoading: true });
    const { postNewBlogPost } = this.props;
    const { title, post, headerImage, category } = this.state;

    const formData = new FormData();
    formData.append('title', title)
    formData.append('content', post)
    formData.append('image', headerImage)
    formData.append('category', category)
    formData.append('publish', false)

    postNewBlogPost(formData).then(data => {
      this.props.history.push('/dashboard/setup/blog')
    });
    this.setState({ saveLoading: false });
  }

  closeNewPanel = (event) => {
    event.preventDefault();
    this.setState(prevState => ({ openPanel: !prevState.openPanel }));
  }

  openPanel = () => {
    this.setState({ errors: null });

    const data = this.state;
    const required = [ 'title', 'post' ];
    const errors = validateFields(data, required)

    if (Object.keys(errors).length > 0) {
      return this.setState({ errors });
    }
    this.setState(prevState => ({ openPanel: !prevState.openPanel }));
  }

  previewPost = () => {
    this.setState({ errors: null });

    const data = this.state;
    const required = [ 'title', 'post' ];
    const errors = validateFields(data, required)

    if (Object.keys(errors).length > 0) {
      return this.setState({ errors });
    }

    this.toggleModal();
  }

  toggleModal = () => {
    this.setState(prevState => ({ previewModal: !prevState.previewModal }), () => {
      if(this.state.previewModal && this.postRef) {
        this.postRef.current.innerHTML = `${this.state.post}`
      }
    });
  }

  close = () => {
    this.props.history.goBack()
  }

  render() {
    const { title, openPanel, errors, fileName, previewModal, saveLoading, category } = this.state;
    const { error, loading, categories, post, location} = this.props;
    const errorObject = serializeErrors(error);
    const { state } = location;

    return (
      <div className="edit-blog-page">
        {previewModal &&
          <Modal onClose={this.toggleModal}>
            <div className="blog-preview">
              <h2 className="text-deep-blue text-center">{title}</h2>
              {post?.image &&
                <div className="img-wrapper">
                  <img src={post?.image} className="img-fluid" alt="post-img" />
                </div>
              }
              <div ref={this.postRef} className="mt-3"></div>
            </div>
          </Modal>
        }
        <RightPanel
          open={openPanel}
          onClose={this.closeNewPanel}
        >
          <h4 className="panel-heading">Edit blog</h4>
          <form onSubmit={this.handleSubmitEditedPost}>
            <label className="panel-label" htmlFor="new-investment-type">What category will this be under?</label>
            <select
              className="form-control panel-input mb-0"
              placeholder="choose investment type"
              name="type"
              onChange={this.handleCategoryChange}
              value={category}
            >
              <option>select category</option>
              {categories && categories.map(category => (
                <option value={category.id} key={category.id}>{category.name}</option>
              ))}
            </select>
            <p className="text-error">{errors ? errors.category : (errorObject && errorObject['category'])}</p>

            <label className="panel-label mt-4" htmlFor="new-investment-icon">Header image</label>
            <div
              className="img-input"
            >
              <div className="img-upload d-flex align-items-center">
                <div className="img-container">
                  <img src={post?.image} alt="blog post" className="post-img"/>
                </div>
                <div className="details-container ml-3">
                  <input type="file" className="file" ref={this.imgRef} accept="image/*" onChange={this.handleImageSelect} />
                  <button className="bg-white pl-4 pr-4" onClick={this.handleFileClick}>
                    Upload file
                  </button>
                  <p>{fileName}</p>
                  <p>Upload PDF, JPEG, JPG or PNG</p>
                </div>
              </div>
              <p className="text-error">{errors ? errors.headerImage : (errorObject && errorObject['image'])}</p>
            </div>
            <p className="text-error mt-0">{errors ? errors.title : (errorObject && errorObject['title'])}</p>

            <div className="row">
              <div className="col-md-6">
                <button className="btn btn-white btn-sm font-md w-100" onClick={this.closeNewPanel}>
                  Cancel
              </button>
              </div>
              <div className="col-md-6">
                <button className="btn btn-primary btn-sm font-md w-100 d-flex align-items-center justify-content-center" type="submit">
                  Request Approval
                  {loading &&
                    <div className="spinner-border spinner-border-white spinner-border-sm ml-2"></div>
                  }
                </button>
              </div>
            </div>

          </form>
        </RightPanel>

        <div className="top-bar d-flex justify-content-between align-items-center mb-4">
          <Back />
          <div className="d-flex">
            <button className="btn btn-primary btn-sm mr-3" onClick={this.openPanel}>
              Publish
            </button>
            <button className="btn btn-white btn-sm mr-3" onClick={this.savePost}>
              Save
              {saveLoading &&
                <div className="spinner-border spinner-border-primary spinner-border-sm ml-2"></div>
              }
            </button>
            <button className="btn btn-white btn-sm mr-3" onClick={this.previewPost}>
              Preview
            </button>
            <button className="btn btn-white btn-sm" onClick={this.close}>
              Close
            </button>
          </div>
        </div>
        <div className="blog-title">
          <input
            value={title}
            name="title"
            onChange={this.handleChange}
            className={`blog-title mt-2 mb-3 p-4 ${(errors ? errors.title : (errorObject && errorObject['title'])) && 'input-error'}`}
            placeholder="Enter topic"
          />
          <p className="text-error mt-0">{errors ? errors.title : (errorObject && errorObject['title'])}</p>
        </div>
        <div>
          <TextEditor
            onChange={post => this.setState({ post })}
            error={errors ? errors.post : (errorObject && errorObject['content'])}
            defaultValue={state?.blogPost?.content}
          />
          <p className="text-error mt-0">{errors ? errors.post : (errorObject && errorObject['content'])}</p>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { app: { blog: { error, data, post, categories } } } = state;
  return {
    loading: getActionLoadingState(state, actionTypes.EDIT_BLOG_POST_REQUEST),
    error,
    data,
    post,
    categories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCategories: (page, limit) => dispatch(getCategories(page, limit)),
    editBlogPost: (payload, slug) => dispatch(editBlogPost(payload, slug)),
    postNewBlogPost: (payload) => dispatch(postNewBlogPost(payload)),
    getSinglePost: (slug) => dispatch(getSinglePost(slug)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditBlog));
