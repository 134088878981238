import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import dayjs from "dayjs";
// import CPLogo from '@/assets/images/cp-logo-white.png'
import CPLogo from "@/assets/images/cp_logo_colored.png";
import {
  HomeIcon,
  ChartBarIcon,
  DocumentReportIcon,
  CurrencyDollarIcon,
  UsersIcon,
  CogIcon,
  SupportIcon,
  ChartPieIcon,
  UserGroupIcon,
  ViewGridIcon,
  AdjustmentsIcon,
  QuestionMarkCircleIcon,
  PresentationChartBarIcon,
  GiftIcon,
} from "@heroicons/react/outline";
import "./style.scss";

class DashboardMenu extends Component {
  state = {
    currentTime: "",
  };

  componentDidMount() {
    this.setTime();
  }

  setTime = () => {
    this.setState({
      currentTime: dayjs().format("D MMM YYYY hh:mm A"),
    });
    setTimeout(this.setTime, 1000);
  };

  menus = [
    {
      name: "Dashboard",
      icon: ViewGridIcon,
      path: `/dashboard/home`,
    },
    {
      name: "Analytics",
      icon: PresentationChartBarIcon,
      path: `/dashboard/analytics`,
    },
    {
      name: "Reports",
      icon: ChartPieIcon,
      path: "/dashboard/reports",
    },
    {
      name: "Sales Agency",
      icon: GiftIcon,
      path: "/dashboard/sales-agencies",
    },
    {
      name: "Transactions",
      icon: CurrencyDollarIcon,
      path: "/dashboard/transactions",
    },
    {
      name: "Users",
      icon: UserGroupIcon,
      path: "/dashboard/users",
    },

    {
      name: "Setup",
      icon: CogIcon,
      path: "/dashboard/setup",
    },
    {
      name: "Support",
      icon: QuestionMarkCircleIcon,
      path: "/dashboard/support",
    },
    {
      name: "Settings",
      icon: AdjustmentsIcon,
      path: "/dashboard/settings",
    },
  ];

  renderMenus = () =>
    this.menus.map((menu) => {
      let Icon = menu.icon;
      return (
        <NavLink
          to={{ pathname: menu.path, state: { pageTitle: menu.name } }}
          key={menu.name}
          activeClassName="main-menu__menu-item--active"
        >
          <div className="main-menu__menu-item">
            <Icon className="stroke-[1.3] main-menu__menu-icon size-[22px]" />
            <span className="main-menu__menu-name">{menu.name}</span>
          </div>
        </NavLink>
      );
    });

  render() {
    return (
      <div className="main-menu">
        <span className="main-menu__logo">
          <div className="flex justify-center items-center !mb-[20px]">
            <img
              src={CPLogo}
              alt="logo"
              className="cp-logo cursor-pointer"
              onClick={() => this.props.history.push("/dashboard")}
            />{" "}
            <span className="text-2xl pl-4 font-light !tracking-[3.2px] -top-3 !text-white">
              BACKOFFICE
            </span>
          </div>
        </span>
        {this.renderMenus()}
        <div className="main-menu__time-heading">
          <p className="mb-3 !capitalize !font-light">Current system time</p>
          <p className="font-light font-heading tracking-wider">
            {this.state.currentTime}
          </p>
        </div>
      </div>
    );
  }
}

export default withRouter(DashboardMenu);
