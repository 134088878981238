import React, { Component } from "react";
import { connect } from "react-redux";
import dayjs from "dayjs";
import { Line } from "react-chartjs-2";
import { fetchUserGrowthAnalytics } from "@/store/analytics/usersAnalyticsAction";
import AnalyticsDateInput from "@/components/AnalyticsDateInput";

class UserGrowth extends Component {
  state = {
    growthData: null,
  };

  componentDidMount() {
    this.fetchGrowthData();
  }

  formatDate = (date) => {
    return dayjs(date).format("YYYY-MM-DD");
  };

  fetchGrowthData = () => {
    const { dates } = this.props;
    if (!dates[1]) return;
    this.props
      .fetchUserGrowthAnalytics(
        this.formatDate(dates[0]),
        this.formatDate(dates[1])
      )
      .then((growthData) => {
        this.setState({ growthData: growthData.data.userGrowth });
      });
  };

  componentDidUpdate(prevProps) {
    if (this.props.dates !== prevProps.dates) {
      this.fetchGrowthData();
    }
  }

  colors = {
    registered: "#9DC6FB",
    unverified: "#C8EA73",
    "pending activation": "#F4A28C",
    activated: "#A0A2EC",
    active: "#FFCEBF",
    conversion: "#F48CEA",
  };

  formatDataSet = (data) => {
    const keys = Object.keys(data);
    return keys.map((key) => {
      return {
        ...data[key],
        borderColor: this.colors[key],
        lineTension: 0,
        fill: false,
      };
    });
  };

  lineData = () => ({
    labels: this.state.growthData?.labels || [],
    datasets: this.formatDataSet(this.state.growthData?.data || {}),
  });

  lineOptions = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: function (value) {
              if (value % 1 === 0) {
                return value;
              }
            },
          },
        },
      ],
    },
    legend: {
      labels: { boxWidth: 15, boxHeight: 15, padding: 25 },
    },
  };

  render() {
    const { growthData } = this.state;
    const { dates, handleDateChange } = this.props;
    return (
      <div>
        <div className="setup-inner__main p-5 analytics-graph">
          <div className="text-center">
            <h5 className="text-medium">User growth by status</h5>
            <AnalyticsDateInput
              isRange={true}
              value={dates[0]}
              startDate={dates[0]}
              endDate={dates[1]}
              handleChange={(date) => handleDateChange(date)}
            />
          </div>
          {!growthData ? (
            <div className="text-center text-primary">
              <div className="spinner-border" role="status"></div>
            </div>
          ) : (
            <Line data={this.lineData()} options={this.lineOptions} />
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserGrowthAnalytics: (startDate, endDate) =>
      dispatch(fetchUserGrowthAnalytics(startDate, endDate)),
  };
};

export default connect(null, mapDispatchToProps)(UserGrowth);
