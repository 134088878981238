import axios from 'axios';
import CONFIG from '@/config';
import * as actions from "./actionTypes";
import { updateUser, logout } from "@/store/user/actions";
import { showAlert } from '@/store/ui/actions';
import { apiRequestHandler, apiDownloadHandler } from '@/utils';

const getCustomersRequest = () => {
    return {
        type: actions.GET_CUSTOMERS_REQUEST,
    }
}

const getCustomersError = () => {
    return {
        type: actions.GET_CUSTOMERS_ERROR,
    }
}

const getCustomersSuccess = () => {
    return {
        type: actions.GET_CUSTOMERS_SUCCESS,
    }
}

const CustomersPageRequest = () => {
    return {
        type: actions.CUSTOMERS_PAGE_REQUEST,
    }
}

const CustomersPageSuccess = () => {
    return {
        type: actions.CUSTOMERS_PAGE_SUCCESS,
    }
}

const CustomersPageError = (message) => {
    return {
        type: actions.CUSTOMERS_PAGE_ERROR,
        message,
    }
}

export const fetchCustomers = (page, limit, status, path) => {
    const url = path ?? status;
    const config = {
        urlPath: `admin/users/customers/${url}?page=${page}&limit=${limit}`,
        requestAction: getCustomersRequest,
        successAction: getCustomersSuccess,
        errorAction: getCustomersError,
    }
    return apiRequestHandler(config);
}

export const searchCustomers = (page, limit, status, searchTerm) => {
    const config = {
        urlPath: `admin/users/customers/${status}/search?searchWord=${searchTerm}&page=${page}&limit=${limit}`,
        requestAction: getCustomersRequest,
        successAction: getCustomersSuccess,
        errorAction: getCustomersError,
    }
    return apiRequestHandler(config);
}

export const fetchSingleCustomer = (id, detail = '') => {
    const config = {
        urlPath: `admin/users/${id}/profile/${detail}`,
        requestAction: getCustomersRequest,
        successAction: getCustomersSuccess,
        errorAction: getCustomersError,
    }
    return apiRequestHandler(config);
}

export const updateAccount = (id, shouldActivate, type, message) => {
    const path = type === 'bvn' ? `activation${shouldActivate ? '' : '/reject'}` : `banks/approval`;
    const payload = type === 'bvn' ? { userId: id, message } : {id, reason: message, status: shouldActivate ? 'active' : 'rejected'}
    const config = {
        urlPath: `admin/users/customer/${path}`,
        method: 'patch',
        payload,
        requestAction: CustomersPageRequest,
        successAction: CustomersPageSuccess,
        errorAction: CustomersPageError,
    }
    return apiRequestHandler(config);
}

export const updateMultipleBvnActivation = (ids, shouldActivate, message) => {
    const config = {
        urlPath: `admin/users/customer/multiple/activation${shouldActivate ? '' : '/reject'}`,
        method: 'patch',
        payload: { requests: ids, message },
        requestAction: CustomersPageRequest,
        successAction: CustomersPageSuccess,
        errorAction: CustomersPageError,
    }
    return apiRequestHandler(config);
}

export const editCustomerProfile = (id, payload) => {
    return (dispatch, getState) => {
        dispatch(CustomersPageRequest());
        return new Promise((resolve) => {
            axios.post(`${CONFIG.BASE_URL}/admin/users/${id}/customer/temp/profile/edit`, payload,{
                headers: {
                    "Authorization": getState().user.token,
                },
            })
            .then(response => {
                dispatch(updateUser({
                    token: response.headers.authorization
                }))
                if (response.status === 200) {
                    dispatch(CustomersPageSuccess());
                    resolve();
                    return dispatch(showAlert({ type: 'success', message: 'Approval requested successfully' }))
                }
            })
            .catch(({ response }) => {
                dispatch(CustomersPageError({message: response?.data.error}));
                dispatch(updateUser({
                    token: response?.headers.authorization
                }))
                if([400, 404].includes(response?.status)) {
                    return response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
                }
                if(response?.status === 403) {
                    return dispatch(showAlert({ type: 'error', message: 'Oops! you are unauthorised' }))
                }
                if (response?.status === 401) {
                    dispatch(showAlert({ type: 'error', message: 'Your session has expired' }));
                    return setTimeout(() => dispatch(logout()), 2000);
                }
                dispatch(showAlert({ type: 'error', message: 'Oops! something went wrong please try again.' }))
            })
        })
    }
}

export const approveOrRejectProfile = (userId, profileId, status, rejectionReason) => {
    const statusObj = {
        approve: 'active',
        reject: 'rejected',
    }
    let payload = {
        status: statusObj[status],
    }
    if(rejectionReason) {
        payload.message = rejectionReason;
    }
    const config = {
        urlPath: `admin/users/${userId}/customer/temp/profile/${profileId}/${status}`,
        method: 'patch',
        payload,
        requestAction: CustomersPageRequest,
        successAction: CustomersPageSuccess,
        errorAction: CustomersPageError,
    }
    return apiRequestHandler(config);
}

export const approveOrRejectMultipleProfile = (profiles, status, rejectionReason) => {
    const statusObj = {
        approve: 'active',
        reject: 'rejected',
    }
    let payload = {
        status: statusObj[status],
        profiles,
    }
    if(rejectionReason) {
        payload.message = rejectionReason;
    }
    const config = {
        urlPath: `admin/users/multiple/customer/temp/profiles/${status}`,
        method: 'patch',
        payload,
        requestAction: CustomersPageRequest,
        successAction: CustomersPageSuccess,
        errorAction: CustomersPageError,
    }
    return apiRequestHandler(config);
}

export const fetchTempProfile = (page, limit, status) => {
    const config = {
        urlPath: `admin/users/customer/temp/profiles/${status}?page=${page}&limit=${limit}`,
        requestAction: getCustomersRequest,
        successAction: getCustomersSuccess,
        errorAction: getCustomersError,
    }
    return apiRequestHandler(config);
}

export const fetchCustomerTransactions = (id, page, limit, startDate, endDate) => {
    const path = startDate ? `/filter?startDate=${startDate}&endDate=${endDate}&page=${page}` : `?page=${page}`;
    return (dispatch, getState) => {
        dispatch(getCustomersRequest());
        return new Promise((resolve) => {
            axios.get(`${CONFIG.BASE_URL}/admin/users/${id}/transactions${path}&limit=${limit}`, {
                headers: {
                    "Authorization": getState().user.token,
                },
            })
            .then(response => {
                dispatch(updateUser({
                    token: response.headers.authorization
                }))
                if (response.status === 200) {
                    dispatch(getCustomersSuccess());
                    resolve(response.data.data);
                }
            })
            .catch(({ response }) => {
                dispatch(getCustomersError());
                dispatch(updateUser({
                    token: response?.headers.authorization
                }))
                if([400, 404].includes(response?.status)) {
                    return response.data.message && dispatch(showAlert({ type: 'error', message: response?.data.message }))
                }
                if(response?.status === 403) {
                    return dispatch(showAlert({ type: 'error', message: 'Oops! you are unauthorised' }))
                }
                if (response?.status === 401) {
                    dispatch(showAlert({ type: 'error', message: 'Your session has expired' }));
                    return setTimeout(() => dispatch(logout()), 2000);
                }
                dispatch(showAlert({ type: 'error', message: 'Oops! something went wrong please try again.' }))
            })
        })
    }
}

export const activateUser = (id) => {
    const config = {
        urlPath: `admin/users/${id}/account/status`,
        method: 'patch',
        payload: { status: 'enabled' },
        requestAction: CustomersPageRequest,
        successAction: CustomersPageSuccess,
        errorAction: CustomersPageError,
    }
    return apiRequestHandler(config);
}

export const fetchBvnInfo = (id) => {
    const config = {
        urlPath: `admin/users/customer/bvn-verification/${id}`,
        requestAction: CustomersPageRequest,
        successAction: CustomersPageSuccess,
        errorAction: CustomersPageError,
    }
    return apiRequestHandler(config);
}

export const exportCustomerInfo = (_, format, status, page, limit) => {
    const section = status === 'not-approved' ? 'unverified' : status;
    const fullUrlPath = `${CONFIG.BASE_URL}/admin/users/customers/${section}/download/${format}?page=${page}&limit=${limit}`;
    return apiDownloadHandler(fullUrlPath);
}

export const exportPendingAccountActivation = (_, format, status, page, limit) => {
    const type = status === 'bvn' ? 'pending-bvn-activation' : 'pending-bank-activation'
    const fullUrlPath = `${CONFIG.BASE_URL}/admin/users/customers/account/${type}/download/${format}${page ? `?page=${page}&limit=${limit}` : ''}`;
    return apiDownloadHandler(fullUrlPath);
}

export const exportCustomerTransactionHistory = (_, format, id, page, limit) => {
    const fullUrlPath = `${CONFIG.BASE_URL}/admin/users/${id}/transactions/download/${format}${page ? `?page=${page}&limit=${limit}` : ''}`;
    return apiDownloadHandler(fullUrlPath);
}
