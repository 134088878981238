import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getActionLoadingState } from "@/store/selectors";
import {
  getAllInvestments,
  updateInvestmentStatus,
  updateInvestment,
  removeInvestment,
} from "@/store/investments/actions";
import actionTypes from "@/store/investments/actionTypes";
import Emptystate from "@/components/Emptystate";
import RightPanel from "@/components/RightPanel";
import Dropdown from "@/components/DropdownMenu";
import Alert from "@/components/Alert";
import ConfirmationBox from "@/components/ConfirmationBox";
import Pagination from "@/components/Pagination";
import { validateFields, serializeErrors, hasPermission } from "@/utils";
import "./style.scss";

class TermedInvesments extends React.Component {
  imgRef = React.createRef();
  backgroundRef = React.createRef();
  state = {
    selectedItem: null,
    selectedInvestment: null,
    showConfirmModal: false,
    itemId: "",
    open: false,
    openView: false,
    fileName: "",
    backgroundFileName: "",
    type: "",
    name: "",
    icon: null,
    background: "",
    description: "",
    errors: null,
    imgUrl: null,
    backgroundUrl: null,
  };

  componentDidMount() {
    this.props.getAllInvestments();
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({
        open: false,
        fileName: "",
        type: "",
        name: "",
        icon: null,
        background: "",
        description: "",
        showConfirmModal: false,
      });
    }
    if (this.props.searchTerm !== prevProps.searchTerm) {
      this.props.getAllInvestments(10, 1, this.props.searchTerm);
    }
  }

  handleSelectItem = (itemId) => {
    const { investments } = this.props;
    const foundItem = investments?.data.find((item) => item.id === itemId);

    this.setState({ selectedInvestment: foundItem }, () =>
      this.setState({ openView: true })
    );
  };

  showConfirm = (id) => {
    this.setState({ showConfirmModal: true, itemId: id });
  };

  toggleModal = () => {
    this.setState({ showConfirmModal: !this.state.showConfirmModal });
  };

  handleDelete = () => {
    const { itemId } = this.state;
    this.props.removeInvestment(itemId);
  };

  handleStatusChange = (targetId, newStatus) => {
    this.props.updateInvestmentStatus(targetId, { status: newStatus });
  };

  handleEditItem = (targetId) => {
    const { investments } = this.props;
    const foundItem = investments?.data.find((item) => item.id === targetId);

    this.setState(
      {
        selectedItem: foundItem,
        description: foundItem.description,
        type: foundItem.type,
        name: foundItem.name,
        imgUrl: foundItem.icon,
        backgroundUrl: foundItem.background,
      },
      () => this.setState({ open: true })
    );
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleTypeChange = (event) => {
    const { value } = event.target;
    this.setState({ type: value });
  };

  /**
   * Triggers file input click
   * @param {Event} event Click event.
   * @param {'background' | 'icon'} uploadType Upload type.
   */
  handleFileClick = (event, uploadType) => {
    event.preventDefault();
    uploadType === "background"
      ? this.backgroundRef.current.click()
      : this.imgRef.current.click();
  };

  handleImageSelect = (event) => {
    const { target } = event;
    if (target.files[0]) {
      this.setState({
        fileName: target.files[0].name,
        icon: target.files[0],
        imgUrl: URL.createObjectURL(target.files[0]),
      });
    }
  };
  handleBackgroundImageSelect = (event) => {
    const { target } = event;
    if (target.files[0]) {
      this.setState({
        backgroundFileName: target.files[0].name,
        background: target.files[0],
        backgroundUrl: URL.createObjectURL(target.files[0]),
      });
    }
  };
  handleSubmit = (event) => {
    event.preventDefault();
    const { updateInvestment } = this.props;
    const { type, name, icon, background, description, selectedItem } =
      this.state;

    this.setState({ errors: null });

    const data = this.state;
    const required = ["type", "name"];
    const errors = validateFields(data, required);

    if (Object.keys(errors).length > 0) {
      return this.setState({ errors });
    }
    const formData = new FormData();
    formData.append("type", type);
    icon && formData.append("icon", icon);
    background && formData.append("background", background);
    formData.append("name", name);
    formData.append("description", description);

    updateInvestment(selectedItem.id, formData);
  };

  closeEditPanel = (event) => {
    event.preventDefault();
    this.setState({
      open: false,
      selectedInvestment: null,
    });
  };

  dropdownmenus = (id, status) => {
    const newStatus = status === "active" ? "Disable" : "Enable";
    const canUpdate = hasPermission(this.props.permissions, "update_service");
    const canApprove = hasPermission(this.props.permissions, "approve_service");
    const canDelete = hasPermission(this.props.permissions, "delete_service");
    return [
      canUpdate
        ? { name: "Edit", handler: () => this.handleEditItem(id) }
        : null,
      canApprove
        ? {
            name: newStatus,
            handler: () =>
              this.handleStatusChange(
                id,
                status === "active" ? "disabled" : "active"
              ),
          }
        : null,
      canDelete
        ? { name: "Remove", handler: () => this.showConfirm(id) }
        : null,
    ].filter(Boolean);
  };

  render() {
    const {
      error,
      loading,
      data,
      updateLoading,
      removeLoading,
      investments,
      searchTerm,
    } = this.props;
    const {
      fileName,
      name,
      description,
      errors,
      imgUrl,
      type,
      selectedInvestment,
      backgroundFileName,
      showConfirmModal,
      backgroundUrl,
    } = this.state;
    const errorObject = serializeErrors(error);
    return (
      <div className="termed-investments-page">
        <ConfirmationBox
          open={showConfirmModal}
          closeHandler={() => this.setState({ showConfirmModal: false })}
          title="Remove Investment"
          question="Are you sure you want to remove this investment?"
          action={this.handleDelete}
          loading={removeLoading}
        />

        <RightPanel
          open={this.state.openView}
          onClose={() => this.setState({ openView: false })}
        >
          <h4 className="panel-heading">
            {selectedInvestment && selectedInvestment.name}
          </h4>
          <div className="text-right border-bottom pb-5">
            <img
              src={selectedInvestment && selectedInvestment.icon}
              className="img-fluid"
              alt="user-img"
            />
          </div>
          <div className="d-flex justify-content-between mt-5">
            <p>Investment name</p>
            <p>
              <b>{selectedInvestment && selectedInvestment.name}</b>
            </p>
          </div>
          <div className="d-flex justify-content-between">
            <p>Investment type</p>
            <p>
              <b>{selectedInvestment && selectedInvestment.type}</b>
            </p>
          </div>
          <div className="d-flex justify-content-between">
            <p>Status</p>
            <p
              className={`status--${
                selectedInvestment && selectedInvestment.status
              }`}
            >
              <b>{selectedInvestment && selectedInvestment.status}</b>
            </p>
          </div>
          <div className="d-flex justify-content-between">
            <p>Description</p>
            <p className="text-right">
              <b>{selectedInvestment && selectedInvestment.description}</b>
            </p>
          </div>
          <div className="d-flex justify-content-between mt-4">
            <button
              className="btn btn-sm w-100 mr-2 btn-white color-deep-blue"
              onClick={() => this.setState({ openView: false })}
            >
              Close
            </button>
          </div>
        </RightPanel>
        <RightPanel
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
        >
          <h4 className="panel-heading">Edit {name} Investment</h4>
          <form onSubmit={this.handleSubmit}>
            <label className="panel-label" htmlFor="investment-type">
              Investment type
            </label>
            <select
              className="form-control panel-input mb-0"
              id="investment-type"
              onChange={this.handleTypeChange}
              value={type}
            >
              <option value="">Choose investment type</option>
              <option value="predefined">Pre-Defined</option>
              <option value="collection">Collections</option>
            </select>
            <p className="text-error">
              {errors ? errors.type : errorObject && errorObject["type"]}
            </p>

            <label className="panel-label mt-4" htmlFor="investment-name">
              Investment name
            </label>
            <input
              type="text"
              className="form-control panel-input mb-0"
              placeholder="Enter investment name"
              id="investment-name"
              name="name"
              value={name}
              onChange={this.handleChange}
            />
            <p className="text-error">
              {errors ? errors.name : errorObject && errorObject["name"]}
            </p>
            <label
              className="panel-label mt-4"
              htmlFor="investment-description"
            >
              Description
            </label>
            <textarea
              type="text"
              className="form-control panel-input mb-0"
              placeholder="Describe investment plan"
              id="investment-description"
              rows="5"
              name="description"
              value={description}
              onChange={this.handleChange}
            />
            <p className="text-error">
              {errors
                ? errors.description
                : errorObject && errorObject["description"]}
            </p>
            <label className="panel-label mt-4" htmlFor="investment-icon">
              my Investment icon
            </label>
            <div className="img-input" id="investment-icon">
              <div className="img-upload d-flex align-items-center">
                {imgUrl && (
                  <div className="user-img-container mr-3">
                    <img src={imgUrl} className="img-fluid" alt="user-img" />
                  </div>
                )}
                <div>
                  <input
                    type="file"
                    className="file"
                    ref={this.imgRef}
                    accept="image/png, image/jpeg"
                    onChange={this.handleImageSelect}
                  />
                  <button
                    className="bg-white pl-4 pr-4"
                    onClick={(event) => this.handleFileClick(event, "icon")}
                  >
                    Upload file
                  </button>
                  <p>{fileName}</p>
                  <p>Upload PDF, JPEG, JPG or PNG</p>
                </div>
              </div>
              <p className="text-error">
                {errors ? errors.icon : errorObject && errorObject["icon"]}
              </p>
            </div>
            <label className="panel-label mt-4" htmlFor="investment-background">
              Investment background
            </label>
            <div className="img-input" id="investment-background">
              <div className="img-upload d-flex align-items-center">
                {backgroundUrl && (
                  <div className="user-img-container mr-3">
                    <img
                      src={backgroundUrl}
                      className="img-fluid"
                      alt="user-img"
                    />
                  </div>
                )}
                <div>
                  <input
                    type="file"
                    className="file"
                    ref={this.backgroundRef}
                    accept="image/png, image/jpeg"
                    onChange={this.handleBackgroundImageSelect}
                  />
                  <button
                    className="bg-white pl-4 pr-4"
                    onClick={(event) =>
                      this.handleFileClick(event, "background")
                    }
                  >
                    Upload file
                  </button>
                  <p>{backgroundFileName}</p>
                  <p>Upload PDF, JPEG, JPG or PNG</p>
                </div>
              </div>
              <p className="text-error">
                {errors ? errors.icon : errorObject && errorObject["icon"]}
              </p>
            </div>
            <div className="row">
              <div className="col-md-6">
                <button
                  className="btn btn-white btn-sm font-md w-100"
                  onClick={this.closeEditPanel}
                >
                  Cancel
                </button>
              </div>
              <div className="col-md-6">
                <button
                  className="btn btn-primary btn-sm font-md w-100 d-flex align-items-center justify-content-center"
                  type="submit"
                >
                  Request Approval
                  {updateLoading && (
                    <div className="spinner-border spinner-border-white spinner-border-sm ml-2"></div>
                  )}
                </button>
              </div>
            </div>
          </form>
          {data && <Alert alert={{ type: "success", message: data.message }} />}
        </RightPanel>
        {loading ? (
          <div className="text-center">
            <div className="spinner-border text-primary ml-auto mr-auto"></div>
          </div>
        ) : (
          investments &&
          (investments?.data?.length > 0 ? (
            <>
              <Pagination
                totalPages={investments.lastPage}
                page={investments.page}
                limit={investments.perPage}
                changePageHandler={(page, limit) =>
                  this.props.getAllInvestments(limit, page, searchTerm)
                }
              />
              <div className="setup-inner__main">
                <table className="custum-table">
                  <tbody>
                    <tr>
                      <th>ID</th>
                      <th>Investment Name</th>
                      <th>Description</th>
                      <th>Investment Type</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                    {investments?.data.map((investment) => (
                      <tr key={investment.id}>
                        <td>{investment.id}</td>
                        <td
                          onClick={() => this.handleSelectItem(investment.id)}
                        >
                          <span className="color-dark-blue cursor-pointer">
                            {investment.name}
                          </span>
                        </td>
                        <td
                          style={{
                            whiteSpace: "normal",
                            maxWidth: "25rem",
                          }}
                        >
                          {investment.description}
                        </td>
                        <td>{investment.type}</td>
                        <td>
                          <span
                            className={` status status--${investment.status}`}
                          >
                            {investment.status}
                          </span>
                        </td>
                        <td className="custum-table__ellipsis">
                          <Dropdown
                            menu={this.dropdownmenus(
                              investment.id,
                              investment.status
                            )}
                            width="117px"
                            arrow={true}
                          >
                            <button className="wrapper-button ellipsis">
                              <img
                                src={
                                  require("@/assets/icons/flat-ellipsis.svg")
                                    .default
                                }
                                alt="dropdown"
                              />
                            </button>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan="6" className="text-center">
                        Showing
                        <span className="font-weight-bold">
                          {investments?.data?.length}
                        </span>
                        of
                        <span className="font-weight-bold">
                          {investments?.total}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <Emptystate
              title={`${
                searchTerm ? "No Result Found" : "No Termed Investment"
              }`}
              icon={require("@/assets/icons/info.svg").default}
            />
          ))
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    app: {
      investments: {
        allInvestments: { data, error },
      },
    },
    user: { permissions },
  } = state;
  return {
    loading: getActionLoadingState(
      state,
      actionTypes.FETCH_ALL_INVESTMENTS_REQUEST
    ),
    updateLoading: getActionLoadingState(
      state,
      actionTypes.UPDATE_INVESTMENT_REQUEST
    ),
    removeLoading: getActionLoadingState(
      state,
      actionTypes.REMOVE_INVESTMENT_REQUEST
    ),
    error,
    data: data,
    investments: data?.investments || data?.services,
    permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllInvestments: (limit, page, searchTerm) =>
      dispatch(getAllInvestments(limit, page, searchTerm)),
    updateInvestmentStatus: (id, payload) =>
      dispatch(updateInvestmentStatus(id, payload)),
    updateInvestment: (id, payload) => dispatch(updateInvestment(id, payload)),
    removeInvestment: (id) => dispatch(removeInvestment(id)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TermedInvesments)
);
