import React, { Component } from "react";
import { connect } from "react-redux";
import { downloadModule } from "@/store/app/actions";
import actionTypes from "@/store/app/actionTypes";
import { getActionLoadingState } from "@/store/selectors";
import Dropdown from "@/components/DropdownMenu";
import ExportPrompt from "@/components/ExportPrompt";
import AnalyticsExportPromt from "@/components/AnalyticsExportPromt";
import dayjs from "dayjs";

class ExportDropdown extends Component {
  state = {
    open: false,
    openAnalyticsPrompt: false,
    selectedFormat: "",
  };

  download = (format, onlyCurrentPage = true) => {
    const { module, status, page = 1, limit = 200, range = "all" } = this.props;
    const targetPage = onlyCurrentPage ? page : null;
    const targetLimit = onlyCurrentPage ? limit : null;
    const todaysDate = dayjs().format("D-MMM-YYYY");

    const downloadModule =
      this.props.downloadHandler ?? this.props.defaultDownloadModule;
    downloadModule(module, format, status, targetPage, targetLimit, range).then(
      (raws) => {
        var blob = new Blob([raws]);
        const data = window.URL.createObjectURL(blob);
        const aTag = document.createElement("a");
        aTag.href = data;
        aTag.download = `${status}-${module}_${todaysDate}.${
          format === "excel" ? "xlsx" : format
        }`;
        document.body.appendChild(aTag);
        aTag.click();
        setTimeout(() => {
          document.body.removeChild(aTag);
          window.URL.revokeObjectURL(data);
        }, 100);
      }
    );
  };

  print = () => {
    window.print();
  };

  openPrompt = (selectedFormat) => {
    if (this.props.hasMoreThanOnePage)
      return this.setState({ open: true, selectedFormat });
    if (this.props.analyticsOptions)
      return this.setState({ openAnalyticsPrompt: true, selectedFormat });
    this.download(selectedFormat);
  };

  onPromptClick = (shouldExportOnlyCurrentPage) => {
    this.download(this.state.selectedFormat, shouldExportOnlyCurrentPage);
    this.setState({ open: false });
  };

  handleAnalytics = (type) => {
    this.download(this.state.selectedFormat);
    this.setState({ openAnalyticsPrompt: false });
  };

  render() {
    const {
      loading,
      module,
      excludeCSV,
      includeJpeg,
      analyticsOptions,
      onAnalyticsViewSelect,
    } = this.props;
    const exportOptions = [
      !excludeCSV && { name: "CSV", handler: () => this.openPrompt("csv") },
      includeJpeg && { name: "JPEG", handler: () => this.openPrompt("jpeg") },
      { name: "Excel", handler: () => this.openPrompt("excel") },
      { name: "PDF", handler: () => this.openPrompt("pdf") },
      { name: "Print", handler: () => this.print() },
    ].filter(Boolean);

    return (
      <>
        <Dropdown menu={exportOptions} width="114px" left>
          <div className="setup-nav__dropdown mr-0">
            Export
            {loading ? (
              <div className="spinner-border spinner-border-sm ml-2 ml-auto"></div>
            ) : (
              <img
                src={require("@/assets/icons/dropdown-caret.svg").default}
                alt="arrow down"
                className="ml-auto"
              />
            )}
          </div>
        </Dropdown>
        <ExportPrompt
          open={this.state.open}
          closeHandler={() => this.setState({ open: false })}
          title={module}
          onSelect={this.onPromptClick}
        />
        <AnalyticsExportPromt
          open={this.state.openAnalyticsPrompt}
          closeHandler={() => this.setState({ openAnalyticsPrompt: false })}
          options={analyticsOptions}
          handler={this.handleAnalytics}
          onSelectOption={onAnalyticsViewSelect}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: getActionLoadingState(state, actionTypes.EXPORT_MODULE_REQUEST),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    defaultDownloadModule: (module, format, status, page, limit, range) =>
      dispatch(downloadModule(module, format, status, page, limit, range)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportDropdown);
