import axios from 'axios';
import CONFIG from '@/config';
import * as actions from "./actionTypes";
import { logout, updateUser } from "@/store/user/actions";
import { showAlert } from '@/store/ui/actions';
import { apiRequestHandler } from '@/utils';

const getTerminationRequest = () => {
    return {
        type: actions.GET_TERMINATIONS_REQUEST,
    }
}

const getTerminationError = () => {
    return {
        type: actions.GET_TERMINATIONS_ERROR,
    }
}

const getTerminationSuccess = () => {
    return {
        type: actions.GET_TERMINATIONS_SUCCESS,
    }
}

const terminationPageRequest = () => {
    return {
        type: actions.TERMINATIONS_PAGE_REQUEST,
    }
}

const terminationPageError = (message) => {
    return {
        type: actions.TERMINATIONS_PAGE_ERROR,
        message,
    }
}

const terminationPageSuccess = () => {
    return {
        type: actions.TERMINATIONS_PAGE_SUCCESS,
    }
}

export const fetchTerminations = (page, limit, status) => {
    const path = status === 'all' ? '' : status;
    const config = {
        urlPath: `admin/transactions/liquidations/${path}?page=${page}&limit=${limit}`,
        requestAction: getTerminationRequest,
        successAction: getTerminationSuccess,
        errorAction: getTerminationError,
    }
    return apiRequestHandler(config);
}

export const searchTerminations = (page, limit, status, searchTerm) => {
    const section = status === 'updates' ? 'pending-update' : status;
    const config = {
        urlPath: `admin/transactions/liquidations/${section}/search?searchWord=${searchTerm}&page=${page}&limit=${limit}`,
        requestAction: getTerminationRequest,
        successAction: getTerminationSuccess,
        errorAction: getTerminationError,
    }
    return apiRequestHandler(config);
}

export const reverseTermination = (id, reason) => {
    const payload = {
        "approval_status": "pending",
        "action": "reverse",
        "reason": reason,
    }
    return (dispatch, getState) => {
        dispatch(terminationPageRequest());
        return new Promise((resolve) => {
            axios.patch(`${CONFIG.BASE_URL}/admin/transactions/${id}/liquidation/reverse`, payload, {
                headers: {
                    "Authorization": getState().user.token,
                },
            })
            .then(response => {
                dispatch(updateUser({
                    token: response.headers.authorization
                }))
                if (response.status === 200) {
                    dispatch(terminationPageSuccess());
                    dispatch(showAlert({ type: 'success', message: response.data.message }))
                    resolve();
                }
            })
            .catch(({ response }) => {
                dispatch(terminationPageError());
                dispatch(updateUser({
                    token: response?.headers.authorization
                }))
                if([400, 422].includes(response?.status)) {
                    return dispatch(showAlert({ type: 'error', message: response?.data.message }))
                }
                if(response?.status === 403) {
                    return dispatch(showAlert({ type: 'error', message: 'Oops! you are unauthorised' }))
                }
                if (response?.status === 401) {
                    dispatch(showAlert({ type: 'error', message: 'Your session has expired' }));
                    return setTimeout(() => dispatch(logout()), 2000);
                }
                dispatch(showAlert({ type: 'error', message: 'Oops! something went wrong please try again.' }))
            })
        })
    }
}

export const editTermination = (id, data, approvalStatus) => {
    return (dispatch, getState) => {
        dispatch(terminationPageRequest());
        axios.patch(`${CONFIG.BASE_URL}/admin/transactions/${id}/liquidation/edit/${approvalStatus ?? ''}`, data, {
            headers: {
                "Authorization": getState().user.token,
            },
        })
        .then(response => {
            dispatch(updateUser({
                token: response.headers.authorization
            }))
            if (response.status === 200) {
                dispatch(terminationPageSuccess());
                dispatch(showAlert({ type: 'success', message: 'Successful' }))
                window.location.reload();
            }
        })
        .catch(({ response }) => {
            dispatch(terminationPageError({message: response?.data.error}));
            dispatch(updateUser({
                token: response?.headers.authorization
            }))
            if([400, 422].includes(response?.status)) {
                return dispatch(showAlert({ type: 'error', message: response?.data.message }))
            }
            if(response?.status === 403) {
                return dispatch(showAlert({ type: 'error', message: 'Oops! you are unauthorised' }))
            }
            if (response?.status === 401) {
                dispatch(showAlert({ type: 'error', message: 'Your session has expired' }));
                return setTimeout(() => dispatch(logout()), 2000);
            }
            dispatch(showAlert({ type: 'error', message: 'Oops! something went wrong please try again.' }))
        })
    }
}

export const updateMultipleTermination = (ids, status, message) => {
    const payload = {
        approval_status: status,
        message,
        requests: ids,
    }
    const statusObject = {
        rejected: 'reject',
        active: 'approve',
    }
    const config = {
        urlPath: `admin/transactions/multiple/liquidation/edit/${statusObject[status]}`,
        payload,
        method: 'patch',
        requestAction: terminationPageRequest,
        successAction: terminationPageSuccess,
        errorAction: terminationPageError,
        alertSuccessMessage: 'Successful',
    }
    return apiRequestHandler(config);
}
