import * as actions from './actionTypes';

const ppiDefaultState = {
  data: null,
  error: false,
  loading: false,
  status: null,
  report: null,
  summary: null,
  unique: null,
  clicks: null,
  downloaded: null,
}

export default (state = ppiDefaultState, action) => {
  switch (action.type) {
    case actions.CLEAR:
      return { ...state, loading: false, error: false, data: null };
    case actions.GET_FUNDS_BY_STATUS_SUCCESS:
      return { ...state, loading: false, error: false, status: action.data?.data, data: action.data }
    case actions.GET_FUNDS_BY_STATUS_ERROR:
      return { ...state, loading: false, error: action.error }
    case actions.GET_FUNDS_REPORT_SUCCESS:
      return { ...state, loading: false, error: false, report: action.data?.data, data: action.data }
    case actions.GET_FUNDS_REPORT_ERROR:
      return { ...state, loading: false, error: action.error }
    case actions.GET_REPORT_SUMMARY_SUCCESS:
      return { ...state, loading: false, error: false, summary: action.data?.data, data: action.data }
    case actions.GET_REPORT_SUMMARY_ERROR:
      return { ...state, loading: false, error: action.error }
    case actions.GET_UNIQUE_CUSTOMER_REPORT_SUCCESS:
      return { ...state, loading: false, error: false, unique: action.data?.data, data: action.data }
    case actions.GET_UNIQUE_CUSTOMER_REPORT_ERROR:
      return { ...state, loading: false, error: action.error }
    case actions.GET_CUSTOMER_CLICKS_REPORT_SUCCESS:
      return { ...state, loading: false, error: false, clicks: action.data?.data, data: action.data }
    case actions.GET_CUSTOMER_CLICKS_REPORT_ERROR:
      return { ...state, loading: false, error: action.error }
    case actions.GET_DOWNLOADED_INFO_REPORT_SUCCESS:
      return { ...state, loading: false, error: false, downloaded: action.data?.data, data: action.data }
    case actions.GET_DOWNLOADED_INFO_REPORT_ERROR:
      return { ...state, loading: false, error: action.error }
    default:
      return state;
  }
}