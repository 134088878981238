import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, NavLink, Redirect } from "react-router-dom";
import Users from './Users/index';
import Segments from './Segments';
import Transactions from "./Transactions";
import TermedInvestments from "./TermedInvestments";

class Analytics extends Component {
  navItems = [
    {name: 'Users', path: 'users'},
    {name: 'Segment', path: 'segment'},
    {name: 'Transactions', path: 'transactions'},
    {name: 'Termed Investments', path: 'termed-investments'},
  ];

  render() {
    const { match: { path } } = this.props;
    const firstRoute = this.navItems.length ? this.navItems[0].path : '';

    return(
      <div className="setup-area">
        <ul className="setup-nav">
          {this.navItems.map((item, index) => (
            <li key={index} className="setup-nav__item" >
              <NavLink
                to={{pathname: `/dashboard/analytics/${item.path}`}}
                activeClassName="setup-nav__item--active"
              >
                {item.name}
              </NavLink>
            </li>
          ))}
        </ul>
        <Switch>
          <Route exact path={path}>
            <Redirect to={{pathname: `${path}/${firstRoute}`}} />
          </Route>
          <Route path={`${path}/users`}>
            <Users />
          </Route>
          <Route exact path={`${path}/segment`}>
            <Segments />
          </Route>
          <Route path={`${path}/transactions`}>
            <Transactions />
          </Route>
          <Route path={`${path}/termed-investments`}>
          <TermedInvestments/>
          </Route>
        </Switch>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    permissions: state.user.permissions,
  }
}

export default withRouter(
    connect(mapStateToProps, null)(Analytics)
    );
