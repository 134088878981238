import React from 'react';
import { connect } from "react-redux";
import { withRouter, Route, Switch, NavLink } from "react-router-dom";
import { exportPPi } from "@/store/reports/actions";
import { getFundsBystatus, searchPPi } from "@/store/ppi/actions";
import PrivatePlacements from './PrivatePlacements';
import PublicIssues from './PublicIssues';
import Report from '../Report';
import Details from '../Details';
import SearchBox from '@/components/SearchBox';
import ExportDropdown from '@/components/ExportDropdown';
import './style.scss';

class AllFunds extends React.Component {

  state = {
    search: '',
    searchTerm: '',
    shouldSearch: false,
  }

  handleSubmitSearch = (event) => {
    event.preventDefault();
  }

  getPrivatePlacements = (page, limit) => {
    const payload = {
      category: 'private-placement',
      page, limit,
      action: 'click'
    }
    this.props.getFundsBystatus(payload)
  }

  getPublicIssues = (page, limit) => {
    const payload = {
      category: 'public-issue',
      page, limit,
      action: 'click'
    }
    this.props.getFundsBystatus(payload)
  }

  cancelSearch = () => {
    if(this.props.history.location.pathname.split('/').includes('public-issues')){
      this.getPublicIssues(1, 10)
    }
    this.getPrivatePlacements(1, 20)
  }

  handleSearch = (search) => {
    this.setState({ search, shouldSearch: true });
    if (search === '') {
      return this.cancelSearch();
    }
    if (this.props.history.location.pathname.split('/').includes('public-issues')) {
      return this.props.searchPPi(search, 'public-issue', 1, 10);
    }
    return this.props.searchPPi(search, 'private-placement', 1, 25);
  }

  handleChange = (event) => {
    const { name, value } = event.target
    this.setState({ [name]: value });
  }

  render() {
    const { match: { path }, location, status } = this.props;
    const { searchTerm } = this.state;

    return (
      <div className="all-funds-page">
        {!(location.pathname.split('/').includes('details') ||
          location.pathname.split('/').includes('report')
        )
          &&
          <div className="setup-inner__top d-flex justify-content-between">
            <ul className="setup-inner__nav mr-0">
              <li className="setup-nav__item" >
                <NavLink
                  exact
                  to={{ pathname: `${path}`, state: { pageTitle: 'Reports' } }}
                  activeClassName="setup-nav__item--active"
                >
                  Private Placements
                </NavLink>
              </li>
              <li className="setup-nav__item" >
                <NavLink
                  to={{ pathname: `${path}/public-issues`, state: { pageTitle: 'Reports' } }}
                  activeClassName="setup-nav__item--active"
                >
                  Public Issues
              </NavLink>
              </li>
            </ul>
            <div className="setup-inner__top--right d-flex justify-content-end">
            <SearchBox
              placeholder="Search"
              handleSearch={searchTerm => this.handleSearch(searchTerm)}
              isActiveSearch={Boolean(searchTerm)}
            />
            <ExportDropdown
              module="mutual-funds"
              status={location.pathname.split('/').includes('public-issues') ? 'public-issue' : 'private-placement'}
              page={status?.page}
              limit={status?.perPage}
              downloadHandler={this.props.exportPPi}
              hasMoreThanOnePage={status?.lastPage > 1}
            />
            </div>
          </div>
        }
        <Switch>
          <Route exact path={path}>
            <PrivatePlacements searchTerm={searchTerm} />
          </Route>
          <Route path={`${path}/public-issues`}>
            <PublicIssues searchTerm={searchTerm}/>
          </Route>
          <Route path={`${path}/:id/report`}>
            <Report />
          </Route>
          <Route path={`${path}/:reportId/details`}>
            <Details />
          </Route>
        </Switch>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { app:
    {
      ppi: {
        status
      }
    }
  } = state;
  return {
    status
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      searchPPi: (search, section, page, limit) => dispatch(searchPPi(search, section, page, limit)),
      exportPPi: (module, format, status, page, limit) => dispatch(exportPPi(module, format, status, page, limit)),
      getFundsBystatus: (payload) => dispatch(getFundsBystatus(payload)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AllFunds)
);
