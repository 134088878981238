import CONFIG from '@/config';
import * as actions from "./actionTypes";
import { logout } from "@/store/user/actions";
import { showAlert } from '@/store/ui/actions';
import { apiRequestHandler, apiDownloadHandler } from '@/utils';

const getMutualFundsRequest = () => {
    return {
        type: actions.GET_MUTUAL_FUNDS_REQUEST
    }
}

const getMutualFundsError = () => {
    return {
        type: actions.GET_MUTUAL_FUNDS_ERROR,
    }
}

const getMutualFundsSuccess = () => {
    return {
        type: actions.GET_MUTUAL_FUNDS_SUCCESS
    }
}

export const fetchMutualFunds = (page, limit, type, id) => {
    const path = id ? `${id}/responses/${type}?page=${page}&limit=${limit}` : `summary/${type}/with-subscriber?page=${page}&limit=${limit}&action=click`;
    const config = {
        urlPath: `admin/mutual-funds/${path}`,
        requestAction: getMutualFundsRequest,
        successAction: getMutualFundsSuccess,
        errorAction: getMutualFundsError,
    }
    return apiRequestHandler(config);
}

export const searchMutualFunds = (page, limit, type, searchTerm) => {
    const config = {
        urlPath: `admin/mutual-funds/summary/${type}/with-subscriber/search?searchWord=${searchTerm}&page=${page}&limit=${limit}`,
        requestAction: getMutualFundsRequest,
        successAction: getMutualFundsSuccess,
        errorAction: getMutualFundsError,
    }
    return apiRequestHandler(config);
}

export const downloadForm = (id) => {
    return (dispatch, getState) => {
        return new Promise((resolve) => {
            fetch(`${CONFIG.BASE_URL}/admin/mutual-funds/responses/${id}/download/pdf`, {
                method: 'GET',
                headers: {
                    "Authorization": getState().user.token,
                    'Content-Type': 'application/pdf',
                },
            })
            .then(response => {
                if(response.status === 200) {
                    response.blob()
                    .then((blob) => {
                        resolve(blob)
                    });
                }
                if(response.status === 401) {
                    dispatch(showAlert({ type: 'error', message: 'Your session has expired' }));
                    return setTimeout(() => dispatch(logout()), 2000) ;
                }
                if(response.status === 403) {
                    dispatch(getMutualFundsError());
                    dispatch(showAlert({ type: 'error', message: 'Oops! you are unauthorised' }));
                }
                if(response.status === 400) {
                    response.json()
                    .then(response => {
                        dispatch(getMutualFundsError({type: "danger", message: response.error}));
                        dispatch(showAlert({ type: 'error', message: response.message }));
                    })
                }
            })
            .catch(() => {
                dispatch(getMutualFundsError());
                dispatch(showAlert({ type: 'error', message: 'Oops! something wrong please try again.' }));
            })
        })
    }
}

export const exportMutualFunds = (_, format, status, page, limit) => {
    const fullUrlPath = `${CONFIG.BASE_URL}/admin/mutual-funds/${status}/summary/with-subscriber/download/${format}?action=click${page ? `&page=${page}&limit=${limit}` : ''}`;
    return apiDownloadHandler(fullUrlPath);
}
