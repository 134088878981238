import axios from 'axios';
import CONFIG from '@/config';
import * as actions from "./actionTypes";
import { logout, updateUser } from "@/store/user/actions";
import { showAlert } from '@/store/ui/actions';

//get private and public placement 
const getFundsBystatusRequest = () => {
  return {
    type: actions.GET_FUNDS_BY_STATUS_REQUEST
  }
}

const getFundsBystatusSuccess = (data) => {
  return {
    type: actions.GET_FUNDS_BY_STATUS_SUCCESS,
    data,
  }
}

const getFundsBystatusError = (error) => {
  return {
    type: actions.GET_FUNDS_BY_STATUS_ERROR,
    error,
  }
}

export const getFundsBystatus = (payload) => {
  const { category, page, limit, action } = payload;

  return (dispatch, getState) => {
    dispatch(getFundsBystatusRequest());

    const { token } = getState().user;
    return new Promise((resolve, reject) => {

    axios.get(`${CONFIG.BASE_URL}/admin/mutual-funds/summary/${category}?limit=${limit}&page=${page}&action=${action}`, {
      headers: {
        'Content-Type': "application/json",
        Authorization: token,
      },
    })
      .then(response => {
        dispatch(updateUser({
          token: response.headers.authorization
        }))
        if ([200, 201].includes(response.status)) {
          dispatch(getFundsBystatusSuccess(response.data))
          resolve(response.data)
        }
        return;
      })
      .catch(({ response }) => {
        reject(response?.data)
        response && dispatch(updateUser({
          token: response.headers.authorization
        }))
        if (response && [400, 404, 403].includes(response.status)) {
          response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
          return dispatch(getFundsBystatusError(response.data.error ? response.data.error : response.data.message));
        }
        if (response && [401].includes(response.status)) {
          dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
          return setTimeout(() => dispatch(logout()), 2000)
        }
        if (response && response.status >= 500) {
          return dispatch(getFundsBystatusError('Oops! We did something wrong.'));
        }
        dispatch(getFundsBystatusError('Oops! We did something wrong.'));
      })    
    })
  }
}

export const searchPPi = (search, section, page, limit) => {
  const sections = {
    "public-issue": {
      request: getFundsBystatusRequest,
      error: getFundsBystatusError,
      success: getFundsBystatusSuccess,
    },
    "private-placement": {
      request: getFundsBystatusRequest,
      error: getFundsBystatusError,
      success: getFundsBystatusSuccess,
    },
  }

  return (dispatch, getState) => {
    dispatch(sections[section].request());

    const { token } = getState().user;
    return new Promise((resolve, reject) => {

      axios.get(`${CONFIG.BASE_URL}/admin/mutual-funds/summary/${section}/search?searchWord=${search}&page=${page}&limit=${limit}&action=click`, {
        headers: {
          'Content-Type': "application/json",
          Authorization: token,
        },
      })
        .then(response => {
          dispatch(updateUser({
            token: response.headers.authorization
          }))
          if ([200, 201].includes(response.status)) {
            dispatch(sections[section].success(response.data))
            resolve(response.data)
          }
          return;
        })
        .catch(({ response }) => {
          reject(response?.data)
          response && dispatch(updateUser({
            token: response.headers.authorization
          }))
          if (response && [400, 404, 403].includes(response.status)) {
            response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
            return dispatch(sections[section].error(response.data.error ? response.data.error : response.data.message));
          }
          if (response && [401].includes(response.status)) {
            dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
            return setTimeout(() => dispatch(logout()), 2000)
          }
          if (response && response.status >= 500) {
            return dispatch(sections[section].error('Oops! We did something wrong.'));
          }
          dispatch(sections[section].error('Oops! We did something wrong.'));
        })
    })
  }
}

//GET REPORTS
const getMutualFundsReportRequest = () => {
  return {
    type: actions.GET_FUNDS_REPORT_REQUEST
  }
}

const getMutualFundsReportSuccess = (data) => {
  return {
    type: actions.GET_FUNDS_REPORT_SUCCESS,
    data,
  }
}

const getMutualFundsReportError = (error) => {
  return {
    type: actions.GET_FUNDS_REPORT_ERROR,
    error,
  }
}

export const getMutualFundsReport = (id) => {
  return (dispatch, getState) => {
    dispatch(getMutualFundsReportRequest());

    const { token } = getState().user;
    return new Promise((resolve, reject) => {

    axios.get(`${CONFIG.BASE_URL}/admin/mutual-funds/${id}/report`, {
      headers: {
        'Content-Type': "application/json",
        Authorization: token,
      },
    })
      .then(response => {
        dispatch(updateUser({
          token: response.headers.authorization
        }))
        if ([200, 201].includes(response.status)) {
          dispatch(getMutualFundsReportSuccess(response.data))
          resolve(response.data)
        }
        return;
      })
      .catch(({ response }) => {
        reject(response?.data)
        response && dispatch(updateUser({
          token: response.headers.authorization
        }))
        if (response && [400, 404, 403].includes(response.status)) {
          response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
          return dispatch(getMutualFundsReportError(response.data.error ? response.data.error : response.data.message));
        }
        if (response && [401].includes(response.status)) {
          dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
          return setTimeout(() => dispatch(logout()), 2000)
        }
        if (response && response.status >= 500) {
          return dispatch(getMutualFundsReportError('Oops! We did something wrong.'));
        }
        dispatch(getMutualFundsReportError('Oops! We did something wrong.'));
      })    
    })
  }
}

//GET REPORTS summary
const getSummaryReportRequest = () => {
  return {
    type: actions.GET_REPORT_SUMMARY_REQUEST
  }
}

const getSummaryReportSuccess = (data) => {
  return {
    type: actions.GET_REPORT_SUMMARY_SUCCESS,
    data,
  }
}

const getSummaryReportError = (error) => {
  return {
    type: actions.GET_REPORT_SUMMARY_ERROR,
    error,
  }
}

export const getSummaryReport = (id, page, limit) => {
  return (dispatch, getState) => {
    dispatch(getSummaryReportRequest());

    const { token } = getState().user;
    return new Promise((resolve, reject) => {

    axios.get(`${CONFIG.BASE_URL}/admin/mutual-funds/${id}/report/summary?limit=${limit}&page=${page}`, {
      headers: {
        'Content-Type': "application/json",
        Authorization: token,
      },
    })
      .then(response => {
        dispatch(updateUser({
          token: response.headers.authorization
        }))
        if ([200, 201].includes(response.status)) {
          dispatch(getSummaryReportSuccess(response.data))
          resolve(response.data)
        }
        return;
      })
      .catch(({ response }) => {
        reject(response?.data)
        response && dispatch(updateUser({
          token: response.headers.authorization
        }))
        if (response && [400, 404, 403].includes(response.status)) {
          response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
          return dispatch(getSummaryReportError(response.data.error ? response.data.error : response.data.message));
        }
        if (response && [401].includes(response.status)) {
          dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
          return setTimeout(() => dispatch(logout()), 2000)
        }
        if (response && response.status >= 500) {
          return dispatch(getSummaryReportError('Oops! We did something wrong.'));
        }
        dispatch(getSummaryReportError('Oops! We did something wrong.'));
      })    
    })
  }
}

//GET REPORTS unique customer
const getUniqueCustomerReportRequest = () => {
  return {
    type: actions.GET_UNIQUE_CUSTOMER_REPORT_REQUEST
  }
}

const getUniqueCustomerReportSuccess = (data) => {
  return {
    type: actions.GET_UNIQUE_CUSTOMER_REPORT_SUCCESS,
    data,
  }
}

const getUniqueCustomerReportError = (error) => {
  return {
    type: actions.GET_UNIQUE_CUSTOMER_REPORT_ERROR,
    error,
  }
}

export const getUniqueCustomerReport = (id, page, limit) => {
  return (dispatch, getState) => {
    dispatch(getUniqueCustomerReportRequest());

    const { token } = getState().user;
    return new Promise((resolve, reject) => {

    axios.get(`${CONFIG.BASE_URL}/admin/mutual-funds/${id}/report/unique-customer?limit=${limit}&page=${page}`, {
      headers: {
        'Content-Type': "application/json",
        Authorization: token,
      },
    })
      .then(response => {
        dispatch(updateUser({
          token: response.headers.authorization
        }))
        if ([200, 201].includes(response.status)) {
          dispatch(getUniqueCustomerReportSuccess(response.data))
          resolve(response.data)
        }
        return;
      })
      .catch(({ response }) => {
        reject(response?.data)
        response && dispatch(updateUser({
          token: response.headers.authorization
        }))
        if (response && [400, 404, 403].includes(response.status)) {
          response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
          return dispatch(getUniqueCustomerReportError(response.data.error ? response.data.error : response.data.message));
        }
        if (response && [401].includes(response.status)) {
          dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
          return setTimeout(() => dispatch(logout()), 2000)
        }
        if (response && response.status >= 500) {
          return dispatch(getUniqueCustomerReportError('Oops! We did something wrong.'));
        }
        dispatch(getUniqueCustomerReportError('Oops! We did something wrong.'));
      })    
    })
  }
}

//GET REPORTS summary
const getCustomerClicksReportRequest = () => {
  return {
    type: actions.GET_CUSTOMER_CLICKS_REPORT_REQUEST
  }
}

const getCustomerClicksReportSuccess = (data) => {
  return {
    type: actions.GET_CUSTOMER_CLICKS_REPORT_SUCCESS,
    data,
  }
}

const getCustomerClicksReportError = (error) => {
  return {
    type: actions.GET_CUSTOMER_CLICKS_REPORT_ERROR,
    error,
  }
}

export const getCustomerClicksReport = (id, page, limit) => {
  return (dispatch, getState) => {
    dispatch(getCustomerClicksReportRequest());

    const { token } = getState().user;
    return new Promise((resolve, reject) => {

    axios.get(`${CONFIG.BASE_URL}/admin/mutual-funds/${id}/report/customer-clicks?limit=${limit}&page=${page}`, {
      headers: {
        'Content-Type': "application/json",
        Authorization: token,
      },
    })
      .then(response => {
        dispatch(updateUser({
          token: response.headers.authorization
        }))
        if ([200, 201].includes(response.status)) {
          dispatch(getCustomerClicksReportSuccess(response.data))
          resolve(response.data)
        }
        return;
      })
      .catch(({ response }) => {
        reject(response?.data)
        response && dispatch(updateUser({
          token: response.headers.authorization
        }))
        if (response && [400, 404, 403].includes(response.status)) {
          response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
          return dispatch(getCustomerClicksReportError(response.data.error ? response.data.error : response.data.message));
        }
        if (response && [401].includes(response.status)) {
          dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
          return setTimeout(() => dispatch(logout()), 2000)
        }
        if (response && response.status >= 500) {
          return dispatch(getCustomerClicksReportError('Oops! We did something wrong.'));
        }
        dispatch(getCustomerClicksReportError('Oops! We did something wrong.'));
      })    
    })
  }
}

//GET REPORTS summary
const getDownloadedInfoReportRequest = () => {
  return {
    type: actions.GET_DOWNLOADED_INFO_REPORT_REQUEST
  }
}

const getDownloadedInfoReportSuccess = (data) => {
  return {
    type: actions.GET_DOWNLOADED_INFO_REPORT_SUCCESS,
    data,
  }
}

const getDownloadedInfoReportError = (error) => {
  return {
    type: actions.GET_DOWNLOADED_INFO_REPORT_ERROR,
    error,
  }
}

export const getDownloadedInfoReport = (id, page, limit) => {
  return (dispatch, getState) => {
    dispatch(getDownloadedInfoReportRequest());

    const { token } = getState().user;
    return new Promise((resolve, reject) => {

    axios.get(`${CONFIG.BASE_URL}/admin/mutual-funds/${id}/report/downloaded-info?limit=${limit}&page=${page}`, {
      headers: {
        'Content-Type': "application/json",
        Authorization: token,
      },
    })
      .then(response => {
        dispatch(updateUser({
          token: response.headers.authorization
        }))
        if ([200, 201].includes(response.status)) {
          dispatch(getDownloadedInfoReportSuccess(response.data))
          resolve(response.data)
        }
        return;
      })
      .catch(({ response }) => {
        reject(response?.data)
        response && dispatch(updateUser({
          token: response.headers.authorization
        }))
        if (response && [400, 404, 403].includes(response.status)) {
          response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
          return dispatch(getDownloadedInfoReportError(response.data.error ? response.data.error : response.data.message));
        }
        if (response && [401].includes(response.status)) {
          dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
          return setTimeout(() => dispatch(logout()), 2000)
        }
        if (response && response.status >= 500) {
          return dispatch(getDownloadedInfoReportError('Oops! We did something wrong.'));
        }
        dispatch(getDownloadedInfoReportError('Oops! We did something wrong.'));
      })    
    })
  }
}
