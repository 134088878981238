module.exports = {
  CLEAR_DATA: "blog/CLEAR_DATA",
  CLEAR_ERROR: "blog/CLEAR_ERROR",
  GET_CATEGORIES_REQUEST: "blog/GET_CATEGORIES_REQUEST",
  GET_CATEGORIES_ERROR: "blog/GET_CATEGORIES_ERROR",
  GET_CATEGORIES_SUCCESS: "blog/GET_CATEGORIES_SUCCESS",
  GET_CATEGORIES_BY_STATUS_REQUEST: "blog/GET_CATEGORIES_BY_STATUS_REQUEST",
  GET_CATEGORIES_BY_STATUS_ERROR: "blog/GET_CATEGORIES_BY_STATUS_ERROR",
  GET_CATEGORIES_BY_STATUS_SUCCESS: "blog/GET_CATEGORIES_BY_STATUS_SUCCESS",
  CREATE_NEW_CATEGORY_REQUEST: "blog/CREATE_NEW_CATEGORY_REQUEST",
  CREATE_NEW_CATEGORY_ERROR: "blog/CREATE_NEW_CATEGORY_ERROR",
  CREATE_NEW_CATEGORY_SUCCESS: "blog/CREATE_NEW_CATEGORY_SUCCESS",
  UPDATE_CATEGORY_REQUEST: "blog/UPDATE_CATEGORY_REQUEST",
  UPDATE_CATEGORY_ERROR: "blog/UPDATE_CATEGORY_ERROR",
  UPDATE_CATEGORY_SUCCESS: "blog/UPDATE_CATEGORY_SUCCESS",
  UPDATE_CATEGORY_STATUS_REQUEST: "blog/UPDATE_CATEGORY_STATUS_REQUEST",
  UPDATE_CATEGORY_STATUS_ERROR: "blog/UPDATE_CATEGORY_STATUS_ERROR",
  UPDATE_CATEGORY_STATUS_SUCCESS: "blog/UPDATE_CATEGORY_STATUS_SUCCESS",
  DELETE_CATEGORY_REQUEST: "blog/DELETE_CATEGORY_REQUEST",
  DELETE_CATEGORY_ERROR: "blog/DELETE_CATEGORY_ERROR",
  DELETE_CATEGORY_SUCCESS: "blog/DELETE_CATEGORY_SUCCESS",
  GET_BLOG_POSTS_REQUEST: "blog/GET_BLOG_POSTS_REQUEST",
  GET_BLOG_POSTS_ERROR: "blog/GET_BLOG_POSTS_ERROR",
  GET_BLOG_POSTS_SUCCESS: "blog/GET_BLOG_POSTS_SUCCESS",
  PUBLISH_BLOG_POSTS_REQUEST: "blog/PUBLISH_BLOG_POSTS_REQUEST",
  PUBLISH_BLOG_POSTS_ERROR: "blog/PUBLISH_BLOG_POSTS_ERROR",
  PUBLISH_BLOG_POSTS_SUCCESS: "blog/PUBLISH_BLOG_POSTS_SUCCESS",
  GET_POST_BY_STATUS_REQUEST: "blog/GET_POST_BY_STATUS_REQUEST",
  GET_POST_BY_STATUS_ERROR: "blog/GET_POST_BY_STATUS_ERROR",
  GET_POST_BY_STATUS_SUCCESS: "blog/GET_POST_BY_STATUS_SUCCESS",
  GET_DRAFTS_REQUEST: "blog/GET_DRAFTS_REQUEST",
  GET_DRAFTS_ERROR: "blog/GET_DRAFTS_ERROR",
  GET_DRAFTS_SUCCESS: "blog/GET_DRAFTS_SUCCESS",
  EDIT_BLOG_POST_REQUEST: "blog/EDIT_BLOG_POST_REQUEST",
  EDIT_BLOG_POST_ERROR: "blog/EDIT_BLOG_POST_ERROR",
  EDIT_BLOG_POST_SUCCESS: "blog/EDIT_BLOG_POST_SUCCESS",
  GET_SINGLE_BLOG_POST_REQUEST: "blog/GET_SINGLE_BLOG_POST_REQUEST",
  GET_SINGLE_BLOG_POST_ERROR: "blog/GET_SINGLE_BLOG_POST_ERROR",
  GET_SINGLE_BLOG_POST_SUCCESS: "blog/GET_SINGLE_BLOG_POST_SUCCESS",
  DELETE_BLOG_POST_REQUEST: "blog/DELETE_BLOG_POST_REQUEST",
  DELETE_BLOG_POST_ERROR: "blog/DELETE_BLOG_POST_ERROR",
  DELETE_BLOG_POST_SUCCESS: "blog/DELETE_BLOG_POST_SUCCESS",
  UPDATE_STATUS_REQUEST: "blog/UPDATE_STATUS_REQUEST",
  UPDATE_STATUS_ERROR: "blog/UPDATE_STATUS_ERROR",
  UPDATE_STATUS_SUCCESS: "blog/UPDATE_STATUS_SUCCESS",
}