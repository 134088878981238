import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import {
  fetchCustomers,
  updateAccount,
  searchCustomers,
  exportPendingAccountActivation,
} from "@/store/Customers";
import actionTypes from "@/store/Customers/actionTypes";
import { getActionLoadingState } from "@/store/selectors";
import Emptystate from "@/components/Emptystate";
import Pagination from "@/components/Pagination";
import ConfirmationBox from "@/components/ConfirmationBox";
import ExportDropdown from "@/components/ExportDropdown";
import SearchBox from "@/components/SearchBox";
import MultipleApprovalDropdown from "@/components/MultipleApprovalDropdown";

class BankActivation extends Component {
  state = {
    users: [],
    searchTerm: "",
    shouldSearch: false,
    selectedRowsIds: [],
  };

  componentDidMount() {
    this.getCustomers(1, 10);
  }

  componentDidUpdate(_, prevState) {
    if (
      this.state.searchTerm !== prevState.searchTerm &&
      this.state.shouldSearch
    ) {
      this.searchCustomers(1, 10, this.state.searchTerm, "banks/pending");
    }
  }

  getCustomers = (page, limit) => {
    if (this.state.searchTerm)
      return this.searchCustomers(page, limit, this.state.searchTerm);
    this.props.fetchCustomers(page, limit, "banks/pending").then((data) => {
      this.setPageData(data.data.users || data.data);
    });
  };

  searchCustomers = (page, limit, searchTerm) => {
    if (!searchTerm) return this.getCustomers(1, 10);
    this.props
      .searchCustomers(page, limit, "pending-bankInfo-approval", searchTerm)
      .then((data) => {
        this.setPageData(data.data.users, searchTerm);
      });
  };

  setPageData = (
    { lastPage, perPage, page, total, data: users },
    searchTerm,
    shouldSearch
  ) => {
    this.setState({
      users,
      lastPage,
      perPage,
      page,
      total,
      searchTerm,
      shouldSearch,
    });
  };

  updateAccount = (shouldActivate, message) => {
    const { userId, isMultipleApproval, selectedRowsIds } = this.state;
    let id = isMultipleApproval ? selectedRowsIds : [userId];
    this.props.updateAccount(id, shouldActivate, "bank", message).then(() => {
      let users = this.state.users.filter((data) => !id.includes(data.id));
      this.setState({
        users,
        selectedRowsIds: [],
        confirmationSuccess: true,
      });
    });
  };

  confirmationData = () => {
    const { isMultipleApproval, selectedRowsIds } = this.state;
    const addS = isMultipleApproval && selectedRowsIds.length > 1;
    return {
      approve: {
        title: `Approve account${addS ? "s" : ""}`,
        question: `Are you sure you want to approve ${
          addS ? "these" : "this"
        } account${addS ? "s" : ""}?`,
        action: () => this.updateAccount(true),
      },
      reject: {
        title: "Reason for Rejecting activation",
        requiresReason: true,
        action: (message) => this.updateAccount(false, message),
      },
    };
  };

  setConfirmationContext = (context, userId, isMultipleApproval) => {
    this.setState({
      confirmationContext: context,
      confirmationSuccess: false,
      userId,
      isMultipleApproval,
    });
  };

  onCheckRow = (e, rowId) => {
    const selectedRows = [...this.state.selectedRowsIds];
    if (e.target.checked) {
      selectedRows.push(rowId);
    } else {
      const uncheckedRowIndex = selectedRows.findIndex((id) => id === rowId);
      selectedRows.splice(uncheckedRowIndex, 1);
    }
    this.setState({ selectedRowsIds: selectedRows });
  };

  renderCustomers = () => {
    return (
      <table className="custum-table">
        <tbody>
          <tr>
            <th></th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Account name</th>
            <th>Account number</th>
            <th>Bank name</th>
            <th></th>
          </tr>
          {this.state.users.map((user) => (
            <tr key={user.id}>
              <td className="row-checkbox">
                <input
                  type="checkbox"
                  onChange={(e) => this.onCheckRow(e, user.id)}
                />
              </td>
              <td className="text-capitalize">
                {user.firstName || user.user.firstName}
              </td>
              <td className="text-capitalize">
                {user.lastName || user.user.lastName}
              </td>
              <td>{user.accountName}</td>
              <td>{user.accountNumber}</td>
              <td>{user.bankName}</td>
              <td className="custum-table__button-cell">
                <button
                  className="custum-table__button-blue"
                  onClick={() =>
                    this.setConfirmationContext("approve", user.id)
                  }
                >
                  Approve
                </button>
                <button
                  className="custum-table__button-plain"
                  onClick={() => this.setConfirmationContext("reject", user.id)}
                >
                  Reject
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  render() {
    const {
      match: {
        params: { status },
      },
      fetching,
      pageRequest,
    } = this.props;
    const {
      users,
      lastPage,
      perPage,
      page,
      total,
      confirmationContext,
      confirmationSuccess,
      searchTerm,
    } = this.state;
    const prompt = this.confirmationData()[confirmationContext];
    const path =
      status === "pending" ? "activation/pending" : "manual/activations";
    const showMultiAprrovalDropdown = this.state.selectedRowsIds.length > 0;

    return (
      <div className="setup-inner">
        <ConfirmationBox
          open={confirmationContext}
          closeHandler={() => this.setState({ confirmationContext: "" })}
          success={confirmationSuccess}
          title={prompt?.title}
          question={prompt?.question}
          action={prompt?.action}
          loading={pageRequest}
          requiresReason={prompt?.requiresReason}
        />
        <div className="setup-inner__top">
          <ul className="setup-inner__nav">
            <li className="setup-nav__item">
              <NavLink
                to={{ pathname: "pending" }}
                isActive={() => status === "pending"}
                activeClassName="setup-nav__item--active"
              >
                All Pending
              </NavLink>
            </li>
          </ul>
          <SearchBox
            placeholder="Search accounts"
            handleSearch={(searchTerm) =>
              this.setState({ searchTerm, shouldSearch: true })
            }
            isActiveSearch={Boolean(searchTerm)}
            key={status}
          />
          {users.length > 0 && (
            <ExportDropdown
              module="pending-bank-account-activation"
              status="pending"
              page={page}
              limit={perPage}
              downloadHandler={this.props.exportPending}
              hasMoreThanOnePage={lastPage > 1}
            />
          )}
          {showMultiAprrovalDropdown && (
            <MultipleApprovalDropdown
              approve={() => this.setConfirmationContext("approve", null, true)}
              reject={() => this.setConfirmationContext("reject", null, true)}
            />
          )}
        </div>
        {fetching ? (
          <div className="text-center text-primary">
            <div className="spinner-border" role="status"></div>
          </div>
        ) : (
          <div className="position-relative">
            {!users.length ? (
              <Emptystate
                title={`${searchTerm ? "No Result Found" : "No Account"}`}
                icon={require("@/assets/icons/info.svg").default}
              />
            ) : (
              <>
                <Pagination
                  totalPages={lastPage}
                  page={page}
                  limit={perPage}
                  changePageHandler={(page, limit) =>
                    this.getCustomers(page, limit, path, searchTerm)
                  }
                />
                <div className="table-overflow">
                  <div className="setup-inner__main setup-inner__expand">
                    {this.renderCustomers()}
                  </div>
                  <div className="data-count">
                    Showing
                    <span className="font-weight-bold mx-2">{`${users.length} of ${total}`}</span>
                    Accounts
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fetching: getActionLoadingState(state, actionTypes.GET_CUSTOMERS_REQUEST),
    pageRequest: getActionLoadingState(
      state,
      actionTypes.CUSTOMERS_PAGE_REQUEST
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCustomers: (page, limit, path) =>
      dispatch(fetchCustomers(page, limit, null, path)),
    updateAccount: (userId, shouldActivate, type, message = "") =>
      dispatch(updateAccount(userId, shouldActivate, type, message)),
    searchCustomers: (page, limit, status, searchTerm) =>
      dispatch(searchCustomers(page, limit, status, searchTerm)),
    exportPending: (action, module, format, status, page, limit) =>
      dispatch(
        exportPendingAccountActivation(
          action,
          module,
          format,
          status,
          page,
          limit
        )
      ),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BankActivation)
);
