import axios from 'axios';
import CONFIG from '@/config';
import { showAlert } from '@/store/ui/actions';
import * as actions from './actionTypes';

export const updateUser = (data) => {
    return {
        type: actions.UPDATE_USER,
        data: data
    }
}

export const logout = (isUserInitiatedLogout) => {
    return () => {
        localStorage.clear();
        if(isUserInitiatedLogout) {
            sessionStorage.removeItem('redirectOnLogin');
        } else {
            sessionStorage.setItem('redirectOnLogin', true);
        }
        window.location = "/login";
    }
}

const changeUserPictureSuccess = () => {
    return {
        type: actions.CHANGE_USER_PICTURE_SUCCESS,
    }
}

const changeUserPictureRequest = () => {
    return {
        type: actions.CHANGE_USER_PICTURE_REQUEST
    }
}

const changeUserPictureError = () => {
    return {
        type: actions.CHANGE_USER_PICTURE_ERROR
    }
}

const disableUserRequest = () => {
    return {
        type: actions.DISABLE_USER_REQUEST,
    }
}

const disableUserError = () => {
    return {
        type: actions.DISABLE_USER_ERROR,
    }
}

const disableUserSuccess = () => {
    return {
        type: actions.DISABLE_USER_SUCCESS,
    }
}

const findUserRequest = () => {
    return {
        type: actions.FIND_USER_REQUEST,
    }
}

const findUserError = () => {
    return {
        type: actions.FIND_USER_ERROR,
    }
}

const findUserSuccess = () => {
    return {
        type: actions.FIND_USER_SUCCESS,
    }
}

export const changeUserPicture = (picture) => {
    return (dispatch, getState) => {
        return new Promise((resolve) => {
            const formData = new FormData();
            formData.append('profile_picture', picture);
            dispatch(changeUserPictureRequest());
            axios.patch(`${CONFIG.BASE_URL}/admin/profile/picture`, formData, {
                headers: {
                    "Authorization": getState().user.token,
                },
            })
            .then(response => {
                if (response.status === 200) {
                    dispatch(updateUser({
                        token: response.headers.authorization,
                        picture: response.data.picture,
                    }));
                    dispatch(changeUserPictureSuccess());
                    resolve();
                }
            })
            .catch(({ response }) => {
                dispatch(changeUserPictureError());
                response && dispatch(updateUser({
                    token: response.headers.authorization
                }))
                if([400, 403].includes(response?.status)) {
                    return dispatch(showAlert({ type: 'error', message: response.data?.message }))
                }
                if(response?.status === 401) {
                    dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
                    return setTimeout(() => dispatch(logout()), 2000) 
                }
                dispatch(showAlert({ type: 'error', message: 'Oops! something went wrong please try again.' }))
            })
        })
    }
}

// this method by default disables a user but it can also enable a user if a status of 'enabled' is passed to it
// the name should have been disableOrEnableUser
export const disableUser = (id, reason, status = 'disabled') => {
    return (dispatch, getState) => {
        dispatch(disableUserRequest());
        return new Promise((resolve) => {
            axios.patch(`${CONFIG.BASE_URL}/admin/users/${id}/account/status`, {status, message: reason}, {
                headers: {
                    "Authorization": getState().user.token,
                },
            })
            .then(response => {
                dispatch(updateUser({
                    token: response.headers.authorization
                }))
                if (response.status === 200) {
                    dispatch(disableUserSuccess());
                    resolve();
                }
            })
            .catch(({ response }) => {
                dispatch(disableUserError());
                dispatch(updateUser({
                    token: response?.headers.authorization
                }))
                if([400, 404].includes(response?.status)) {
                    response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
                }
                if(response?.status === 403) {
                    return dispatch(showAlert({ type: 'error', message: 'Oops! you are unauthorised' }))
                }
                if (response?.status === 401) {
                    dispatch(showAlert({ type: 'error', message: 'Your session has expired' }));
                    return setTimeout(() => dispatch(logout()), 2000);
                }
                dispatch(showAlert({ type: 'error', message: 'Oops! something went wrong please try again.' }))
            })
        })
    }
}

export const findUser = (name) => {
    return (dispatch, getState) => {
        dispatch(findUserRequest());
        return new Promise((resolve) => {
            axios.get(`${CONFIG.BASE_URL}/admin/users/customers/full-text?search=${name}`,{
                headers: {
                    "Authorization": getState().user.token,
                },
            })
            .then(response => {
                dispatch(updateUser({
                    token: response.headers.authorization
                }))
                if (response.status === 200) {
                    dispatch(findUserSuccess());
                    resolve(response.data.data.users);
                }
            })
            .catch(({ response }) => {
                dispatch(findUserError());
                dispatch(updateUser({
                    token: response?.headers.authorization
                }))
                if([400, 404].includes(response?.status)) {
                    response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
                }
                if(response?.status === 403) {
                    return dispatch(showAlert({ type: 'error', message: 'Oops! you are unauthorised' }))
                }
                if (response?.status === 401) {
                    dispatch(showAlert({ type: 'error', message: 'Your session has expired' }));
                    return setTimeout(() => dispatch(logout()), 2000);
                }
                dispatch(showAlert({ type: 'error', message: 'Oops! something went wrong please try again.' }))
            })
        })
    }
}
