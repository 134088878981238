import CONFIG from '@/config';
import * as actions from "./actionTypes";
import axios from "axios";
import { showAlert } from '@/store/ui/actions';
import { apiRequestHandler, apiDownloadHandler } from '@/utils';

const appDashboardRequest = () => {
    return {
        type: actions.APP_DASHBOARD_REQUEST,
    }
}

const appDashboardSuccess = () => {
    return {
        type: actions.APP_DASHBOARD_SUCCESS,
    }
}

const appDashboardError = () => {
    return {
        type: actions.APP_DASHBOARD_ERROR,
    }
}

const fetchGraphRequest = () => {
    return {
        type: actions.FETCH_GRAPH_REQUEST,
    }
}

const fetchGraphError = () => {
    return {
        type: actions.FETCH_GRAPH_ERROR,
    }
}

const fetchGraphSuccess = () => {
    return {
        type: actions.FETCH_GRAPH_SUCCESS,
    }
}

export const fetchDashboardInfo = () => {
    const config = {
        urlPath: 'admin/dashboard',
        requestAction: appDashboardRequest,
        successAction: appDashboardSuccess,
        errorAction: appDashboardError,
    }
    return apiRequestHandler(config);
}

export const fetchGraph = (type, range) => {
    const config = {
        urlPath: `admin/dashboard/update-chart?range=${range}&type=${type}`,
        requestAction: fetchGraphRequest,
        successAction: fetchGraphSuccess,
        errorAction: fetchGraphError,
    }
    return apiRequestHandler(config);
}

export const fetchBanks = () => {
    return (dispatch) => {
        return new Promise((resolve) => {
            axios.get(`${CONFIG.BASE_URL}/banks`)
            .then(response => {
                if (response.status === 200) {
                    resolve(response.data.data.banks);
                }
            })
            .catch(() => {
                dispatch(showAlert({ type: 'error', message: 'Oops! error fetching bank list' }))
            })
        })
    }
}

export const downloadModule = (module, format, status, page, limit, range) => {
    const fullUrlPath = `${CONFIG.BASE_URL}/admin/${module}/download/${format}?status=${status}${page ? `&page=${page}&limit=${limit}` : ''}&range=${range}`;
    return apiDownloadHandler(fullUrlPath);
}
