import React, { Component } from "react";

class CpModal extends Component {
    state = {
        close: false,
    }

    outsideClick = () => {
        const { outsideClose, close } = this.props;
        if(outsideClose && close) {
            close();
        }
    }

    render() {
        const { open, children, width, height, customClass = ''} = this.props;
        return(
            <div className={`modal-pane ${customClass} ${!open ? 'modal-pane--hide' : ''}`}>
                <div className="modal-pane__backdrop" onClick={this.outsideClick}></div>
                <div className="modal-pane__content" style={{width: width ?? '', height: height ?? ''}}>
                    {children}
                </div>
            </div>
        )
    }
}

export default CpModal;