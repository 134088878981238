import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getActionLoadingState } from "@/store/selectors";
import actionTypes from "@/store/support/actionTypes";
import {
  getSingleTicket,
  sendMessage,
  updateTicketStatus,
} from "@/store/support/actions";
import Back from "@/components/Back";
import MessageItem from "@/components/MessageItem";
import Modal from "@/components/PopModal";
import Attachment from "@/assets/icons/attachment.svg";
import SendMessage from "@/assets/icons/send-message.svg";
import { validateFields } from "@/utils";
import "./style.scss";

class SingleTicket extends React.Component {
  state = {
    message: "",
    files: [],
    fileNames: [],
    messages: [],
    showConfirmModal: false,
    image: "",
    showImageModal: false,
  };
  imgRef = React.createRef();

  componentDidMount() {
    const { params } = this.props.match;
    this.props.getSingleTicket(params.ticketId).then((data) => {
      this.updateMessages(data.ticket?.ticketResponses);
    });
  }

  updateMessages = (messages) => {
    this.setState({ messages: [...this.state.messages, ...messages] });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });

    if (name === "message" && event.keyCode === 13) {
      return this.submitMessage();
    }
  };

  handleFileClick = (e) => {
    e.preventDefault();
    this.imgRef.current.click();
  };

  handleImageSelect = (event) => {
    const { target } = event;
    const { fileNames, files } = this.state;
    if (target.files[0]) {
      this.setState({
        files: [...files, ...target.files],
        fileNames: [...fileNames, ...target.files],
      });
    }
  };

  submitMessage = (event) => {
    event.preventDefault();
    const { sendMessage, user } = this.props;
    const { params } = this.props.match;
    const { message, files, messages } = this.state;

    const data = this.state;
    const required = ["message"];
    const errors = validateFields(data, required);

    if (Object.keys(errors).length > 0 && !files) {
      return;
    }

    const formData = new FormData();
    for (var x = 0; x < files.length; x++) {
      formData.append(
        files.length > 1 ? "attachment" : "attachment[]",
        files[x]
      );
    }
    formData.append("message", message);

    sendMessage(params.ticketId, formData).then((data) => {
      const messageItem = {
        ...data,
        user: { pictureUrl: user?.picture, isStaff: 1 },
      };
      this.setState({
        messages: [...messages, messageItem],
        message: "",
        files: [],
        fileNames: [],
      });
    });
  };

  handleResolveTicket = () => {
    const { params } = this.props.match;
    this.props
      .updateTicketStatus(params.ticketId, { status: "resolved" })
      .then((data) => {
        this.toggleConfirmationModal();
        this.props.history.push({
          pathname: `/dashboard/support/tickets`,
          state: { pageTitle: "Support" },
        });
      });
  };

  handleShowImage = (image) => {
    this.setState({ image }, () => {
      this.toggleImageModal();
    });
  };

  removeAttachment = (index) => {
    const { files } = this.state;
    const updatedFiles = [...files].filter((v, i) => i !== index);

    this.setState({ files: updatedFiles });
  };

  toggleConfirmationModal = () => {
    this.setState((prevState) => ({
      showConfirmModal: !prevState.showConfirmModal,
    }));
  };

  toggleImageModal = () => {
    this.setState((prevState) => ({
      showImageModal: !prevState.showImageModal,
    }));
  };

  render() {
    const { ticket, loading, confirmLoading, sendLoading } = this.props;
    const {
      message,
      files,
      messages,
      showConfirmModal,
      showImageModal,
      image,
    } = this.state;
    return (
      <div className="single-ticket-page">
        {showImageModal && (
          <Modal onClose={this.toggleImageModal} classes="image-modal">
            <img src={image} alt="attachment" className="img-fluid" />
          </Modal>
        )}
        {showConfirmModal && (
          <Modal onClose={this.toggleModal}>
            <div className="text-center">
              <h3>Confirm</h3>
              <p>Are you sure you want to resolve this ticket</p>
              <div className="d-flex">
                <button
                  className="btn btn-sm btn-white btn-block mt-3 mr-2"
                  onClick={this.toggleConfirmationModal}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-sm btn-primary btn-block mt-3 ml-2 d-flex align-items-center justify-content-center"
                  onClick={this.handleResolveTicket}
                >
                  Confirm
                  {confirmLoading && (
                    <div className="spinner-border spinner-border-white spinner-border-sm ml-2"></div>
                  )}
                </button>
              </div>
            </div>
          </Modal>
        )}
        <div className="d-flex align-items-center justify-content-between">
          <Back />
          {ticket && ticket.status === "open" && (
            <button
              className="btn btn-sm btn-white"
              onClick={this.toggleConfirmationModal}
            >
              Resolve ticket
            </button>
          )}
          {ticket && ticket.status === "resolved" && (
            <p className="status-close p-3 text-x-small">Resolved</p>
          )}
        </div>
        <div className="bg-white">
          <div className="border-bottom p-4 text-center mt-3">
            {loading && (
              <div className="text-center">
                <div className="spinner-border spinner-border-primary text-primary spinner-border-md mr-2"></div>
              </div>
            )}
            <h3 className="text-deep-blue text-medium mb-0 ticket-title">
              {ticket && ticket.title}
            </h3>
          </div>
          <div className="message-container">
            {messages.length > 0 &&
              messages.map((message) => (
                <MessageItem
                  message={message}
                  key={message.id}
                  viewImage={this.handleShowImage}
                />
              ))}
          </div>
          {ticket && ticket.status === "open" && (
            <div className="border-top message-area p-3 mt-2">
              <div className="d-flex w-100">
                <form onSubmit={this.submitMessage}>
                  <textarea
                    placeholder="Send a message..."
                    className="border-0 w-100"
                    value={message}
                    name="message"
                    onChange={this.handleChange}
                    type="text"
                  />
                </form>

                <div
                  className="d-flex align-items-center justify-content-center cursor-pointer attachment-section"
                  onClick={this.handleFileClick}
                >
                  <img src={Attachment} alt="attachment" />
                </div>
                <div
                  className="bg-default d-flex align-items-center justify-content-center send-message cursor-pointer"
                  onClick={this.submitMessage}
                >
                  {!sendLoading && <img src={SendMessage} alt="send" />}
                  {sendLoading && (
                    <div className="spinner-border text-white spinner-border-sm"></div>
                  )}
                </div>
              </div>
              <div className="d-flex">
                {files.length > 0 &&
                  files.map((file, index) => (
                    <div className="file-items" key={index}>
                      <p>{file && file.name}</p>
                      <p
                        className="text-x-small mb-0 remove-item"
                        onClick={() => this.removeAttachment(index)}
                      >
                        remove
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
        <input
          type="file"
          className="file"
          ref={this.imgRef}
          accept="image/png, image/jpeg, application/pdf"
          onChange={this.handleImageSelect}
          multiple
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    app: {
      support: { ticket },
      user,
    },
  } = state;

  return {
    loading: getActionLoadingState(
      state,
      actionTypes.GET_SINGLE_TICKET_REQUEST
    ),
    confirmLoading: getActionLoadingState(
      state,
      actionTypes.UPDATE_TICKET_STATUS_REQUEST
    ),
    sendLoading: getActionLoadingState(state, actionTypes.SEND_MESSAGE_REQUEST),
    ticket,
    user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSingleTicket: (id) => dispatch(getSingleTicket(id)),
    sendMessage: (id, payload) => dispatch(sendMessage(id, payload)),
    updateTicketStatus: (id, payload) =>
      dispatch(updateTicketStatus(id, payload)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SingleTicket)
);
