import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "./style.scss";

export default class MaturitiesAnalyticsDate extends Component {
  state = {
    openPicker: false,
  };

  handleClick = (e) => {
    this.setState({ openPicker: !this.state.openPicker });
  };

  render() {
    const { maxDate, minDate, value, handleChange, isRange } = this.props;

    return (
      <div className="analytic-date-input" onClick={this.handleClick}>
        <img
          src={require("@/assets/icons/calendar.svg").default}
          alt="arrow down"
          className="mr-4"
        />

        <DatePicker
          className={`analytic-date-input__input ${
            isRange ? "analytic-date-input__input--range" : ""
          }`}
          selected={value}
          selectsRange={isRange}
          onChange={handleChange}
          maxDate={maxDate}
          minDate={minDate}
          dateFormat="d MMM yyyy"
          placeholderText="Select date"
        />
      </div>
    );
  }
}
