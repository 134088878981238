import React, { Component } from "react";
import { withRouter, Route, Switch, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { getActionLoadingState } from "@/store/selectors";
import {
  createNewCategory,
  filterBlogPost,
  getCategories,
  getDrafts,
  getPostsBystatus,
  getCategoriesBystatus,
  getActivePosts,
} from "@/store/blog/actions";
import actionTypes from "@/store/blog/actionTypes";
import Blogs from "./Blog";
import Drafts from "./Drafts";
import NewBlog from "./NewBlog";
import EditBlog from "./EditBlog";
import PendingApproval from "./PendingApproval";
import PendingCategories from "./PendingCategories";
import Category from "./Category";
import RightPanel from "@/components/RightPanel";
import SearchBox from "@/components/SearchBox";
import { serializeErrors, validateFields, hasPermission } from "@/utils";
import "./style.scss";

class Blog extends Component {
  state = {
    search: "",
    openPanel: false,
    categoryName: "",
    errors: "",
  };

  handleSubmitSearch = (event) => {
    event.preventDefault();
    const { search } = this.state;
    const urlArr = this.props.history.location.pathname.split("/");

    if (urlArr.includes("category")) {
      return this.props.filterBlogPost(search, "categories", 1, 20, "active");
    }
    if (urlArr.includes("drafts")) {
      return this.props.filterBlogPost(search, "drafts", 1, 20, "active");
    }
    if (urlArr.includes("pending")) {
      return this.props.filterBlogPost(search, "blogs", 1, 20, "pending");
    }
    if (urlArr.includes("pending-categories")) {
      return this.props.filterBlogPost(search, "categories", 1, 20, "pending");
    }
    if (urlArr[urlArr.length - 1] === "blog") {
      return this.props.filterBlogPost(search, "blogs", 1, 20, "active");
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({ [name]: value });
  };

  cancelSearch = () => {
    const urlArr = this.props.history.location.pathname.split("/");
    if (urlArr.includes("category")) {
      return this.props.getCategories(1, 10);
    }
    if (urlArr.includes("drafts")) {
      return this.props.getDrafts(1, 10);
    }
    if (urlArr.includes("pending")) {
      return this.props.getPostsBystatus("pending", 1, 10);
    }
    if (urlArr.includes("pending-categories")) {
      return this.props.getCategoriesBystatus("pending", 1, 10);
    }
    if (urlArr[urlArr.length - 1] === "blog") {
      return this.props.getActivePosts(1, 10);
    }
  };

  handleSearch = (search) => {
    this.setState({ search });
    if (search === "") {
      return this.cancelSearch();
    }
    const urlArr = this.props.history.location.pathname.split("/");

    if (urlArr.includes("category")) {
      return this.props.filterBlogPost(search, "categories", 1, 20, "active");
    }
    if (urlArr.includes("drafts")) {
      return this.props.filterBlogPost(search, "drafts", 1, 20, "active");
    }
    if (urlArr.includes("pending")) {
      return this.props.filterBlogPost(search, "blogs", 1, 20, "pending");
    }
    if (urlArr.includes("pending-categories")) {
      return this.props.filterBlogPost(search, "categories", 1, 20, "pending");
    }
    if (urlArr[urlArr.length - 1] === "blog") {
      return this.props.filterBlogPost(search, "blogs", 1, 20, "active");
    }
  };

  createNewBlog = () => {
    const {
      match: { path },
    } = this.props;

    this.props.history.push({
      pathname: `${path}/create-new`,
      state: { pageTitle: "Setup" },
    });
  };

  handleCreateCategory = (event) => {
    event.preventDefault();
    const { createNewCategory } = this.props;
    const { categoryName } = this.state;

    const data = this.state;
    const required = ["categoryName"];
    const errors = validateFields(data, required);

    if (Object.keys(errors).length > 0) {
      return this.setState({ errors });
    }
    createNewCategory({ category: categoryName }).then((data) => {
      this.setState((prevState) => ({ openPanel: !prevState.openPanel }));
      this.setState({ categoryName: "" });
    });
  };

  closeNewCategoryPanel = (event) => {
    event.preventDefault();
    this.setState((prevState) => ({ openPanel: !prevState.openPanel }));
  };

  render() {
    const {
      match: { path },
      location,
      loading,
      error,
      permissions,
    } = this.props;
    const { search, openPanel, categoryName, errors } = this.state;
    const errorObject = serializeErrors(error);
    const canCreate = hasPermission(permissions, "create_post");
    const canApprove = hasPermission(permissions, "approve_service");
    const canUpdate = hasPermission(permissions, "update_service");

    return (
      <>
        {!(
          location.pathname.split("/").includes("create-new") ||
          location.pathname.split("/").includes("edit")
        ) && (
          <div className="blog-page">
            <RightPanel open={openPanel} onClose={this.closeNewCategoryPanel}>
              <h4 className="panel-heading">Add new category</h4>
              <form onSubmit={this.handleCreateCategory}>
                <label className="panel-label" htmlFor="new-category-name">
                  New category name
                </label>
                <input
                  name="categoryName"
                  type="text"
                  placeholder="Category name"
                  className="border-grey form-control panel-input mb-0"
                  value={categoryName}
                  onChange={this.handleChange}
                />
                <p className="text-error">
                  {errors
                    ? errors.categoryName
                    : errorObject && errorObject["category"]}
                </p>

                <div className="row mt-5">
                  <div className="col-md-6">
                    <button
                      className="btn btn-white btn-sm font-md w-100"
                      onClick={this.closeNewCategoryPanel}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="col-md-6">
                    <button
                      className="btn btn-primary btn-sm font-md w-100 d-flex align-items-center justify-content-center"
                      type="submit"
                    >
                      Request Approval
                      {loading && (
                        <div className="spinner-border spinner-border-white spinner-border-sm ml-2"></div>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </RightPanel>
            <div className="setup-inner__top justify-content-between">
              <ul className="setup-inner__nav mr-0">
                <li className="setup-nav__item">
                  <NavLink
                    exact
                    to={{
                      pathname: `${path}/category`,
                      state: { pageTitle: "Setup" },
                    }}
                    activeClassName="setup-nav__item--active"
                  >
                    Category
                  </NavLink>
                </li>
                <li className="setup-nav__item">
                  <NavLink
                    exact
                    to={{ pathname: `${path}`, state: { pageTitle: "Blog" } }}
                    activeClassName="setup-nav__item--active"
                  >
                    Blog
                  </NavLink>
                </li>
                <li className="setup-nav__item">
                  <NavLink
                    to={{
                      pathname: `${path}/drafts`,
                      state: { pageTitle: "Blog" },
                    }}
                    activeClassName="setup-nav__item--active"
                  >
                    Drafts
                  </NavLink>
                </li>
                {canApprove && (
                  <li className="setup-nav__item">
                    <NavLink
                      to={{
                        pathname: `${path}/pending`,
                        state: { pageTitle: "Blog" },
                      }}
                      activeClassName="setup-nav__item--active"
                    >
                      Pending blogs
                    </NavLink>
                  </li>
                )}
                {canApprove && (
                  <li className="setup-nav__item">
                    <NavLink
                      to={{
                        pathname: `${path}/pending-categories`,
                        state: { pageTitle: "Blog" },
                      }}
                      activeClassName="setup-nav__item--active"
                    >
                      Pending categories
                    </NavLink>
                  </li>
                )}
              </ul>
              <div className="d-flex align-items-center">
                <SearchBox
                  placeholder="Search"
                  handleSearch={this.handleSearch}
                  isActiveSearch={Boolean(search)}
                  key={this.props.location.pathname}
                />
                {canCreate && (
                  <div>
                    {!location.pathname.split("/").includes("category") && (
                      <button
                        className="setup-inner__button"
                        onClick={this.createNewBlog}
                      >
                        <img
                          src={require("@/assets/icons/add-icon.svg").default}
                          className="img-fluid mr-2"
                          alt="add"
                        />
                        Create new blog
                      </button>
                    )}
                    {location.pathname.split("/").includes("category") && (
                      <button
                        className="setup-inner__button"
                        onClick={this.closeNewCategoryPanel}
                      >
                        <img
                          src={require("@/assets/icons/add-icon.svg").default}
                          className="img-fluid mr-2"
                          alt="add"
                        />
                        Add new Category
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <Switch>
          <Route exact path={path}>
            <Blogs />
          </Route>
          <Route exact path={`${path}/category`}>
            <Category />
          </Route>
          <Route path={`${path}/drafts`}>
            <Drafts />
          </Route>
          {canApprove && (
            <Route path={`${path}/pending`}>
              <PendingApproval />
            </Route>
          )}
          {canApprove && (
            <Route path={`${path}/pending-categories`}>
              <PendingCategories />
            </Route>
          )}
          {canCreate && (
            <Route path={`${path}/create-new`}>
              <NewBlog />
            </Route>
          )}
          {canUpdate && (
            <Route path={`${path}/edit/:slug`}>
              <EditBlog />
            </Route>
          )}
        </Switch>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    app: {
      blog: { error, data },
    },
    user: { permissions },
  } = state;
  return {
    loading: getActionLoadingState(
      state,
      actionTypes.CREATE_NEW_CATEGORY_REQUEST
    ),
    error,
    data,
    permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createNewCategory: (payload) => dispatch(createNewCategory(payload)),
    filterBlogPost: (search, section, page, limit, status) =>
      dispatch(filterBlogPost(search, section, page, limit, status)),
    getCategories: (page, limit) => dispatch(getCategories(page, limit)),
    getDrafts: (page, limit) => dispatch(getDrafts(page, limit)),
    getPostsBystatus: (status, page, limit) =>
      dispatch(getPostsBystatus(status, page, limit)),
    getCategoriesBystatus: (status, page, limit) =>
      dispatch(getCategoriesBystatus(status, page, limit)),
    getActivePosts: (page, limit) => dispatch(getActivePosts(page, limit)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Blog));
