import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import {
  fetchInvestments,
  cancelInvestment,
  updateInvestment,
  updateMultipleInvestment,
} from "@/store/Transactions/investmentsAction";
import { clearError } from "@/store/ui/actions";
import actionTypes from "@/store/Transactions/actionTypes";
import { getActionLoadingState, getActionErrorState } from "@/store/selectors";
import {
  currency,
  hasPermission,
  serializeErrors,
  camelCaseToString,
} from "@/utils";
import RightPanel from "@/components/RightPanel";
import Dropdown from "@/components/DropdownMenu";
import Emptystate from "@/components/Emptystate";
import ConfirmationBox from "@/components/ConfirmationBox";
import InputError from "@/components/InputError";
import Pagination from "@/components/Pagination";
import SearchBox from "@/components/SearchBox";
import ExportDropdown from "@/components/ExportDropdown";
import MultipleApprovalDropdown from "@/components/MultipleApprovalDropdown";
import "react-datepicker/dist/react-datepicker.css";

class Investments extends Component {
  state = {
    open: false,
    previewMode: false,
    investments: [],
    targetAmount: "",
    startDate: "",
    endDate: "",
    frequency: "",
    searchTerm: "",
    shouldSearch: false,
    selectedRows: [],
  };

  componentDidMount() {
    const { status } = this.props.match.params;
    this.getInvestments(1, 10, status);
  }

  componentDidUpdate(prevProps, prevState) {
    const { status } = this.props.match.params;
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ selectedRows: [] }, () =>
        this.getInvestments(1, 10, status)
      );
    } else if (
      this.state.searchTerm !== prevState.searchTerm &&
      this.state.shouldSearch
    ) {
      this.getInvestments(1, 10, status, this.state.searchTerm);
    }
  }

  getInvestments = (page, limit, status, searchTerm) => {
    this.props
      .fetchInvestments(page, limit, status, searchTerm)
      .then((investments) => {
        this.setPageData(investments, searchTerm, false);
      });
  };

  setPageData = (
    { lastPage, perPage, page, total, data: investments },
    searchTerm,
    shouldSearch
  ) => {
    this.setState({
      investments,
      lastPage,
      perPage,
      page,
      total,
      searchTerm,
      shouldSearch,
    });
  };

  confirmationData = () => {
    const { isMultipleApproval, selectedRows } = this.state;
    const addS = isMultipleApproval && selectedRows.length > 1;
    return {
      cancelInvestment: {
        title: "Reason for reversing investment",
        action: (reason) => this.cancelInvestment(reason),
        requiresReason: true,
      },
      approveUpdate: {
        title: `Approve update${addS ? "s" : ""}`,
        action: () =>
          isMultipleApproval
            ? this.updateMultiple("approve")
            : this.approveUpdate(),
        question: `Are you sure you want to approve ${
          addS ? "these" : "this"
        } update${addS ? "s" : ""}`,
      },
      rejectUpdate: {
        title: `Reason for rejecting update${addS ? "s" : ""}`,
        action: (reason) =>
          isMultipleApproval
            ? this.updateMultiple("reject", reason)
            : this.rejectUpdate(reason),
        requiresReason: true,
      },
    };
  };

  setConfirmationContext = (context, investmentId, isMultipleApproval) => {
    this.setState({
      confirmationContext: context,
      confirmationSuccess: false,
      investmentId,
      isMultipleApproval,
    });
  };

  cancelInvestment = (reason) => {
    const { investmentId } = this.state;
    this.props.cancelInvestment(investmentId, reason).then(() => {
      this.setState({ confirmationContext: "" });
    });
  };

  onEdit = (id) => {
    const investmentInfo = this.state.investments.find(
      (investment) => investment.id === id
    );
    this.setState({
      targetAmount: investmentInfo.targetAmount,
      startDate: dayjs(investmentInfo.startDate).format("YYYY-MM-DD"),
      endDate: investmentInfo.endDate
        ? dayjs(investmentInfo.endDate).format("YYYY-MM-DD")
        : null,
      frequency: investmentInfo.frequency,
      editId: id,
      isCollection: investmentInfo.endDate ? false : true,
      open: true,
    });
  };

  editInvestment = (e) => {
    e.preventDefault();
    const { targetAmount, startDate, endDate, frequency, editId } = this.state;
    this.props.updateInvestment(
      { action: "edit", targetAmount, startDate, endDate, frequency },
      editId,
      "initiate"
    );
  };

  approveUpdate = () => {
    const { investmentId } = this.state;
    const investmentInfo = this.state.investments.find(
      (investment) => investment.id === investmentId
    );
    const { targetAmount, startDate, endDate, frequency } = {
      ...investmentInfo,
      ...investmentInfo.temp,
    };
    const payload = {
      targetAmount,
      frequency,
      startDate: dayjs(startDate).format("YYYY-MM-DD"),
      endDate: endDate ? dayjs(endDate).format("YYYY-MM-DD") : null,
    };
    this.props.updateInvestment(payload, investmentId);
  };

  rejectUpdate = (message) => {
    const { investmentId } = this.state;
    this.props.updateInvestment(
      { status: "reject", message },
      investmentId,
      "reject"
    );
  };

  updateMultiple = (status, reason) => {
    const { selectedRows } = this.state;
    if (status === "approve") {
      this.props.updateMultipleInvestment(selectedRows, "approve");
    } else {
      const ids = selectedRows.map((row) => row.id);
      this.props.updateMultipleInvestment(ids, "reject", reason).then(() => {
        window.location.reload();
      });
    }
  };

  dropdownmenus = (investmentId, isActive) => {
    const canEdit = hasPermission(this.props.permissions, "update_investment");
    const canCancel = hasPermission(
      this.props.permissions,
      "cancel_investment"
    );
    return [
      {
        name: "View Transactions",
        handler: () => this.props.history.push(`${investmentId}/transactions`),
      },
      isActive && canEdit
        ? { name: "Edit investment", handler: () => this.onEdit(investmentId) }
        : null,
      isActive && canCancel
        ? {
            name: "Cancel investment",
            handler: () =>
              this.setConfirmationContext("cancelInvestment", investmentId),
            textClass: "text-red",
          }
        : null,
    ].filter(Boolean);
  };

  onRowClick = (e, investment) => {
    if (!["BUTTON", "IMG", "LI", "INPUT"].includes(e.target.nodeName)) {
      const { status } = this.props.match.params;
      if (status === "updates") {
        return this.setState({
          oldData: investment.compare.currentInvestment,
          newData: investment.compare.investmentUpdate,
          open: true,
          previewMode: true,
        });
      }
      if (status === "snappy-investment") {
        return this.setState({
          previewMode: true,
          previewItem: {
            "Reference Number": investment.reference,
            Category: investment.service.name,
            "Full Name": `${investment.user.firstName} ${investment.user.lastName}`,
            Title: investment.title,
            "Amount Invested": `${currency(investment.targetAmount)}`,
            "Interest Rate": `${investment.interestRate}%`,
            "Maturity Date": dayjs(investment.endDate).format("D MMM YYYY"),
            "Date Created": dayjs(investment.created_at).format("D MMM YYYY"),
          },
          open: true,
        });
      }
      this.setState({
        previewMode: true,
        previewItem: {
          "Reference Number": investment.reference,
          Category: investment.service.name,
          "Full Name": `${investment.user.firstName} ${investment.user.lastName}`,
          "Target Amount": `${currency(investment.targetAmount)}`,
          Frequency: investment.frequency,
          "Payment Amount": `${investment.installment}`,
          "Date Created": dayjs(investment.created_at).format("D MMM YYYY"),
          "Start Date": dayjs(investment.startDate).format("D MMM YYYY"),
          "End Date": investment.endDate
            ? dayjs(investment.endDate).format("D MMM YYYY")
            : "N/A",
        },
        open: true,
      });
    }
  };

  onCheckRow = (e, { id, targetAmount, startDate, endDate, frequency }) => {
    const selectedRows = [...this.state.selectedRows];
    if (e.target.checked) {
      selectedRows.push({
        id,
        targetAmount,
        frequency,
        startDate: dayjs(startDate).format("YYYY-MM-DD"),
        endDate: endDate ? dayjs(endDate).format("YYYY-MM-DD") : null,
      });
    } else {
      const uncheckedRowIndex = selectedRows.findIndex((row) => row.id === id);
      selectedRows.splice(uncheckedRowIndex, 1);
    }
    this.setState({ selectedRows });
  };

  renderInvestments = () => {
    const { status } = this.props.match.params;
    const isUpdate = status === "updates";
    const isSnappyInvestment = status === "snappy-investment";

    if (isSnappyInvestment) {
      return this.renderSnappyInvestments(isUpdate);
    } else {
      return this.renderDefaultInvestments(isUpdate);
    }
  };

  renderDefaultInvestments = (isUpdate) => {
    return (
      <table className="custum-table custum-table__long">
        <tbody>
          <tr>
            {isUpdate && <th></th>}
            <th>Reference Number</th>
            <th>Category</th>
            <th>Full Name</th>
            <th>Title</th>
            <th>Target Amt.</th>
            <th>Frequency</th>
            <th>Payment Amt.</th>
            <th>Date Created</th>
            <th>Status</th>
            <th></th>
          </tr>
          {this.state.investments.map((data) => {
            const investment = isUpdate ? { ...data, ...data.temp } : data;
            const showDropdown = ["active", "booked"].includes(
              investment.order_status
            );

            return (
              <tr
                key={investment.id}
                onClick={(e) => this.onRowClick(e, investment)}
                className="cursor-pointer"
              >
                {isUpdate && (
                  <td className="row-checkbox">
                    <input
                      type="checkbox"
                      onChange={(e) => this.onCheckRow(e, investment)}
                    />
                  </td>
                )}
                <td style={{ whiteSpace: "normal", maxWidth: "30rem" }}>
                  {investment.reference}
                </td>
                <td>{investment.service.name}</td>
                <td className="text-capitalize">{`${investment.user.firstName} ${investment.user.lastName}`}</td>
                <td style={{ maxWidth: "25rem", whiteSpace: "normal" }}>
                  {investment.title}
                </td>
                <td className="font-heading font-medium">{`₦${currency(
                  investment.targetAmount
                )}`}</td>
                <td className="text-capitalize">{investment.frequency}</td>
                <td className="font-heading font-medium">{`₦${currency(
                  investment.installment
                )}`}</td>
                <td>{dayjs(investment.created_at).format("D MMM YYYY")}</td>
                <td>
                  <span className={`status status--${investment.order_status}`}>
                    {investment.order_status}
                  </span>
                </td>
                {!isUpdate && (
                  <td className="custum-table__ellipsis">
                    <Dropdown
                      menu={this.dropdownmenus(investment.id, showDropdown)}
                      arrow={true}
                    >
                      <button className="wrapper-button ellipsis">
                        <img
                          src={
                            require("@/assets/icons/flat-ellipsis.svg").default
                          }
                          alt="dropdown"
                        />
                      </button>
                    </Dropdown>
                  </td>
                )}
                {isUpdate && (
                  <td className="custum-table__button-cell">
                    <button
                      className="custum-table__button-blue"
                      onClick={() =>
                        this.setConfirmationContext(
                          "approveUpdate",
                          investment.id
                        )
                      }
                    >
                      Approve
                    </button>
                    <button
                      className="custum-table__button-plain"
                      onClick={() =>
                        this.setConfirmationContext(
                          "rejectUpdate",
                          investment.id
                        )
                      }
                    >
                      Reject
                    </button>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  renderSnappyInvestments = (isUpdate) => {
    return (
      <table className="custum-table custum-table__long">
        <tbody>
          <tr>
            {isUpdate && <th></th>}
            <th>Reference Number</th>
            <th>Category</th>
            <th>Full Name</th>
            <th>Title</th>
            <th>Amount Invested</th>
            <th>Interest Rate</th>
            <th>Maturity Date</th>
            <th>Date Created</th>
            <th>Status</th>
            <th></th>
          </tr>
          {this.state.investments.map((data) => {
            const investment = isUpdate ? { ...data, ...data.temp } : data;
            const showDropdown = ["active", "booked"].includes(
              investment.order_status
            );

            return (
              <tr
                key={investment.id}
                onClick={(e) => this.onRowClick(e, investment)}
                className="cursor-pointer"
              >
                {isUpdate && (
                  <td className="row-checkbox">
                    <input
                      type="checkbox"
                      onChange={(e) => this.onCheckRow(e, investment)}
                    />
                  </td>
                )}
                <td style={{ whiteSpace: "normal", maxWidth: "30rem" }}>
                  {investment.reference}
                </td>
                <td>{investment.service.name}</td>
                <td className="text-capitalize">{`${investment.user.firstName} ${investment.user.lastName}`}</td>
                <td style={{ maxWidth: "25rem", whiteSpace: "normal" }}>
                  {investment.title}
                </td>
                <td className="font-heading font-medium">{`₦${currency(
                  investment.targetAmount
                )}`}</td>
                <td className="text-capitalize">{investment.interestRate}</td>
                <td>{dayjs(investment.endDate).format("D MMM YYYY")}</td>
                <td>{dayjs(investment.created_at).format("D MMM YYYY")}</td>
                <td>
                  <span className={`status status--${investment.order_status}`}>
                    {investment.order_status}
                  </span>
                </td>
                {!isUpdate && (
                  <td className="custum-table__ellipsis">
                    <Dropdown
                      menu={this.dropdownmenus(investment.id, showDropdown)}
                      arrow={true}
                    >
                      <button className="wrapper-button ellipsis">
                        <img
                          src={
                            require("@/assets/icons/flat-ellipsis.svg").default
                          }
                          alt="dropdown"
                        />
                      </button>
                    </Dropdown>
                  </td>
                )}
                {isUpdate && (
                  <td className="custum-table__button-cell">
                    <button
                      className="custum-table__button-blue"
                      onClick={() =>
                        this.setConfirmationContext(
                          "approveUpdate",
                          investment.id
                        )
                      }
                    >
                      Approve
                    </button>
                    <button
                      className="custum-table__button-plain"
                      onClick={() =>
                        this.setConfirmationContext(
                          "rejectUpdate",
                          investment.id
                        )
                      }
                    >
                      Reject
                    </button>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  renderPreview = () => {
    const { previewItem, newData, oldData } = this.state;
    const { status } = this.props.match.params;
    if (status === "updates") {
      return (
        newData &&
        Object.keys(newData).map((field) => (
          <div className="panel-info__row" key={field}>
            <span className="font-md text-lowercase mr-5">
              {camelCaseToString(field)}
            </span>
            <span className="text-right ml-2">
              <span className="d-block font-md text-bold text-grey text-break">
                Old data:
                {field === "targetAmount"
                  ? currency(oldData[field])
                  : oldData[field]}
              </span>
              <span className="d-block font-md text-bold text-break">
                New data:
                {field === "targetAmount"
                  ? currency(newData[field])
                  : newData[field]}
              </span>
            </span>
          </div>
        ))
      );
    }
    return Object.keys(previewItem).map((field) => (
      <div className="panel-info__row" key={field}>
        <span className="panel-info__field">{field}</span>
        <span className="panel-info__value">{previewItem[field]}</span>
      </div>
    ));
  };

  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  closePanel = (e) => {
    e.preventDefault();
    this.setState({
      open: false,
      previewMode: false,
    });
    this.props.clearError();
  };

  render() {
    const {
      match: {
        params: { status },
      },
      fetching,
      pageRequest,
      pageRequestError,
    } = this.props;
    const {
      previewMode,
      investments,
      confirmationContext,
      targetAmount,
      endDate,
      frequency,
      isCollection,
      lastPage,
      perPage,
      page,
      total,
      searchTerm,
    } = this.state;
    const prompt = this.confirmationData()[confirmationContext];
    const createErrorObject = serializeErrors(pageRequestError?.message);
    const showMultiAprrovalDropdown = this.state.selectedRows.length > 0;

    return (
      <div className="setup-inner">
        <RightPanel open={this.state.open} onClose={this.closePanel}>
          <h1 className="panel-heading">{`${
            previewMode ? "Investment Details" : "Edit Investment"
          }`}</h1>
          {!previewMode ? (
            <form
              className="form employer-onboard__form"
              onSubmit={this.editInvestment}
            >
              <label className="panel-label" htmlFor="target-amount">
                Target amount
              </label>
              <input
                type="text"
                className="form-control panel-input"
                placeholder="500,000"
                id="target-amount"
                name="targetAmount"
                autoComplete="off"
                value={targetAmount}
                onChange={this.onInputChange}
                required
              />
              <InputError error={createErrorObject.targetAmount} />
              {!isCollection && (
                <>
                  <label className="panel-label" htmlFor="end-date">
                    End date
                  </label>
                  <DatePicker
                    className="form-control panel-input"
                    dayClassName={() => "react-datepicker-dates"}
                    selected={endDate ? new Date(endDate) : null}
                    onChange={(date) =>
                      this.setState({
                        endDate: dayjs(date).format("YYYY-MM-DD"),
                      })
                    }
                    id="end-date"
                    placeholderText="Select end date"
                    autoComplete="off"
                    required
                  />
                </>
              )}
              <InputError error={createErrorObject.isNotValidEndDate} />
              <label className="panel-label" htmlFor="frequency">
                Frequency
              </label>
              <select
                className="form-control panel-input"
                id="frequency"
                name="frequency"
                value={frequency}
                onChange={this.onInputChange}
                required
              >
                <option>Select frequency</option>
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
                <option value="quarterly">Quarterly</option>
                <option value="bi-annually">Bi-annually</option>
                <option value="annually">Annually</option>
              </select>
              <InputError error={createErrorObject.frequencyOfPayment} />
              <div className="dual-button-box">
                <button onClick={this.closePanel} className="cp-button-blue">
                  Cancel
                </button>
                <button className="cp-button-blue">
                  Request Confirmation
                  {pageRequest && (
                    <div className="spinner-border spinner-border-white spinner-border-sm ml-2 mb-2"></div>
                  )}
                </button>
              </div>
            </form>
          ) : (
            <div className="panel-info">
              {previewMode && this.renderPreview()}
              <button className="long-button" onClick={this.closePanel}>
                Close
              </button>
            </div>
          )}
        </RightPanel>
        <ConfirmationBox
          open={confirmationContext}
          closeHandler={() => this.setState({ confirmationContext: "" })}
          title={prompt?.title}
          question={prompt?.question}
          action={prompt?.action}
          loading={pageRequest}
          requiresReason={prompt?.requiresReason}
        />
        <div className="setup-inner__top">
          <ul className="setup-inner__nav">
            <li className="setup-nav__item">
              <NavLink
                to={{ pathname: "recent" }}
                isActive={() => status === "recent"}
                activeClassName="setup-nav__item--active"
              >
                Recent
              </NavLink>
            </li>
            <li className="setup-nav__item">
              <NavLink
                to={{ pathname: "all" }}
                isActive={() => status === "all"}
                activeClassName="setup-nav__item--active"
              >
                All investments
              </NavLink>
            </li>
            <li className="setup-nav__item">
              <NavLink
                to={{ pathname: "nearing-maturity" }}
                isActive={() => status === "nearing-maturity"}
                activeClassName="setup-nav__item--active"
              >
                Nearing Maturity
              </NavLink>
            </li>
            <li className="setup-nav__item">
              <NavLink
                to={{ pathname: "snappy-investment" }}
                isActive={() => status === "snappy-investment"}
                activeClassName="setup-nav__item--active"
              >
                Snappy Investment
              </NavLink>
            </li>
            <li className="setup-nav__item">
              <NavLink
                to={{ pathname: "updates" }}
                isActive={() => status === "updates"}
                activeClassName="setup-nav__item--active"
              >
                Pending updates
              </NavLink>
            </li>
          </ul>
          <SearchBox
            placeholder="Search"
            handleSearch={(searchTerm) =>
              this.setState({ searchTerm, shouldSearch: true })
            }
            isActiveSearch={Boolean(searchTerm)}
            key={this.props.location.pathname}
          />
          {investments.length > 0 && (
            <ExportDropdown
              module="investments"
              status={status === "updates" ? "pending-approval" : "approved"}
              range={status === "updates" ? "none" : status}
              page={page}
              limit={perPage}
              hasMoreThanOnePage={lastPage > 1}
            />
          )}
          {showMultiAprrovalDropdown && (
            <MultipleApprovalDropdown
              approve={() =>
                this.setConfirmationContext("approveUpdate", null, true)
              }
              reject={() =>
                this.setConfirmationContext("rejectUpdate", null, true)
              }
            />
          )}
        </div>
        {fetching ? (
          <div className="text-center text-primary">
            <div className="spinner-border" role="status"></div>
          </div>
        ) : (
          <div className="position-relative">
            {!investments.length ? (
              <Emptystate
                title={`${searchTerm ? "No Result Found" : "No investments"}`}
                icon={require("@/assets/icons/info.svg").default}
              />
            ) : (
              <>
                <Pagination
                  totalPages={lastPage}
                  page={page}
                  limit={perPage}
                  changePageHandler={(page, limit) =>
                    this.getInvestments(page, limit, status, searchTerm)
                  }
                />
                <div className="table-overflow">
                  <div className="setup-inner__main setup-inner__expand">
                    {this.renderInvestments()}
                  </div>
                  <div className="data-count">
                    Showing
                    <span className="font-weight-bold mx-2">{`${investments.length} of ${total}`}</span>
                    Investments
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fetching: getActionLoadingState(state, actionTypes.GET_INVESTMENTS_REQUEST),
    pageRequest: getActionLoadingState(
      state,
      actionTypes.INVESTMENTS_PAGE_REQUEST
    ),
    pageRequestError: getActionErrorState(
      state,
      actionTypes.INVESTMENTS_PAGE_ERROR
    ),
    permissions: state.user.permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchInvestments: (page, limit, recent, searchTerm) =>
      dispatch(fetchInvestments(page, limit, recent, searchTerm)),
    updateInvestment: (data, id, status) =>
      dispatch(updateInvestment(data, id, status)),
    updateMultipleInvestment: (data, status, message) =>
      dispatch(updateMultipleInvestment(data, status, message)),
    cancelInvestment: (id, reason) => dispatch(cancelInvestment(id, reason)),
    clearError: () => dispatch(clearError()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Investments)
);
