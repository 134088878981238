import * as actions from "./actionTypes";

const newInvestmentDefaultState = {
  data: null,
  error: false,
  loading: false,
  investment: null,
}

const newInvestments = (state = newInvestmentDefaultState, action) => {
  switch (action.type) {
    case actions.CLEAR:
      return { ...state, loading: false, error: false, data: null };
    case actions.CREATE_INVESTMENT_REQUEST:
      return { ...state, loading: true, error: false }
    case actions.CREATE_INVESTMENT_SUCCESS:
      return { ...state, loading: false, error: false, data: action.data, investment: action.data.data.investment }
    case actions.CREATE_INVESTMENT_ERROR:
      return { ...state, loading: false, error: action.error }
    default:
      return state;
  }
}

const flashDealsInvestmentDefaultState = {
  data: {
    investments: {
      data: []
    }
  },
  error: false,
  loading: false,
}

const flashDealInvestments = (state = flashDealsInvestmentDefaultState, action) => {
  switch (action.type) {
    case actions.FETCH_FLASH_DEALS_INVESTMENTS_REQUEST:
      return { ...state, loading: true, error: false }
    case actions.FETCH_FLASH_DEALS_INVESTMENTS_SUCCESS:
      return { ...state, loading: false, error: false, data: action.data }
    case actions.FETCH_FLASH_DEALS_INVESTMENTS_ERROR:
      return { ...state, loading: false, error: action.error }
    case actions.REMOVE_INVESTMENT_REQUEST:
      return { ...state, loading: true, error: false }
    case actions.REMOVE_INVESTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          investments: {
            ...state.data.investments,
            data: state.data.investments.data.filter(item => item.id !== action.id)
          }
        }
      }
    case actions.REMOVE_INVESTMENT_ERROR:
      return { ...state, loading: false, error: action.error }
    case actions.UPDATE_INVESTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          investments: {
            ...state.data.investments,
            data: state.data.investments.data.filter(item => item.id !== action.data.data.investment.id)
          }
        }
      }
    case actions.UPDATE_INVESTMENT_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          investments: {
            ...state.data.investments,
            data: state.data.investments.data
                .map(item => {
                  if(item.id === action.data.data.investment.id ) {
                    return { ...item, status: action.data.data.investment.status }
                  }
                  return item
                })
            // .filter(item => item.id !== action.data.data.investment.id)
          }
        }
      }
    default:
      return state;
  }
}


const allInvestmentDefaultState = {
  data: {
    investments: {
      data: []
    }
  },
  error: false,
  loading: false,
}

const allInvestments = (state = allInvestmentDefaultState, action) => {
  switch (action.type) {
    case actions.FETCH_ALL_INVESTMENTS_REQUEST:
      return { ...state, loading: true, error: false }
    case actions.FETCH_ALL_INVESTMENTS_SUCCESS:
      return { ...state, loading: false, error: false, data: action.data }
    case actions.FETCH_ALL_INVESTMENTS_ERROR:
      return { ...state, loading: false, error: action.error }
    case actions.REMOVE_INVESTMENT_REQUEST:
      return { ...state, loading: true, error: false }
    case actions.REMOVE_INVESTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          investments: {
            ...state.data.investments,
            data: state.data.investments.data.filter(item => item.id !== action.id)
          }
        }
      }
    case actions.REMOVE_INVESTMENT_ERROR:
      return { ...state, loading: false, error: action.error }
    case actions.UPDATE_INVESTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          investments: {
            ...state.data.investments,
            data: state.data.investments.data.filter(item => item.id !== action.data.data.investment.id)
          }
        }
      }
    case actions.UPDATE_INVESTMENT_STATUS_SUCCESS:
      return { 
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          investments: {
            ...state.data.investments,
            data: state.data.investments.data
              .map(item => {
                if(item.id === action.data.data.investment.id ) {
                  return { ...item, status: action.data.data.investment.status }
                }
                return item
              })
              // .filter(item => item.id !== action.data.data.investment.id)
          }
        }
      }
    default:
      return state;
  }
}

const pendingInvestmentDefaultState = {
  data: {
    investments: {
      data: []
    }
  },
  error: false,
  loading: false,
}


const pendingFlashDealInvestmentDefaultState = {
  data: {
    investments: {
      data: []
    }
  },
  error: false,
  loading: false,
}

const pendingFlashDealInvestments = (state = pendingInvestmentDefaultState, action) => {
  switch (action.type) {
    case actions.FETCH_ALL_PENDING_FLASH_DEALS_INVESTMENTS_REQUEST:
      return { ...state, loading: true, error: false }
    case actions.FETCH_ALL_PENDING_FLASH_DEALS_INVESTMENTS_SUCCESS:
      return { ...state, loading: false, error: false, data: action.data }
    case actions.UPDATE_FLASH_DEAL_INVESTMENT_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          investments: {
            ...state.data.investments,
            data: state.data.investments.data.filter(item => item.id !== action.data.data.investment.id)
          }
        }
      }
    case actions.UPDATE_MULTIPLE_INVESTMENT_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          investments: {
            ...state.data.investments,
            data: state.data.investments.data.filter(item => !action.data.includes(item.id))
          }
        }
      }
    case actions.CREATE_INVESTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          investments: {
            ...state.data.investments,
            data: [...state.data.investments.data, action.data.data.investment]
          }
        }
      }
    case actions.REMOVE_INVESTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          investments: {
            ...state.data.investments,
            data: state.data.investments.data.filter(item => item.id !== action.id)
          }
        }
      }
    case actions.FETCH_ALL_PENDING_FLASH_DEALS_INVESTMENTS_ERROR:
      return { ...state, loading: false, error: action.error }
    default:
      return state;
  }
}

const pendingInvestments = (state = pendingInvestmentDefaultState, action) => {
  switch (action.type) {
    case actions.FETCH_ALL_PENDING_INVESTMENTS_REQUEST:
      return { ...state, loading: true, error: false }
    case actions.FETCH_ALL_PENDING_INVESTMENTS_SUCCESS:
      return { ...state, loading: false, error: false, data: action.data }
    case actions.UPDATE_INVESTMENT_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          investments: {
            ...state.data.investments,
            data: state.data.investments.data.filter(item => item.id !== action.data.data.investment.id)
          }
        }
      }
    case actions.UPDATE_MULTIPLE_INVESTMENT_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          investments: {
            ...state.data.investments,
            data: state.data.investments.data.filter(item => !action.data.includes(item.id))
          }
        }
      }
    case actions.CREATE_INVESTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          investments: {
            ...state.data.investments,
            data: [...state.data.investments.data, action.data.data.investment]
          }
        }
      }
    case actions.REMOVE_INVESTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          investments: {
            ...state.data.investments,
            data: state.data.investments.data.filter(item => item.id !== action.id)
          }
        }
      }
    case actions.FETCH_ALL_PENDING_INVESTMENTS_ERROR:
      return { ...state, loading: false, error: action.error }
    default:
      return state;
  }
}

const singleInvestmentDefaultState = {
  data: null,
  error: false,
  loading: false,
  investment: null,
}

const singleInvestment = (state = singleInvestmentDefaultState, action) => {
  switch (action.type) {
    case actions.CLEAR_DATA:
      return { ...state, data: null };
    case actions.UPDATE_INVESTMENT_STATUS_REQUEST:
      return { ...state, loading: true, error: false }
    case actions.UPDATE_INVESTMENT_STATUS_SUCCESS:
      return { ...state, loading: false, error: false, data: action.data, investment: action.data.data.investment }
    case actions.UPDATE_INVESTMENT_STATUS_ERROR:
      return { ...state, loading: false, error: action.error }
    case actions.UPDATE_INVESTMENT_REQUEST:
      return { ...state, loading: true, error: false }
    case actions.UPDATE_INVESTMENT_SUCCESS:
      return { ...state, loading: false, error: false, data: action.data, investment: action.data.data.investment }
    case actions.UPDATE_INVESTMENT_ERROR:
      return { ...state, loading: false, error: action.error }
    default:
      return state;
  }
}


export default (state = {}, action) => {
  return {
    newInvestments: newInvestments(state.newInvestments, action),
    allInvestments: allInvestments(state.allInvestments, action),
    flashDealInvestments: flashDealInvestments(state.flashDealInvestments, action),
    pendingInvestments: pendingInvestments(state.pendingInvestments, action),
    pendingFlashDealInvestments: pendingFlashDealInvestments(state.pendingFlashDealInvestments, action),
    singleInvestment: singleInvestment(state.singleInvestment, action),
  }
}