import React from "react";
import { withRouter, Route, Switch, Redirect } from "react-router-dom";
import { Doughnut } from "react-chartjs-2";
import { connect } from "react-redux";
import { getActionLoadingState } from "@/store/selectors";
import actionTypes from "@/store/portfolio/actionTypes";
import { fetchPortfolio } from "@/store/portfolio/actions";
import DashboardBodyMenu from "@/components/DashboardBodyMenu";
import Card from "@/components/Card";
import TermedInvestments from "./TermedInvestments";
import FinancialInstruments from "./FinancialInstruments";
import { ReactComponent as BackArrrow } from "@/assets/icons/previous-page-arrow.svg";
import {
  portfolioPieData,
  portfolioPieOptions,
  currency,
  isNegative,
} from "@/utils";

class Portfolio extends React.Component {
  state = {
    selectedStatus: "all",
  };

  handleChangestatus = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      return this.getPortfolio(`${value}`);
    });
  };

  componentDidMount() {
    this.getPortfolio("all");
  }

  getPortfolio = (status) => {
    const { id } = this.props.match.params;
    this.props.fetchPortfolio(id, status);
  };

  render() {
    const {
      match: {
        path,
        params: { id },
      },
      data,
      loading,
    } = this.props;

    const menus = [
      {
        name: "Termed Investments",
        path: `/dashboard/user/${id}/portfolio/termed-investments`,
      },
      // {
      //   name: 'Financial Instruments',
      //   path: `/dashboard/user/${id}/portfolio/financial-instruments`,
      // },
    ];
    const { selectedStatus } = this.state;
    return (
      <div className="setup-area">
        <div className="px-5 py-4 bg-white d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center ml-2">
            <BackArrrow
              className="cp-pagination__arrow--active"
              onClick={() =>
                this.props.history.push(`/dashboard/users/all/${id}`)
              }
            />
            <span
              className="font-md ml-3 font-weight-bold cp-pagination__arrow--active"
              onClick={() =>
                this.props.history.push(`/dashboard/users/all/${id}`)
              }
            >
              Back
            </span>
          </div>
          <h4 className="color-dark-blue my-0">Portfolio</h4>
          <div>&nbsp;</div>
        </div>
        <div className="setup-inner">
          <div className="portfiolio-page">
            <div className="row">
              <div className="col-md-8">
                <div className="card p-5">
                  <div className="d-flex align-items-bottom mt-0 flex-wrap">
                    <DashboardBodyMenu menus={menus} classes="mt-0" />
                    <select
                      className="selectbox p-2 text-small border-grey text-black"
                      defaultValue={selectedStatus}
                      name="selectedStatus"
                      onChange={this.handleChangestatus}
                    >
                      <option value="all">All</option>
                      <option value="active">Active</option>
                      <option value="booked">Booked</option>
                      <option value="matured">Matured</option>
                      <option value="cancelled">Cancelled</option>
                      <option value="terminated">Terminated</option>
                    </select>
                  </div>
                  {loading ? (
                    <div className="text-center text-primary mt-5">
                      <div className="spinner-border" role="status"></div>
                    </div>
                  ) : (
                    <div className="mt-2">
                      <Switch>
                        <Route exact path={path}>
                          <Redirect
                            to={`/dashboard/user/${this.props.match.params.id}/portfolio/termed-investments`}
                          />
                        </Route>
                        <Route path={`${path}/termed-investments`}>
                          <TermedInvestments investments={data?.investments} />
                        </Route>
                        <Route path={`${path}/financial-instruments`}>
                          <FinancialInstruments />
                        </Route>
                      </Switch>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="card p-5">
                  <h3 className="text-deep-blue text-small">
                    Portfolio Allocation
                  </h3>
                  <div className="mt-4">
                    <Doughnut
                      data={portfolioPieData(
                        Number(data?.wallet.NGN?.replaceAll(",", "")),
                        data?.portfolios?.portfolioWorth.amount
                      )}
                      options={portfolioPieOptions}
                    />
                    <Card classes="border-blue mt-3">
                      <p className="text-grey text-small mb-0">
                        Total Portfolio Value
                      </p>
                      <p className="text-deep-blue text-large mb-0">
                        &#x20A6;
                        {data && data.portfolios?.portfolioWorth
                          ? currency(data.portfolios?.portfolioWorth.amount)
                          : 0}
                      </p>
                      <div className="d-flex mt-2 align-items-baseline">
                        <img
                          src={
                            isNegative(
                              data &&
                                data?.portfolios?.portfolioWorth.percentageDiff
                            ) === "neutral"
                              ? require("@/assets/icons/flatline.svg").default
                              : isNegative(
                                  data &&
                                    data?.portfolios?.portfolioWorth
                                      .percentageDiff
                                )
                              ? require("@/assets/icons/fall-red-small.svg")
                                  .default
                              : require("@/assets/icons/rise-green.svg").default
                          }
                          className="img-fluid mr-2"
                          alt="difference"
                        />
                        <p
                          className={`text-small  mr-2 mb-0 
                            ${
                              isNegative(
                                data &&
                                  data?.portfolios?.portfolioWorth
                                    .percentageDiff
                              ) === "neutral"
                                ? "text-grey"
                                : isNegative(
                                    data &&
                                      data?.portfolios?.portfolioWorth
                                        .percentageDiff
                                  )
                                ? "text-red"
                                : "text-green"
                            }
                            `}
                        >
                          {data &&
                            data?.portfolios?.portfolioWorth.percentageDiff}
                          %
                        </p>
                        <p
                          className={`text-small mb-0 
                            ${
                              isNegative(
                                data &&
                                  data?.portfolios?.portfolioWorth
                                    .percentageDiff
                              ) === "neutral"
                                ? "text-grey"
                                : isNegative(
                                    data &&
                                      data?.portfolios?.portfolioWorth
                                        .percentageDiff
                                  )
                                ? "text-red"
                                : "text-green"
                            }
                            `}
                        >
                          (Last 7 days)
                        </p>
                      </div>
                    </Card>
                    <Card classes="bg-default mt-3">
                      <p className="text-white text-small mb-0">
                        Total Returns
                      </p>
                      <p className="text-white text-large mb-0">
                        &#x20A6;
                        {data && data.portfolios?.totalReturns
                          ? currency(data.portfolios?.totalReturns.amount)
                          : 0}
                      </p>
                      <div className="d-flex mt-2 align-items-baseline">
                        <img
                          src={
                            isNegative(
                              data &&
                                data?.portfolios?.totalReturns.percentageDiff
                            ) === "neutral"
                              ? require("@/assets/icons/flatline-white.svg")
                                  .default
                              : isNegative(
                                  data &&
                                    data?.portfolios?.totalReturns
                                      .percentageDiff
                                )
                              ? require("@/assets/icons/fall-white-small.svg")
                                  .default
                              : require("@/assets/icons/rise-white-small.svg")
                                  .default
                          }
                          className="img-fluid mr-2"
                          alt="difference"
                        />
                        <p className="text-small text-white mr-2 mb-0">
                          {data && data.portfolios?.totalReturns
                            ? data.portfolios?.totalReturns.percentageDiff
                            : 0}
                          %
                        </p>
                        <p className="text-small text-white mb-0">
                          (Last 7 days)
                        </p>
                      </div>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    app: {
      portfolio: { data },
    },
  } = state;

  return {
    loading: getActionLoadingState(state, actionTypes.FETCH_PORTFOLIO_REQUEST),
    data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPortfolio: (id, status) => dispatch(fetchPortfolio(id, status)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Portfolio)
);
