import axios from 'axios';
import CONFIG from '@/config';
import * as actions from "./actionTypes";
import { logout, updateUser } from "@/store/user/actions";
import { showAlert } from '@/store/ui/actions';
import { apiDownloadHandler } from "@/utils";

const getDepositsRequest = () => {
    return {
        type: actions.GET_DEPOSITS_REQUEST,
    }
}

const getDepositsError = () => {
    return {
        type: actions.GET_DEPOSITS_ERROR,
    }
}

const getDepositsSuccess = () => {
    return {
        type: actions.GET_DEPOSITS_SUCCESS,
    }
}

const depositsPageRequest = () => {
    return {
        type: actions.DEPOSITS_PAGE_REQUEST,
    }
}

const depositsPageError = () => {
    return {
        type: actions.DEPOSITS_PAGE_ERROR,
    }
}

const depositsPageSuccess = () => {
    return {
        type: actions.DEPOSITS_PAGE_SUCCESS,
    }
}

export const fetchDeposits = (page, limit, status, searchTerm) => {
    let path = `${status}/deposits?page=${page}&limit=${limit}`;
    if(searchTerm) {
        const section = status === 'bank' ? 'bank-transfer' : status === 'card' ? 'card-deposit' : status;
        path = `deposits/search?search=${searchTerm}&section=${section}&page=${page}&limit=${limit}`;
    }
    return (dispatch, getState) => {
        dispatch(getDepositsRequest());
        return new Promise((resolve) => {
            axios.get(`${CONFIG.BASE_URL}/admin/transactions/${path}`, {
                headers: {
                    "Authorization": getState().user.token,
                },
            })
            .then(response => {
                dispatch(updateUser({
                    token: response.headers.authorization
                }))
                if (response.status === 200) {
                    dispatch(getDepositsSuccess());
                    resolve(response.data.data.deposits || response.data.data);
                }
            })
            .catch(({ response }) => {
                dispatch(getDepositsError());
                dispatch(updateUser({
                    token: response?.headers.authorization
                }))
                if([400, 404].includes(response?.status)) {
                    response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
                }
                if(response?.status === 403) {
                    return dispatch(showAlert({ type: 'error', message: 'Oops! you are unauthorised' }))
                }
                if (response?.status === 401) {
                    dispatch(showAlert({ type: 'error', message: 'Your session has expired' }));
                    return setTimeout(() => dispatch(logout()), 2000);
                }
                dispatch(showAlert({ type: 'error', message: 'Oops! something went wrong please try again.' }))
            })
        })
    }
}

export const updateDeposit = (id, status, message) => {
    const payload = {
        "status": status === 'initiate-refund' ? 'reverse' : status === 'hold' ? 'held' : 'release',
        message,
    }
    return (dispatch, getState) => {
        dispatch(depositsPageRequest());
        return new Promise((resolve) => {
            axios.patch(`${CONFIG.BASE_URL}/admin/transactions/${id}/deposit/${status}`, payload, {
                headers: {
                    "Authorization": getState().user.token,
                },
            })
            .then(response => {
                dispatch(updateUser({
                    token: response.headers.authorization
                }))
                if (response.status === 200) {
                    dispatch(depositsPageSuccess());
                    if(status === 'initiate-refund') dispatch(showAlert({ type: 'success', message: 'Request has been successfully sent for approval' }))
                    resolve();
                }
            })
            .catch(({ response }) => {
                dispatch(depositsPageError());
                dispatch(updateUser({
                    token: response?.headers.authorization
                }))
                if([400, 404].includes(response?.status)) {
                    response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
                }
                if(response?.status === 403) {
                    return dispatch(showAlert({ type: 'error', message: 'Oops! you are unauthorised' }))
                }
                if (response?.status === 401) {
                    dispatch(showAlert({ type: 'error', message: 'Your session has expired' }));
                    return setTimeout(() => dispatch(logout()), 2000);
                }
                dispatch(showAlert({ type: 'error', message: 'Oops! something went wrong please try again.' }))
            })
        })
    }
}

export const exportDeposits = (_, format, status, page, limit) => {
    const fullUrlPath = `${CONFIG.BASE_URL}/admin/transactions/${status}/deposits/download/${format}${page ? `?page=${page}&limit=${limit}` : ''}`;
    return apiDownloadHandler(fullUrlPath);
}
