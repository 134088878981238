import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Redirect } from "react-router-dom";
import AllFaq from './allFaq';
import CreateFaq from './create';
import { hasPermission } from '@/utils';

class Faq extends Component {
    canCreateFAQ = hasPermission(this.props.permissions, 'create_faq');
    canUpdateFAQ = hasPermission(this.props.permissions, 'update_faq');
    
    render() {
        const { match: { path } } = this.props;
        return(
            <Switch>
                <Route exact path={path}>
                    <Redirect to={{pathname: `${path}/active`}} />
                </Route>
                <Route path={`${path}/:type(active|pending|draft)`}>
                    <AllFaq />
                </Route>
                {this.canCreateFAQ && <Route path={`${path}/create`}>
                    <CreateFaq />
                </Route>}
                {this.canUpdateFAQ && <Route path={`${path}/edit/:id(\\d+)`}>
                    <CreateFaq />
                </Route>}
            </Switch>
        )
    }
}

const mapStateToProps = state => {
    return {
        permissions: state.user.permissions,
    }
}

export default withRouter(
    connect(mapStateToProps, null)(Faq)
);
