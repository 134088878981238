import * as actions from "./actionTypes";
import { apiRequestHandler } from '@/utils';

const getReversalsRequest = () => {
    return {
        type: actions.GET_REVERSALS_REQUEST,
    }
}

const getReversalsError = () => {
    return {
        type: actions.GET_REVERSALS_ERROR,
    }
}

const getReversalsSuccess = () => {
    return {
        type: actions.GET_REVERSALS_SUCCESS,
    }
}

const reversalsPageRequest = () => {
    return {
        type: actions.REVERSALS_PAGE_REQUEST,
    }
}

const reversalsPageError = () => {
    return {
        type: actions.REVERSALS_PAGE_ERROR,
    }
}

const reversalsPageSuccess = () => {
    return {
        type: actions.REVERSALS_PAGE_SUCCESS,
    }
}

export const fetchReversals = (page, limit, type, status) => {
    const paths = {
        deposits: `transactions/deposit/reversals/${status}`,
        terminations: `transactions/liquidation/reversals/${status}`,
        investments: `investments/reversals/${status}`,
        withdrawals: `transactions/withdrawals/reversals/${status}`,
    }
    const config = {
        urlPath: `admin/${paths[type]}?page=${page}&limit=${limit}`,
        requestAction: getReversalsRequest,
        successAction: getReversalsSuccess,
        errorAction: getReversalsError,
    }
    return apiRequestHandler(config);
}

export const searchReversals = (page, limit, type, status, searchTerm) => {
    const paths = {
        deposits: `transactions/deposits/reversals/${status}/search?searchWord=${searchTerm}`,
        terminations: `transactions/liquidation/reversals/${status}/search?searchWord=${searchTerm}`,
        investments: `investments/reversals/search?searchWord=${searchTerm}&approval_status=${status}&action=reverse`,
        withdrawals: `transactions/withdrawals/reversals/${status === 'active' ? 'reversed' : 'initReverse'}/search?searchWord=${searchTerm}`,
    }
    const config = {
        urlPath: `admin/${paths[type]}&page=${page}&limit=${limit}`,
        requestAction: getReversalsRequest,
        successAction: getReversalsSuccess,
        errorAction: getReversalsError,
    }
    return apiRequestHandler(config);
}

export const approveReversal = (id, type) => {
    const payload = {
        deposits: {
            "status": "reverse"
        },
        terminations: {
            "approval_status": "active"
        },
        investments: {
            "status": "cancelled"
        },
        withdrawals: {
            "status": "reverse"
        }
    }
    const paths = {
        deposits: `transactions/${id}/deposit/refund`,
        terminations: `transactions/${id}/liquidation/reverse/approve`,
        investments: `investments/${id}/cancel`,
        withdrawals: `transactions/withdrawals/${id}/reverse/approve`,
    }

    const config = {
        urlPath: `admin/${paths[type]}`,
        payload: payload[type],
        method: 'patch',
        requestAction: reversalsPageRequest,
        successAction: reversalsPageSuccess,
        errorAction: reversalsPageError,
    }
    return apiRequestHandler(config);
}

export const rejectReversal = (id, type, reason) => {
    const payload = {
        deposits: {
            "status": "reject",
            "message": reason,
        },
        terminations: {
            "approval_status": "rejected",
            reason,
        },
        investments: {
            "status": "reject",
            "message": reason,
        },
        withdrawals: {
            "status": "reject",
            "message": reason,
        }
    }
    const paths = {
        deposits: `transactions/${id}/deposit/reject`,
        terminations: `transactions/${id}/liquidation/reject`,
        investments: `investments/${id}/cancel/reject`,
        withdrawals: `transactions/withdrawals/${id}/reverse/reject`,
    }

    const config = {
        urlPath: `admin/${paths[type]}`,
        payload: payload[type],
        method: 'patch',
        requestAction: reversalsPageRequest,
        successAction: reversalsPageSuccess,
        errorAction: reversalsPageError,
    }
    return apiRequestHandler(config);
}

export const multipleApprove = (ids, type) => {
    const payload = {
        status: 'reverse',
        approval_status: 'active',
        requests: ids
    }
    const paths = {
        deposits: `transactions/multiple/deposits/refund`,
        terminations: `transactions/multiple/liquidations/reverse/approve`,
        investments: `investments/multiple/cancel/approve`,
        withdrawals: `transactions/withdrawals/multiple/reversals/approve`,
    }

    const config = {
        urlPath: `admin/${paths[type]}`,
        payload: payload,
        method: 'patch',
        requestAction: reversalsPageRequest,
        successAction: reversalsPageSuccess,
        errorAction: reversalsPageError,
    }
    return apiRequestHandler(config);
}

export const multipleReject = (ids, type, message) => {
    const payload = {
        deposits: {
            message,
            requests: ids,
        },
        terminations: {
            reason: message,
            approval_status: 'rejected',
            requests: ids,
        },
        investments: {
            message,
            requests: ids,
            approval_status: 'rejected',
        },
        withdrawals: {
            message,
            status: 'reject',
            requests: ids,
        }
    }
    const paths = {
        deposits: `transactions/multiple/deposits/reject`,
        terminations: `transactions/multiple/liquidations/reverse/reject`,
        investments: `investments/multiple/cancel/reject`,
        withdrawals: `transactions/withdrawals/multiple/reversals/reject`,
    }

    const config = {
        urlPath: `admin/${paths[type]}`,
        payload: payload[type],
        method: 'patch',
        requestAction: reversalsPageRequest,
        successAction: reversalsPageSuccess,
        errorAction: reversalsPageError,
    }
    return apiRequestHandler(config);
}
