import React from 'react';
import { withRouter, Route, Switch, NavLink } from "react-router-dom";
import UniqueCustomers from './UniqueCustomers';
import CustomerClicks from './CustomerClicks';
import ClickSummary from './ClickSummary';
import DownloadedInformation from './DownloadedInformation';
import Back from '@/components/Back';
import './style.scss';

class Details extends React.Component {

  state = {
    search: '',
  }

  componentDidMount(){
    const { params } = this.props.match;

    if(!params?.reportId) {
      this.props.history.push({
        pathname: '/dashboard/reports/ppi',
        state: { pageTitle: 'Report' },
      })
    }
  }

  handleSubmitSearch = (event) => {
    event.preventDefault();
    // const { search } = this.state;
  }

  handleChange = (event) => {
    const { name, value } = event.target
    this.setState({ [name]: value });
  }

  render() {
    const { match: { path, params } } = this.props;
    const { search } = this.state;

    return (
      <div className="ppi-details-page">
        <div className="row justify-content-between align-items-center">
          <Back url={`${`/dashboard/reports/ppi/${params.reportId}/report`}`} />
          <div className="row setup-inner__top--right align-items-center">
            <div className="col-md-7">
              <form onSubmit={this.handleSubmitSearch}>
                <input
                  name="search"
                  type="text"
                  placeholder="&#128269;   Search"
                  className="pr-3 pl-3 border-grey border-radius-default search-input"
                  value={search}
                  onChange={this.handleChange}
                />
              </form>
            </div>
            <div className="col-md-5">
              <select
                className="form-control panel-input mb-0"
                name="export"
              >
                <option value="">Export</option>
                <option value="csv">CSV</option>
                <option value="excel">Excel</option>
                <option value="pdf">PDF</option>
                <option value="print">Print</option>
              </select>
            </div>
          </div>
        </div>
        <div className="setup-inner__top justify-content-between mt-4">
          <ul className="setup-inner__nav mr-0">
            <li className="setup-nav__item" >
              <NavLink
                exact
                to={{ pathname: `/dashboard/reports/ppi/${params.reportId}/details/`, state: { pageTitle: 'Reports' } }}
                activeClassName="setup-nav__item--active"
              >
                Summary of clicks
                </NavLink>
            </li>
            <li className="setup-nav__item" >
              <NavLink
                to={{ pathname: `/dashboard/reports/ppi/${params.reportId}/details/unique-customers`, state: { pageTitle: 'Reports' } }}
                activeClassName="setup-nav__item--active"
              >
                Unique customers
                </NavLink>
            </li>
            <li className="setup-nav__item" >
              <NavLink
                to={{ pathname: `/dashboard/reports/ppi/${params.reportId}/details/customer-clicks`, state: { pageTitle: 'Reports' } }}
                activeClassName="setup-nav__item--active"
              >
                Average clicks
                </NavLink>
            </li>
            <li className="setup-nav__item" >
              <NavLink
                to={{ pathname: `/dashboard/reports/ppi/${params.reportId}/details/downloaded-information`, state: { pageTitle: 'Reports' } }}
                activeClassName="setup-nav__item--active"
              >
                Downloaded the information
                </NavLink>
            </li>
          </ul>
        </div>

        <Switch>
          <Route exact path={path}>
            <ClickSummary id={params?.reportId} />
          </Route>
          <Route path={`${path}/unique-customers`}>
            <UniqueCustomers id={params?.reportId}/>
          </Route>
          <Route path={`${path}/customer-clicks`}>
            <CustomerClicks id={params?.reportId}/>
          </Route>
          <Route path={`${path}/downloaded-information`}>
            <DownloadedInformation id={params?.reportId}/>
          </Route>
        </Switch>
      </div>
    )
  }
}

export default withRouter(Details);
