import React from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getActionLoadingState } from "@/store/selectors";
import { getCategoriesBystatus, updateCategoryStatus } from "@/store/blog/actions";
import actionTypes from "@/store/blog/actionTypes";
import Modal from '@/components/PopModal';
import Pagination from '@/components/Pagination';
import './style.scss';

class PendingCategories extends React.Component {
  postRef = React.createRef();

  state = {
    selectedCategory: null,
    approvalModal: false,
    rejectModal: false,
  }

  componentDidMount() {
    if (this.props.pending) {
      this.updateSelected(this.props.pending[0])
    }
    this.getCategories(1, 10)
  }

  componentDidUpdate(prevProps) {
    if(prevProps.pending !== this.props.pending) {
      this.updateSelected(this.props.pending[0])
    }
  }

  getCategories = (page, limit) => {
    this.props.getCategoriesBystatus('pending', page, limit)
  }

  updateSelected = (data) => {
    this.setState({ selectedCategory: data })
  }

  selectPost = (category) => {
    this.setState({ selectedCategory: category })
  }

  toggleApproveModal = () => {
    this.setState(prevState => ({ approvalModal: !prevState.approvalModal }))
  }

  toggleRejectModal = () => {
    this.setState(prevState => ({ rejectModal: !prevState.rejectModal }))
  }

  handleApprove = () => {
    const { selectedCategory } = this.state;
    const { updateCategoryStatus } = this.props;
    if (!selectedCategory) return;

    updateCategoryStatus(selectedCategory.id, { status: 'active' })
      .then(data => {
        this.toggleApproveModal()
        this.props.getCategoriesBystatus('pending', 1, 10)
          .then(data => {
            this.updateSelected(data?.data?.data[0]);
          })
      })
      .catch(error => {
        this.toggleApproveModal()
      })
  }

  handleReject = () => {
    const { selectedCategory } = this.state;
    const { updateCategoryStatus } = this.props;
    if (!selectedCategory) return;

    updateCategoryStatus(selectedCategory.id, { status: 'rejected' })
      .then(data => {
        this.toggleRejectModal()
        this.props.getCategoriesBystatus('pending', 1, 10)
          .then(data => {
            this.updateSelected(data?.data?.data[0])
          })
      })
      .catch(error => {
        this.toggleRejectModal()
      })
  }

  render() {
    const { loading, pending, statusLoading, data } = this.props;
    const { selectedCategory, approvalModal, rejectModal } = this.state;

    return (
      <div className="pending-categories-page">
        {approvalModal &&
          <Modal onClose={this.toggleApproveModal}>
            <div className="text-center">
              <h3>Confirm</h3>
              <p>Are you sure you want to approve this category?</p>
              <div className="d-flex">
                <button className="btn btn-sm btn-white btn-block mt-3 mr-2" onClick={() => this.setState({ approvalModal: false })}>
                  Cancel
                </button>
                <button className="btn btn-sm btn-primary btn-block mt-3 ml-2 d-flex align-items-center justify-content-center" onClick={this.handleApprove}>
                  Confirm
                  {statusLoading &&
                    <div className="spinner-border spinner-border-white spinner-border-sm ml-2"></div>
                  }
                </button>
              </div>

            </div>
          </Modal>
        }
        {rejectModal &&
          <Modal onClose={this.toggleRejectModal}>
            <div className="text-center">
              <h3>Confirm</h3>
              <p>Are you sure you want to reject this category?</p>
              <div className="d-flex">
                <button className="btn btn-sm btn-white btn-block mt-3 mr-2" onClick={() => this.setState({ rejectModal: false })}>
                  Cancel
                </button>
                <button className="btn btn-sm btn-danger btn-block mt-3 ml-2 d-flex align-items-center justify-content-center" onClick={this.handleReject}>
                  Confirm
                  {statusLoading &&
                    <div className="spinner-border spinner-border-white spinner-border-sm ml-2"></div>
                  }
                </button>
              </div>

            </div>
          </Modal>
        }
        <Pagination
          totalPages={data?.lastPage}
          page={data?.page}
          limit={data?.perPage}
          changePageHandler={(page, limit) => this.getCategories(page, limit)}
        />
        <div className="card mb-5">
          <div className="row no-gutters">
            <div className="col-md-3 border-right p-5">
              {pending && pending.length > 0 ? pending.map(item => (
                <p className="text-deep-blue font-md cursor-pointer text-capitalize pb-5" key={item.id} onClick={() => this.selectPost(item)}>
                  {item.name}
                </p>
              ))
                : <p className="font-md text-deep-blue">No Pending Items</p>
              }
            </div>
            <div className="col-md-9">
              <div className="p-5 d-flex justify-content-between align-items-center border-bottom">
                <h2 className="text-deep-blue text-capitalize">
                  {selectedCategory && selectedCategory.name}
                  {pending && pending.length < 1 && 'No pending items'}
                </h2>
                {loading &&
                  <div className="spinner-border spinner-border-blue spinner-border-md ml-2"></div>
                }
                <div className="d-flex align-items-center">

                  <p className={`status--${selectedCategory?.action} mr-4 mb-0`}>
                    {selectedCategory?.action}
                  </p>
                  {selectedCategory &&
                    <div className="d-flex">
                      <button className="btn btn-sm btn-primary py-1 mr-3" onClick={this.toggleApproveModal}>
                        Approve category
                    </button>
                      <button className="btn btn-sm btn-white py-1 text-danger" onClick={this.toggleRejectModal}>
                        Reject category
                    </button>
                    </div>
                  }
                </div>
              </div>
              <div className="post-container p-4">
                <p className="text-deep-blue font-md">No post under this category yet</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { app: { blog: { error, data, pendingCategories } } } = state;
  return {
    loading: getActionLoadingState(state, actionTypes.GET_CATEGORIES_BY_STATUS_REQUEST),
    statusLoading: getActionLoadingState(state, actionTypes.UPDATE_CATEGORY_STATUS_REQUEST),
    error,
    data,
    pending: pendingCategories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCategoriesBystatus: (status, page, limit) => dispatch(getCategoriesBystatus(status, page, limit)),
    updateCategoryStatus: (id, payload) => dispatch(updateCategoryStatus(id, payload)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PendingCategories));
