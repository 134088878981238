import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, NavLink } from "react-router-dom";
import htmlToDraft from "html-to-draftjs";
import { EditorState, ContentState } from "draft-js";
import {
  fetchMutualFunds,
  updateMutualFund,
  multipleApproveReject,
  exportMutualFunds,
} from "@/store/setup/mutualFunds";
import { getActionLoadingState } from "@/store/selectors";
import actionTypes from "@/store/setup/actionTypes";
import Dropdown from "@/components/DropdownMenu";
import Emptystate from "@/components/Emptystate";
import Pagination from "@/components/Pagination";
import RightPanel from "@/components/RightPanel";
import ConfirmationBox from "@/components/ConfirmationBox";
import CustomSelectInput from "@/components/CustomSelectInput";
import MultipleApprovalDropdown from "@/components/MultipleApprovalDropdown";
import SearchBox from "@/components/SearchBox";
import ExportDropdown from "@/components/ExportDropdown";
import dayjs from "dayjs";
import Create from "./Create";
import { camelCaseToString, hasPermission } from "@/utils";
import "./style.scss";

class MutualFunds extends Component {
  state = {
    mutualFunds: [],
    action: "create",
    selectedRowsIds: [],
    searchTerm: "",
    makeRequest: false,
  };

  componentDidMount() {
    this.getMutualFunds(1, 10);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.getMutualFunds(1, 10);
    } else if (
      this.state.action !== prevState.action &&
      this.state.makeRequest
    ) {
      this.getMutualFunds(1, 10);
    }
    if (
      this.state.searchTerm !== prevState.searchTerm &&
      this.state.makeRequest
    ) {
      this.getMutualFunds(1, 10, this.state.searchTerm);
    }
  }

  isShowing = (type) => {
    if (this.props.location.pathname.indexOf(type) !== -1) {
      return true;
    }
    return false;
  };

  getMutualFunds = (page, limit, searchTerm) => {
    if (this.isShowing("/new") || this.isShowing("/edit")) return;
    const isShowingPending = this.isShowing("/pending");
    const status = isShowingPending ? "pending" : "active";
    const fundType = isShowingPending ? "pending" : "not-pending";
    const action = isShowingPending ? this.state.action : "create";
    this.props
      .fetchMutualFunds(page, limit, status, action, searchTerm, fundType)
      .then((data) => {
        if (searchTerm)
          return this.setPageData(data.mutualFunds, action, searchTerm);
        this.setPageData(data, action, searchTerm);
      });
  };

  setPageData = (
    { lastPage, perPage, page, total, data: mutualFunds },
    action,
    searchTerm
  ) => {
    this.setState({
      mutualFunds,
      lastPage,
      perPage,
      page,
      total,
      searchTerm,
      makeRequest: false,
      selectedRowsIds: [],
      action,
    });
  };

  confirmationData = () => {
    const isRequest = ["disable", "enable", "delete"].includes(
      this.state.action
    );
    const { isMultipleApproval, selectedRowsIds } = this.state;
    const addS = isMultipleApproval && selectedRowsIds.length > 1;
    const text = isRequest
      ? `request${addS ? "s" : ""}`
      : `New Offer${addS ? "s" : ""}`;

    return {
      disable: {
        title: "Reason for disabling New Offers",
        action: (reason) => this.updateMutualFund(reason),
        requiresReason: true,
      },
      enable: {
        title: "Enable New Offers",
        question: `Are you sure you want enable this New Offer?`,
        action: () => this.updateMutualFund(),
      },
      reject: {
        title: `Reason for rejecting ${text}`,
        action: (reason) =>
          isMultipleApproval
            ? this.multipleApproveReject(reason)
            : this.updateMutualFund(reason),
        requiresReason: true,
      },
      approve: {
        title: `Approve ${text}`,
        question: `Are you sure you want to approve ${
          addS ? "these" : "this"
        } ${text}?`,
        action: () =>
          isMultipleApproval
            ? this.multipleApproveReject()
            : this.updateMutualFund(),
      },
      delete: {
        title: "Reason for Deleting New Offers",
        action: (reason) => this.updateMutualFund(reason),
        requiresReason: true,
      },
    };
  };

  setConfirmationContext = (
    context,
    mutualFundId,
    newStatus = "",
    isMultipleApproval
  ) => {
    this.setState({
      confirmationContext: context,
      confirmationSuccess: false,
      id: mutualFundId,
      newStatus,
      isMultipleApproval,
    });
  };

  dropdownmenus = (id, type, status) => {
    const displayStatus = status === "active" ? "disable" : "enable";
    const newStatus =
      status === "active" ? "pending-disabled" : "pending-active";
    const canUpdate = hasPermission(
      this.props.permissions,
      "initiate_mutual_fund_update"
    );
    const canEnableOrDisable = hasPermission(this.props.permissions, [
      "initiate_enable_mutual_fund",
      "initiate_disable_mutual_fund",
    ]);
    const canDelete = hasPermission(
      this.props.permissions,
      "initiate_delete_mutual_fund"
    );

    return [
      canUpdate && {
        name: `Edit ${type} Offer`,
        handler: () => this.props.history.push(`mutual-funds/edit/${id}`),
      },
      canEnableOrDisable && {
        name: `${displayStatus} ${type} Offer`,
        handler: () =>
          this.setConfirmationContext(displayStatus, id, newStatus),
      },
      canDelete && {
        name: `delete ${type} Offer`,
        handler: () =>
          this.setConfirmationContext("delete", id, "pending-delete"),
        textClass: "text-red",
      },
    ].filter(Boolean);
  };

  onRowClick = (e, data) => {
    if (!["BUTTON", "IMG", "LI", "INPUT"].includes(e.target.nodeName)) {
      if (this.state.action === "edit") {
        return this.setState({
          oldData: data.oldMutualFund,
          newData: data.newMutualFund,
          open: true,
        });
      }
      this.setState({
        title: data.title,
        summary: data.summary,
        category: data.category,
        description: this.getPlainText(data.description),
        startDate: dayjs(data.startDate).format("D MMM YYYY"),
        endDate: dayjs(data.endDate).format("D MMM YYYY"),
        offerDocument: data.document,
        offerImage: data.image,
        tag: data.tags,
        formBuilderFields: data.formBuilder
          ?.map((field) => field.title)
          .join(", "),
        open: true,
      });
    }
  };

  multipleApproveReject = (message) => {
    const { action, newStatus, selectedRowsIds, mutualFunds, total } =
      this.state;
    const payload = {
      status: newStatus,
      mutualAction: action === "enable" ? "create" : action,
      approvalStatus: "none",
      mutualFunds: selectedRowsIds,
      message,
    };
    this.props.multipleApproveReject(payload, action === "delete").then(() => {
      const unchangedItems = mutualFunds.filter(
        (data) => !selectedRowsIds.includes(data.id)
      );
      this.setState({
        mutualFunds: unchangedItems,
        selectedRowsIds: [],
        confirmationSuccess: true,
        total: total - selectedRowsIds.length,
      });
    });
  };

  updateMutualFund = (reason) => {
    const { id, newStatus, mutualFunds, total } = this.state;
    this.props.updateMutualFund(id, newStatus, reason).then(() => {
      if (this.isShowing("pending")) {
        const allMutualFunds = [...mutualFunds];
        const mutualFundIndex = allMutualFunds.findIndex(
          (fund) => fund.id === id
        );
        allMutualFunds.splice(mutualFundIndex, 1);
        this.setState({
          mutualFunds: allMutualFunds,
          confirmationSuccess: true,
          total: total - 1,
        });
      } else {
        this.setState({ confirmationSuccess: true });
      }
    });
  };

  getPlainText = (html) => {
    const contentBlock = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    const editorState = EditorState.createWithContent(contentState);
    return editorState.getCurrentContent().getPlainText();
  };

  onCheckRow = (e, rowId) => {
    const selectedRows = [...this.state.selectedRowsIds];
    if (e.target.checked) {
      selectedRows.push(rowId);
    } else {
      const uncheckedRowIndex = selectedRows.findIndex((id) => id === rowId);
      selectedRows.splice(uncheckedRowIndex, 1);
    }
    this.setState({ selectedRowsIds: selectedRows });
  };

  renderPendingMutualFunds = () => {
    const isEdited = this.state.action === "edit";
    const isDisable = this.state.action === "disable";
    const isDelete = this.state.action === "delete";
    const newStatus = isDisable ? "disabled" : isDelete ? "delete" : "active";
    const canApprove = hasPermission(
      this.props.permissions,
      "approve_mutual_fund"
    );

    return (
      <table className="custum-table">
        <tbody>
          <tr>
            <th></th>
            <th>Title</th>
            <th>Category</th>
            {!isEdited ? (
              <>
                <th>Opening Date</th>
                <th>Closing Date</th>
                <th>Tag</th>
              </>
            ) : (
              <>
                <th>Date of Change</th>
                <th>Number of Modifications</th>
                <th>Field Modification</th>
              </>
            )}
            {(isDisable || isDelete) && <th>Reason</th>}
            {canApprove && <th className="actions-column">Actions</th>}
          </tr>
          {this.state.mutualFunds.map((mutualFund) => (
            <tr
              key={mutualFund.id}
              onClick={(e) => this.onRowClick(e, mutualFund)}
              className="cursor-pointer"
            >
              <td className="text-capitalize">
                <input
                  type="checkbox"
                  onChange={(e) => this.onCheckRow(e, mutualFund.id)}
                />
              </td>
              <td className="text-capitalize">{mutualFund.title}</td>
              <td>{mutualFund.category}</td>
              {!isEdited ? (
                <>
                  <td>{dayjs(mutualFund.startDate).format("D MMM YYYY")}</td>
                  <td>{dayjs(mutualFund.endDate).format("D MMM YYYY")}</td>
                  <td>{mutualFund.tags}</td>
                </>
              ) : (
                <>
                  <td>{dayjs(mutualFund.created_at).format("D MMM YYYY")}</td>
                  <td>{mutualFund.numberOfFields}</td>
                  <td style={{ maxWidth: "24rem", whiteSpace: "normal" }}>
                    {mutualFund.fields?.join(", ")}
                  </td>
                </>
              )}
              {(isDisable || isDelete) && (
                <td>{mutualFund.reasons?.[0]?.initiatorMessage || "--"}</td>
              )}
              {canApprove && (
                <td className="custum-table__button-cell">
                  <button
                    className="custum-table__button-blue"
                    onClick={() =>
                      this.setConfirmationContext(
                        "approve",
                        mutualFund.id,
                        newStatus
                      )
                    }
                  >
                    Approve
                  </button>
                  <button
                    className="custum-table__button-plain"
                    onClick={() =>
                      this.setConfirmationContext(
                        "reject",
                        mutualFund.id,
                        "rejected"
                      )
                    }
                  >
                    Reject
                  </button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  renderActiveMutualFunds = () => {
    return (
      <table className="custum-table custum-table__long">
        <tbody>
          <tr>
            <th>Title</th>
            <th>Summary</th>
            <th>Category</th>
            <th>Description</th>
            <th>Opening Date</th>
            <th>Closing Date</th>
            <th>Tag</th>
            <th>Status</th>
            <th></th>
          </tr>
          {this.state.mutualFunds.map((mutualFund) => (
            <tr
              key={mutualFund.id}
              onClick={(e) => this.onRowClick(e, mutualFund)}
              className="cursor-pointer"
            >
              <td>{mutualFund.title}</td>
              <td
                style={{
                  maxWidth: "25rem",
                  whiteSpace: "normal",
                }}
              >
                {mutualFund.summary}
              </td>
              <td
                style={{
                  maxWidth: "25rem",
                  whiteSpace: "normal",
                }}
              >
                {mutualFund.category}
              </td>
              <td
                style={{
                  maxWidth: "25rem",
                  whiteSpace: "normal",
                }}
              >
                {this.getPlainText(mutualFund.description ?? "")}
              </td>
              <td>{dayjs(mutualFund.startDate).format("D MMM YYYY")}</td>
              <td>{dayjs(mutualFund.endDate).format("D MMM YYYY")}</td>
              <td>{mutualFund.tags}</td>
              <td>
                <span
                  className={`status ${
                    mutualFund.status === "active"
                      ? "status--active"
                      : "status--disabled"
                  }`}
                >
                  {mutualFund.status}
                </span>
              </td>
              <td className="custum-table__ellipsis">
                <Dropdown
                  menu={this.dropdownmenus(
                    mutualFund.id,
                    mutualFund.category,
                    mutualFund.status
                  )}
                  arrow={true}
                >
                  <button className="wrapper-button">
                    <img
                      src={require("@/assets/icons/flat-ellipsis.svg").default}
                      alt="dropdown"
                    />
                  </button>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  onCreateClick = () => {
    const {
      match: { path },
    } = this.props;
    this.props.history.push(`${path}/new`);
  };

  closePanel = (e) => {
    e.preventDefault();
    this.setState({ open: false });
  };

  displayLink = (docArray, title) => {
    return docArray.map((url, index) => (
      <a
        className="d-block mb-2"
        key={index}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {title ?? `document-${index + 1}.pdf`}
      </a>
    ));
  };

  renderEditPreviewItem = (field, value) => {
    if (field === "document") return this.displayLink(value);
    if (field === "formBuilder")
      return value?.map((item) => item.title).join(", ");
    if (field === "description") return this.getPlainText(value);
    if (field === "image") return this.displayLink([value], "offer-image");
    if (field === "endDate") return dayjs(value).format("D MMM YYYY");

    return camelCaseToString(value);
  };

  selectType = (type) => {
    this.setState({ action: type, makeRequest: true });
  };

  render() {
    const {
      match: { path },
      fetching,
      confirmationLoading,
      permissions,
    } = this.props;
    const {
      lastPage,
      perPage,
      page,
      total,
      mutualFunds,
      open,
      confirmationContext,
      confirmationSuccess,
      oldData,
      newData,
      action,
      searchTerm,
    } = this.state;
    const prompt = this.confirmationData()[confirmationContext];
    const isCreatePage = this.isShowing("new") || this.isShowing("edit");
    const canCreate = hasPermission(permissions, "create_mutual_fund");
    const showMultiAprrovalDropdown = this.state.selectedRowsIds.length > 0;

    return (
      <div className="setup-inner">
        <RightPanel open={open} onClose={this.closePanel}>
          <h1 className="panel-heading">
            {this.state.action !== "edit"
              ? "New Offers"
              : "Field Modifications"}
          </h1>
          <div className="panel-info">
            {this.state.action !== "edit" ? (
              <>
                <div className="panel-info__row">
                  <span className="panel-info__field">Title</span>
                  <span className="panel-info__value">{this.state.title}</span>
                </div>
                <div className="panel-info__row">
                  <span className="panel-info__field">Summary</span>
                  <span className="panel-info__value">
                    {this.state.summary}
                  </span>
                </div>
                <div className="panel-info__row">
                  <span className="panel-info__field">Category</span>
                  <span className="panel-info__value">
                    {this.state.category}
                  </span>
                </div>
                <div className="panel-info__row">
                  <span className="panel-info__field">Description</span>
                  <span className="panel-info__value">
                    {this.state.description}
                  </span>
                </div>
                <div className="panel-info__row">
                  <span className="panel-info__field">Opening Date</span>
                  <span className="panel-info__value">
                    {this.state.startDate}
                  </span>
                </div>
                <div className="panel-info__row">
                  <span className="panel-info__field">Closing Date</span>
                  <span className="panel-info__value">
                    {this.state.endDate}
                  </span>
                </div>
                {this.state.offerDocument && (
                  <div className="panel-info__row">
                    <span className="panel-info__field">Offer document</span>
                    <span className="panel-info__value">
                      {this.displayLink(this.state.offerDocument)}
                    </span>
                  </div>
                )}
                <div className="panel-info__row">
                  <span className="panel-info__field">Image</span>
                  <span className="panel-info__value">
                    {this.displayLink([this.state.offerImage], "offer-image")}
                  </span>
                </div>
                <div className="panel-info__row">
                  <span className="panel-info__field">Tag</span>
                  <span className="panel-info__value">{this.state.tag}</span>
                </div>
                {this.state.formBuilderFields && (
                  <div className="panel-info__row">
                    <span className="panel-info__field">
                      Form builder fields
                    </span>
                    <span className="panel-info__value">
                      {this.state.formBuilderFields}
                    </span>
                  </div>
                )}
                <button className="long-button" onClick={this.closePanel}>
                  Close
                </button> 
              </>
            ) : (
              <>
                {newData &&
                  Object.keys(newData).map((field) => (
                    <div className="panel-info__row" key={field}>
                      <span className="font-md text-lowercase mr-5">
                        {camelCaseToString(field)}
                      </span>
                      <span className="text-right ml-2">
                        <span className="d-block font-md text-bold text-grey text-break">
                          Old data: 
                          {this.renderEditPreviewItem(field, oldData[field])}
                        </span>
                        <span className="d-block font-md text-bold text-break">
                          New data: 
                          {this.renderEditPreviewItem(field, newData[field])}
                        </span>
                      </span>
                    </div>
                  ))}
              </>
            )}
          </div>
        </RightPanel>
        <ConfirmationBox
          open={confirmationContext}
          closeHandler={() => this.setState({ confirmationContext: "" })}
          success={confirmationSuccess}
          title={prompt?.title}
          question={prompt?.question}
          action={prompt?.action}
          loading={confirmationLoading}
          requiresReason={prompt?.requiresReason}
        />
        <div className="setup-inner__top">
          <ul className="setup-inner__nav">
            <li className="setup-nav__item">
              <NavLink
                exact
                to={{ pathname: `${path}`, state: { pageTitle: "Setup" } }}
                activeClassName="setup-nav__item--active"
              >
                New Offers
              </NavLink>
            </li>
            <li className="setup-nav__item">
              <NavLink
                to={{
                  pathname: `${path}/pending`,
                  state: { pageTitle: "Setup" },
                }}
                activeClassName="setup-nav__item--active"
              >
                Pending Approval
              </NavLink>
            </li>
          </ul>
          <SearchBox
            placeholder="Search"
            handleSearch={(searchTerm) =>
              this.setState({ searchTerm, makeRequest: true })
            }
            isActiveSearch={Boolean(searchTerm)}
            key={this.props.location.pathname + action}
          />
          {mutualFunds.length > 0 && (
            <ExportDropdown
              module="mutual funds"
              status={this.isShowing("/pending") ? "pending" : "active"}
              page={page}
              limit={perPage}
              downloadHandler={(...args) =>
                this.props.exportMutualFunds(action, ...args)
              }
              hasMoreThanOnePage={lastPage > 1}
            />
          )}
          {this.isShowing("pending") ? (
            <>
              <CustomSelectInput
                options={[
                  { name: "New Offers", value: "create" },
                  { name: "Edited New Offers", value: "edit" },
                  { name: "Disabled New Offers", value: "disable" },
                  { name: "Enabled New Offers", value: "enable" },
                  { name: "Deleted New Offers", value: "delete" },
                ]}
                onChange={this.selectType}
                width="175px"
                value={action}
              />
              {showMultiAprrovalDropdown && (
                <MultipleApprovalDropdown
                  approve={() =>
                    this.setConfirmationContext(
                      "approve",
                      null,
                      action === "disable" ? "disabled" : "active",
                      true
                    )
                  }
                  reject={() =>
                    this.setConfirmationContext(
                      "reject",
                      null,
                      "rejected",
                      true
                    )
                  }
                />
              )}
            </>
          ) : (
            !isCreatePage &&
            canCreate && (
              <button
                className="setup-inner__button"
                onClick={this.onCreateClick}
              >
                <img
                  src={require("@/assets/icons/plus.svg").default}
                  alt="plus icon"
                  className="setup-inner__button-img"
                />
                Create New Offers
              </button>
            )
          )}
        </div>
        {fetching ? (
          <div className="text-center text-primary">
            <div className="spinner-border" role="status"></div>
          </div>
        ) : (
          <div className="position-relative">
            {!isCreatePage && !mutualFunds.length ? (
              <Emptystate
                title={`${searchTerm ? "No Result Found" : "No New Offers"}`}
                icon={require("@/assets/icons/info.svg").default}
              />
            ) : (
              <>
                {!isCreatePage && (
                  <Pagination
                    totalPages={lastPage}
                    page={page}
                    limit={perPage}
                    changePageHandler={(page, limit) =>
                      this.getMutualFunds(page, limit, searchTerm)
                    }
                  />
                )}
                <div className="table-overflow">
                  <div className="setup-inner__main setup-inner__expand">
                    {
                      <Switch>
                        <Route
                          exact
                          path={path}
                          render={() => this.renderActiveMutualFunds()}
                        />
                        <Route
                          exact
                          path={`${path}/pending`}
                          render={() => this.renderPendingMutualFunds()}
                        />
                        <Route path={`${path}/new`}>
                          <Create />
                        </Route>
                        <Route path={`${path}/edit/:id`}>
                          <Create />
                        </Route>
                      </Switch>
                    }
                  </div>
                  {!isCreatePage && (
                    <div className="data-count">
                      Showing
                      <span className="font-weight-bold mx-2">{`${mutualFunds.length} of ${total}`}</span>
                      New Offers
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fetching: getActionLoadingState(
      state,
      actionTypes.GET_MUTUAL_FUNDS_REQUEST
    ),
    confirmationLoading: getActionLoadingState(
      state,
      actionTypes.MUTUAL_FUNDS_PAGE_REQUEST
    ),
    permissions: state.user.permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMutualFunds: (page, limit, status, action, searchTerm, type) =>
      dispatch(fetchMutualFunds(page, limit, status, action, searchTerm, type)),
    updateMutualFund: (id, status, reason = "") =>
      dispatch(updateMutualFund(id, status, reason)),
    multipleApproveReject: (payload, isDeleteRequest) =>
      dispatch(multipleApproveReject(payload, isDeleteRequest)),
    exportMutualFunds: (action, module, format, status, page, limit) =>
      dispatch(exportMutualFunds(action, module, format, status, page, limit)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MutualFunds)
);
