import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import dayjs from "dayjs";
import { getActionLoadingState } from "@/store/selectors";
import { getCtrReports } from "@/store/reports/actions";
import actionTypes from "@/store/reports/actionTypes";
import Pagination from "@/components/Pagination";
import Emptystate from "@/components/Emptystate";
import RightPanel from "@/components/RightPanel";
import { formatCurrency } from "@/utils";
import "./style.scss";

class Recent extends React.Component {
  state = {
    openPanel: false,
    selectedReport: null,
  };
  componentDidMount() {
    this.getAllReport(1, 20);
  }
  getAllReport = (page, limit) => {
    this.props.getCtrReports("recent", page, limit);
  };

  closeCtrReportPanel = (event) => {
    event && event.preventDefault();
    this.setState((prevState) => ({ openPanel: !prevState.openPanel }));
  };

  handleSelectItem = (item) => {
    this.setState({ selectedReport: item }, () => {
      this.closeCtrReportPanel();
    });
  };

  render() {
    const { loading, reports } = this.props;
    const { openPanel, selectedReport } = this.state;

    return (
      <div className="recent-ctr-report mb-5">
        <RightPanel open={openPanel} onClose={this.closeCtrReportPanel}>
          <h4 className="panel-heading">Report Details</h4>
          <div className="d-flex justify-content-between">
            <label className="text-grey">Type of Institution</label>
            <label className="text-black font-weight-bold">
              {selectedReport?.typeOfInstitution}
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">Institution Code</label>
            <label className="text-black font-weight-bold">
              {selectedReport?.institutionCodel ?? "-"}
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">Sec Reg NO.</label>
            <label className="text-black font-weight-bold">
              {selectedReport?.regNumber ?? "-"}
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">Nse Number</label>
            <label className="text-black font-weight-bold">
              {selectedReport?.nseNumber ?? "-"}
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">Cscs Number</label>
            <label className="text-black font-weight-bold">
              {selectedReport?.cscsNumber ?? "-"}
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">Transaction Branch</label>
            <label className="text-black font-weight-bold">
              {selectedReport?.branch ?? "-"}
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">First Line Address</label>
            <label className="text-black font-weight-bold">
              {selectedReport?.firstLineAddress ?? "-"}
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">Second Line Address</label>
            <label className="text-black font-weight-bold">
              {selectedReport?.secondLineAddress ?? "-"}
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">City</label>
            <label className="text-black font-weight-bold">
              {selectedReport?.city ?? "-"}
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">State</label>
            <label className="text-black font-weight-bold">
              {selectedReport?.state ?? "-"}
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">Investor’s Surname</label>
            <label className="text-black font-weight-bold">
              {selectedReport?.user.lastName}
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">First Name</label>
            <label className="text-black font-weight-bold">
              {selectedReport?.user.firstName}
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">Title</label>
            <label className="text-black font-weight-bold">
              {selectedReport?.user.title}
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">Alias</label>
            <label className="text-black font-weight-bold">
              {selectedReport?.user.title} {selectedReport?.user.firstName}{" "}
              {selectedReport?.user.lastName}
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">Date of birth</label>
            <label className="text-black font-weight-bold">
              {selectedReport &&
                dayjs(selectedReport?.user.dateOfBirth).format("DD, MMM YYYY")}
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">Date of incorporation</label>
            <label className="text-black font-weight-bold">
              {selectedReport &&
                dayjs(selectedReport?.user.created_at).format("DD, MMM YYYY")}
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">Occupation</label>
            <label className="text-black font-weight-bold">
              {selectedReport?.user?.employment?.occupation ?? "-"}
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">Type of business</label>
            <label className="text-black font-weight-bold">-</label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">ID number</label>
            <label className="text-black font-weight-bold">
              {selectedReport?.user.id}
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">RC number</label>
            <label className="text-black font-weight-bold">-</label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">Type of ID</label>
            <label className="text-black font-weight-bold">
              {selectedReport?.user?.idType ?? "-"}
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">First line address</label>
            <label className="text-black font-weight-bold">
              {selectedReport?.user?.residentialAddress ?? "-"}
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">Second line address</label>
            <label className="text-black font-weight-bold">-</label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">City</label>
            <label className="text-black font-weight-bold">
              {selectedReport?.city ?? "-"}
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">State</label>
            <label className="text-black font-weight-bold">
              {selectedReport?.user.stateOfOrigin}
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">E-mail</label>
            <label className="text-black font-weight-bold">
              {selectedReport?.user.email}
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">Telephone</label>
            <label className="text-black font-weight-bold">
              {selectedReport?.user.phone}
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">Transaction date</label>
            <label className="text-black font-weight-bold">
              {selectedReport &&
                dayjs(selectedReport?.transaction.created_at).format(
                  "DD, MMM YYYY"
                )}
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">Account number(s)</label>
            <label className="text-black font-weight-bold">
              {selectedReport?.transaction.accountNumber}
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">Account status</label>
            <label className="text-black font-weight-bold">
              {selectedReport?.user.status}
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">Cscs account number</label>
            <label className="text-black font-weight-bold">-</label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">Values per stock/share</label>
            <label className="text-black font-weight-bold">-</label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">Volume</label>
            <label className="text-black font-weight-bold">
              {selectedReport
                ? formatCurrency(selectedReport?.transaction.amount)
                : "-"}
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">Amount(N)</label>
            <label className="text-black font-weight-bold">
              {selectedReport
                ? formatCurrency(selectedReport?.transaction.amount)
                : "-"}
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">Instrument type</label>
            <label className="text-black font-weight-bold">
              {selectedReport?.transaction.instrument ?? "-"}
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">Birth place</label>
            <label className="text-black font-weight-bold">
              {selectedReport?.user.placeOfBirth}
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">Gender</label>
            <label className="text-black font-weight-bold">
              {selectedReport?.user.gender}
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">BVN</label>
            <label className="text-black font-weight-bold">
              {selectedReport?.user.bvn}
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">Nationality</label>
            <label className="text-black font-weight-bold">
              {selectedReport?.user.nationality}
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">Employer's name</label>
            <label className="text-black font-weight-bold">
              {selectedReport?.user?.employment.companyName}
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">Employer's address</label>
            <label className="text-black font-weight-bold">
              {selectedReport?.user?.employment.companyAddress}
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">Employer's No</label>
            <label className="text-black font-weight-bold">
              {selectedReport?.user?.employment?.officialPhoneNumber}
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">Source of funds</label>
            <label className="text-black font-weight-bold">
              {selectedReport?.transaction.channel}
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">Tax no</label>
            <label className="text-black font-weight-bold">-</label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">ID issue date</label>
            <label className="text-black font-weight-bold">-</label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">ID expiry date</label>
            <label className="text-black font-weight-bold">-</label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">Issuing authority</label>
            <label className="text-black font-weight-bold">-</label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">Issuing country</label>
            <label className="text-black font-weight-bold">-</label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">Nuban a/c sending inflow</label>
            <label className="text-black font-weight-bold">-</label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">Sender’s a/c name</label>
            <label className="text-black font-weight-bold">-</label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">Sending bank</label>
            <label className="text-black font-weight-bold">-</label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="text-grey">Sender’s account name</label>
            <label className="text-black font-weight-bold">-</label>
          </div>
        </RightPanel>
        <Pagination
          totalPages={reports?.reports.lastPage}
          page={reports?.reports.page}
          limit={reports?.reports.perPage ?? ""}
          changePageHandler={(page, limit) => this.getAllReport(page, limit)}
        />
        {loading && (
          <div className="text-center p-4">
            <div className="spinner-border spinner-border-primary text-primary spinner-border-md mr-2"></div>
          </div>
        )}
        {reports?.reports &&
          (reports?.reports?.data?.length > 0 ? (
            <>
              <div className="setup-inner__main">
                <table className="custum-table">
                  <tbody>
                    <tr>
                      <th>Type of institution</th>
                      <th>Alias</th>
                      <th>Transaction Branch</th>
                      <th>Email</th>
                      <th>Telephone Number</th>
                      <th>Transaction Date</th>
                    </tr>
                    {reports?.reports?.data.map((item) => (
                      <tr
                        key={item.id}
                        onClick={() => this.handleSelectItem(item)}
                        className="cursor-pointer"
                      >
                        <td>
                          <span>{item?.typeOfInstitution}</span>
                        </td>
                        <td>
                          {item?.user.firstName} {item?.user.lastName}
                        </td>
                        <td>
                          <span>{item?.branch}</span>
                        </td>
                        <td>{item?.user.email}</td>
                        <td>{item?.user.phone}</td>
                        <td>
                          {dayjs(item?.created_at).format("DD, MMM YYYY")}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan="6" className="text-center">
                        Showing {reports?.reports?.data?.length} of{" "}
                        {reports?.reports?.total}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <Emptystate
              title={
                this.props.searchWord !== ""
                  ? "No Result Found"
                  : "No CTR Report"
              }
              icon={require("@/assets/icons/info.svg").default}
            />
          ))}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    app: {
      reports: { reports },
    },
  } = state;
  return {
    loading: getActionLoadingState(state, actionTypes.GET_CTR_REPORTS_REQUEST),
    reports,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCtrReports: (type, page, limit) =>
      dispatch(getCtrReports(type, page, limit)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Recent));
