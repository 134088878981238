const UI_REDUCER_DEFAULT_STATE = {
    loading: {},
    updating: {},
    errors: {},
    alerts: {}
}

export default (state = UI_REDUCER_DEFAULT_STATE, action) => {
    const { type, message } = action;
    const { loading } = state;
    const matches = /(.*)_(REQUEST|SUCCESS|ERROR|ALERT|UPDATE|CLEAR)/.exec(type);

    if (!matches) {
        return state;
    }
    // eslint-disable-next-line
    const [actionName, actionPrefix, actionState] = matches;

    switch (actionState) {
        case "ALERT":
            return {
                ...state,
                alerts: {
                    [actionPrefix]: message
                }
            }
        case "CLEAR":
            return {
                ...state,
                alerts: {
                    [actionPrefix]: null
                },
                errors: {},
            }
        default:
            return {
                ...state,
                loading: {
                    ...loading,
                    [actionPrefix]: actionState === "REQUEST"
                },
                errors: {
                    [actionPrefix]: actionState === "ERROR" ? message : false
                },
                updating: {
                    ...state.updating,
                    [actionPrefix]: actionState === "UPDATE"
                },
                alerts: {
                    ...state.alerts,
                    [actionPrefix]: actionState === "ALERT"
                }
            }
    }
}