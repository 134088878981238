// reportWebVitals.js

const reportWebVitals = (onPerfEntry) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    // Get performance data for the current page
    window.performance.getEntriesByType("paint").forEach((entry) => {
      onPerfEntry(entry.name, entry.startTime);
    });
    window.performance.getEntriesByType("navigation").forEach((entry) => {
      onPerfEntry(entry.name, entry.duration);
    });
  }
};

export default reportWebVitals;
