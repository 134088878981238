import React from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getActionLoadingState } from "@/store/selectors";
import { getMutualFundsReport } from "@/store/ppi/actions";
import { exportSinglePPiReport } from "@/store/reports/actions";
import actionTypes from "@/store/ppi/actionTypes";
import { Bar, Line } from 'react-chartjs-2';
import { getLineChartLabels, getLineChartValues } from '@/utils';
import ExportDropdown from '@/components/ExportDropdown';
import './style.scss';
import Back from '@/components/Back';

class Report extends React.Component {

  componentDidMount() {
    const { match: { params } } = this.props;
    this.props.getMutualFundsReport(params.id)
  }

  navigateToDetails = (direction) => {
    const { history, match: { params } } = this.props;
    const url = direction ?  `/dashboard/reports/ppi/${params.id}/details/${direction}` : `/dashboard/reports/ppi/${params.id}/details`
    history.push({
      pathname: url,
      state: { pageTitle: 'Report', fundId: params.id },
    })
  }

  render() {
    const { report, loading, match: { params } } = this.props;

    return (
      <div className="ppi-report-page">
        <div className="top-section">
          <div className="row justify-content-between">
            <Back />
            <ExportDropdown
              excludeCSV
              module="mutual-funds"
              status={params.id}
              downloadHandler={this.props.exportSinglePPiReport}
            />
          </div>
        </div>

        <div className="report-section card p-5 mt-3">
          <h4 className="text-center">How your investment is performing</h4>
          {loading &&
            <div className="spinner-border text-primary ml-auto mr-auto"></div>
          }
          <div className="bar-section mb-5 mt-5">
            <Bar
              data = {{
                labels: [
                  "Summary of clicks", "Number of unique customers", "Average clicks", "Downloaded the information"
                ],
                datasets: [{
                  label: `Performance of ${report?.title}`,
                  data: [report?.__meta__?.totalSummary, report?.__meta__?.totalUniqueCustomers, report?.__meta__?.averageClicks, report?.__meta__?.totalDownload],
                  backgroundColor: '#044472',
                }],
              }}
              width={100}
              height={50}
              options={{ 
                responsive: true,
                scales: {
                  xAxes: [{
                    offset: true, // <-- This right here
                  }],
                  yAxes: [{
                      display: true,
                      ticks: {
                        beginAtZero: true,
                        stepValue: 5,
                      }
                  }]
                },
              }}
            />
          </div>
          <div className="row no-gutters mt-5 mb-5">
            <div className="col-md-3 card text-center p-4 cursor-pointer" onClick={() => this.navigateToDetails()}>
              <h1 className="text-deep-blue mb-3 pt-3">{report?.__meta__?.totalSummary}</h1>
              <p className="text-small mb-0 pb-3">Summary of clicks</p>
            </div>
            <div className="col-md-3 card text-center p-4 cursor-pointer" onClick={() => this.navigateToDetails('unique-customers')}>
              <h1 className="text-deep-blue mb-3 pt-3">{report?.__meta__?.totalUniqueCustomers}</h1>
              <p className="text-small mb-0 pb-3">Number of unique customers</p>

            </div>
            <div className="col-md-3 card text-center p-4 cursor-pointer" onClick={() => this.navigateToDetails('customer-clicks')}>
              <h1 className="text-deep-blue mb-3 pt-3">{report?.__meta__?.averageClicks}</h1>
              <p className="text-small mb-0 pb-3">Average clicks</p>

            </div>
            <div className="col-md-3 card text-center p-4 cursor-pointer" onClick={() => this.navigateToDetails('downloaded-information')}>
              <h1 className="text-deep-blue mb-3 pt-3">{report?.__meta__?.totalDownload}</h1>
              <p className="text-small mb-0 pb-3">Downloaded the information</p>

            </div>
          </div>
          <div className="line-chart-section mt-5">
          <Line
              data = {{
                labels: report?.perfomance && getLineChartLabels(report?.perfomance),
                datasets: [{
                  label: 'All time performance',
                  fill: false,
                  borderColor: '#044472',
                  data: report?.perfomance ? getLineChartValues(report?.perfomance) : [],
                }],
              }}
              width={100}
              height={50}
              options={{ 
                responsive: true,
                scales: {
                  xAxes: [{
                    offset: true, // <-- This right here
                  }],
                  yAxes: [{
                      display: true,
                      ticks: {
                        beginAtZero: true,
                        stepValue: 5,
                      }
                  }]
                },
              }}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { app:
    {
      ppi: {
        report
      }
    }
  } = state;
  return {
    loading: getActionLoadingState(state, actionTypes.GET_FUNDS_REPORT_REQUEST),
    report,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMutualFundsReport: (id) => dispatch(getMutualFundsReport(id)),
    exportSinglePPiReport: (module, format, status) => dispatch(exportSinglePPiReport(module, format, status)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Report));
