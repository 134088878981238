import axios from 'axios';
import CONFIG from '@/config';
import * as actions from "./actionTypes";
import { logout, updateUser } from "@/store/user/actions";
import { showAlert } from '@/store/ui/actions';

const getCategoriesRequest = () => {
  return {
    type: actions.GET_CATEGORIES_REQUEST
  }
}

const getCategoriesSuccess = (data) => {
  return {
    type: actions.GET_CATEGORIES_SUCCESS,
    data,
  }
}

const getCategoriesError = (error) => {
  return {
    type: actions.GET_CATEGORIES_ERROR,
    error,
  }
}

export const getCategories = (page, limit) => {
  return (dispatch, getState) => {
    dispatch(getCategoriesRequest());

    const { token } = getState().user;
    return new Promise((resolve, reject) => {

    axios.get(`${CONFIG.BASE_URL}/admin/blog/category?limit=${limit}&page=${page}`, {
      headers: {
        'Content-Type': "application/json",
        Authorization: token,
      },
    })
      .then(response => {
        dispatch(updateUser({
          token: response.headers.authorization
        }))
        if ([200, 201].includes(response.status)) {
          dispatch(getCategoriesSuccess(response.data))
          resolve(response.data)
        }
        return;
      })
      .catch(({ response }) => {
        reject(response?.data)
        response && dispatch(updateUser({
          token: response.headers.authorization
        }))
        if (response && [400, 404, 403].includes(response.status)) {
          response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
          return dispatch(getCategoriesError(response.data.error ? response.data.error : response.data.message));
        }
        if (response && [401].includes(response.status)) {
          dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
          return setTimeout(() => dispatch(logout()), 2000)
        }
        if (response && response.status >= 500) {
          return dispatch(getCategoriesError('Oops! We did something wrong.'));
        }
        dispatch(getCategoriesError('Oops! We did something wrong.'));
      })    
    })
  }
}

//create new category
const createNewCategoryRequest = () => {
  return {
    type: actions.CREATE_NEW_CATEGORY_REQUEST
  }
}

const createNewCategorySuccess = (data) => {
  return {
    type: actions.CREATE_NEW_CATEGORY_SUCCESS,
    data,
  }
}

const createNewCategoryError = (error) => {
  return {
    type: actions.CREATE_NEW_CATEGORY_ERROR,
    error,
  }
}

export const createNewCategory = (payload) => {
  return (dispatch, getState) => {
    dispatch(createNewCategoryRequest());

    const { token } = getState().user;
    return new Promise((resolve, reject) => {

    axios.post(`${CONFIG.BASE_URL}/admin/blog/category`, payload, {
      headers: {
        'Content-Type': "application/json",
        Authorization: token,
      },
    })
      .then(response => {
        dispatch(updateUser({
          token: response.headers.authorization
        }))
        if ([200, 201].includes(response.status)) {
          dispatch(createNewCategorySuccess(response.data))
          response.data.message && dispatch(showAlert({ type: 'success', message: response.data.message }))
          resolve(response.data)
        }
        return;
      })
      .catch(({ response }) => {
        reject(response?.data)
        response && dispatch(updateUser({
          token: response.headers.authorization
        }))
        if (response && [400, 404, 403].includes(response.status)) {
          response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
          return dispatch(createNewCategoryError(response.data.error ? response.data.error : response.data.message));
        }
        if (response && [401].includes(response.status)) {
          dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
          return setTimeout(() => dispatch(logout()), 2000)
        }
        if (response && response.status >= 500) {
          return dispatch(createNewCategoryError('Oops! We did something wrong.'));
        }
        dispatch(createNewCategoryError('Oops! We did something wrong.'));
      })    
    })
  }
}

//UPDATE CATEGORY
const updateCategoryRequest = () => {
  return {
    type: actions.UPDATE_CATEGORY_REQUEST
  }
}

const updateCategorySuccess = (data) => {
  return {
    type: actions.UPDATE_CATEGORY_SUCCESS,
    data,
  }
}

const updateCategoryError = (error) => {
  return {
    type: actions.UPDATE_CATEGORY_ERROR,
    error,
  }
}

export const updateCategory = (id, payload) => {
  return (dispatch, getState) => {
    dispatch(updateCategoryRequest());

    const { token } = getState().user;
    return new Promise((resolve, reject) => {

    axios.put(`${CONFIG.BASE_URL}/admin/blog/category/${id}`, payload, {
      headers: {
        'Content-Type': "application/json",
        Authorization: token,
      },
    })
      .then(response => {
        dispatch(updateUser({
          token: response.headers.authorization
        }))
        if ([200, 201].includes(response.status)) {
          dispatch(updateCategorySuccess(response.data))
          response.data.message && dispatch(showAlert({ type: 'success', message: response.data.message }))
          resolve(response.data)
        }
        return;
      })
      .catch(({ response }) => {
        reject(response?.data)
        response && dispatch(updateUser({
          token: response.headers.authorization
        }))
        if (response && [400, 404, 403].includes(response.status)) {
          response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
          return dispatch(updateCategoryError(response.data.error ? response.data.error : response.data.message));
        }
        if (response && [401].includes(response.status)) {
          dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
          return setTimeout(() => dispatch(logout()), 2000)
        }
        if (response && response.status >= 500) {
          return dispatch(updateCategoryError('Oops! We did something wrong.'));
        }
        dispatch(updateCategoryError('Oops! We did something wrong.'));
      })    
    })
  }
}

//UPDATE CATEGORY STATUS
const updateCategoryStatusRequest = () => {
  return {
    type: actions.UPDATE_CATEGORY_STATUS_REQUEST
  }
}

const updateCategoryStatusSuccess = (data) => {
  return {
    type: actions.UPDATE_CATEGORY_STATUS_SUCCESS,
    data,
  }
}

const updateCategoryStatusError = (error) => {
  return {
    type: actions.UPDATE_CATEGORY_STATUS_ERROR,
    error,
  }
}

export const updateCategoryStatus = (id, payload) => {

  return (dispatch, getState) => {
    dispatch(updateCategoryStatusRequest());

    const { token } = getState().user;
    return new Promise((resolve, reject) => {

    axios.put(`${CONFIG.BASE_URL}/admin/blog/category/${id}/status`, payload, {
      headers: {
        'Content-Type': "application/json",
        Authorization: token,
      },
    })
      .then(response => {
        dispatch(updateUser({
          token: response.headers.authorization
        }))
        if ([200, 201].includes(response.status)) {
          dispatch(updateCategoryStatusSuccess(response.data))
          response.data.message && dispatch(showAlert({ type: 'success', message: response.data.message }))
          resolve(response.data ? response.data : response)
        }
        return;
      })
      .catch(({ response }) => {
        reject(response?.data)
        response && dispatch(updateUser({
          token: response.headers.authorization
        }))
        if (response && [400, 404, 403].includes(response.status)) {
          response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
          return dispatch(updateCategoryStatusError(response.data.error ? response.data.error : response.data.message));
        }
        if (response && [401].includes(response.status)) {
          dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
          return setTimeout(() => dispatch(logout()), 2000)
        }
        if (response && response.status >= 500) {
          return dispatch(updateCategoryStatusError('Oops! We did something wrong.'));
        }
        dispatch(updateCategoryStatusError('Oops! We did something wrong.'));
      })    
    })
  }
}

//DELETE CATEGORY
const deleteCategoryRequest = () => {
  return {
    type: actions.DELETE_CATEGORY_REQUEST
  }
}

const deleteCategorySuccess = (data) => {
  return {
    type: actions.DELETE_CATEGORY_SUCCESS,
    data,
  }
}

const deleteCategoryError = (error) => {
  return {
    type: actions.DELETE_CATEGORY_ERROR,
    error,
  }
}

export const deleteCategory = (id) => {

  return (dispatch, getState) => {
    dispatch(deleteCategoryRequest());

    const { token } = getState().user;
    return new Promise((resolve, reject) => {

    axios.delete(`${CONFIG.BASE_URL}/admin/blog/category/${id}`, {
      headers: {
        'Content-Type': "application/json",
        Authorization: token,
      },
    })
      .then(response => {
        dispatch(updateUser({
          token: response.headers.authorization
        }))
        if ([200, 201].includes(response.status)) {
          dispatch(deleteCategorySuccess(response.data))
          response.data.message && dispatch(showAlert({ type: 'success', message: response.data.message }))
          resolve(response.data ? response.data : response)
        }
        return;
      })
      .catch(({ response }) => {
        reject(response?.data)
        response && dispatch(updateUser({
          token: response.headers.authorization
        }))
        if (response && [400, 404, 403].includes(response.status)) {
          response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
          return dispatch(deleteCategoryError(response.data.error ? response.data.error : response.data.message));
        }
        if (response && [401].includes(response.status)) {
          dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
          return setTimeout(() => dispatch(logout()), 2000)
        }
        if (response && response.status >= 500) {
          return dispatch(deleteCategoryError('Oops! We did something wrong.'));
        }
        dispatch(deleteCategoryError('Oops! We did something wrong.'));
      })    
    })
  }
}

//GET CATEGORIES BY STATUS
const getCategoriesBystatusRequest = () => {
  return {
    type: actions.GET_CATEGORIES_BY_STATUS_REQUEST
  }
}

const getCategoriesBystatusSuccess = (data) => {
  return {
    type: actions.GET_CATEGORIES_BY_STATUS_SUCCESS,
    data,
  }
}

const getCategoriesBystatusError = (error) => {
  return {
    type: actions.GET_CATEGORIES_BY_STATUS_ERROR,
    error,
  }
}

export const getCategoriesBystatus = (status, page, limit) => {
  return (dispatch, getState) => {
    dispatch(getCategoriesBystatusRequest());

    const { token } = getState().user;
    return new Promise((resolve) => {
      axios.get(`${CONFIG.BASE_URL}/admin/blog/category/status/${status}?limit=${limit}&page=${page}`, {
        headers: {
          'Content-Type': "application/json",
          Authorization: token,
        },
      })
        .then(response => {
          dispatch(updateUser({
            token: response.headers.authorization
          }))
          if ([200, 201].includes(response.status)) {
            dispatch(getCategoriesBystatusSuccess(response.data));
            resolve(response.data);
          }
          return;
        })
        .catch(({ response }) => {
          response && dispatch(updateUser({
            token: response.headers.authorization
          }))
          if (response && [400, 404, 403].includes(response.status)) {
            response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
            return dispatch(getCategoriesBystatusError(response.data.error ? response.data.error : response.data.message));
          }
          if (response && [401].includes(response.status)) {
            dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
            return setTimeout(() => dispatch(logout()), 2000)
          }
          if (response && response.status >= 500) {
            return dispatch(getCategoriesBystatusError('Oops! We did something wrong.'));
          }
          dispatch(getCategoriesBystatusError('Oops! We did something wrong.'));
        })
    })
  }
}

//POST NEW BLOG POST
const postNewBlogPostRequest = () => {
  return {
    type: actions.PUBLISH_BLOG_POSTS_REQUEST
  }
}

const postNewBlogPostSuccess = (data) => {
  return {
    type: actions.PUBLISH_BLOG_POSTS_SUCCESS,
    data,
  }
}

const postNewBlogPostError = (error) => {
  return {
    type: actions.PUBLISH_BLOG_POSTS_ERROR,
    error,
  }
}

export const postNewBlogPost = (payload) => {
  return (dispatch, getState) => {
    dispatch(postNewBlogPostRequest());

    const { token } = getState().user;
    return new Promise((resolve, reject) => {
      axios.post(`${CONFIG.BASE_URL}/admin/blog/posts`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: token,
        },
      })
        .then(response => {
          dispatch(updateUser({
            token: response.headers.authorization
          }))
          if ([200, 201].includes(response.status)) {
            dispatch(postNewBlogPostSuccess(response.data));
            resolve(response.data);
            return dispatch(showAlert({ type: 'success', message: response.data.message }))
          }
          return;
        })
        .catch(({ response }) => {
          reject(response);
          response && dispatch(updateUser({
            token: response.headers.authorization
          }))
          if (response && [400].includes(response.status)) {
            return dispatch(editBlogPostError(response.data.error ? response.data.error : response.data.message));
          }
          if (response && [404, 403].includes(response.status)) {
            response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
            return dispatch(postNewBlogPostError(response.data.error ? response.data.error : response.data.message));
          }
          if (response && [401].includes(response.status)) {
            dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
            return setTimeout(() => dispatch(logout()), 2000)
          }
          if (response && response.status >= 500) {
            return dispatch(postNewBlogPostError('Oops! We did something wrong.'));
          }
          dispatch(postNewBlogPostError('Oops! We did something wrong.'));
        })
    })
  }
}

//GET POSTS BY STATUS
const getPostsBystatusRequest = () => {
  return {
    type: actions.GET_POST_BY_STATUS_REQUEST
  }
}

const getPostsBystatusSuccess = (data) => {
  return {
    type: actions.GET_POST_BY_STATUS_SUCCESS,
    data,
  }
}

const getPostsBystatusError = (error) => {
  return {
    type: actions.GET_POST_BY_STATUS_ERROR,
    error,
  }
}

export const getPostsBystatus = (status, page, limit) => {
  return (dispatch, getState) => {
    dispatch(getPostsBystatusRequest());

    const { token } = getState().user;
    return new Promise((resolve) => {
      axios.get(`${CONFIG.BASE_URL}/admin/blog/posts/status/${status}?limit=${limit}&page=${page}`, {
        headers: {
          'Content-Type': "application/json",
          Authorization: token,
        },
      })
        .then(response => {
          dispatch(updateUser({
            token: response.headers.authorization
          }))
          if ([200, 201].includes(response.status)) {
            dispatch(getPostsBystatusSuccess(response.data));
            resolve(response.data);
          }
          return;
        })
        .catch(({ response }) => {
          response && dispatch(updateUser({
            token: response.headers.authorization
          }))
          if (response && [400, 404, 403].includes(response.status)) {
            response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
            return dispatch(getPostsBystatusError(response.data.error ? response.data.error : response.data.message));
          }
          if (response && [401].includes(response.status)) {
            dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
            return setTimeout(() => dispatch(logout()), 2000)
          }
          if (response && response.status >= 500) {
            return dispatch(getPostsBystatusError('Oops! We did something wrong.'));
          }
          dispatch(getPostsBystatusError('Oops! We did something wrong.'));
        })
    })
  }
}

//GET DRAFTS
const getDraftsRequest = () => {
  return {
    type: actions.GET_DRAFTS_REQUEST
  }
}

const getDraftsSuccess = (data) => {
  return {
    type: actions.GET_DRAFTS_SUCCESS,
    data,
  }
}

const getDraftsError = (error) => {
  return {
    type: actions.GET_DRAFTS_ERROR,
    error,
  }
}

export const getDrafts = (page, limit) => {
  return (dispatch, getState) => {
    dispatch(getDraftsRequest());

    const { token } = getState().user;
    return new Promise((resolve) => {
      axios.get(`${CONFIG.BASE_URL}/admin/blog/posts/drafts?limit=${limit}&page=${page}`, {
        headers: {
          'Content-Type': "application/json",
          Authorization: token,
        },
      })
        .then(response => {
          dispatch(updateUser({
            token: response.headers.authorization
          }))
          if ([200, 201].includes(response.status)) {
            dispatch(getDraftsSuccess(response.data));
            resolve(response.data);
          }
          return;
        })
        .catch(({ response }) => {
          response && dispatch(updateUser({
            token: response.headers.authorization
          }))
          if (response && [400, 404, 403].includes(response.status)) {
            response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
            return dispatch(getDraftsError(response.data.error ? response.data.error : response.data.message));
          }
          if (response && [401].includes(response.status)) {
            dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
            return setTimeout(() => dispatch(logout()), 2000)
          }
          if (response && response.status >= 500) {
            return dispatch(getDraftsError('Oops! We did something wrong.'));
          }
          dispatch(getDraftsError('Oops! We did something wrong.'));
        })
    })
  }
}


//GET ACTIVE POSTS
const getActivePostsRequest = () => {
  return {
    type: actions.GET_BLOG_POSTS_REQUEST
  }
}

const getActivePostsSuccess = (data) => {
  return {
    type: actions.GET_BLOG_POSTS_SUCCESS,
    data,
  }
}

const getActivePostsError = (error) => {
  return {
    type: actions.GET_BLOG_POSTS_ERROR,
    error,
  }
}

export const getActivePosts = (page, limit) => {
  return (dispatch, getState) => {
    dispatch(getActivePostsRequest());

    const { token } = getState().user;
    return new Promise((resolve) => {
      axios.get(`${CONFIG.BASE_URL}/admin/blog/posts?limit=${limit}&page=${page}`, {
        headers: {
          'Content-Type': "application/json",
          Authorization: token,
        },
      })
        .then(response => {
          dispatch(updateUser({
            token: response.headers.authorization
          }))
          if ([200, 201].includes(response.status)) {
            dispatch(getActivePostsSuccess(response.data));
            resolve(response.data);
          }
          return;
        })
        .catch(({ response }) => {
          response && dispatch(updateUser({
            token: response.headers.authorization
          }))
          if (response && [400, 404, 403].includes(response.status)) {
            response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
            return dispatch(getActivePostsError(response.data.error ? response.data.error : response.data.message));
          }
          if (response && [401].includes(response.status)) {
            dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
            return setTimeout(() => dispatch(logout()), 2000)
          }
          if (response && response.status >= 500) {
            return dispatch(getActivePostsError('Oops! We did something wrong.'));
          }
          dispatch(getActivePostsError('Oops! We did something wrong.'));
        })
    })
  }
}


//EDIT BLOG POST
const editBlogPostRequest = () => {
  return {
    type: actions.EDIT_BLOG_POST_REQUEST
  }
}

const editBlogPostSuccess = (data) => {
  return {
    type: actions.EDIT_BLOG_POST_SUCCESS,
    data,
  }
}

const editBlogPostError = (error) => {
  return {
    type: actions.EDIT_BLOG_POST_ERROR,
    error,
  }
}

export const editBlogPost = (payload, slug) => {
  return (dispatch, getState) => {
    dispatch(editBlogPostRequest());

    const { token } = getState().user;
    return new Promise((resolve) => {
      axios.put(`${CONFIG.BASE_URL}/admin/blog/posts/${slug}`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: token,
        },
      })
        .then(response => {
          dispatch(updateUser({
            token: response.headers.authorization
          }))
          if ([200, 201].includes(response.status)) {
            dispatch(editBlogPostSuccess(response.data));
            dispatch(showAlert({ type: 'success', message: response.data?.message }))
            resolve(response.data);
          }
          return;
        })
        .catch(({ response }) => {
          response && dispatch(updateUser({
            token: response.headers.authorization
          }))
          if (response && [400].includes(response.status)) {
            return dispatch(editBlogPostError(response.data.error ? response.data.error : response.data.message));
          }
          if (response && [404, 403].includes(response.status)) {
            response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
            return dispatch(editBlogPostError(response.data.error ? response.data.error : response.data.message));
          }
          if (response && [401].includes(response.status)) {
            dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
            return setTimeout(() => dispatch(logout()), 2000)
          }
          if (response && response.status >= 500) {
            return dispatch(editBlogPostError('Oops! We did something wrong.'));
          }
          dispatch(editBlogPostError('Oops! We did something wrong.'));
        })
    })
  }
}

//GET SINGLE BLOG POST
const getSinglePostRequest = () => {
  return {
    type: actions.GET_SINGLE_BLOG_POST_REQUEST
  }
}

const getSinglePostSuccess = (data) => {
  return {
    type: actions.GET_SINGLE_BLOG_POST_SUCCESS,
    data,
  }
}

const getSinglePostError = (error) => {
  return {
    type: actions.GET_SINGLE_BLOG_POST_ERROR,
    error,
  }
}

export const getSinglePost = (slug) => {
  return (dispatch, getState) => {
    dispatch(getSinglePostRequest());

    const { token } = getState().user;
    return new Promise((resolve) => {
      axios.get(`${CONFIG.BASE_URL}/admin/blog/posts/${slug}`, {
        headers: {
          'Content-Type': "application/json",
          Authorization: token,
        },
      })
        .then(response => {
          dispatch(updateUser({
            token: response.headers.authorization
          }))
          if ([200, 201].includes(response.status)) {
            dispatch(getSinglePostSuccess(response.data));
            resolve(response.data);
          }
          return;
        })
        .catch(({ response }) => {
          response && dispatch(updateUser({
            token: response.headers.authorization
          }))
          if (response && [400, 404, 403].includes(response.status)) {
            response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
            return dispatch(getSinglePostError(response.data.error ? response.data.error : response.data.message));
          }
          if (response && [401].includes(response.status)) {
            dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
            return setTimeout(() => dispatch(logout()), 2000)
          }
          if (response && response.status >= 500) {
            return dispatch(getSinglePostError('Oops! We did something wrong.'));
          }
          dispatch(getSinglePostError('Oops! We did something wrong.'));
        })
    })
  }
}

//DELETE BLOG POST
const deletePostRequest = () => {
  return {
    type: actions.DELETE_BLOG_POST_REQUEST
  }
}

const deletePostSuccess = (data) => {
  return {
    type: actions.DELETE_BLOG_POST_SUCCESS,
    data,
  }
}

const deletePostError = (error) => {
  return {
    type: actions.DELETE_BLOG_POST_ERROR,
    error,
  }
}

export const deletePost = (slug) => {
  return (dispatch, getState) => {
    dispatch(deletePostRequest());

    const { token } = getState().user;
    return new Promise((resolve, reject) => {
      axios.delete(`${CONFIG.BASE_URL}/admin/blog/posts/${slug}`, {
        headers: {
          'Content-Type': "application/json",
          Authorization: token,
        },
      })
        .then(response => {
          dispatch(updateUser({
            token: response.headers.authorization
          }))
          if ([200, 201].includes(response.status)) {
            dispatch(deletePostSuccess(response.data));
            dispatch(showAlert({ type: 'success', message: response.data.message }))
            resolve(response.data);
          }
          return;
        })
        .catch(({ response }) => {
          reject(response)
          response && dispatch(updateUser({
            token: response.headers.authorization
          }))
          if (response && [400, 404, 403].includes(response.status)) {
            response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
            return dispatch(deletePostError(response.data.error ? response.data.error : response.data.message));
          }
          if (response && [401].includes(response.status)) {
            dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
            return setTimeout(() => dispatch(logout()), 2000)
          }
          if (response && response.status >= 500) {
            return dispatch(deletePostError('Oops! We did something wrong.'));
          }
          dispatch(deletePostError('Oops! We did something wrong.'));
        })
    })
  }
}

//UPDATE BLOG POST STATUS
const updateBlogStatusRequest = () => {
  return {
    type: actions.UPDATE_STATUS_REQUEST
  }
}

const updateBlogStatusSuccess = (data) => {
  return {
    type: actions.UPDATE_STATUS_SUCCESS,
    data,
  }
}

const updateBlogStatusError = (error) => {
  return {
    type: actions.UPDATE_STATUS_ERROR,
    error,
  }
}

export const updateBlogStatus = (slug, payload) => {
  return (dispatch, getState) => {
    dispatch(updateBlogStatusRequest());

    const { token } = getState().user;
    return new Promise((resolve, reject) => {
      axios.put(`${CONFIG.BASE_URL}/admin/blog/posts/${slug}/status`, payload, {
        headers: {
          'Content-Type': "application/json",
          Authorization: token,
        },
      })
        .then(response => {
          dispatch(updateUser({
            token: response.headers.authorization
          }))
          if ([200, 201].includes(response.status)) {
            dispatch(updateBlogStatusSuccess(response.data));
            dispatch(showAlert({ type: 'success', message: response.data.message }))
            resolve(response.data);
          }
          return;
        })
        .catch(({ response }) => {
          reject(response)
          response && dispatch(updateUser({
            token: response.headers.authorization
          }))
          if (response && [400, 404, 403].includes(response.status)) {
            response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
            return dispatch(updateBlogStatusError(response.data.error ? response.data.error : response.data.message));
          }
          if (response && [401].includes(response.status)) {
            dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
            return setTimeout(() => dispatch(logout()), 2000)
          }
          if (response && response.status >= 500) {
            return dispatch(updateBlogStatusError('Oops! We did something wrong.'));
          }
          dispatch(updateBlogStatusError('Oops! We did something wrong.'));
        })
    })
  }
}

//EDIT BLOG POST
export const filterBlogPost = (search, section, page, limit, status) => {
  const sections = {
    blogs: {
      request: getActivePostsRequest,
      error: getActivePostsError,
      success: getActivePostsSuccess,
      url: `${CONFIG.BASE_URL}/admin/blog/posts/filter?search=${search}&page=${page}&limit=${limit}&status=${status}`,
    },
    categories: {
      request: getCategoriesRequest,
      error: getCategoriesError,
      success: getCategoriesSuccess,
      url: `${CONFIG.BASE_URL}/admin/blog/category/filter?search=${search}&page=${page}&limit=${limit}&status=${status}`,
    },
    drafts: {
      request: getDraftsRequest,
      error: getDraftsError,
      success: getDraftsSuccess,
      url: `${CONFIG.BASE_URL}/admin/blog/posts/drafts/filter?search=${search}&page=${page}&limit=${limit}`,
    }
  }
  return (dispatch, getState) => {
    dispatch(sections[section].request());

    const { token } = getState().user;
    return new Promise((resolve) => {
      axios.get(sections[section].url, {
        headers: {
          'Content-Type': "application/json",
          Authorization: token,
        },
      })
        .then(response => {
          dispatch(updateUser({
            token: response.headers.authorization
          }))
          if ([200, 201].includes(response.status)) {
            if(section === 'blogs' && status === 'pending') {
              return dispatch(getPostsBystatusSuccess(response.data));
            }
            if(section === 'categories' && status === 'pending') {
              return dispatch(getCategoriesBystatusSuccess(response.data));
            }
            dispatch(sections[section].success(response.data));
            resolve(response.data);
          }
          return;
        })
        .catch(({ response }) => {
          response && dispatch(updateUser({
            token: response.headers.authorization
          }))
          if (response && [400, 404, 403].includes(response.status)) {
            response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
            return dispatch(sections[section].error(response.data.error ? response.data.error : response.data.message));
          }
          if (response && [401].includes(response.status)) {
            dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
            return setTimeout(() => dispatch(logout()), 2000)
          }
          if (response && response.status >= 500) {
            return dispatch(sections[section].error('Oops! We did something wrong.'));
          }
          dispatch(sections[section].error('Oops! We did something wrong.'));
        })
    })
  }
}