import React, { Component } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { currency } from "@/utils";

class Pagination extends Component {
  backClick = () => {
    const { page, limit } = this.props;
    if (+page === 1) return;
    this.props.changePageHandler(+page - 1, limit);
  };

  nextClick = () => {
    const { page, limit } = this.props;
    if (+page === this.props.totalPages) return;
    this.props.changePageHandler(+page + 1, limit);
  };

  onInputChange = (e) => {
    this.props.changePageHandler(1, e.target.value);
  };

  render() {
    const { totalPages, page, limit } = this.props;
    return page ? (
      <div className="flex items-center justify-between p-4 bg-white mb-2 rounded-lg border border-gray-300">
        <div className="flex items-center">
          <span className="text-lg font-medium">Show</span>
          <select
            className="mx-2 border rounded-md p-1 text-lg"
            onChange={this.onInputChange}
            value={limit}
          >
            <option>5</option>
            <option>10</option>
            <option>20</option>
            <option>40</option>
            <option>60</option>
          </select>
          <span className="text-lg font-medium">entries</span>
        </div>
        <div className="flex items-center">
          <button
            className={`flex items-center justify-center w-8 h-8 rounded-full ${
              page > 1
                ? "bg-blue-500 text-white hover:bg-blue-700"
                : "text-gray-400 cursor-not-allowed"
            }`}
            onClick={this.backClick}
            disabled={page <= 1}
          >
            <ChevronLeftIcon className="size-6" />
          </button>
          <span className="mx-4 text-lg font-semibold">
            {page} of {Number(totalPages).toLocaleString()}
          </span>
          <button
            className={`flex items-center justify-center w-8 h-8 rounded-full ${
              page < totalPages
                ? "bg-blue-700 text-white hover:bg-blue-700"
                : "text-gray-400 cursor-not-allowed"
            }`}
            onClick={this.nextClick}
            disabled={page >= totalPages}
          >
            <ChevronRightIcon className="size-6" />
          </button>
        </div>
      </div>
    ) : null;
  }
}

export default Pagination;
