import React, { Component } from "react";
import Modal from '@/components/cpModal';

class ExportPrompt extends Component {
    close = () => {
        this.props.closeHandler();
    }

    render() {
        const {open, title, onSelect} = this.props;
        return(
            <Modal open={open} outsideClose close={this.close}>
                <div className="confirmation-modal text-center">
                    <h2 className="confirmation-modal__heading">{`Export ${title}`}</h2>
                    <button className="setup-inner__button d-block mx-auto w-50" onClick={() => onSelect(true)}>
                        Export current page
                    </button>
                    <button className="setup-inner__button d-block mx-auto mt-3 w-50" onClick={() => onSelect(false)}>
                        Export all pages
                    </button>
                </div>
            </Modal>
        )
    }
}

export default ExportPrompt;
