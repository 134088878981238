import axios from "axios";
import CONFIG from "@/config";
import * as actions from "./actionTypes";
import { logout, updateUser } from "@/store/user/actions";
import { showAlert } from "@/store/ui/actions";

const clear = () => {
  return {
    type: actions.CLEAR,
  };
};

const getReferralsRequest = () => {
  return {
    type: actions.GET_REFERRALS_REQUEST,
  };
};

const getReferralsError = () => {
  return {
    type: actions.GET_REFERRALS_ERROR,
  };
};

const getReferralsSuccess = () => {
  return {
    type: actions.GET_REFERRALS_SUCCESS,
  };
};

export const fetchReferrals = (page, limit, campaign_type, searchTerm) => {
  let query = `limit=${limit}&page=${page}&campaign_type=${campaign_type}`;
  
  if (searchTerm) {
    query += `&search=${encodeURIComponent(searchTerm)}`; 
  }

  return (dispatch, getState) => {
    dispatch(getReferralsSuccess());
    return new Promise((resolve) => {
      axios
        .get(`${CONFIG.BASE_URL}/admin/campaigns?${query}`, {
          headers: {
            Authorization: getState().user.token,
          },
        })
        .then((response) => {
          dispatch(
            updateUser({
              token: response.headers.authorization,
            })
          );
          if (response.status === 200) {
            dispatch(getReferralsSuccess());
            resolve(response.data.data || response.data.data);
          }
        })
        .catch(({ response }) => {
          dispatch(getReferralsError());
          dispatch(
            updateUser({
              token: response?.headers.authorization,
            })
          );
          if ([400, 404].includes(response?.status)) {
            response.data.message &&
              dispatch(
                showAlert({ type: "error", message: response.data.message })
              );
          }
          if (response?.status === 403) {
            return dispatch(
              showAlert({
                type: "error",
                message: "Oops! you are unauthorized",
              })
            );
          }
          if (response?.status === 401) {
            dispatch(
              showAlert({ type: "error", message: "Your session has expired" })
            );
            return setTimeout(() => dispatch(logout()), 2000);
          }
          dispatch(
            showAlert({
              type: "error",
              message: "Oops! something went wrong, please try again.",
            })
          );
        });
    });
  };
};


export const createReferral = (payload) => {
  return (dispatch, getState) => {
    dispatch(getReferralsRequest());

    const { token } = getState().user;

    axios
      .post(`${CONFIG.BASE_URL}/admin/campaigns`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((response) => {
        dispatch(
          updateUser({
            token: response.headers.authorization,
          })
        );
        if ([200, 201].includes(response.status)) {
          dispatch(getReferralsSuccess(response.data));
          setTimeout(() => dispatch(clear()), 3000);
          return dispatch(
            showAlert({ type: "success", message: response.data.message })
          );
        }
        return;
      })
      .catch(({ response }) => {
        response &&
          dispatch(
            updateUser({
              token: response.headers.authorization,
            })
          );
        if (response && [400, 404, 403].includes(response.status)) {
          response.data.message &&
            dispatch(
              showAlert({ type: "error", message: response.data.message })
            );
          return dispatch(
            getReferralsError(
              response.data.error ? response.data.error : response.data.message
            )
          );
        }
        if (response && [401].includes(response.status)) {
          dispatch(
            showAlert({ type: "error", message: "Your session has expired" })
          );
          return setTimeout(() => dispatch(logout()), 2000);
        }
        if (response && response.status >= 500) {
          return dispatch(getReferralsError("Oops! We did something wrong."));
        }
        dispatch(getReferralsError("Oops! We did something wrong."));
      });
  };
};

export const editReferral = (payload, id) => {
  return (dispatch, getState) => {
    dispatch(getReferralsRequest());
    const { token } = getState().user;
    axios
      .put(`${CONFIG.BASE_URL}/admin/campaigns/${id}`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((response) => {
        dispatch(
          updateUser({
            token: response.headers.authorization,
          })
        );
        if ([200, 201].includes(response.status)) {
          dispatch(getReferralsSuccess(response.data));
          setTimeout(() => dispatch(clear()), 3000);
          return dispatch(
            showAlert({ type: "success", message: response.data.message })
          );
        }
        return;
      })
      .catch(({ response }) => {
        response &&
          dispatch(
            updateUser({
              token: response.headers.authorization,
            })
          );
        if (response && [400, 404, 403].includes(response.status)) {
          response.data.message &&
            dispatch(
              showAlert({ type: "error", message: response.data.message })
            );
          return dispatch(
            getReferralsError(
              response.data.error ? response.data.error : response.data.message
            )
          );
        }
        if (response && [401].includes(response.status)) {
          dispatch(
            showAlert({ type: "error", message: "Your session has expired" })
          );
          return setTimeout(() => dispatch(logout()), 2000);
        }
        if (response && response.status >= 500) {
          return dispatch(getReferralsError("Oops! We did something wrong."));
        }
        dispatch(getReferralsError("Oops! We did something wrong."));
      });
  };
};

export const toggleReferralStatus = (campaignId) => {
  return (dispatch, getState) => {
    dispatch(getReferralsRequest());

    const { token } = getState().user;

    axios
      .put(
        `${CONFIG.BASE_URL}/admin/campaigns/${campaignId}/toggle`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      )
      .then((response) => {
        dispatch(
          updateUser({
            token: response.headers.authorization,
          })
        );

        if (response.status === 200) {
          dispatch(getReferralsSuccess());
          return dispatch(
            showAlert({ type: "success", message: response.data.message })
          );
        }
      })
      .catch(({ response }) => {
        dispatch(
          updateUser({
            token: response?.headers.authorization,
          })
        );

        if ([400, 404, 403].includes(response?.status)) {
          response.data.message &&
            dispatch(
              showAlert({ type: "error", message: response.data.message })
            );
        } else if (response?.status === 401) {
          dispatch(
            showAlert({ type: "error", message: "Your session has expired" })
          );
          return setTimeout(() => dispatch(logout()), 2000);
        } else {
          dispatch(
            showAlert({
              type: "error",
              message: "Oops! Something went wrong. Please try again.",
            })
          );
        }

        dispatch(
          getReferralsError(
            response?.data?.message || "Error toggling campaign status"
          )
        );
      });
  };
};
