import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getActionLoadingState } from "@/store/selectors";
import { getPostsBystatus, updateBlogStatus } from "@/store/blog/actions";
import actionTypes from "@/store/blog/actionTypes";
import DropdownMenu from "@/components/DropdownMenu";
import Modal from "@/components/PopModal";
import Pagination from "@/components/Pagination";
import { hasPermission } from "@/utils";
import "./style.scss";

class PendingApproval extends React.Component {
  postRef = React.createRef();

  state = {
    selectedPost: null,
    approvalModal: false,
    rejectModal: false,
  };

  componentDidMount() {
    if (this.props.pending) {
      this.updateSelected(this.props.pending[0]);
    }
    this.getPosts(1, 10);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pending !== this.props.pending) {
      this.updateSelected(this.props.pending[0]);
    }
  }

  getPosts = (page, limit) => {
    this.props.getPostsBystatus("pending", page, limit);
  };

  updateSelected = (data) => {
    this.setState({ selectedPost: data }, () => {
      if (this.state.selectedPost && this.postRef) {
        this.postRef.current.innerHTML = `${this.state.selectedPost?.content}`;
      }
      if (this.state.selectedPost === undefined || !this.state.selectedPost) {
        this.postRef.current.innerHTML = null;
      }
    });
  };

  selectPost = (post) => {
    this.setState({ selectedPost: post }, () => {
      if (this.state.selectedPost && this.postRef) {
        this.postRef.current.innerHTML = `${this.state.selectedPost?.content}`;
      }
    });
  };

  handleEdit = () => {
    const { selectedPost } = this.state;
    const { history } = this.props;
    if (!selectedPost) return;
    history.push({
      pathname: `/dashboard/setup/blog/edit/${selectedPost.slug}`,
      state: {
        blogPost: selectedPost,
        pageTitle: "Edit Post",
      },
    });
  };

  toggleApproveModal = () => {
    this.setState((prevState) => ({ approvalModal: !prevState.approvalModal }));
  };

  toggleRejectModal = () => {
    this.setState((prevState) => ({ rejectModal: !prevState.rejectModal }));
  };

  handleApprove = () => {
    const { selectedPost } = this.state;
    const { updateBlogStatus } = this.props;
    if (!selectedPost) return;

    updateBlogStatus(selectedPost.slug, { status: "active" })
      .then((data) => {
        this.toggleApproveModal();
        this.props.getPostsBystatus("pending", 1, 10).then((data) => {
          this.updateSelected(data?.data?.data[0]);
          this.postRef.current.innerHTML = null;
        });
      })
      .catch((error) => {
        this.toggleApproveModal();
      });
  };

  handleReject = () => {
    const { selectedPost } = this.state;
    const { updateBlogStatus } = this.props;
    if (!selectedPost) return;

    updateBlogStatus(selectedPost.slug, { status: "rejected" })
      .then((data) => {
        this.toggleRejectModal();
        this.props.getPostsBystatus("pending", 1, 10).then((data) => {
          this.updateSelected(data?.data?.data[0]);
          this.postRef.current.innerHTML = null;
        });
      })
      .catch((error) => {
        this.toggleRejectModal();
      });
  };

  render() {
    const { loading, pending, statusLoading, data, permissions } = this.props;
    const canApprove = hasPermission(permissions, "approve_service");
    const canUpdate = hasPermission(permissions, "update_service");

    const { selectedPost, approvalModal, rejectModal } = this.state;
    const menu = [
      canUpdate && {
        name: "Edit blog",
        handler: this.handleEdit,
      },
      canApprove && {
        name: "Approve blog",
        handler: this.toggleApproveModal,
      },
      canApprove && {
        name: "Reject Blog",
        handler: this.toggleRejectModal,
      },
    ];
    return (
      <div className="pending-posts-page">
        {approvalModal && (
          <Modal onClose={this.toggleApproveModal}>
            <div className="text-center">
              <h3>Confirm</h3>
              <p>Are you sure you want to approve this blog post?</p>
              <div className="d-flex">
                <button
                  className="btn btn-sm btn-white btn-block mt-3 mr-2"
                  onClick={() => this.setState({ approvalModal: false })}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-sm btn-primary btn-block mt-3 ml-2 d-flex align-items-center justify-content-center"
                  onClick={this.handleApprove}
                >
                  Confirm
                  {statusLoading && (
                    <div className="spinner-border spinner-border-white spinner-border-sm ml-2"></div>
                  )}
                </button>
              </div>
            </div>
          </Modal>
        )}
        {rejectModal && (
          <Modal onClose={this.toggleRejectModal}>
            <div className="text-center">
              <h3>Confirm</h3>
              <p>Are you sure you want to reject this blog post?</p>
              <div className="d-flex">
                <button
                  className="btn btn-sm btn-white btn-block mt-3 mr-2"
                  onClick={() => this.setState({ rejectModal: false })}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-sm btn-danger btn-block mt-3 ml-2 d-flex align-items-center justify-content-center"
                  onClick={this.handleReject}
                >
                  Confirm
                  {statusLoading && (
                    <div className="spinner-border spinner-border-white spinner-border-sm ml-2"></div>
                  )}
                </button>
              </div>
            </div>
          </Modal>
        )}
        <Pagination
          totalPages={data?.lastPage}
          page={data?.page}
          limit={data?.perPage}
          changePageHandler={(page, limit) => this.getPosts(page, limit)}
        />
        <div className="card mb-5">
          <div className="row no-gutters">
            <div className="col-md-3 border-right p-5">
              {pending && pending.length > 0 ? (
                pending.map((item) => (
                  <p
                    className="text-deep-blue font-md cursor-pointer text-capitalize pb-5"
                    key={item.id}
                    onClick={() => this.selectPost(item)}
                  >
                    {item.title}
                  </p>
                ))
              ) : (
                <p className="font-md text-deep-blue">No Pending Items</p>
              )}
            </div>
            <div className="col-md-9">
              <div className="p-5 d-flex justify-content-between align-items-center border-bottom">
                <h2 className="text-deep-blue text-capitalize">
                  {selectedPost && selectedPost.title}
                  {pending && pending.length < 1 && "No pending items"}
                </h2>
                {loading && (
                  <div className="spinner-border spinner-border-blue spinner-border-md ml-2"></div>
                )}
                <div className="d-flex">
                  <p className={`status--${selectedPost?.action} mr-4`}>
                    {selectedPost?.action}
                  </p>
                  {selectedPost && (
                    <DropdownMenu menu={menu}>
                      <img
                        src={
                          require("@/assets/icons/flat-ellipsis.svg").default
                        }
                        alt="dropdown"
                      />
                    </DropdownMenu>
                  )}
                </div>
              </div>
              <div className="post-container p-4">
                {selectedPost?.image && (
                  <div className="img-wrapper">
                    <img
                      src={selectedPost?.image}
                      className="img-fluid"
                      alt="post-img"
                    />
                  </div>
                )}
                <div ref={this.postRef} className="mt-3"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    app: {
      blog: { error, data, pending },
    },
    user: { permissions },
  } = state;
  return {
    loading: getActionLoadingState(
      state,
      actionTypes.GET_POST_BY_STATUS_REQUEST
    ),
    statusLoading: getActionLoadingState(
      state,
      actionTypes.UPDATE_STATUS_REQUEST
    ),
    error,
    data,
    pending,
    permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPostsBystatus: (status, page, limit) =>
      dispatch(getPostsBystatus(status, page, limit)),
    updateBlogStatus: (slug, payload) =>
      dispatch(updateBlogStatus(slug, payload)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PendingApproval)
);
