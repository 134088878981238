import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, NavLink, Switch, Route, Redirect } from "react-router-dom";
import dayjs from "dayjs";
import {
  exportDeposits,
  exportWithdrawals,
} from "@/store/analytics/transactionAnalyticsAction";
import Deposits from "./Deposits";
import Withdrawals from "./Withdrawals";
import ExportDropdown from "@/components/ExportDropdown";
import AnalyticsDateInput from "@/components/AnalyticsDateInput";
import ReloadButton from "@/components/ReloadButton";
import "./Style.scss";

const twelveMonthsAgo = new Date().setMonth(new Date().getMonth() - 12);
class TransactionAnalytics extends Component {
  state = {
    depositByMonthDate: [twelveMonthsAgo, new Date()],
    depositByTypeDate: [twelveMonthsAgo, new Date()],
    withdrawalsDate: [twelveMonthsAgo, new Date()],
    totalAndAverageDepositDate: [twelveMonthsAgo, new Date()],
    totalAndAverageWithdrawalDate: [twelveMonthsAgo, new Date()],
    viewToExport: "default",
    withdrawalView: "default",
  };

  depositExportOptions = [
    { name: "All Pages", value: "default" },
    {
      name: "Deposit Value By Month",
      value: "total-deposit-distribution-by-month",
    },
    {
      name: "Value Of Deposit By Type",
      value: "total-deposit-distribution-by-type",
    },
    {
      name: "Total And Average Deposits",
      value: "totalAndAverageDeposit",
    },
  ];
  withdrawalExportOptions = [
    {
      name: "All Pages",
      value: "default",
    },
    {
      name: "Total And Average Withdrawals",
      value: "totalAndAverageWithdrawal",
    },
    {
      name: "Total Withdrawals By Month",
      value: "total-withdrawal-distribution-by-month",
    },
  ];
  handleTandAChange = (date) => {
    this.setState({
      totalAndAverageDepositDate: date,
    });
  };
  handleWithdrawalChange = (date) => {
    this.setState({
      totalAndAverageWithdrawalDate: date,
    });
  };
  render() {
    const {
      match: { path },
    } = this.props;
    const { pathname } = this.props.location;

    const {
      depositByMonthDate,
      depositByTypeDate,
      withdrawalsDate,
      totalAndAverageDepositDate,
      totalAndAverageWithdrawalDate,
      viewToExport,
      withdrawalView,
    } = this.state;
    const [mStartDate, mEndDate] = depositByMonthDate;
    const [tStartDate, tEndDate] = depositByTypeDate;
    const [startDate, endDate] = totalAndAverageDepositDate;
    const [wStartDate, wEndDate] = totalAndAverageWithdrawalDate;

    const isDeposits = pathname.includes("deposits");
    const isWithdrawals = pathname.includes("withdrawals");

    return (
      <div className="setup-inner">
        <div className="setup-inner__top">
          <ul className="setup-inner__nav">
            <li className="setup-nav__item">
              <NavLink
                exact
                to={{ pathname: `${path}/deposits` }}
                activeClassName="setup-nav__item--active"
              >
                Deposits
              </NavLink>
            </li>
            <li className="setup-nav__item">
              <NavLink
                to={{ pathname: `${path}/withdrawals` }}
                activeClassName="setup-nav__item--active"
              >
                Withdrawals
              </NavLink>
            </li>
          </ul>

          {isDeposits && (
            <div className="right-dropdown">
              <AnalyticsDateInput
                value={startDate}
                isRange={true}
                startDate={startDate}
                endDate={endDate}
                handleChange={(date) => this.handleTandAChange(date)}
              />
              <div className="transaction-reload">
                <ReloadButton />
              </div>
              <ExportDropdown
                module="transactions"
                status="deposits"
                excludeCSV
                includeJpeg
                downloadHandler={(...args) =>
                  this.props.exportDeposits(
                    dayjs(tStartDate).format("YYYY-MM-DD"),
                    dayjs(tEndDate).format("YYYY-MM-DD"),
                    dayjs(mStartDate).format("YYYY-MM-DD"),
                    dayjs(mEndDate).format("YYYY-MM-DD"),
                    dayjs(startDate).format("YYYY-MM-DD"),
                    dayjs(endDate).format("YYYY-MM-DD"),
                    viewToExport,
                    ...args
                  )
                }
                analyticsOptions={this.depositExportOptions}
                onAnalyticsViewSelect={(view) =>
                  this.setState({ viewToExport: view })
                }
              />
            </div>
          )}
          {isWithdrawals && (
            <div className="right-dropdown">
              <AnalyticsDateInput
                value={wStartDate}
                isRange={true}
                startDate={wStartDate}
                endDate={wEndDate}
                handleChange={(date) => this.handleWithdrawalChange(date)}
              />
              <div className="transaction-reload">
                <ReloadButton />
              </div>
              <ExportDropdown
                module="transaction-analytics"
                status="withdrawals"
                excludeCSV
                includeJpeg
                downloadHandler={(...args) =>
                  this.props.exportWithdrawals(
                    dayjs(wStartDate).format("YYYY-MM-DD"),
                    dayjs(wEndDate).format("YYYY-MM-DD"),
                    dayjs(withdrawalsDate[0]).format("YYYY-MM-DD"),
                    dayjs(withdrawalsDate[1]).format("YYYY-MM-DD"),

                    withdrawalView,
                    ...args
                  )
                }
                analyticsOptions={this.withdrawalExportOptions}
                onAnalyticsViewSelect={(view) =>
                  this.setState({ withdrawalView: view })
                }
              />
            </div>
          )}
        </div>
        <div>
          <Switch>
            <Route exact path={path}>
              <Redirect to={{ pathname: `${path}/deposits` }} />
            </Route>
            <Route exact path={`${path}/deposits`}>
              <Deposits
                monthDates={depositByMonthDate}
                typeDates={depositByTypeDate}
                totalAndAverageDate={totalAndAverageDepositDate}
                handleMonthDateChange={(date) =>
                  this.setState({ depositByMonthDate: date })
                }
                handleTypeDateChange={(date) =>
                  this.setState({ depositByTypeDate: date })
                }
              />
            </Route>
            <Route exact path={`${path}/withdrawals`}>
              <Withdrawals
                dates={withdrawalsDate}
                handleDateChange={(date) =>
                  this.setState({ withdrawalsDate: date })
                }
                handleTotalAndAVerageDate={(date) =>
                  this.setState({
                    totalAndAverageWithdrawalDate: date,
                  })
                }
                totalAndAverageWithdrawalDate={totalAndAverageWithdrawalDate}
              />
            </Route>
          </Switch>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    exportDeposits: (
      mStartDate,
      mEndDate,
      tStartDate,
      tEndDate,
      startDate,
      endDate,
      viewToExport,
      module,
      format
    ) =>
      dispatch(
        exportDeposits(
          module,
          format,
          viewToExport,
          startDate,
          endDate,
          mStartDate,
          mEndDate,
          tStartDate,
          tEndDate
        )
      ),
    exportWithdrawals: (
      startDate,
      endDate,
      mStartDate,
      mEndDate,
      viewToExport,
      module,
      format
    ) =>
      dispatch(
        exportWithdrawals(
          module,
          format,
          viewToExport,
          startDate,
          endDate,
          mStartDate,
          mEndDate
        )
      ),
  };
};

export default withRouter(
  connect(null, mapDispatchToProps)(TransactionAnalytics)
);
