import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import dayjs from "dayjs";
import {
  fetchReversals,
  approveReversal,
  rejectReversal,
  searchReversals,
  multipleApprove,
  multipleReject,
} from "@/store/Transactions/reversalsActions";
import actionTypes from "@/store/Transactions/actionTypes";
import { getActionLoadingState } from "@/store/selectors";
import { currency } from "@/utils";
import RightPanel from "@/components/RightPanel";
import Emptystate from "@/components/Emptystate";
import ConfirmationBox from "@/components/ConfirmationBox";
import Pagination from "@/components/Pagination";
import SearchBox from "@/components/SearchBox";
import MultipleApprovalDropdown from "@/components/MultipleApprovalDropdown";
import "../Reports/CTR/style.scss";

class Reversals extends Component {
  state = {
    open: false,
    previewMode: false,
    reversals: [],
    status: "pending",
    searchTerm: "",
    selectedRowsIds: [],
  };

  componentDidMount() {
    const { type } = this.props.match.params;
    this.getReversals(1, 10, type, this.state.status);
  }

  componentDidUpdate(prevProps, prevState) {
    const { type } = this.props.match.params;
    const { status, searchTerm, shouldSearch } = this.state;
    if (
      this.props.location.pathname !== prevProps.location.pathname ||
      status !== prevState.status
    ) {
      this.setState({ selectedRowsIds: [] }, () =>
        this.getReversals(1, 10, type, status)
      );
    }
    if (searchTerm !== prevState.searchTerm && shouldSearch) {
      this.searchReversals(1, 10, type, status, searchTerm);
    }
  }

  getReversals = (page, limit, type, status, searchTerm) => {
    if (searchTerm)
      return this.searchReversals(page, limit, type, status, searchTerm);
    this.props.fetchReversals(page, limit, type, status).then((reversals) => {
      const data =
        reversals.data.transactions ||
        reversals.data.investments ||
        reversals.data.withdrawals;
      this.setPageData(data);
    });
  };

  searchReversals = (page, limit, type, status, searchTerm) => {
    if (!searchTerm) return this.getReversals(1, 10, type, status);
    this.props
      .searchReversals(page, limit, type, status, searchTerm)
      .then((result) => {
        const data =
          result.data.transactions ||
          result.data.deposits ||
          result.data.withdrawals ||
          result.data.investments;
        this.setPageData(data, searchTerm, false);
      });
  };

  setPageData = (
    { lastPage, perPage, page, total, data: reversals },
    searchTerm,
    shouldSearch
  ) => {
    this.setState({
      reversals,
      lastPage,
      perPage,
      page,
      total,
      searchTerm,
      shouldSearch,
    });
  };

  onStatusChange = (e) => {
    this.setState({ status: e.target.value });
  };

  onInvestmentRowClick = (e, investment) => {
    if (!["BUTTON", "IMG", "LI", "INPUT"].includes(e.target.nodeName)) {
      this.setState({
        previewMode: true,
        previewItem: {
          "Reference Number": investment.reference,
          Category: investment.service?.name,
          "Full Name": `${investment.user.firstName} ${investment.user.lastName}`,
          "Target Amount": `₦${currency(investment.targetAmount)}`,
          Frequency: investment.frequency,
          "Payment Amount": `₦${currency(investment.installment)}`,
          "Date Created": dayjs(investment.created_at).format("D MMM YYYY"),
          "Start Date": dayjs(investment.startDate).format("D MMM YYYY"),
          "End Date": investment.endDate
            ? dayjs(investment.endDate).format("D MMM YYYY")
            : "N/A",
          "Reason for Reversal":
            investment.reasons[0]?.initiatorMessage || "--",
        },
        open: true,
      });
    }
  };

  renderInvestments = (investments) => {
    const showActions = this.state.status === "pending";
    return (
      <table className="custum-table">
        <tbody>
          <tr>
            {showActions && <th></th>}
            <th>Reference</th>
            <th>Category</th>
            <th>Full Name</th>
            <th>Target Amt.</th>
            <th>Frequency</th>
            <th>Payment Amt.</th>
            <th>Date Created</th>
            <th>Reason</th>
            {showActions && <th className="actions-column">Actions</th>}
          </tr>
          {investments.map((investment) => (
            <tr
              key={investment.id}
              onClick={(e) => this.onInvestmentRowClick(e, investment)}
              className="cursor-pointer"
            >
              {showActions && (
                <td className="row-checkbox">
                  <input
                    type="checkbox"
                    onChange={(e) => this.onCheckRow(e, investment.id)}
                  />
                </td>
              )}
              <td
                style={{
                  whiteSpace: "normal",
                  maxWidth: "24.5rem",
                }}
              >
                {investment.reference}
              </td>
              <td>{investment.service?.name}</td>
              <td className="text-capitalize">{`${investment.user.firstName} ${investment.user.lastName}`}</td>
              <td>{`₦${currency(investment.targetAmount)}`}</td>
              <td>{investment.frequency}</td>
              <td>{`₦${currency(investment.installment)}`}</td>
              <td>{dayjs(investment.created_at).format("D MMM YYYY")}</td>
              <td>{investment.reasons[0]?.initiatorMessage || "--"}</td>
              {showActions && (
                <td className="custum-table__button-cell">
                  <button
                    className="custum-table__button-blue"
                    onClick={() =>
                      this.setConfirmationContext("approve", investment.id)
                    }
                  >
                    Approve
                  </button>
                  <button
                    className="custum-table__button-plain"
                    onClick={() =>
                      this.setConfirmationContext("reject", investment.id)
                    }
                  >
                    Reject
                  </button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  onDepositsRowClick = (e, deposit) => {
    if (!["BUTTON", "IMG", "LI", "INPUT"].includes(e.target.nodeName)) {
      this.setState({
        previewMode: true,
        previewItem: {
          Reference: deposit.reference,
          "User ID": deposit.user.id,
          "Full Name": `${deposit.user.firstName} ${deposit.user.lastName}`,
          Amount: `₦${currency(deposit.amount)}`,
          "Bank Name": "N/A",
          "Account Number": "N/A",
          "Date & Time": dayjs(deposit.paidAt).format("D MMM YYYY hh:m:ss"),
          "Reason for Reversal": deposit.reasons[0]?.initiatorMessage || "--",
        },
        open: true,
      });
    }
  };

  renderDeposits = (deposits) => {
    const showActions = this.state.status === "pending";
    return (
      <table className="custum-table">
        <tbody>
          <tr>
            {showActions && <th></th>}
            <th>Reference</th>
            <th>User ID</th>
            <th>Full Name</th>
            <th>Amount</th>
            <th>Bank Name</th>
            <th>Account Number</th>
            <th>Date & Time</th>
            <th>Reason</th>
            {showActions && <th className="actions-column">Actions</th>}
          </tr>
          {deposits.map((deposit) => (
            <tr
              key={deposit.id}
              onClick={(e) => this.onDepositsRowClick(e, deposit)}
              className="cursor-pointer"
            >
              {showActions && (
                <td className="row-checkbox">
                  <input
                    type="checkbox"
                    onChange={(e) => this.onCheckRow(e, deposit.id)}
                  />
                </td>
              )}
              <td style={{ maxWidth: "24rem", whiteSpace: "normal" }}>
                {deposit.reference}
              </td>
              <td>{deposit.user?.id}</td>
              <td className="text-capitalize">{`${deposit.user?.firstName} ${deposit.user?.lastName}`}</td>
              <td>{`₦${currency(deposit.amount)}`}</td>
              <td>N/A</td>
              <td>N/A</td>
              <td>{dayjs(deposit.paidAt).format("D MMM YYYY hh:m:ss")}</td>
              <td>{deposit.reasons[0]?.initiatorMessage || "--"}</td>
              {showActions && (
                <td className="custum-table__button-cell">
                  <button
                    className="custum-table__button-blue"
                    onClick={() =>
                      this.setConfirmationContext("approve", deposit.id)
                    }
                  >
                    Approve
                  </button>
                  <button
                    className="custum-table__button-plain"
                    onClick={() =>
                      this.setConfirmationContext("reject", deposit.id)
                    }
                  >
                    Reject
                  </button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  onTerminationRowClick = (e, termination) => {
    if (!["BUTTON", "IMG", "LI", "INPUT"].includes(e.target.nodeName)) {
      this.setState({
        previewMode: true,
        previewItem: {
          Reference: termination.reference,
          "Termed investment REF": termination.investment.reference,
          "Termed investment name": termination.investment.title,
          "Customer name": `${termination.user.firstName} ${termination.user.lastName}`,
          "Start date": dayjs(termination.investment.startDate).format(
            "D MMM YYYY"
          ),
          "Maturity date": termination.investment.endDate
            ? dayjs(termination.investment.endDate).format("D MMM YYYY")
            : "N/A",
          "Termination date": dayjs(termination.created_at).format(
            "D MMM YYYY"
          ),
          "Termination amount": `₦${currency(termination.amount)}`,
          "Accrued interest": `₦${currency(
            termination.investment.accruedInterest
          )}`,
          "Penalty amount": `₦${currency(termination.fees)}`,
          "Balance on investment": `₦${currency(
            termination.investment.balance
          )}`,
          "Part/Full liquidation": `${termination.liquidation} liquidation`,
          "Reason for Reversal":
            termination.reasons[0]?.initiatorMessage || "--",
        },
        open: true,
      });
    }
  };

  onCheckRow = (e, rowId) => {
    const selectedRows = [...this.state.selectedRowsIds];
    if (e.target.checked) {
      selectedRows.push(rowId);
    } else {
      const uncheckedRowIndex = selectedRows.findIndex((id) => id === rowId);
      selectedRows.splice(uncheckedRowIndex, 1);
    }
    this.setState({ selectedRowsIds: selectedRows });
  };

  renderTerminations = (terminations) => {
    const showActions = this.state.status === "pending";
    return (
      <table className="custum-table">
        <tbody>
          <tr>
            {showActions && <th></th>}
            <th>Reference</th>
            <th>Termed Investment Ref</th>
            <th>Termed Investment Name</th>
            <th>Customer Name</th>
            <th>Start Date</th>
            <th>Maturity Date</th>
            <th>Reason</th>
            {showActions && <th className="actions-column">Actions</th>}
          </tr>
          {terminations.map((termination) => (
            <tr
              key={termination.id}
              onClick={(e) => this.onTerminationRowClick(e, termination)}
              className="cursor-pointer"
            >
              {showActions && (
                <td className="row-checkbox">
                  <input
                    type="checkbox"
                    onChange={(e) => this.onCheckRow(e, termination.id)}
                  />
                </td>
              )}
              <td style={{ whiteSpace: "normal", maxWidth: "24rem" }}>
                {termination?.reference}
              </td>
              <td>{termination.investment?.reference}</td>
              <td style={{ whiteSpace: "normal", maxWidth: "24rem" }}>
                {termination.investment?.title}
              </td>
              <td className="text-capitalize">{`${termination.user?.firstName} ${termination.user?.lastName}`}</td>
              <td>
                {dayjs(termination.investment?.startDate).format("D MMM YYYY")}
              </td>
              <td>
                {termination.investment?.endDate
                  ? dayjs(termination.investment?.endDate).format("D MMM YYYY")
                  : "N/A"}
              </td>
              <td>{termination.reasons[0]?.initiatorMessage || "--"}</td>
              {showActions && (
                <td className="custum-table__button-cell">
                  <button
                    className="custum-table__button-blue"
                    onClick={() =>
                      this.setConfirmationContext("approve", termination.id)
                    }
                  >
                    Approve
                  </button>
                  <button
                    className="custum-table__button-plain"
                    onClick={() =>
                      this.setConfirmationContext("reject", termination.id)
                    }
                  >
                    Reject
                  </button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  onWithdrawalRowClick = (e, withdrawal) => {
    if (!["BUTTON", "IMG", "LI", "INPUT"].includes(e.target.nodeName)) {
      this.setState({
        previewMode: true,
        previewItem: {
          "Full Name": `${withdrawal.user?.firstName} ${withdrawal.user.lastName}`,
          Amount: withdrawal.amount,
          "Bank Code": withdrawal.user?.bankInfo.bankCode,
          "Account Number": withdrawal.user?.bankInfo.accountNumber,
          "Date & Time": dayjs(withdrawal.created_at).format(
            "D MMM YYYY hh:m:s"
          ),
          "Reason for Reversal":
            withdrawal.reasons[0]?.initiatorMessage || "--",
        },
        open: true,
      });
    }
  };

  renderWithdrawals = (withdrawals) => {
    const showActions = this.state.status === "pending";
    return (
      <table className="custum-table">
        <tbody>
          <tr>
            {showActions && <th></th>}
            <th>Full Name</th>
            <th>Amount</th>
            <th>Bank Code</th>
            <th>Account Number</th>
            <th>Date & Time</th>
            <th>Reason</th>
            {showActions && <th className="actions-column">Actions</th>}
          </tr>
          {withdrawals.map((withdrawal) => (
            <tr
              key={withdrawal.id}
              onClick={(e) => this.onWithdrawalRowClick(e, withdrawal)}
              className="cursor-pointer"
            >
              {showActions && (
                <td className="row-checkbox">
                  <input
                    type="checkbox"
                    onChange={(e) => this.onCheckRow(e, withdrawal.id)}
                  />
                </td>
              )}
              <td className="text-capitalize">{`${withdrawal.user?.firstName} ${withdrawal.user?.lastName}`}</td>
              <td>{`₦${currency(withdrawal.amount)}`}</td>
              <td>{withdrawal.user?.bankInfo?.bankCode}</td>
              <td>{withdrawal.user?.bankInfo?.accountNumber}</td>
              <td>
                {dayjs(withdrawal.created_at).format("D MMM YYYY hh:m:s")}
              </td>
              <td style={{ maxWidth: "24rem", whiteSpace: "normal" }}>
                {withdrawal.reasons[0]?.initiatorMessage || "--"}
              </td>
              {showActions && (
                <td className="custum-table__button-cell">
                  <button
                    className="custum-table__button-blue"
                    onClick={() =>
                      this.setConfirmationContext("approve", withdrawal.id)
                    }
                  >
                    Approve
                  </button>
                  <button
                    className="custum-table__button-plain"
                    onClick={() =>
                      this.setConfirmationContext("reject", withdrawal.id)
                    }
                  >
                    Reject
                  </button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  setConfirmationContext = (context, transactionId, isMultipleApproval) => {
    this.setState({
      confirmationContext: context,
      confirmationSuccess: false,
      transactionId,
      transactionType: this.props.match.params.type,
      isMultipleApproval,
    });
  };

  confirmationData = () => {
    const { isMultipleApproval, selectedRowsIds, transactionType } = this.state;
    // addS below is used to know whether to display the message in plural or singular form
    const addS = isMultipleApproval && selectedRowsIds.length > 1;
    return {
      approve: {
        title: `Reverse ${
          addS ? transactionType : transactionType?.slice(0, -1)
        }`,
        question: `Are you sure you want to reverse ${
          addS ? "these" : "this"
        } ${addS ? transactionType : transactionType?.slice(0, -1)}?`,
        action: () => this.approveReversal(isMultipleApproval),
      },
      reject: {
        title: `Reason for rejecting reversal${addS ? "s" : ""}`,
        requiresReason: true,
        action: (reason) => this.rejectReversal(reason, isMultipleApproval),
      },
    };
  };

  approveReversal = (isMultiple) => {
    const { transactionId, transactionType, selectedRowsIds } = this.state;
    if (isMultiple) {
      this.props.multipleApprove(selectedRowsIds, transactionType).then(() => {
        this.removeMultipleFromList(selectedRowsIds);
      });
    } else {
      this.props.approveReversal(transactionId, transactionType).then(() => {
        this.removeItemFromList(transactionId);
      });
    }
  };

  rejectReversal = (reason, isMultiple) => {
    const { transactionId, transactionType, selectedRowsIds } = this.state;
    if (isMultiple) {
      this.props
        .multipleReject(selectedRowsIds, transactionType, reason)
        .then(() => {
          this.removeMultipleFromList(selectedRowsIds);
        });
    } else {
      this.props
        .rejectReversal(transactionId, transactionType, reason)
        .then(() => {
          this.removeItemFromList(transactionId);
        });
    }
  };

  removeMultipleFromList = (selectedRowsIds) => {
    const unchangedItems = this.state.reversals.filter(
      (data) => !selectedRowsIds.includes(data.id)
    );
    this.setState({
      reversals: unchangedItems,
      selectedRowsIds: [],
      confirmationSuccess: true,
      total: this.state.total - selectedRowsIds.length,
    });
  };

  removeItemFromList = (transactionId) => {
    const allReversals = [...this.state.reversals];
    const reversalIndex = allReversals.findIndex(
      (reversal) => reversal.id === transactionId
    );
    allReversals.splice(reversalIndex, 1);
    this.setState({
      reversals: allReversals,
      confirmationSuccess: true,
      total: this.state.total - 1,
    });
  };

  renderReversals = () => {
    const { type } = this.props.match.params;
    const typeActions = {
      deposits: this.renderDeposits,
      terminations: this.renderTerminations,
      investments: this.renderInvestments,
      withdrawals: this.renderWithdrawals,
    };
    return typeActions[type](this.state.reversals);
  };

  renderPreview = () => {
    const { previewItem } = this.state;
    return Object.keys(previewItem).map((field) => (
      <div className="panel-info__row" key={field}>
        <span className="panel-info__field">{field}</span>
        <span className="panel-info__value">{previewItem[field]}</span>
      </div>
    ));
  };

  closePanel = (e) => {
    e.preventDefault();
    this.setState({
      open: false,
      previewMode: false,
    });
  };

  render() {
    const {
      match: {
        params: { type },
      },
      fetching,
      pageRequest,
    } = this.props;
    const {
      previewMode,
      reversals,
      confirmationContext,
      confirmationSuccess,
      lastPage,
      perPage,
      page,
      total,
      status,
      searchTerm,
    } = this.state;
    const prompt = this.confirmationData()[confirmationContext];
    const showMultiAprrovalDropdown = this.state.selectedRowsIds.length > 0;

    return (
      <div className="setup-inner">
        <RightPanel open={this.state.open} onClose={this.closePanel}>
          <h1 className="panel-heading">Reversal Details</h1>
          <div className="panel-info">
            {previewMode && this.renderPreview()}
            <button className="long-button" onClick={this.closePanel}>
              Close
            </button>
          </div>
        </RightPanel>
        <ConfirmationBox
          open={confirmationContext}
          closeHandler={() => this.setState({ confirmationContext: "" })}
          success={confirmationSuccess}
          title={prompt?.title}
          question={prompt?.question}
          action={prompt?.action}
          loading={pageRequest}
          requiresReason={prompt?.requiresReason}
        />
        <div className="setup-inner__top">
          <ul className="setup-inner__nav">
            <li className="setup-nav__item">
              <NavLink
                to={{ pathname: "withdrawals" }}
                isActive={() => type === "withdrawals"}
                activeClassName="setup-nav__item--active"
              >
                Withdrawals
              </NavLink>
            </li>
            <li className="setup-nav__item">
              <NavLink
                to={{ pathname: "deposits" }}
                isActive={() => type === "deposits"}
                activeClassName="setup-nav__item--active"
              >
                Deposits
              </NavLink>
            </li>
            <li className="setup-nav__item">
              <NavLink
                to={{ pathname: "investments" }}
                isActive={() => type === "investments"}
                activeClassName="setup-nav__item--active"
              >
                Investments
              </NavLink>
            </li>
            <li className="setup-nav__item">
              <NavLink
                to={{ pathname: "terminations" }}
                isActive={() => type === "terminations"}
                activeClassName="setup-nav__item--active"
              >
                Terminations
              </NavLink>
            </li>
          </ul>
          <SearchBox
            placeholder="Search"
            handleSearch={(searchTerm) =>
              this.setState({ searchTerm, shouldSearch: true })
            }
            isActiveSearch={Boolean(searchTerm)}
            key={this.props.location.pathname + status}
          />
          {status === "pending" && showMultiAprrovalDropdown && (
            <MultipleApprovalDropdown
              approve={() => this.setConfirmationContext("approve", null, true)}
              reject={() => this.setConfirmationContext("reject", null, true)}
            />
          )}
          <select
            className="setup-nav__dropdown"
            onChange={this.onStatusChange}
            value={status}
          >
            <option value="pending">Pending</option>
            <option value="active">Approved</option>
          </select>
        </div>
        {fetching ? (
          <div className="text-center text-primary">
            <div className="spinner-border" role="status"></div>
          </div>
        ) : (
          <div className="position-relative">
            {!reversals.length ? (
              <Emptystate
                title={`${searchTerm ? "No Result Found" : "No Reversal"}`}
                icon={require("@/assets/icons/info.svg").default}
              />
            ) : (
              <>
                <Pagination
                  totalPages={lastPage}
                  page={page}
                  limit={perPage}
                  changePageHandler={(page, limit) =>
                    this.getReversals(page, limit, type, status, searchTerm)
                  }
                />
                <div className="table-overflow">
                  <div className="setup-inner__main setup-inner__expand">
                    {this.renderReversals()}
                  </div>
                  <div className="data-count">
                    Showing
                    <span className="font-weight-bold mx-2">{`${reversals.length} of ${total}`}</span>
                    Reversals
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fetching: getActionLoadingState(state, actionTypes.GET_REVERSALS_REQUEST),
    pageRequest: getActionLoadingState(
      state,
      actionTypes.REVERSALS_PAGE_REQUEST
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchReversals: (page, limit, type, status) =>
      dispatch(fetchReversals(page, limit, type, status)),
    searchReversals: (page, limit, type, status, searchTerm) =>
      dispatch(searchReversals(page, limit, type, status, searchTerm)),
    approveReversal: (id, type) => dispatch(approveReversal(id, type)),
    rejectReversal: (id, type, reason) =>
      dispatch(rejectReversal(id, type, reason)),
    multipleApprove: (ids, type) => dispatch(multipleApprove(ids, type)),
    multipleReject: (ids, type, message) =>
      dispatch(multipleReject(ids, type, message)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Reversals)
);
