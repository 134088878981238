import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import dayjs from "dayjs";
import {
  fetchReport,
  searchReport,
  exportLiabilityTracker,
} from "@/store/reports/actions";
import actionTypes from "@/store/reports/actionTypes";
import { getActionLoadingState } from "@/store/selectors";
import Emptystate from "@/components/Emptystate";
import Pagination from "@/components/Pagination";
import SearchBox from "@/components/SearchBox";
import ExportDropdown from "@/components/ExportDropdown";
import { currency } from "@/utils";
import "../Reports/CTR/style.scss";

class LiabilityTracker extends Component {
  state = {
    liability: [],
    page: 1,
    searchTerm: "",
    shouldSearch: false,
  };

  componentDidMount() {
    const { status } = this.props.match.params;
    this.getLiability(1, 10, status);
  }

  componentDidUpdate(prevProps, prevState) {
    const { status } = this.props.match.params;
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ searchTerm: "", shouldSearch: false }, () =>
        this.getLiability(1, 10, status)
      );
    }
    if (
      this.state.searchTerm !== prevState.searchTerm &&
      this.state.shouldSearch
    ) {
      if (!this.state.searchTerm) return this.getLiability(1, 10, status);
      this.searchLiability(this.state.searchTerm, 1, 10, status);
    }
  }

  getLiability = (page, limit, status) => {
    if (this.state.searchTerm)
      return this.searchLiability(this.state.searchTerm, page, limit);
    this.props.fetchLiability(page, limit, status).then(({ data }) => {
      this.setState({
        lastPage: Math.ceil(data.total.value / limit),
        perPage: limit,
        page,
        total: data.total.value,
        liability: data.hits,
        searchTerm: "",
      });
    });
  };

  searchLiability = (searchTerm, page, limit, status) => {
    if (!searchTerm) return this.getLiability(1, 10, status);
    this.props.searchLiability(searchTerm, page, limit).then(({ data }) => {
      this.setState({
        total: data.total.value,
        perPage: limit,
        page,
        lastPage: Math.ceil(data.total.value / limit),
        liability: data.hits,
        shouldSearch: false,
      });
    });
  };

  renderLiability = () => {
    return (
      <table className="custum-table custum-table__long">
        <tbody>
          <tr>
            <th>Termed investment ref</th>
            <th>Termed investment name</th>
            <th>Customer name</th>
            <th>Date/Time</th>
            <th>Start date</th>
            <th>Maturity date</th>
            <th>Target Amount</th>
            <th>Payment amount</th>
            <th>Current Value</th>
            <th>Interest rate</th>
            <th>Accrued interest</th>
            <th>% of target</th>
            <th>Category</th>
          </tr>
          {this.state.liability.map((data) => (
            <tr key={data._id}>
              <td style={{ whiteSpace: "normal", maxWidth: "24rem" }}>
                {data._source.investment_ref}
              </td>
              <td style={{ whiteSpace: "normal", maxWidth: "24rem" }}>
                {data._source.investment_name}
              </td>
              <td style={{ whiteSpace: "normal", maxWidth: "24rem" }}>
                {data._source.customer_name}
              </td>
              <td>
                {dayjs(data._source.logged_at).format("D MMM YYYY hh:m:ss")}
              </td>
              <td>{dayjs(data._source.start_date).format("D MMM YYYY")}</td>
              <td>
                {data._source.maturity_date
                  ? dayjs(data._source.maturity_date).format("D MMM YYYY")
                  : "N/A"}
              </td>
              <td className="font-heading">{`₦${currency(
                data._source.target_amount
              )}`}</td>
              <td className="font-heading">{`₦${currency(
                data._source.payment_amount
              )}`}</td>
              <td className="font-heading">{`₦${currency(
                data._source.current_value
              )}`}</td>
              <td>{data._source.interest_rate}%</td>
              <td className="font-heading">{`₦${currency(
                data._source.accrued_interest
              )}`}</td>
              <td>{data._source.percentage_of_interest}%</td>
              <td>{data._source.category}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  render() {
    const {
      match: {
        params: { status },
      },
      fetching,
    } = this.props;
    const { liability, lastPage, perPage, page, total, searchTerm } =
      this.state;

    return (
      <div className="setup-inner">
        <div className="setup-inner__top">
          <ul className="setup-inner__nav">
            <li className="setup-nav__item">
              <NavLink
                to={{ pathname: "recent", state: { pageTitle: "Reports" } }}
                isActive={() => status === "recent"}
                activeClassName="setup-nav__item--active"
              >
                Recent
              </NavLink>
            </li>
            <li className="setup-nav__item">
              <NavLink
                to={{ pathname: "all", state: { pageTitle: "Reports" } }}
                isActive={() => status === "all"}
                activeClassName="setup-nav__item--active"
              >
                All Liability Tracker
              </NavLink>
            </li>
          </ul>
          <SearchBox
            placeholder="Search"
            handleSearch={(searchTerm) =>
              this.setState({ searchTerm, shouldSearch: true })
            }
            isActiveSearch={Boolean(searchTerm)}
            key={status}
          />
          {liability.length > 0 && (
            <ExportDropdown
              module="liabilty-tracker"
              status={status}
              page={page}
              limit={perPage}
              downloadHandler={this.props.exportLiabilityTracker}
              hasMoreThanOnePage={lastPage > 1}
            />
          )}
        </div>
        {fetching ? (
          <div className="text-center text-primary">
            <div className="spinner-border" role="status"></div>
          </div>
        ) : (
          <div className="position-relative">
            {!liability.length ? (
              <Emptystate
                title={`${
                  searchTerm ? "No Result Found" : "No Recent Records"
                }`}
                icon={require("@/assets/icons/info.svg").default}
              />
            ) : (
              <>
                <Pagination
                  totalPages={lastPage}
                  page={page}
                  limit={perPage}
                  changePageHandler={(page, limit) =>
                    this.getLiability(page, limit, status)
                  }
                />
                <div className="table-overflow">
                  <div className="setup-inner__main setup-inner__expand">
                    {this.renderLiability()}
                  </div>
                  <div className="data-count">
                    Showing
                    <span className="font-weight-bold mx-2">{`${liability.length} of ${total}`}</span>
                    Records
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fetching: getActionLoadingState(state, actionTypes.GET_REPORTS_REQUEST),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchLiability: (offset, limit, path) =>
      dispatch(fetchReport(offset, limit, path, "liability")),
    searchLiability: (searchTerm, page, limit) =>
      dispatch(searchReport(searchTerm, "liability", page, limit)),
    exportLiabilityTracker: (module, format, status, page, limit) =>
      dispatch(exportLiabilityTracker(module, format, status, page, limit)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LiabilityTracker)
);
