import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import dayjs from "dayjs";
import {
  fetchDeposits,
  updateDeposit,
  exportDeposits,
} from "@/store/Transactions/depositsAction";
import { disableUser } from "@/store/user/actions";
import actionTypes from "@/store/Transactions/actionTypes";
import userActionTypes from "@/store/user/actionTypes";
import { getActionLoadingState } from "@/store/selectors";
import { currency, hasPermission } from "@/utils";
import RightPanel from "@/components/RightPanel";
import Dropdown from "@/components/DropdownMenu";
import Emptystate from "@/components/Emptystate";
import ConfirmationBox from "@/components/ConfirmationBox";
import Pagination from "@/components/Pagination";
import SearchBox from "@/components/SearchBox";
import ExportDropdown from "@/components/ExportDropdown";

class Deposits extends Component {
  state = {
    open: false,
    previewMode: false,
    deposits: [],
    searchTerm: "",
    shouldSearch: false,
  };

  componentDidMount() {
    const { status } = this.props.match.params;
    this.getDeposits(1, 10, status);
  }

  componentDidUpdate(prevProps, prevState) {
    const { status } = this.props.match.params;
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.getDeposits(1, 10, status);
    }
    if (
      this.state.searchTerm !== prevState.searchTerm &&
      this.state.shouldSearch
    ) {
      this.getDeposits(1, 10, status, this.state.searchTerm);
    }
  }

  getDeposits = (page, limit, status, searchTerm) => {
    this.props
      .fetchDeposits(page, limit, status, searchTerm)
      .then((deposits) => {
        this.setPageData(deposits, searchTerm);
      });
  };

  setPageData = (
    { lastPage, perPage, page, total, data: deposits },
    searchTerm
  ) => {
    this.setState({
      deposits,
      lastPage,
      perPage,
      page,
      total,
      searchTerm,
      shouldSearch: false,
    });
  };

  dropdownmenus = (depositId, reference, userId) => {
    const {
      match: {
        params: { status },
      },
      permissions,
    } = this.props;
    const canHold = hasPermission(permissions, "hold_deposit");
    const canReverse = hasPermission(permissions, "reverse_deposit");
    const canDisableUser = hasPermission(permissions, "disable_user");
    return [
      status !== "held" && canReverse
        ? {
            name: "Reverse deposit",
            handler: () =>
              this.setConfirmationContext("initiate-refund", depositId, userId),
          }
        : null,
      status !== "held" && canHold
        ? {
            name: "Hold deposit",
            handler: () =>
              this.setConfirmationContext("hold", depositId, userId),
          }
        : null,
      status === "held"
        ? {
            name: "Release deposit",
            handler: () =>
              this.setConfirmationContext(
                "release",
                depositId,
                userId,
                reference
              ),
          }
        : null,
      canDisableUser
        ? {
            name: "Disable user",
            handler: () =>
              this.setConfirmationContext("disableUser", depositId, userId),
          }
        : null,
    ].filter(Boolean);
  };

  setConfirmationContext = (context, depositId, userId, reference) => {
    this.setState({
      confirmationContext: context,
      confirmationSuccess: false,
      depositId,
      reference,
      userId,
    });
  };

  confirmationData = () => ({
    "initiate-refund": {
      title: "Reason for reversing deposit",
      question: "",
      action: (reversalReason) =>
        this.updateDeposit("initiate-refund", reversalReason),
      requiresReason: true,
    },
    hold: {
      title: "Reason for holding deposit",
      action: (reason) => this.updateDeposit("hold", reason),
      requiresReason: true,
    },
    release: {
      title: "Release deposit",
      question: "Are you sure you want to release this deposit?",
      action: () => this.updateDeposit("release"),
      requiresReason: false,
    },
    disableUser: {
      title: "Reason for disabling user",
      action: (reason) => this.disableUser(reason),
      requiresReason: true,
    },
  });

  updateDeposit = (status, reversalReason) => {
    const { depositId, deposits, total } = this.state;
    this.props.updateDeposit(depositId, status, reversalReason).then(() => {
      if (status !== "initiate-refund") {
        const allDeposits = [...deposits];
        const depositIndex = allDeposits.findIndex(
          (deposit) => deposit.id === depositId
        );
        allDeposits.splice(depositIndex, 1);
        this.setState({
          deposits: allDeposits,
          confirmationSuccess: true,
          total: total - 1,
        });
      } else {
        this.setState({ confirmationContext: "" });
      }
    });
  };

  disableUser = (reason) => {
    const { userId } = this.state;
    this.props.disableUser(userId, reason).then(() => {
      this.setState({ confirmationSuccess: true });
    });
  };

  onRowClick = (e, deposit) => {
    if (!["BUTTON", "IMG", "LI"].includes(e.target.nodeName)) {
      let previewItem = {
        "Reference Number": deposit.reference,
        "User ID": deposit.user?.id,
        "Full Name": `${deposit.user?.firstName} ${deposit.user?.lastName}`,
        Amount: `₦${currency(deposit.amount)}`,
        "Bank Name":
          deposit.channel !== "card" ? deposit.user?.nuban?.BankName : "N/A",
        "Account Number":
          deposit.channel !== "card"
            ? deposit.user?.nuban?.accountNumber
            : "N/A",
        "Date & Time": dayjs(deposit.paidAt).format("D MMM YYYY hh:m:ss"),
      };
      if (deposit.channel !== "card") {
        previewItem = {
          ...previewItem,
          "Sender Name": deposit.senderName || "",
          "Sender Bank": deposit.senderBank || "",
          "Sender Account Number": deposit.senderAccountNumber || "",
        };
      }
      this.setState({
        previewMode: true,
        previewItem,
        open: true,
      });
    }
  };

  renderDeposits = () => {
    const { status } = this.props.match.params;
    return (
      <table className="custum-table">
        <tbody>
          <tr>
            <th>Reference Number</th>
            <th>User ID</th>
            <th>Full Name</th>
            <th>Amount</th>
            {status !== "card" && <th>Bank Name</th>}
            {status !== "card" && <th>Account Number</th>}
            <th>Date & Time</th>
            <th></th>
          </tr>
          {this.state.deposits.map((deposit) => (
            <tr
              key={deposit.id}
              onClick={(e) => this.onRowClick(e, deposit)}
              className="cursor-pointer"
            >
              <td
                style={{ whiteSpace: "normal", maxWidth: "24rem" }}
                className="font-medium tracking-wider"
              >
                {deposit.reference}
              </td>
              <td>{deposit.user?.id}</td>
              <td className="text-capitalize">{`${deposit.user?.firstName} ${deposit.user?.lastName}`}</td>
              <td className="font-heading font-medium">{`₦${currency(
                deposit.amount
              )}`}</td>
              {status !== "card" && (
                <td>
                  {deposit.channel !== "card"
                    ? deposit.user?.nuban?.BankName
                    : "N/A"}
                </td>
              )}
              {status !== "card" && (
                <td>
                  {deposit.channel !== "card"
                    ? deposit.user?.nuban?.accountNumber
                    : "N/A"}
                </td>
              )}
              <td>{dayjs(deposit.created_at).format("D MMM YYYY hh:m:ss")}</td>
              <td className="custum-table__ellipsis">
                <Dropdown
                  menu={this.dropdownmenus(
                    deposit?.id,
                    deposit.reference,
                    deposit.user?.id
                  )}
                  arrow={true}
                >
                  <button className="wrapper-button ellipsis">
                    <img
                      src={require("@/assets/icons/flat-ellipsis.svg").default}
                      alt="dropdown"
                    />
                  </button>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  renderPreview = () => {
    const { previewItem } = this.state;
    return Object.keys(previewItem).map((field) => (
      <div className="panel-info__row" key={field}>
        <span className="panel-info__field">{field}</span>
        <span className="panel-info__value">{previewItem[field]}</span>
      </div>
    ));
  };

  closePanel = (e) => {
    e.preventDefault();
    this.setState({
      open: false,
      previewMode: false,
    });
  };

  render() {
    const {
      match: {
        params: { status },
      },
      fetching,
      pageRequest,
      disablingUser,
    } = this.props;
    const {
      previewMode,
      deposits,
      confirmationContext,
      confirmationSuccess,
      lastPage,
      perPage,
      page,
      total,
      searchTerm,
    } = this.state;
    const prompt = this.confirmationData()[confirmationContext];

    return (
      <div className="setup-inner">
        <RightPanel open={this.state.open} onClose={this.closePanel}>
          <h1 className="panel-heading">Deposit Details</h1>
          <div className="panel-info">
            {previewMode && this.renderPreview()}
            <button className="long-button" onClick={this.closePanel}>
              Close
            </button>
          </div>
        </RightPanel>
        <ConfirmationBox
          open={confirmationContext}
          closeHandler={() => this.setState({ confirmationContext: "" })}
          success={confirmationSuccess}
          title={prompt?.title}
          question={prompt?.question}
          action={prompt?.action}
          loading={pageRequest || disablingUser}
          requiresReason={prompt?.requiresReason}
        />
        <div className="setup-inner__top">
          <ul className="setup-inner__nav">
            <li className="setup-nav__item">
              <NavLink
                to={{ pathname: "card", state: { pageTitle: "Transactions" } }}
                isActive={() => status === "card"}
                activeClassName="setup-nav__item--active"
              >
                Card Deposits
              </NavLink>
            </li>
            <li className="setup-nav__item">
              <NavLink
                to={{ pathname: "bank", state: { pageTitle: "Deposits" } }}
                isActive={() => status === "bank"}
                activeClassName="setup-nav__item--active"
              >
                Transfers
              </NavLink>
            </li>
            <li className="setup-nav__item">
              <NavLink
                to={{ pathname: "held", state: { pageTitle: "Transactions" } }}
                isActive={() => status === "held"}
                activeClassName="setup-nav__item--active"
              >
                Held Deposits
              </NavLink>
            </li>
          </ul>
          <SearchBox
            placeholder="Search"
            handleSearch={(searchTerm) =>
              this.setState({ searchTerm, shouldSearch: true })
            }
            isActiveSearch={Boolean(searchTerm)}
            key={status}
          />
          {deposits.length > 0 && (
            <ExportDropdown
              module="deposits"
              status={status}
              page={page}
              limit={perPage}
              downloadHandler={this.props.exportDeposits}
              hasMoreThanOnePage={lastPage > 1}
            />
          )}
        </div>
        {fetching ? (
          <div className="text-center text-primary">
            <div className="spinner-border" role="status"></div>
          </div>
        ) : (
          <div className="position-relative">
            {!deposits.length ? (
              <Emptystate
                title={`${searchTerm ? "No Result Found" : "No Deposits"}`}
                icon={require("@/assets/icons/info.svg").default}
              />
            ) : (
              <>
                <Pagination
                  totalPages={lastPage}
                  page={page}
                  limit={perPage}
                  changePageHandler={(page, limit) =>
                    this.getDeposits(page, limit, status, searchTerm)
                  }
                />
                <div className="table-overflow">
                  <div className="setup-inner__main setup-inner__expand">
                    {this.renderDeposits()}
                  </div>
                  <div className="data-count">
                    Showing
                    <span className="font-weight-bold mx-2">{`${deposits.length} of ${total}`}</span>
                    Deposits
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fetching: getActionLoadingState(state, actionTypes.GET_DEPOSITS_REQUEST),
    pageRequest: getActionLoadingState(
      state,
      actionTypes.DEPOSITS_PAGE_REQUEST
    ),
    disablingUser: getActionLoadingState(
      state,
      userActionTypes.DISABLE_USER_REQUEST
    ),
    permissions: state.user.permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDeposits: (page, limit, status, searchTerm) =>
      dispatch(fetchDeposits(page, limit, status, searchTerm)),
    disableUser: (userId, reason) => dispatch(disableUser(userId, reason)),
    updateDeposit: (id, status, message) =>
      dispatch(updateDeposit(id, status, message)),
    exportDeposits: (module, format, status, page, limit) =>
      dispatch(exportDeposits(module, format, status, page, limit)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Deposits)
);
