import React from 'react'
import { connect } from "react-redux";
import { withRouter, Route, Switch, NavLink } from "react-router-dom";
import { getActionLoadingState } from "@/store/selectors";
import actionTypes from "@/store/support/actionTypes";
import { searchTickets, getTickets } from '@/store/support/actions'
import SolvedTickets from './SolvedTickets';
import UnsolvedTickets from './UnresolvedTickets';
import SingleTickets  from './SingleTicket';
import SearchBox from '@/components/SearchBox';
import './style.scss'

class Tickets extends React.Component {

  state = {
    search: '',
  }

  handleChange = (event) => {
    const { name, value } = event.target
    this.setState({ [name]: value });

    if(name === 'search' && value === '') {
      
    }
  }

  cancelSearch = () => {
    if(this.props.history.location.pathname.split('/').includes('solved')){
      return this.props.getTickets('closed', 10, 1);
    }
    return this.props.getTickets('open', 10, 1);
  }

  handleSearch = (search) => {
    if(search === '') {
      return this.cancelSearch();
    }
    if(this.props.history.location.pathname.split('/').includes('solved')){
      return this.props.searchTickets({ search, status: 'resolved' });
    }
    return this.props.searchTickets({ search, status: 'open' });
  }

  render() {
    const { match: { path } } = this.props;
    const { search } = this.state;
    return (
      <div className="tickets-page">
        {!this.props.history.location.pathname.split('/').includes('ticket') &&
          <div className="setup-inner__top justify-content-between">
          <ul className="setup-inner__nav mr-0">
            <li className="setup-nav__item" >
              <NavLink
                exact
                to={{ pathname: `${path}`, state: { pageTitle: 'Support' } }}
                activeClassName="setup-nav__item--active"
              >
                Unsolved Tickets
                </NavLink>
            </li>
            <li className="setup-nav__item" >
              <NavLink
                to={{ pathname: `${path}/solved`, state: { pageTitle: 'Support' } }}
                activeClassName="setup-nav__item--active"
              >
                Solved Tickets
                </NavLink>
            </li>
          </ul>
          <SearchBox
            placeholder="Search"
            handleSearch={this.handleSearch}
            isActiveSearch={Boolean(search)}
            key={this.props.location.pathname}
          />
        </div>
        }
        <Switch>
          <Route exact path={path}>
            <UnsolvedTickets search={this.state.search} />
          </Route>
          <Route path={`${path}/solved`}>
            <SolvedTickets search={this.state.search} />
          </Route>
          <Route path={`${path}/ticket/:ticketId`}>
            <SingleTickets />
          </Route>
        </Switch>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { app: { support: {error } } } = state;

  return {
    loading: getActionLoadingState(state, actionTypes.GET_TICKETS_REQUEST),
    error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTickets: (status, limit, pageNumber) => dispatch(getTickets(status, limit, pageNumber)),
    searchTickets: (payload) => dispatch(searchTickets(payload)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tickets));
