import React, { Component } from "react";
import { connect } from "react-redux";
import {
  createSalesAgency,
  fetchSalesAgency,
  editSalesAgency,
} from "@/store/setup/salesAgencyAction";
import { clearError } from "@/store/ui/actions";
import actionTypes from "@/store/setup/actionTypes";
import { getActionLoadingState, getActionErrorState } from "@/store/selectors";
import Alert from "@/components/Alert";
import { serializeErrors, validateFields } from "@/utils";
import InputError from "@/components/InputError";
import RightPanel from "@/components/RightPanel";
import Emptystate from "@/components/Emptystate";
import ExportDropdown from "@/components/ExportDropdown";
import MultipleApprovalDropdown from "@/components/MultipleApprovalDropdown";
import ConfirmationBox from "@/components/ConfirmationBox";
import Pagination from "@/components/Pagination";
import Dropdown from "@/components/DropdownMenu";
import { withRouter, Route, Switch, NavLink } from "react-router-dom";
import Agents from "./agents";
import { PlusIcon } from "@heroicons/react/outline";
import SearchBox from "@/components/SearchBox";
import dayjs from "dayjs";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { Link } from "react-router-dom/cjs/react-router-dom";

class SalesAgency extends Component {
  state = {
    open: false,
    createSalesAgencyOpen: false,
    editing: false,
    currentSalesAgencyData: {},
    pendingConfigs: [],
    salesagency: [],
    currentValue: "",
    newValue: "",
    reward: 0,
    reward_type: "",
    coins: "",
    name: "",
    max_amount: "",
    confirmationContext: "",
    selectedRowsIds: [],
    searchTerm: "",
    qualifying_actions: [],
    availableActions: ["register", "activate", "Deposit", "Invest"],
    formErrors: {
      name: "",
      reward: "",
      reward_type: "",
      qualifying_actions: "",
    },
    touched: {
      name: "",
      reward: "",
      reward_type: "",
      qualifying_actions: "",
    },
  };

  componentDidMount() {
    this.getSalesAgency(1, 10);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ selectedRows: [] }, () => this.getSalesAgency(1, 10));
    } else if (
      this.state.searchTerm !== prevState.searchTerm &&
      this.state.shouldSearch
    ) {
      this.getSalesAgency(1, 10, this.state.searchTerm);
    }
  }

  validateField = (name, value) => {
    let error = "";

    switch (name) {
      case "name":
        if (!value.trim()) {
          error = "Agency name is required";
        } else if (value.length < 2) {
          error = "Agency name must be at least 2 characters";
        }
        break;

      case "reward_type":
        if (!value) {
          error = "Reward type is required";
        }
        break;

      case "reward":
        if (!value) {
          error = "Reward value is required";
        } else if (isNaN(value) || value < 0) {
          error = "Reward must be a valid positive number";
        }
        break;

      case "qualifying_actions":
        if (!value || value.length === 0) {
          error = "At least one qualifying action is required";
        }
        break;

      default:
        break;
    }

    return error;
  };

  validateForm = () => {
    const { name, reward, reward_type, qualifying_actions } = this.state;
    const errors = {
      name: this.validateField("name", name),
      reward: this.validateField("reward", reward),
      reward_type: this.validateField("reward_type", reward_type),
      qualifying_actions: this.validateField(
        "qualifying_actions",
        qualifying_actions
      ),
    };

    const touched = {
      name: true,
      reward: true,
      reward_type: true,
      qualifying_actions: true,
    };

    this.setState({ formErrors: errors, touched });

    return !Object.values(errors).some((error) => error);
  };

  renderError = (fieldName) => {
    const { formErrors, touched } = this.state;
    if (touched[fieldName] && formErrors[fieldName]) {
      return (
        <div className="text-red-500 text-sm -mt-5 mb-4">
          {formErrors[fieldName]}
        </div>
      );
    }
    return null;
  };

  onInputChange = (e) => {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      [name]: value,
      touched: {
        ...prevState.touched,
        [name]: true,
      },
      formErrors: {
        ...prevState.formErrors,
        [name]: this.validateField(name, value),
      },
    }));
  };

  handleCheckboxChange = (e) => {
    const { value } = e.target;
    const { qualifying_actions } = this.state;

    const newQualifyingActions = qualifying_actions.includes(value)
      ? qualifying_actions.filter((action) => action !== value)
      : [...qualifying_actions, value];

    this.setState((prevState) => ({
      qualifying_actions: newQualifyingActions,
      touched: {
        ...prevState.touched,
        qualifying_actions: true,
      },
      formErrors: {
        ...prevState.formErrors,
        qualifying_actions: this.validateField(
          "qualifying_actions",
          newQualifyingActions
        ),
      },
    }));
  };

  getSalesAgency = (page, limit, searchTerm) => {
    this.props.fetchSalesAgency(page, limit, searchTerm).then((salesagency) => {
      this.setPageData(salesagency, searchTerm, false);
    });
  };

  setPageData = (
    { lastPage, perPage, page, total, data: salesagency },
    searchTerm,
    shouldSearch
  ) => {
    this.setState({
      salesagency,
      lastPage,
      perPage,
      page,
      total,
      searchTerm,
      shouldSearch,
    });
  };

  handleSearch = (searchTerm) => {
    this.setState({ searchTerm, page: 1 });
  };

  handleCheckboxChange = (e) => {
    const { value } = e.target;
    const { qualifying_actions } = this.state;

    if (qualifying_actions.includes(value)) {
      this.setState({
        qualifying_actions: qualifying_actions.filter(
          (action) => action !== value
        ),
      });
    } else {
      this.setState({
        qualifying_actions: [...qualifying_actions, value],
      });
    }
  };

  onCheckRow = (e, rowId) => {
    const selectedRows = [...this.state.selectedRowsIds];
    if (e.target.checked) {
      selectedRows.push(rowId);
    } else {
      const uncheckedRowIndex = selectedRows.findIndex((id) => id === rowId);
      selectedRows.splice(uncheckedRowIndex, 1);
    }
    this.setState({ selectedRowsIds: selectedRows });
  };

  onInputChange = (e) => {
    const { name, options, files } = e.target;
    if (name === "qualifying_actions") {
      const selectedActions = Array.from(options)
        .filter((option) => option.selected)
        .map((option) => option.value);

      this.setState({ qualifying_actions: selectedActions });
    } else {
      this.setState({ [name]: files?.[0] ?? e.target.value });
    }
  };

  dropdownmenus = (data) => {
    // const canUpdate = hasPermission(this.props.permissions, "update_role");
    // const canDelete = hasPermission(this.props.permissions, "delete_role");

    return [
      {
        name: "View Agents",
        handler: () =>
          this.props.history.push(
            `/dashboard/sales-agencies/${data.id}/agents`
          ),
      },
    ].filter(Boolean);
  };

  renderSalesAgency = () => {
    const { salesagency } = this.state;
    return !salesagency?.length ? (
      <Emptystate
        title="No Sales Agency"
        icon={require("@/assets/icons/info.svg").default}
      />
    ) : (
      <>
        <div className=""></div>
        <div className="setup-inner__main">
          <table className="custum-table">
            <tbody>
              <tr>
                <th></th>
                <th>ID</th>
                <th>Agency Name</th>
                <th>No. of agents enrolled</th>

                <th>Qualifying Actions</th>
                <th>Reward Type</th>
                <th>Reward Value</th>
                {/* <th>Amount/Coins</th> */}
                <th>Created At</th>
                <th className="actions-column">Actions</th>
              </tr>
              {this.state.salesagency.map((data) => {
                return (
                  <tr key={data.id}>
                    <td className="row-checkbox">
                      <input
                        type="checkbox"
                        onChange={(e) => this.onCheckRow(e, data.id)}
                      />
                    </td>
                    <td className="text-center">{data.id}</td>
                    <td>
                      <Link to={`/dashboard/sales-agencies/${data.id}/agents`}>
                        {data.name}
                      </Link>
                    </td>
                    <td>{data.agents.length}</td>

                    <td>{JSON.parse(data.qualifying_actions).join(", ")}</td>
                    <td>{data.reward_type}</td>
                    <td className="font-heading">{data.reward}</td>

                    <td className="">
                      {dayjs(data.created_at).format("D MMM, YYYY h:mm a")}
                    </td>
                    <td className="custum-table__ellipsis">
                      <Dropdown
                        menu={this.dropdownmenus(data)}
                        width="117px"
                        arrow={true}
                      >
                        <button className="wrapper-button ellipsis">
                          <img
                            src={
                              require("@/assets/icons/flat-ellipsis.svg")
                                .default
                            }
                            alt="dropdown"
                          />
                        </button>
                      </Dropdown>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  requestApproval = (e) => {
    e.preventDefault();
    const { configId, newValue } = this.state;
    this.props.changeConfiguration(configId, newValue).then(() => {
      this.setState({
        open: false,
        confirmationSuccess: true,
        confirmationContext: "Success",
      });
    });
  };

  createSalesAgency = async (e) => {
    e.preventDefault();

    if (!this.validateForm()) {
      return;
    }

    const { createSalesAgency } = this.props;
    const { name, reward, reward_type, qualifying_actions } = this.state;

    const payload = {
      name,
      reward,
      reward_type,
      qualifying_actions,
    };

    try {
      await createSalesAgency(payload);

      this.setState({
        createSalesAgencyOpen: false,
        name: "",
        reward: "",
        reward_type: "",
        qualifying_actions: [],
        formErrors: {
          name: "",
          reward: "",
          reward_type: "",
          qualifying_actions: "",
        },
        touched: {
          name: false,
          reward: false,
          reward_type: false,
          qualifying_actions: false,
        },
      });

      setTimeout(() => {
        this.getSalesAgency(1, 10, this.state.searchTerm);
      }, 100);
    } catch (error) {
      this.setState({
        createSalesAgencyOpen: false,
        errors: { api: error.message || "An unexpected error occurred." },
      });
    }
  };

  removeFromList = (ids) => {
    let pendingConfigs;
    if (Array.isArray(ids)) {
      pendingConfigs = this.state.pendingConfigs.filter(
        (data) => !ids.includes(data.id)
      );
    } else {
      pendingConfigs = [...this.state.pendingConfigs];
      const configIndex = pendingConfigs.findIndex(
        (config) => config.id === ids
      );
      pendingConfigs.splice(configIndex, 1);
    }
    this.setState({
      pendingConfigs,
      selectedRowsIds: [],
      confirmationSuccess: true,
    });
  };

  updateConfig = (status) => {
    const { configId, isMultipleApproval, selectedRowsIds } = this.state;
    const target = isMultipleApproval ? selectedRowsIds : configId;
    this.props.updateConfiguration(target, status).then(() => {
      this.removeFromList(target);
    });
  };

  updateSnappyConfig = (status) => {
    const { configId, isMultipleApproval, selectedRowsIds } = this.state;
    const target = isMultipleApproval ? selectedRowsIds : configId;
    this.props.updateSnappyConfiguration(target, status).then(() => {
      this.removeFromList(target);
    });
  };

  confirmationData = () => {
    const { isMultipleApproval, selectedRowsIds } = this.state;
    const addS = isMultipleApproval && selectedRowsIds.length > 1;

    return {
      approveConfig: {
        title: `Approve configuration${addS ? "s" : ""}`,
        question: `Are you sure you want to approve ${
          addS ? "these" : "this"
        } configuration${addS ? "s" : ""}?`,
        action: () => this.updateConfig("active"),
      },
      rejectConfig: {
        title: `Reject Configuration${addS ? "s" : ""}`,
        question: `Are you sure you want to reject ${
          addS ? "these" : "this"
        } configuration${addS ? "s" : ""}?`,
        action: () => this.updateConfig("reject"),
      },
      activeConfig: {
        title: `Approve configuration${addS ? "s" : ""}`,
        question: `Are you sure you want to approve ${
          addS ? "these" : "this"
        } configuration${addS ? "s" : ""}?`,
        action: () => this.updateSnappyConfig("active"),
      },
      pendingConfig: {
        title: `Reject Configuration${addS ? "s" : ""}`,
        question: `Are you sure you want to reject ${
          addS ? "these" : "this"
        } configuration${addS ? "s" : ""}?`,
        action: () => this.updateSnappyConfig("pending"),
      },
    };
  };

  setConfirmationContext = (context, configId, isMultipleApproval) => {
    this.setState({
      confirmationContext: context,
      confirmationSuccess: false,
      configId,
      isMultipleApproval,
    });
  };

  onEditClick = (name, configId, currentValue) => {
    this.setState({
      name,
      configId,
      currentValue,
      newValue: currentValue,
      open: true,
    });
  };

  onCreateClick = (minTenor, maxTenor, minAmount, maxAmount, rate, penalty) => {
    this.setState({
      maxTenor,
      minTenor,
      minAmount,
      penalty,
      maxAmount,
      rate,
      createSalesAgencyOpen: true,
    });
  };

  closePanel = (e) => {
    e.preventDefault();
    this.setState({
      open: false,
      name: "",
      currentValue: "",
      configId: "",
    });
    this.props.clearError();
  };

  closeCreateSalesAgencyPanel = (e) => {
    e.preventDefault();
    this.setState({
      createSalesAgencyOpen: false,
    });
    this.props.clearError();
  };

  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const {
      match: { path },
      pageRequestError,
      confirmationLoading,
      permissions,
      fetching,
    } = this.props;
    const {
      name,
      currentValue,
      newValue,
      confirmationSuccess,
      confirmationContext,
      reward,
      reward_type,
      coins,
      qualifying_actions,
      total,
      searchTerm,
      lastPage,
      page,
      perPage,
      salesagency,
    } = this.state;
    const createErrorObject = serializeErrors(pageRequestError?.message);
    const prompt = this.confirmationData()[confirmationContext];
    const showMultiAprrovalDropdown = this.state.selectedRowsIds.length > 0;

    if (this.props.location.pathname.includes("/agents")) {
      return (
        <Switch>
          <Route path={`${path}/:id/agents`} component={Agents} />
        </Switch>
      );
    }

    return (
      <div className="setup-inner">
        <Alert alert={pageRequestError} />
        <RightPanel
          open={this.state.createSalesAgencyOpen}
          onClose={this.closeCreateSalesAgencyPanel}
        >
          <h2 className="panel-heading">
            {this.state.editing ? "Edit Sales Agency" : "Create Sales Agency"}
          </h2>
          <form
            className="form employer-onboard__form"
            onSubmit={this.createSalesAgency}
          >
            <label className="panel-label" htmlFor="name">
              Agency Name
            </label>
            <input
              type="text"
              className={`form-control panel-input ${
                this.state.touched.name && this.state.formErrors.name
                  ? "error-input"
                  : ""
              }`}
              id="name"
              name="name"
              value={this.state.name}
              onChange={this.onInputChange}
              autoComplete="off"
            />
            {this.renderError("name")}

            <select
              className={`form-control panel-input ${
                this.state.touched.reward_type &&
                this.state.formErrors.reward_type
                  ? "error-input"
                  : ""
              }`}
              id="reward_type"
              name="reward_type"
              value={this.state.reward_type || ""}
              onChange={this.onInputChange}
            >
              <option value="">Select Reward Type</option>
              <option value="cash">Cash</option>
              <option value="coins">Coins</option>
              <option value="not_applicable">Not Applicable</option>
            </select>
            {this.renderError("reward_type")}

            <input
              type="number"
              className={`form-control panel-input ${
                this.state.touched.reward && this.state.formErrors.reward
                  ? "error-input"
                  : ""
              }`}
              id="reward"
              name="reward"
              onChange={this.onInputChange}
              value={this.state.reward}
            />
            {this.renderError("reward")}

            <label className="panel-label">Qualifying Actions</label>
            <div className="mt-2" id="qualifying_actions">
              {this.state.availableActions.map((action) => (
                <label key={action} className="inline-flex items-center">
                  <input
                    type="checkbox"
                    name="qualifying_actions"
                    value={action}
                    checked={this.state.qualifying_actions.includes(action)}
                    onChange={this.handleCheckboxChange}
                    className="form-checkbox h-4 w-4"
                  />
                  <span className="ml-2 mr-5 panel-label">{action}</span>
                </label>
              ))}
            </div>

            <InputError error={createErrorObject.value} />
            <div className="dual-button-box mt-10">
              <button
                type="button"
                onClick={this.closeCreateSalesAgencyPanel}
                className="cp-button-blue"
              >
                Cancel
              </button>
              <button type="submit" className="cp-button-blue">
                Save Changes
                {confirmationLoading && (
                  <div className="spinner-border spinner-border-white spinner-border-sm ml-2 mb-2"></div>
                )}
              </button>
            </div>
          </form>
        </RightPanel>
        <div className="setup-inner__top d-flex justify-end w-100">
          <ConfirmationBox
            open={confirmationContext}
            closeHandler={() => this.setState({ confirmationContext: "" })}
            success={confirmationSuccess}
            title={prompt?.title}
            question={prompt?.question}
            action={prompt?.action}
            loading={confirmationLoading}
            requiresReason={prompt?.requiresReason}
          />

          <SearchBox
            placeholder="Search"
            handleSearch={(searchTerm) =>
              this.setState({ searchTerm, shouldSearch: true })
            }
            isActiveSearch={Boolean(searchTerm)}
            key={this.props.location.pathname}
          />

          <button
            className="setup-inner__button"
            onClick={() =>
              this.setState({
                createSalesAgencyOpen: true,
                editing: false,
                currentSalesAgencyData: {},
                name: "",
                reward: "",
                reward_type: "",
                coins: "",
                qualifying_actions: [],
              })
            }
          >
            <PlusIcon className="size-6 mr-3" />
            <span>Create Sales Agency</span>
          </button>
          {showMultiAprrovalDropdown && (
            <MultipleApprovalDropdown
              approve={() =>
                this.setConfirmationContext("approveConfig", null, true)
              }
              reject={() =>
                this.setConfirmationContext("rejectConfig", null, true)
              }
            />
          )}
        </div>
        {fetching ? (
          <div className="text-center text-primary">
            <div className="spinner-border" role="status"></div>
          </div>
        ) : (
          <div className="position-relative">
            {!salesagency.length ? (
              <Emptystate
                title={`${searchTerm ? "No Result Found" : "No Sales Agency"}`}
                icon={require("@/assets/icons/info.svg").default}
              />
            ) : (
              <>
                <Pagination
                  totalPages={lastPage}
                  page={page}
                  limit={perPage}
                  changePageHandler={(page, limit) =>
                    this.getSalesAgency(page, limit, searchTerm)
                  }
                />
                <div className="table-overflow">
                  <div className="setup-inner__main setup-inner__expand">
                    {this.renderSalesAgency()}
                  </div>
                  <div className="data-count">
                    Showing
                    <span className="font-weight-bold mx-2">{`${salesagency.length} of ${total}`}</span>
                    Sales Agency
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fetching: getActionLoadingState(
      state,
      actionTypes.GET_SALES_AGENCY_REQUEST
    ),
    confirmationLoading: getActionLoadingState(
      state,
      actionTypes.SALES_AGENCY_PAGE_REQUEST
    ),
    pageRequestError: getActionErrorState(
      state,
      actionTypes.SALES_AGENCY_PAGE_ERROR
    ),
    permissions: state.user.permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSalesAgency: (page, limit, searchTerm) =>
      dispatch(fetchSalesAgency(page, limit, searchTerm)),

    createSalesAgency: (payload) => dispatch(createSalesAgency(payload)),
    editSalesAgency: (payload, id) => dispatch(editSalesAgency(payload, id)),

    clearError: () => dispatch(clearError()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SalesAgency)
);
