import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Provider } from "react-redux";
import { store, persistor } from "@/store";
import { PersistGate } from "redux-persist/integration/react";

import NotFound from "@/components/NotFound";
import NavigationListener from "@/components/NavigationListener";
import Login from "@/pages/Login";
import SetupPassword from "@/pages/SetupPassword";
import Dashboard from "@/pages/Dashboard";
import EventComponent from "./components/EventComponent";
import "./style/styles.scss";

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <Router>
          <NavigationListener />
          <Switch>
            <Route exact path="/">
              <Redirect to="/login" />
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/setup-password">
              <SetupPassword />
            </Route>
            <Route path="/dashboard">
              <EventComponent Component={Dashboard} />
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
