import React, { Component, Fragment } from "react";

export default class InputError extends Component {
    render() {
        const { error } = this.props;
        
        return(
            <Fragment>
                {error &&
                    <div className="error d-flex align-items-center">
                        <p className="text mb-0">{error}</p>
                    </div>
                }
            </Fragment>
        )
    }
}