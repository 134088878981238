import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import dayjs from "dayjs";
import { ReactComponent as BackArrrow } from "@/assets/icons/previous-page-arrow.svg";
import {
  fetchMutualFunds,
  downloadForm,
} from "@/store/Transactions/mutualFundsAction";
import actionTypes from "@/store/Transactions/actionTypes";
import { getActionLoadingState } from "@/store/selectors";
import Emptystate from "@/components/Emptystate";
import Pagination from "@/components/Pagination";
import RightPanel from "@/components/RightPanel";
import Dropdown from "@/components/DropdownMenu";
import Modal from "@/components/cpModal";
import { currency, camelCaseToString } from "@/utils";

class MutualFundPerformance extends Component {
  state = {
    mutualFunds: [],
    openResponse: false,
    userResponse: {},
  };

  componentDidMount() {
    this.getPerformance(1, 10);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.getPerformance(1, 10);
    }
  }

  getPerformance = (page, limit) => {
    const { id, status } = this.props.match.params;
    this.props.fetchMutualFunds(page, limit, status, id).then((mutualFunds) => {
      this.setPageData(mutualFunds.data);
    });
  };

  setPageData = ({
    responses: { lastPage, perPage, page, total, data: mutualFunds },
    mutualFund: { title },
  }) => {
    this.setState({ mutualFunds, lastPage, perPage, page, total, title });
  };

  dropdownmenus = (mutualFundId, response, userObj) => {
    return [
      {
        name: "Download Form PDF",
        handler: () => this.downloadForm(mutualFundId),
      },
      {
        name: "Preview Form",
        handler: () =>
          this.setState({
            openResponse: true,
            userResponse: response,
            userObj,
            mutualFundId,
          }),
      },
    ];
  };

  downloadForm = (id) => {
    this.props.downloadForm(id).then((raws) => {
      var blob = new Blob([raws], { type: "application/pdf" });
      const data = window.URL.createObjectURL(blob);
      const aTag = document.createElement("a");
      aTag.href = data;
      aTag.download = "file.pdf";
      document.body.appendChild(aTag);
      aTag.click();
      setTimeout(() => {
        document.body.removeChild(aTag);
        window.URL.revokeObjectURL(data);
      }, 100);
    });
  };

  onRowClick = (e, data) => {
    if (!["BUTTON", "IMG", "LI"].includes(e.target.nodeName)) {
      this.setState({
        previewMode: true,
        previewItem: {
          "First Name": data.user.firstName,
          "Last Name": data.user.lastName,
          "Phone Number": data.user.phone,
          "Date of Birth": dayjs(data.user.dateOfBirth).format("D MMM YYYY"),
          Address: data.user.residentialAddress,
        },
        open: true,
      });
    }
  };

  renderMutualFunds = () => {
    return (
      <table className="custum-table">
        <tbody>
          <tr>
            <th>Subscribed Customer</th>
            <th>Date Suscribed</th>
            <th>Phone Number</th>
            <th>Email Address</th>
            <th></th>
          </tr>
          {this.state.mutualFunds.map((mutualFund) => (
            <tr
              key={mutualFund.id}
              onClick={(e) => this.onRowClick(e, mutualFund)}
              className="cursor-pointer"
            >
              <td>{`${mutualFund.user.firstName} ${mutualFund.user.lastName}`}</td>
              <td>{dayjs(mutualFund.created_at).format("D MMM YYYY")}</td>
              <td>{mutualFund.user.phone}</td>
              <td>{mutualFund.user.email}</td>
              <td className="custum-table__ellipsis">
                <Dropdown
                  menu={this.dropdownmenus(
                    mutualFund.id,
                    mutualFund.response,
                    mutualFund.user
                  )}
                  arrow={true}
                >
                  <button className="wrapper-button ellipsis">
                    <img
                      src={require("@/assets/icons/flat-ellipsis.svg").default}
                      alt="dropdown"
                    />
                  </button>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  renderPreview = () => {
    const { previewItem } = this.state;
    return Object.keys(previewItem).map((field) => (
      <div className="panel-info__row" key={field}>
        <span className="panel-info__field">{field}</span>
        <span className="panel-info__value">{previewItem[field]}</span>
      </div>
    ));
  };

  closePanel = (e) => {
    e.preventDefault();
    this.setState({
      open: false,
      previewMode: false,
    });
  };

  render() {
    const {
      match: {
        params: { status },
      },
      fetching,
    } = this.props;
    const {
      mutualFunds,
      lastPage,
      perPage,
      page,
      total,
      previewMode,
      title,
      openResponse,
      userResponse,
      userObj,
      mutualFundId,
    } = this.state;

    return (
      <div className="setup-inner">
        <RightPanel open={this.state.open} onClose={this.closePanel}>
          <h1 className="panel-heading">Details</h1>
          <div className="panel-info">
            {previewMode && this.renderPreview()}
            <button className="long-button" onClick={this.closePanel}>
              Close
            </button>
          </div>
        </RightPanel>
        <Modal
          width="74rem"
          height="78rem"
          open={openResponse}
          customClass="response-preview__box"
        >
          <div className="response-preview">
            <div className="response-preview__top">
              <img
                src={require("@/assets/icons/CP-full-logo.png").default}
                alt="CP logo"
              />
              <h3>{title}</h3>
              {Object.keys(userResponse).map((field, index) => {
                return field === "countryCode" ? (
                  ""
                ) : (
                  <div className="response-preview__data mb-4" key={index}>
                    <span className="response-preview__field">
                      {camelCaseToString(field)}
                    </span>
                    <span className="response-preview__value">
                      {field === "subscriptionAmount"
                        ? `₦${currency(userResponse[field])}`
                        : field === "phoneNumber"
                        ? `${userResponse["countryCode"]}${userResponse[field]}}`
                        : userResponse[field] ?? "_ _"}
                    </span>
                  </div>
                );
              })}
            </div>
            <div className="response-preview__bottom pt-1">
              <div className="response-preview__data mt-5">
                <span className="response-preview__field font-italic">
                  For Official use only
                  <span className="text-red">*</span>
                </span>
              </div>
              <div className="response-preview__data mt-5">
                <span className="response-preview__field">
                  User ID: {`${userObj?.firstName} ${userObj?.lastName}`}
                </span>
                <span className="response-preview__value">{userObj?.id}</span>
              </div>
              <div className="response-preview__data mt-4">
                <span className="response-preview__field">
                  Form downloaded by
                </span>
              </div>
              <div className="response-preview__data mt-4">
                <span className="response-preview__field">
                  Form downloaded date
                </span>
              </div>
              <div className="response-preview__data mt-4">
                <span className="response-preview__field">Authorized By</span>
              </div>
              <div className="response-preview__data mt-4">
                <span className="response-preview__field">Payment Status</span>
              </div>

              <div className="dual-button-box col-md-6 mx-auto my-5 pt-2">
                <button
                  onClick={() => this.setState({ openResponse: false })}
                  className="cp-button-blue color-red"
                >
                  Cancel
                </button>
                <button
                  className="cp-button-blue"
                  onClick={() => this.downloadForm(mutualFundId)}
                >
                  Download PDF
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <div className="d-flex align-items-center mb-5">
          <BackArrrow
            className="cp-pagination__arrow--active"
            onClick={() =>
              this.props.history.push("/dashboard/transactions/mutual-funds")
            }
          />
          <span
            className="font-md mx-3 font-weight-bold cp-pagination__arrow--active"
            onClick={() =>
              this.props.history.push("/dashboard/transactions/mutual-funds")
            }
          >
            Back
          </span>
        </div>
        <div className="setup-inner__top">
          <ul className="setup-inner__nav">
            <li className="setup-nav__item">
              <NavLink
                to={{
                  pathname: "recent",
                  state: { pageTitle: "Transactions" },
                }}
                isActive={() => status === "recent"}
                activeClassName="setup-nav__item--active"
              >
                Recent
              </NavLink>
            </li>
            <li className="setup-nav__item">
              <NavLink
                to={{ pathname: "all", state: { pageTitle: "Transactions" } }}
                isActive={() => status === "all"}
                activeClassName="setup-nav__item--active"
              >
                All Subscribers
              </NavLink>
            </li>
          </ul>
        </div>
        {fetching ? (
          <div className="text-center text-primary">
            <div className="spinner-border" role="status"></div>
          </div>
        ) : (
          <div className="position-relative">
            {!mutualFunds.length ? (
              <Emptystate
                title="No subscribers"
                icon={require("@/assets/icons/info.svg").default}
              />
            ) : (
              <>
                <Pagination
                  totalPages={lastPage}
                  page={page}
                  limit={perPage}
                  changePageHandler={(page, limit) =>
                    this.getPerformance(page, limit)
                  }
                />
                <div className="table-overflow">
                  <div className="setup-inner__main setup-inner__expand">
                    {this.renderMutualFunds()}
                  </div>
                  <div className="data-count">
                    Showing
                    <span className="font-weight-bold mx-2">{`${mutualFunds.length} of ${total}`}</span>
                    Subscribers
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fetching: getActionLoadingState(
      state,
      actionTypes.GET_MUTUAL_FUNDS_REQUEST
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMutualFunds: (page, limit, type, id) =>
      dispatch(fetchMutualFunds(page, limit, type, id)),
    downloadForm: (id) => dispatch(downloadForm(id)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MutualFundPerformance)
);
