import React from 'react';
import { withRouter, Route, Switch } from "react-router-dom";
import AllFunds from './AllFunds';
import './style.scss';

class PPI extends React.Component {

  render() {
    const { match: { path } } = this.props;

    return (
      <div className="all-ppi-page mb-5">
        <Switch>
          <Route path={path}>
            <AllFunds />
          </Route>
        </Switch>
      </div>
    )
  }
}

export default withRouter(PPI);
