import React, { Component } from "react";
import { withRouter, Route, Switch, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { filterCtrReports, downloadCtrReports, getCtrReports } from "@/store/reports/actions";
import Recent from './Recent';
import AllCtr from './AllCtr';
import SearchBox from '@/components/SearchBox';
import ExportDropdown from '@/components/ExportDropdown';
import './style.scss';

class CTR extends Component {

  state = {
    search: '',
    exportValue: ''
  }

  cancelSearch = () => {
    if(this.props.history.location.pathname.split('/').includes('all')){
      this.props.getCtrReports('all', 1, 10)
    }
    this.props.getCtrReports('recent', 1, 20)
  }

  handleSearch = (search) => {
    this.setState({ search });
    if (search === '') {
      return this.cancelSearch();
    }
    if (this.props.history.location.pathname.split('/').includes('all')) {
      return this.props.filterCtrReports(search, 'all', 1, 10);
    }
    return this.props.filterCtrReports(search, 'recent', 1, 25);
  }

  handleChange = (event) => {
    const { name, value } = event.target
    this.setState({ [name]: value });
  }

  handleDownloadChange = (event) => {
    const { name, value } = event.target
    this.setState({ [name]: value });
    if (value === 'csv') {
      return this.props.downloadCtrReports();
    }
  }

  render() {
    const { match: { path }, reports } = this.props;
    const { search } = this.state;

    return (
      <div className="ctr-report-page">
        <div className="setup-inner__top justify-content-between mt-4">
          <ul className="setup-inner__nav mr-0">
            <li className="setup-nav__item" >
              <NavLink
                exact
                to={{ pathname: `${path}`, state: { pageTitle: 'Reports' } }}
                activeClassName="setup-nav__item--active"
              >
                Recent
                </NavLink>
            </li>
            <li className="setup-nav__item" >
              <NavLink
                to={{ pathname: `${path}/all`, state: { pageTitle: 'Reports' } }}
                activeClassName="setup-nav__item--active"
              >
                All CTR Reports
                </NavLink>
            </li>
          </ul>

          <div className="row setup-inner__top--right align-items-center">
            <SearchBox
              placeholder="Search"
              handleSearch={this.handleSearch}
              isActiveSearch={Boolean(search)}
              key={this.props.location.pathname}
            />
            <ExportDropdown
              module="ctr-report"
              status={this.props.history.location.pathname.split('/').includes('all') ? 'all' : 'recent'}
              page={reports?.reports.page}
              limit={reports?.reports.perPage ?? ''}
              downloadHandler={this.props.downloadCtrReports}
              hasMoreThanOnePage={reports?.reports.lastPage > 1}
            />
          </div>
        </div>

        <Switch>
          <Route exact path={path}>
            <Recent searchWord={search}/>
          </Route>
          <Route path={`${path}/all`}>
            <AllCtr searchWord={search}/>
          </Route>
        </Switch>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    app: {
      reports: { reports }
    }
  } = state;
  return {
    reports
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    filterCtrReports: (search, section, page, limit) => dispatch(filterCtrReports(search, section, page, limit)),
    downloadCtrReports: (module, format, status, page, limit) => dispatch(downloadCtrReports(module, format, status, page, limit)),
    getCtrReports: (type, page, limit) => dispatch(getCtrReports(type, page, limit)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CTR));
