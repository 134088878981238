import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import {
  createReferral,
  fetchReferrals,
  editReferral,
  toggleReferralStatus,
} from "@/store/setup/referralAction";
import actionTypes from "@/store/setup/actionTypes";
import { getActionLoadingState } from "@/store/selectors";
import Emptystate from "@/components/Emptystate";
import Pagination from "@/components/Pagination";
import SearchBox from "@/components/SearchBox";
import RightPanel from "@/components/RightPanel";
import Compressor from "compressorjs";
import Dropdown from "@/components/DropdownMenu";
import dayjs from "dayjs";

class Referrals extends Component {
  state = {
    referrals: [],
    total: 0,
    page: 1,
    limit: 10,
    searchTerm: "",
    shouldSearch: false,
    createReferralOpen: false,
    campaign_name: "",
    referral_code: "",
    campaign_type: "",
    reward_type: "",
    amount: 0.0,
    coins: 0,
    gratification_type: "",
    sharing_ratio: "",
    max_referrals: 0,
    qualifying_actions: [],
    availableActions: ["register", "activate"],
    banner_url: "",
    start_date: "",
    end_date: "",
    editing: false,
    currentReferralData: {},
    formErrors: {
      campaign_name: "",
      referral_code: "",
      campaign_type: "",
      reward_type: "",
      amount: "",
      coins: "",
      gratification_type: "",
      sharing_ratio: "",
      max_referrals: "",
      qualifying_actions: "",
      terms_conditions: "",
      banner_url: "",
      start_date: "",
      end_date: "",
    },
    touched: {
      campaign_name: false,
      referral_code: false,
      campaign_type: false,
      reward_type: false,
      amount: false,
      coins: false,
      gratification_type: false,
      sharing_ratio: false,
      max_referrals: false,
      qualifying_actions: false,
      terms_conditions: false,
      banner_url: false,
      start_date: false,
      end_date: false,
    },
  };

  componentDidMount() {
    const { campaign_type } = this.props.match.params;
    this.getReferrals(1, 10, campaign_type);
  }

  componentDidUpdate(prevProps, prevState) {
    const { campaign_type } = this.props.match.params;
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ selectedRows: [] }, () =>
        this.getReferrals(1, 10, campaign_type)
      );
    } else if (
      this.state.searchTerm !== prevState.searchTerm &&
      this.state.shouldSearch
    ) {
      this.getReferrals(1, 10, campaign_type, this.state.searchTerm);
    }
  }

  getReferrals = (page, limit, campaign_type, searchTerm) => {
    this.props
      .fetchReferrals(page, limit, campaign_type, searchTerm)
      .then((referrals) => {
        this.setPageData(referrals, searchTerm, false);
      });
  };

  setPageData = (
    { lastPage, perPage, page, total, data: referrals },
    searchTerm,
    shouldSearch
  ) => {
    this.setState({
      referrals,
      lastPage,
      perPage,
      page,
      total,
      searchTerm,
      shouldSearch,
    });
  };

  handleSearch = (searchTerm) => {
    this.setState({ searchTerm, page: 1 });
  };

  handleCheckboxChange = (e) => {
    const { value } = e.target;
    const { qualifying_actions } = this.state;

    if (qualifying_actions.includes(value)) {
      this.setState({
        qualifying_actions: qualifying_actions.filter(
          (action) => action !== value
        ),
      });
    } else {
      this.setState({
        qualifying_actions: [...qualifying_actions, value],
      });
    }
  };

  resetForm = () => {
    this.setState({
      campaign_name: "",
      referral_code: "",
      campaign_type: "",
      reward_type: "",
      amount: 0.0,
      coins: 0,
      gratification_type: "",
      sharing_ratio: "",
      max_referrals: 0,
      qualifying_actions: [],
      banner_url: "",
      terms_conditions: "",
      start_date: "",
      end_date: "",
      editing: false,
      currentReferralData: {},
    });
  };

  validateField = (name, value) => {
    let error = "";
  
    if (value === undefined || value === null) {
      if (name === "coins" || name === "banner_url") {
        return ""; // These fields are optional
      }
      return `${name} is required`; // Required fields
    }
  
    switch (name) {
      case "campaign_name":
        if (!String(value).trim()) {
          error = "Campaign name is required";
        }
        break;
  
      case "campaign_type":
        if (!value) {
          error = "Campaign type is required";
        }
        break;
  
      case "referral_code":
        if (this.state.campaign_type === "non_monetary") {
          if (!String(value).trim()) {
            error = "Referral code is required for non-monetary campaigns";
          }
        }
        break;
  
      case "reward_type":
        if (!value) {
          error = "Reward type is required";
        }
        break;
  
      case "amount":
        if (!String(value).trim()) {
          error = "Amount is required";
        } else if (isNaN(Number(value)) || Number(value) < 0) {
          error = "Amount must be a positive number";
        }
        break;
  
      case "coins":
        if (value && (isNaN(Number(value)) || Number(value) < 0)) {
          error = "Coins must be a positive number";
        }
        break;
  
      case "gratification_type":
        if (!value) {
          error = "Gratification type is required";
        }
        break;
  
      case "sharing_ratio":
        if (!String(value).trim()) {
          error = "Sharing ratio is required";
        } else if (!/^\d+:\d+$/.test(value)) {
          error = "Sharing ratio must be in format X:Y";
        }
        break;
  
      case "max_referrals":
        if (!String(value).trim()) {
          error = "Maximum referrals is required";
        } else if (isNaN(Number(value)) || Number(value) < 1) {
          error = "Maximum referrals must be at least 1";
        }
        break;
  
      case "qualifying_actions":
        if (!Array.isArray(value) || !value.length) {
          error = "At least one qualifying action is required";
        }
        break;
  
      case "terms_conditions":
        if (!String(value).trim()) {
          error = "Terms and conditions are required";
        }
        break;
  
      case "start_date":
        if (!value) {
          error = "Start date is required";
        }
        break;
  
      case "end_date":
        if (!value) {
          error = "End date is required";
        } else if (new Date(value) <= new Date(this.state.start_date)) {
          error = "End date must be after start date";
        }
        break;
  
      default:
        break;
    }
  
    return error;
  };

  validateForm = () => {
    const {
      campaign_name,
      referral_code,
      campaign_type,
      reward_type,
      amount,
      coins,
      gratification_type,
      sharing_ratio,
      max_referrals,
      qualifying_actions,
      terms_conditions,
      start_date,
      end_date,
    } = this.state;

    const errors = {
      campaign_name: this.validateField("campaign_name", campaign_name),
      campaign_type: this.validateField("campaign_type", campaign_type),
      referral_code: this.validateField("referral_code", referral_code),
      reward_type: this.validateField("reward_type", reward_type),
      amount: this.validateField("amount", amount),
      coins: this.validateField("coins", coins),
      gratification_type: this.validateField(
        "gratification_type",
        gratification_type
      ),
      sharing_ratio: this.validateField("sharing_ratio", sharing_ratio),
      max_referrals: this.validateField("max_referrals", max_referrals),
      qualifying_actions: this.validateField(
        "qualifying_actions",
        qualifying_actions
      ),
      terms_conditions: this.validateField(
        "terms_conditions",
        terms_conditions
      ),
      start_date: this.validateField("start_date", start_date),
      end_date: this.validateField("end_date", end_date),
    };

    const touched = {
      campaign_name: true,
      campaign_type: true,
      referral_code: true,
      reward_type: true,
      amount: true,
      coins: true,
      gratification_type: true,
      sharing_ratio: true,
      max_referrals: true,
      qualifying_actions: true,
      terms_conditions: true,
      start_date: true,
      end_date: true,
    };

    this.setState({ formErrors: errors, touched });

    return !Object.values(errors).some((error) => error);
  };

  renderError = (fieldName) => {
    const { formErrors, touched } = this.state;
    if (touched[fieldName] && formErrors[fieldName]) {
      return (
        <div className="text-red-500 text-sm -mt-5 mb-4">{formErrors[fieldName]}</div>
      );
    }
    return null;
  };

  createNewReferral = async (e) => {
    e.preventDefault();

    if (!this.validateForm()) {
      return;
    }

    const { createReferral, editReferral } = this.props;
    const {
      campaign_name,
      referral_code,
      campaign_type,
      reward_type,
      amount,
      coins,
      gratification_type,
      sharing_ratio,
      max_referrals,
      qualifying_actions,
      terms_conditions,
      banner_url,
      start_date,
      end_date,
      editing,
      currentReferralData,
    } = this.state;

    this.setState({ errors: null });

    const payload = {
      campaign_name,
      campaign_type,
      reward_type,
      amount,
      coins,
      gratification_type,
      sharing_ratio,
      max_referrals,
      qualifying_actions,
      terms_conditions,
      banner_url,
      start_date,
      end_date,
    };
    if (this.state.campaign_type === "non_monetary") {
      payload.referral_code = referral_code;
    }

    try {
      if (editing) {
        if (currentReferralData && currentReferralData.id) {
          await editReferral(payload, currentReferralData.id);
        } else {
          throw new Error("No ID provided for edit");
        }
      } else {
        await createReferral(payload);
      }

      this.setState({
        createReferralOpen: false,
      });
      this.resetForm();
    } catch (error) {
      this.setState({
        createReferralOpen: false,
        errors: { api: error.message || "An unexpected error occurred." },
      });
    }
  };

  toggleStatus = async (campaignId) => {
    const { toggleReferralStatus } = this.props;

    try {
      await toggleReferralStatus(campaignId);

      this.setState({
        campaignStatusToggled: true,
      });
    } catch (error) {
      this.setState({
        campaignStatusToggled: false,
        errors: {
          api:
            error.message ||
            "An unexpected error occurred while toggling status.",
        },
      });
    }
  };

  onCreateClick = (minTenor, maxTenor, minAmount, maxAmount, rate, penalty) => {
    this.setState({
      maxTenor,
      minTenor,
      minAmount,
      penalty,
      maxAmount,
      rate,
      createReferralOpen: true,
    });
  };

  closeCreateReferralPanel = (e) => {
    e.preventDefault();
    this.setState({
      createReferralOpen: false,
    });
    this.resetForm();
  };

  dropdownMenus = (id, data) => [
    {
      name: "Edit",
      handler: () => {
        this.setState(
          {
            editing: true,
            createReferralOpen: true,
            currentReferralData: data,
          },
          () => {
            this.setState({
              campaign_name: data.campaign_name || "",
              referral_code: data.referral_code || "",
              campaign_type: data.campaign_type || "",
              reward_type: data.reward_type || "",
              amount: data.amount || "",
              coins: data.coins || "",
              gratification_type: data.gratification_type || "",
              sharing_ratio: data.sharing_ratio || "",
              max_referrals: data.max_referrals || "",
              qualifying_actions: data.qualifying_actions || [],
              terms_conditions: data.terms_conditions || "",
              banner_url: data.banner_url || null,
              start_date: data.start_date || "",
              end_date: data.end_date || "",
            });
          }
        );
      },
    },
    {
      name: data.is_active === 0 ? "Activate" : "Deactivate",
      handler: () => this.toggleStatus(id),
    },
  ];

  onInputChange = (e) => {
    const { name, options, files } = e.target;
    let value;

    if (name === "qualifying_actions") {
      const selectedActions = Array.from(options)
        .filter((option) => option.selected)
        .map((option) => option.value);
      value = selectedActions;
    } else if (name === "banner_url") {
      // ... existing banner_url handling ...
      value = files?.[0];
    } else {
      value = files?.[0] ?? e.target.value;
    }

    this.setState(
      {
        [name]: value,
        touched: { ...this.state.touched, [name]: true },
      },
      () => {
        const error = this.validateField(name, value);
        this.setState({
          formErrors: { ...this.state.formErrors, [name]: error },
        });
      }
    );
  };

  renderReferrals = () => {
    return (
      <div className="setup-inner__main">
        <table className="custum-table">
          <tbody>
            <tr>
              <th>S/N</th>
              <th>Campaign Name</th>
              <th>Campaign Type</th>
              <th>Reward Type</th>
              <th>Status</th>
              <th>Date</th>
              <th className="actions-column">Actions</th>
            </tr>
            {this.state.referrals.map((referral, index) => (
              <tr key={referral.id}>
                <td>{index + 1}</td>
                <td className="text-capitalize">{referral.campaign_name}</td>
                <td className="text-capitalize">{referral.campaign_type}</td>
                <td className="text-capitalize">{referral.reward_type}</td>
                <td>
                  <span
                    className={`status status--${
                      referral.is_active === 1 ? "active" : "inactive"
                    }`}
                  >
                    {referral.is_active === 1 ? "Active" : "Inactive"}
                  </span>
                </td>
                <td>{dayjs(referral.createdAt).format("D MMM YYYY")}</td>
                <td className="custum-table__ellipsis">
                  <Dropdown
                    menu={this.dropdownMenus(referral.id, referral)}
                    arrow={true}
                  >
                    <button className="wrapper-button">
                      <img
                        src={
                          require("@/assets/icons/flat-ellipsis.svg").default
                        }
                        alt="dropdown"
                      />
                    </button>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  render() {
    const {
      match: {
        params: { campaign_type },
      },
      fetching,
    } = this.props;
    const { referrals, total, page, limit, searchTerm, lastPage, perPage } =
      this.state;

    return (
      <div className="setup-inner">
        <RightPanel
          open={this.state.createReferralOpen}
          onClose={this.closeCreateReferralPanel}
        >
          <h2 className="panel-heading">
            {this.state.editing ? "Edit Ref. Scheme" : "Create Ref. Scheme"}
          </h2>
          <form
            className="form employer-onboard__form"
            onSubmit={this.createNewReferral}
          >
            <label className="panel-label" htmlFor="campaign_name">
              Campaign Name
            </label>
            <input
              type="text"
              className={`form-control panel-input ${
                this.state.touched.campaign_name &&
                this.state.formErrors.campaign_name
                  ? "error-input"
                  : ""
              }`}
              id="campaign_name"
              name="campaign_name"
              value={this.state.campaign_name}
              onChange={this.onInputChange}
              autoComplete="off"
            />
            {this.renderError("campaign_name")}

            <label className="panel-label" htmlFor="campaign_type">
              Campaign Type
            </label>
            <select
              className={`form-control panel-input ${
                this.state.touched.campaign_type &&
                this.state.formErrors.campaign_type
                  ? "error-input"
                  : ""
              }`}
              id="campaign_type"
              name="campaign_type"
              value={this.state.campaign_type || ""}
              onChange={this.onInputChange}
            >
              <option value="">Select Campaign Type</option>
              <option value="monetary">Monetary</option>
              <option value="non_monetary">Non-Monetary</option>
              <option value="reward">Reward</option>
            </select>
            {this.renderError("campaign_type")}

            {this.state.campaign_type === "non_monetary" && (
              <>
                <label className="panel-label" htmlFor="referral_code">
                  Referral Code
                </label>
                <input
                  type="text"
                  className="form-control panel-input"
                  id="referral_code"
                  name="referral_code"
                  value={this.state.referral_code}
                  onChange={this.onInputChange}
                  autoComplete="off"
                />
              </>
            )}
            <label className="panel-label" htmlFor="reward_type">
              Reward Type
            </label>
            <select
              className={`form-control panel-input ${
                this.state.touched.reward_type &&
                this.state.formErrors.reward_type
                  ? "error-input"
                  : ""
              } `}
              id="reward_type"
              name="reward_type"
              value={this.state.reward_type || ""}
              onChange={this.onInputChange}
            >
              <option value="">Select Reward Type</option>
              <option value="cash">Cash</option>
              <option value="coins">Coins</option>
              <option value="random_giveaway">Random Giveaway</option>
            </select>
            {this.renderError("reward_type")}

            <label className="panel-label" htmlFor="amount">
              Amount
            </label>
            <input
              type="number"
              className={`form-control panel-input ${
                this.state.touched.amount &&
                this.state.formErrors.amount
                  ? "error-input"
                  : ""
              }`}
              id="amount"
              name="amount"
              value={this.state.amount}
              onChange={this.onInputChange}
              autoComplete="off"
            />
            {this.renderError("amount")}

            <label className="panel-label" htmlFor="coins">
              Coins
            </label>
            <input
              type="number"
              className={`form-control panel-input ${
                this.state.touched.coins &&
                this.state.formErrors.coins
                  ? "error-input"
                  : ""
              }`}
              id="coins"
              name="coins"
              value={this.state.coins}
              onChange={this.onInputChange}
              autoComplete="off"
            />
            {this.renderError("coins")}

            <label className="panel-label" htmlFor="gratification_type">
              Gratification Type
            </label>
            <select
              className={`form-control panel-input ${
                this.state.touched.gratification_type &&
                this.state.formErrors.gratification_type
                  ? "error-input"
                  : ""
              } `}
              id="gratification_type"
              name="gratification_type"
              value={this.state.gratification_type || ""}
              onChange={this.onInputChange}
            >
              <option value="">Select Reward Type</option>
              <option value="one_sided">One Sided</option>
              <option value="two_sided">Two Sided</option>
            </select>
            {this.renderError("gratification_type")}

            <label className="panel-label" htmlFor="sharing_ratio">
              Sharing Ratio
            </label>
            <input
              type="text"
              className={`form-control panel-input ${
                this.state.touched.sharing_ratio &&
                this.state.formErrors.sharing_ratio
                  ? "error-input"
                  : ""
              } `}
              id="sharing_ratio"
              name="sharing_ratio"
              value={this.state.sharing_ratio}
              onChange={this.onInputChange}
              autoComplete="off"
            />
            {this.renderError("sharing_ratio")}

            <label className="panel-label" htmlFor="max_referrals">
              Max Referrals
            </label>
            <input
              type="number"
              className={`form-control panel-input ${
                this.state.touched.max_referrals &&
                this.state.formErrors.max_referrals
                  ? "error-input"
                  : ""
              } `}
              id="max_referrals"
              name="max_referrals"
              value={this.state.max_referrals}
              onChange={this.onInputChange}
              autoComplete="off"
            />
            {this.renderError("max_referrals")}

            <label className="panel-label" htmlFor="qualifying_actions">
              Qualifying Actions
            </label>
            <div className="mt-2" id="qualifying_actions">
              {this.state.availableActions.map((action) => (
                <label key={action} className="inline-flex items-center">
                  <input
                    type="checkbox"
                    name="qualifying_actions"
                    value={action}
                    checked={this.state.qualifying_actions.includes(action)}
                    onChange={this.handleCheckboxChange}
                    className="form-checkbox h-4 w-4"
                  />
                  <span className="ml-2 mr-5 panel-label">{action}</span>
                </label>
              ))}
            </div>

            <label className="panel-label mt-5" htmlFor="terms_conditions">
              Terms and Conditions
            </label>
            <textarea
              className={`form-control panel-input ${
                this.state.touched.terms_conditions &&
                this.state.formErrors.terms_conditions
                  ? "error-input"
                  : ""
              } `}
              id="terms_conditions"
              name="terms_conditions"
              value={this.state.terms_conditions}
              onChange={this.onInputChange}
              autoComplete="off"
            />
            {this.renderError("terms_conditions")}

            <label className="panel-label"> Banner</label>
            <div className="custum-form__field custum-form__file-upload mb-5">
              <input
                type="file"
                id="banner_url"
                name="banner_url"
                accept=".png, .jpg, .jpeg"
                onChange={this.onInputChange}
              />
              <label htmlFor="banner_url" className="cursor-pointer">
                {this.state.banner_url?.name || (
                  <>
                    <span className="text-blue mr-2">+ Click here</span>
                    to upload an image
                  </>
                )}
              </label>
            </div>

            <label className="panel-label" htmlFor="start_date">
              Start Date
            </label>
            <input
              type="date"
              className={`form-control panel-input ${
                this.state.touched.start_date &&
                this.state.formErrors.start_date
                  ? "error-input"
                  : ""
              } `}
              id="start_date"
              name="start_date"
              value={this.state.start_date}
              onChange={this.onInputChange}
              autoComplete="off"
            />
            {this.renderError("start_date")}

            <label className="panel-label" htmlFor="end_date">
              End Date
            </label>
            <input
              type="date"
              className={`form-control panel-input ${
                this.state.touched.end_date &&
                this.state.formErrors.end_date
                  ? "error-input"
                  : ""
              } `}
              id="end_date"
              name="end_date"
              value={this.state.end_date}
              onChange={this.onInputChange}
              autoComplete="off"
            />
            {this.renderError("end_date")}
            <div className="dual-button-box">
              <button
                type="button"
                onClick={this.closeCreateReferralPanel}
                className="cp-button-blue"
              >
                Cancel
              </button>
              <button className="cp-button-blue">
                {this.state.editing ? "Save Changes" : "Request Approval"}
                {this.state.confirmationLoading && (
                  <div className="spinner-border spinner-border-white spinner-border-sm ml-2 mb-2"></div>
                )}
              </button>
            </div>
          </form>
        </RightPanel>

        <div className="setup-inner__top d-flex justify-content-between w-100">
          <ul className="setup-inner__nav">
            <li className="setup-nav__item">
              <NavLink
                to={{ pathname: "monetary" }}
                isActive={() => campaign_type === "monetary"}
                activeClassName="setup-nav__item--active"
              >
                Monetary
              </NavLink>
            </li>
            <li className="setup-nav__item">
              <NavLink
                to={{ pathname: "non-monetary" }}
                isActive={() => campaign_type === "non-monetary"}
                activeClassName="setup-nav__item--active"
              >
                Non-Monetary
              </NavLink>
            </li>
            <li className="setup-nav__item">
              <NavLink
                to={{ pathname: "reward" }}
                isActive={() => campaign_type === "reward"}
                activeClassName="setup-nav__item--active"
              >
                Rewards
              </NavLink>
            </li>
          </ul>

          <SearchBox
            placeholder="Search"
            handleSearch={(searchTerm) =>
              this.setState({ searchTerm, shouldSearch: true })
            }
            isActiveSearch={Boolean(searchTerm)}
            key={this.props.location.pathname}
          />

          <div>
            <button
              className="setup-inner__button"
              onClick={() =>
                this.setState({
                  createReferralOpen: true,
                })
              }
            >
              <img
                src={require("@/assets/icons/plus.svg").default}
                alt="plus icon"
                className="setup-inner__button-img"
              />
              Create Referral Schemes
            </button>
          </div>
        </div>
        {fetching ? (
          <div className="text-center text-primary">
            <div className="spinner-border" role="status"></div>
          </div>
        ) : (
          <div className="position-relative">
            {!referrals.length ? (
              <Emptystate
                title={`${searchTerm ? "No Result Found" : "No referrals"}`}
                icon={require("@/assets/icons/info.svg").default}
              />
            ) : (
              <>
                <Pagination
                  totalPages={lastPage}
                  page={page}
                  limit={perPage}
                  changePageHandler={(page, limit) =>
                    this.getReferrals(page, limit, campaign_type, searchTerm)
                  }
                />
                <div className="table-overflow">
                  <div className="setup-inner__main setup-inner__expand">
                    {this.renderReferrals()}
                  </div>
                  <div className="data-count">
                    Showing
                    <span className="font-weight-bold mx-2">{`${referrals.length} of ${total}`}</span>
                    Referrals
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fetching: getActionLoadingState(state, actionTypes.GET_REFERRALS_REQUEST),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchReferrals: (page, limit, campaign_type, searchTerm) =>
      dispatch(fetchReferrals(page, limit, campaign_type, searchTerm)),

    createReferral: (payload) => dispatch(createReferral(payload)),
    editReferral: (payload, id) => dispatch(editReferral(payload, id)),
    toggleReferralStatus: (id) => dispatch(toggleReferralStatus(id)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Referrals)
);
