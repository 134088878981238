import axios from 'axios';
import CONFIG from '@/config';
import * as actions from "./actionTypes";
import { logout, updateUser } from "@/store/user/actions";
import { showAlert } from '@/store/ui/actions';

const fetchPortfolioRequest = () => {
  return {
    type: actions.FETCH_PORTFOLIO_REQUEST,
  }
}

const fetchPortfolioError = (message) => {
  return {
    type: actions.FETCH_PORTFOLIO_ERROR,
    error: message,
  }
}

const fetchPortfolioSuccess = (data) => {
  return {
    type: actions.FETCH_PORTFOLIO_SUCCESS,
    data,
  }
}

export const fetchPortfolio = (id, status) => {
  return (dispatch, getState) => {
    dispatch(fetchPortfolioRequest());

    const { token } = getState().user;

    axios.get(`${CONFIG.BASE_URL}/admin/users/${id}/portfolio/termed/${status}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
    .then(response => {
      dispatch(updateUser({
        token: response.headers.authorization
      }))
      if ([200, 201].includes(response.status)) {
        dispatch(fetchPortfolioSuccess(response.data.data));
      }
    })
    .catch((error) => {
      error.response && dispatch(updateUser({
        token: error.response.headers.authorization
      }))
      if (error.response && [400, 404, 403].includes(error.response.status)) {
        return dispatch(fetchPortfolioError(error.response.data.error ? error.response.data.error : error.response.data.message));
      }
      if (error.response && [401].includes(error.response.status)) {
        dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
        return setTimeout(() => dispatch(logout()), 2000) 
      }
      if (error.response && error.response.status >= 500) {
        return dispatch(fetchPortfolioError('Oops! We did something wrong.'));
      }
      dispatch(fetchPortfolioError('Oops! We did something wrong.'));
    })
  }
}
