import axios from "axios";
import CONFIG from "@/config";
import * as actions from "./actionTypes";
import { logout, updateUser } from "@/store/user/actions";
import { showAlert } from "@/store/ui/actions";
import { apiRequestHandler } from "@/utils";

const clear = () => {
  return {
    type: actions.CLEAR,
  };
};

const getSalesAgencyRequest = () => {
  return {
    type: actions.GET_SALES_AGENCY_REQUEST,
  };
};

const getSalesAgencyError = () => {
  return {
    type: actions.GET_SALES_AGENCY_ERROR,
  };
};

const getSalesAgencySuccess = () => {
  return {
    type: actions.GET_SALES_AGENCY_SUCCESS,
  };
};

const salesAgencyPageRequest = () => {
  return {
    type: actions.SALES_AGENCY_PAGE_REQUEST,
  };
};

const salesAgencyPageError = (message) => {
  return {
    type: actions.SALES_AGENCY_PAGE_ERROR,
    message,
  };
};

const salesAgencyPageSuccess = () => {
  return {
    type: actions.SALES_AGENCY_PAGE_SUCCESS,
  };
};

const getSingleAgentRequest = () => {
  return {
    type: actions.GET_SINGLE_AGENT_REQUEST,
  };
};

const getSingleAgentError = () => {
  return {
    type: actions.GET_SINGLE_AGENT_ERROR,
  };
};

const getSingleAgentSuccess = () => {
  return {
    type: actions.GET_SINGLE_AGENT_SUCCESS,
  };
};

export const fetchSalesAgency = (page, limit, searchTerm) => {
  let param = `?page=${page}&limit=${limit}`;
  if (searchTerm) {
    param = `search?searchWord=${searchTerm}&page=${page}&limit=${limit}`;
  }

  return (dispatch, getState) => {
    dispatch(getSalesAgencyRequest());
    return new Promise((resolve) => {
      axios
        .get(`${CONFIG.BASE_URL}/admin/sales-agencies/${param}`, {
          headers: {
            Authorization: getState().user.token,
          },
        })
        .then((response) => {
          dispatch(
            updateUser({
              token: response.headers.authorization,
            })
          );
          if (response.status === 200) {
            dispatch(getSalesAgencySuccess());
            resolve(response.data.data || response.data);
          }
        })
        .catch(({ response }) => {
          dispatch(getSalesAgencyError());
          dispatch(
            updateUser({
              token: response?.headers.authorization,
            })
          );
          if ([400, 404].includes(response?.status)) {
            response.data.message &&
              dispatch(
                showAlert({ type: "error", message: response.data.message })
              );
          }
          if (response?.status === 403) {
            return dispatch(
              showAlert({
                type: "error",
                message: "Oops! you are unauthorised",
              })
            );
          }
          if (response?.status === 401) {
            dispatch(
              showAlert({ type: "error", message: "Your session has expired" })
            );
            return setTimeout(() => dispatch(logout()), 2000);
          }
          dispatch(
            showAlert({
              type: "error",
              message: "Oops! something went wrong please try again.",
            })
          );
        });
    });
  };
};

export const createSalesAgency = (payload) => {
  return (dispatch, getState) => {
    dispatch(getSalesAgencyRequest());

    const { token } = getState().user;

    axios
      .post(`${CONFIG.BASE_URL}/admin/sales-agencies`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((response) => {
        dispatch(
          updateUser({
            token: response.headers.authorization,
          })
        );
        if ([200, 201].includes(response.status)) {
          dispatch(getSalesAgencySuccess(response.data));
          setTimeout(() => dispatch(clear()), 3000);
          return dispatch(
            showAlert({ type: "success", message: response.data.message })
          );
        }
        return;
      })
      .catch(({ response }) => {
        response &&
          dispatch(
            updateUser({
              token: response.headers.authorization,
            })
          );
        if (response && [400, 404, 403].includes(response.status)) {
          response.data.message &&
            dispatch(
              showAlert({ type: "error", message: response.data.message })
            );
          return dispatch(
            getSalesAgencyError(
              response.data.error ? response.data.error : response.data.message
            )
          );
        }
        if (response && [401].includes(response.status)) {
          dispatch(
            showAlert({ type: "error", message: "Your session has expired" })
          );
          return setTimeout(() => dispatch(logout()), 2000);
        }
        if (response && response.status >= 500) {
          return dispatch(getSalesAgencyError("Oops! We did something wrong."));
        }
        dispatch(getSalesAgencyError("Oops! We did something wrong."));
      });
  };
};

export const editSalesAgency = (id, status) => {
  const path = `/admin/sales-agencies/${id}`;
  const payload = { status };
  const config = {
    urlPath: path,
    method: "put",
    payload,
    requestAction: salesAgencyPageRequest,
    successAction: salesAgencyPageSuccess,
    errorAction: salesAgencyPageError,
  };
  return apiRequestHandler(config);
};

export const fetchSingleAgent = (id) => {
  return (dispatch, getState) => {
    dispatch(getSingleAgentRequest());
    return new Promise((resolve) => {
      axios
        .get(`${CONFIG.BASE_URL}/admin/sales-agencies/${id}/agents`, {
          headers: {
            Authorization: getState().user.token,
          },
        })
        .then((response) => {
          dispatch(
            updateUser({
              token: response.headers.authorization,
            })
          );
          if (response.status === 200) {
            dispatch(getSingleAgentSuccess());
            resolve(response.data);
          }
        })
        .catch(({ response }) => {
          dispatch(getSingleAgentError());
          dispatch(
            updateUser({
              token: response?.headers.authorization,
            })
          );
          if ([400, 404].includes(response?.status)) {
            response.data.message &&
              dispatch(
                showAlert({ type: "error", message: response.data.message })
              );
          }
          if (response?.status === 403) {
            return dispatch(
              showAlert({
                type: "error",
                message: "Oops! you are unauthorised",
              })
            );
          }
          if (response?.status === 401) {
            dispatch(
              showAlert({ type: "error", message: "Your session has expired" })
            );
            return setTimeout(() => dispatch(logout()), 2000);
          }
          dispatch(
            showAlert({
              type: "error",
              message: "Oops! something went wrong please try again.",
            })
          );
        });
    });
  };
};

export const fetchAgentPerformance = (id) => {
  return (dispatch, getState) => {
    dispatch(getSingleAgentRequest());
    return new Promise((resolve) => {
      axios
        .get(`${CONFIG.BASE_URL}/admin/sales-agencies/performance/${id}`, {
          headers: {
            Authorization: getState().user.token,
          },
        })
        .then((response) => {
          dispatch(
            updateUser({
              token: response.headers.authorization,
            })
          );
          if (response.status === 200) {
            dispatch(getSingleAgentSuccess());
            resolve(response.data);
          }
        })
        .catch(({ response }) => {
          dispatch(getSingleAgentError());
          dispatch(
            updateUser({
              token: response?.headers.authorization,
            })
          );
          if ([400, 404].includes(response?.status)) {
            response.data.message &&
              dispatch(
                showAlert({ type: "error", message: response.data.message })
              );
          }
          if (response?.status === 403) {
            return dispatch(
              showAlert({
                type: "error",
                message: "Oops! you are unauthorised",
              })
            );
          }
          if (response?.status === 401) {
            dispatch(
              showAlert({ type: "error", message: "Your session has expired" })
            );
            return setTimeout(() => dispatch(logout()), 2000);
          }
          dispatch(
            showAlert({
              type: "error",
              message: "Oops! something went wrong please try again.",
            })
          );
        });
    });
  };
};

export const createSingleAgent = (payload) => {
  return (dispatch, getState) => {
    dispatch(getSingleAgentRequest());

    const { token } = getState().user;

    axios
      .post(`${CONFIG.BASE_URL}/admin/sales-agencies/agents`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((response) => {
        dispatch(
          updateUser({
            token: response.headers.authorization,
          })
        );
        if ([200, 201].includes(response.status)) {
          dispatch(getSingleAgentSuccess(response.data));
          setTimeout(() => dispatch(clear()), 3000);
          return dispatch(
            showAlert({ type: "success", message: response.data.message })
          );
        }
        return;
      })
      .catch(({ response }) => {
        response &&
          dispatch(
            updateUser({
              token: response.headers.authorization,
            })
          );
        if (response && [400, 404, 403].includes(response.status)) {
          response.data.message &&
            dispatch(
              showAlert({ type: "error", message: response.data.message })
            );
          return dispatch(
            getSingleAgentError(
              response.data.error ? response.data.error : response.data.message
            )
          );
        }
        if (response && [401].includes(response.status)) {
          dispatch(
            showAlert({ type: "error", message: "Your session has expired" })
          );
          return setTimeout(() => dispatch(logout()), 2000);
        }
        if (response && response.status >= 500) {
          return dispatch(getSingleAgentError("Oops! We did something wrong."));
        }
        dispatch(getSingleAgentError("Oops! We did something wrong."));
      });
  };
};
