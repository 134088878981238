import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getActionLoadingState } from "@/store/selectors";
import {
  getGuideCategoryBystatus,
  updateGuideCategorystatus,
} from "@/store/support/actions";
import actionTypes from "@/store/support/actionTypes";
import Pagination from "@/components/Pagination";
import Emptystate from "@/components/Emptystate";
import Modal from "@/components/PopModal";
import "./style.scss";

class PendingCategories extends React.Component {
  state = {
    showConfirmModal: false,
    showConfirmApprovalModal: false,
    selectedItem: null,
    approveItem: "",
  };

  componentDidMount() {
    this.getCategories(1, 10);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.searchString !== prevProps.searchString &&
      this.props.searchString !== ""
    ) {
      this.getCategories(1, 10, this.props.searchString);
    }
    if (
      this.props.searchString !== prevProps.searchString &&
      this.props.searchString === ""
    ) {
      this.getCategories(1, 10);
    }
  }

  getCategories = (page, limit, searchTerm) => {
    this.props.getGuideCategoryBystatus("pending", page, limit, searchTerm);
  };

  showConfirmApproval = (id) => {
    this.setState({ showConfirmApprovalModal: true, approveItem: id });
  };

  handleApprove = (id) => {
    const { approveItem } = this.state;
    this.props
      .updateGuideCategorystatus(approveItem, { status: "active" })
      .then((data) => {
        this.setState({ showConfirmApprovalModal: false });
        this.getCategories(1, 10);
      });
  };

  showConfirm = (id) => {
    this.setState({ showConfirmModal: true, itemId: id });
  };

  handleReject = () => {
    const { itemId } = this.state;
    this.props
      .updateGuideCategorystatus(itemId, { status: "rejected" })
      .then((data) => {
        this.setState({ showConfirmModal: false });
        this.getCategories(1, 10);
      });
  };

  toggleModal = () => {
    this.setState({ showConfirmModal: !this.state.showConfirmModal });
  };

  render() {
    const { pendingCategories, loading, updateLoading } = this.props;
    const { showConfirmApprovalModal, showConfirmModal } = this.state;

    return (
      <div className="pending-guide-categories-page">
        {showConfirmModal && (
          <Modal onClose={this.toggleModal}>
            <div className="text-center">
              <h3>Confirm</h3>
              <p>Are you sure you want to reject this category?</p>
              <div className="d-flex">
                <button
                  className="btn btn-sm btn-white btn-block mt-3 mr-2"
                  onClick={() => this.setState({ showConfirmModal: false })}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-sm btn-danger btn-block mt-3 ml-2 d-flex align-items-center justify-content-center"
                  onClick={this.handleReject}
                >
                  Confirm
                  {updateLoading && (
                    <div className="spinner-border spinner-border-white spinner-border-sm ml-2"></div>
                  )}
                </button>
              </div>
            </div>
          </Modal>
        )}
        {showConfirmApprovalModal && (
          <Modal onClose={this.toggleModal}>
            <div className="text-center">
              <h3>Confirm</h3>
              <p>Are you sure you want to Approve this category?</p>
              <div className="d-flex">
                <button
                  className="btn btn-sm btn-white btn-block mt-3 mr-2"
                  onClick={() =>
                    this.setState({ showConfirmApprovalModal: false })
                  }
                >
                  Cancel
                </button>
                <button
                  className="btn btn-sm btn-primary btn-block mt-3 ml-2 d-flex align-items-center justify-content-center"
                  onClick={this.handleApprove}
                >
                  Confirm
                  {updateLoading && (
                    <div className="spinner-border spinner-border-white spinner-border-sm ml-2"></div>
                  )}
                </button>
              </div>
            </div>
          </Modal>
        )}
        <div className="text-center">
          {!pendingCategories && loading && (
            <div className="spinner-border text-primary ml-auto mr-auto"></div>
          )}
        </div>
        {pendingCategories &&
          (pendingCategories?.data.length > 0 ? (
            <>
              <Pagination
                totalPages={pendingCategories.lastPage}
                page={pendingCategories.page}
                limit={pendingCategories.perPage}
                changePageHandler={(page, limit) =>
                  this.getCategories(page, limit)
                }
              />
              <div className="setup-inner__main">
                <table className="custum-table">
                  <tbody>
                    <tr>
                      <th>Category Name</th>
                      <th>Description</th>
                      <th>Action Performed</th>
                      <th className="actions-column">Actions</th>
                    </tr>
                    {pendingCategories?.data.map((guide) => (
                      <tr key={guide.id}>
                        <td>{guide.title}</td>
                        <td style={{ maxWidth: "25rem", whiteSpace: "normal" }}>
                          {guide.content}
                        </td>
                        <td>{guide.action}</td>
                        <td>
                          <div className="d-flex">
                            <button
                              className="btn btn-xsm btn-primary mr-3"
                              onClick={() => this.showConfirmApproval(guide.id)}
                            >
                              Approve
                            </button>
                            <button
                              className="btn btn-xsm btn-white color-red"
                              onClick={() => this.showConfirm(guide.id)}
                            >
                              Reject
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan="6" className="text-center">
                        Showing {pendingCategories?.data.length} of 
                        {pendingCategories?.total}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <Emptystate
              title={
                this.props.searchString === ""
                  ? "No categories pending approval"
                  : "No category pending approval matches the search word"
              }
              icon={require("@/assets/icons/info.svg").default}
            />
          ))}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    app: {
      support: { error, data, pendingCategories },
    },
  } = state;
  return {
    loading: getActionLoadingState(
      state,
      actionTypes.GET_GUIDE_CATEGORY_BY_STATUS_REQUEST
    ),
    updateLoading: getActionLoadingState(
      state,
      actionTypes.UPDATE_GUIDE_CATEGORY_STATUS_REQUEST
    ),
    rejectLoading: getActionLoadingState(
      state,
      actionTypes.REMOVE_GUIDE_CATEGORY_REQUEST
    ),
    error,
    data,
    pendingCategories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGuideCategoryBystatus: (status, page, limit, searchTerm) =>
      dispatch(getGuideCategoryBystatus(status, page, limit, searchTerm)),
    updateGuideCategorystatus: (id, payload) =>
      dispatch(updateGuideCategorystatus(id, payload)),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PendingCategories)
);
