import * as actions from "./actionTypes";
import investments from '@/store/investments';
import support from '@/store/support';
import blog from '@/store/blog';
import notifications from '@/store/notifications';
import ppi from '@/store/ppi';
import portfolio from '@/store/portfolio';
import reports from '@/store/reports';

const dashboardInfo = (state = {}, action) => {
    switch(action.type) {
        case actions.FETCH_DASHBOARD_SUCCESS:
            return action.data;
        default:
            return state
    }
}

export default (state = {}, action) => {
  return {
    dashboardInfo: dashboardInfo(state.dashboardInfo, action),
    notifications: notifications(state.notifications, action),
    investments: investments(state.investments, action),
    support: support(state.support, action),
    reports: reports(state.reports, action),
    blog: blog(state.blog, action),
    ppi: ppi(state.ppi, action),
    portfolio: portfolio(state.portfolio, action),
  }
}
