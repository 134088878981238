import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Redirect } from "react-router-dom";
import { logoutUser } from "@/store/auth/actions";
import { changeUserPicture } from "@/store/user/actions";
import { getActionLoadingState } from "@/store/selectors";
import {
  getAllNotifications,
  connectToSocket,
} from "@/store/notifications/actions";
import actionTypes from "@/store/user/actionTypes";
import DashboardMenu from "./DashboardMenu";
import DashboardHome from "./DashboardHome";
import NotFound from "@/components/NotFound";
import FloatingToastAlert from "@/components/FloatingToastAlert";
import ImageUploadInput from "@/components/ImageUploadInput";
import RightPanel from "@/components/RightPanel";
import Dropdown from "@/components/DropdownMenu";
import Notifications from "@/components/Notifications";
import Reports from "./Reports";
import Analytics from "./Analytics";
import SalesAgency from "./SalesAgency";
import Settings from "./Settings";
import Setup from "./Setup";
import Support from "./Support";
import Transactions from "./Transactions";
import Users from "./Users";
import TransactionHistory from "./User/TransactionHistory";
import UserPortfolio from "./User/Portfolio";
import NoNofit from "@/assets/icons/notification-bell.svg";
import NewNofit from "@/assets/icons/new-notification.svg";
import { checkUnread } from "@/utils";
import "./style.scss";
import { BellIcon } from "@heroicons/react/outline";
import agents from "./SalesAgency/agents";
import Agents from "./SalesAgency/agents";

class Dashboard extends Component {
  state = {
    open: false,
    newPicture: "",
    notif: false,
  };

  componentDidMount() {
    this.props.connectToSocket();
    this.props.getAllNotifications(20, 1);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.notifications !== this.props.notifications) {
      const result = checkUnread(this.props.notifications);
      if (result) {
        return this.updateNotifIcon(true);
      }
      this.updateNotifIcon(false);
    }
  }

  updateNotifIcon = (bool) => {
    this.setState({ notif: bool });
  };

  removeNotif = () => {
    this.setState({ notif: false });
  };

  panelButtonClick = () => {
    if (this.state.newPicture) {
      this.props.changeUserPicture(this.state.newPicture).then(() => {
        this.setState({
          open: false,
          newPicture: "",
        });
      });
    } else {
      this.setState({ open: false });
    }
  };

  render() {
    const {
      match: { path },
      location: { pathname },
      alert,
      user: {
        authorized,
        isStaff,
        firstName,
        lastName,
        picture,
        email,
        phone,
        role,
      },
      changingPicture,
      notifications,
      notificationsMeta,
    } = this.props;
    const { notif } = this.state;

    const dropdown = [
      {
        name: (
          <>
            <img
              src={require("@/assets/icons/users.svg").default}
              alt="users"
            />
            Profile
          </>
        ),
        handler: () => this.setState({ open: true }),
      },
      {
        name: (
          <>
            <img
              src={require("@/assets/icons/logout.svg").default}
              alt="logout"
            />
            Logout
          </>
        ),
        handler: () => this.props.logoutUser(),
      },
    ];

    const segments = pathname.split("/");

    const pageTitle =
      segments.length > 2 ? segments[2].replace(/-/g, " ") : "Default Title";

    return (
      <div className="dashboard">
        <DashboardMenu />
        <div className="dashboard-body">
          <div className="dashboard-header">
            <h1 className="dashboard-header__title font-primary text-3xl text-blue-900">
              {pageTitle === "home" ? "Dashboard" : pageTitle}
            </h1>
            <Notifications
              notifications={notifications}
              meta={notificationsMeta}
              hideNotif={this.removeNotif}
            >
              <div className="relative group mr-4">
                <BellIcon
                  className="size-10 text-gray-500 group-hover:text-blue-500"
                  aria-hidden="true"
                />
                {notif && (
                  <span className="absolute top-0 right-0 h-3 w-3 rounded-full bg-red-500 ring-2 ring-white"></span>
                )}
              </div>
            </Notifications>
            <Dropdown menu={dropdown} left width="150px">
              <img
                src={
                  picture || require("@/assets/icons/img-default.svg").default
                }
                alt="profile"
                className="dashboard-header__profile-picture rounded-circle"
              />
            </Dropdown>
            <div className="dashboard-header__user-details">
              <span className="dashboard-header__user-name">{`${firstName} ${lastName}`}</span>
              <span className="dashboard-header__user-role">Admin</span>
            </div>
            <RightPanel
              open={this.state.open}
              onClose={() => this.setState({ open: false })}
            >
              <h1 className="panel-heading">Your Profile</h1>
              <div className="panel-info">
                <ImageUploadInput
                  id="picture"
                  name="picture"
                  handleFile={(image) => this.setState({ newPicture: image })}
                  currentImageURL={
                    picture || require("@/assets/icons/img-default.svg")
                  }
                  removePrevie={!this.state.open}
                />
                <div className="panel-info__row">
                  <span className="panel-info__field">Full Name</span>
                  <span className="panel-info__value">{`${firstName} ${lastName}`}</span>
                </div>
                <div className="panel-info__row">
                  <span className="panel-info__field">Phone number</span>
                  <span className="panel-info__value">{phone}</span>
                </div>
                <div className="panel-info__row">
                  <span className="panel-info__field">Email address</span>
                  <span className="panel-info__value">{email}</span>
                </div>
                <div className="panel-info__row">
                  <span className="panel-info__field">Role</span>
                  <span className="panel-info__value">{role}</span>
                </div>
                <button className="long-button" onClick={this.panelButtonClick}>
                  {this.state.newPicture ? "Save" : "Close"}{" "}
                  {changingPicture && (
                    <div className="spinner-border spinner-border-blue spinner-border-sm ml-2 mb-2"></div>
                  )}
                </button>
              </div>
            </RightPanel>
          </div>
          <div className="dashboard-body">
            {alert && <FloatingToastAlert alert={alert} />}
            <Switch>
              {!authorized && <Redirect to="/login" />}
              {!isStaff && <Redirect to="/login" />}
              <Route exact path={path}>
                <Redirect to={`${path}/home`} />
              </Route>
              <Route path={`${path}/home`}>
                <DashboardHome />
              </Route>
              <Route path={`${path}/analytics`}>
                <Analytics />
              </Route>
              <Route path={`${path}/sales-agencies`}>
                <SalesAgency />
              </Route>
              <Route path={`${path}/reports`}>
                <Reports />
              </Route>
              <Route path={`${path}/settings`}>
                <Settings />
              </Route>
              <Route path={`${path}/setup`}>
                <Setup />
              </Route>
              <Route path={`${path}/support`}>
                <Support />
              </Route>
              <Route path={`${path}/transactions`}>
                <Transactions />
              </Route>
              <Route path={`${path}/users`}>
                <Users />
              </Route>
              <Route path={`${path}/user/:id/transactions`}>
                <TransactionHistory />
              </Route>
              <Route path={`${path}/user/:id/portfolio`}>
                <UserPortfolio />
              </Route>
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    ui: {
      alerts: { ui_SHOW_ALERT },
    },
    app: { notifications },
    user,
  } = state;
  return {
    alert: ui_SHOW_ALERT,
    changingPicture: getActionLoadingState(
      state,
      actionTypes.CHANGE_USER_PICTURE_REQUEST
    ),
    user,
    notifications: notifications?.notifications,
    notificationsMeta: notifications?.meta,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => dispatch(logoutUser()),
    changeUserPicture: (picture) => dispatch(changeUserPicture(picture)),
    connectToSocket: () => dispatch(connectToSocket()),
    getAllNotifications: (limit, page) =>
      dispatch(getAllNotifications(limit, page)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
