import React from 'react';
import moment from 'dayjs';
import './style.scss';

class PreviewBlog extends React.Component {

  contentRef = React.createRef();

  componentDidMount() {
    this.contentRef.current.innerHTML = `${this.props.content}`
  }

  render() {
    const { image, title } = this.props;
    return (
      <div className="preview-blog-page">
        <p className="text-small cursor-pointer" onClick={this.props.handlePreview}>{'< Back'}</p>
        <p className="text-small mt-4">{moment().format('MMM DD, YYYY')}</p>
        <h2>{title}</h2>
        {image && <img
          className="feature-img img-fluid mt-3"
          alt="feature"
          src={image}
        />}
        <div className="content mt-3 border-bottom pb-4">
          <div ref={this.contentRef}></div>
        </div>
      </div>
    )
  }
}

export default PreviewBlog;
