import React, { Component } from "react";
import { connect } from "react-redux";
import {
  createSingleAgent,
  fetchSingleAgent,
  fetchAgentPerformance,
  fetchSalesAgency,
} from "@/store/setup/salesAgencyAction";
import { clearError } from "@/store/ui/actions";
import actionTypes from "@/store/setup/actionTypes";
import { getActionLoadingState, getActionErrorState } from "@/store/selectors";
import Alert from "@/components/Alert";
import { serializeErrors, validateFields } from "@/utils";
import InputError from "@/components/InputError";
import RightPanel from "@/components/RightPanel";
import Emptystate from "@/components/Emptystate";
import ExportDropdown from "@/components/ExportDropdown";
import MultipleApprovalDropdown from "@/components/MultipleApprovalDropdown";
import ConfirmationBox from "@/components/ConfirmationBox";
import Pagination from "@/components/Pagination";
import Dropdown from "@/components/DropdownMenu";
import { withRouter, Route, Switch, NavLink } from "react-router-dom";
import PhoneNumberInput from "@/components/PhoneNumberInput";
import AgentPerformance from "./agent-performance";
import { PlusIcon } from "@heroicons/react/outline";
import { ArrowCircleLeftIcon } from "@heroicons/react/outline";
import SearchBox from "@/components/SearchBox";
import dayjs from "dayjs";
import { states } from "./states";

class Agents extends Component {
  state = {
    countryCode: "+234",
    open: false,
    createAgentOpen: false,
    agentDetailsOpen: false,
    showPerformancePanel: false,
    editing: false,
    currentAgentData: {},
    pendingConfigs: [],
    agents: [],
    currentValue: "",
    newValue: "",
    confirmationContext: "",
    selectedRowsIds: [],
    searchTerm: "",
    name: "",
    email: "",
    phone: "",
    sales_agency_id: 0,
    selectedState: "",
    salesagency: [],
    formErrors: {
      name: "",
      email: "",
      phone: "",
      sales_agency_id: "",
      selectedState: "",
    },
    touched: {
      name: false,
      email: false,
      phone: false,
      sales_agency_id: false,
      selectedState: false,
    },
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) {
      this.getSingleAgent(id, 1, 10, this.state.searchTerm);
    }
    {
      this.getSalesAgency(1, 10, this.state.searchTerm);
    }
  }

  validateField = (name, value) => {
    let error = "";

    switch (name) {
      case "name":
        if (!value.trim()) {
          error = "Name is required";
        } else if (value.length < 2) {
          error = "Name must be at least 2 characters";
        }
        break;

      case "email":
        if (!value) {
          error = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          error = "Email is invalid";
        }
        break;

      case "phone":
        if (!value) {
          error = "Phone number is required";
        } else if (value.length < 10) {
          error = "Phone number must be at least 10 digits";
        }
        break;

      case "sales_agency_id":
        if (!value) {
          error = "Sales Agency is required";
        }
        break;

      case "selectedState":
        if (!value) {
          error = "State is required";
        }
        break;

      default:
        break;
    }

    return error;
  };

  onInputChange = (e) => {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      [name]: value,
      touched: {
        ...prevState.touched,
        [name]: true,
      },
      formErrors: {
        ...prevState.formErrors,
        [name]: this.validateField(name, value),
      },
    }));
  };

  onSalesAgencyInputChange = (e) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      sales_agency_id: value,
      touched: {
        ...prevState.touched,
        sales_agency_id: true,
      },
      formErrors: {
        ...prevState.formErrors,
        sales_agency_id: this.validateField("sales_agency_id", value),
      },
    }));
  };

  validateForm = () => {
    const { name, email, phone, sales_agency_id, selectedState } = this.state;
    const errors = {
      name: this.validateField("name", name),
      email: this.validateField("email", email),
      phone: this.validateField("phone", phone),
      sales_agency_id: this.validateField("sales_agency_id", sales_agency_id),
      selectedState: this.validateField("selectedState", selectedState),
    };

    // Mark all fields as touched
    const touched = {
      name: true,
      email: true,
      phone: true,
      sales_agency_id: true,
      selectedState: true,
    };

    this.setState({ formErrors: errors, touched });

    return !Object.values(errors).some((error) => error);
  };

  getSingleAgent = (agencyId, page, limit, searchTerm) => {
    console.log("Fetching agents with ID:", agencyId); // Add this
    this.props
      .fetchSingleAgent(agencyId, page, limit, searchTerm)
      .then((agents) => {
        console.log("Received agents:", agents); // Add this
        this.setPageData(agents, searchTerm, false);
      });
  };

  getSalesAgency = (page, limit, searchTerm) => {
    this.props.fetchSalesAgency(page, limit, searchTerm).then((response) => {
      if (response && response.data) {
        this.setState({
          salesagency: response.data,
        });
      }
    });
  };

  setPageData = (
    { lastPage, perPage, page, total, data: agents, salesagency },
    searchTerm,
    shouldSearch
  ) => {
    console.log("Setting page data with agents:", agents); // Add this
    this.setState({
      agents,
      lastPage,
      perPage,
      page,
      total,
      searchTerm,
      shouldSearch,
    });
  };

  handleSearch = (searchTerm) => {
    this.setState({ searchTerm, page: 1 });
  };

  onCheckRow = (e, rowId) => {
    const selectedRows = [...this.state.selectedRowsIds];
    if (e.target.checked) {
      selectedRows.push(rowId);
    } else {
      const uncheckedRowIndex = selectedRows.findIndex((id) => id === rowId);
      selectedRows.splice(uncheckedRowIndex, 1);
    }
    this.setState({ selectedRowsIds: selectedRows });
  };

  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onRowClick = (e, data) => {
    this.setState(
      {
        currentAgentData: data,
      },
      () => {
        this.openAgentDetailsPanel(e);
      }
    );
  };

  onPerformanceClick = (e, data) => {
    this.setState(
      {
        currentAgentData: data,
      },
      () => {
        this.props.fetchAgentPerformance(data.id).then((performanceData) => {
          this.setState({
            performanceData,
            showPerformancePanel: true,
          });
        });
      }
    );
  };

  dropdownmenus = (data) => {
    return [
      {
        name: "View Agent",
        handler: (e) => this.onRowClick(e, data),
      },
      {
        name: "Agent Performance",
        handler: (e) => this.onPerformanceClick(e, data),
      },
    ].filter(Boolean);
  };

  renderAgents = () => {
    return (
      <>
        <div className="setup-inner__main">
          <table className="custum-table">
            <tbody>
              <tr>
                <th></th>
                <th>ID</th>
                <th>Name</th>
                {/* <th>Phone</th> */}
                <th>Reward Amount</th>
                <th>Total Referrals</th>
                <th>Total Deposits</th>
                <th>Total Investments</th>
                <th>Total Withdrawals</th>
                <th>Date Enrolled</th>
                <th className="actions-column">Actions</th>
              </tr>
              {this.state?.agents?.data.map((data) => {
                return (
                  <tr key={data.id}>
                    <td className="row-checkbox">
                      <input
                        type="checkbox"
                        onChange={(e) => this.onCheckRow(e, data.id)}
                      />
                    </td>
                    <td className="text-center">{data.id}</td>
                    <td>{data.name}</td>
                    {/* <td>{data.phone}</td> */}
                    <td className="font-heading">{data.reward_amount}</td>
                    <td>{data.total_referrals}</td>
                    <td>{data.total_deposits}</td>
                    <td>{data.total_investments}</td>
                    <td>{data.total_withdrawals}</td>
                    <td className="">
                      {dayjs(data.created_at).format("D MMM, YYYY h:mm a")}
                    </td>
                    <td className="custum-table__ellipsis">
                      <Dropdown
                        menu={this.dropdownmenus(data)}
                        width="117px"
                        arrow={true}
                      >
                        <button className="wrapper-button ellipsis">
                          <img
                            src={
                              require("@/assets/icons/flat-ellipsis.svg")
                                .default
                            }
                            alt="dropdown"
                          />
                        </button>
                      </Dropdown>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  requestApproval = (e) => {
    e.preventDefault();
    const { configId, newValue } = this.state;
    this.props.changeConfiguration(configId, newValue).then(() => {
      this.setState({
        open: false,
        confirmationSuccess: true,
        confirmationContext: "Success",
      });
    });
  };

  handleError = (errorMessage) => {
    this.setState({
      createAgentOpen: false,
      errors: { api: errorMessage || "An unexpected error occurred." },
    });
  };
  createAgent = async (e) => {
    e.preventDefault();

    if (!this.validateForm()) {
      return;
    }

    const { createSingleAgent, match } = this.props;
    const { name, email, phone, sales_agency_id, selectedState } = this.state;

    const payload = {
      name,
      email,
      phone,
      sales_agency_id: match.params.id || sales_agency_id,
      state: selectedState,
    };

    try {
      await createSingleAgent(payload);

      // Reset form state
      this.setState({
        createAgentOpen: false,
        name: "",
        email: "",
        phone: "",
        sales_agency_id: "",
        selectedState: "",
        formErrors: {
          name: "",
          email: "",
          phone: "",
          sales_agency_id: "",
          selectedState: "",
        },
        touched: {
          name: false,
          email: false,
          phone: false,
          sales_agency_id: false,
          selectedState: false,
        },
      });

      const { id: agencyId } = this.props.match.params;
      if (agencyId) {
        await this.getSingleAgent(agencyId, 1, 10, this.state.searchTerm);
      }
    } catch (error) {
      this.handleError(error.message);
    }
  };

  renderError = (fieldName) => {
    const { formErrors, touched } = this.state;
    if (touched[fieldName] && formErrors[fieldName]) {
      return (
        <div className="text-red-500 text-sm -mt-5 mb-4">{formErrors[fieldName]}</div>
      );
    }
    return null;
  };

  removeFromList = (ids) => {
    let pendingConfigs;
    if (Array.isArray(ids)) {
      pendingConfigs = this.state.pendingConfigs.filter(
        (data) => !ids.includes(data.id)
      );
    } else {
      pendingConfigs = [...this.state.pendingConfigs];
      const configIndex = pendingConfigs.findIndex(
        (config) => config.id === ids
      );
      pendingConfigs.splice(configIndex, 1);
    }
    this.setState({
      pendingConfigs,
      selectedRowsIds: [],
      confirmationSuccess: true,
    });
  };

  updateConfig = (status) => {
    const { configId, isMultipleApproval, selectedRowsIds } = this.state;
    const target = isMultipleApproval ? selectedRowsIds : configId;
    this.props.updateConfiguration(target, status).then(() => {
      this.removeFromList(target);
    });
  };

  updateSnappyConfig = (status) => {
    const { configId, isMultipleApproval, selectedRowsIds } = this.state;
    const target = isMultipleApproval ? selectedRowsIds : configId;
    this.props.updateSnappyConfiguration(target, status).then(() => {
      this.removeFromList(target);
    });
  };

  confirmationData = () => {
    const { isMultipleApproval, selectedRowsIds } = this.state;
    const addS = isMultipleApproval && selectedRowsIds.length > 1;

    return {};
  };

  setConfirmationContext = (context, configId, isMultipleApproval) => {
    this.setState({
      confirmationContext: context,
      confirmationSuccess: false,
      configId,
      isMultipleApproval,
    });
  };

  onEditClick = (name, configId, currentValue) => {
    this.setState({
      name,
      configId,
      currentValue,
      newValue: currentValue,
      open: true,
    });
  };

  onCreateClick = (minTenor, maxTenor, minAmount, maxAmount, rate, penalty) => {
    this.setState({
      maxTenor,
      minTenor,
      minAmount,
      penalty,
      maxAmount,
      rate,
      createAgentOpen: true,
    });
  };

  closePanel = (e) => {
    e.preventDefault();
    this.setState({
      open: false,
      name: "",
      currentValue: "",
      configId: "",
    });
    this.props.clearError();
  };

  closeCreateAgentPanel = (e) => {
    e.preventDefault();
    this.setState({
      createAgentOpen: false,
    });
    this.props.clearError();
  };

  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  openAgentDetailsPanel = (e) => {
    e.preventDefault();
    this.setState({ agentDetailsOpen: true });
  };

  closeAgentDetailsPanel = (e) => {
    e.preventDefault();
    this.setState({ agentDetailsOpen: false });
  };

  openPerformancePanel = (e) => {
    e.preventDefault();
    this.setState({ showPerformancePanel: true });
  };

  closePerformancePanel = (e) => {
    e.preventDefault();
    this.setState({ showPerformancePanel: false });
  };

  onInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const { pageRequestError, confirmationLoading, fetching } = this.props;
    const {
      confirmationSuccess,
      confirmationContext,
      agents,
      searchTerm,
      lastPage,
      page,
      perPage,
    } = this.state;
    const prompt = this.confirmationData()[confirmationContext];
    const showMultiAprrovalDropdown = this.state.selectedRowsIds.length > 0;

    console.log("Current agents state:", this.state.agents.data); // Add this

    return (
      <div className="setup-inner">
        <Alert alert={pageRequestError} />
        <RightPanel
          open={this.state.createAgentOpen}
          onClose={this.closeCreateAgentPanel}
        >
          <h2 className="panel-heading">
            {this.state.editing ? "Edit Agent" : "Create Agent"}
          </h2>
          <form
            className="form employer-onboard__form"
            onSubmit={this.createAgent}
            noValidate
          >
            <label className="panel-label" htmlFor="name">
              Name
            </label>
            <input
              type="text"
              className={`form-control panel-input ${
                this.state.touched.name && this.state.formErrors.name ? 'error-input' : ''
              }`}
              id="name"
              name="name"
              value={this.state.name}
              onChange={this.onInputChange}
              autoComplete="off"
            />
            {this.renderError('name')}

            <label className="panel-label" htmlFor="email">
              Email
            </label>
            <input
              type="text"
              className={`form-control panel-input ${
                this.state.touched.email && this.state.formErrors.email ? 'error-input' : ''
              }`}
              id="email"
              name="email"
              value={this.state.email}
              onChange={this.onInputChange}
              autoComplete="off"
            />
            {this.renderError('email')}

            <PhoneNumberInput
              onInputChange={this.onInputChange}
              countryCodeValue={this.state.countryCode}
              phoneValue={this.state.phone}
              error={this.state.touched.phone && this.state.formErrors.phone}
            />
            {this.renderError('phone')}

            <label className="panel-label" htmlFor="sales_agency">
              Sales Agency
            </label>
            <select
              className={`form-control panel-input ${
                this.state.touched.sales_agency_id && this.state.formErrors.sales_agency_id ? 'error-input' : ''
              }`}
              id="sales_agency"
              name="sales_agency_id"
              value={this.state.sales_agency_id}
              onChange={this.onSalesAgencyInputChange}
            >
              <option value="">Select Sales Agency</option>
              {this.state.salesagency &&
                this.state.salesagency.map((agency) => (
                  <option key={agency.id} value={agency.id}>
                    {agency.name}
                  </option>
                ))}
            </select>
            {this.renderError('sales_agency_id')}

            <label className="panel-label" htmlFor="state">
              State
            </label>
            <select
              className={`form-control panel-input ${
                this.state.touched.selectedState && this.state.formErrors.selectedState ? 'error-input' : ''
              }`}
              id="state"
              name="selectedState"
              value={this.state.selectedState}
              onChange={this.onInputChange}
            >
              <option value="">Select State</option>
              {states.map((state) => (
                <option value={state} key={state}>
                  {state}
                </option>
              ))}
            </select>
            {this.renderError('selectedState')}

            <div className="dual-button-box">
              <button
                type="button"
                onClick={this.closeCreateAgentPanel}
                className="cp-button-blue"
              >
                Cancel
              </button>
              <button className="cp-button-blue">
                Save Changes
                {this.props.confirmationLoading && (
                  <div className="spinner-border spinner-border-white spinner-border-sm ml-2 mb-2"></div>
                )}
              </button>
            </div>
          </form>
        </RightPanel>
        <RightPanel
          open={this.state.agentDetailsOpen}
          onClose={this.closeAgentDetailsPanel}
        >
          <h1 className="panel-heading">Agent Details</h1>
          <div className="panel-info">
            <div className="panel-info__row">
              <span className="panel-info__field">Name</span>
              <span className="panel-info__value">
                {this.state.currentAgentData.name}
              </span>
            </div>
            <div className="panel-info__row">
              <span className="panel-info__field">Email</span>
              <span className="panel-info__value">
                {this.state.currentAgentData.email}
              </span>
            </div>
            <div className="panel-info__row">
              <span className="panel-info__field">Phone</span>
              <span className="panel-info__value">
                {this.state.currentAgentData.phone}
              </span>
            </div>
            <RightPanel
              open={this.state.showPerformancePanel}
              onClose={() => this.setState({ showPerformancePanel: false })}
              classes="agent-performance-panel"
            >
              <h1 className="panel-heading">Agent Performance</h1>
              <div className="panel-info">
                {this.state.performanceData && (
                  <>
                    <div className="panel-info__row">
                      <span className="panel-info__field">
                        Total Commission
                      </span>
                      <span className="panel-info__value">
                        {this.state.performanceData.total_commission}
                      </span>
                    </div>
                    <div className="panel-info__row">
                      <span className="panel-info__field">Total Referrals</span>
                      <span className="panel-info__value">
                        {this.state.performanceData.total_referrals}
                      </span>
                    </div>
                    <div className="panel-info__row">
                      <span className="panel-info__field">
                        Active Referrals
                      </span>
                      <span className="panel-info__value">
                        {this.state.performanceData.active_referrals}
                      </span>
                    </div>
                    <div className="panel-info__row">
                      <span className="panel-info__field">
                        Total Referral Value
                      </span>
                      <span className="panel-info__value">
                        {this.state.performanceData.total_referral_value}
                      </span>
                    </div>
                  </>
                )}
              </div>
            </RightPanel>
            <div className="panel-info__row">
              <span className="panel-info__field">Total Withdrawals</span>
              <span className="panel-info__value">
                {this.state.currentAgentData.total_withdrawals}
              </span>
            </div>
            <div className="panel-info__row">
              <span className="panel-info__field">Link</span>
              <span className="panel-info__value">
                {this.state.currentAgentData.link}
              </span>
            </div>
            <div className="panel-info__row">
              <span className="panel-info__field">Referral code</span>
              <span className="panel-info__value">
                {this.state.currentAgentData.referral_code}
              </span>
            </div>
            <div className="panel-info__row">
              <span className="panel-info__field">Date Enrolled</span>
              <span className="panel-info__value">
                {dayjs(this.state.currentAgentData.created_at).format(
                  "D MMM, YYYY h:mm a"
                )}
              </span>
            </div>
          </div>
        </RightPanel>
        <RightPanel
          open={this.state.showPerformancePanel}
          onClose={() => this.setState({ showPerformancePanel: false })}
          classes="agent-performance-panel"
        >
          <h1 className="panel-heading">Agent Performance</h1>
          <div className="panel-info">
            {this.state.performanceData && (
              <>
                <div className="panel-info__row">
                  <span className="panel-info__field">Agent ID</span>
                  <span className="panel-info__value">
                    {this.state.performanceData.data?.id}
                  </span>
                </div>
                <div className="panel-info__row">
                  <span className="panel-info__field">Name</span>
                  <span className="panel-info__value">
                    {this.state.performanceData.data?.name}
                  </span>
                </div>
                <div className="panel-info__row">
                  <span className="panel-info__field">Reward Amount</span>
                  <span className="panel-info__value">
                    {this.state.performanceData.data?.reward_amount}
                  </span>
                </div>
                <div className="panel-info__row">
                  <span className="panel-info__field">Total Referrals</span>
                  <span className="panel-info__value">
                    {this.state.performanceData.data?.total_referrals}
                  </span>
                </div>
                <div className="panel-info__row">
                  <span className="panel-info__field">Total Withdrawals</span>
                  <span className="panel-info__value">
                    {this.state.performanceData.data?.total_withdrawals}
                  </span>
                </div>
                <div className="panel-info__row">
                  <span className="panel-info__field">Total Investments</span>
                  <span className="panel-info__value">
                    {this.state.performanceData.data?.total_investments}
                  </span>
                </div>
                <div className="panel-info__row">
                  <span className="panel-info__field">Total Deposits</span>
                  <span className="panel-info__value">
                    {this.state.performanceData.data?.total_deposits}
                  </span>
                </div>
                <div className="panel-info__row">
                  <span className="panel-info__field">Loyalty Points</span>
                  <span className="panel-info__value">
                    {this.state.performanceData.data?.loyalty_points}
                  </span>
                </div>
                <div className="panel-info__row">
                  <span className="panel-info__field">Enrollment Date</span>
                  <span className="panel-info__value">
                    {dayjs(this.state.performanceData.data?.created_at).format(
                      "D MMM, YYYY h:mm a"
                    )}
                  </span>
                </div>
                <div className="panel-info__row">
                  <span className="panel-info__field">Status</span>
                  <span className="panel-info__value">
                    {this.state.performanceData.data?.status}
                  </span>
                </div>
              </>
            )}
          </div>
        </RightPanel>
        <div className="setup-inner__top d-flex justify-between w-100">
          <ArrowCircleLeftIcon
            className="size-10 stroke-blue-900 cursor-pointer cp-pagination__arrow--active"
            onClick={() => this.props.history.goBack()}
          />
          <div className="w-1/2 flex justify-end">
            <SearchBox
              placeholder="Search"
              handleSearch={(searchTerm) =>
                this.setState({ searchTerm, shouldSearch: true })
              }
              isActiveSearch={Boolean(searchTerm)}
              key={this.props.location.pathname}
            />

            <button
              className="setup-inner__button"
              onClick={() =>
                this.setState({
                  createAgentOpen: true,
                  editing: false,
                  currentAgentData: {},
                  name: "",
                  email: "",
                  phone: "",
                  sales_agency_id: 0,
                })
              }
            >
              <PlusIcon className="size-6 mr-3" />
              <span>Create Agent</span>
            </button>
          </div>
          <ConfirmationBox
            open={confirmationContext}
            closeHandler={() => this.setState({ confirmationContext: "" })}
            success={confirmationSuccess}
            title={prompt?.title}
            question={prompt?.question}
            action={prompt?.action}
            loading={confirmationLoading}
            requiresReason={prompt?.requiresReason}
          />
          {/* <ExportDropdown
            module="configurations"
            status={
              this.isShowingActiveConfigurations()
                ? "approved"
                : "pending-approval"
            }
          /> */}
          {showMultiAprrovalDropdown && (
            <MultipleApprovalDropdown
              approve={() =>
                this.setConfirmationContext("approveConfig", null, true)
              }
              reject={() =>
                this.setConfirmationContext("rejectConfig", null, true)
              }
            />
          )}
        </div>
        {fetching ? (
          <div className="text-center text-primary">
            <div className="spinner-border" role="status"></div>
          </div>
        ) : (
          <div className="position-relative">
            {!agents?.data?.length ? (
              <Emptystate
                title={`${searchTerm ? "No Result Found" : "No agents"}`}
                icon={require("@/assets/icons/info.svg").default}
              />
            ) : (
              <>
                <Pagination
                  totalPages={lastPage}
                  page={page}
                  limit={perPage}
                  changePageHandler={(page, limit) =>
                    this.getSingleAgent(
                      this.props.match.params.id,
                      page,
                      limit,
                      searchTerm
                    )
                  }
                />
                <div className="table-overflow">
                  <div className="setup-inner__main setup-inner__expand">
                    {this.renderAgents()}
                  </div>
                  <div className="data-count">
                    Showing
                    <span className="font-weight-bold mx-2">{`${agents?.data?.length} of ${agents?.total}`}</span>
                    Agents
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fetching: getActionLoadingState(
      state,
      actionTypes.GET_SALES_AGENCY_REQUEST
    ),
    confirmationLoading: getActionLoadingState(
      state,
      actionTypes.SALES_AGENCY_PAGE_REQUEST
    ),
    pageRequestError: getActionErrorState(
      state,
      actionTypes.SALES_AGENCY_PAGE_ERROR
    ),
    permissions: state.user.permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSingleAgent: (page, limit, searchTerm, id) =>
      dispatch(fetchSingleAgent(page, limit, searchTerm, id)),
    createSingleAgent: (payload) => dispatch(createSingleAgent(payload)),
    fetchAgentPerformance: (id) => dispatch(fetchAgentPerformance(id)),
    fetchSalesAgency: (page, limit, searchTerm) =>
      dispatch(fetchSalesAgency(page, limit, searchTerm)),
    clearError: () => dispatch(clearError()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Agents));
