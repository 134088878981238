import axios from 'axios';
import CONFIG from '@/config';
import * as actions from "./actionTypes";
import { logout, updateUser } from "@/store/user/actions";
import { showAlert } from '@/store/ui/actions';
import { validate } from "@/utils";

const getFaqRequest = () => {
    return {
        type: actions.GET_FAQ_REQUEST,
    }
}

const getFaqError = () => {
    return {
        type: actions.GET_FAQ_ERROR,
    }
}

const getFaqSuccess = () => {
    return {
        type: actions.GET_FAQ_SUCCESS,
    }
}

const faqPageRequest = () => {
    return {
        type: actions.FAQ_PAGE_REQUEST,
    }
}

const faqPageError = (message) => {
    return {
        type: actions.FAQ_PAGE_ERROR,
        message,
    }
}

const faqPageSuccess = () => {
    return {
        type: actions.FAQ_PAGE_SUCCESS,
    }
}

export const fetchFaqs = (status, page, limit, id, searchWord) => {
    const statuses = {
        active: 'all',
        draft: 'draft',
        pending: 'pending-approval',
    }
    const path = id ? id : status === 'draft' ? 'draft' : `status/${status}`;
    const fullPath = `${CONFIG.BASE_URL}/admin/supports/faqs/${path}?page=${page}&limit=${limit}`
    const searchPath = `${CONFIG.BASE_URL}/admin/supports/faqs/search/${statuses[status]}?page=${page}&limit=${limit}&searchWord=${searchWord}`
    
    return (dispatch, getState) => {
        dispatch(getFaqRequest());
        return new Promise((resolve) => {
            axios.get(searchWord ? searchPath : fullPath, {
                headers: {
                    "Authorization": getState().user.token,
                },
            })
            .then(response => {
                dispatch(updateUser({
                    token: response.headers.authorization
                  }))
                if (response.status === 200) {
                    resolve(response.data.data);
                    dispatch(getFaqSuccess());
                }
            })
            .catch(({ response }) => {
                dispatch(getFaqError());
                dispatch(updateUser({token: response?.headers.authorization}))
                if(response?.status === 401) {
                    dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
                    return setTimeout(() => dispatch(logout()), 2000) 
                }
                if(response?.status === 403) {
                    return dispatch(showAlert({ type: 'error', message: 'Oops! you are unauthorised' }))
                }
                return dispatch(showAlert({ type: 'error', message: 'Oops! something went wrong please try again.' }))
            })
        })
    }
}

export const createOrEditFaq = (payload, editId, history, location) => {
    const required = ['title', 'content'];
    return (dispatch, getState) => {
        dispatch(validate(payload, required, showAlert))
        .then(() => {
            dispatch(faqPageRequest());
            axios({
                method: editId ? 'put' : 'post',
                url: `${CONFIG.BASE_URL}/admin/supports/faqs/${editId ?? ''}`,
                data: payload,
                headers: {'Authorization': getState().user.token}
            })
            .then(response => {
                dispatch(updateUser({
                    token: response.headers.authorization
                    }))
                if ([200, 201].includes(response.status)) {
                    dispatch(faqPageSuccess());
                    return location ? history.puch(location) : history.goBack();
                }
            })
            .catch(({ response }) => {
                dispatch(faqPageError({message: response?.data.error}));
                dispatch(updateUser({token: response?.headers.authorization}))
                if(response?.status === 401) {
                    dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
                    return setTimeout(() => dispatch(logout()), 2000) 
                }
                if(response?.status === 403) {
                    return dispatch(showAlert({ type: 'error', message: 'Oops! you are unauthorised' }))
                }
                return dispatch(showAlert({ type: 'error', message: 'Oops! something went wrong please try again.' }))
            })
        });
    }
}

export const updateOrDeleteFaq = (id, payload) => {
    // it is a delete request if payload is undefined
    return (dispatch, getState) => {
        dispatch(faqPageRequest());
        return new Promise((resolve) => {
            axios({
                method: payload ? 'put' : 'delete',
                url: `${CONFIG.BASE_URL}/admin/supports/faqs/${id}/${payload ? 'status' : ''}`,
                data: payload,
                headers: {'Authorization': getState().user.token}
            })
            .then(response => {
                dispatch(updateUser({
                    token: response.headers.authorization
                    }))
                if ([200, 201].includes(response.status)) {
                    dispatch(faqPageSuccess());
                    resolve();
                }
            })
            .catch(({ response }) => {
                dispatch(faqPageError());
                dispatch(updateUser({token: response?.headers.authorization}))
                if(response?.status === 401) {
                    dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
                    return setTimeout(() => dispatch(logout()), 2000) 
                }
                if(response?.status === 403) {
                    return dispatch(showAlert({ type: 'error', message: 'Oops! you are unauthorised' }))
                }
                return dispatch(showAlert({ type: 'error', message: 'Oops! something went wrong please try again.' }))
            })
        })
    }
}
