import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getActionLoadingState } from "@/store/selectors";
import { getGuideBystatus, updateGuideStatus } from "@/store/support/actions";
import actionTypes from "@/store/support/actionTypes";
import Pagination from "@/components/Pagination";
import Emptystate from "@/components/Emptystate";
import Modal from "@/components/PopModal";
import RightPanel from "@/components/RightPanel";
import "./style.scss";

class PendingApproval extends React.Component {
  state = {
    showConfirmModal: false,
    showConfirmApprovalModal: false,
    selectedItem: null,
    selectedGuide: null,
    approveItem: "",
    openGuideDetailPanel: false,
  };

  componentDidMount() {
    this.getGuides(1, 10);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.searchString !== prevProps.searchString &&
      this.props.searchString !== ""
    ) {
      this.getGuides(1, 10, this.props.searchString);
    }
    if (
      this.props.searchString !== prevProps.searchString &&
      this.props.searchString === ""
    ) {
      this.getGuides(1, 10);
    }
  }

  getGuides = (page, limit, searchTerm) => {
    this.props.getGuideBystatus("pending", page, limit, searchTerm);
  };

  showConfirmApproval = (id) => {
    this.setState({ showConfirmApprovalModal: true, approveItem: id });
  };

  showConfirm = (id) => {
    this.setState({ showConfirmModal: true, itemId: id });
  };

  selectGuide = (guide) => {
    this.setState({ selectedGuide: guide }, () =>
      this.toggleGuideDetailPanel()
    );
  };

  handleApprove = (id) => {
    const { approveItem } = this.state;
    this.props
      .updateGuideStatus(approveItem, { status: "active" })
      .then((data) => {
        this.setState({ showConfirmApprovalModal: false });
        this.getGuides(1, 10);
      });
  };

  handleReject = () => {
    const { itemId } = this.state;
    this.props
      .updateGuideStatus(itemId, { status: "rejected" })
      .then((data) => {
        this.setState({ showConfirmModal: false });
        this.getGuides(1, 10);
      })
      .catch(() => {
        this.setState({ showConfirmModal: false });
      });
  };

  toggleModal = () => {
    this.setState({ showConfirmModal: !this.state.showConfirmModal });
  };

  toggleGuideDetailPanel = () => {
    this.setState((prevState) => ({
      openGuideDetailPanel: !prevState.openGuideDetailPanel,
    }));
  };

  render() {
    const { pendingGuides, loading, updateLoading } = this.props;
    const {
      showConfirmApprovalModal,
      showConfirmModal,
      openGuideDetailPanel,
      selectedGuide,
    } = this.state;

    return (
      <div className="pending-guides-page">
        <RightPanel
          open={openGuideDetailPanel}
          onClose={this.toggleGuideDetailPanel}
        >
          <h4 className="panel-heading">Pending Guide</h4>
          <label className="panel-label">Category</label>
          <input
            name="topic"
            type="text"
            placeholder={selectedGuide?.parent?.title}
            className="border-grey form-control panel-input mb-0"
            disabled={true}
          />

          <label className="panel-label mt-4">Topic Name</label>
          <input
            name="topic"
            type="text"
            placeholder={selectedGuide?.title}
            className="border-grey form-control panel-input mb-0"
            disabled={true}
          />
          <label className="panel-label mt-4" htmlFor="new-topic-icon">
            Image
          </label>
          <div className="img-input" id="new-topic-icon">
            <div className="img-upload d-flex align-items-center">
              <div>
                <img
                  src={selectedGuide?.image}
                  className="guide-img"
                  alt="guide"
                />
                {/* <button className="bg-white pl-4 pr-4">
                    View Image
                  </button> */}
              </div>
            </div>
          </div>

          <label className="panel-label mt-4">Description</label>
          <textarea
            name="topic"
            type="text"
            placeholder={selectedGuide?.content}
            className="border-grey form-control panel-input mb-0"
            rows="5"
            disabled={true}
          />
          <label className="panel-label mt-4">Reason</label>
          <textarea
            name="topic"
            type="text"
            placeholder={selectedGuide?.reason}
            className="border-grey form-control panel-input mb-0"
            rows="5"
            disabled={true}
          />
        </RightPanel>
        {showConfirmModal && (
          <Modal onClose={this.toggleModal}>
            <div className="text-center">
              <h3>Confirm</h3>
              <p>Are you sure you want to reject this topic?</p>
              <div className="d-flex">
                <button
                  className="btn btn-sm btn-white btn-block mt-3 mr-2"
                  onClick={() => this.setState({ showConfirmModal: false })}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-sm btn-danger btn-block mt-3 ml-2 d-flex align-items-center justify-content-center"
                  onClick={this.handleReject}
                >
                  Confirm
                  {updateLoading && (
                    <div className="spinner-border spinner-border-white spinner-border-sm ml-2"></div>
                  )}
                </button>
              </div>
            </div>
          </Modal>
        )}
        {showConfirmApprovalModal && (
          <Modal onClose={this.toggleModal}>
            <div className="text-center">
              <h3>Confirm</h3>
              <p>Are you sure you want to Approve this topic?</p>
              <div className="d-flex">
                <button
                  className="btn btn-sm btn-white btn-block mt-3 mr-2"
                  onClick={() =>
                    this.setState({ showConfirmApprovalModal: false })
                  }
                >
                  Cancel
                </button>
                <button
                  className="btn btn-sm btn-primary btn-block mt-3 ml-2 d-flex align-items-center justify-content-center"
                  onClick={this.handleApprove}
                >
                  Confirm
                  {updateLoading && (
                    <div className="spinner-border spinner-border-white spinner-border-sm ml-2"></div>
                  )}
                </button>
              </div>
            </div>
          </Modal>
        )}
        <div className="text-center">
          {!pendingGuides && loading && (
            <div className="spinner-border text-primary ml-auto mr-auto"></div>
          )}
        </div>
        {pendingGuides &&
          (pendingGuides?.data.length > 0 ? (
            <>
              <Pagination
                totalPages={pendingGuides.lastPage}
                page={pendingGuides.page}
                limit={pendingGuides.perPage}
                changePageHandler={(page, limit) => this.getGuides(page, limit)}
              />
              <div className="setup-inner__main">
                <table className="custum-table">
                  <tbody>
                    <tr>
                      <th>Topic Name</th>
                      <th>Category</th>
                      <th>Action performed</th>
                      <th className="actions-column">Actions</th>
                    </tr>
                    {pendingGuides?.data.map((guide) => (
                      <tr key={guide.id}>
                        <td
                          onClick={() => this.selectGuide(guide)}
                          className="cursor-pointer"
                        >
                          {guide.title}
                        </td>
                        <td>{guide?.parent?.title}</td>
                        <td>{guide?.action}</td>
                        <td>
                          <div className="d-flex">
                            <button
                              className="btn btn-xsm btn-primary mr-3"
                              onClick={() => this.showConfirmApproval(guide.id)}
                            >
                              Approve
                            </button>
                            <button
                              className="btn btn-xsm btn-white color-red"
                              onClick={() => this.showConfirm(guide.id)}
                            >
                              Reject
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan="6" className="text-center">
                        Showing {pendingGuides?.data.length} of{" "}
                        {pendingGuides?.total}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <Emptystate
              title={
                this.props.searchString === ""
                  ? "No user guide Pending approval"
                  : "No user guide pending approval matches the search word"
              }
              icon={require("@/assets/icons/info.svg").default}
            />
          ))}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    app: {
      support: { error, data, pendingGuides },
    },
  } = state;
  return {
    loading: getActionLoadingState(
      state,
      actionTypes.GET_USER_GUIDE_BY_STATUS_REQUEST
    ),
    updateLoading: getActionLoadingState(
      state,
      actionTypes.UPDATE_GUIDE_STATUS_REQUEST
    ),
    rejectLoading: getActionLoadingState(state, actionTypes.delete),
    error,
    data,
    pendingGuides,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGuideBystatus: (status, page, limit, searchTerm) =>
      dispatch(getGuideBystatus(status, page, limit, searchTerm)),
    updateGuideStatus: (id, payload) =>
      dispatch(updateGuideStatus(id, payload)),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PendingApproval)
);
