import React, { Component } from "react";
import { RefreshIcon } from "@heroicons/react/outline"; // Import the Refresh icon from Heroicons
import "./style.scss";

export default class Index extends Component {
  reloadAll = () => {
    window.location.reload();
  };

  render() {
    return (
      <div>
        <button
          className="flex items-center justify-center transition-all bg-blue-900 text-white rounded-xl hover:bg-blue-950 focus:outline-none focus:ring-2 focus:ring-blue-900 focus:ring-opacity-75"
          onClick={this.reloadAll}
        >
          <RefreshIcon className="size-6 mr-2" />
          <span className="text-lg tracking-wider">Refresh</span>
        </button>
      </div>
    );
  }
}
