import React, { Component } from "react";
import "./style.scss";

class SearchBox extends Component {
  state = {
    searchTerm: "",
    isFocused: false,
    activeSearch: this.props.isActiveSearch,
  };

  componentDidUpdate(prevprops) {
    if (this.props.isActiveSearch !== prevprops.isActiveSearch) {
      this.setState({ activeSearch: this.props.isActiveSearch });
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { searchTerm } = this.state;
    if (searchTerm.trim().length < 1) return;
    this.props.handleSearch(searchTerm.trim());
  };

  onChange = (e) => {
    this.setState({ searchTerm: e.target.value });
  };

  cancelSearch = () => {
    this.setState({ searchTerm: "" });
    this.props.handleSearch("");
  };

  render() {
    const { searchTerm, activeSearch, isFocused } = this.state;
    return (
      <form className="search-box mr-4" onSubmit={this.onSubmit}>
        {activeSearch || isFocused || searchTerm ? (
          <button
            type="button"
            className="wrapper-button"
            onClick={this.cancelSearch}
          >
            <img
              src={require("@/assets/icons/left-arrow.svg").default}
              alt="arrow pointing left"
            />
          </button>
        ) : (
          <img src={require("@/assets/icons/search.svg").default} alt="lens" />
        )}
        <input
          type="text"
          placeholder={this.props.placeholder}
          onChange={this.onChange}
          value={searchTerm}
          onFocus={() => this.setState({ isFocused: true })}
          onBlur={() => this.setState({ isFocused: false })}
        />
      </form>
    );
  }
}

export default SearchBox;
