import axios from 'axios';
import CONFIG from '@/config';
import * as actions from "./actionTypes";
import { logout, updateUser } from "@/store/user/actions";
import { validate } from "@/utils";
import { showAlert } from '@/store/ui/actions';

const getAdminRequest = () => {
    return {
        type: actions.GET_ADMIN_REQUEST
    }
}

const getAdminError = () => {
    return {
        type: actions.GET_ADMIN_ERROR,
    }
}

const getAdminSuccess = () => {
    return {
        type: actions.GET_ADMIN_SUCCESS
    }
}

const adminPageRequest = () => {
    return {
        type: actions.ADMIN_PAGE_REQUEST
    }
}

const adminPageError = (message) => {
    return {
        type: actions.ADMIN_PAGE_ERROR,
        message
    }
}

const adminPageSuccess = () => {
    return {
        type: actions.ADMIN_PAGE_SUCCESS
    }
}

export const fetchAdmins = (page, limit, status, searchTerm) => {
    let path = `${status === 'active' ? '' : '/status/pending'}?page=${page}&limit=${limit}`;
    if(searchTerm) path = `/full-text?search=${searchTerm}&status=${status === 'active' ? 'not-pending' : 'pending'}&page=${page}&limit=${limit}`;
    return (dispatch, getState) => { 
        dispatch(getAdminRequest());
        return new Promise((resolve) => {
            axios.get(`${CONFIG.BASE_URL}/admin/users${path}`, {
                headers: {
                    "Authorization": getState().user.token,
                },
            })
            .then(response => {
                dispatch(updateUser({token: response.headers.authorization}))
                if (response.status === 200) {
                    resolve(response.data.data?.users || response.data.data?.admins);
                    dispatch(getAdminSuccess());
                }
            })
            .catch(({ response }) => {
                dispatch(getAdminError());
                dispatch(updateUser({token: response?.headers.authorization}))
                if (response?.status === 401) {
                    dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
                    return setTimeout(() => dispatch(logout()), 2000)
                }
                if(response?.status === 403) {
                    return dispatch(showAlert({ type: 'error', message: 'Oops! you are unauthorised' }))
                }
                dispatch(showAlert({ type: 'error', message: 'Oops! something went wrong please try again.' }))
            })
        })
    }
}

export const registerAdmin = (data) => {
    return (dispatch, getState) => {
        const required = ['firstName', 'lastName', 'email', 'phone', 'roleId',]
        //validate data
        dispatch(validate(data, required, showAlert))
        .then(() => {
            dispatch(adminPageRequest());
            axios.post(`${CONFIG.BASE_URL}/admin/register`, data, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": getState().user.token,
                },
            })
            .then(response => {
                dispatch(updateUser({
                    token: response.headers.authorization
                }))
                if(response.status === 201) {
                    dispatch(adminPageSuccess());
                    window.location.reload();
                }
            })
            .catch(({ response }) => {
                dispatch(adminPageError({type: "danger", message: response?.data.error }));
                dispatch(updateUser({token: response?.headers.authorization}))
                if(response?.status === 401) {
                    dispatch(showAlert({ type: 'error', message: 'Your session has expired' }));
                    return setTimeout(() => dispatch(logout()), 2000);
                }
                if(response?.status === 403) {
                    return dispatch(showAlert({ type: 'error', message: 'Oops! you are unauthorised' }));
                }
                if(response?.status === 400) {
                    return dispatch(showAlert({ type: 'error', message: response.data.message }));
                }
                dispatch(showAlert({ type: 'error', message: 'Oops! something wrong please try again.' }));
            })
        });
    }
}

export const editAdmin = (data, adminId) => {
    return (dispatch, getState) => {
        const required = ['firstName', 'lastName', 'email', 'phone', 'roleId',]
        dispatch(adminPageRequest());
        //validate data
        dispatch(validate(data, required, showAlert))
        .then(() => {
            axios.put(`${CONFIG.BASE_URL}/admin/users/${adminId}`, data, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": getState().user.token,
                },
            })
            .then(response => {
                dispatch(updateUser({
                    token: response.headers.authorization
                }))
                if(response.status === 200) {
                    dispatch(adminPageSuccess());
                    window.location.reload();
                }
            })
            .catch(({ response }) => {
                dispatch(adminPageError({type: "danger", message: response?.data.error }));
                dispatch(updateUser({token: response?.headers.authorization}));
                if(response?.status === 401) {
                    dispatch(showAlert({ type: 'error', message: 'Your session has expired' }));
                    return setTimeout(() => dispatch(logout()), 2000);
                }
                if(response?.status === 403) {
                    return dispatch(showAlert({ type: 'error', message: 'Oops! you are unauthorised' }));
                }
                if(response?.status === 400) {
                    return dispatch(showAlert({ type: 'error', message: response.data?.message }));
                }
                dispatch(showAlert({ type: 'error', message: 'Oops! something wrong please try again.' }));
            })
        });
    }
}

export const changeAdminStatus = (adminId, newStatus, action) => {
    const status = newStatus === 'enable' ? "active" : "disabled";
    return (dispatch, getState) => { 
        dispatch(adminPageRequest());
        return new Promise((resolve) => {
            axios.patch(`${CONFIG.BASE_URL}/admin/users/${adminId}/${action ? action : 'status'}`, {"staffApproval": status}, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": getState().user.token,
                },
            })
            .then(response => {
                dispatch(updateUser({
                    token: response.headers.authorization
                  }))
                if (response.status === 200) {
                    dispatch(adminPageSuccess());
                    resolve();
                }
            })
            .catch(({ response }) => {
                dispatch(adminPageError());
                dispatch(updateUser({token: response?.headers.authorization}))
                if(response?.status === 401) {
                    dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
                    return setTimeout(() => dispatch(logout()), 2000) 
                }
                if(response?.status === 403) {
                    return dispatch(showAlert({ type: 'error', message: 'Oops! you are unauthorised' }));
                }
                dispatch(showAlert({ type: 'error', message: 'Oops! something wrong please try again.' }));
            })
        })
    }
}

export const deleteAdmin = (adminId) => {
    return (dispatch, getState) => { 
        dispatch(adminPageRequest());
        return new Promise((resolve) => {
            axios.delete(`${CONFIG.BASE_URL}/admin/users/${adminId}`, {
                headers: {
                    "Authorization": getState().user.token,
                },
            })
            .then(response => {
                dispatch(updateUser({
                    token: response.headers.authorization
                  }))
                if (response.status === 200) {
                    dispatch(adminPageSuccess());
                    resolve();
                }
            })
            .catch(({ response }) => {
                dispatch(adminPageError());
                dispatch(updateUser({token: response?.headers.authorization}));
                if(response?.status === 401) {
                    dispatch(showAlert({ type: 'error', message: 'Your session has expired' }));
                    return setTimeout(() => dispatch(logout()), 2000);
                }
                if(response?.status === 403) {
                    return dispatch(adminPageError({ type: "danger", message: "Oops! you are unauthorised" }));
                }
                dispatch(showAlert({ type: 'error', message: 'Oops! something wrong please try again.' }));
            })
        })
    }
}

export const resetAdminsPassword = (id, adminName) => {
    return (dispatch, getState) => { 
        dispatch(adminPageRequest());
        axios.get(`${CONFIG.BASE_URL}/admin/users/${id}/reset-password`, {
            headers: {
                "Authorization": getState().user.token,
            },
        })
        .then(response => {
            dispatch(updateUser({token: response.headers.authorization}))
            if (response.status === 200) {
                dispatch(adminPageSuccess());
                dispatch(showAlert({ type: 'success', message: `Password reset information has been sent to ${adminName}` }))
            }
        })
        .catch(({ response }) => {
            dispatch(adminPageError());
            dispatch(updateUser({token: response?.headers.authorization}))
            if (response?.status === 401) {
                dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
                return setTimeout(() => dispatch(logout()), 2000)
            }
            if(response?.status === 403) {
                return dispatch(showAlert({ type: 'error', message: 'Oops! you are unauthorised' }))
            }
            dispatch(showAlert({ type: 'error', message: 'Oops! something went wrong please try again.' }))
        })
    }
}

export const multipleApproveReject = (payload, type) => {
    return (dispatch, getState) => { 
        dispatch(adminPageRequest());
        return new Promise((resolve) => {
            axios.patch(`${CONFIG.BASE_URL}/admin/users/multiple/${type}`, payload, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": getState().user.token,
                },
            })
            .then(response => {
                dispatch(updateUser({
                    token: response.headers.authorization
                  }))
                if (response.status === 200) {
                    dispatch(adminPageSuccess());
                    resolve();
                }
            })
            .catch(({ response }) => {
                dispatch(adminPageError());
                dispatch(updateUser({token: response?.headers.authorization}))
                if(response?.status === 401) {
                    dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
                    return setTimeout(() => dispatch(logout()), 2000) 
                }
                if(response?.status === 403) {
                    return dispatch(showAlert({ type: 'error', message: 'Oops! you are unauthorised' }));
                }
                dispatch(showAlert({ type: 'error', message: 'Oops! something wrong please try again.' }));
            })
        })
    }
}
