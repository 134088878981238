import React from 'react';
import { withRouter, Route, Switch, NavLink, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Tickets from './Tickets';
import './style.scss';

class Support extends React.Component {
    navItems = [
        {name: 'Tickets', path: 'tickets'},
    ];

    render() {
        const { match: { path } } = this.props;
        return (
            <div>
                <ul className="setup-nav">
                    {this.navItems.map((item, index) => (
                        <li key={index} className="setup-nav__item" >
                            <NavLink
                                to={{ pathname: `/dashboard/support/${item.path}`, state: { pageTitle: 'Support' } }}
                                activeClassName="setup-nav__item--active"
                            >
                                {item.name}
                            </NavLink>
                        </li>
                    ))}
                </ul>
                <Switch>
                    <Route exact path={path}>
                        <Redirect to={`${path}/tickets`} />
                    </Route>
                    <Route path={`${path}/tickets`}>
                        <Tickets />
                    </Route>
                </Switch>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { user } = state;
    return {
        permissions: user?.permissions,
    }
}

export default withRouter(
    connect(mapStateToProps, null)(Support)
);