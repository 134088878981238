import axios from 'axios';
import CONFIG from '@/config';
import * as actions from "./actionTypes";
import { logout, updateUser } from "@/store/user/actions";
import { showAlert } from '@/store/ui/actions';
import { validate, apiRequestHandler } from "@/utils";

const getRoleRequest = () => {
    return {
        type: actions.GET_ROLE_REQUEST
    }
}

const getRoleError = (message) => {
    return {
        type: actions.GET_ROLE_ERROR,
        message
    }
}

const getRoleSuccess = () => {
    return {
        type: actions.GET_ROLE_SUCCESS
    }
}

const rolePageRequest = () => {
    return {
        type: actions.ROLE_PAGE_REQUEST
    }
}

const rolePageError = (message) => {
    return {
        type: actions.ROLE_PAGE_ERROR,
        message
    }
}

const rolePageSuccess = () => {
    return {
        type: actions.ROLE_PAGE_SUCCESS
    }
}

export const fetchRoles = (page, limit, status = '', searchTerm) => {
    const path = searchTerm ? `${searchTerm}/search?status=${status ? status : 'not-pending'}&page=${page}&limit=${limit}` : `${status}?page=${page}&limit=${limit}`;
    return (dispatch, getState) => {
        dispatch(getRoleRequest());
        return new Promise((resolve) => {
            axios.get(`${CONFIG.BASE_URL}/admin/roles/${path}`, {
                headers: {
                    "Authorization": getState().user.token,
                },
            })
            .then(response => {
                dispatch(updateUser({
                    token: response.headers.authorization
                  }))
                if (response.status === 200) {
                    resolve(response.data.data?.roles);
                    dispatch(getRoleSuccess());
                }
            })
            .catch(({ response }) => {
                dispatch(getRoleError());
                dispatch(updateUser({token: response?.headers.authorization}))
                if(response?.status === 401) {
                    dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
                    return setTimeout(() => dispatch(logout()), 2000) 
                }
                if(response?.status === 403) {
                    return dispatch(showAlert({ type: 'error', message: 'Oops! you are unauthorised' }))
                }
                return dispatch(showAlert({ type: 'error', message: 'Oops! something went wrong please try again.' }))
            })
        })
    }
}

export const createOrEditRole = (data, roleId) => {
    const path = roleId ? `admin/roles/${roleId}` : 'admin/roles';
    return (dispatch, getState) => {
        const required = [
            "name",
            "description",
            "permissions",
        ]
        //validate data
        dispatch(validate(data, required, showAlert))
        .then(() => {
            dispatch(rolePageRequest());
            fetch(`${CONFIG.BASE_URL}/${path}`, {
                method: `${roleId ? 'PUT' : 'POST'}`,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": getState().user.token,
                },
                body: JSON.stringify(data)
            })
            .then(response => {
                if([200, 201].includes(response?.status)) {
                    return response.json()
                    .then(() => {
                        dispatch(rolePageSuccess());
                        dispatch(showAlert({ type: 'success', message: 'Successful' }))
                        window.location.reload();
                    });
                }
                throw response;
            })
            .catch(err => {
                dispatch(rolePageError());
                if(err?.status === 401) {
                    dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
                    return setTimeout(() => dispatch(logout()), 2000) 
                }
                if(err?.status === 403) {
                    return dispatch(showAlert({ type: 'error', message: 'Oops! you are unauthorised' }))
                }
                if(err?.status === 400) {
                    err.json().then(e => {
                        return dispatch(showAlert({ type: 'error', message: e.message }));
                    })
                }
                dispatch(showAlert({type: "error", message: "Oops! something wrong please try again."}));
            })
        });
    }
}

export const fetchPermissions = () => {
    return (dispatch, getState) => { 
        return new Promise((resolve) => {
            axios.get(`${CONFIG.BASE_URL}/admin/permissions`, {
                headers: {
                    "Authorization": getState().user.token,
                },
            })
            .then(response => {
                if (response.status === 200) {
                    resolve(response.data.data?.permissions);
                }
            })
            .catch(({ response }) => {
                response && dispatch(updateUser({
                    token: response.headers.authorization
                  }))
                if(response && response.status === 401) {
                    dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
                    return setTimeout(() => dispatch(logout()), 2000) 
                }
                if(response && response.status === 403) {
                    return dispatch(rolePageError({ type: "danger", message: "Oops! you are unauthorised" }));
                }
                dispatch(rolePageError({ type: "danger", message: "Oops! something went wrong please try again." }));
            })
        })
    }
}

export const changeRoleStatus = (roleId, status) => {
    const path = Array.isArray(roleId) ? `admin/roles/multiple/approve` : `admin/roles/${roleId}/status`
    const payload = {status};
    if(Array.isArray(roleId)) {
        payload.requests = roleId;
    }
    const config = {
        urlPath: path,
        method: 'patch',
        payload,
        requestAction: rolePageRequest,
        successAction: rolePageSuccess,
        errorAction: rolePageError,
    }
    return apiRequestHandler(config);
}

export const deleteRole = (roleId) => {
    const config = {
        urlPath: `admin/roles/${roleId}`,
        method: 'delete',
        requestAction: rolePageRequest,
        successAction: rolePageSuccess,
        errorAction: rolePageError,
    }
    return apiRequestHandler(config);
}

export const rejectRole = (id) => {
    const path = Array.isArray(id) ? `admin/roles/multiple/reject` : `admin/roles/${id}/reject`
    const payload = {requests: id};
    const config = {
        urlPath: path,
        method: 'patch',
        payload,
        requestAction: rolePageRequest,
        successAction: rolePageSuccess,
        errorAction: rolePageError,
    }
    return apiRequestHandler(config);
}
