import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, NavLink, Redirect } from "react-router-dom";
import AllUsers from './AllUsers';
import AccountActivation from './AccountActivation';
import BankActivation from './BankActivation';
import ProfileUpdate from './ProfileUpdate';
import { hasPermission } from '@/utils';
import SingleUser from './SingleUser';

class Users extends Component {
    navItems = [
        {name: 'View Users', path: 'all'},
        {name: 'Account Activation', path: 'activations'},
        {name: 'Bank Account Activation', path: 'bank-activations/pending'},
        {name: 'Profile Update', path: 'updates'},
    ];

    render() {
        const { match: { path } } = this.props;
        const firstRoute = this.navItems.length ? this.navItems[0].path : '';
        const canReadUsers = hasPermission(this.props.permissions, ['read_user', 'activate_user_account', 'reject_user_account', 'enable_disable_user', 'update_user_profile', 'approve_profile', 'reject_profile', 'read_profile']);

        return(
            <div className="setup-area">
                <ul className="setup-nav">
                    {this.navItems.map((item, index) => (
                        <li key={index} className="setup-nav__item" >
                            <NavLink  
                                to={{pathname: `/dashboard/users/${item.path}`, state: { pageTitle: 'Users'}}} 
                                activeClassName="setup-nav__item--active"
                            >
                                {item.name}
                            </NavLink>
                        </li>
                    ))}
                </ul>
                {canReadUsers && <Switch>
                    <Route exact path={path}>
                        <Redirect to={{pathname: `${path}/${firstRoute}`}} />
                    </Route>
                    <Route exact path={`${path}/all`}>
                        <Redirect to={{pathname: `${path}/all/registered`}} />
                    </Route>
                    <Route path={`${path}/all/:status(registered|not-approved|activated|deactivated)`}>
                        <AllUsers />
                    </Route>
                    <Route path={`${path}/all/:id(\\d+)`}>
                        <SingleUser />
                    </Route>
                    <Route exact path={`${path}/activations`}>
                        <Redirect to={{pathname: `${path}/activations/pending`}} />
                    </Route>
                    <Route path={`${path}/activations/:status(pending|manual|auto)`}>
                        <AccountActivation />
                    </Route>
                    <Route path={`${path}/bank-activations/:status(pending)`}>
                        <BankActivation />
                    </Route>
                    <Route exact path={`${path}/updates`}>
                        <Redirect to={{pathname: `${path}/updates/pending`}} />
                    </Route>
                    <Route path={`${path}/updates/:status(pending|approved)`}>
                        <ProfileUpdate />
                    </Route>
                </Switch>}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        permissions: state.user.permissions,
    }
}

export default withRouter(
    connect(mapStateToProps, null)(Users)
);
