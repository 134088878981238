import * as actions from "./actionTypes";

const portfolioDefaultState = {
  data: null,
  error: false,
  loading: false,
  investment: null,
}

export default (state = portfolioDefaultState, action) => {
  switch (action.type) {
    case actions.FETCH_PORTFOLIO_SUCCESS:
      return { ...state, loading: false, error: false, data: action.data }
    case actions.FETCH_PORTFOLIO_ERROR:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
}