import * as actions from './actionTypes';

const reportsDefaultState = {
  data: null,
  error: false,
  loading: false,
  reports: null,
}

export default (state = reportsDefaultState, action) => {
  switch (action.type) {
    case actions.CLEAR:
      return { ...state, loading: false, error: false, data: null };
    case actions.GET_CTR_REPORTS_SUCCESS:
      return { ...state, loading: false, error: false, reports: action.data?.data, data: action.data }
    case actions.GET_CTR_REPORTS_ERROR:
      return { ...state, loading: false, error: action.error }
      case actions.DOWNLOAD_USER_REPORTS_SUCCESS:
        return { ...state, loading: false, error: false, data: action.data }
      case actions.DOWNLOAD_USER_REPORTS_ERROR:
        return { ...state, loading: false, error: action.error }
    default:
      return state;
  }
} 