import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Textbox from "@/components/Textbox";
import { login } from "@/store/auth/actions";
import actionTypes from "@/store/auth/actionTypes";
import { getActionLoadingState } from "@/store/selectors";
import { validateFields, serializeErrors } from "@/utils";
import AdminAuthNav from "@/components/AdminAuthNav";
import "./style.scss";
import { UserCircleIcon } from "@heroicons/react/outline";

class Login extends React.Component {
  state = {
    email: "",
    password: "",
    passwordType: "password",
    errors: null,
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handlePasswordType = () => {
    const { passwordType } = this.state;
    if (passwordType === "password") {
      return this.setState({ passwordType: "text" });
    }
    return this.setState({ passwordType: "password" });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const { login, history } = this.props;
    const { email, password } = this.state;

    this.setState({ errors: null });

    const data = this.state;
    const required = ["email", "password"];
    const errors = validateFields(data, required);

    if (Object.keys(errors).length > 0) {
      return this.setState({ errors });
    }

    const payload = { email, password };
    login(payload, history);
  };

  render() {
    const { email, password, passwordType, errors } = this.state;
    const { loading, error } = this.props;
    const errorObject = serializeErrors(error);
    return (
      <div className="admin-login">
        <AdminAuthNav />
        <h3 className="flex justify-center items-center text-white text-center font-bold font-primary tracking-tighter !mt-3">
          <h3 className="m-0"> Welcome Back </h3>
          <UserCircleIcon className="hidden size-12 stroke-1" />
        </h3>
        <p className="text-gray-100 text-center mt-2">
          Enter your login details to access your account
        </p>
        <div className="box ml-auto mr-auto pt-3">
          <div className="login-form">
            <div className="container">
              <form autoComplete="off" onSubmit={this.handleSubmit}>
                <Textbox
                  onChange={this.handleChange}
                  name="email"
                  value={email}
                  label="Email"
                  placeholder="Email address"
                  boxClasses="mt-3"
                  type="email"
                  error={
                    errors ? errors.email : errorObject && errorObject["email"]
                  }
                />
                <Textbox
                  onChange={this.handleChange}
                  name="password"
                  value={password}
                  label="Password"
                  placeholder="Password"
                  boxClasses="mt-3"
                  type={passwordType}
                  iconUrl={require("@/assets/icons/view-password.svg").default} // Use .default if necessary
                  onIconClick={this.handlePasswordType}
                  error={
                    errors
                      ? errors.password
                      : errorObject && errorObject["password"]
                  }
                />

                <button className="btn btn-sm transition-all w-100 !rounded-xl  !bg-blue-900 hover:!bg-blue px-4 !py-[25px] text-white mt-5 justify-content-center align-items-center d-flex">
                  Sign in
                  {loading && (
                    <div className="spinner-border spinner-border-white spinner-border-sm ml-2"></div>
                  )}
                </button>
                {error && typeof error === "string" && (
                  <p className="singin-error">{error}</p>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    auth: { error },
  } = state;
  return {
    loading: getActionLoadingState(state, actionTypes.LOGIN_REQUEST),
    error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (payload, history) => dispatch(login(payload, history)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
