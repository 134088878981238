module.exports = {
    GET_TERMINATIONS_REQUEST: "terminations/GET_TERMINATIONS_REQUEST",
    GET_TERMINATIONS_SUCCESS: "terminations/GET_TERMINATIONS_SUCCESS",
    GET_TERMINATIONS_ERROR: "terminations/GET_TERMINATIONS_ERROR",
    TERMINATIONS_PAGE_REQUEST: "terminations/TERMINATIONS_PAGE_REQUEST",
    TERMINATIONS_PAGE_SUCCESS: "terminations/TERMINATIONS_PAGE_SUCCESS",
    TERMINATIONS_PAGE_ERROR: "terminations/TERMINATIONS_PAGE_ERROR",
    GET_WITHDRAWALS_REQUEST: "withdrawals/GET_WITHDRAWALS_REQUEST",
    GET_WITHDRAWALS_SUCCESS: "withdrawals/GET_WITHDRAWALS_SUCCESS",
    GET_WITHDRAWALS_ERROR: "withdrawals/GET_WITHDRAWALS_ERROR",
    WITHDRAWALS_PAGE_REQUEST: "withdrawals/WITHDRAWALS_PAGE_REQUEST",
    WITHDRAWALS_PAGE_SUCCESS: "withdrawals/WITHDRAWALS_PAGE_SUCCESS",
    WITHDRAWALS_PAGE_ERROR: "withdrawals/WITHDRAWALS_PAGE_ERROR",
    GET_DEPOSITS_REQUEST: "deposits/GET_DEPOSITS_REQUEST",
    GET_DEPOSITS_SUCCESS: "deposits/GET_DEPOSITS_SUCCESS",
    GET_DEPOSITS_ERROR: "deposits/GET_DEPOSITS_ERROR",
    DEPOSITS_PAGE_REQUEST: "deposits/DEPOSITS_PAGE_REQUEST",
    DEPOSITS_PAGE_ERROR: "deposits/DEPOSITS_PAGE_ERROR",
    DEPOSITS_PAGE_SUCCESS: "deposits/DEPOSITS_PAGE_SUCCESS",
    GET_BOOKINGS_REQUEST: "bookings/GET_BOOKINGS_REQUEST",
    GET_BOOKINGS_SUCCESS: "bookings/GET_BOOKINGS_SUCCESS",
    GET_BOOKINGS_ERROR: "bookings/GET_BOOKINGS_ERROR",
    BOOKINGS_PAGE_REQUEST: "bookings/BOOKINGS_PAGE_REQUEST",
    BOOKINGS_PAGE_SUCCESS: "bookings/BOOKINGS_PAGE_SUCCESS",
    BOOKINGS_PAGE_ERROR: "bookings/BOOKINGS_PAGE_ERROR",
    GET_INVESTMENTS_REQUEST: "investments/GET_INVESTMENTS_REQUEST",
    GET_INVESTMENTS_SUCCESS: "investments/GET_INVESTMENTS_SUCCESS",
    GET_INVESTMENTS_ERROR: "investments/GET_INVESTMENTS_ERROR",
    INVESTMENTS_PAGE_REQUEST: "investments/INVESTMENTS_PAGE_REQUEST",
    INVESTMENTS_PAGE_SUCCESS: "investments/INVESTMENTS_PAGE_SUCCESS",
    INVESTMENTS_PAGE_ERROR: "investments/INVESTMENTS_PAGE_ERROR",
    GET_REVERSALS_REQUEST: "reversals/GET_REVERSALS_REQUEST",
    GET_REVERSALS_SUCCESS: "reversals/GET_REVERSALS_SUCCESS",
    GET_REVERSALS_ERROR: "reversals/GET_REVERSALS_ERROR",
    REVERSALS_PAGE_REQUEST: "reversals/REVERSALS_PAGE_REQUEST",
    REVERSALS_PAGE_SUCCESS: "reversals/REVERSALS_PAGE_SUCCESS",
    REVERSALS_PAGE_ERROR: "reversals/REVERSALS_PAGE_ERROR",
    GET_MUTUAL_FUNDS_REQUEST: "mutualFunds/GET_MUTUAL_FUNDS_REQUEST",
    GET_MUTUAL_FUNDS_ERROR: "mutualFunds/GET_MUTUAL_FUNDS_ERROR",
    GET_MUTUAL_FUNDS_SUCCESS: "mutualFunds/GET_MUTUAL_FUNDS_SUCCESS",
}
