module.exports = {
  CLEAR_ERROR: "auth/CLEAR_ERROR",
  CLEAR_DATA: "auth/CLEAR_DATA",
  LOGIN_REQUEST: "auth/LOGIN_REQUEST",
  LOGIN_ERROR: "auth/LOGIN_ERROR",
  LOGIN_SUCCESS: "auth/LOGIN_SUCCESS",
  RESET_PASSWORD_REQUEST: "auth/RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_ERROR: "auth/RESET_PASSWORD_ERROR",
  RESET_PASSWORD_SUCCESS: "auth/RESET_PASSWORD_SUCCESS",
  GET_REFRESH_TOKEN_REQUEST: "dashboard/GET_REFRESH_TOKEN_REQUEST",
  GET_REFRESH_TOKEN_ERROR: "dashboard/GET_REFRESH_TOKEN_ERROR",
  GET_REFRESH_TOKEN_SUCCESS: "dashboard/GET_REFRESH_TOKEN_SUCCESS",
};
