import CONFIG from "@/config";
import * as actions from "./actionTypes";
import { apiRequestHandler, apiDownloadHandler } from "@/utils";

const getTransactionAnalyticsRequest = () => {
  return {
    type: actions.GET_TRANSACTION_ANALYTICS_REQUEST,
  };
};

const getTransactionAnalyticsError = () => {
  return {
    type: actions.GET_TRANSACTION_ANALYTICS_ERROR,
  };
};

export const fetchDepositAnalytics = (view, startDate, endDate) => {
  let queryParam = "";
  if (view === "total-deposit-distribution-by-month") {
    queryParam = `&mStartDate=${startDate}&mEndDate=${endDate}`;
  }
  if (view === "total-deposit-distribution-by-type") {
    queryParam = `&tStartDate=${startDate}&tEndDate=${endDate}`;
  }
  if (view === "totalAndAverageDeposit") {
    queryParam = `&startDate=${startDate}&endDate=${endDate}`;
  }
  if (view === "default") {
    queryParam = `&startDate=${startDate}&endDate=${endDate}&tStartDate=${startDate}&tEndDate=${endDate}&mStartDate=${startDate}&mEndDate=${endDate}`;
  }

  const config = {
    urlPath: `admin/analytics/transaction/deposits?view=${view}${queryParam}`,
    requestAction: getTransactionAnalyticsRequest,
    errorAction: getTransactionAnalyticsError,
  };
  return apiRequestHandler(config);
};

export const fetchWithdrawalAnalytics = (view, startDate, endDate) => {
  let queryParam = "";
  if (view === "total-withdrawal-distribution-by-month") {
    queryParam = `&mStartDate=${startDate}&mEndDate=${endDate}`;
  }
  if (view === "totalAndAverageWithdrawal") {
    queryParam = `&startDate=${startDate}&endDate=${endDate}`;
  }
  if (view === "default") {
    queryParam = `&startDate=${startDate}&endDate=${endDate}&mStartDate=${startDate}&mEndDate=${endDate}`;
  }

  const config = {
    urlPath: `admin/analytics/transaction/withdrawals?view=${view}${queryParam}`,
    requestAction: getTransactionAnalyticsRequest,
    errorAction: getTransactionAnalyticsError,
  };
  return apiRequestHandler(config);
};



export const exportDeposits = (
  _,
  format,
  view,
  startDate,
  endDate,
  tStartDate,
  tEndDate,
  mStartDate,
  mEndDate
) => {
  
  let queryParam = "";
  if (view === "total-deposit-distribution-by-month") {
    queryParam = `&mStartDate=${mStartDate}&mEndDate=${mEndDate}`;
  }
  if (view === "total-deposit-distribution-by-type") {
    queryParam = `&tStartDate=${tStartDate}&tEndDate=${tEndDate}`;
  }
  if (view === "totalAndAverageDeposit") {
    queryParam = `&startDate=${startDate}&endDate=${endDate}`;
  }
  if (view === "default") {
    queryParam = `&startDate=${startDate}&endDate=${endDate}&tStartDate=${tStartDate}&tEndDate=${tEndDate}&mStartDate=${mStartDate}&mEndDate=${mEndDate}`;
  }
  const fullUrlPath = `${CONFIG.BASE_URL}/admin/analytics/transaction/deposits/downloads?${queryParam}&view=${view}&type=${format}`;
  return apiDownloadHandler(fullUrlPath);
};
export const exportWithdrawals = (
  _,
  format,
  view,
  startDate,
  endDate,
  mStartDate,
  mEndDate
) => {
  let queryParam = "";
  if (view === "totalAndAverageWithdrawal") {
    queryParam = `&startDate=${startDate}&endDate=${endDate}`;
  }
  if (view === "total-withdrawal-distribution-by-month") {
    queryParam = `&mStartDate=${mStartDate}&mEndDate=${mEndDate}`;
  }
  if (view === "default") {
    queryParam = `&startDate=${startDate}&endDate=${endDate}&mStartDate=${mStartDate}&mEndDate=${mEndDate}`;
  }
  const fullUrlPath = `${CONFIG.BASE_URL}/admin/analytics/transaction/withdrawals/downloads?view=${view}${queryParam}&type=${format}`;
  return apiDownloadHandler(fullUrlPath);
};
