import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getActionLoadingState } from "@/store/selectors";
import {
  getCategories,
  updateCategory,
  deleteCategory,
} from "@/store/blog/actions";
import actionTypes from "@/store/blog/actionTypes";
import DropdownMenu from "@/components/DropdownMenu";
import Modal from "@/components/PopModal";
import RightPanel from "@/components/RightPanel";
import Pagination from "@/components/Pagination";
import { serializeErrors, validateFields, hasPermission } from "@/utils";
import "./style.scss";

class Category extends React.Component {
  state = {
    selectedCategory: null,
    openPanel: false,
    categoryName: "",
    errors: "",
    showConfirmModal: false,
  };

  componentDidMount() {
    this.getCategories(1, 10);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.categories !== this.props.categories) {
      this.updateSelected(this.props.categories[0]);
    }
  }

  getCategories = (page, limit) => {
    this.props.getCategories(page, limit);
  };

  updateSelected = (data) => {
    this.setState({ selectedCategory: data });
  };

  selectCategory = (category) => {
    this.setState({ selectedCategory: category });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleEdit = () => {
    this.setState({ categoryName: this.state.selectedCategory?.name });
    this.toggleCategoryPanel();
  };

  handleEditCategory = (event) => {
    event.preventDefault();
    const { updateCategory } = this.props;
    const { categoryName, selectedCategory } = this.state;

    const data = this.state;
    const required = ["categoryName"];
    const errors = validateFields(data, required);

    if (Object.keys(errors).length > 0) {
      return this.setState({ errors });
    }
    updateCategory(selectedCategory.id, { category: categoryName }).then(
      (data) => {
        this.setState((prevState) => ({ openPanel: !prevState.openPanel }));
        this.setState({ categoryName: "" });
        this.getCategories(1, 10);
      }
    );
  };

  handleDelete = () => {
    const { selectedCategory } = this.state;
    const { deleteCategory } = this.props;
    deleteCategory(selectedCategory.id)
      .then((data) => {
        this.toggleConfirmModal();
        this.getCategories(1, 10);
      })
      .catch((error) => {
        this.toggleConfirmModal();
      });
  };

  closeNewPanel = (event) => {
    event && event.preventDefault();
    this.toggleCategoryPanel();
  };

  toggleCategoryPanel = (event) => {
    event && event.preventDefault();
    this.setState((prevState) => ({ openPanel: !prevState.openPanel }));
  };

  toggleConfirmModal = () => {
    this.setState((prevState) => ({
      showConfirmModal: !prevState.showConfirmModal,
    }));
  };

  render() {
    const {
      loading,
      categories,
      removeLoading,
      data,
      editLoading,
      error,
      permissions,
    } = this.props;
    const {
      selectedCategory,
      errors,
      openPanel,
      categoryName,
      showConfirmModal,
    } = this.state;
    const errorObject = serializeErrors(error);
    const canDelete = hasPermission(permissions, "delete_service");
    const canUpdate = hasPermission(permissions, "update_service");

    const menu = [
      canUpdate && {
        name: "Edit Category",
        handler: this.handleEdit,
      },
      canDelete && {
        name: "Delete Category",
        handler: this.toggleConfirmModal,
      },
    ];
    return (
      <div className="blog-categories-page">
        {showConfirmModal && (
          <Modal onClose={this.toggleConfirmModal}>
            <div className="text-center">
              <h3>Confirm</h3>
              <p>Are you sure you want to delete this category?</p>
              <div className="d-flex">
                <button
                  className="btn btn-sm btn-white btn-block mt-3 mr-2"
                  onClick={() => this.setState({ showConfirmModal: false })}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-sm btn-danger btn-block mt-3 ml-2 d-flex align-items-center justify-content-center"
                  onClick={this.handleDelete}
                >
                  Confirm
                  {removeLoading && (
                    <div className="spinner-border spinner-border-white spinner-border-sm ml-2"></div>
                  )}
                </button>
              </div>
            </div>
          </Modal>
        )}
        <RightPanel open={openPanel} onClose={this.toggleCategoryPanel}>
          <h4 className="panel-heading">Edit category</h4>
          <form onSubmit={this.handleEditCategory}>
            <label className="panel-label" htmlFor="new-investment-type">
              Category name
            </label>
            <input
              name="categoryName"
              type="text"
              placeholder="Category name"
              className="border-grey form-control panel-input mb-0"
              value={categoryName}
              onChange={this.handleChange}
            />
            <p className="text-error">
              {errors
                ? errors.categoryName
                : errorObject && errorObject["category"]}
            </p>

            <div className="row mt-5">
              <div className="col-md-6">
                <button
                  className="btn btn-white btn-sm font-md w-100"
                  onClick={this.closeNewPanel}
                >
                  Cancel
                </button>
              </div>
              <div className="col-md-6">
                <button
                  className="btn btn-primary btn-sm font-md w-100 d-flex align-items-center justify-content-center"
                  type="submit"
                >
                  Request Approval
                  {editLoading && (
                    <div className="spinner-border spinner-border-white spinner-border-sm ml-2"></div>
                  )}
                </button>
              </div>
            </div>
          </form>
        </RightPanel>
        <Pagination
          totalPages={data?.lastPage}
          page={data?.page}
          limit={data?.perPage}
          changePageHandler={(page, limit) => this.getPosts(page, limit)}
        />
        <div className="card mb-5">
          <div className="row no-gutters">
            <div className="col-md-3 border-right p-5">
              {categories && categories.length > 0 ? (
                categories.map((category) => (
                  <p
                    className="text-deep-blue font-md cursor-pointer text-capitalize pb-5"
                    key={category.id}
                    onClick={() => this.selectCategory(category)}
                  >
                    {category.name}
                  </p>
                ))
              ) : (
                <p className="font-md text-deep-blue">No Categories</p>
              )}
            </div>
            <div className="col-md-9">
              <div className="p-5 d-flex justify-content-between align-items-center border-bottom">
                <h2 className="text-deep-blue text-capitalize">
                  {selectedCategory && selectedCategory.name}
                  {categories && categories.length < 1 && "No Categories"}
                </h2>
                {loading && (
                  <div className="spinner-border spinner-border-blue spinner-border-md ml-2"></div>
                )}
                {selectedCategory && (canDelete || canUpdate) && (
                  <DropdownMenu menu={menu}>
                    <img
                      src={require("@/assets/icons/flat-ellipsis.svg").default}
                      alt="dropdown"
                    />
                  </DropdownMenu>
                )}
              </div>
              <div className="category-topics-container p-4">
                {selectedCategory &&
                  (selectedCategory?.posts?.length > 0 ? (
                    selectedCategory?.posts?.map((post) => (
                      <p
                        key={post.id}
                        className="text-deep-blue text-capitalize pb-4"
                      >
                        {post.title}
                      </p>
                    ))
                  ) : (
                    <p className="text-deep-blue text-capitalize font-md">
                      No post under this category
                    </p>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    app: {
      blog: { categories, error, data },
    },
    user: { permissions },
  } = state;
  return {
    loading: getActionLoadingState(state, actionTypes.GET_CATEGORIES_REQUEST),
    editLoading: getActionLoadingState(
      state,
      actionTypes.UPDATE_CATEGORY_REQUEST
    ),
    removeLoading: getActionLoadingState(
      state,
      actionTypes.DELETE_CATEGORY_REQUEST
    ),
    error,
    data,
    categories,
    permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCategories: (page, limit) => dispatch(getCategories(page, limit)),
    updateCategory: (id, payload) => dispatch(updateCategory(id, payload)),
    deleteCategory: (id) => dispatch(deleteCategory(id)),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Category)
);
