import React, { Component } from "react";
import Dropdown from "@/components/DropdownMenu";

export default class MultipleApprovalDropdown extends Component {
  render() {
    const options = [
      { name: "Approve", handler: () => this.props.approve?.() },
      {
        name: "Reject",
        textClass: "text-red",
        handler: () => this.props.reject?.(),
      },
    ];

    return (
      <Dropdown menu={this.props.options ?? options} width={"114px"} left>
        <div className="setup-nav__dropdown mr-4">
          Actions
          <img
            src={require("@/assets/icons/dropdown-caret.svg").default}
            alt="arrow down"
            className="ml-auto"
          />
        </div>
      </Dropdown>
    );
  }
}

export class MultipleActiveApprovalDropdown extends Component {
  render() {
    const options = [
      {
        name: "Delete",
        textClass: "text-red",
        handler: () => this.props.delete?.(),
      },
    ];

    return (
      <Dropdown menu={this.props.options ?? options} width={"114px"} left>
        <div className="setup-nav__dropdown mr-4">
          Actions
          <img
            src={require("@/assets/icons/dropdown-caret.svg").default}
            alt="arrow down"
            className="ml-auto"
          />
        </div>
      </Dropdown>
    );
  }
}
