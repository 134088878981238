import React, { Component } from "react";
import './style.scss';

class News extends Component {
    render() {
        return(
            <div>'News'</div>
        )
    }
}

export default News;