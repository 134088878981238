import React from "react";
import "./style.scss";
// import CPLogo from "@/assets/images/cp-logo-white.png";
import CPLogo from "@/assets/images/cp_logo_colored.png";

const AdminAuthNav = () => {
  return (
    <div className="admin-auth-nav">
      <div className="container">
        <div className="flex justify-center">
          <div className="logo mt-5">
            <img src={CPLogo} alt="logo" className="cp-logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminAuthNav;
