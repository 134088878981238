import axios from 'axios';
import CONFIG from '@/config';
import * as actions from "./actionTypes";
import { logout, updateUser } from "@/store/user/actions";
import { showAlert } from '@/store/ui/actions';
import { apiRequestHandler, apiDownloadHandler } from '@/utils';

const getReporstRequest = () => {
  return {
    type: actions.GET_REPORTS_REQUEST,
  }
}

const getReporstError = () => {
  return {
    type: actions.GET_REPORTS_ERROR,
  }
}

const getReporstSuccess = () => {
  return {
    type: actions.GET_REPORTS_SUCCESS,
  }
}

export const fetchReport = (page, limit, timeFramne, type) => {
  const path = timeFramne === 'all' ? '' : 'recent';
  const paths = {
    audit: 'audit/trails',
    liability: 'liabilities/tracker',
  }
  const config = {
      fullUrlPath: `${CONFIG.REPORTS_BASE_URL}/${paths[type]}/${path}?page=${page}&limit=${limit}`,
      requestAction: getReporstRequest,
      successAction: getReporstSuccess,
      errorAction: getReporstError,
  }
  return apiRequestHandler(config);
}

export const searchReport = (searchTerm, type, page, limit) => {
  const paths = {
    audit: 'audit/trails',
    liability: 'liabilities/tracker',
  }
  const config = {
      fullUrlPath: `${CONFIG.REPORTS_BASE_URL}/${paths[type]}/search?key=all&searchWord=${searchTerm}&page=${page}&limit=${limit}`,
      requestAction: getReporstRequest,
      successAction: getReporstSuccess,
      errorAction: getReporstError,
  }
  return apiRequestHandler(config);
}

//GET REPORTS summary
const getCtrReportsRequest = () => {
  return {
    type: actions.GET_CTR_REPORTS_REQUEST
  }
}

const getCtrReportsSuccess = (data) => {
  return {
    type: actions.GET_CTR_REPORTS_SUCCESS,
    data,
  }
}

const getCtrReportsError = (error) => {
  return {
    type: actions.GET_CTR_REPORTS_ERROR,
    error,
  }
}

export const getCtrReports = (type, page, limit) => {
  let url;

  if(type === 'all') {
    url = `${CONFIG.BASE_URL}/admin/transactions/ctr?limit=${limit}&page=${page}`
  } else {
    url =`${CONFIG.BASE_URL}/admin/transactions/ctr/recent?limit=${limit}&page=${page}`
  }
  return (dispatch, getState) => {
    dispatch(getCtrReportsRequest());

    const { token } = getState().user;
    return new Promise((resolve, reject) => {

      axios.get(url, {
        headers: {
          'Content-Type': "application/json",
          Authorization: token,
        },
      })
        .then(response => {
          dispatch(updateUser({
            token: response.headers.authorization
          }))
          if ([200, 201].includes(response.status)) {
            dispatch(getCtrReportsSuccess(response.data))
            resolve(response.data)
          }
          return;
        })
        .catch(({ response }) => {
          reject(response?.data)
          response && dispatch(updateUser({
            token: response.headers.authorization
          }))
          if (response && [400, 404, 403].includes(response.status)) {
            response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
            return dispatch(getCtrReportsError(response.data.error ? response.data.error : response.data.message));
          }
          if (response && [401].includes(response.status)) {
            dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
            return setTimeout(() => dispatch(logout()), 2000)
          }
          if (response && response.status >= 500) {
            return dispatch(getCtrReportsError('Oops! We did something wrong.'));
          }
          dispatch(getCtrReportsError('Oops! We did something wrong.'));
        })
    })
  }
}

export const filterCtrReports = (search, section, page, limit) => {
  const sections = {
    all: {
      request: getCtrReportsRequest,
      error: getCtrReportsError,
      success: getCtrReportsSuccess,
    },
    recent: {
      request: getCtrReportsRequest,
      error: getCtrReportsError,
      success: getCtrReportsSuccess,
    },
  }
  return (dispatch, getState) => {
    dispatch(sections[section].request());

    const { token } = getState().user;
    return new Promise((resolve, reject) => {

      axios.get(`${CONFIG.BASE_URL}/admin/transactions/ctr/filter?search=${search}&page=${page}&limit=${limit}`, {
        headers: {
          'Content-Type': "application/json",
          Authorization: token,
        },
      })
        .then(response => {
          dispatch(updateUser({
            token: response.headers.authorization
          }))
          if ([200, 201].includes(response.status)) {
            dispatch(sections[section].success(response.data))
            resolve(response.data)
          }
          return;
        })
        .catch(({ response }) => {
          reject(response?.data)
          response && dispatch(updateUser({
            token: response.headers.authorization
          }))
          if (response && [400, 404, 403].includes(response.status)) {
            response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
            return dispatch(sections[section].error(response.data.error ? response.data.error : response.data.message));
          }
          if (response && [401].includes(response.status)) {
            dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
            return setTimeout(() => dispatch(logout()), 2000)
          }
          if (response && response.status >= 500) {
            return dispatch(sections[section].error('Oops! We did something wrong.'));
          }
          dispatch(sections[section].error('Oops! We did something wrong.'));
        })
    })
  }
}

//DOWNLOAD USER REPORTS summary
const downloadUserReportsRequest = () => {
  return {
    type: actions.DOWNLOAD_USER_REPORTS_REQUEST
  }
}

const downloadUserReportsSuccess = (data) => {
  return {
    type: actions.DOWNLOAD_USER_REPORTS_SUCCESS,
    data,
  }
}

const downloadUserReportsError = (error) => {
  return {
    type: actions.DOWNLOAD_USER_REPORTS_ERROR,
    error,
  }
}

export const downloadUserReports = (payload) => {
  return (dispatch, getState) => {
    dispatch(downloadUserReportsRequest());

    const { token } = getState().user;
    return new Promise((resolve, reject) => {
      fetch(`${CONFIG.BASE_URL}/admin/users/customers/reports?report=${payload}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then(response => {
        if ([200, 201].includes(response.status)) {
          dispatch(downloadUserReportsSuccess(response))
          return resolve(response.blob())
        }
        if ([400, 404, 403, 422].includes(response.status)) {
          response.json()
            .then(res => {
              res.message && dispatch(showAlert({ type: 'error', message: res.message }))
              return dispatch(downloadUserReportsError(res.error ? res.error : res.message));
            })
        }
        if ([401].includes(response.status)) {
          dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
          return setTimeout(() => dispatch(logout()), 2000)
        }
        if (response.status >= 500) {
          dispatch(downloadUserReportsError('Oops! We did something wrong.'));
        }
      })
      .catch(() => {
        dispatch(downloadUserReportsError('Oops! We did something wrong.'));
      })
    })
  }
}

//DOWNLOAD CTR REPORTS summary
const downloadCtrReportsRequest = () => {
  return {
    type: actions.DOWNLOAD_CTR_REPORTS_REQUEST
  }
}

const downloadCtrReportsSuccess = (data) => {
  return {
    type: actions.DOWNLOAD_CTR_REPORTS_SUCCESS,
    data,
  }
}

const downloadCtrReportsError = (error) => {
  return {
    type: actions.DOWNLOAD_CTR_REPORTS_ERROR,
    error,
  }
}

export const downloadCtrReports = (_, format, status, page, limit) => {
  return (dispatch, getState) => {
    dispatch(downloadCtrReportsRequest());

    const { token } = getState().user;
    return new Promise((resolve, reject) => {

      axios.get(`${CONFIG.BASE_URL}/admin/transactions/ctr/download/${format}?page=${page}&limit=${limit}&range=${status}`, {
        headers: {
          'Content-Type': "application/json",
          Authorization: token,
        },
      })
        .then(response => {
          dispatch(updateUser({
            token: response.headers.authorization
          }))
          if ([200, 201].includes(response.status)) {
            dispatch(downloadCtrReportsSuccess(response.data))
            resolve(response.data)
          }
          return;
        })
        .catch(({ response }) => {
          reject(response?.data)
          response && dispatch(updateUser({
            token: response.headers.authorization
          }))
          if (response && [400, 404, 403].includes(response.status)) {
            response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
            return dispatch(downloadCtrReportsError(response.data.error ? response.data.error : response.data.message));
          }
          if (response && [401].includes(response.status)) {
            dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
            return setTimeout(() => dispatch(logout()), 2000)
          }
          if (response && response.status >= 500) {
            return dispatch(downloadCtrReportsError('Oops! We did something wrong.'));
          }
          dispatch(downloadCtrReportsError('Oops! We did something wrong.'));
        })
    })
  }
}

export const exportAuditTrail = (_, format, status, page, limit) => {
  const fullUrlPath = `${CONFIG.REPORTS_BASE_URL}/audit/trails/download/${format}?range=${status}${page ? `&page=${page}&limit=${limit}` : ''}`;
  return apiDownloadHandler(fullUrlPath);
}

export const exportLiabilityTracker = (_, format, status, page, limit) => {
  const fullUrlPath = `${CONFIG.REPORTS_BASE_URL}/liabilities/tracker/downloads/${format}?range=${status}${page ? `&page=${page}&limit=${limit}` : ''}`;
  return apiDownloadHandler(fullUrlPath);
}

export const exportPPi = (_, format, status, page, limit) => {
  const fullUrlPath = `${CONFIG.BASE_URL}/admin/mutual-funds/${status}/summary/download/${format}?action=click${page ? `&page=${page}&limit=${limit}` : ''}`;
  return apiDownloadHandler(fullUrlPath);
}

export const exportSinglePPiReport = (_, format, status) => {
  const fullUrlPath = `${CONFIG.BASE_URL}/admin/mutual-funds/${status}/download/${format}`;
  return apiDownloadHandler(fullUrlPath);
}
