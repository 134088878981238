import React from 'react';
import './style.scss';

const Card = ({ classes, children}) => {
  return (
    <div className={`card-container ${classes}`}>
        {children}
    </div>
  )
}

export default Card;
