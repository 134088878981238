import axios from 'axios';
import CONFIG from '@/config';
import * as actions from "./actionTypes";
import { logout, updateUser } from "@/store/user/actions";
import { showAlert } from '@/store/ui/actions';
import { apiDownloadHandler, apiRequestHandler } from '@/utils';

const getWithdrawalsRequest = () => {
    return {
        type: actions.GET_WITHDRAWALS_REQUEST,
    }
}

const getWithdrawalsError = () => {
    return {
        type: actions.GET_WITHDRAWALS_ERROR,
    }
}

const getWithdrawalsSuccess = () => {
    return {
        type: actions.GET_WITHDRAWALS_SUCCESS,
    }
}

const withdrawalsPageRequest = () => {
    return {
        type: actions.WITHDRAWALS_PAGE_REQUEST,
    }
}

const withdrawalsPageError = () => {
    return {
        type: actions.WITHDRAWALS_PAGE_ERROR,
    }
}

const withdrawalsPageSuccess = () => {
    return {
        type: actions.WITHDRAWALS_PAGE_SUCCESS,
    }
}

export const fetchWithdrawals = (page, limit, status, searchTerm) => {
    let path = `${status}?page=${page}&limit=${limit}`;
    if(searchTerm) {
        const sections = {
            pending: 'pending-withdrawal',
            confirmed: 'pending-approval',
            active: 'approved-withdrawal',
            held: 'held',
        }
        path = `search?search=${searchTerm}&section=${sections[status]}&page=${page}&limit=${limit}`;
    }
    return (dispatch, getState) => {
        dispatch(getWithdrawalsRequest());
        return new Promise((resolve) => {
            axios.get(`${CONFIG.BASE_URL}/admin/transactions/withdrawals/${path}`, {
                headers: {
                    "Authorization": getState().user.token,
                },
            })
            .then(response => {
                dispatch(updateUser({
                    token: response.headers.authorization
                }))
                if (response.status === 200) {
                    dispatch(getWithdrawalsSuccess());
                    resolve(response.data.data.withdrawals || response.data.data);
                }
            })
            .catch(({ response }) => {
                dispatch(getWithdrawalsError());
                dispatch(updateUser({
                    token: response?.headers.authorization
                }))
                if([400, 404].includes(response?.status)) {
                    response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
                }
                if(response?.status === 403) {
                    return dispatch(showAlert({ type: 'error', message: 'Oops! you are unauthorised' }))
                }
                if (response?.status === 401) {
                    dispatch(showAlert({ type: 'error', message: 'Your session has expired' }));
                    return setTimeout(() => dispatch(logout()), 2000);
                }
                dispatch(showAlert({ type: 'error', message: 'Oops! something went wrong please try again.' }))
            })
        })
    }
}

export const updateWithdrawal = (id, status, message) => {
    const allStatus = {
        confirmed: 'confirm',
        active: 'approve',
        held: 'hold',
        pending: 'approve',
        'initiate-reverse': 'initiate-reverse',
    }
    return (dispatch, getState) => {
        dispatch(withdrawalsPageRequest());
        return new Promise((resolve) => {
            axios.patch(`${CONFIG.BASE_URL}/admin/transactions/withdrawals/${id}/${allStatus[status]}`, {status, message}, {
                headers: {
                    "Authorization": getState().user.token,
                },
            })
            .then(response => {
                dispatch(updateUser({
                    token: response.headers.authorization
                }))
                if (response.status === 200) {
                    dispatch(withdrawalsPageSuccess());
                    if(status === 'initiate-reverse') dispatch(showAlert({ type: 'success', message: 'Request has been successfully sent for approval' }))
                    resolve();
                }
            })
            .catch(({ response }) => {
                dispatch(withdrawalsPageError());
                dispatch(updateUser({
                    token: response?.headers.authorization
                }))
                if([400, 404].includes(response?.status)) {
                    return response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
                }
                if(response?.status === 403) {
                    return dispatch(showAlert({ type: 'error', message: 'Oops! you are unauthorised' }))
                }
                if (response?.status === 401) {
                    dispatch(showAlert({ type: 'error', message: 'Your session has expired' }));
                    return setTimeout(() => dispatch(logout()), 2000);
                }
                dispatch(showAlert({ type: 'error', message: 'Oops! something went wrong please try again.' }))
            })
        })
    }
}

export const updateMultipleWithdrawal = (ids, status) => {
    const allStatus = {
        confirmed: 'confirm',
        active: 'approve',
        held: 'hold',
        pending: 'release',
    }
    const payload = {
        status,
        "requests": ids,
    }
    const config = {
        urlPath: `admin/transactions/withdrawals/multiple/${allStatus[status]}`,
        payload,
        method: 'patch',
        requestAction: withdrawalsPageRequest,
        successAction: withdrawalsPageSuccess,
        errorAction: withdrawalsPageError,
    }
    return apiRequestHandler(config);
}

export const exportWithdrawals = (_, format, status, page, limit) => {
    const section = status === 'confirmed' ? 'pending-approval' : `${status}-withdrawals`
    const fullUrlPath = `${CONFIG.BASE_URL}/admin/transactions/withdrawals/${section}/download/${format}?page=${page}&limit=${limit}`;
    return apiDownloadHandler(fullUrlPath);
}
