import axios from 'axios';
import CONFIG from '@/config';
import * as actions from "./actionTypes";
import { logout, updateUser } from "@/store/user/actions";
import { showAlert } from '@/store/ui/actions';
import { validate, apiDownloadHandler } from "@/utils";

const mutualFundsPageRequest = () => {
    return {
        type: actions.MUTUAL_FUNDS_PAGE_REQUEST
    }
}

const mutualFundsPageError = (message) => {
    return {
        type: actions.MUTUAL_FUNDS_PAGE_ERROR,
        message
    }
}

const mutualFundsPageSuccess = () => {
    return {
        type: actions.MUTUAL_FUNDS_PAGE_SUCCESS
    }
}

const getMutualFundsRequest = () => {
    return {
        type: actions.GET_MUTUAL_FUNDS_REQUEST
    }
}

const getMutualFundsError = () => {
    return {
        type: actions.GET_MUTUAL_FUNDS_ERROR,
    }
}

const getMutualFundsSuccess = () => {
    return {
        type: actions.GET_MUTUAL_FUNDS_SUCCESS
    }
}

const getSingleMutualFundsRequest = () => {
    return {
        type: actions.GET_SINGLE_MUTUAL_FUND_REQUEST
    }
}

const getSingleMutualFundsError = () => {
    return {
        type: actions.GET_SINGLE_MUTUAL_FUND_ERROR,
    }
}

const getSingleMutualFundsSuccess = () => {
    return {
        type: actions.GET_SINGLE_MUTUAL_FUND_SUCCESS,
    }
}

export const fetchSingleMutualFund = (id) => {
    return (dispatch, getState) => {
        dispatch(getSingleMutualFundsRequest());
        return new Promise((resolve) => {
            axios.get(`${CONFIG.BASE_URL}/admin/mutual-funds/${id}`, {
                headers: {
                    "Authorization": getState().user.token,
                },
            })
            .then(response => {
                dispatch(updateUser({
                    token: response.headers.authorization
                }))
                if (response.status === 200) {
                    dispatch(getSingleMutualFundsSuccess());
                    resolve(response.data.data);
                }
            })
            .catch(({ response }) => {
                dispatch(getSingleMutualFundsError());
                dispatch(updateUser({
                    token: response?.headers.authorization
                }))
                if([400, 404].includes(response?.status)) {
                    response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
                }
                if(response?.status === 403) {
                    return dispatch(showAlert({ type: 'error', message: 'Oops! you are unauthorised' }))
                }
                if (response?.status === 401) {
                    dispatch(showAlert({ type: 'error', message: 'Your session has expired' }));
                    return setTimeout(() => dispatch(logout()), 2000);
                }
                dispatch(showAlert({ type: 'error', message: 'Oops! something went wrong please try again.' }))
            })
        })
    }
}

export const fetchMutualFunds = (page, limit, status, action, searchTerm, type) => {
    const paths = {
        create: `status/${status}?action=${action}&approvalStatus=none&page=${page}`,
        disable: `status/active?action=${action}&approvalStatus=pending&page=${page}`,
        enable: `status/disabled?action=${action}&approvalStatus=pending&page=${page}`,
        edit: `updates/pending?page=${page}`,
        delete: `status/active?action=${action}&approvalStatus=pending&page=${page}`,
    }
    const searchPaths = {
        create: `search/${searchTerm}?action=${action}&approvalStatus=none&status=${type}&page=${page}`,
        disable: `search/${searchTerm}?action=disable&approvalStatus=pending&status=active&page=${page}`,
        enable: `search/${searchTerm}?action=enable&approvalStatus=pending&status=disabled&page=${page}`,
        edit: `search/${searchTerm}?action=edit&approvalStatus=pending&status=active&page=${page}`,
        delete: `search/${searchTerm}?action=delete&approvalStatus=pending&status=active&page=${page}`,
    }
    const urlPath = searchTerm ? searchPaths[action] : paths[action];
    return (dispatch, getState) => {
        dispatch(getMutualFundsRequest());
        return new Promise((resolve) => {
            axios.get(`${CONFIG.BASE_URL}/admin/mutual-funds/${urlPath}&limit=${limit}`, {
                headers: {
                    "Authorization": getState().user.token,
                },
            })
            .then(response => {
                dispatch(updateUser({
                    token: response.headers.authorization
                }))
                if (response.status === 200) {
                    dispatch(getMutualFundsSuccess());
                    resolve(response.data.data);
                }
            })
            .catch(({ response }) => {
                dispatch(getMutualFundsError());
                dispatch(updateUser({
                    token: response?.headers.authorization
                }))
                if([400, 404].includes(response?.status)) {
                    return response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
                }
                if(response?.status === 403) {
                    return dispatch(showAlert({ type: 'error', message: 'Oops! you are unauthorised' }))
                }
                if (response?.status === 401) {
                    dispatch(showAlert({ type: 'error', message: 'Your session has expired' }));
                    return setTimeout(() => dispatch(logout()), 2000);
                }
                dispatch(showAlert({ type: 'error', message: 'Oops! something went wrong please try again.' }))
            })
        })
    }
}

export const createOrEditMutualFund = (data, id, history) => {
    // if the 'id' parameter has a value the request is an edit request else it is create
    const path = id ? `/${id}/initiate-update` : '';
    return (dispatch, getState) => {
        const required = ['title', 'summary', 'description', 'category', 'tags', 'startDate', 'endDate', !id && 'image'].filter(Boolean);
        dispatch(validate(data, required, showAlert))
        .then(() => {
            dispatch(mutualFundsPageRequest());
            const formData = new FormData();
            for (const field in data) {
                if(field === 'document') {
                    data[field].forEach(file => formData.append("document[]", file));
                }
                else {
                    formData.append(`${field}`, data[field]);
                }
            }
            axios({
                method: id ? 'patch' : 'post',
                url: `${CONFIG.BASE_URL}/admin/mutual-funds${path}`,
                data: formData,
                headers: {'Authorization': getState().user.token}
            })
            .then(response => {
                dispatch(updateUser({
                    token: response.headers.authorization
                }))
                if([200, 201].includes(response.status)) {
                    dispatch(mutualFundsPageSuccess());
                    dispatch(showAlert({ type: 'success', message: 'Successful' }))
                    return history.goBack();
                }
            })
            .catch(({ response }) => {
                dispatch(mutualFundsPageError({message: response?.data.error}));
                response && dispatch(updateUser({
                    token: response.headers.authorization
                }))
                if(response?.status === 401) {
                    dispatch(showAlert({ type: 'error', message: 'Your session has expired' }));
                    return setTimeout(() => dispatch(logout()), 2000) ;
                }
                if(response?.status === 403) {
                    return dispatch(showAlert({ type: 'error', message: 'Oops! you are unauthorised' }));
                }
                if(response?.status === 400) {
                    return dispatch(showAlert({ type: 'error', message: response.data.message }));
                }
                return dispatch(showAlert({ type: 'error', message: 'Oops! something wrong please try again.' }));
            })
        });
    }
}

export const updateMutualFund = (id, newStatus, reason) => {
    const payload = { status: newStatus }
    if(reason) payload.message = reason;
    return (dispatch, getState) => {
        dispatch(mutualFundsPageRequest());
        return new Promise((resolve) => {
            axios({
                method: newStatus !== 'delete' ? 'patch' : newStatus,
                url: `${CONFIG.BASE_URL}/admin/mutual-funds/${id}/${newStatus !== 'delete' ? 'status' : ''}`,
                data: payload,
                headers: {'Authorization': getState().user.token}
            })
            .then(response => {
                dispatch(updateUser({
                    token: response.headers.authorization
                }))
                if (response.status === 200) {
                    dispatch(mutualFundsPageSuccess());
                    resolve();
                }
            })
            .catch(({ response }) => {
                dispatch(mutualFundsPageError({message: response?.data.error}));
                dispatch(updateUser({
                    token: response?.headers.authorization
                }))
                if([400, 404].includes(response?.status)) {
                    response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
                }
                if(response?.status === 403) {
                    return dispatch(showAlert({ type: 'error', message: 'Oops! you are unauthorised' }))
                }
                if (response?.status === 401) {
                    dispatch(showAlert({ type: 'error', message: 'Your session has expired' }));
                    return setTimeout(() => dispatch(logout()), 2000);
                }
                dispatch(showAlert({ type: 'error', message: 'Oops! something went wrong please try again.' }))
            })
        })
    }
}

export const multipleApproveReject = (payload, isDeleteRequest) => {
    return (dispatch, getState) => {
        dispatch(mutualFundsPageRequest());
        return new Promise((resolve) => {
            axios({
                method: isDeleteRequest ? 'delete' : 'patch',
                url: `${CONFIG.BASE_URL}/admin/mutual-funds/multiple/${isDeleteRequest ? 'delete' : 'mutuals/status'}`,
                data: payload,
                headers: {'Authorization': getState().user.token}
            })
            .then(response => {
                dispatch(updateUser({
                    token: response.headers.authorization
                }))
                if (response.status === 200) {
                    dispatch(mutualFundsPageSuccess());
                    resolve();
                }
            })
            .catch(({ response }) => {
                dispatch(mutualFundsPageError({message: response?.data.error}));
                dispatch(updateUser({
                    token: response?.headers.authorization
                }))
                if([400, 404].includes(response?.status)) {
                    response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
                }
                if(response?.status === 403) {
                    return dispatch(showAlert({ type: 'error', message: 'Oops! you are unauthorised' }))
                }
                if (response?.status === 401) {
                    dispatch(showAlert({ type: 'error', message: 'Your session has expired' }));
                    return setTimeout(() => dispatch(logout()), 2000);
                }
                dispatch(showAlert({ type: 'error', message: 'Oops! something went wrong please try again.' }))
            })
        })
    }
}

export const exportMutualFunds = (action, _, format, status, page, limit) => {
    const statusObj = {
        create: status,
        disable: 'active',
        enable: 'disabled',
        edit: `active`,
        delete: 'active',
    }
    const fullUrlPath = `${CONFIG.BASE_URL}/admin/mutual-funds/status/${statusObj[action]}/downloads/${format}?action=${action}&approvalStatus=${action === 'create' ? 'none' : 'pending'}&page=${page}&limit=${limit}`;
    return apiDownloadHandler(fullUrlPath);
}
