import React from "react";
import { withRouter, Route, Switch, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { getActionLoadingState } from "@/store/selectors";
import {
  createNewFSInvestment,
  createNewInvestment,
  updateMultipleInvestmentStatus,
} from "@/store/investments/actions";
import actionTypes from "@/store/investments/actionTypes";
import TermedInvestments from "./TermedInvestments";
import PendingApproval from "./PendingApproval";
import RightPanel from "@/components/RightPanel";
import { validateFields, serializeErrors, hasPermission } from "@/utils";
import SearchBox from "@/components/SearchBox";
import ExportDropdown from "@/components/ExportDropdown";
import ConfirmationBox from "@/components/ConfirmationBox";
import MultipleApprovalDropdown from "@/components/MultipleApprovalDropdown";
import "./style.scss";
import FlashDealInvestment from "@/pages/Dashboard/Setup/Investments/FlashDeals";
import TextEditor from "@/components/TextEditor";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PendingApprovalFlashDeal from "@/pages/Dashboard/Setup/Investments/PendingApprovalFlashDeal";

class Investments extends React.Component {
  imgRef = React.createRef();
  backgroundRef = React.createRef();
  offerDocRef = React.createRef();
  state = {
    open: false,
    openFlashDealInvestment: false,
    fileName: "",
    backgroundFileName: "",
    type: "",
    name: "",
    icon: null,
    background: "",
    description: "",
    summary: "",
    errors: null,
    searchTerm: "",
    selectedRowsIds: [],
    offerDocument: null,
    offerDocumentFileName: "",
    offerName: "",
    tenor: "",
    rate: "",
    offerAmount: "",
    minimumInvestment: "",
    penalty: "",
    openingData: "",
    closingDate: "",
    category: "",
    tags: [],
    liquidationPenalty: "false",
  };

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      setTimeout(
        () =>
          this.setState({
            open: false,
            openFlashDealInvestment: false,
            fileName: "",
            type: "",
            name: "",
            icon: null,
            description: "",
            backgroundFileName: "",
            background: "",
          }),
        3000
      );
    }
    if (this.props.displayedInvestment !== prevProps.displayedInvestment) {
      this.setState({
        approveMultiple: false,
        rejectMultiple: false,
        selectedRowsIds: [],
      });
    }
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ searchTerm: "" });
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleTypeChange = (event) => {
    const { value } = event.target;
    this.setState({ type: value });
  };

  handleFileClick = (event) => {
    event.preventDefault();
    this.imgRef.current.click();
  };
  handleBackgroundClick = (event) => {
    event.preventDefault();
    this.backgroundRef.current.click();
  };

  handleImageSelect = (event) => {
    const { target } = event;
    if (target.files[0]) {
      this.setState({ fileName: target.files[0].name, icon: target.files[0] });
    }
  };
  handleBackgroundImageSelect = (event) => {
    const { target } = event;
    if (target.files[0]) {
      this.setState({
        backgroundFileName: target.files[0].name,
        background: target.files[0],
      });
    }
  };
  handleSubmit = (event) => {
    event.preventDefault();

    const { createNewInvestment } = this.props;
    const { type, name, icon, background, description } = this.state;

    this.setState({ errors: null });

    const data = this.state;
    const required = ["type", "name"];
    const errors = validateFields(data, required);

    if (Object.keys(errors).length > 0) {
      return this.setState({ errors });
    }
    if (!icon) return this.setState({ errors: { icon: "field is required" } });
    if (!background)
      return this.setState({
        errors: { background: "field is required" },
      });
    const formData = new FormData();
    formData.append("type", type);
    formData.append("icon", icon);
    formData.append("background", background);
    formData.append("name", name);
    formData.append("description", description);

    createNewInvestment(formData);
  };

  closeNewPanel = (event) => {
    event.preventDefault();

    this.setState({
      open: false,
      openFlashDealInvestment: false,
      fileName: "",
      backgroundFileName: "",
      type: "",
      name: "",
      icon: null,
      background: "",
      description: "",
      summary: "",
      errors: null,
      searchTerm: "",
      selectedRowsIds: [],
      offerDocument: null,
      offerDocumentFileName: "",
      offerName: "",
      tenor: "",
      rate: "",
      offerAmount: "",
      minimumInvestment: "",
      penalty: "",
      openingData: "",
      closingDate: "",
      category: "",
      tags: [],
      liquidationPenalty: "false",
    });
  };

  onCheckRow = (isChecked, rowId) => {
    const selectedRows = [...this.state.selectedRowsIds];
    if (isChecked) {
      selectedRows.push(rowId);
    } else {
      const uncheckedRowIndex = selectedRows.findIndex((id) => id === rowId);
      selectedRows.splice(uncheckedRowIndex, 1);
    }
    this.setState({ selectedRowsIds: selectedRows });
  };

  /* Create Flash deals investment handlers */
  handleOfferDocImageSelect = (event) => {
    const { target } = event;
    if (target.files[0]) {
      this.setState({
        offerDocumentFileName: target.files[0].name,
        offerDocument: target.files[0],
      });
    }
  };

  handleOfferDocFileClick = (event) => {
    event.preventDefault();
    this.offerDocRef.current.click();
  };

  handleCategoryChange = (event) => {
    const { value } = event.target;

    this.setState({ category: String(value).toUpperCase() });
  };

  handleSubmitFDI = (event) => {
    event.preventDefault();

    const { createNewFSInvestment } = this.props;
    const {
      icon,
      background,
      description,
      offerDocument,
      offerName,
      tenor,
      rate,
      offerAmount,
      minimumInvestment,
      penalty,
      category,
      openingData,
      closingDate,
      tags,
      summary,
      liquidationPenalty,
    } = this.state;

    this.setState({ errors: null });

    const data = this.state;
    const required = ["offerName"];
    const errors = validateFields(data, required);

    if (Object.keys(errors).length > 0) {
      return this.setState({ errors });
    }
    if (!icon) return this.setState({ errors: { icon: "field is required" } });
    if (!background)
      return this.setState({
        errors: { background: "field is required" },
      });
    const formData = new FormData();
    formData.append("icon", icon);
    formData.append("background", background);
    formData.append("description", description);
    formData.append("offerName", offerName);
    formData.append("offerDocument", offerDocument);
    formData.append("tenor", tenor);
    formData.append("rate", rate);
    formData.append("offerAmount", offerAmount);
    formData.append("minimumInvestment", minimumInvestment);
    formData.append("penalty", penalty);
    formData.append("category", category);
    formData.append("openingData", openingData);
    formData.append("closingDate", closingDate);
    formData.append("tags", tags);
    formData.append("summary", summary);
    formData.append("liquidationPenalty", liquidationPenalty === "true");

    createNewFSInvestment(formData);
  };

  render() {
    const {
      match: { path },
      loading,
      error,
      permissions,
      multipleUpdateLoading,
    } = this.props;
    const {
      summary,
      offerDocumentFileName,
      fileName,
      name,
      description,
      backgroundFileName,
      errors,
      searchTerm,
      approveMultiple,
      rejectMultiple,
      selectedRowsIds,
      offerName,
      tenor,
      rate,
      offerAmount,
      minimumInvestment,
      penalty,
      category,
      openingData,
      closingDate,
      tags,
      liquidationPenalty,
    } = this.state;
    const errorObject = serializeErrors(error);
    const canCreate = hasPermission(permissions, "create_service");
    const canApprove = hasPermission(permissions, "approve_service");
    const isShowingActiveInvestment =
      this.props.location.pathname.indexOf("pending") === -1;
    const addS = selectedRowsIds.length > 1;

    const isFlashDealPath = window.location.pathname.includes("flash-deals");

    return (
      <div className="investments-page">
        <ConfirmationBox
          open={approveMultiple || rejectMultiple}
          closeHandler={() =>
            this.setState({ approveMultiple: false, rejectMultiple: false })
          }
          title={`${approveMultiple ? "Approve" : "Reject"} Investment${
            addS ? "s" : ""
          }`}
          question={`Are you sure you want to ${
            approveMultiple ? "approve" : "reject"
          } ${addS ? "these" : "this"} investment${addS ? "s" : ""}?`}
          action={() =>
            this.props.updateMultipleInvestmentStatus(
              selectedRowsIds,
              approveMultiple ? "approve" : "reject"
            )
          }
          loading={multipleUpdateLoading}
        />
        <RightPanel open={this.state.open} onClose={this.closeNewPanel}>
          <h4 className="panel-heading">Create New Investment</h4>
          <form onSubmit={this.handleSubmit}>
            <label className="panel-label" htmlFor="new-investment-type">
              Investment type
            </label>
            <select
              className="form-control panel-input mb-0"
              placeholder="choose investment type"
              id="new-investment-type"
              name="type"
              onChange={this.handleTypeChange}
            >
              <option value="">Choose investment type</option>
              <option value="predefined">Pre-Defined</option>
              <option value="collection">Collections</option>
            </select>
            <p className="text-error">
              {errors ? errors.type : errorObject && errorObject["type"]}
            </p>

            <label className="panel-label mt-4" htmlFor="new-investment-name">
              Investment name
            </label>
            <input
              type="text"
              className="form-control panel-input mb-0"
              placeholder="Enter investment name"
              id="new-investment-name"
              name="name"
              value={name}
              onChange={this.handleChange}
            />
            <p className="text-error">
              {errors ? errors.name : errorObject && errorObject["name"]}
            </p>
            <label
              className="panel-label mt-4"
              htmlFor="new-investment-description"
            >
              Description
            </label>
            <textarea
              type="text"
              className="form-control panel-input mb-0"
              placeholder="Describe investment plan"
              id="new-investment-description"
              rows="5"
              name="description"
              value={description}
              onChange={this.handleChange}
            />
            <p className="text-error">
              {errors
                ? errors.description
                : errorObject && errorObject["description"]}
            </p>
            <label className="panel-label mt-4" htmlFor="new-investment-icon">
              Investment icon
            </label>
            <div className="img-input" id="new-investment-icon">
              <div className="img-upload d-flex align-items-center">
                <div>
                  <input
                    type="file"
                    className="file"
                    ref={this.imgRef}
                    accept="image/*"
                    onChange={this.handleImageSelect}
                  />
                  <button
                    className="bg-white pl-4 pr-4"
                    onClick={this.handleFileClick}
                  >
                    Upload file
                  </button>
                  <p>{fileName}</p>
                  <p>Upload PDF, JPEG, JPG or PNG</p>
                </div>
              </div>
              <p className="text-error">
                {errors ? errors.icon : errorObject && errorObject["icon"]}
              </p>
            </div>
            <label className="panel-label mt-4" htmlFor="new-investment-icon">
              Background Image
            </label>
            <div className="img-input" id="new-investment-icon">
              <div className="img-upload d-flex align-items-center">
                <div>
                  <input
                    type="file"
                    className="file"
                    ref={this.backgroundRef}
                    accept="image/*"
                    onChange={this.handleBackgroundImageSelect}
                  />
                  <button
                    className="bg-white pl-4 pr-4"
                    onClick={this.handleBackgroundClick}
                  >
                    Upload file
                  </button>
                  <p>{backgroundFileName}</p>
                  <p>Upload PDF, JPEG, JPG or PNG</p>
                </div>
              </div>
              <p className="text-error">
                {errors ? errors.icon : errorObject && errorObject["icon"]}
              </p>
            </div>
            <div className="row">
              <div className="col-md-6">
                <button
                  className="btn btn-white btn-sm font-md w-100"
                  onClick={this.closeNewPanel}
                >
                  Cancel
                </button>
              </div>
              <div className="col-md-6">
                <button
                  className="btn btn-primary btn-sm font-md w-100 d-flex align-items-center justify-content-center"
                  type="submit"
                >
                  Request Approval
                  {loading && (
                    <div className="spinner-border spinner-border-white spinner-border-sm ml-2"></div>
                  )}
                </button>
              </div>
            </div>
          </form>
        </RightPanel>
        <RightPanel
          open={this.state.openFlashDealInvestment}
          onClose={this.closeNewPanel}
        >
          <h4 className="panel-heading">Create New Flash deal </h4>
          <form onSubmit={this.handleSubmitFDI}>
            <label className="panel-label mt-4" htmlFor="new-investment-name">
              Offer name
            </label>
            <input
              type="text"
              className="form-control panel-input mb-0"
              placeholder="Enter offer name"
              id="new-investment-name"
              name="offerName"
              value={offerName}
              onChange={this.handleChange}
            />
            <p className="text-error">
              {errors ? errors.name : errorObject && errorObject["offerName"]}
            </p>
            <label className="panel-label mt-4" htmlFor="new-investment-name">
              Offer Opening Date
            </label>
            <DatePicker
              className={`custum-form__field custum-form__input w-100 ${
                errors?.openingData ? "error-input" : ""
              }`}
              dayClassName={() => "react-datepicker-dates"}
              minDate={new Date()}
              selected={openingData ? new Date(openingData) : null}
              onChange={(openingData) =>
                this.setState({
                  openingData: openingData.toISOString().slice(0, 10),
                })
              }
              id="startDate"
              placeholderText="Select start date"
              autoComplete="off"
              dateFormat="dd/MM/yyyy"
            />
            <p className="text-error">
              {errors
                ? errors.openingData
                : errorObject && errorObject["openingData"]}
            </p>
            <label className="panel-label mt-4" htmlFor="new-investment-name">
              Offer Closing Date
            </label>
            <DatePicker
              className={`custum-form__field custum-form__input w-100 ${
                errors?.closingDate ? "error-input" : ""
              }`}
              dayClassName={() => "react-datepicker-dates"}
              minDate={new Date()}
              selected={closingDate ? new Date(closingDate) : null}
              onChange={(closingDate) =>
                this.setState({
                  closingDate: closingDate.toISOString().slice(0, 10),
                })
              }
              id="startDate"
              placeholderText="Select end date"
              autoComplete="off"
              dateFormat="dd/MM/yyyy"
            />
            <p className="text-error">
              {errors
                ? errors.closingDate
                : errorObject && errorObject["closingDate"]}
            </p>
            <label className="panel-label mt-4" htmlFor="new-investment-name">
              Category
            </label>

            <select
              className="form-control panel-input mb-0"
              id="new-investment-type"
              name="category"
              onChange={this.handleCategoryChange}
            >
              <option value="">Select category</option>
              <option value="Bond">Bonds</option>
              <option value="CP">Commercial papers</option>
              <option value="MF">Mutual funds</option>
            </select>
            <p className="text-error">
              {errors
                ? errors.category
                : errorObject && errorObject["category"]}
            </p>

            {/*  */}
            {/*  */}

            <label className="panel-label mt-4" htmlFor="new-investment-name">
              Return Basis
            </label>

            <select
              className="form-control panel-input mb-0"
              id="new-investment-type"
              name="return_basis"
              // onChange={this.handleCategoryChange}
            >
              <option value="">Select Return Basis</option>

              <option value="simple interest">simple interest</option>
              <option value="compound interest">compound interest</option>
              <option value="upfront interest">upfront interest</option>
            </select>

            {/* end */}

            {/* Payout Frequency: (to be dynamic based on the return basis -> when it's compund interest ) */}
            <label className="panel-label mt-4" htmlFor="new-investment-name">
              Payout Frequency
            </label>
            <select
              className="form-control panel-input mb-0"
              id="payout-frequency-type"
              name="payout_frequency"
              // onChange={this.handleCategoryChange}
            >
              <option value="">Payout Frequency</option>

              <option value="monthly">Monthy</option>
              <option value="quarterly">Quarterly</option>
              <option value="annually">Annually</option>
            </select>
            {/* end */}
            {/*  */}
            <label className="panel-label mt-4" htmlFor="new-investment-name">
              Tenor (In Days)
            </label>
            <input
              type="number"
              className="form-control panel-input mb-0"
              placeholder="Enter tenor"
              id="new-investment-name"
              name="tenor"
              value={tenor}
              onChange={this.handleChange}
            />
            <p className="text-error">
              {errors ? errors.tenor : errorObject && errorObject["tenor"]}
            </p>
            <label className="panel-label mt-4" htmlFor="new-investment-name">
              Rate
            </label>
            <input
              type="number"
              className="form-control panel-input mb-0"
              placeholder="Enter rate"
              id="new-investment-name"
              name="rate"
              value={rate}
              onChange={this.handleChange}
            />
            <p className="text-error">
              {errors ? errors.rate : errorObject && errorObject["rate"]}
            </p>
            <label className="panel-label mt-4" htmlFor="new-investment-name">
              Offer Amount
            </label>
            <input
              type="number"
              className="form-control panel-input mb-0"
              placeholder="Enter offer amount"
              id="new-investment-name"
              name="offerAmount"
              value={offerAmount}
              onChange={this.handleChange}
            />
            <p className="text-error">
              {errors
                ? errors.offerAmount
                : errorObject && errorObject["offerAmount"]}
            </p>
            <label className="panel-label mt-4" htmlFor="new-investment-name">
              Minimum Investment
            </label>
            <input
              type="number"
              className="form-control panel-input mb-0"
              placeholder="Enter minimum investment"
              id="new-investment-name"
              name="minimumInvestment"
              value={minimumInvestment}
              onChange={this.handleChange}
            />
            <p className="text-error">
              {errors
                ? errors.minimumInvestment
                : errorObject && errorObject["minimumInvestment"]}
            </p>
            <label
              className="panel-label mt-4"
              htmlFor="penaltyName"
              id="penaltyName"
            >
              Liquidation Penalty
            </label>
            <div className="d-flex align-items-center form-check p-0">
              <div className="form-check form-check-inline">
                <input
                  id="LiquidationTrue"
                  type="radio"
                  className="mb-0 mr-1 form-check-input"
                  placeholder="Enter penalty"
                  name="liquidationPenalty"
                  value={"true"}
                  checked={liquidationPenalty === "true"}
                  onChange={(e) =>
                    this.setState({ liquidationPenalty: e.target.value })
                  }
                />
                <label
                  className="form-check-label mr-4 "
                  htmlFor="LiquidationTrue"
                >
                  Yes
                </label>
              </div>

              <div className="form-check form-check-inline">
                <input
                  id="LiquidationFalse"
                  type="radio"
                  className="mb-0 mr-1 form-check-input"
                  placeholder="Enter penalty"
                  name="liquidationPenalty"
                  value={"false"}
                  checked={liquidationPenalty === "false"}
                  onChange={(e) =>
                    this.setState({ liquidationPenalty: e.target.value })
                  }
                />
                <label
                  className="form-check-label mr-4"
                  htmlFor="LiquidationFalse"
                >
                  No
                </label>
              </div>
            </div>

            <p className="text-error">
              {errors
                ? errors.liquidationPenalty
                : errorObject && errorObject["liquidationPenalty"]}
            </p>
            {liquidationPenalty === "true" ? (
              <div>
                <label
                  className="panel-label mt-4"
                  htmlFor="new-investment-name"
                >
                  penalty
                </label>
                <input
                  type="text"
                  className="form-control panel-input mb-0"
                  placeholder="Enter penalty"
                  id="new-investment-name"
                  name="penalty"
                  value={penalty}
                  onChange={this.handleChange}
                />
                <p className="text-error">
                  {errors
                    ? errors.penalty
                    : errorObject && errorObject["penalty"]}
                </p>
              </div>
            ) : null}

            <label className="panel-label mt-4" htmlFor="new-investment-name">
              Tags
            </label>
            <input
              type="text"
              className="form-control panel-input mb-0"
              placeholder="Enter tags separated by commas"
              id="new-investment-name"
              name="tags"
              value={tags}
              onChange={(e) =>
                this.setState({
                  tags: e.target.value.split(",").map((tag) => tag.trim()),
                })
              }
            />
            <p className="text-error">
              {errors ? errors.tags : errorObject && errorObject["tags"]}
            </p>
            <label
              className="panel-label mt-4"
              htmlFor="new-investment-summary"
            >
              Summary
            </label>
            <input
              type="text"
              className={`form-control panel-input mb-0 ${
                errors?.summary ? "error-input" : ""
              }`}
              id="new-investment-summary"
              name="summary"
              value={summary}
              onChange={(e) => this.setState({ summary: e.target.value })}
            />
            <p className="text-error">
              {errors ? errors.summary : errorObject && errorObject["summary"]}
            </p>
            <label
              className="panel-label mt-4"
              htmlFor="new-investment-description"
            >
              Description
            </label>
            <TextEditor
              customClass={`custum-form__editor ${
                errors?.description ? "custum-form__editor--error" : ""
              }`}
              onChange={(description) => this.setState({ description })}
              toolbarOptions={[
                "history",
                "inline",
                "blockType",
                "fontSize",
                "fontFamily",
                "image",
                "list",
                "textAlign",
              ]}
              toolbarConfigs={{
                inline: { options: ["bold", "italic", "underline"] },
              }}
              height="200px"
              defaultValue={description}
            />
            <p className="text-error">
              {errors
                ? errors.description
                : errorObject && errorObject["description"]}
            </p>
            <label className="panel-label mt-4" htmlFor="new-investment-icon">
              Offer Document
            </label>
            <div className="img-input" id="new-investment-icon">
              <div className="img-upload d-flex align-items-center">
                <div>
                  <input
                    type="file"
                    className="file"
                    ref={this.offerDocRef}
                    accept="image/*"
                    onChange={this.handleOfferDocImageSelect}
                  />
                  <button
                    className="bg-white pl-4 pr-4"
                    onClick={this.handleOfferDocFileClick}
                  >
                    Upload file
                  </button>
                  <p>{offerDocumentFileName}</p>
                  <p>Upload PDF, JPEG, JPG or PNG</p>
                </div>
              </div>
              <p className="text-error">
                {errors
                  ? errors.offerDocument
                  : errorObject && errorObject["offerDocument"]}
              </p>
            </div>
            <label className="panel-label mt-4" htmlFor="new-investment-icon">
              Investment icon
            </label>
            <div className="img-input" id="new-investment-icon">
              <div className="img-upload d-flex align-items-center">
                <div>
                  <input
                    type="file"
                    className="file"
                    ref={this.imgRef}
                    accept="image/*"
                    onChange={this.handleImageSelect}
                  />
                  <button
                    className="bg-white pl-4 pr-4"
                    onClick={this.handleFileClick}
                  >
                    Upload file
                  </button>
                  <p>{fileName}</p>
                  <p>Upload PDF, JPEG, JPG or PNG</p>
                </div>
              </div>
              <p className="text-error">
                {errors ? errors.icon : errorObject && errorObject["icon"]}
              </p>
            </div>
            <label className="panel-label mt-4" htmlFor="new-investment-icon">
              Background Image
            </label>
            <div className="img-input" id="new-investment-icon">
              <div className="img-upload d-flex align-items-center">
                <div>
                  <input
                    type="file"
                    className="file"
                    ref={this.backgroundRef}
                    accept="image/*"
                    onChange={this.handleBackgroundImageSelect}
                  />
                  <button
                    className="bg-white pl-4 pr-4"
                    onClick={this.handleBackgroundClick}
                  >
                    Upload file
                  </button>
                  <p>{backgroundFileName}</p>
                  <p>Upload PDF, JPEG, JPG or PNG</p>
                </div>
              </div>
              <p className="text-error">
                {errors
                  ? errors.background
                  : errorObject && errorObject["background"]}
              </p>
            </div>
            <div className="row">
              <div className="col-md-6">
                <button
                  className="btn btn-white btn-sm font-md w-100"
                  onClick={this.closeNewPanel}
                >
                  Cancel
                </button>
              </div>
              <div className="col-md-6">
                <button
                  className="btn btn-primary btn-sm font-md w-100 d-flex align-items-center justify-content-center"
                  type="submit"
                >
                  Request Approval
                  {loading && (
                    <div className="spinner-border spinner-border-white spinner-border-sm ml-2"></div>
                  )}
                </button>
              </div>
            </div>
          </form>
        </RightPanel>

        <div className="setup-inner__top">
          <ul className="setup-inner__nav">
            <li className="setup-nav__item">
              <NavLink
                exact
                to={{ pathname: `${path}` }}
                activeClassName="setup-nav__item--active"
              >
                Termed Inv.
              </NavLink>
            </li>
            {canApprove && (
              <li className="setup-nav__item">
                <NavLink
                  to={{ pathname: `${path}/pending` }}
                  activeClassName="setup-nav__item--active"
                >
                  Pending Approval
                </NavLink>
              </li>
            )}
            <li className="setup-nav__item">
              <NavLink
                exact
                to={{ pathname: `${path}/flash-deals` }}
                activeClassName="setup-nav__item--active"
              >
                Flash Deals
              </NavLink>
            </li>
            <li className="setup-nav__item">
              <NavLink
                exact
                to={{ pathname: `${path}/pending-flash-deals` }}
                activeClassName="setup-nav__item--active"
              >
                Pending Flash Deals
              </NavLink>
            </li>
          </ul>
          <SearchBox
            placeholder="Search"
            handleSearch={(searchTerm) => this.setState({ searchTerm })}
            isActiveSearch={Boolean(searchTerm)}
            key={this.props.location.pathname}
          />
          <ExportDropdown
            module="services"
            status={isShowingActiveInvestment ? "approved" : "pending-approval"}
            page={this.props.displayedInvestment.data?.investments?.page}
            limit={this.props.displayedInvestment.data?.investments?.perPage}
            hasMoreThanOnePage={
              this.props.displayedInvestment.data?.investments?.lastPage > 1
            }
          />
          {selectedRowsIds.length > 0 && (
            <MultipleApprovalDropdown
              approve={() => this.setState({ approveMultiple: true })}
              reject={() => this.setState({ rejectMultiple: true })}
            />
          )}
          {isShowingActiveInvestment && canCreate && (
            <button
              className="setup-inner__button"
              onClick={() =>
                this.setState({
                  [isFlashDealPath ? "openFlashDealInvestment" : "open"]: true,
                })
              }
            >
              <img
                src={require("@/assets/icons/plus.svg").default}
                alt="plus icon"
                className="setup-inner__button-img"
              />
              {isFlashDealPath ? "New Flash Deal Inv." : "Create Investment"}
            </button>
          )}
        </div>

        <Switch>
          <Route exact path={path}>
            <TermedInvestments searchTerm={searchTerm} />
          </Route>
          {canApprove && (
            <Route path={`${path}/pending`}>
              <PendingApproval
                searchTerm={searchTerm}
                onCheckRow={this.onCheckRow}
              />
            </Route>
          )}
          <Route exact path={`${path}/flash-deals`}>
            <FlashDealInvestment searchTerm={searchTerm} />
          </Route>
          <Route exact path={`${path}/pending-flash-deals`}>
            <PendingApprovalFlashDeal
              searchTerm={searchTerm}
              onCheckRow={this.onCheckRow}
            />
          </Route>
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    app: {
      investments: {
        pendingInvestments,
        allInvestments,
        newInvestments: { data, error },
      },
    },
    user: { permissions },
  } = state;
  return {
    loading: getActionLoadingState(
      state,
      actionTypes.CREATE_INVESTMENT_REQUEST
    ),
    error,
    data,
    permissions,
    displayedInvestment: pendingInvestments || allInvestments,
    multipleUpdateLoading: getActionLoadingState(
      state,
      actionTypes.UPDATE_MULTIPLE_INVESTMENT_STATUS_REQUEST
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createNewInvestment: (payload) => dispatch(createNewInvestment(payload)),
    createNewFSInvestment: (payload) =>
      dispatch(createNewFSInvestment(payload)),

    updateMultipleInvestmentStatus: (ids, status) =>
      dispatch(updateMultipleInvestmentStatus(ids, status)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Investments)
);
