import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import dayjs from "dayjs";
import {
  fetchCustomerTransactions,
  exportCustomerTransactionHistory,
} from "@/store/Customers";
import { getActionLoadingState } from "@/store/selectors";
import actionTypes from "@/store/Customers/actionTypes";
import { ReactComponent as BackArrrow } from "@/assets/icons/previous-page-arrow.svg";
import Emptystate from "@/components/Emptystate";
import Pagination from "@/components/Pagination";
import DateRangeFilter from "@/components/DateRangeFilter";
import RightPanel from "@/components/RightPanel";
import ExportDropdown from "@/components/ExportDropdown";
import { currency } from "@/utils";

class TransactionHistory extends Component {
  state = {
    transactions: [],
    startDate: "",
    endDate: "",
    user: {},
  };

  componentDidMount() {
    this.getTransactions(1, 10);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.getTransactions(1, 10);
    }
    if (
      this.state.startDate !== prevState.startDate ||
      this.state.endDate !== prevState.endDate
    ) {
      this.getTransactions(1, 10);
    }
  }

  getTransactions = (page, limit) => {
    const { id } = this.props.match.params;
    const { startDate, endDate } = this.state;
    this.props
      .fetchTransaction(id, page, limit, startDate, endDate)
      .then((data) => {
        // if startDate has a value it means it was a filter transactions request
        startDate
          ? this.setPageData(data)
          : this.setPageData(data.transactions, data.user);
      });
  };

  setPageData = (
    { lastPage, perPage, page, total, data: transactions },
    user = {}
  ) => {
    this.setState({ transactions, lastPage, perPage, page, total, user });
  };

  renderTransactions = () => {
    return (
      <table className="custum-table">
        <tbody>
          <tr>
            <th>Transaction Date</th>
            <th>Time</th>
            <th>Reference Number</th>
            <th>Description</th>
            <th>Amount</th>
          </tr>
          {this.state.transactions.map((data) => (
            <tr key={data.id}>
              <td>{dayjs(data.created_at).format("D MMM YYYY")}</td>
              <td>{dayjs(data.created_at).format("hh:mm")}</td>
              <td>{data.reference}</td>
              <td className="text-capitalize--first">{data.title}</td>
              <td
                className={`${
                  data.type === "credit"
                    ? "text-green"
                    : data.type === "debit"
                    ? "text-red"
                    : "text-blue"
                } font-weight-bold`}
              >
                {`₦${currency(data.amount)}`}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  filterTransaction = (startDate, endDate) => {
    this.setState({ startDate, endDate });
  };

  viewUserInfo = () => {
    this.setState({ open: true });
  };

  render() {
    const { transactions, lastPage, perPage, page, total, startDate, endDate } =
      this.state;
    const {
      firstName,
      middleName,
      lastName,
      phone,
      countryCode,
      pictureUrl,
      email,
      id,
    } = this.state.user;

    return (
      <div className="setup-area">
        <RightPanel
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
        >
          <h1 className="panel-heading">User Information</h1>
          <div className="panel-info">
            <div className="panel-info__picture">
              <img
                src={pictureUrl || require("@/assets/icons/img-default.svg")}
                alt="profile"
              />
            </div>
            <div className="panel-info__row">
              <span className="panel-info__field">Full name</span>
              <span className="panel-info__value">{`${firstName} ${
                middleName ?? ""
              } ${lastName}`}</span>
            </div>
            <div className="panel-info__row">
              <span className="panel-info__field">Phone number</span>
              <span className="panel-info__value">
                {`${countryCode ?? "0"}` + Number(phone)}
              </span>
            </div>
            <div className="panel-info__row">
              <span className="panel-info__field">Email address</span>
              <span className="panel-info__value">{email}</span>
            </div>
            <button
              className="long-button"
              onClick={() => this.setState({ open: false })}
            >
              Close
            </button>
          </div>
        </RightPanel>
        <div className="px-5 py-4 bg-white d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center ml-2">
            <BackArrrow
              className="cp-pagination__arrow--active"
              onClick={() => this.props.history.goBack()}
            />
            <span
              className="font-md ml-3 font-weight-bold cp-pagination__arrow--active"
              onClick={() => this.props.history.goBack()}
            >
              Back
            </span>
          </div>
          <h4 className="color-dark-blue my-0">Transaction History</h4>
          <div>&nbsp;</div>
        </div>
        <div className="setup-inner">
          {this.props.fetching ? (
            <div className="text-center text-primary">
              <div className="spinner-border" role="status"></div>
            </div>
          ) : (
            <div className="">
              <div className="d-flex  align-items-center mb-5">
                <button
                  className="btn btn-link text-small text-400 mr-auto"
                  onClick={this.viewUserInfo}
                >
                  View User Information
                </button>
                {transactions.length > 0 && (
                  <ExportDropdown
                    module="Transaction history"
                    status={id}
                    page={page}
                    limit={perPage}
                    hasMoreThanOnePage={lastPage > 1}
                    downloadHandler={this.props.exportHistory}
                  />
                )}
                <DateRangeFilter
                  filterHandler={(startDate, endDate) =>
                    this.filterTransaction(startDate, endDate)
                  }
                  startDate={startDate}
                  endDate={endDate}
                />
              </div>
              {!transactions.length ? (
                <Emptystate
                  title="No transaction"
                  icon={require("@/assets/icons/info.svg").default}
                />
              ) : (
                <>
                  <Pagination
                    totalPages={lastPage}
                    page={page}
                    limit={perPage}
                    changePageHandler={(page, limit) =>
                      this.getTransactions(page, limit)
                    }
                  />
                  <div className="table-overflow">
                    <div className="setup-inner__main setup-inner__expand">
                      {this.renderTransactions()}
                    </div>
                    <div className="data-count">
                      Showing
                      <span className="font-weight-bold mx-2">{`${transactions.length} of ${total}`}</span>
                      Transactions
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fetching: getActionLoadingState(state, actionTypes.GET_CUSTOMERS_REQUEST),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTransaction: (id, page, limit, startDate, endDate) =>
      dispatch(fetchCustomerTransactions(id, page, limit, startDate, endDate)),
    exportHistory: (module, format, status, page, limit) =>
      dispatch(
        exportCustomerTransactionHistory(module, format, status, page, limit)
      ),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TransactionHistory)
);
