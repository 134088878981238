import React from "react";
import { withRouter, Route, Switch, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { getActionLoadingState } from "@/store/selectors";
import { addGuideCategory } from "@/store/support/actions";
import actionTypes from "@/store/support/actionTypes";
import RightPanel from "@/components/RightPanel";
import { serializeErrors, validateFields, hasPermission } from "@/utils";
import Guides from "./Guides";
import PendingApproval from "./PendingApproval";
import SearchBox from "@/components/SearchBox";
import PendingCategories from "./PendingCategories";
import "./style.scss";

class UserGuide extends React.Component {
  imgRef = React.createRef();

  state = {
    search: "",
    openPanel: false,
    categoryName: "",
    description: "",
    errors: "",
    categoryImage: null,
    fileName: "",
  };

  handleSubmitSearch = (event) => {
    event.preventDefault();
    // const { search } = this.state;
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });

    // if(name === 'search' && value === '') {
    //   if(this.props.history.location.pathname.split('/').includes('solved')){
    //     return this.props.getTickets('close', 10, 1);
    //   }
    //   return this.props.getTickets('open', 10, 1);
    // }
  };

  handleFileClick = (event) => {
    event.preventDefault();
    this.imgRef.current.click();
  };

  handleImageSelect = (event) => {
    const { target } = event;
    if (target.files[0]) {
      this.setState({
        fileName: target.files[0].name,
        categoryImage: target.files[0],
      });
    }
  };

  handleCreateCategory = (event) => {
    event.preventDefault();
    const { addGuideCategory } = this.props;
    const { categoryName, categoryImage, description } = this.state;

    const data = this.state;
    const required = ["categoryName", "fileName", "description"];
    const errors = validateFields(data, required);

    if (Object.keys(errors).length > 0) {
      return this.setState({ errors });
    }
    const formData = new FormData();
    formData.append("title", categoryName);
    formData.append("image", categoryImage);
    formData.append("content", description);

    addGuideCategory(formData).then((data) => {
      this.setState((prevState) => ({ openPanel: !prevState.openPanel }));
      this.setState({ categoryName: "", description: "", fileName: "" });
    });
  };

  toggleNewCategoryPanel = (event) => {
    event.preventDefault();
    this.setState((prevState) => ({ openPanel: !prevState.openPanel }));
  };

  render() {
    const {
      match: { path },
      loading,
      error,
      permissions,
    } = this.props;
    const { search, openPanel, categoryName, errors, fileName, description } =
      this.state;
    const errorObject = serializeErrors(error);
    const canApprove = hasPermission(permissions, "approve_service");

    return (
      <div className="user-guide-page">
        <RightPanel open={openPanel} onClose={this.toggleNewCategoryPanel}>
          <h4 className="panel-heading">Add new category</h4>
          <form noValidate onSubmit={this.handleCreateCategory}>
            <label className="panel-label" htmlFor="new-category-name">
              Category name
            </label>
            <input
              name="categoryName"
              type="text"
              placeholder="Category name"
              className="border-grey form-control panel-input mb-0"
              value={categoryName}
              onChange={this.handleChange}
            />
            <p className="text-error">
              {errors
                ? errors.categoryName
                : errorObject && errorObject["title"]}
            </p>

            <label className="panel-label mt-4" htmlFor="new-category-name">
              Category description
            </label>
            <textarea
              name="description"
              type="text"
              placeholder="Category description"
              className="border-grey form-control panel-input mb-0"
              value={description}
              onChange={this.handleChange}
            />
            <p className="text-error">
              {errors
                ? errors.description
                : errorObject && errorObject["content"]}
            </p>

            <label className="panel-label mt-4" htmlFor="new-investment-icon">
              Category Icon
            </label>
            <div className="img-input" id="new-category-icon">
              <div className="img-upload d-flex align-items-center">
                <div>
                  <input
                    type="file"
                    className="file"
                    ref={this.imgRef}
                    accept="image/*"
                    onChange={this.handleImageSelect}
                  />
                  <button
                    className="bg-white pl-4 pr-4"
                    onClick={this.handleFileClick}
                  >
                    Upload file
                  </button>
                  <p>{fileName}</p>
                  <p>Upload JPEG, JPG or PNG</p>
                </div>
              </div>
              <p className="text-error">
                {errors ? errors.fileName : errorObject && errorObject["image"]}
              </p>
            </div>
            <div className="row mt-5">
              <div className="col-md-6">
                <button
                  className="btn btn-white btn-sm font-md w-100"
                  onClick={this.toggleNewCategoryPanel}
                >
                  Cancel
                </button>
              </div>
              <div className="col-md-6">
                <button
                  className="btn btn-primary btn-sm font-md w-100 d-flex align-items-center justify-content-center"
                  type="submit"
                >
                  Request Approval
                  {loading && (
                    <div className="spinner-border spinner-border-white spinner-border-sm ml-2"></div>
                  )}
                </button>
              </div>
            </div>
          </form>
        </RightPanel>
        <div className="setup-inner__top justify-content-between">
          <ul className="setup-inner__nav mr-0">
            <li className="setup-nav__item">
              <NavLink
                exact
                to={{ pathname: `${path}`, state: { pageTitle: "User Guide" } }}
                activeClassName="setup-nav__item--active"
              >
                User Guides
              </NavLink>
            </li>
            {canApprove && (
              <li className="setup-nav__item">
                <NavLink
                  to={{
                    pathname: `${path}/pending`,
                    state: { pageTitle: "User Guide" },
                  }}
                  activeClassName="setup-nav__item--active"
                >
                  Pending Approval
                </NavLink>
              </li>
            )}
            {canApprove && (
              <li className="setup-nav__item">
                <NavLink
                  to={{
                    pathname: `${path}/pending-categories`,
                    state: { pageTitle: "User Guide" },
                  }}
                  activeClassName="setup-nav__item--active"
                >
                  Pending categories
                </NavLink>
              </li>
            )}
          </ul>
          <div className="align-items-center d-flex">
            <SearchBox
              placeholder="Search"
              handleSearch={(searchTerm) =>
                this.setState({ search: searchTerm, shouldSearch: true })
              }
              isActiveSearch={Boolean(search)}
              key={this.props.location.pathname}
            />
            <button
              className="setup-inner__button"
              onClick={this.toggleNewCategoryPanel}
            >
              <img
                src={require("@/assets/icons/add-icon.svg").default}
                className="setup-inner__button-img"
                alt="add"
              />
              Add New Category
            </button>
          </div>
        </div>
        <Switch>
          <Route exact path={path}>
            <Guides searchString={search} />
          </Route>
          <Route path={`${path}/pending`}>
            <PendingApproval searchString={search} />
          </Route>
          <Route exact path={`${path}/pending-categories`}>
            <PendingCategories searchString={search} />
          </Route>
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    app: {
      support: { error },
    },
    user: { permissions },
  } = state;
  return {
    loading: getActionLoadingState(
      state,
      actionTypes.ADD_GUIDE_CATEGORY_REQUEST
    ),
    error,
    permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addGuideCategory: (payload) => dispatch(addGuideCategory(payload)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserGuide)
);
