module.exports = {
  CLEAR: "investments/CLEAR",
  CLEAR_DATA: "investments/CLEAR_DATA",
  CREATE_INVESTMENT_REQUEST: "investments/CREATE_INVESTMENT_REQUEST",
  CREATE_INVESTMENT_ERROR: "investments/CREATE_INVESTMENT_ERROR",
  CREATE_INVESTMENT_SUCCESS: "investments/CREATE_INVESTMENT_SUCCESS",
  CREATE_FLASH_DEALS_INVESTMENT_REQUEST: "investments/CREATE_FLASH_DEALS_INVESTMENT_REQUEST",
  CREATE_FLASH_DEALS_INVESTMENT_ERROR: "investments/CREATE_FLASH_DEALS_INVESTMENT_ERROR",
  CREATE_FLASH_DEALS_INVESTMENT_SUCCESS: "investments/CREATE_FLASH_DEALS_INVESTMENT_SUCCESS",
  FETCH_ALL_INVESTMENTS_REQUEST: "investments/FETCH_ALL_INVESTMENTS_REQUEST",
  FETCH_ALL_INVESTMENTS_ERROR: "investments/FETCH_ALL_INVESTMENTS_ERROR",
  FETCH_ALL_INVESTMENTS_SUCCESS: "investments/FETCH_ALL_INVESTMENTS_SUCCESS",
  FETCH_FLASH_DEALS_INVESTMENTS_REQUEST: "investments/FETCH_FLASH_DEALS_INVESTMENTS_REQUEST",
  FETCH_FLASH_DEALS_INVESTMENTS_ERROR: "investments/FETCH_FLASH_DEALS_INVESTMENTS_ERROR",
  FETCH_FLASH_DEALS_INVESTMENTS_SUCCESS: "investments/FETCH_FLASH_DEALS_INVESTMENTS_SUCCESS",
  FETCH_ALL_PENDING_INVESTMENTS_REQUEST: "investments/FETCH_ALL_PENDING_INVESTMENTS_REQUEST",
  FETCH_ALL_PENDING_INVESTMENTS_ERROR: "investments/FETCH_ALL_PENDING_INVESTMENTS_ERROR",
  FETCH_ALL_PENDING_INVESTMENTS_SUCCESS: "investments/FETCH_ALL_PENDING_INVESTMENTS_SUCCESS",
  FETCH_ALL_PENDING_FLASH_DEALS_INVESTMENTS_REQUEST: "investments/FETCH_ALL_PENDING_FLASH_DEALS_INVESTMENTS_REQUEST",
  FETCH_ALL_PENDING_FLASH_DEALS_INVESTMENTS_ERROR: "investments/FETCH_ALL_PENDING_FLASH_DEALS_INVESTMENTS_ERROR",
  FETCH_ALL_PENDING_FLASH_DEALS_INVESTMENTS_SUCCESS: "investments/FETCH_ALL_PENDING_FLASH_DEALS_INVESTMENTS_SUCCESS",
  UPDATE_INVESTMENT_STATUS_REQUEST: "investments/UPDATE_INVESTMENT_STATUS_REQUEST",
  UPDATE_INVESTMENT_STATUS_ERROR: "investments/UPDATE_INVESTMENT_STATUS_ERROR",
  UPDATE_INVESTMENT_STATUS_SUCCESS: "investments/UPDATE_INVESTMENT_STATUS_SUCCESS",
  UPDATE_FLASH_DEAL_INVESTMENT_STATUS_REQUEST: "investments/UPDATE_FLASH_DEAL_INVESTMENT_STATUS_REQUEST",
  UPDATE_FLASH_DEAL_INVESTMENT_STATUS_ERROR: "investments/UPDATE_FLASH_DEAL_INVESTMENT_STATUS_ERROR",
  UPDATE_FLASH_DEAL_INVESTMENT_STATUS_SUCCESS: "investments/UPDATE_FLASH_DEAL_INVESTMENT_STATUS_SUCCESS",
  UPDATE_MULTIPLE_INVESTMENT_STATUS_REQUEST: "investments/UPDATE_MULTIPLE_INVESTMENT_STATUS_REQUEST",
  UPDATE_MULTIPLE_INVESTMENT_STATUS_SUCCESS: "investments/UPDATE_MULTIPLE_INVESTMENT_STATUS_SUCCESS",
  UPDATE_MULTIPLE_INVESTMENT_STATUS_ERROR: "investments/UPDATE_MULTIPLE_INVESTMENT_STATUS_ERROR",
  UPDATE_INVESTMENT_REQUEST: "investments/UPDATE_INVESTMENT_REQUEST",
  UPDATE_INVESTMENT_ERROR: "investments/UPDATE_INVESTMENT_ERROR",
  UPDATE_INVESTMENT_SUCCESS: "investments/UPDATE_INVESTMENT_SUCCESS",
  UPDATE_FLASH_DEAL_INVESTMENT_REQUEST: "investments/UPDATE_FLASH_DEAL_INVESTMENT_REQUEST",
  UPDATE_FLASH_DEAL_INVESTMENT_ERROR: "investments/UPDATE_FLASH_DEAL_INVESTMENT_ERROR",
  UPDATE_FLASH_DEAL_INVESTMENT_SUCCESS: "investments/UPDATE_FLASH_DEAL_INVESTMENT_SUCCESS",
  REMOVE_INVESTMENT_REQUEST: "investments/REMOVE_INVESTMENT_REQUEST",
  REMOVE_INVESTMENT_ERROR: "investments/REMOVE_INVESTMENT_ERROR",
  REMOVE_INVESTMENT_SUCCESS: "investments/REMOVE_INVESTMENT_SUCCESS",
}