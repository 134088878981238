import CONFIG from '@/config';
import * as actions from "./actionTypes";
import { updateUser, logout } from '@/store/user/actions';
import axios from 'axios';
import { showAlert } from '@/store/ui/actions';

const clearError = () => {
  return {
    type: actions.CLEAR_ERROR,
  }
}

const clearData = () => {
  return {
    type: actions.CLEAR_DATA,
  }
}

const loginRequest = () => {
  return {
      type: actions.LOGIN_REQUEST,
  }
}

const loginError = (message) => {
  return {
    type: actions.LOGIN_ERROR,
    error: message,
  }
}

const loginSuccess = () => {
  return {
      type: actions.LOGIN_SUCCESS,
  }
}

const resetPasswordRequest = () => {
  return {
    type: actions.RESET_PASSWORD_REQUEST,
  }
}

const resetPasswordSuccess = (data) => {
  return {
    type: actions.RESET_PASSWORD_SUCCESS,
    data
  }
}

const resetPasswordError = (error) => {
  return {
    type: actions.RESET_PASSWORD_ERROR,
    error,
  }
}

export const login = (payload, history) => {
  return (dispatch) => {
    dispatch(loginRequest());

    fetch(`${CONFIG.BASE_URL}/auth/login`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(payload)
    })
    .then(response => {
        if ([200, 201].includes(response.status)) {
          response.json()
          .then(res => {
            if(res.isStaff === false) {
              return dispatch(loginError('unauthorized'));
            }
            dispatch(updateUser({ 
              token: `Bearer ${res.token}`,
            }));
            if(!res.isActiveAdmin) {
              return history.push('/setup-password');
            }
            dispatch(fetchAdminProfile(history));
          });
        }

        if ([400, 401, 403, 404].includes(response.status)) {
          response.json()
          .then(res => {
              dispatch(loginError(res.error ? res.error : res.message));
              setTimeout(() => dispatch(clearError()), 3000);
          })
        }
        if (response.status >= 500) {
          dispatch(loginError('Oops! We did something wrong.'));
        }
    })
    .catch(() => {
      dispatch(loginError('Oops! We did something wrong.'));
    })
  }
}

export const logoutUser = () => {
  return (dispatch, getState) => {
    fetch(`${CONFIG.BASE_URL}/auth/logout`, {
        method: "GET",
        headers: {
            "Authorization": getState().user.token,
        },
    })
    .then(() => dispatch(logout(true)))
    .catch(() => {
      dispatch(showAlert({ type: 'error', message: 'Oops! something went wrong please try again.' }))
    })
  }
}

export const resetPassword = (payload, history) => {
  return (dispatch, getState) => {
    dispatch(resetPasswordRequest());

    const { token } = getState().user;

    fetch(`${CONFIG.BASE_URL}/auth/password/update`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload)
    })
    .then(response => {
        if ([200, 201].includes(response.status)) {
          response.json()
          .then(res => {
            dispatch(resetPasswordSuccess(res));
            dispatch(fetchAdminProfile(history));
          });
        }
        if ([400,404].includes(response.status)) {
          response.json()
          .then(res => {
              dispatch(resetPasswordError(res.error ? res.error : res.message));
          })
        }
        if ([401].includes(response.status)) {
          dispatch(logout())
        }
        if (response.status >= 500) {
          dispatch(resetPasswordError('Oops! We did something wrong.'));
        }
    })
    .catch(() => {
      dispatch(resetPasswordError('Oops! We did something wrong.'));
    })
  }
}

const fetchAdminProfile = (history) => {
  return (dispatch, getState) => {
    fetch(`${CONFIG.BASE_URL}/admin/profile`, {
        method: "GET",
        headers: {
            "Authorization": getState().user.token,
        },
    })
    .then(response => {
        if (response.status === 200) {
            response.json()
            .then(res => {
              const userData = res.data.user;
              dispatch(updateUser({
                emailAddress: userData.email,
                authorized: true,
                isStaff: userData.staff,
                picture: userData.pictureUrl,
                firstName: userData.firstName,
                lastName: userData.lastName,
                lastLogin: userData.last_login_at,
                isActiveAdmin: userData.activeAdmin,
                email: userData.email,
                phone: userData.phone,
                role: userData.roles[0].slug,
                permissions: userData.roles[0].permissions.map(permission => permission.slug)
              }));
              dispatch(loginSuccess());
              dispatch(clearError());
              dispatch(clearData())
              if(sessionStorage.getItem('redirectOnLogin') && document.referrer) {
                sessionStorage.removeItem('redirectOnLogin');
                window.location = document.referrer;
                return;
              }
              history.push('/dashboard');
            })
        }
        if(response.status === 401) {
            dispatch(logout());
        }
    })
    .catch(() => {
        dispatch(loginError("Oops! something went wrong please try again."));
    })
  }
}

const getRefreshTokenRequest = () => {
  return {
    type: actions.GET_REFRESH_TOKEN_REQUEST,
  }
}

const getRefreshTokenError = (message) => {
  return {
    type: actions.GET_REFRESH_TOKEN_ERROR,
    error: message,
  }
}

const getRefreshTokenSuccess = (data) => {
  return {
    type: actions.GET_REFRESH_TOKEN_SUCCESS,
    data,
  }
}

export const getRefreshToken = () => {
  return (dispatch, getState) => {
    dispatch(getRefreshTokenRequest());

    const { token } = getState().user;

    axios.get(`${CONFIG.BASE_URL}/auth/refresh-token`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
    .then(response => {
      dispatch(updateUser({
        token: response.headers.authorization
      }))
      dispatch(getRefreshTokenSuccess(response.data));
    })
    .catch(({ response }) => {
      response && dispatch(updateUser({
        token: response.headers.authorization
      }))
      if (response && [400, 404, 422].includes(response.status)) {
        return dispatch(getRefreshTokenError(response.data.error ? response.data.error : response.data.message));
      }
      if (response && [401].includes(response.status)) {
        dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
        return setTimeout(() => dispatch(logout()), 2000) 
      }
      if (response && response.status >= 500) {
        return dispatch(getRefreshTokenError('Oops! We did something wrong.'));
      }
      return dispatch(getRefreshTokenError('Oops! We did something wrong.'));
    })
  }
}