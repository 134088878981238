import React, { Component } from "react";
import Dropdown from "@/components/DropdownMenu";

class CustomSelectInput extends Component {
  valueRef = React.createRef();
  renderMenu = () =>
    this.props.options.map((option) => {
      if (option.value === this.props.value)
        this.valueRef.current = option.name;
      return {
        name: option.name,
        handler: () => this.props.onChange(option.value),
      };
    });

  render() {
    return (
      <Dropdown
        menu={this.renderMenu()}
        width={this.props.width ?? "114px"}
        left
      >
        <div
          className="setup-nav__dropdown mr-4"
          style={{ width: this.props.width ?? "114px" }}
        >
          {this.valueRef.current}
          {this.props.loading ? (
            <div className="spinner-border spinner-border-sm ml-2 ml-auto"></div>
          ) : (
            <img
              src={require("@/assets/icons/dropdown-caret.svg").default}
              alt="arrow down"
              className="ml-auto"
            />
          )}
        </div>
      </Dropdown>
    );
  }
}

export default CustomSelectInput;
