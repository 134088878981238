import React, { Component } from "react";
import { connect } from "react-redux";
import dayjs from "dayjs";
import { Bar } from "react-chartjs-2";
import { getBarChartOptions } from "@/utils/index";
import { fetchDemographics } from "@/store/analytics/usersAnalyticsAction";
import AnalyticsDateInput from "@/components/AnalyticsDateInput";

class Demographics extends Component {
  state = {
    ageDistribution: null,
    genderDistribution: null,
  };

  componentDidMount() {
    this.fetchAgeDemographics();
    this.fetchGenderDemographics();
  }

  fetchAgeDemographics = () => {
    const date =
      this.props.ageDate && dayjs(this.props.ageDate).format("YYYY-MM-DD");
    const type = date ? "age" : "default-no-date";
    this.props.fetchDemographics(type, date).then((ageData) => {
      this.setState({ ageDistribution: ageData.data.ageDistribution });
    });
  };

  fetchGenderDemographics = () => {
    const date =
      this.props.genderDate &&
      dayjs(this.props.genderDate).format("YYYY-MM-DD");
    const type = date ? "gender" : "default-no-date";
    this.props.fetchDemographics(type, date).then((genderData) => {
      this.setState({
        genderDistribution: genderData.data.genderDistribution,
      });
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.ageDate !== prevProps.ageDate) {
      this.fetchAgeDemographics();
    }
    if (this.props.genderDate !== prevProps.genderDate) {
      this.fetchGenderDemographics();
    }
  }

  ageData = () => ({
    labels: [...this.state.ageDistribution.label],
    datasets: [
      {
        data: [...this.state.ageDistribution.data],
        backgroundColor: [
          "#9DC6FB",
          "#C8EA73",
          "#F4A28C",
          "#A0A2EC",
          "#9DC6FB",
          "#FFCEBF",
        ],
      },
    ],
  });

  genderData = () => ({
    labels: [...this.state.genderDistribution.label],
    datasets: [
      {
        data: [...this.state.genderDistribution.data],
        backgroundColor: ["#9DC6FB", "#F4A28C", "#A0A2EC"],
      },
    ],
  });

  render() {
    const { ageDistribution, genderDistribution } = this.state;

    const { ageDate, genderDate, handleAgeDateChange, handleGenderDateChange } =
      this.props;
    return (
      <div id="users-container">
        <div className="setup-inner__main p-5 analytics-graph" id="users-print">
          <div className="text-center">
            <h5 className="text-medium">Age Demographics</h5>
            <AnalyticsDateInput
              value={ageDate}
              handleChange={(date) => handleAgeDateChange(date)}
            />
          </div>
          {!ageDistribution ? (
            <div className="text-center text-primary">
              <div className="spinner-border" role="status"></div>
            </div>
          ) : (
            <Bar
              data={this.ageData}
              options={getBarChartOptions("No. of Users", "Age Range")}
            />
          )}
        </div>
        <div
          className="setup-inner__main p-5 mt-5 analytics-graph"
          id="users-print"
        >
          <div className="text-center">
            <h5 className="text-medium">Gender Demographics</h5>
            <AnalyticsDateInput
              value={genderDate}
              handleChange={(date) => handleGenderDateChange(date)}
            />
          </div>
          {!genderDistribution ? (
            <div className="text-center text-primary">
              <div className="spinner-border" role="status"></div>
            </div>
          ) : (
            <Bar
              data={this.genderData}
              options={getBarChartOptions("No. of Users", "Gender")}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDemographics: (type, date) => dispatch(fetchDemographics(type, date)),
  };
};

export default connect(null, mapDispatchToProps)(Demographics);
