import * as actions from './actionTypes';

const blogDefaultState = {
  data: null,
  error: false,
  loading: false,
  post: null,
  posts: null,
  categories: null,
  pending: null,
  drafts: null,
  pendingCategories: null,
}

export default (state = blogDefaultState, action) => {
  switch (action.type) {
    case actions.CLEAR:
      return { ...state, loading: false, error: false, data: null };
    case actions.GET_BLOG_POSTS_SUCCESS:
      return { ...state, loading: false, error: false, posts: action.data?.data?.data, data: action.data?.data }
    case actions.GET_BLOG_POSTS_ERROR:
      return { ...state, loading: false, error: action.error }
    case actions.PUBLISH_BLOG_POSTS_SUCCESS:
      return { ...state, loading: false, error: false, data: action.data }
    case actions.PUBLISH_BLOG_POSTS_ERROR:
      return { ...state, loading: false, error: action.error }
    case actions.GET_CATEGORIES_SUCCESS:
      return { ...state, loading: false, error: false, categories: action.data?.data?.data, data: action.data?.data }
    case actions.GET_CATEGORIES_ERROR:
      return { ...state, loading: false, error: action.error }
    case actions.GET_POST_BY_STATUS_SUCCESS:
      return { ...state, loading: false, error: false, pending: action.data?.data?.data, data: action.data?.data }
    case actions.GET_POST_BY_STATUS_ERROR:
      return { ...state, loading: false, error: action.error }
    case actions.GET_DRAFTS_SUCCESS:
      return { ...state, loading: false, error: false, drafts: action.data?.data?.data, data: action.data?.data }
    case actions.GET_DRAFTS_ERROR:
      return { ...state, loading: false, error: action.error }
    case actions.EDIT_BLOG_POST_SUCCESS:
      return { ...state, loading: false, error: false, data: action.data }
    case actions.EDIT_BLOG_POST_ERROR:
      return { ...state, loading: false, error: action.error }
    case actions.GET_SINGLE_BLOG_POST_SUCCESS:
      return { ...state, loading: false, error: false, post: action.data?.data, data: action.data?.data }
    case actions.GET_SINGLE_BLOG_POST_ERROR:
      return { ...state, loading: false, error: action.error }
    case actions.DELETE_BLOG_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.data?.data
      }
    case actions.DELETE_BLOG_POST_ERROR:
      return { ...state, loading: false, error: action.error }
    case actions.UPDATE_STATUS_SUCCESS:
      return { ...state, loading: false, error: false, data: action.data?.data }
    case actions.UPDATE_STATUS_ERROR:
      return { ...state, loading: false, error: action.error }
    case actions.UPDATE_CATEGORY_SUCCESS:
      return { ...state, loading: false, error: false, data: action.data?.data }
    case actions.UPDATE_CATEGORY_ERROR:
      return { ...state, loading: false, error: action.error }
    case actions.GET_CATEGORIES_BY_STATUS_SUCCESS:
      return { ...state, loading: false, error: false, data: action.data?.data, pendingCategories: action.data?.data?.data, }
    case actions.GET_CATEGORIES_BY_STATUS_ERROR:
      return { ...state, loading: false, error: action.error }
    case actions.UPDATE_CATEGORY_STATUS_SUCCESS:
      return { ...state, loading: false, error: false, data: action.data?.data }
    case actions.UPDATE_CATEGORY_STATUS_ERROR:
      return { ...state, loading: false, error: action.error }
    case actions.DELETE_CATEGORY_SUCCESS:
      return { ...state, loading: false, error: false, data: action.data?.data }
    case actions.DELETE_CATEGORY_ERROR:
      return { ...state, loading: false, error: action.error }
    default:
      return state;
  }
}