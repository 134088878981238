import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import dayjs from "dayjs";
import {
  fetchSegmentsAnalytics,
  exportSegmentsAnalytics,
} from "@/store/analytics/segmentsAnalyticsAction";
import ExportDropdown from "@/components/ExportDropdown";
import { Bar } from "react-chartjs-2";
import { getBarChartOptions, getTransactionChartOptions } from "@/utils/index";
import AnalyticsDateInput from "@/components/AnalyticsDateInput";
import ReloadButton from "@/components/ReloadButton";
import "./style.scss";

const twelveMonthsAgo = new Date().setMonth(new Date().getMonth() - 12);
class SegmentsAnalytics extends Component {
  state = {
    usersPerSegmentDate: new Date(),
    averageInvestmentPerSegmentDates: [twelveMonthsAgo, new Date()],
    averageNumberOfInvestmentPerSegmentDates: [twelveMonthsAgo, new Date()],
    activeUserInSegmentDate: new Date(),
    viewToExport: "default",
    usersPerSegment: null,
    activeUserInSegment: null,
    averageInvestmentPerSegment: null,
    averageNumberOfInvestmentPerSegment: null,
  };

  componentDidMount() {
    this.fetchUsersPerSegment();
    this.fetchAverageInvestmentPerSegment();
    this.fetchAverageNumberOfInvestmentPerSegment();
    this.fetchActiveUserInSegment();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.usersPerSegmentDate !== prevState.usersPerSegmentDate) {
      this.fetchUsersPerSegment();
    }
    if (
      this.state.averageInvestmentPerSegmentDates !==
      prevState.averageInvestmentPerSegmentDates
    ) {
      this.fetchAverageInvestmentPerSegment();
    }
    if (
      this.state.averageNumberOfInvestmentPerSegmentDates !==
      prevState.averageNumberOfInvestmentPerSegmentDates
    ) {
      this.fetchAverageNumberOfInvestmentPerSegment();
    }
    if (
      this.state.activeUserInSegmentDate !== prevState.activeUserInSegmentDate
    ) {
      this.fetchActiveUserInSegment();
    }
  }

  formatDate = (date) => {
    return dayjs(date).format("YYYY-MM-DD");
  };

  fetchUsersPerSegment = () => {
    const { usersPerSegmentDate } = this.state;
    const date = usersPerSegmentDate && this.formatDate(usersPerSegmentDate);
    const view = date ? "count-users-per-segment" : "default-no-date";
    this.props.fetchSegmentsAnalytics(view, date).then((res) => {
      this.setState({ usersPerSegment: res.data.countUserPerSegment });
    });
  };

  fetchActiveUserInSegment = () => {
    const { activeUserInSegmentDate } = this.state;
    const date =
      activeUserInSegmentDate && this.formatDate(activeUserInSegmentDate);
    const view = date ? "active-users-per-segment" : "default-no-date";
    this.props.fetchSegmentsAnalytics(view, date).then((res) => {
      this.setState({ activeUserInSegment: res.data.activeUsersPerSegment });
    });
  };

  fetchAverageInvestmentPerSegment = () => {
    const { averageInvestmentPerSegmentDates } = this.state;
    if (!averageInvestmentPerSegmentDates[1]) return;
    this.props
      .fetchSegmentsAnalytics(
        "average-investment-per-segment",
        this.formatDate(averageInvestmentPerSegmentDates[0]),
        this.formatDate(averageInvestmentPerSegmentDates[1])
      )
      .then((res) => {
        this.setState({
          averageInvestmentPerSegment: res.data.averageInvestmentPerSegment,
        });
      });
  };

  fetchAverageNumberOfInvestmentPerSegment = () => {
    const { averageNumberOfInvestmentPerSegmentDates } = this.state;
    if (!averageNumberOfInvestmentPerSegmentDates[1]) return;
    this.props
      .fetchSegmentsAnalytics(
        "average-no-investment-per-segment",
        this.formatDate(averageNumberOfInvestmentPerSegmentDates[0]),
        this.formatDate(averageNumberOfInvestmentPerSegmentDates[1])
      )
      .then((res) => {
        this.setState({
          averageNumberOfInvestmentPerSegment:
            res.data.averageCountOfInvestmentPerSegment,
        });
      });
  };

  exportOptions = [
    { name: "All Pages", value: "default" },
    { name: "Users per segment", value: "count-users-per-segment" },
    {
      name: "Average investment per Segment",
      value: "average-investment-per-segment",
    },
    {
      name: "Average no of investments per segment",
      value: "average-no-investment-per-segment",
    },
    { name: "Active users in the segment", value: "active-users-per-segment" },
  ];

  chartColors = [
    "#9DC6FB",
    "#C8EA73",
    "#F4A28C",
    "#A0A2EC",
    "#9DC6FB",
    "#FFCEBF",
  ];

  generateColors = (labelsArray) => {
    return labelsArray?.map(
      (_) =>
        `#${((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0")}`
    );
  };

  usersPerSegmentData = () => ({
    labels: [...this.state.usersPerSegment.labels],
    datasets: [
      {
        data: [...this.state.usersPerSegment.data],
        backgroundColor: [
          ...this.chartColors,
          ...this.generateColors(this.state.usersPerSegment.labels),
        ],
      },
    ],
  });

  activeUserInSegmentData = () => ({
    labels: [...this.state.activeUserInSegment.labels],
    datasets: [
      {
        data: [...this.state.activeUserInSegment.data],
        backgroundColor: [
          ...this.chartColors,
          ...this.generateColors(this.state.activeUserInSegment.labels),
        ],
      },
    ],
  });

  averageInvestmentPerSegmentData = () => ({
    labels: [...this.state.averageInvestmentPerSegment.labels],
    datasets: [
      {
        data: [...this.state.averageInvestmentPerSegment.data],
        backgroundColor: [
          ...this.chartColors,
          ...this.generateColors(this.state.averageInvestmentPerSegment.labels),
        ],
      },
    ],
  });

  averageNumberOfInvestmentPerSegmentData = () => ({
    labels: [...this.state.averageNumberOfInvestmentPerSegment.labels],
    datasets: [
      {
        data: [...this.state.averageNumberOfInvestmentPerSegment.data],
        backgroundColor: [
          ...this.chartColors,
          ...this.generateColors(
            this.state.averageNumberOfInvestmentPerSegment.labels
          ),
        ],
      },
    ],
  });

  render() {
    const {
      usersPerSegmentDate,
      averageInvestmentPerSegmentDates,
      averageNumberOfInvestmentPerSegmentDates,
      activeUserInSegmentDate,
      viewToExport,
      usersPerSegment,
      activeUserInSegment,
      averageInvestmentPerSegment,
      averageNumberOfInvestmentPerSegment,
    } = this.state;

    return (
      <div className="setup-inner">
        <div className="setup-inner__top">
          <div className="segment-export">
            <div className="termed-reload">
              <ReloadButton />
            </div>
            <ExportDropdown
              module="user-analytics"
              status="demographics"
              excludeCSV
              includeJpeg
              downloadHandler={(...args) =>
                this.props.exportSegmentsAnalytics(
                  this.formatDate(averageInvestmentPerSegmentDates[0]),
                  this.formatDate(averageInvestmentPerSegmentDates[1]),
                  this.formatDate(averageNumberOfInvestmentPerSegmentDates[0]),
                  this.formatDate(averageNumberOfInvestmentPerSegmentDates[1]),
                  usersPerSegmentDate,
                  activeUserInSegmentDate,
                  viewToExport,
                  ...args
                )
              }
              analyticsOptions={this.exportOptions}
              onAnalyticsViewSelect={(view) =>
                this.setState({ viewToExport: view })
              }
            />
          </div>
        </div>
        <div id="page-container">
          <div
            id="graph-print"
            className="setup-inner__main p-5 analytics-graph"
          >
            <div className="text-center">
              <h5 className="text-medium">Users per segment</h5>
              <AnalyticsDateInput
                value={usersPerSegmentDate}
                handleChange={(date) =>
                  this.setState({ usersPerSegmentDate: date })
                }
              />
            </div>
            {!usersPerSegment ? (
              <div className="text-center text-primary">
                <div className="spinner-border" role="status"></div>
              </div>
            ) : (
              <Bar
                data={this.usersPerSegmentData}
                options={getBarChartOptions("No. of Users", "Segment")}
              />
            )}
          </div>
          <div
            id="graph-print"
            className="setup-inner__main p-5 mt-5 analytics-graph"
          >
            <div className="text-center">
              <h5 className="text-medium">Average investment per Segment</h5>
              <AnalyticsDateInput
                value={averageInvestmentPerSegmentDates[0]}
                isRange
                startDate={averageInvestmentPerSegmentDates[0]}
                endDate={averageInvestmentPerSegmentDates[1]}
                handleChange={(date) =>
                  this.setState({ averageInvestmentPerSegmentDates: date })
                }
              />
            </div>
            {!averageInvestmentPerSegment ? (
              <div className="text-center text-primary">
                <div className="spinner-border" role="status"></div>
              </div>
            ) : (
              <Bar
                data={this.averageInvestmentPerSegmentData}
                options={getTransactionChartOptions(
                  "Value of Investments",
                  "Segments"
                )}
              />
            )}
          </div>
          <div
            id="graph-print"
            className="setup-inner__main p-5 mt-5 analytics-graph"
          >
            <div className="text-center">
              <h5 className="text-medium">
                Average no of investments per segment
              </h5>
              <AnalyticsDateInput
                value={averageNumberOfInvestmentPerSegmentDates[0]}
                isRange
                startDate={averageNumberOfInvestmentPerSegmentDates[0]}
                endDate={averageNumberOfInvestmentPerSegmentDates[1]}
                handleChange={(date) =>
                  this.setState({
                    averageNumberOfInvestmentPerSegmentDates: date,
                  })
                }
              />
            </div>
            {!averageNumberOfInvestmentPerSegment ? (
              <div className="text-center text-primary">
                <div className="spinner-border" role="status"></div>
              </div>
            ) : (
              <Bar
                data={this.averageNumberOfInvestmentPerSegmentData}
                options={getBarChartOptions("No. of Investments", "Segments")}
              />
            )}
          </div>
          <div id="graph-print" className="segment-bottom-graph">
            <div className="setup-inner__main p-5 analytics-graph">
              <div className="text-center ">
                <h5 className="text-medium">Active users in the segment</h5>
                <AnalyticsDateInput
                  value={activeUserInSegmentDate}
                  handleChange={(date) =>
                    this.setState({ activeUserInSegmentDate: date })
                  }
                />
              </div>
              {!activeUserInSegment ? (
                <div className="text-center text-primary">
                  <div className="spinner-border" role="status"></div>
                </div>
              ) : (
                <Bar
                  data={this.activeUserInSegmentData}
                  options={getBarChartOptions("No. of Users", "Segments")}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSegmentsAnalytics: (view, startDate, endDate) =>
      dispatch(fetchSegmentsAnalytics(view, startDate, endDate)),
    exportSegmentsAnalytics: (
      avgInvestmentPerSegmentStartDate,
      avgInvestmentPerSegmentEndDate,
      avgNoInvestmentPerSegmentStartDate,
      avgNoInvestmentPerSegmentEndDate,
      usersPerSegmentDate,
      activeUsersPerSegmentDate,
      viewToExport,
      module,
      format
    ) =>
      dispatch(
        exportSegmentsAnalytics(
          module,
          format,
          viewToExport,
          avgInvestmentPerSegmentStartDate,
          avgInvestmentPerSegmentEndDate,
          avgNoInvestmentPerSegmentStartDate,
          avgNoInvestmentPerSegmentEndDate,
          usersPerSegmentDate,
          activeUsersPerSegmentDate
        )
      ),
  };
};

export default withRouter(connect(null, mapDispatchToProps)(SegmentsAnalytics));
