import * as actions from "./actionTypes";
import CONFIG from "@/config";
import { apiRequestHandler, validate, apiDownloadHandler } from "@/utils";
import { logout} from "@/store/user/actions";
import { showAlert } from '@/store/ui/actions';



const getPushNotificationRequest = () => {
  return {
    type: actions.GET_PUSH_NOTIFICATION_REQUEST,
  };
};

const getPushNotificationError = () => {
  return {
    type: actions.GET_PUSH_NOTIFICATION_ERROR,
  };
};

const getPushNotificationSuccess = () => {
  return {
    type: actions.GET_PUSH_NOTIFICATION_SUCCESS,
  };
};

const pushNotificationPageRequest = () => {
  return {
    type: actions.PUSH_NOTIFICATION_PAGE_REQUEST,
  };
};

const pushNotificationPageError = (message) => {
  return {
    type: actions.PUSH_NOTIFICATION_PAGE_ERROR,
    message,
  };
};

const pushNotificationPageSuccess = () => {
  return {
    type: actions.PUSH_NOTIFICATION_PAGE_SUCCESS,
  };
};

export const fetchPushNotifications = (page, limit, currentPage) => {
  const config = {
    urlPath: `admin/pushnotifications/${
      currentPage !== "active" ? `status/${currentPage}` : ""
    }?page=${page}&limit=${limit}`,

    requestAction: getPushNotificationRequest,
    successAction: getPushNotificationSuccess,
    errorAction: getPushNotificationError,
  };
  return apiRequestHandler(config);
};

export const searchPushNotifications = (page, limit, status, searchTerm) => {
  const config = {
    urlPath: `admin/pushnotifications/filter/${status}?search=${searchTerm}&page=${page}&limit=${limit}`,
    requestAction: getPushNotificationRequest,
    successAction: getPushNotificationSuccess,
    errorAction: getPushNotificationError,
  };
  return apiRequestHandler(config);
};

export const changeStatus = (ids, status, reason) => {
  let payload = { id: ids, status };
  if (reason) payload.message = reason;
  const config = {
    urlPath: "admin/pushnotifications/approval",
    method: "post",
    payload,
    requestAction: pushNotificationPageRequest,
    successAction: pushNotificationPageSuccess,
    errorAction: pushNotificationPageError,
  };
  return apiRequestHandler(config);
};







export const createOrUpdate = (payload, id) => {
  const path = `admin/pushnotifications/${id ? id : ''}`;
  return (dispatch, getState) => {
    const required = [
      'title', 'description', 'deliveryDate', 'deliveryType'
    ];
    return new Promise((resolve) => {
      //validate data
      dispatch(validate(payload, required, showAlert)).then(() => {
        payload.services = JSON.stringify(payload.services);
        dispatch( pushNotificationPageRequest ());
        const formData = new FormData();
        for (const field in payload) {
          formData.append(`${field}`, payload[field]);
        }
        fetch(`${CONFIG.BASE_URL}/${path}`, {
          method: `${id ? "PUT" : "POST"}`,
          headers: {
            Authorization: getState().user.token,
          },
          body: formData,
        })
          .then((response) => {
            if ([200, 201].includes(response.status)) {
              response.json().then(() => {
                dispatch(pushNotificationPageSuccess());
                resolve();
              });
            }
            if (response.status === 401) {
              dispatch(
                showAlert({
                  type: "error",
                  message: "Your session has expired",
                })
              );
              return setTimeout(() => dispatch(logout()), 2000);
            }
            if (response.status === 403) {
              dispatch(pushNotificationPageError());
              dispatch(
                showAlert({
                  type: "error",
                  message: "Oops! you are unauthorised",
                })
              );
            }
            if (response.status === 400) {
              response.json().then((response) => {
                dispatch(
                  pushNotificationPageError({ type: "danger", message: response.error })
                );
                dispatch(
                  showAlert({ type: "error", message: response.message })
                );
              });
            }
          })
          .catch(() => {
            dispatch(pushNotificationPageError());
            dispatch(
              showAlert({
                type: "error",
                message: "Oops! something wrong please try again.",
              })
            );
          });
      });
    });
  };
};

export const deleteNotification = (ids) => {
  const config = {
    urlPath: "admin/pushnotifications",
    method: "delete",
    payload: { id: ids },
    requestAction: pushNotificationPageRequest,
    successAction: pushNotificationPageSuccess,
    errorAction: pushNotificationPageError,
  };
  return apiRequestHandler(config);
};

export const exportNotifications = (_, format, status, page, limit) => {
  const fullUrlPath = `${CONFIG.BASE_URL}/admin/pushnotifications/status/${status}/download/${format}?page=${page}&limit=${limit}`;
  return apiDownloadHandler(fullUrlPath);
};
