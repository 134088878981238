import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Textbox from "@/components/Textbox";
import { resetPassword } from "@/store/auth/actions";
import actionTypes from "@/store/auth/actionTypes";
import { getActionLoadingState } from "@/store/selectors";
import { validateFields, isPasswordEqual, serializeErrors } from "@/utils";
import Alert from "@/components/Alert";
import AdminAuthNav from "@/components/AdminAuthNav";
import "./style.scss";

class SetupNewPassword extends React.Component {
  state = {
    password: "",
    confirmPassword: "",
    passwordType: "password",
    conPasswordType: "password",
    errors: null,
  };

  handlePasswordType = () => {
    const { passwordType } = this.state;
    if (passwordType === "password") {
      return this.setState({ passwordType: "text" });
    }
    return this.setState({ passwordType: "password" });
  };

  handleConPasswordType = () => {
    const { conPasswordType } = this.state;
    if (conPasswordType === "password") {
      return this.setState({ conPasswordType: "text" });
    }
    return this.setState({ conPasswordType: "password" });
  };

  handleChange = (event) => {
    const { errors } = this.state;

    const { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      if (name === "confirmPassword") {
        const error = isPasswordEqual(
          this.state.confirmPassword,
          this.state.password
        );
        if (!error) {
          return this.setState({
            errors: { ...errors, confirmPassword: null },
          });
        }
        return this.setState({ errors: { ...errors, ...error } });
      }
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const { resetPassword, history } = this.props;
    const {
      params: { token },
    } = this.props.match;
    const { password, confirmPassword } = this.state;

    // isPasswordEqual() returns null if the passwords are equall
    if (isPasswordEqual(password, confirmPassword) !== null) return;
    this.setState({ errors: null });

    const data = this.state;
    const required = ["password", "confirmPassword"];
    const errors = validateFields(data, required);

    if (Object.keys(errors).length > 0) {
      return this.setState({ errors });
    }

    const payload = { password, confirmPassword, resetToken: token };
    resetPassword(payload, history);
  };

  render() {
    const { password, confirmPassword, errors, passwordType, conPasswordType } =
      this.state;
    const { loading, data, error } = this.props;
    const errorObject = serializeErrors(error);

    return (
      <div className="admin-setup-password-page text-center">
        <AdminAuthNav />
        <h3 className="text-white">Set a new password</h3>
        <div className="box ml-auto mr-auto pt-3">
          <div className="container">
            <form autoComplete="off" onSubmit={this.handleSubmit}>
              <Textbox
                onChange={this.handleChange}
                name="password"
                value={password}
                label="Password"
                placeholder="Password"
                boxClasses="mt-3"
                type={passwordType}
                iconUrl={require("@/assets/icons/view-password.svg").default}
                onIconClick={this.handlePasswordType}
                error={
                  errors
                    ? errors.password
                    : errorObject && errorObject["password"]
                }
              />
              <Textbox
                onChange={this.handleChange}
                name="confirmPassword"
                value={confirmPassword}
                label="Confirm Password"
                placeholder="Confirm Password"
                boxClasses="mt-3"
                type={conPasswordType}
                iconUrl={require("@/assets/icons/view-password.svg").default}
                onIconClick={this.handleConPasswordType}
                error={errors && errors.confirm}
              />
              <button className="w-100 btn btn-sm btn-primary mt-4 justify-content-center align-items-center d-flex">
                Create new password
                {loading && (
                  <div className="spinner-border spinner-border-white spinner-border-sm ml-2"></div>
                )}
              </button>
            </form>
            {data && (
              <Alert alert={{ type: "success", message: data.message }} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    auth: { error, data },
  } = state;
  return {
    loading: getActionLoadingState(state, actionTypes.RESET_PASSWORD_REQUEST),
    error,
    data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (payload, history) =>
      dispatch(resetPassword(payload, history)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SetupNewPassword)
);
