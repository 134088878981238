import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import dayjs from "dayjs";
import {
  fetchCustomers,
  activateUser,
  searchCustomers,
  exportCustomerInfo,
} from "@/store/Customers";
import actionTypes from "@/store/Customers/actionTypes";
import { getActionLoadingState } from "@/store/selectors";
import Emptystate from "@/components/Emptystate";
import Pagination from "@/components/Pagination";
import Dropdown from "@/components/DropdownMenu";
import ConfirmationBox from "@/components/ConfirmationBox";
import SearchBox from "@/components/SearchBox";
import ExportDropdown from "@/components/ExportDropdown";
import { hasPermission } from "@/utils";

class AllUsers extends Component {
  state = {
    users: [],
    searchTerm: "",
  };

  componentDidMount() {
    const { status } = this.props.match.params;
    this.getCustomers(1, 10, status);
  }

  componentDidUpdate(prevProps, prevState) {
    const { status } = this.props.match.params;
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ searchTerm: "", shouldSearch: false }, () =>
        this.getCustomers(1, 10, status)
      );
    }
    if (
      this.state.searchTerm !== prevState.searchTerm &&
      this.state.shouldSearch
    ) {
      this.searchCustomers(1, 10, status, this.state.searchTerm);
    }
  }

  getCustomers = (page, limit, status) => {
    if (this.state.searchTerm)
      return this.searchCustomers(page, limit, status, this.state.searchTerm);
    this.props.fetchCustomers(page, limit, status).then((data) => {
      this.setPageData(data.data.users);
    });
  };

  searchCustomers = (page, limit, status, searchTerm) => {
    if (!searchTerm) return this.getCustomers(1, 10, status);
    const category = status === "not-approved" ? "pending" : status;
    this.props
      .searchCustomers(page, limit, category, searchTerm)
      .then((data) => {
        this.setPageData(data.data.users, searchTerm);
      });
  };

  setPageData = (
    { lastPage, perPage, page, total, data: users },
    searchTerm,
    shouldSearch
  ) => {
    this.setState({
      users,
      lastPage,
      perPage,
      page,
      total,
      searchTerm,
      shouldSearch,
    });
  };

  onRowClick = (e, userId) => {
    if (["BUTTON", "IMG", "LI"].includes(e.target.nodeName)) return;
    this.props.history.push(`${userId}`);
  };

  renderCustomers = () => {
    const { status } = this.props.match.params;
    const isPending = status === "not-approved";
    const isDeactivated = status === "deactivated";
    return (
      <table className="custum-table">
        <tbody>
          <tr>
            {!isPending && <th>Name</th>}
            <th>Phone Number</th>
            <th>Email Address</th>
            <th>Email Verification Date</th>
            <th>Date of Registration</th>
            {!isPending && <th>Last Login</th>}
            {isDeactivated && <th></th>}
          </tr>
          {this.state.users.map((user) => (
            <tr
              key={user.id}
              onClick={(e) => this.onRowClick(e, user.id)}
              className="cursor-pointer"
            >
              {!isPending && (
                <td className="text-capitalize">
                  {user.firstName
                    ? `${user.firstName} ${user.lastName}`
                    : "N/A"}
                </td>
              )}
              <td>{`${user.countryCode ?? ""}${user.phone}`}</td>
              <td>{user.email}</td>
              <td>
                {user.confirmationDate
                  ? dayjs(user.confirmationDate).format("D MMM YYYY")
                  : ""}
              </td>
              <td>{dayjs(user.created_at).format("D MMM YYYY")}</td>
              {!isPending && (
                <td>
                  {user.last_login_at
                    ? dayjs(user.last_login_at).format("D MMM YYYY")
                    : "N/A"}
                </td>
              )}
              {isDeactivated && (
                <td className="custum-table__ellipsis">
                  <Dropdown menu={this.dropdownmenus(user.id)} arrow={true}>
                    <button className="wrapper-button ellipsis">
                      <img
                        src={
                          require("@/assets/icons/flat-ellipsis.svg").default
                        }
                        alt="dropdown"
                      />
                    </button>
                  </Dropdown>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  confirmationData = () => ({
    activateUser: {
      title: "Activate user",
      question: "Are you sure you want to activate this user?",
      action: () => this.activateUser(),
    },
  });

  setConfirmationContext = (context, userId) => {
    this.setState({
      confirmationContext: context,
      confirmationSuccess: false,
      userId,
    });
  };

  dropdownmenus = (userId) => {
    const canActivate = hasPermission(this.props.permissions, "activate_user");
    return [
      canActivate && {
        name: "Activate user",
        handler: () => this.setConfirmationContext("activateUser", userId),
      },
    ].filter(Boolean);
  };

  activateUser = () => {
    const { userId, users, total } = this.state;
    this.props.activateUser(userId).then(() => {
      const usersList = [...users];
      const userIndex = usersList.findIndex((user) => user.id === userId);
      usersList.splice(userIndex, 1);
      this.setState({
        users: usersList,
        confirmationSuccess: true,
        total: total - 1,
      });
    });
  };

  renderPreview = () => {
    const { previewItem } = this.state;
    return Object.keys(previewItem).map((field) => (
      <div className="panel-info__row" key={field}>
        <span className="panel-info__field">{field}</span>
        <span className="panel-info__value">{previewItem[field]}</span>
      </div>
    ));
  };

  render() {
    const {
      match: {
        params: { status },
      },
      fetching,
      pageRequest,
    } = this.props;
    const {
      users,
      lastPage,
      perPage,
      page,
      total,
      confirmationContext,
      confirmationSuccess,
      searchTerm,
    } = this.state;
    const prompt = this.confirmationData()[confirmationContext];

    return (
      <div className="setup-inner">
        <ConfirmationBox
          open={confirmationContext}
          closeHandler={() => this.setState({ confirmationContext: "" })}
          success={confirmationSuccess}
          title={prompt?.title}
          question={prompt?.question}
          action={prompt?.action}
          loading={pageRequest}
        />
        <div className="setup-inner__top">
          <ul className="setup-inner__nav">
            <li className="setup-nav__item">
              <NavLink
                to={{ pathname: "registered", state: { pageTitle: "Users" } }}
                isActive={() => status === "registered"}
                activeClassName="setup-nav__item--active"
              >
                Registered
              </NavLink>
            </li>
            <li className="setup-nav__item">
              <NavLink
                to={{ pathname: "not-approved", state: { pageTitle: "Users" } }}
                isActive={() => status === "not-approved"}
                activeClassName="setup-nav__item--active"
              >
                Unverified
              </NavLink>
            </li>
            <li className="setup-nav__item">
              <NavLink
                to={{ pathname: "activated", state: { pageTitle: "Users" } }}
                isActive={() => status === "activated"}
                activeClassName="setup-nav__item--active"
              >
                Activated
              </NavLink>
            </li>
            <li className="setup-nav__item">
              <NavLink
                to={{ pathname: "deactivated", state: { pageTitle: "Users" } }}
                isActive={() => status === "deactivated"}
                activeClassName="setup-nav__item--active"
              >
                Deactivated
              </NavLink>
            </li>
          </ul>
          <SearchBox
            placeholder="Search"
            handleSearch={(searchTerm) =>
              this.setState({ searchTerm, shouldSearch: true })
            }
            isActiveSearch={Boolean(searchTerm)}
            key={status}
          />
          {users.length > 0 && (
            <ExportDropdown
              module="users"
              status={status}
              page={page}
              limit={perPage}
              downloadHandler={this.props.exportCustomerInfo}
              hasMoreThanOnePage={lastPage > 1}
            />
          )}
        </div>
        {fetching ? (
          <div className="text-center text-primary">
            <div className="spinner-border" role="status"></div>
          </div>
        ) : (
          <div className="position-relative">
            {!users.length ? (
              <Emptystate
                title={`${searchTerm ? "No Result Found" : "No Customers"}`}
                icon={require("@/assets/icons/info.svg").default}
              />
            ) : (
              <>
                <Pagination
                  totalPages={lastPage}
                  page={page}
                  limit={perPage}
                  changePageHandler={(page, limit) =>
                    this.getCustomers(page, limit, status, searchTerm)
                  }
                />
                <div className="table-overflow">
                  <div className="setup-inner__main setup-inner__expand">
                    {this.renderCustomers()}
                  </div>
                  <div className="data-count">
                    Showing
                    <span className="font-weight-bold mx-2">{`${users.length} of ${total}`}</span>
                    Users
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fetching: getActionLoadingState(state, actionTypes.GET_CUSTOMERS_REQUEST),
    pageRequest: getActionLoadingState(
      state,
      actionTypes.CUSTOMERS_PAGE_REQUEST
    ),
    permissions: state.user.permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCustomers: (page, limit, status) =>
      dispatch(fetchCustomers(page, limit, status)),
    searchCustomers: (page, limit, status, searchTerm) =>
      dispatch(searchCustomers(page, limit, status, searchTerm)),
    activateUser: (userId) => dispatch(activateUser(userId)),
    exportCustomerInfo: (module, format, status, page, limit) =>
      dispatch(exportCustomerInfo(module, format, status, page, limit)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AllUsers)
);
