import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import ReactGA from "react-ga4";
import reportWebVitals from './reportWebVitals';

ReactGA.initialize("G-RXSQ0HH7M8");

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

const SendAnalytics = ()=> {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}

reportWebVitals(SendAnalytics);
