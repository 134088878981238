import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getActionLoadingState } from "@/store/selectors";
import {
  getAllFlashDealsInvestments,
  updateInvestmentStatus,
  updateInvestment,
  removeInvestment,
  updateFDInvestmentStatus,
  updateFDInvestment,
} from "@/store/investments/actions";
import actionTypes from "@/store/investments/actionTypes";
import Emptystate from "@/components/Emptystate";
import RightPanel from "@/components/RightPanel";
import Dropdown from "@/components/DropdownMenu";
import Alert from "@/components/Alert";
import ConfirmationBox from "@/components/ConfirmationBox";
import Pagination from "@/components/Pagination";
import {
  validateFields,
  serializeErrors,
  hasPermission,
  currency,
} from "@/utils";
import "./style.scss";
import dayjs from "dayjs";
import TextEditor from "@/components/TextEditor";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import generateRandomKey from "draft-js/lib/generateRandomKey";
import { convertFromRaw } from "draft-js";

class FlashDealInvestment extends React.Component {
  imgRef = React.createRef();
  backgroundRef = React.createRef();
  offerDocRef = React.createRef();

  state = {
    selectedItem: null,
    selectedInvestment: null,
    showConfirmModal: false,
    itemId: "",
    open: false,
    openView: false,
    fileName: "",
    backgroundFileName: "",
    icon: null,
    background: "",
    description: "",
    summary: "",
    errors: null,
    offerDocument: null,
    offerDocumentFileName: "",
    offerName: "",
    tenor: "",
    rate: "",
    offerAmount: "",
    minimumInvestment: "",
    penalty: "",
    openingData: "",
    closingDate: "",
    category: "",
    tags: [],
    liquidationPenalty: 0,
    imgUrl: null,
    backgroundUrl: null,
    offerDocumentUrl: null,
  };

  componentDidMount() {
    this.props.getAllFlashDealsInvestments();
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({
        open: false,
        fileName: "",
        type: "",
        name: "",
        icon: null,
        background: "",
        description: "",
        showConfirmModal: false,
      });
    }
    if (this.props.searchTerm !== prevProps.searchTerm) {
      this.props.getAllFlashDealsInvestments(10, 1, this.props.searchTerm);
    }
  }

  handleSelectItem = (itemId) => {
    const { investments } = this.props;
    const foundItem = investments?.data.find((item) => item.id === itemId);

    this.setState({ selectedInvestment: foundItem }, () =>
      this.setState({ openView: true })
    );
  };

  showConfirm = (id) => {
    this.setState({ showConfirmModal: true, itemId: id });
  };

  toggleModal = () => {
    this.setState({ showConfirmModal: !this.state.showConfirmModal });
  };

  handleDelete = () => {
    const { itemId } = this.state;
    this.props.removeInvestment(itemId);
  };

  handleStatusChange = (targetId, newStatus) => {
    this.props.updateInvestmentStatus(targetId, { status: newStatus });
  };

  stripHtml = (html) => {
    const decodedHtml = html.replace(/(&lt;|<)[^>]*(&gt;|>)/g, "");
    return decodedHtml.replace(/<[^>]*>/g, "");
  };

  handleEditItem = (targetId) => {
    const { investments } = this.props;
    const foundItem = investments?.data.find((item) => item.id === targetId);

    console.log(foundItem.description);

    this.setState(
      {
        selectedItem: foundItem,
        description: foundItem.description,
        imgUrl: foundItem.icon,
        background: foundItem.background,
        offerDocumentUrl: foundItem.offerDocument,
        offerName: foundItem.offerName,
        tenor: foundItem.tenor,
        rate: foundItem.rate,
        offerAmount: foundItem.offerAmount,
        minimumInvestment: foundItem.minimumInvestment,
        penalty: foundItem?.penalty,
        category: foundItem.category,
        openingData: foundItem.openingData,
        closingDate: foundItem.closingDate,
        tags: foundItem.tags,
        summary: foundItem.summary,
        liquidationPenalty: foundItem.liquidationPenalty ? 1 : 0,
      },
      () => this.setState({ open: true })
    );
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleCategoryChange = (event) => {
    const { value } = event.target;
    console.log({ value });

    this.setState({ category: String(value).toUpperCase() });
  };

  /**
   * Triggers file input click
   * @param {Event} event Click event.
   * @param {'background' | 'icon'| 'offerDocument'} uploadType Upload type.
   */
  handleFileClick = (event, uploadType) => {
    event.preventDefault();
    uploadType === "background"
      ? this.backgroundRef.current.click()
      : uploadType === "offerDocument"
      ? this.offerDocRef.current.click()
      : this.imgRef.current.click();
  };

  handleImageSelect = (event) => {
    const { target } = event;
    if (target.files[0]) {
      this.setState({
        fileName: target.files[0].name,
        icon: target.files[0],
        imgUrl: URL.createObjectURL(target.files[0]),
      });
    }
  };

  handleBackgroundImageSelect = (event) => {
    const { target } = event;
    if (target.files[0]) {
      this.setState({
        backgroundFileName: target.files[0].name,
        background: target.files[0],
        backgroundUrl: URL.createObjectURL(target.files[0]),
      });
    }
  };

  handleOfferDocImageSelect = (event) => {
    const { target } = event;
    if (target.files[0]) {
      this.setState({
        offerDocumentFileName: target.files[0].name,
        offerDocument: target.files[0],
        offerDocumentUrl: URL.createObjectURL(target.files[0]),
      });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { updateInvestment } = this.props;
    const {
      icon,
      background,
      selectedItem,
      description,
      offerDocument,
      offerName,
      tenor,
      rate,
      offerAmount,
      minimumInvestment,
      penalty,
      category,
      openingData,
      closingDate,
      tags,
      summary,
      liquidationPenalty,
    } = this.state;

    this.setState({ errors: null });

    const data = this.state;
    const required = ["offerName"];
    const errors = validateFields(data, required);

    if (Object.keys(errors).length > 0) {
      return this.setState({ errors });
    }

    const formData = new FormData();
    formData.append("icon", icon);

    // Ensure `background` is properly appended
    if (background instanceof File) {
      formData.append("background", background);
    } else if (typeof background === "string") {
      formData.append("background", background); // Use a separate field if it's a URL
    }

    formData.append("offerDocument", offerDocument);
    formData.append("description", description);
    formData.append("offerName", offerName);
    formData.append("tenor", tenor);
    formData.append("rate", rate);
    formData.append("offerAmount", offerAmount);
    formData.append("minimumInvestment", minimumInvestment);
    formData.append("penalty", penalty);
    formData.append("category", category);
    formData.append("openingData", dayjs(openingData).format("YYYY-MM-DD"));
    formData.append("closingDate", dayjs(closingDate).format("YYYY-MM-DD"));
    formData.append("tags", tags);
    formData.append("summary", summary);
    formData.append("liquidationPenalty", liquidationPenalty ? 1 : 0);
    formData.append("requestType", "UPDATE");
    formData.append("id", selectedItem.id);

    updateInvestment(formData);
  };

  closeEditPanel = (event) => {
    event.preventDefault();
    this.setState({
      open: false,
      selectedInvestment: null,
    });
  };

  dropdownmenus = (id, status) => {
    const newStatus = status === "active" ? "Mark as sold out" : "Enable";
    const canUpdate = hasPermission(this.props.permissions, "update_service");
    const canApprove = hasPermission(this.props.permissions, "approve_service");
    const canDelete = hasPermission(this.props.permissions, "delete_service");
    return [
      canUpdate
        ? { name: "Edit", handler: () => this.handleEditItem(id) }
        : null,
      canApprove
        ? {
            name: newStatus,
            handler: () =>
              this.handleStatusChange(
                id,
                status === "active" ? "closed" : "active"
              ),
          }
        : null,
      canDelete
        ? { name: "Remove", handler: () => this.showConfirm(id) }
        : null,
    ].filter(Boolean);
  };

  render() {
    const {
      error,
      loading,
      data,
      updateLoading,
      removeLoading,
      investments,
      searchTerm,
    } = this.props;
    const {
      fileName,
      description,
      errors,
      imgUrl,
      backgroundFileName,
      offerName,
      tenor,
      rate,
      offerAmount,
      minimumInvestment,
      penalty,
      openingData,
      closingDate,
      category,
      tags,
      liquidationPenalty,
      selectedInvestment,
      showConfirmModal,
      backgroundUrl,
      offerDocumentUrl,
      offerDocumentFileName,
      summary,
    } = this.state;
    const errorObject = serializeErrors(error);

    return (
      <div className="termed-investments-page -setup-inner !mb-[20px]">
        <ConfirmationBox
          open={showConfirmModal}
          closeHandler={() => this.setState({ showConfirmModal: false })}
          title="Remove Investment"
          question="Are you sure you want to remove this investment?"
          action={this.handleDelete}
          loading={removeLoading}
        />

        <RightPanel
          open={this.state.openView}
          onClose={() => this.setState({ openView: false })}
        >
          <h4 className="panel-heading">
            {selectedInvestment && selectedInvestment.offerName}
          </h4>
          <div className="text-right border-bottom pb-5">
            <img
              src={selectedInvestment && selectedInvestment.icon}
              className="w-full"
              alt="user-img"
            />
          </div>
          <div className="d-flex justify-content-between mt-5">
            <p>Offer name</p>
            <p>
              <b>{selectedInvestment && selectedInvestment.offerName}</b>
            </p>
          </div>
          <div className="d-flex justify-content-between">
            <p>Offer opening date</p>
            <p>
              <b>
                {selectedInvestment &&
                  dayjs(selectedInvestment.openingData).format("D MMM YYYY")}
              </b>
            </p>
          </div>

          <div className="d-flex justify-content-between">
            <p>Offer opening date</p>
            <p>
              <b>
                {selectedInvestment &&
                  dayjs(selectedInvestment.closingDate).format("D MMM YYYY")}
              </b>
            </p>
          </div>
          <div className="d-flex justify-content-between">
            <p>Status</p>
            <p
              className={`status--${
                selectedInvestment && selectedInvestment.status
              }`}
            >
              <b>{selectedInvestment && selectedInvestment.status}</b>
            </p>
          </div>
          <div className="d-flex justify-content-between">
            <p>Tenor</p>
            <p>
              <b>{selectedInvestment && selectedInvestment.tenor}</b>
            </p>
          </div>
          <div className="d-flex justify-content-between">
            <p>Category</p>
            <p>
              <b>{selectedInvestment && selectedInvestment.category}</b>
            </p>
          </div>
          <div className="d-flex justify-content-between">
            <p>Interest Rate</p>
            <p>
              <b>{selectedInvestment && selectedInvestment.rate}</b>
            </p>
          </div>
          <div className="d-flex justify-content-between">
            <p>Pre Liquidation</p>
            <p>
              <b>
                {selectedInvestment &&
                selectedInvestment.liquidationPenalty === 1
                  ? "Yes"
                  : "No"}
              </b>
            </p>
          </div>

          <div className="d-flex justify-content-between">
            <p>Offer Amount</p>
            <p>
              <b>
                {selectedInvestment &&
                  `₦${currency(selectedInvestment.offerAmount)}`}
              </b>
            </p>
          </div>
          <div className="d-flex justify-content-between">
            <p>Tags</p>
            <p>
              <b>{selectedInvestment && selectedInvestment.tags}</b>
            </p>
          </div>
          <div className=" justify-content-between mt-3">
            <p>Summary</p>
            <p
              className="text-left !text-sm leading-10"
              dangerouslySetInnerHTML={{
                __html: selectedInvestment && selectedInvestment.summary,
              }}
            >
              {/* <b>{selectedInvestment && selectedInvestment.summary}</b> */}
            </p>
          </div>
          <div className="d-flex justify-content-between">
            <p>Description</p>
            <p
              className="text-left !text-sm leading-10"
              dangerouslySetInnerHTML={{
                __html: selectedInvestment && selectedInvestment.description,
              }}
            >
              {/* <b>{selectedInvestment && selectedInvestment.summary}</b> */}
            </p>
          </div>

          <div className="d-flex justify-content-between mt-4">
            <button
              className="btn btn-sm w-100 mr-2 btn-white color-deep-blue"
              onClick={() => this.setState({ openView: false })}
            >
              Close
            </button>
          </div>
        </RightPanel>
        <RightPanel
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
        >
          <h4 className="panel-heading">Edit {offerName} Investment</h4>
          <form onSubmit={this.handleSubmit}>
            <label className="panel-label mt-4" htmlFor="new-investment-name">
              Offer name
            </label>
            <input
              type="text"
              className="form-control panel-input mb-0"
              placeholder="Enter offer name"
              id="new-investment-name"
              name="offerName"
              value={offerName}
              onChange={this.handleChange}
            />
            <p className="text-error">
              {errors ? errors.name : errorObject && errorObject["offerName"]}
            </p>
            <label className="panel-label mt-4" htmlFor="new-investment-name">
              Offer Opening Date
            </label>
            <DatePicker
              className={`custum-form__field custum-form__input w-100 ${
                errors?.openingData ? "error-input" : ""
              }`}
              dayClassName={() => "react-datepicker-dates"}
              minDate={new Date()}
              selected={openingData ? new Date(openingData) : null}
              onChange={(openingData) =>
                this.setState({
                  openingData: openingData.toISOString().slice(0, 10),
                })
              }
              id="startDate"
              placeholderText="Select start date"
              autoComplete="off"
              dateFormat="dd/MM/yyyy"
            />
            <p className="text-error">
              {errors
                ? errors.openingData
                : errorObject && errorObject["openingData"]}
            </p>
            <label className="panel-label mt-4" htmlFor="new-investment-name">
              Offer Closing Date
            </label>
            <DatePicker
              className={`custum-form__field custum-form__input w-100 ${
                errors?.closingDate ? "error-input" : ""
              }`}
              dayClassName={() => "react-datepicker-dates"}
              minDate={new Date()}
              selected={closingDate ? new Date(closingDate) : null}
              onChange={(closingDate) =>
                this.setState({
                  closingDate: closingDate.toISOString().slice(0, 10),
                })
              }
              id="startDate"
              placeholderText="Select end date"
              autoComplete="off"
              dateFormat="dd/MM/yyyy"
            />
            <p className="text-error">
              {errors
                ? errors.closingDate
                : errorObject && errorObject["closingDate"]}
            </p>
            <label className="panel-label mt-4" htmlFor="new-investment-name">
              Category
            </label>

            <select
              className="form-control panel-input mb-0"
              id="new-investment-type"
              name="category"
              value={category}
              onChange={this.handleCategoryChange}
            >
              <option value="">Select category</option>
              <option value="Bond">Bonds</option>
              <option value="CP">Commercial papers</option>
              <option value="MF">Mutual funds</option>
            </select>

            {/*  */}

            <label className="panel-label mt-4" htmlFor="new-investment-name">
              Return Basis
            </label>

            <select
              className="form-control panel-input mb-0"
              id="new-investment-type"
              name="return_basis"
              onChange={this.handleCategoryChange}
            >
              <option value="">Select Return Basis</option>

              <option value="simple interest">simple interest</option>
              <option value="compound interest">compound interest</option>
              <option value="upfront interest">upfront interest</option>
            </select>

            {/* end */}

            {/* Payout Frequency: (to be dynamic based on the return basis -> when it's compund interest ) */}
            <label className="panel-label mt-4" htmlFor="new-investment-name">
              Payout Frequency
            </label>
            <select
              className="form-control panel-input mb-0"
              id="payout-frequency-type"
              name="payout_frequency"
              onChange={this.handleCategoryChange}
            >
              <option value="">Payout Frequency</option>

              <option value="monthly">Monthy</option>
              <option value="quarterly">Quarterly</option>
              <option value="annually">Annually</option>
            </select>
            {/* end */}
            <p className="text-error">
              {errors
                ? errors.category
                : errorObject && errorObject["category"]}
            </p>
            <label className="panel-label mt-4" htmlFor="new-investment-name">
              Tenor (In Days)
            </label>
            <input
              type="number"
              className="form-control panel-input mb-0"
              placeholder="Enter tenor"
              id="new-investment-name"
              name="tenor"
              value={tenor}
              onChange={this.handleChange}
            />
            <p className="text-error">
              {errors ? errors.tenor : errorObject && errorObject["tenor"]}
            </p>
            <label className="panel-label mt-4" htmlFor="new-investment-name">
              Rate
            </label>
            <input
              type="number"
              className="form-control panel-input mb-0"
              placeholder="Enter rate"
              id="new-investment-name"
              name="rate"
              value={rate}
              onChange={this.handleChange}
            />
            <p className="text-error">
              {errors ? errors.rate : errorObject && errorObject["rate"]}
            </p>
            <label className="panel-label mt-4" htmlFor="new-investment-name">
              Offer Amount
            </label>
            <input
              type="number"
              className="form-control panel-input mb-0"
              placeholder="Enter offer amount"
              id="new-investment-name"
              name="offerAmount"
              value={offerAmount}
              onChange={this.handleChange}
            />
            <p className="text-error">
              {errors
                ? errors.offerAmount
                : errorObject && errorObject["offerAmount"]}
            </p>
            <label className="panel-label mt-4" htmlFor="new-investment-name">
              Minimum Investment
            </label>
            <input
              type="number"
              className="form-control panel-input mb-0"
              placeholder="Enter minimum investment"
              id="new-investment-name"
              name="minimumInvestment"
              value={minimumInvestment}
              onChange={this.handleChange}
            />
            <p className="text-error">
              {errors
                ? errors.minimumInvestment
                : errorObject && errorObject["minimumInvestment"]}
            </p>
            <label
              className="panel-label mt-4"
              htmlFor="penaltyName"
              id="penaltyName"
            >
              Liquidation Penalty
            </label>
            <div className="">
              <div className="form-check form-check-inline">
                <input
                  id="LiquidationTrue"
                  type="radio"
                  className="mb-0 mr-1 form-check-input"
                  placeholder="Enter penalty"
                  name="liquidationPenalty"
                  value={"true"}
                  checked={
                    liquidationPenalty === "true" || liquidationPenalty === 1
                  }
                  onChange={(e) =>
                    this.setState({ liquidationPenalty: e.target.value })
                  }
                />
                <label
                  className="form-check-label mr-4 "
                  htmlFor="LiquidationTrue"
                >
                  Yes
                </label>
              </div>

              <div className="form-check form-check-inline">
                <input
                  id="LiquidationFalse"
                  type="radio"
                  className="mb-0 mr-1 form-check-input"
                  placeholder="Enter penalty"
                  name="liquidationPenalty"
                  value={"false"}
                  checked={
                    liquidationPenalty === "false" || liquidationPenalty === 0
                  }
                  onChange={(e) =>
                    this.setState({ liquidationPenalty: e.target.value })
                  }
                />
                <label
                  className="form-check-label mr-4"
                  htmlFor="LiquidationFalse"
                >
                  No
                </label>
              </div>
            </div>

            <p className="text-error">
              {errors
                ? errors.liquidationPenalty
                : errorObject && errorObject["liquidationPenalty"]}
            </p>
            {liquidationPenalty === "true" || liquidationPenalty === 1 ? (
              <div>
                <label
                  className="panel-label mt-4"
                  htmlFor="new-investment-name"
                >
                  penalty
                </label>
                <input
                  type="text"
                  className="form-control panel-input mb-0"
                  placeholder="Enter penalty"
                  id="penalty"
                  name="penalty"
                  value={penalty}
                  onChange={(e) => this.setState({ penalty: e.target.value })}
                />
                <p className="text-error">
                  {errors
                    ? errors.penalty
                    : errorObject && errorObject["penalty"]}
                </p>
              </div>
            ) : null}

            <label className="panel-label mt-4" htmlFor="new-investment-name">
              Tags
            </label>
            <input
              type="text"
              className="form-control panel-input mb-0"
              placeholder="Enter tags separated by commas"
              id="new-investment-name"
              name="tags"
              value={tags}
              onChange={(e) =>
                this.setState({
                  tags: e.target.value.split(",").map((tag) => tag.trim()),
                })
              }
            />
            <p className="text-error">
              {errors ? errors.tags : errorObject && errorObject["tags"]}
            </p>
            <label
              className="panel-label mt-4"
              htmlFor="new-investment-summary"
            >
              Summary
            </label>
            <input
              type="text"
              className={`form-control panel-input mb-0 ${
                errors?.summary ? "error-input" : ""
              }`}
              id="new-investment-summary"
              name="summary"
              value={summary}
              onChange={(e) => this.setState({ summary: e.target.value })}
            />
            <p className="text-error">
              {errors ? errors.summary : errorObject && errorObject["summary"]}
            </p>

            <label
              className="panel-label mt-4"
              htmlFor="new-investment-description"
            >
              Description
            </label>
            <TextEditor
              customClass={`custum-form__editor ${
                errors?.description ? "custum-form__editor--error" : ""
              }`}
              onChange={(description) => this.setState({ description })}
              toolbarOptions={[
                "history",
                "inline",
                "blockType",
                "fontSize",
                "fontFamily",
                "image",
                "list",
                "textAlign",
              ]}
              toolbarConfigs={{
                inline: { options: ["bold", "italic", "underline"] },
              }}
              height="200px"
              defaultValue={description}
            />
            <p className="text-error">
              {errors
                ? errors.description
                : errorObject && errorObject["description"]}
            </p>
            <label className="panel-label mt-4" htmlFor="investment-icon">
              Offer Document
            </label>
            <div className="img-input" id="investment-icon">
              <div className="img-upload d-flex align-items-center">
                {offerDocumentUrl && (
                  <div className="user-img-container mr-3">
                    <img
                      src={offerDocumentUrl}
                      className="img-fluid"
                      alt="user-img"
                    />
                  </div>
                )}
                <div>
                  <input
                    type="file"
                    className="file"
                    ref={this.offerDocRef}
                    accept="image/png, image/jpeg"
                    onChange={this.handleOfferDocImageSelect}
                  />
                  <button
                    className="bg-white pl-4 pr-4"
                    onClick={(event) =>
                      this.handleFileClick(event, "offerDocument")
                    }
                  >
                    Upload file
                  </button>
                  <p>{offerDocumentFileName}</p>
                  <p>Upload PDF, JPEG, JPG or PNG</p>
                </div>
              </div>
              <p className="text-error">
                {errors
                  ? errors.offerDocument
                  : errorObject && errorObject["offerDocument"]}
              </p>
            </div>
            <label className="panel-label mt-4" htmlFor="investment-icon">
              my Investment icon
            </label>
            <div className="img-input" id="investment-icon">
              <div className="img-upload d-flex align-items-center">
                {imgUrl && (
                  <div className="user-img-container mr-3">
                    <img src={imgUrl} className="img-fluid" alt="user-img" />
                  </div>
                )}
                <div>
                  <input
                    type="file"
                    className="file"
                    ref={this.imgRef}
                    accept="image/png, image/jpeg"
                    onChange={this.handleImageSelect}
                  />
                  <button
                    className="bg-white pl-4 pr-4"
                    onClick={(event) => this.handleFileClick(event, "icon")}
                  >
                    Upload file
                  </button>
                  <p>{fileName}</p>
                  <p>Upload PDF, JPEG, JPG or PNG</p>
                </div>
              </div>
              <p className="text-error">
                {errors ? errors.icon : errorObject && errorObject["icon"]}
              </p>
            </div>
            <label className="panel-label mt-4" htmlFor="investment-background">
              Investment background
            </label>
            <div className="img-input" id="investment-background">
              <div className="img-upload d-flex align-items-center">
                {backgroundUrl && (
                  <div className="user-img-container mr-3">
                    <img
                      src={backgroundUrl}
                      className="img-fluid"
                      alt="user-img"
                    />
                  </div>
                )}
                <div>
                  <input
                    type="file"
                    className="file"
                    ref={this.backgroundRef}
                    accept="image/png, image/jpeg"
                    onChange={this.handleBackgroundImageSelect}
                  />
                  <button
                    className="bg-white pl-4 pr-4"
                    onClick={(event) =>
                      this.handleFileClick(event, "background")
                    }
                  >
                    Upload file
                  </button>
                  <p>{backgroundFileName}</p>
                  <p>Upload PDF, JPEG, JPG or PNG</p>
                </div>
              </div>
              <p className="text-error">
                {errors
                  ? errors.background
                  : errorObject && errorObject["background"]}
              </p>
            </div>
            <div className="row">
              <div className="col-md-6">
                <button
                  className="btn btn-white btn-sm font-md w-100"
                  onClick={this.closeEditPanel}
                >
                  Cancel
                </button>
              </div>
              <div className="col-md-6">
                <button
                  className="btn btn-primary btn-sm font-md w-100 d-flex align-items-center justify-content-center"
                  type="submit"
                >
                  Request Approval
                  {updateLoading && (
                    <div className="spinner-border spinner-border-white spinner-border-sm ml-2"></div>
                  )}
                </button>
              </div>
            </div>
          </form>
          {data && <Alert alert={{ type: "success", message: data.message }} />}
        </RightPanel>
        {loading ? (
          <div className="text-center">
            <div className="spinner-border text-primary ml-auto mr-auto"></div>
          </div>
        ) : (
          investments &&
          (investments?.data?.length > 0 ? (
            <>
              <Pagination
                totalPages={investments.lastPage}
                page={investments.page}
                limit={investments.perPage}
                changePageHandler={(page, limit) =>
                  this.props.getAllFlashDealsInvestments(
                    limit,
                    page,
                    searchTerm
                  )
                }
              />
              <div className="table-overflow">
                <div className="setup-inner__main setup-inner__expand !mb-[20px]">
                  <table className="custum-table custum-table__long">
                    <tbody>
                      <tr>
                        <th>ID</th>
                        <th>Offer Name</th>
                        <th>Offer opening date</th>
                        <th>Offer Closure Date</th>
                        <th>Tenor</th>
                        <th>Summary</th>
                        <th>Description</th>
                        <th>Category</th>
                        <th>Interest Rate</th>
                        <th>Pre Liquidation</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                      {investments?.data.map((investment) => (
                        <tr key={investment.id}>
                          <td>{investment.id}</td>
                          <td
                            onClick={() => this.handleSelectItem(investment.id)}
                          >
                            <span className="color-dark-blue cursor-pointer">
                              {investment.offerName}
                            </span>
                          </td>
                          <td>
                            {dayjs(investment.openingData).format("D MMM YYYY")}
                          </td>
                          <td>
                            {dayjs(investment.closingDate).format("D MMM YYYY")}
                          </td>
                          <td>{investment.tenor}</td>
                          <td
                            className="h-12 overflow-hidden text-ellipsis !whitespace-pre truncate"
                            // style={{
                            //   whiteSpace: "normal",
                            //   maxWidth: "25rem",
                            //   "white-space": "nowrap",
                            //   overflow: "hidden",
                            //   "text-overflow": "ellipsis",
                            // }}
                          >
                            {investment.summary &&
                              this.stripHtml(investment.summary)}
                          </td>
                          <td
                            className="h-12 overflow-hidden text-ellipsis !whitespace-pre truncate"

                            // style={{
                            //   whiteSpace: "normal",
                            //   maxWidth: "25rem",
                            //   "white-space": "nowrap",
                            //   overflow: "hidden",
                            //   "text-overflow": "ellipsis",
                            // }}
                          >
                            {investment.description &&
                              this.stripHtml(investment.description)}
                          </td>
                          <td>{investment.category}</td>
                          <td>{investment.rate}</td>
                          <td>
                            {investment.liquidationPenalty === 1 ? "Yes" : "No"}
                          </td>
                          <td>
                            <span
                              className={` status status--${investment.status}`}
                            >
                              {investment.status}
                            </span>
                          </td>
                          <td className="custum-table__ellipsis">
                            <Dropdown
                              menu={this.dropdownmenus(
                                investment.id,
                                investment.status
                              )}
                              width="145px"
                              arrow={true}
                            >
                              <button className="wrapper-button ellipsis">
                                <img
                                  src={
                                    require("@/assets/icons/flat-ellipsis.svg")
                                      .default
                                  }
                                  alt="dropdown"
                                />
                              </button>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan="6" className="text-center">
                          Showing
                          <span className="font-weight-bold">
                            {investments?.data?.length}
                          </span>
                          of
                          <span className="font-weight-bold">
                            {investments?.total}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          ) : (
            <Emptystate
              title={`${
                searchTerm ? "No Result Found" : "No Flash Deal Investment"
              }`}
              icon={require("@/assets/icons/info.svg").default}
            />
          ))
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    app: {
      investments: {
        flashDealInvestments: { data, error },
      },
    },
    user: { permissions },
  } = state;
  return {
    loading: getActionLoadingState(
      state,
      actionTypes.FETCH_FLASH_DEALS_INVESTMENTS_REQUEST
    ),
    updateLoading: getActionLoadingState(
      state,
      actionTypes.UPDATE_FLASH_DEAL_INVESTMENT_REQUEST
    ),
    removeLoading: getActionLoadingState(
      state,
      actionTypes.REMOVE_INVESTMENT_REQUEST
    ),
    error,
    data: data,
    investments: data?.investments || data?.services,
    permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllFlashDealsInvestments: (limit, page, searchTerm) =>
      dispatch(getAllFlashDealsInvestments(limit, page, searchTerm)),
    updateInvestmentStatus: (id, payload) =>
      dispatch(updateFDInvestmentStatus(id, payload)),
    updateInvestment: (payload) => dispatch(updateFDInvestment(payload)),
    removeInvestment: (id) => dispatch(removeInvestment(id)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FlashDealInvestment)
);
