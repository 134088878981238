import React, { Component } from "react";
import "./style.scss";

class FormBuilderMenu extends Component {
  state = {
    showMenu: false,
    createCustom: false,
    title: "",
  };
  fieldBox = React.createRef();
  textInput = React.createRef();

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.hide);
  }

  show = (e) => {
    this.setState({ showMenu: true });
    document.addEventListener("mousedown", this.hide);
  };

  hide = (e) => {
    if (!this.fieldBox.current.contains(e.target)) {
      this.setState({
        showMenu: false,
        createCustom: false,
        title: "",
      });
      document.removeEventListener("mousedown", this.hide);
    }
  };

  createCustom = () => {
    const newField = this.textInput.current.value.trim();
    if (!newField) return;
    const field = {
      title: newField,
      name: newField.replace(" ", ""),
      placeholder: newField,
      type: "text",
    };
    this.props.onFieldClick(field);
    this.setState({ showMenu: false, createCustom: false, title: "" });
  };

  onCreateCustomClick = () => {
    this.textInput.current.focus();
    this.setState({ createCustom: true });
  };

  onFieldClick = (field) => {
    this.props.onFieldClick(field);
    this.setState({ showMenu: false });
  };

  render() {
    const { fields } = this.props;
    const { showMenu, createCustom, title } = this.state;
    const hasTitle = title.trim().length > 0;

    return (
      <div className="builder" ref={this.fieldBox}>
        <div className="builder__add">
          <button type="button" className="wrapper-button" onClick={this.show}>
            <img
              src={require("@/assets/icons/add-form-field.svg").default}
              alt="add button"
            />
          </button>
          <span>Create a new form field</span>
        </div>
        {showMenu && (
          <div className="builder__box">
            <input
              type="text"
              onChange={(e) => this.setState({ title: e.target.value })}
              className={`builder__button builder__input ${
                !createCustom && "builder__button--hidden"
              }`}
              ref={this.textInput}
              placeholder="Enter Title"
              required
            />
            {createCustom ? (
              <button
                type="button"
                className={`builder__button builder__button--solid ${
                  !hasTitle ? "btn-disabled" : ""
                }`}
                onClick={this.createCustom}
              >
                Create
              </button>
            ) : (
              <button
                type="button"
                className="builder__button"
                onClick={this.onCreateCustomClick}
              >
                <img
                  src={require("@/assets/icons/edit3.svg").default}
                  alt="add button"
                />
                Create a custom form
              </button>
            )}
            <ul className="builder__list">
              {fields.map((field, index) => (
                <li
                  className="builder__list-item"
                  key={index}
                  onClick={() => this.onFieldClick(field)}
                >
                  {field.title}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  }
}
export default FormBuilderMenu;
