import axios from "axios";
import CONFIG from "@/config";
import * as actions from "./actionTypes";
import { logout, updateUser } from "@/store/user/actions";
import { showAlert } from "@/store/ui/actions";
import { validate, apiRequestHandler } from "@/utils";

const getInvestmentsRequest = () => {
  return {
    type: actions.GET_INVESTMENTS_REQUEST,
  };
};

const getInvestmentsError = () => {
  return {
    type: actions.GET_INVESTMENTS_ERROR,
  };
};

const getInvestmentsSuccess = () => {
  return {
    type: actions.GET_INVESTMENTS_SUCCESS,
  };
};

const investmentsPageRequest = () => {
  return {
    type: actions.INVESTMENTS_PAGE_REQUEST,
  };
};

const investmentsPageError = (message) => {
  return {
    type: actions.INVESTMENTS_PAGE_ERROR,
    message,
  };
};

const investmentsPageSuccess = () => {
  return {
    type: actions.INVESTMENTS_PAGE_SUCCESS,
  };
};

export const fetchInvestments = (page, limit, status, searchTerm) => {
  let path = "";
  if (status === "nearing-maturity") {
    path = "nearing/maturity";
  } else if (status === "snappy-investment") {
    path = "snappy";
  }
  path = `${path}?page=${page}&limit=${limit}`;
  if (searchTerm)
    path = `search?search=${searchTerm}&section=${
      status === "updates" ? "pending-update" : status
    }&page=${page}&limit=${limit}`;
  return (dispatch, getState) => {
    dispatch(getInvestmentsRequest());
    return new Promise((resolve) => {
      axios
        .get(`${CONFIG.BASE_URL}/admin/investments/${path}`, {
          headers: {
            Authorization: getState().user.token,
          },
        })
        .then((response) => {
          dispatch(
            updateUser({
              token: response.headers.authorization,
            })
          );
          if (response.status === 200) {
            dispatch(getInvestmentsSuccess());
            resolve(response.data.data.investments || response.data.data);
          }
        })
        .catch(({ response }) => {
          dispatch(getInvestmentsError());
          dispatch(
            updateUser({
              token: response?.headers.authorization,
            })
          );
          if ([400, 404].includes(response?.status)) {
            response.data.message &&
              dispatch(
                showAlert({ type: "error", message: response.data.message })
              );
          }
          if (response?.status === 403) {
            return dispatch(
              showAlert({
                type: "error",
                message: "Oops! you are unauthorised",
              })
            );
          }
          if (response?.status === 401) {
            dispatch(
              showAlert({ type: "error", message: "Your session has expired" })
            );
            return setTimeout(() => dispatch(logout()), 2000);
          }
          dispatch(
            showAlert({
              type: "error",
              message: "Oops! something went wrong please try again.",
            })
          );
        });
    });
  };
};

export const cancelInvestment = (id, reason) => {
  const payload = {
    action: "reverse",
    message: reason,
  };
  return (dispatch, getState) => {
    dispatch(investmentsPageRequest());
    return new Promise((resolve) => {
      axios
        .patch(
          `${CONFIG.BASE_URL}/admin/investments/${id}/cancel/initiate`,
          payload,
          {
            headers: {
              Authorization: getState().user.token,
            },
          }
        )
        .then((response) => {
          dispatch(
            updateUser({
              token: response.headers.authorization,
            })
          );
          if (response.status === 200) {
            dispatch(investmentsPageSuccess());
            dispatch(
              showAlert({ type: "success", message: response.data.message })
            );
            resolve();
          }
        })
        .catch(({ response }) => {
          dispatch(investmentsPageError());
          dispatch(
            updateUser({
              token: response?.headers.authorization,
            })
          );
          if ([400, 404].includes(response?.status)) {
            response.data.message &&
              dispatch(
                showAlert({ type: "error", message: response.data.message })
              );
          }
          if (response?.status === 403) {
            return dispatch(
              showAlert({
                type: "error",
                message: "Oops! you are unauthorised",
              })
            );
          }
          if (response?.status === 401) {
            dispatch(
              showAlert({ type: "error", message: "Your session has expired" })
            );
            return setTimeout(() => dispatch(logout()), 2000);
          }
          dispatch(
            showAlert({
              type: "error",
              message: "Oops! something went wrong please try again.",
            })
          );
        });
    });
  };
};

export const updateInvestment = (data, id, status) => {
  const path = status ? `${id}/edit/${status}` : `${id}`;
  return (dispatch, getState) => {
    const required = ["targetAmount", "startDate", "frequency"];
    dispatch(investmentsPageRequest());
    dispatch(validate(data, required, investmentsPageError)).then(() => {
      axios
        .patch(`${CONFIG.BASE_URL}/admin/investments/${path}`, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: getState().user.token,
          },
        })
        .then((response) => {
          dispatch(
            updateUser({
              token: response.headers.authorization,
            })
          );
          if (response.status === 200) {
            dispatch(investmentsPageSuccess());
            dispatch(showAlert({ type: "success", message: "Successful" }));
            window.location.reload();
          }
        })
        .catch(({ response }) => {
          dispatch(investmentsPageError({ message: response?.data.error }));
          response &&
            dispatch(
              updateUser({
                token: response.headers.authorization,
              })
            );
          if (response?.status === 401) {
            dispatch(
              showAlert({ type: "error", message: "Your session has expired" })
            );
            return setTimeout(() => dispatch(logout()), 2000);
          }
          if (response?.status === 403) {
            return dispatch(
              showAlert({
                type: "error",
                message: "Oops! you are unauthorised",
              })
            );
          }
          if (response?.status === 400) {
            return dispatch(
              showAlert({ type: "error", message: response.data.message })
            );
          }
          return dispatch(
            showAlert({
              type: "error",
              message: "Oops! something wrong please try again.",
            })
          );
        });
    });
  };
};

export const updateMultipleInvestment = (data, status, message) => {
  const payload =
    status === "approve"
      ? { data }
      : {
          status: "reject",
          message,
          requests: data,
        };
  const config = {
    urlPath: `admin/investments/multiple/${
      status === "approve" ? "update/approve" : "edit/reject"
    }`,
    payload,
    method: "patch",
    requestAction: investmentsPageRequest,
    successAction: investmentsPageSuccess,
    errorAction: investmentsPageError,
    alertSuccessMessage: "Successful",
  };
  return apiRequestHandler(config);
};

export const fetchInvestmentTransactions = (investmentId, page, limit) => {
  return (dispatch, getState) => {
    dispatch(getInvestmentsRequest());
    return new Promise((resolve) => {
      axios
        .get(
          `${CONFIG.BASE_URL}/admin/transactions/investments/${investmentId}?page=${page}&limit=${limit}`,
          {
            headers: {
              Authorization: getState().user.token,
            },
          }
        )
        .then((response) => {
          dispatch(
            updateUser({
              token: response.headers.authorization,
            })
          );
          if (response.status === 200) {
            dispatch(getInvestmentsSuccess());
            resolve(response.data.data.transactions);
          }
        })
        .catch(({ response }) => {
          dispatch(getInvestmentsError());
          dispatch(
            updateUser({
              token: response?.headers.authorization,
            })
          );
          if ([400, 404].includes(response?.status)) {
            response.data.message &&
              dispatch(
                showAlert({ type: "error", message: response.data.message })
              );
          }
          if (response?.status === 403) {
            return dispatch(
              showAlert({
                type: "error",
                message: "Oops! you are unauthorised",
              })
            );
          }
          if (response?.status === 401) {
            dispatch(
              showAlert({ type: "error", message: "Your session has expired" })
            );
            return setTimeout(() => dispatch(logout()), 2000);
          }
          dispatch(
            showAlert({
              type: "error",
              message: "Oops! something went wrong please try again.",
            })
          );
        });
    });
  };
};
