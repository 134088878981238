import axios from 'axios';
import CONFIG from '@/config';
import * as actions from "./actionTypes";
import { logout, updateUser } from "@/store/user/actions";
import { showAlert } from '@/store/ui/actions';

// const clearError = () => {
//   return {
//     type: actions.CLEAR_ERROR,
//   }
// }

// const clearData = () => {
//   return {
//     type: actions.CLEAR_DATA,
//   }
// }

const getTicketsRequest = () => {
  return {
    type: actions.GET_TICKETS_REQUEST,
  }
}

const getTicketsError = (message) => {
  return {
    type: actions.GET_TICKETS_ERROR,
    error: message,
  }
}

const getTicketsSuccess = (data) => {
  return {
    type: actions.GET_TICKETS_SUCCESS,
    data,
  }
}

export const getTickets = (status, limit, pageNumber) => {
  return (dispatch, getState) => {
    dispatch(getTicketsRequest());

    const { token } = getState().user;

    axios.get(`${CONFIG.BASE_URL}/admin/supports/tickets/status/${status}?limit=${limit}&page=${pageNumber}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then(response => {
        dispatch(updateUser({
          token: response.headers.authorization
        }))
        if ([200, 201].includes(response.status)) {
          dispatch(getTicketsSuccess(response.data.data));
        }
      })
      .catch((error) => {
        error.response && dispatch(updateUser({
          token: error.response.headers.authorization
        }))
        if (error.response && [400, 404, 403, 422].includes(error.response.status)) {
          return dispatch(getTicketsError(error.response.data.error ? error.response.data.error : error.response.data.message));
        }
        if (error.response && [401].includes(error.response.status)) {
          dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
          return setTimeout(() => dispatch(logout()), 2000)
        }
        if (error.response && error.response.status >= 500) {
          return dispatch(getTicketsError('Oops! We did something wrong.'));
        }
        dispatch(getTicketsError('Oops! We did something wrong.'));
      })
  }
}

//filter tickets/ search tickets
const searchTicketsRequest = () => {
  return {
    type: actions.SEARCH_TICKETS_REQUEST,
  }
}

const searchTicketsError = (message) => {
  return {
    type: actions.SEARCH_TICKETS_ERROR,
    error: message,
  }
}

const searchTicketsSuccess = (data) => {
  return {
    type: actions.SEARCH_TICKETS_SUCCESS,
    data,
  }
}

export const searchTickets = (payload) => {
  return (dispatch, getState) => {
    dispatch(searchTicketsRequest());

    const { token } = getState().user;
    return new Promise((resolve) => {
      axios.get(`${CONFIG.BASE_URL}/admin/supports/tickets/filter?search=${payload.search}&status=${payload.status}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
        .then(response => {
          dispatch(updateUser({
            token: response.headers.authorization
          }))
          if ([200, 201].includes(response.status)) {
            dispatch(searchTicketsSuccess(response.data.data));
            return resolve(response.data.data)
          }
        })
        .catch((error) => {
          error.response && dispatch(updateUser({
            token: error.response.headers.authorization
          }))
          if (error.response && [400, 404, 403, 422].includes(error.response.status)) {
            dispatch(showAlert({ type: 'error', message: error?.response?.data?.message }))
            return dispatch(searchTicketsError(error.response.data.error ? error.response.data.error : error.response.data.message));
          }
          if (error.response && [401].includes(error.response.status)) {
            dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
            return setTimeout(() => dispatch(logout()), 2000)
          }
          if (error.response && error.response.status >= 500) {
            return dispatch(searchTicketsError('Oops! We did something wrong.'));
          }
          dispatch(searchTicketsError('Oops! We did something wrong.'));
        })
    })
  }
}


//GET SINGLE TICKET
const getSingleTicketRequest = () => {
  return {
    type: actions.GET_SINGLE_TICKET_REQUEST,
  }
}

const getSingleTicketError = (message) => {
  return {
    type: actions.GET_SINGLE_TICKET_ERROR,
    error: message,
  }
}

const getSingleTicketSuccess = (data) => {
  return {
    type: actions.GET_SINGLE_TICKET_SUCCESS,
    data,
  }
}

export const getSingleTicket = (id) => {
  return (dispatch, getState) => {
    dispatch(getSingleTicketRequest());

    const { token } = getState().user;
    return new Promise((resolve) => {
      axios.get(`${CONFIG.BASE_URL}/admin/supports/tickets/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
        .then(response => {
          dispatch(updateUser({
            token: response.headers.authorization
          }))
          if ([200, 201].includes(response.status)) {
            dispatch(getSingleTicketSuccess(response.data.data));
            return resolve(response.data.data)
          }
        })
        .catch((error) => {
          error.response && dispatch(updateUser({
            token: error.response.headers.authorization
          }))
          if (error.response && [400, 404, 403, 422].includes(error.response.status)) {
            dispatch(showAlert({ type: 'error', message: error?.response?.data?.message }))
            return dispatch(getSingleTicketError(error.response.data.error ? error.response.data.error : error.response.data.message));
          }
          if (error.response && [401].includes(error.response.status)) {
            dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
            return setTimeout(() => dispatch(logout()), 2000)
          }
          if (error.response && error.response.status >= 500) {
            return dispatch(getSingleTicketError('Oops! We did something wrong.'));
          }
          dispatch(getSingleTicketError('Oops! We did something wrong.'));
        })
    })
  }
}

//send message
const sendMessageRequest = () => {
  return {
    type: actions.SEND_MESSAGE_REQUEST,
  }
}

const sendMessageError = (message) => {
  return {
    type: actions.SEND_MESSAGE_ERROR,
    error: message,
  }
}

const sendMessageSuccess = (data) => {
  return {
    type: actions.SEND_MESSAGE_SUCCESS,
    data,
  }
}

export const sendMessage = (id, payload) => {
  return (dispatch, getState) => {
    dispatch(sendMessageRequest());

    const { token } = getState().user;
    return new Promise((resolve) => {
      axios.post(`${CONFIG.BASE_URL}/admin/supports/tickets/${id}/message`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
        .then(response => {
          dispatch(updateUser({
            token: response.headers.authorization
          }))
          if ([200, 201].includes(response.status)) {
            dispatch(sendMessageSuccess(response.data.data));
            return resolve(response.data.data)
          }
        })
        .catch((error) => {
          error.response && dispatch(updateUser({
            token: error.response.headers.authorization
          }))
          if (error.response && [400, 404, 403, 422].includes(error.response.status)) {
            dispatch(showAlert({ type: 'error', message: error?.response?.data?.message }))
            return dispatch(sendMessageError(error.response.data.error ? error.response.data.error : error.response.data.message));
          }
          if (error.response && [401].includes(error.response.status)) {
            dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
            return setTimeout(() => dispatch(logout()), 2000)
          }
          if (error.response && error.response.status >= 500) {
            return dispatch(sendMessageError('Oops! We did something wrong.'));
          }
          dispatch(sendMessageError('Oops! We did something wrong.'));
        })
    })
  }
}

// update ticket status
const updateTicketStatusRequest = () => {
  return {
    type: actions.UPDATE_TICKET_STATUS_REQUEST,
  }
}

const updateTicketStatusError = (message) => {
  return {
    type: actions.UPDATE_TICKET_STATUS_ERROR,
    error: message,
  }
}

const updateTicketStatusSuccess = (data) => {
  return {
    type: actions.UPDATE_TICKET_STATUS_SUCCESS,
    data,
  }
}

export const updateTicketStatus = (id, payload) => {
  return (dispatch, getState) => {
    dispatch(updateTicketStatusRequest());

    const { token } = getState().user;
    return new Promise((resolve) => {
      axios.put(`${CONFIG.BASE_URL}/admin/supports/tickets/${id}/status`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
        .then(response => {
          dispatch(updateUser({
            token: response.headers.authorization
          }))
          if ([200, 201].includes(response.status)) {
            dispatch(updateTicketStatusSuccess(response.data.data));
            dispatch(showAlert({ type: 'success', message: response.data.message }))
            return resolve(response.data.data)
          }
        })
        .catch((error) => {
          error.response && dispatch(updateUser({
            token: error.response.headers.authorization
          }))
          if (error.response && [400, 404, 403, 422].includes(error.response.status)) {
            dispatch(showAlert({ type: 'error', message: error?.response?.data?.message }))
            return dispatch(updateTicketStatusError(error.response.data.error ? error.response.data.error : error.response.data.message));
          }
          if (error.response && [401].includes(error.response.status)) {
            dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
            return setTimeout(() => dispatch(logout()), 2000)
          }
          if (error.response && error.response.status >= 500) {
            return dispatch(updateTicketStatusError('Oops! We did something wrong.'));
          }
          dispatch(updateTicketStatusError('Oops! We did something wrong.'));
        })
    })
  }
}


//GET user guides
const getUserGuidesRequest = () => {
  return {
    type: actions.GET_USER_GUIDES_REQUEST,
  }
}

const getUserGuidesError = (message) => {
  return {
    type: actions.GET_USER_GUIDES_ERROR,
    error: message,
  }
}

const getUserGuidesSuccess = (data) => {
  return {
    type: actions.GET_USER_GUIDES_SUCCESS,
    data,
  }
}

export const getUserGuides = (page, limit, searchTerm) => {
  let url;
  const section = 'all';
  if(searchTerm) {
    url = `${CONFIG.BASE_URL}/admin/supports/guides/${section}/search?searchWord=${searchTerm}&page=${page}&limit=${limit}`
  }
  else {
    url = `${CONFIG.BASE_URL}/admin/supports/guides?page=${page}&limit=${limit}`
  }

  return (dispatch, getState) => {
    dispatch(getUserGuidesRequest());

    const { token } = getState().user;
    return new Promise((resolve) => {
      axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
        .then(response => {
          dispatch(updateUser({
            token: response.headers.authorization
          }))
          if ([200, 201].includes(response.status)) {
            dispatch(getUserGuidesSuccess(response.data.data));
            return resolve(response.data.data)
          }
        })
        .catch((error) => {
          error.response && dispatch(updateUser({
            token: error.response.headers.authorization
          }))
          if (error.response && [400, 404, 403, 422].includes(error.response.status)) {
            dispatch(showAlert({ type: 'error', message: error?.response?.data?.message }))
            return dispatch(getUserGuidesError(error.response.data.error ? error.response.data.error : error.response.data.message));
          }
          if (error.response && [401].includes(error.response.status)) {
            dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
            return setTimeout(() => dispatch(logout()), 2000)
          }
          if (error.response && error.response.status >= 500) {
            return dispatch(getUserGuidesError('Oops! We did something wrong.'));
          }
          dispatch(getUserGuidesError('Oops! We did something wrong.'));
        })
    })
  }
}

//ADD GUIDE CATEGORY
const addGuideCategoryRequest = () => {
  return {
    type: actions.ADD_GUIDE_CATEGORY_REQUEST,
  }
}

const addGuideCategoryError = (message) => {
  return {
    type: actions.ADD_GUIDE_CATEGORY_ERROR,
    error: message,
  }
}

const addGuideCategorySuccess = (data) => {
  return {
    type: actions.ADD_GUIDE_CATEGORY_SUCCESS,
    data,
  }
}

export const addGuideCategory = (payload) => {
  return (dispatch, getState) => {
    dispatch(addGuideCategoryRequest());

    const { token } = getState().user;
    return new Promise((resolve) => {
      axios.post(`${CONFIG.BASE_URL}/admin/supports/guides/category`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
        .then(response => {
          dispatch(updateUser({
            token: response.headers.authorization
          }))
          if ([200, 201].includes(response.status)) {
            dispatch(addGuideCategorySuccess(response.data.data));
            dispatch(showAlert({ type: 'success', message: response?.data?.message }))
            return resolve(response.data.data)
          }
        })
        .catch((error) => {
          error.response && dispatch(updateUser({
            token: error.response.headers.authorization
          }))
          if (error.response && [400, 404, 403, 422].includes(error.response.status)) {
            dispatch(showAlert({ type: 'error', message: error?.response?.data?.message }))
            return dispatch(addGuideCategoryError(error.response.data.error ? error.response.data.error : error.response.data.message));
          }
          if (error.response && [401].includes(error.response.status)) {
            dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
            return setTimeout(() => dispatch(logout()), 2000)
          }
          if (error.response && error.response.status >= 500) {
            return dispatch(addGuideCategoryError('Oops! We did something wrong.'));
          }
          dispatch(addGuideCategoryError('Oops! We did something wrong.'));
        })
    })
  }
}

//ADD USER GUIDE topic
const addGuideTopicRequest = () => {
  return {
    type: actions.ADD_USER_GUIDES_REQUEST,
  }
}

const addGuideTopicError = (message) => {
  return {
    type: actions.ADD_USER_GUIDES_ERROR,
    error: message,
  }
}

const addGuideTopicSuccess = (data) => {
  return {
    type: actions.ADD_USER_GUIDES_SUCCESS,
    data,
  }
}

export const addGuideTopic = (payload) => {
  return (dispatch, getState) => {
    dispatch(addGuideTopicRequest());

    const { token } = getState().user;
    return new Promise((resolve) => {
      axios.post(`${CONFIG.BASE_URL}/admin/supports/guides`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
        .then(response => {
          dispatch(updateUser({
            token: response.headers.authorization
          }))
          if ([200, 201].includes(response.status)) {
            dispatch(addGuideTopicSuccess(response.data.data));
            dispatch(showAlert({ type: 'success', message: response?.data?.message }))
            return resolve(response.data.data)
          }
        })
        .catch((error) => {
          error.response && dispatch(updateUser({
            token: error.response.headers.authorization
          }))
          if (error.response && [400, 404, 403, 422].includes(error.response.status)) {
            dispatch(showAlert({ type: 'error', message: error?.response?.data?.message }))
            return dispatch(addGuideTopicError(error.response.data.error ? error.response.data.error : error.response.data.message));
          }
          if (error.response && [401].includes(error.response.status)) {
            dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
            return setTimeout(() => dispatch(logout()), 2000)
          }
          if (error.response && error.response.status >= 500) {
            return dispatch(addGuideTopicError('Oops! We did something wrong.'));
          }
          dispatch(addGuideTopicError('Oops! We did something wrong.'));
        })
    })
  }
}


//GET user guides
const getGuideBystatusRequest = () => {
  return {
    type: actions.GET_USER_GUIDE_BY_STATUS_REQUEST,
  }
}

const getGuideBystatusError = (message) => {
  return {
    type: actions.GET_USER_GUIDE_BY_STATUS_ERROR,
    error: message,
  }
}

const getGuideBystatusSuccess = (data) => {
  return {
    type: actions.GET_USER_GUIDE_BY_STATUS_SUCCESS,
    data,
  }
}

export const getGuideBystatus = (status, page, limit, searchTerm) => {
  let url;
  const section = 'pending-approval';
  if(searchTerm) {
    url = `${CONFIG.BASE_URL}/admin/supports/guides/${section}/search?searchWord=${searchTerm}&page=${page}&limit=${limit}`
  }
  else {
    url =`${CONFIG.BASE_URL}/admin/supports/guides/status/${status}?page=${page}&limit=${limit}`
  }
  return (dispatch, getState) => {
    dispatch(getGuideBystatusRequest());

    const { token } = getState().user;
    return new Promise((resolve) => {
      axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
        .then(response => {
          dispatch(updateUser({
            token: response.headers.authorization
          }))
          if ([200, 201].includes(response.status)) {
            dispatch(getGuideBystatusSuccess(response.data.data));
            return resolve(response.data.data)
          }
        })
        .catch((error) => {
          error.response && dispatch(updateUser({
            token: error.response.headers.authorization
          }))
          if (error.response && [400, 404, 403, 422].includes(error.response.status)) {
            dispatch(showAlert({ type: 'error', message: error?.response?.data?.message }))
            return dispatch(getGuideBystatusError(error.response.data.error ? error.response.data.error : error.response.data.message));
          }
          if (error.response && [401].includes(error.response.status)) {
            dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
            return setTimeout(() => dispatch(logout()), 2000)
          }
          if (error.response && error.response.status >= 500) {
            return dispatch(getGuideBystatusError('Oops! We did something wrong.'));
          }
          dispatch(getGuideBystatusError('Oops! We did something wrong.'));
        })
    })
  }
}


//GET user guides
const getGuideCategoryBystatusRequest = () => {
  return {
    type: actions.GET_GUIDE_CATEGORY_BY_STATUS_REQUEST,
  }
}

const getGuideCategoryBystatusError = (message) => {
  return {
    type: actions.GET_GUIDE_CATEGORY_BY_STATUS_ERROR,
    error: message,
  }
}

const getGuideCategoryBystatusSuccess = (data) => {
  return {
    type: actions.GET_GUIDE_CATEGORY_BY_STATUS_SUCCESS,
    data,
  }
}

export const getGuideCategoryBystatus = (status, page, limit, searchTerm) => {
  let url;
  const section = 'pending-category';
  if(searchTerm) {
    url = `${CONFIG.BASE_URL}/admin/supports/guides/${section}/search?searchWord=${searchTerm}&page=${page}&limit=${limit}`
  }
  else {
    url =`${CONFIG.BASE_URL}/admin/supports/guides/category/status/${status}?page=${page}&limit=${limit}`
  }
  return (dispatch, getState) => {
    dispatch(getGuideCategoryBystatusRequest());

    const { token } = getState().user;
    return new Promise((resolve) => {
      axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
        .then(response => {
          dispatch(updateUser({
            token: response.headers.authorization
          }))
          if ([200, 201].includes(response.status)) {
            dispatch(getGuideCategoryBystatusSuccess(response.data.data));
            return resolve(response.data.data)
          }
        })
        .catch((error) => {
          error.response && dispatch(updateUser({
            token: error.response.headers.authorization
          }))
          if (error.response && [400, 404, 403, 422].includes(error.response.status)) {
            dispatch(showAlert({ type: 'error', message: error?.response?.data?.message }))
            return dispatch(getGuideCategoryBystatusError(error.response.data.error ? error.response.data.error : error.response.data.message));
          }
          if (error.response && [401].includes(error.response.status)) {
            dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
            return setTimeout(() => dispatch(logout()), 2000)
          }
          if (error.response && error.response.status >= 500) {
            return dispatch(getGuideCategoryBystatusError('Oops! We did something wrong.'));
          }
          dispatch(getGuideCategoryBystatusError('Oops! We did something wrong.'));
        })
    })
  }
}

// UPDATE CATEGORY guides
const updateGuideCategorystatusRequest = () => {
  return {
    type: actions.UPDATE_GUIDE_CATEGORY_STATUS_REQUEST,
  }
}

const updateGuideCategorystatusError = (message) => {
  return {
    type: actions.UPDATE_GUIDE_CATEGORY_STATUS_ERROR,
    error: message,
  }
}

const updateGuideCategorystatusSuccess = (data) => {
  return {
    type: actions.UPDATE_GUIDE_CATEGORY_STATUS_SUCCESS,
    data,
  }
}

export const updateGuideCategorystatus = (id, payload) => {
  return (dispatch, getState) => {
    dispatch(updateGuideCategorystatusRequest());

    const { token } = getState().user;
    return new Promise((resolve) => {
      axios.put(`${CONFIG.BASE_URL}/admin/supports/guides/${id}/status`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
        .then(response => {
          dispatch(updateUser({
            token: response.headers.authorization
          }))
          if ([200, 201].includes(response.status)) {
            dispatch(updateGuideCategorystatusSuccess(response.data.data));
            dispatch(showAlert({ type: 'success', message: response?.data?.message }))
            return resolve(response.data.data)
          }
        })
        .catch((error) => {
          error.response && dispatch(updateUser({
            token: error.response.headers.authorization
          }))
          if (error.response && [400, 404, 403, 422].includes(error.response.status)) {
            dispatch(showAlert({ type: 'error', message: error?.response?.data?.message }))
            return dispatch(updateGuideCategorystatusError(error.response.data.error ? error.response.data.error : error.response.data.message));
          }
          if (error.response && [401].includes(error.response.status)) {
            dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
            return setTimeout(() => dispatch(logout()), 2000)
          }
          if (error.response && error.response.status >= 500) {
            return dispatch(updateGuideCategorystatusError('Oops! We did something wrong.'));
          }
          dispatch(updateGuideCategorystatusError('Oops! We did something wrong.'));
        })
    })
  }
}

// UPDATE user guides
const updateUserGuideRequest = () => {
  return {
    type: actions.UPDATE_USER_GUIDES_REQUEST,
  }
}

const updateUserGuideError = (message) => {
  return {
    type: actions.UPDATE_USER_GUIDES_ERROR,
    error: message,
  }
}

const updateUserGuideSuccess = (data) => {
  return {
    type: actions.UPDATE_USER_GUIDES_SUCCESS,
    data,
  }
}

export const updateUserGuide = (id, payload) => {
  return (dispatch, getState) => {
    dispatch(updateUserGuideRequest());

    const { token } = getState().user;
    return new Promise((resolve) => {
      axios.put(`${CONFIG.BASE_URL}/admin/supports/guides/${id}`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
        .then(response => {
          dispatch(updateUser({
            token: response.headers.authorization
          }))
          if ([200, 201].includes(response.status)) {
            dispatch(updateUserGuideSuccess(response.data.data));
            dispatch(showAlert({ type: 'success', message: response?.data?.message }))
            return resolve(response.data.data)
          }
        })
        .catch((error) => {
          error.response && dispatch(updateUser({
            token: error.response.headers.authorization
          }))
          if (error.response && [400, 404, 403, 422].includes(error.response.status)) {
            dispatch(showAlert({ type: 'error', message: error?.response?.data?.message }))
            return dispatch(updateUserGuideError(error.response.data.error ? error.response.data.error : error.response.data.message));
          }
          if (error.response && [401].includes(error.response.status)) {
            dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
            return setTimeout(() => dispatch(logout()), 2000)
          }
          if (error.response && error.response.status >= 500) {
            return dispatch(updateUserGuideError('Oops! We did something wrong.'));
          }
          dispatch(updateUserGuideError('Oops! We did something wrong.'));
        })
    })
  }
}

//REMOVE guides
const deleteGuidesRequest = () => {
  return {
    type: actions.DELETE_USER_GUIDES_REQUEST,
  }
}

const deleteGuidesError = (message) => {
  return {
    type: actions.DELETE_USER_GUIDES_ERROR,
    error: message,
  }
}

const deleteGuidesSuccess = (data) => {
  return {
    type: actions.DELETE_USER_GUIDES_SUCCESS,
    data,
  }
}

export const deleteGuides = (payload) => {
  return (dispatch, getState) => {
    dispatch(deleteGuidesRequest());

    const { token } = getState().user;
    return new Promise((resolve) => {
      axios.delete(`${CONFIG.BASE_URL}/admin/supports/guides`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        data: payload
      })
        .then(response => {
          dispatch(updateUser({
            token: response.headers.authorization
          }))
          if ([200, 201].includes(response.status)) {
            dispatch(deleteGuidesSuccess(response.data.data));
            dispatch(showAlert({ type: 'success', message: response?.data?.message }))
            return resolve(response.data.data)
          }
        })
        .catch((error) => {
          error.response && dispatch(updateUser({
            token: error.response.headers.authorization
          }))
          if (error.response && [400, 404, 403, 422].includes(error.response.status)) {
            dispatch(showAlert({ type: 'error', message: error?.response?.data?.message }))
            return dispatch(deleteGuidesError(error.response.data.error ? error.response.data.error : error.response.data.message));
          }
          if (error.response && [401].includes(error.response.status)) {
            dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
            return setTimeout(() => dispatch(logout()), 2000)
          }
          if (error.response && error.response.status >= 500) {
            return dispatch(deleteGuidesError('Oops! We did something wrong.'));
          }
          dispatch(deleteGuidesError('Oops! We did something wrong.'));
        })
    })
  }
}

// UPDATE guides category
const updateGuideCategoryRequest = () => {
  return {
    type: actions.UPDATE_GUIDE_CATEGORY_REQUEST,
  }
}

const updateGuideCategoryError = (message) => {
  return {
    type: actions.UPDATE_GUIDE_CATEGORY_ERROR,
    error: message,
  }
}

const updateGuideCategorySuccess = (data) => {
  return {
    type: actions.UPDATE_GUIDE_CATEGORY_SUCCESS,
    data,
  }
}

export const updateGuideCategory = (id, payload) => {
  return (dispatch, getState) => {
    dispatch(updateGuideCategoryRequest());

    const { token } = getState().user;
    return new Promise((resolve) => {
      axios.put(`${CONFIG.BASE_URL}/admin/supports/guides/category/${id}`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
        .then(response => {
          dispatch(updateUser({
            token: response.headers.authorization
          }))
          if ([200, 201].includes(response.status)) {
            dispatch(updateGuideCategorySuccess(response.data.data));
            dispatch(showAlert({ type: 'success', message: response?.data?.message }))
            return resolve(response.data.data)
          }
        })
        .catch((error) => {
          error.response && dispatch(updateUser({
            token: error.response.headers.authorization
          }))
          if (error.response && [400, 404, 403, 422].includes(error.response.status)) {
            dispatch(showAlert({ type: 'error', message: error?.response?.data?.message }))
            return dispatch(updateGuideCategoryError(error.response.data.error ? error.response.data.error : error.response.data.message));
          }
          if (error.response && [401].includes(error.response.status)) {
            dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
            return setTimeout(() => dispatch(logout()), 2000)
          }
          if (error.response && error.response.status >= 500) {
            return dispatch(updateGuideCategoryError('Oops! We did something wrong.'));
          }
          dispatch(updateGuideCategoryError('Oops! We did something wrong.'));
        })
    })
  }
}

// UPDATE guides category
const updateGuideStatusRequest = () => {
  return {
    type: actions.UPDATE_GUIDE_STATUS_REQUEST,
  }
}

const updateGuideStatusError = (message) => {
  return {
    type: actions.UPDATE_GUIDE_STATUS_ERROR,
    error: message,
  }
}

const updateGuideStatusSuccess = (data) => {
  return {
    type: actions.UPDATE_GUIDE_STATUS_SUCCESS,
    data,
  }
}

export const updateGuideStatus = (id, payload) => {
  return (dispatch, getState) => {
    dispatch(updateGuideStatusRequest());

    const { token } = getState().user;
    return new Promise((resolve, reject) => {
      axios.put(`${CONFIG.BASE_URL}/admin/supports/guides/${id}/status`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
        .then(response => {
          dispatch(updateUser({
            token: response.headers.authorization
          }))
          if ([200, 201].includes(response.status)) {
            dispatch(updateGuideStatusSuccess(response.data.data));
            dispatch(showAlert({ type: 'success', message: response?.data?.message }))
            return resolve(response.data.data)
          }
        })
        .catch((error) => {
          reject()
          error.response && dispatch(updateUser({
            token: error.response.headers.authorization
          }))
          if (error.response && [400, 404, 403, 422].includes(error.response.status)) {
            dispatch(showAlert({ type: 'error', message: error?.response?.data?.message }))
            return dispatch(updateGuideStatusError(error.response.data.error ? error.response.data.error : error.response.data.message));
          }
          if (error.response && [401].includes(error.response.status)) {
            dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
            return setTimeout(() => dispatch(logout()), 2000)
          }
          if (error.response && error.response.status >= 500) {
            return dispatch(updateGuideStatusError('Oops! We did something wrong.'));
          }
          dispatch(updateGuideStatusError('Oops! We did something wrong.'));
        })
    })
  }
}