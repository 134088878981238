import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, NavLink, Redirect } from "react-router-dom";
import AuditTrail from './AuditTrail';
import CTR from './CTR';
import LiabilityTracker from './LiabilityTracker';
import UserInformation from "./UserInformation";
import PPI from "./PPI";
import { hasPermission } from "@/utils";

class Reports extends Component {
    canReadLiabilityTracker = hasPermission(this.props.permissions, [
        "read_liability_tracker"
    ]);
    canReadAuditTrail = hasPermission(this.props.permissions, [
        "read_audit_trail"
    ]);

    canReadCTR = hasPermission(this.props.permissions, [
        "read_ctr"
    ]);
    canDownloadUserReport = hasPermission(this.props.permissions, [
        "download_user_information"
    ])
    canDownloadCTR = hasPermission(this.props.permissions, [
        "download_ctr"
    ]);

    canReadPPPI = hasPermission(this.props.permissions, [
        "read_ppi"
    ]);
    navItems = [
        {name: 'Audit Trail', path: 'audit-trail'},
        {name: 'Liability Tracker', path: 'liabilty-tracker'},
        {name: 'CTR Report', path: 'ctr-report'},
        {name: 'Private Placement & Public Issues', path: 'ppi'},
        {name: 'User Information', path: 'user-information'},
    ].filter(item => {
        if(item.path === 'audit-trail' && this.canReadAuditTrail) return true;
        if(item.path === 'liabilty-tracker' && this.canReadLiabilityTracker) return true;
        if(item.path === 'ctr-report' && this.canReadCTR) return true;
        if(item.path === 'ppi' && this.canReadPPPI) return true;
        if(item.path === 'user-information' && this.canDownloadUserReport) return true;
    });



    render() {
        const { match: { path } } = this.props;
        const firstRoute = this.navItems.length ? this.navItems[0].path : '';
        return(
            <div className="setup-area">
                <ul className="setup-nav">
                    {this.navItems.map((item, index) => (
                        <li key={index} className="setup-nav__item" >
                            <NavLink  
                                to={{pathname: `/dashboard/reports/${item.path}`, state: { pageTitle: 'Reports'}}} 
                                activeClassName="setup-nav__item--active"
                            >
                                {item.name}
                            </NavLink>
                        </li>
                    ))}
                </ul>
                <Switch>
                    <Route exact path={path}>
                        <Redirect to={{pathname: `${path}/${firstRoute}`, state: { pageTitle: 'Reports'}}} />
                    </Route>
                    <Route exact path={`${path}/audit-trail`}>
                        <Redirect to={{pathname: `${path}/audit-trail/recent`, state: { pageTitle: 'Reports'}}} />
                    </Route>
                    {this.canReadAuditTrail && 
                        <Route path={`${path}/audit-trail/:status(recent|all)`}>
                            <AuditTrail />
                        </Route>
                    }
                    <Route exact path={`${path}/liabilty-tracker`}>
                        <Redirect to={`${path}/liabilty-tracker/recent`} />
                    </Route>
                    {this.canReadLiabilityTracker && 
                        <Route path={`${path}/liabilty-tracker/:status(recent|all)`}>
                            <LiabilityTracker />
                        </Route>
                    }
                    {this.canReadCTR && 
                        <Route path={`${path}/ctr-report`}>
                            <CTR />
                        </Route>
                    }
                    {this.canReadPPPI && 
                        <Route path={`${path}/ppi`}>
                            <PPI />
                        </Route>
                    }
                    {this.canDownloadUserReport && 
                        <Route path={`${path}/user-information`}>
                            <UserInformation />
                        </Route>
                    }
                </Switch>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        permissions: state.user.permissions,
    }
}

export default withRouter(
    connect(mapStateToProps, null)(Reports)
);
