export const columns = [
  {
    name: "Name",
    id: 1,
  },
  {
    name: "title",
    id: 2,
  },
  {
    name: "Description",
    id: 3,
  },
  {
    name: "Date",
    id: 4,
  },
  {
    name: "Status",
    id: 5,
  },
];
