module.exports = {
  CLEAR_DATA: "support/CLEAR_DATA",
  CLEAR_ERROR: "support/CLEAR_ERROR",
  GET_TICKETS_REQUEST: "support/GET_TICKETS_REQUEST",
  GET_TICKETS_ERROR: "support/GET_TICKETS_ERROR",
  GET_TICKETS_SUCCESS: "support/GET_TICKETS_SUCCESS",
  GET_SINGLE_TICKET_REQUEST: "support/GET_SINGLE_TICKET_REQUEST",
  GET_SINGLE_TICKET_ERROR: "support/GET_SINGLE_TICKET_ERROR",
  GET_SINGLE_TICKET_SUCCESS: "support/GET_SINGLE_TICKET_SUCCESS",
  SEARCH_TICKETS_REQUEST: "support/SEARCH_TICKETS_REQUEST",
  SEARCH_TICKETS_ERROR: "support/SEARCH_TICKETS_ERROR",
  SEARCH_TICKETS_SUCCESS: "support/SEARCH_TICKETS_SUCCESS",
  SEND_MESSAGE_REQUEST: "support/SEND_MESSAGE_REQUEST",
  SEND_MESSAGE_ERROR: "support/SEND_MESSAGE_ERROR",
  SEND_MESSAGE_SUCCESS: "support/SEND_MESSAGE_SUCCESS",
  UPDATE_TICKET_STATUS_REQUEST: "support/UPDATE_TICKET_STATUS_REQUEST",
  UPDATE_TICKET_STATUS_ERROR: "support/UPDATE_TICKET_STATUS_ERROR",
  UPDATE_TICKET_STATUS_SUCCESS: "support/UPDATE_TICKET_STATUS_SUCCESS",
  GET_USER_GUIDES_REQUEST: "support/GET_USER_GUIDES_REQUEST",
  GET_USER_GUIDES_ERROR: "support/GET_USER_GUIDES_ERROR",
  GET_USER_GUIDES_SUCCESS: "support/GET_USER_GUIDES_SUCCESS",
  GET_USER_GUIDE_BY_STATUS_REQUEST: "support/GET_USER_GUIDE_BY_STATUS_REQUEST",
  GET_USER_GUIDE_BY_STATUS_ERROR: "support/GET_USER_GUIDE_BY_STATUS_ERROR",
  GET_USER_GUIDE_BY_STATUS_SUCCESS: "support/GET_USER_GUIDE_BY_STATUS_SUCCESS",
  UPDATE_USER_GUIDES_REQUEST: "support/UPDATE_USER_GUIDES_REQUEST",
  UPDATE_USER_GUIDES_ERROR: "support/UPDATE_USER_GUIDES_ERROR",
  UPDATE_USER_GUIDES_SUCCESS: "support/UPDATE_USER_GUIDES_SUCCESS",
  DELETE_USER_GUIDES_REQUEST: "support/DELETE_USER_GUIDES_REQUEST",
  DELETE_USER_GUIDES_ERROR: "support/DELETE_USER_GUIDES_ERROR",
  DELETE_USER_GUIDES_SUCCESS: "support/DELETE_USER_GUIDES_SUCCESS",
  ADD_USER_GUIDES_REQUEST: "support/ADD_USER_GUIDES_REQUEST",
  ADD_USER_GUIDES_ERROR: "support/ADD_USER_GUIDES_ERROR",
  ADD_USER_GUIDES_SUCCESS: "support/ADD_USER_GUIDES_SUCCESS",
  GET_SINGLE_USER_GUIDES_REQUEST: "support/GET_SINGLE_USER_GUIDES_REQUEST",
  GET_SINGLE_USER_GUIDES_ERROR: "support/GET_SINGLE_USER_GUIDES_ERROR",
  GET_SINGLE_USER_GUIDES_SUCCESS: "support/GET_SINGLE_USER_GUIDES_SUCCESS",
  ADD_GUIDE_CATEGORY_REQUEST: "support/ADD_GUIDE_CATEGORY_REQUEST",
  ADD_GUIDE_CATEGORY_ERROR: "support/ADD_GUIDE_CATEGORY_ERROR",
  ADD_GUIDE_CATEGORY_SUCCESS: "support/ADD_GUIDE_CATEGORY_SUCCESS",
  GET_GUIDE_CATEGORY_BY_STATUS_REQUEST: "support/GET_GUIDE_CATEGORY_BY_STATUS_REQUEST",
  GET_GUIDE_CATEGORY_BY_STATUS_ERROR: "support/GET_GUIDE_CATEGORY_BY_STATUS_ERROR",
  GET_GUIDE_CATEGORY_BY_STATUS_SUCCESS: "support/GET_GUIDE_CATEGORY_BY_STATUS_SUCCESS",
  UPDATE_GUIDE_CATEGORY_STATUS_REQUEST: "support/UPDATE_GUIDE_CATEGORY_STATUS_REQUEST",
  UPDATE_GUIDE_CATEGORY_STATUS_ERROR: "support/UPDATE_GUIDE_CATEGORY_STATUS_ERROR",
  UPDATE_GUIDE_CATEGORY_STATUS_SUCCESS: "support/UPDATE_GUIDE_CATEGORY_STATUS_SUCCESS",
  REMOVE_GUIDE_CATEGORY_REQUEST: "support/REMOVE_GUIDE_CATEGORY_REQUEST",
  REMOVE_GUIDE_CATEGORY_ERROR: "support/REMOVE_GUIDE_CATEGORY_ERROR",
  REMOVE_GUIDE_CATEGORY_SUCCESS: "support/REMOVE_GUIDE_CATEGORY_SUCCESS",
  UPDATE_GUIDE_CATEGORY_REQUEST: "support/UPDATE_GUIDE_CATEGORY_REQUEST",
  UPDATE_GUIDE_CATEGORY_ERROR: "support/UPDATE_GUIDE_CATEGORY_ERROR",
  UPDATE_GUIDE_CATEGORY_SUCCESS: "support/UPDATE_GUIDE_CATEGORY_SUCCESS",
  UPDATE_GUIDE_STATUS_REQUEST: "support/UPDATE_GUIDE_STATUS_REQUEST",
  UPDATE_GUIDE_STATUS_ERROR: "support/UPDATE_GUIDE_STATUS_ERROR",
  UPDATE_GUIDE_STATUS_SUCCESS: "support/UPDATE_GUIDE_STATUS_SUCCESS",
}