import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, NavLink, Switch, Route, Redirect } from "react-router-dom";
import dayjs from "dayjs";
import {
  exportDemographics,
  exportInvestment,
  exportUserGrowth,
} from "@/store/analytics/usersAnalyticsAction";
import Demographics from "./Demographics";
import InvestmentValue from "./InvestmentValue";
import UserGrowth from "./UserGrowth";
import ReloadButton from "@/components/ReloadButton";
import ExportDropdown from "@/components/ExportDropdown";
import "./style.scss";

const twelveMonthsAgo = new Date().setMonth(new Date().getMonth() - 12);
class UsersAnalytics extends Component {
  state = {
    demographicsAgeDate: new Date(),
    demographicsGenderDate: new Date(),
    investmentAgeDates: [twelveMonthsAgo, new Date()],
    investmentGenderDates: [twelveMonthsAgo, new Date()],
    userGrowthDates: [twelveMonthsAgo, new Date()],
    viewToExport: "default",
  };

  demographicsExportOptions = [
    { name: "All Pages", value: "default" },
    { name: "Age Demographics", value: "age" },
    { name: "Gender Demographics", value: "gender" },
  ];

  investmentValueExportOptions = [
    { name: "All Pages", value: "default" },
    { name: "Investment by age", value: "age" },
    { name: "Investment by gender", value: "gender" },
  ];

  render() {
    const {
      match: { path },
    } = this.props;
    const { pathname } = this.props.location;

    const {
      demographicsAgeDate,
      demographicsGenderDate,
      investmentAgeDates,
      investmentGenderDates,
      userGrowthDates,
      viewToExport,
    } = this.state;

    const demographicsAgeExportDate =
      viewToExport === "gender"
        ? null
        : demographicsAgeDate
        ? dayjs(demographicsAgeDate).format("YYYY-MM-DD")
        : null;
    const demographicsGenderExportDate =
      viewToExport === "age"
        ? null
        : demographicsGenderDate
        ? dayjs(demographicsGenderDate).format("YYYY-MM-DD")
        : null;

    const isDemographic = pathname.includes("demographics");
    const isInvestment = pathname.includes("investment");
    const isUserGrowth = pathname.includes("user-growth");

    return (
      <div className="setup-inner">
        <div className="setup-inner__top">
          <ul className="setup-inner__nav">
            <li className="setup-nav__item">
              <NavLink
                exact
                to={{ pathname: `${path}/demographics` }}
                activeClassName="setup-nav__item--active"
              >
                Demographics
              </NavLink>
            </li>
            <li className="setup-nav__item">
              <NavLink
                to={{ pathname: `${path}/investment-value` }}
                activeClassName="setup-nav__item--active"
              >
                Investment Value
              </NavLink>
            </li>
            <li className="setup-nav__item">
              <NavLink
                to={{ pathname: `${path}/user-growth` }}
                activeClassName="setup-nav__item--active"
              >
                User Growth
              </NavLink>
            </li>
          </ul>

          {isDemographic && (
            <div className="right-menus">
              <div className="users-reload">
                <ReloadButton />
              </div>
              <ExportDropdown
                module="user-analytics"
                status="demographics"
                excludeCSV
                includeJpeg
                downloadHandler={(...args) =>
                  this.props.exportDemographics(
                    demographicsAgeExportDate,
                    demographicsGenderExportDate,
                    viewToExport,
                    ...args
                  )
                }
                analyticsOptions={this.demographicsExportOptions}
                onAnalyticsViewSelect={(view) =>
                  this.setState({ viewToExport: view })
                }
              />
            </div>
          )}
          {isInvestment && (
            <div className="right-menus">
              <div className="users-reload">
                <ReloadButton />
              </div>
              <ExportDropdown
                module="user-analytics"
                status="investment"
                excludeCSV
                includeJpeg
                downloadHandler={(...args) =>
                  this.props.exportInvestment(
                    dayjs(investmentAgeDates[0]).format("YYYY-MM-DD"),
                    dayjs(investmentAgeDates[1]).format("YYYY-MM-DD"),
                    dayjs(investmentGenderDates[0]).format("YYYY-MM-DD"),
                    dayjs(investmentGenderDates[1]).format("YYYY-MM-DD"),
                    viewToExport,
                    ...args
                  )
                }
                analyticsOptions={this.investmentValueExportOptions}
                onAnalyticsViewSelect={(view) =>
                  this.setState({ viewToExport: view })
                }
              />
            </div>
          )}
          {isUserGrowth && (
            <div className="right-menus">
              <div className="users-reload">
                <ReloadButton />
              </div>
              <ExportDropdown
                module="growth-analytics"
                status="users"
                excludeCSV
                includeJpeg
                downloadHandler={(...args) =>
                  this.props.exportUserGrowth(
                    dayjs(userGrowthDates[0]).format("YYYY-MM-DD"),
                    dayjs(userGrowthDates[1]).format("YYYY-MM-DD"),
                    ...args
                  )
                }
              />
            </div>
          )}
        </div>
        <div>
          <Switch>
            <Route exact path={path}>
              <Redirect to={{ pathname: `${path}/demographics` }} />
            </Route>
            <Route exact path={`${path}/demographics`}>
              <Demographics
                ageDate={demographicsAgeDate}
                genderDate={demographicsGenderDate}
                handleAgeDateChange={(date) =>
                  this.setState({ demographicsAgeDate: date })
                }
                handleGenderDateChange={(date) =>
                  this.setState({ demographicsGenderDate: date })
                }
              />
            </Route>
            <Route exact path={`${path}/investment-value`}>
              <InvestmentValue
                ageDates={investmentAgeDates}
                genderDates={investmentGenderDates}
                handleAgeDateChange={(date) =>
                  this.setState({ investmentAgeDates: date })
                }
                handleGenderDateChange={(date) =>
                  this.setState({ investmentGenderDates: date })
                }
              />
            </Route>
            <Route exact path={`${path}/user-growth`}>
              <UserGrowth
                dates={userGrowthDates}
                handleDateChange={(date) =>
                  this.setState({ userGrowthDates: date })
                }
              />
            </Route>
          </Switch>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    exportDemographics: (ageDate, genderDate, viewToExport, module, format) =>
      dispatch(
        exportDemographics(module, format, viewToExport, ageDate, genderDate)
      ),
    exportInvestment: (
      ageStartDate,
      ageEndDate,
      genderStartDate,
      genderEndDate,
      viewToExport,
      module,
      format
    ) =>
      dispatch(
        exportInvestment(
          module,
          format,
          viewToExport,
          ageStartDate,
          ageEndDate,
          genderStartDate,
          genderEndDate
        )
      ),
    exportUserGrowth: (startDate, endDate, module, format) =>
      dispatch(exportUserGrowth(module, format, startDate, endDate)),
  };
};

export default withRouter(connect(null, mapDispatchToProps)(UsersAnalytics));
