import React, { Component } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { EditorState, ContentState } from "draft-js";
import "./style.scss";

class TextEditor extends Component {
  constructor(props) {
    super(props);

    const { defaultValue } = this.props;

    this.state = {
      editorState: false,
      uploadImages: [],
    };

    if (defaultValue) {
      const contentBlock = htmlToDraft(defaultValue);

      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );

      const editorState = EditorState.createWithContent(contentState);

      this.state.editorState = editorState;
    }
  }

  onChange = (editorState) => {
    const html = draftToHtml(editorState);

    //check if editor is empty
    if (editorState.blocks.length === 1 && editorState.blocks[0].text === "") {
      this.props.onChange && this.props.onChange("");
      return;
    }

    this.props.onChange && this.props.onChange(html);
  };

  uploadImageCallBack = (file) => {
    // long story short, every time we upload an image, we
    // need to save it to the state so we can get it's data
    // later when we decide what to do with it.

    // Make sure you have a uploadImages: [] as your default state
    let uploadedImages = [];

    const imageObject = {
      file: file,
      localSrc: URL.createObjectURL(file),
    };

    uploadedImages.push(imageObject);

    this.setState({ uploadedImages: uploadedImages });

    // We need to return a promise with the image src
    // the img src we will use here will be what's needed
    // to preview it in the browser. This will be different than what
    // we will see in the index.md file we generate.
    return new Promise((resolve, reject) => {
      resolve({ data: { link: imageObject.localSrc } });
    });
  };

  render() {
    const { editorState } = this.state;
    const {
      error,
      toolbarOptions,
      toolbarConfigs,
      height = "500px",
      customClass = "",
    } = this.props;

    return (
      <div id="text-editor">
        <Editor
          toolbar={{
            options: toolbarOptions ?? [
              "history",
              "inline",
              "blockType",
              "fontSize",
              "fontFamily",
              "link",
              "image",
              "list",
              "textAlign",
              "colorPicker",
              "embedded",
              "emoji",
            ],
            image: {
              className: "toolbar-emoji",
              uploadCallback: this.uploadImageCallBack,
              previewImage: true,
              alt: { present: true, mandatory: false },
              inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
            },
            ...toolbarConfigs,
          }}
          editorStyle={{
            height,
            padding: "12px",
            backgroundColor: "white",
            fontSize: "14px",
          }}
          toolbarStyle={{
            backgroundColor: "rgba(229, 229, 229, .6)",
          }}
          wrapperStyle={{
            border: error
              ? "0.5px solid rgba(240, 55, 23, 0.45)"
              : "0.5px solid rgba(69, 101, 255, .45)",
            borderRadius: "6px",
            overflow: "hidden",
          }}
          onChange={this.onChange}
          defaultEditorState={editorState}
          placeholder="Enter text here"
          wrapperClassName={customClass}
        />
      </div>
    );
  }
}

export default TextEditor;
