import React, { Component } from "react";
import codes from './countryCodes';
import './style.scss';

class PhoneNumberInput extends Component {
    render() {
        return(
            <div className="phone-input">
                <select
                    // required
                    className="form-control phone-input__child"
                    name="countryCode"
                    defaultValue={this.props.defaultCountryCode}
                    value={this.props.countryCodeValue}
                    onChange={this.props.onInputChange}
                    disabled={this.props.isDisabled}
                >
                    {codes.map(code => <option value={code.dial_code} key={code.name}>{code.dial_code} {code.name}</option>)}
                </select>
                <input 
                    type="text" 
                    className="form-control panel-input phone-input__child" 
                    placeholder="Phone number" 
                    id={this.props.id ?? 'phone'}
                    name={this.props.name ?? 'phone'}
                    defaultValue={this.props.defaultPhone}
                    value={this.props.phoneValue}
                    onChange={this.props.onInputChange}
                    autoComplete="off"
                    required
                    disabled={this.props.isDisabled}
                />
            </div>
        )
    }
}

export default PhoneNumberInput;
