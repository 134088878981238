import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getActionLoadingState } from "@/store/selectors";
import {
  getPendingFDInvestments,
  updateFDInvestmentStatus,
  removeInvestment,
} from "@/store/investments/actions";
import actionTypes from "@/store/investments/actionTypes";
import Emptystate from "@/components/Emptystate";
import RightPanel from "@/components/RightPanel";
import Pagination from "@/components/Pagination";
import ConfirmationBox from "@/components/ConfirmationBox";
import "./style.scss";
import dayjs from "dayjs";
import { currency } from "@/utils";

class PendingApproval extends React.Component {
  state = {
    selectedItem: null,
    open: false,
    showConfirmModal: false,
    showConfirmApprovalModal: false,
    itemId: "",
    approveItem: "",
  };

  componentDidMount() {
    this.props.getPendingInvestments();
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({
        open: false,
        showConfirmModal: false,
        showConfirmApprovalModal: false,
      });
    }
    if (this.props.searchTerm !== prevProps.searchTerm) {
      this.props.getPendingInvestments(10, 1, this.props.searchTerm);
    }
  }

  handleSelectItem = (itemId) => {
    const { data } = this.props;
    const foundItem = data?.investments?.data.find(
      (item) => item.id === itemId
    );

    this.setState({ selectedItem: foundItem }, () =>
      this.setState({ open: true })
    );
  };

  showConfirmApproval = (id) => {
    this.setState({ showConfirmApprovalModal: true, approveItem: id });
  };

  handleApprove = (id) => {
    const { approveItem } = this.state;
    this.props.updateInvestmentStatus(approveItem, { status: "active" });
  };

  showConfirm = (id) => {
    this.setState({ showConfirmModal: true, itemId: id });
  };

  handleReject = () => {
    const { itemId } = this.state;
    this.props.removeInvestment(itemId);
  };

  toggleModal = () => {
    this.setState({ showConfirmModal: !this.state.showConfirmModal });
  };

  stripHtml = (html) => {
    const decodedHtml = html.replace(/(&lt;|<)[^>]*(&gt;|>)/g, "");
    return decodedHtml.replace(/<[^>]*>/g, "");
  };

  render() {
    const { loading, data, updateLoading, rejectLoading, searchTerm } =
      this.props;
    const { selectedItem, showConfirmModal, showConfirmApprovalModal } =
      this.state;
    const pendingData = data?.investments || data?.services;

    return (
      <div className="pending-approval-investments">
        <RightPanel
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
        >
          <h4 className="panel-heading">
            {selectedItem && selectedItem.offerName}
          </h4>
          <div className="text-right border-bottom pb-5">
            <img
              src={selectedItem && selectedItem.icon}
              className="img-fluid"
              alt="user-img"
            />
          </div>
          <div className="d-flex justify-content-between mt-5">
            <p>Offer name</p>
            <p>
              <b>{selectedItem && selectedItem.offerName}</b>
            </p>
          </div>
          <div className="d-flex justify-content-between">
            <p>Offer opening date</p>
            <p>
              <b>
                {selectedItem &&
                  dayjs(selectedItem.openingData).format("D MMM YYYY")}
              </b>
            </p>
          </div>

          <div className="d-flex justify-content-between">
            <p>Offer closing date</p>
            <p>
              <b>
                {selectedItem &&
                  dayjs(selectedItem.closingDate).format("D MMM YYYY")}
              </b>
            </p>
          </div>
          <div className="d-flex justify-content-between">
            <p>Status</p>
            <p className={`status--${selectedItem && selectedItem.status}`}>
              <b>{selectedItem && selectedItem.status}</b>
            </p>
          </div>
          <div className="d-flex justify-content-between">
            <p>Tenor</p>
            <p>
              <b>{selectedItem && selectedItem.tenor}</b>
            </p>
          </div>
          <div className="d-flex justify-content-between">
            <p>Category</p>
            <p>
              <b>{selectedItem && selectedItem.category}</b>
            </p>
          </div>
          <div className="d-flex justify-content-between">
            <p>Interest Rate</p>
            <p>
              <b>{selectedItem && selectedItem.rate}</b>
            </p>
          </div>
          <div className="d-flex justify-content-between">
            <p>Pre Liquidation</p>
            <p>
              <b>
                {selectedItem && selectedItem.liquidationPenalty === 1
                  ? "Yes"
                  : "No"}
              </b>
            </p>
          </div>

          <div className="d-flex justify-content-between">
            <p>Offer Amount</p>
            <p>
              <b>{selectedItem && `₦${currency(selectedItem.offerAmount)}`}</b>
            </p>
          </div>
          <div className="d-flex flex-col justify-content-between">
            <p>Temp </p> <br />
            <p className="flex flex-col">
              <b>
                {selectedItem && selectedItem.temp
                  ? (() => {
                      try {
                        const parsedTemp = JSON.parse(selectedItem.temp);
                        return (
                          <div>
                            {Object.entries(parsedTemp).map(
                              ([key, value], index) => (
                                <div key={index}>
                                  {key !== "service_id" ? (
                                    <span>
                                      <strong>{key}:</strong> {value}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              )
                            )}
                          </div>
                        );
                      } catch (e) {
                        return selectedItem.temp; // Display as-is if parsing fails
                      }
                    })()
                  : "N/A"}
              </b>
            </p>
          </div>

          <div className="d-flex justify-content-between">
            <p>Tags</p>
            <p>
              {/*<b>{selectedItem && JSON.parse(selectedItem.tags).join(", ")}</b>*/}
            </p>
          </div>
          <div className="d-flex justify-content-between">
            <p>Summary</p>
            <p className="text-right">
              <b>{selectedItem && this.stripHtml(selectedItem.summary)}</b>
            </p>
          </div>
          <div className="d-flex justify-content-between">
            <p>Description</p>
            <p className="text-right">
              <b>{selectedItem && this.stripHtml(selectedItem.description)}</b>
            </p>
          </div>
          <div className="d-flex justify-content-between mt-4">
            <button
              className="btn btn-sm w-50 mr-2 btn-white color-red"
              onClick={() => this.showConfirm(selectedItem.id)}
            >
              Reject
            </button>
            <button
              className="btn btn-sm w-50 ml-2 btn-primary"
              onClick={() => this.showConfirmApproval(selectedItem.id)}
            >
              Approve
              {updateLoading && (
                <div className="spinner-border spinner-border-white spinner-border-sm ml-2"></div>
              )}
            </button>
          </div>
        </RightPanel>

        <ConfirmationBox
          open={showConfirmModal}
          closeHandler={() => this.setState({ showConfirmModal: false })}
          title="Reject Investment"
          question="Are you sure you want to reject this investment?"
          action={this.handleReject}
          loading={rejectLoading}
        />
        <ConfirmationBox
          open={showConfirmApprovalModal}
          closeHandler={() =>
            this.setState({ showConfirmApprovalModal: false })
          }
          title="Approve Investment"
          question="Are you sure you want to approve this investment?"
          action={this.handleApprove}
          loading={updateLoading}
        />
        {loading ? (
          <div className="text-center">
            <div className="spinner-border text-primary ml-auto mr-auto"></div>
          </div>
        ) : (
          data &&
          (pendingData?.data.length > 0 ? (
            <>
              <Pagination
                totalPages={pendingData.lastPage}
                page={pendingData.page}
                limit={pendingData.perPage}
                changePageHandler={(page, limit) =>
                  this.props.getPendingInvestments(limit, page, searchTerm)
                }
              />
              <div className="table-overflow">
                <div className="setup-inner__main setup-inner__expand">
                  <table className="custum-table custum-table__long">
                    <tbody>
                      <tr>
                        {/*<th></th>*/}
                        <th>ID</th>
                        <th>Offer Name</th>
                        <th>Offer opening date</th>
                        <th>Offer Closure Date</th>
                        <th>Tenor</th>
                        <th>Summary</th>
                        <th>Description</th>
                        <th>Category</th>
                        <th>Interest Rate</th>
                        <th>Pre Liquidation</th>
                        <th>Status</th>
                        <th className="!w-max">Actions</th>
                      </tr>
                      {pendingData.data?.map((investment) => (
                        <tr key={investment.id}>
                          {/*<td className="row-checkbox">
                            <input
                                type="checkbox"
                            onChange={(e) =>
                              this.props.onCheckRow(
                                e.target.checked,
                                investment.id
                              )
                            }
                          />
                        </td>*/}
                          <td>{investment.id}</td>
                          <td
                            onClick={() => this.handleSelectItem(investment.id)}
                          >
                            <span className="color-dark-blue cursor-pointer">
                              {investment.offerName}
                            </span>
                          </td>
                          <td>
                            {dayjs(investment.openingData).format("D MMM YYYY")}
                          </td>
                          <td>
                            {dayjs(investment.closingDate).format("D MMM YYYY")}
                          </td>
                          <td>{investment.tenor}</td>
                          <td
                            className="h-12 overflow-hidden text-ellipsis !whitespace-pre truncate"
                            // style={{
                            //   whiteSpace: "normal",
                            //   maxWidth: "25rem",
                            //   "white-space": "nowrap",
                            //   overflow: "hidden",
                            //   "text-overflow": "ellipsis",
                            // }}
                          >
                            {investment.summary &&
                              this.stripHtml(investment.summary)}
                          </td>
                          <td
                            className="h-12 overflow-hidden text-ellipsis !whitespace-pre truncate"
                            // style={{
                            //   whiteSpace: "normal",
                            //   maxWidth: "25rem",
                            //   "white-space": "nowrap",
                            //   overflow: "hidden",
                            //   "text-overflow": "ellipsis",
                            // }}
                          >
                            {investment.description &&
                              this.stripHtml(investment.description)}
                          </td>
                          <td>{investment.category}</td>
                          <td>{investment.rate}</td>
                          <td>
                            {investment.liquidationPenalty === 1 ? "Yes" : "No"}
                          </td>
                          <td>
                            <span
                              className={` status status--${investment.status}`}
                            >
                              {investment.status}
                            </span>
                          </td>
                          <td
                            className="custum-table__button-cell"
                            style={{ maxWidth: "25rem" }}
                          >
                            <button
                              className="custum-table__button-blue"
                              onClick={() =>
                                this.showConfirmApproval(investment.id)
                              }
                            >
                              Approve
                            </button>
                            <button
                              className="custum-table__button-plain"
                              onClick={() => this.showConfirm(investment.id)}
                            >
                              Reject
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      fontSize: "1.4rem",
                    }}
                    className="text-center py-4"
                  >
                    Showing
                    <span style={{ fontWeight: "bold" }}>
                      {data?.investments?.data.length}
                    </span>
                    of
                    <span style={{ fontWeight: "bold" }}>
                      {data?.investments?.total}
                    </span>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <Emptystate
              title={`${
                searchTerm
                  ? "No Result Found"
                  : "No Investment Pending approval"
              }`}
              icon={require("@/assets/icons/info.svg").default}
            />
          ))
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    app: {
      investments: {
        pendingFlashDealInvestments: { data, error },
        singleInvestment,
      },
    },
  } = state;
  return {
    loading: getActionLoadingState(
      state,
      actionTypes.FETCH_ALL_PENDING_FLASH_DEALS_INVESTMENTS_REQUEST
    ),
    updateLoading: getActionLoadingState(
      state,
      actionTypes.UPDATE_FLASH_DEAL_INVESTMENT_STATUS_REQUEST
    ),
    rejectLoading: getActionLoadingState(
      state,
      actionTypes.REMOVE_INVESTMENT_REQUEST
    ),
    error,
    data,
    singleInvestmentData: singleInvestment.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPendingInvestments: (limit, page, searchTerm) =>
      dispatch(getPendingFDInvestments(limit, page, searchTerm)),
    updateInvestmentStatus: (id, payload) =>
      dispatch(updateFDInvestmentStatus(id, payload)),
    removeInvestment: (id) => dispatch(removeInvestment(id)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PendingApproval)
);
