import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { fetchFaqs, createOrEditFaq } from "@/store/setup/faqAction";
import actionTypes from "@/store/setup/actionTypes";
import { getActionLoadingState, getActionErrorState } from "@/store/selectors";
import InputError from "@/components/InputError";
import { ReactComponent as BackArrrow } from '@/assets/icons/previous-page-arrow.svg';
import { serializeErrors } from "@/utils";

class CreateFaq extends Component {
    state = {
        title: '',
        content: '',
    }

    componentDidMount() {
        if(this.isShowing('edit')) {
            const { id } = this.props.match.params;
            this.props.fetchFaqs(id).then(data => this.setContent(data.faq));
        }
    }

    setContent = ({title, content}) => this.setState({title, content});

    isShowing = (type) => {
        if(this.props.location.pathname.indexOf(type) !== -1){
            return true;
        }
        return false;
    }

    createOrEditFaq = (shouldPublish) => {
        const { title, content } = this.state;
        const { id = null } = this.props.match.params;
        this.props.createOrEditFaq({title, content, publish: shouldPublish}, id, this.props.history);
    }

    onInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    render() {
        const createErrorObject = serializeErrors(this.props.pageRequestError?.message);
        return(
            this.props.fetching ? <div className="text-center text-primary mt-5 pt-5">
                <div className="spinner-border" role="status"></div>
            </div> :
            <div className="setup-inner">
                <div className="setup-inner__top">
                    <BackArrrow 
                        className='cp-pagination__arrow--active'
                        onClick={() => this.props.history.goBack()}
                    />
                    <span 
                        className="font-md mx-3 font-weight-bold cp-pagination__arrow--active mr-auto"
                        onClick={() => this.props.history.goBack()}
                    >Back</span>
                    <button className="faq__button faq__button--blue" onClick={() => this.createOrEditFaq(true)}>
                         Publish
                    </button>
                    <button className="faq__button faq__button--light" onClick={() => this.createOrEditFaq(false)}>
                         Save
                    </button>
                    <button className="faq__button faq__button--light" onClick={() => this.props.history.goBack()}>
                         Cancel
                    </button>
                </div>
                <div>
                    <input
                        name="title"
                        value={this.state.title}
                        onChange={this.onInputChange}
                        type="text"
                        className={`faq__input faq__input--title ${createErrorObject.title ? 'error-input' : ''}`}
                        placeholder="Enter topic..."
                        required
                        autoComplete="off"
                    />
                    <InputError error={createErrorObject.title} />
                    <textarea 
                        name="content"
                        value={this.state.content}
                        onChange={this.onInputChange}
                        cols="30" rows="22"
                        className={`faq__input faq__input--response ${createErrorObject.content ? 'error-input' : ''}`}
                        placeholder="Enter response here..."
                        required
                    />
                    <InputError error={createErrorObject.content} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetching: getActionLoadingState(state, actionTypes.GET_FAQ_REQUEST),
        pageRequestError: getActionErrorState(state, actionTypes.FAQ_PAGE_ERROR),

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createOrEditFaq: (payload, editId, history) => dispatch(createOrEditFaq(payload, editId, history)),
        fetchFaqs: (id) => dispatch(fetchFaqs(null, 1, 1, id)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CreateFaq)
);
