import React, { Component } from "react";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import { ReactComponent as EditIcon } from "@/assets/icons/filter.svg";
import "./style.scss";
import "react-datepicker/dist/react-datepicker.css";

class DateRangeFilter extends Component {
  state = {
    from: this.props.startDate ?? "",
    to: this.props.endDate ?? "",
    showFilter: this.props.startDate ? true : false,
    hasFilterdOnce: this.props.startDate ? true : false,
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { to, from } = this.state;
    this.setState({ hasFilterdOnce: true });
    this.props.filterHandler(from, to);
  };

  showFilter = () => {
    this.setState({ showFilter: true });
  };

  cancelFilter = () => {
    if (this.state.hasFilterdOnce) {
      return this.props.filterHandler(null, null);
    }
    this.setState({
      showFilter: false,
      from: "",
      to: "",
    });
  };

  setDate = (name, value) => {
    this.setState({ [name]: dayjs(value).format("YYYY-MM-DD") });
  };

  render() {
    const { showFilter, to, from } = this.state;
    return (
      <div className="d-flex align-items-center range-box">
        {showFilter ? (
          <form className="range-filter" onSubmit={this.onSubmit}>
            <div className="range-filter__box">
              <img
                src={require("@/assets/icons/calendar.svg").default}
                alt="arrow down"
                className="ml-auto mr-2"
              />
              <div className="range-filter__innner">
                <label htmlFor="from" className="range-filter__label">
                  From
                </label>
                <DatePicker
                  className="range-filter__input"
                  dayClassName={() => "react-datepicker-dates"}
                  selected={from ? new Date(from) : null}
                  onChange={(date) => this.setDate("from", date)}
                  id="from"
                  required
                  placeholderText="dd/mm/yyyy"
                  autoComplete="off"
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <div className="range-filter__innner">
                <label htmlFor="to" className="range-filter__label">
                  To
                </label>
                <DatePicker
                  className="range-filter__input"
                  dayClassName={() => "react-datepicker-dates"}
                  minDate={from ? new Date(from) : null}
                  selected={to ? new Date(to) : null}
                  onChange={(date) => this.setDate("to", date)}
                  id="to"
                  required
                  placeholderText="dd/mm/yyyy"
                  autoComplete="off"
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </div>
            <button className="range-filter__button setup-inner__button px-4">
              Apply
            </button>
            <button
              type="button"
              className="range-filter__button setup-inner__button px-4"
              onClick={this.cancelFilter}
            >
              Cancel
            </button>
          </form>
        ) : (
          <button
            className="setup-inner__button px-4 ml-auto"
            onClick={() => this.setState({ showFilter: true })}
          >
            <EditIcon className="setup-inner__button-img" />
            Filter by date
          </button>
        )}
      </div>
    );
  }
}

export default DateRangeFilter;
