import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter, NavLink, Route, Switch } from "react-router-dom";
import {
  fetchRoles,
  createOrEditRole,
  fetchPermissions,
  changeRoleStatus,
  deleteRole,
  rejectRole,
} from "@/store/setup/rolesAction";
import { clearError } from "@/store/ui/actions";
import actionTypes from "@/store/setup/actionTypes";
import { getActionLoadingState, getActionErrorState } from "@/store/selectors";
import { serializeErrors, hasPermission } from "@/utils";
import Dropdown from "@/components/DropdownMenu";
import Modal from "@/components/cpModal";
import Emptystate from "@/components/Emptystate";
import ExportDropdown from "@/components/ExportDropdown";
import SearchBox from "@/components/SearchBox";
import Pagination from "@/components/Pagination";
import MultipleApprovalDropdown, {
  MultipleActiveApprovalDropdown,
} from "@/components/MultipleApprovalDropdown";
import "./Role.scss";
import SingleRole from "./SingleRole";

class Roles extends Component {
  defaultState = {
    open: false,
    name: "",
    description: "",
    addedPermissions: [],
    roleId: "",
    confirmationContext: "",
    confirmationSuccess: false,
    RightPanelHeading: "",
    previewMode: false,
    shouldSearch: false,
    selectedRowsIds: [],
  };

  state = {
    ...this.defaultState,
    pendingRoles: [],
    allPermissions: [],
    searchTerm: "",
    roles: [],
    rowHide: false,
  };
  componentDidMount() {
    this.getRoles(1, 10);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ selectedRowsIds: [] });
      this.getRoles(1, 10);
    }
    if (
      this.state.searchTerm !== prevState.searchTerm &&
      this.state.shouldSearch
    ) {
      this.getRoles(1, 10, this.state.searchTerm);
    }
  }

  isShowingActiveRoles = () => {
    if (this.props.location.pathname.indexOf("pending") === -1) {
      return true;
    }
    return false;
  };

  getRoles = (page, limit, searchTerm) => {
    if (this.isShowingActiveRoles()) {
      this.props.fetchRoles(page, limit, "", searchTerm).then((roles) => {
        this.setState({ roles: roles.data });
        this.setPageData(roles, searchTerm);
      });
    } else {
      this.props
        .fetchRoles(page, limit, "pending", searchTerm)
        .then((roles) => {
          this.setState({ pendingRoles: roles.data });
          this.setPageData(roles, searchTerm);
        });
    }
  };

  setPageData = ({ lastPage, perPage, page, total }, searchTerm) => {
    this.setState({ lastPage, perPage, page, total, searchTerm });
  };

  rederActiveRoles = () => {
    const { roles, searchTerm } = this.state;
    return !roles.length ? (
      <Emptystate
        title={`${searchTerm ? "No Result Found" : "No roles"}`}
        icon={require("@/assets/icons/info.svg").default}
      />
    ) : (
      <table className="custum-table">
        <tbody>
          <tr>
            <th></th>
            <th>Role ID</th>
            <th>Role</th>
            <th>Role Description</th>
            <th></th>
          </tr>
          {roles?.map((role) => (
            <tr
              key={role.id}
              //onClick={(e) => this.onRowClick(e, role)}
              className="cursor-pointer"
            >
              <td className="row-checkbox">
                <input
                  type="checkbox"
                  onChange={(e) => this.onCheckRow(e, role.id)}
                />
              </td>
              <td>{role.id}</td>
              <td className="text-capitalize">{role.name}</td>
              <td style={{ maxWidth: "25rem", whiteSpace: "normal" }}>
                {role.description}
              </td>

              <td className="custum-table__ellipsis">
                <Dropdown
                  menu={this.dropdownmenus(role)}
                  width="117px"
                  arrow={true}
                >
                  <button className="wrapper-button">
                    <img
                      src={require("@/assets/icons/flat-ellipsis.svg").default}
                      alt="dropdown"
                    />
                  </button>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  onCheckRow = (e, rowId) => {
    const selectedRows = [...this.state.selectedRowsIds];
    if (e.target.checked) {
      selectedRows.push(rowId);
    } else {
      const uncheckedRowIndex = selectedRows.findIndex((id) => id === rowId);
      selectedRows.splice(uncheckedRowIndex, 1);
    }
    this.setState({ selectedRowsIds: selectedRows });
  };

  renderPendingRoles = () => {
    const { pendingRoles } = this.state;
    return !pendingRoles.length ? (
      <Emptystate
        title="No pending roles"
        icon={require("@/assets/icons/info.svg").default}
      />
    ) : (
      <table className="custum-table">
        <tbody>
          <tr>
            <th></th>
            <th>ID</th>
            <th>Role Name</th>
            <th>Description</th>
            <th className="actions-column">Actions</th>
          </tr>
          {pendingRoles.map((role) => (
            <tr
              key={role.id}
              onClick={(e) => this.onRowClick(e, role)}
              className="cursor-pointer"
            >
              <td className="row-checkbox">
                <input
                  type="checkbox"
                  onChange={(e) => this.onCheckRow(e, role.id)}
                />
              </td>
              <td>{role.id}</td>
              <td className="text-capitalize">{role.name}</td>
              <td style={{ maxWidth: "25rem", whiteSpace: "normal" }}>
                {role.description}
              </td>
              <td className="custum-table__button-cell">
                <button
                  className="custum-table__button-blue"
                  onClick={() =>
                    this.setConfirmationContext("approveRole", role.id)
                  }
                >
                  Approve
                </button>
                <button
                  className="custum-table__button-plain"
                  onClick={() =>
                    this.setConfirmationContext("rejectRole", role.id)
                  }
                >
                  Reject
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  dropdownmenus = (data) => {
    const canUpdate = hasPermission(this.props.permissions, "update_role");
    const canDelete = hasPermission(this.props.permissions, "delete_role");

    return [
      { name: "View", handler: (e) => this.onRowClick(e, data) },

      canUpdate
        ? { name: "Edit", handler: () => this.editRole(data.id) }
        : null,

      canDelete
        ? {
            name: "Delete",
            handler: () => this.setConfirmationContext("deleteRole", data.id),
            textClass: "text-red",
          }
        : null,
    ].filter(Boolean);
  };

  setConfirmationContext = (
    context,
    roleId,
    newStatus = "",
    isMultipleApproval
  ) => {
    this.setState({
      confirmationContext: context,
      confirmationSuccess: false,
      roleId,
      newStatus,
      isMultipleApproval,
    });
  };

  confirmationData = (id) => {
    const { newStatus } = this.state;
    const status = newStatus === "enable" ? "active" : "disabled";
    const { isMultipleApproval, selectedRowsIds } = this.state;
    const addS = isMultipleApproval && selectedRowsIds.length > 1;
    return {
      changeStatus: {
        title: `${newStatus} role`,
        question: `Are you sure you want to ${newStatus} this role?`,
        action: () => this.changeRoleStatus(status),
      },
      deleteRole: {
        title: "Delete role",
        question: "Are you sure you want to delete this role?",
        action: () => this.deleteRole(id),
      },
      approveRole: {
        title: `Approve role${addS ? "s" : ""}`,
        question: `Are you sure you want to approve ${
          addS ? "these" : "this"
        } role${addS ? "s" : ""}?`,
        action: () => this.changeRoleStatus("active"),
      },
      rejectRole: {
        title: `Reject role${addS ? "s" : ""}`,
        question: `Are you sure you want to reject ${
          addS ? "these" : "this"
        } role${addS ? "s" : ""}?`,
        action: () => this.rejectRole(),
      },
    };
  };

  removeMultipleFromList = (selectedRowsIds) => {
    const unchangedItems = this.state.roles.filter(
      (data) => !selectedRowsIds.includes(data.id)
    );
    this.setState({
      roles: unchangedItems,
      selectedRowsIds: [],
      confirmationSuccess: true,
      total: this.state.total - selectedRowsIds.length,
    });
  };

  removeFromList = (rolesKey, id) => {
    const list = this.state[rolesKey];
    const allRoles = [...list];
    const roleIndex = allRoles.findIndex((role) => role.id === id);
    if (roleIndex !== -1) allRoles.splice(roleIndex, 1);
    this.setState({ [rolesKey]: allRoles });
  };

  changeRoleStatus = (status) => {
    const { roleId, roles, total, isMultipleApproval, selectedRowsIds } =
      this.state;
    const ids = isMultipleApproval ? selectedRowsIds : [roleId];
    this.props
      .changeRoleStatus(isMultipleApproval ? selectedRowsIds : roleId, status)
      .then(() => {
        if (this.isShowingActiveRoles()) {
          const unchangedItems = roles.filter((data) => !ids.includes(data.id));
          this.setState({
            roles: unchangedItems,
            confirmationSuccess: true,
            selectedRowsIds: [],
            total: total - ids.length,
          });
        } else {
          this.removeFromList("pendingRoles", roleId);
          this.setState({ confirmationSuccess: true });
        }
      });
  };

  rejectRole = () => {
    const { roleId, isMultipleApproval, selectedRowsIds } = this.state;
    this.props
      .rejectRole(isMultipleApproval ? selectedRowsIds : roleId)
      .then(() => {
        if (isMultipleApproval)
          return this.removeMultipleFromList(selectedRowsIds);
        this.removeFromList("pendingRoles", roleId);
        this.setState({ confirmationSuccess: true });
      });
  };

  deleteRole = () => {
    const { roleId, isMultipleApproval, selectedRowsIds } = this.state;
    this.props
      .deleteRole(isMultipleApproval ? selectedRowsIds : roleId)
      .then(() => {
        if (isMultipleApproval)
          return this.removeMultipleFromList(selectedRowsIds);
        this.removeFromList("roles", roleId);
        this.setState({ confirmationSuccess: true });
      });
  };

  onRowClick = (e, role) => {
    this.fetchPermissionsIfNotExit();
    if (!["BUTTON", "INPUT"].includes(e.target.nodeName)) {
      this.setState({
        ...this.defaultState,
        open: true,
        RightPanelHeading: role.name,
        name: role.name,
        description: role.description,
        addedPermissions: role.permissions,
        roleId: role.id,
        rowHide: true,
        previewMode: true,
      });
    }
  };

  createOrEditRoleRequest = (e) => {
    e.preventDefault();
    const { name, description, roleId } = this.state;
    const payload = {
      name,
      description,
      permissions: this.state.addedPermissions.map(
        (permission) => permission.id
      ),
    };
    this.props.createOrEditRole(payload, roleId);
  };

  fetchPermissionsIfNotExit = () => {
    if (!this.state.allPermissions.length) {
      this.props.fetchPermissions().then((allPermissions) => {
        this.setState({ allPermissions });
      });
    }
  };

  createRole = () => {
    const {
      match: { path },
    } = this.props;
    this.fetchPermissionsIfNotExit();
    this.setState({
      ...this.defaultState,
      rowHide: true,
      open: true,
      RightPanelHeading: "Create new role",
      previewMode: false,
    });
  };
  goBack = () => {
    this.props.history.push("/dashboard/setup/roles");
  };

  editRole = (id) => {
    const {
      match: { path },
    } = this.props;
    const roleInfo = this.state.roles.find((role) => role.id === id);
    this.props.history.push(`${path}/${id}`);
    this.fetchPermissionsIfNotExit();
    this.setState({
      name: roleInfo.name,
      description: roleInfo.description,
      addedPermissions: roleInfo.permissions,
      roleId: id,
      open: true,
      RightPanelHeading: "Edit role",
      previewMode: false,
      rowHide: true,
    });
    this.goBack();
  };

  addPermission = (newPermission) => {
    const { addedPermissions } = this.state;
    //check if position is already selected if yes do nothing
    if (
      addedPermissions.find(
        (permission) => permission.name === newPermission.name
      )
    )
      return;
    const currentPermissions = [...addedPermissions, newPermission];
    this.setState({ addedPermissions: currentPermissions });
  };

  removePermission = (id) => {
    const { addedPermissions } = this.state;
    const newPermissions = addedPermissions.filter((p) => p.id !== id);
    this.setState({
      addedPermissions: newPermissions,
    });
  };
  multipleReject = () => {
    this.setConfirmationContext("rejectRole", null, null, true);
    this.reload();
  };
  multipleApprove = () => {
    this.setConfirmationContext("approveRole", null, "active", true);
    this.reload();
  };
  reload = () => {
    setTimeout(() => {
      window.location.reload();
    }, 5000);
  };
  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  closePanel = (e) => {
    e.preventDefault();
    this.setState({ open: false });
    this.props.clearError();
  };

  goPrevious = () => {
    this.setState({
      rowHide: false,
    });
  };
  render() {
    const {
      match: { path },
      pageRequestError,
      confirmationLoading,
      permissions,
      fetching,
    } = this.props;
    let {
      confirmationContext,
      confirmationSuccess,
      roleId,
      searchTerm,
      addedPermissions,
    } = this.state;
    const { lastPage, page, perPage, total } = this.state;
    const prompt = this.confirmationData()[confirmationContext];
    const canApprove = hasPermission(permissions, "approve_role");
    const createErrorObject = serializeErrors(pageRequestError?.message);
    const canCreate = hasPermission(permissions, "create_role");
    const isShowingActiveRoles = this.isShowingActiveRoles();
    const showMultiAprrovalDropdown = this.state.selectedRowsIds.length > 0;

    return (
      <div id="role-setup" className="setup-inner setup-inner--light">
        {this.state.rowHide ? (
          <SingleRole
            goPrevious={this.goPrevious}
            createOrEdit={this.createOrEditRoleRequest}
            name={this.state.name}
            description={this.state.description}
            onInputChange={this.onInputChange}
            addedPermissions={addedPermissions}
            allPermissions={this.state.allPermissions}
            previewMode={this.state.previewMode}
            editRole={() => this.editRole(roleId)}
            onRejectClick={() =>
              this.setConfirmationContext("rejectRole", roleId)
            }
            onApproveClick={() =>
              this.setConfirmationContext("approveRole", roleId)
            }
            onEditClick={() => {
              this.setState({ previewMode: false });
            }}
            removePermission={this.removePermission}
            addPermission={this.addPermission}
            createErrorObject={createErrorObject}
          />
        ) : (
          <div className="setup-inner__top">
            <ul className="setup-inner__nav">
              <li className="setup-nav__item">
                <NavLink
                  exact
                  to={{ pathname: `${path}`, state: { pageTitle: "Setup" } }}
                  activeClassName="setup-nav__item--active"
                >
                  Roles
                </NavLink>
              </li>
              {canApprove && (
                <li className="setup-nav__item">
                  <NavLink
                    to={{
                      pathname: `${path}/pending`,
                      state: { pageTitle: "Setup" },
                    }}
                    activeClassName="setup-nav__item--active"
                  >
                    Pending Approval
                  </NavLink>
                </li>
              )}
            </ul>
            <SearchBox
              placeholder="Search"
              handleSearch={(searchTerm) =>
                this.setState({ searchTerm, shouldSearch: true })
              }
              isActiveSearch={Boolean(searchTerm)}
              key={this.props.location.pathname}
            />
            {!isShowingActiveRoles && showMultiAprrovalDropdown && (
              <MultipleApprovalDropdown
                approve={this.multipleApprove}
                reject={this.multipleReject}
              />
            )}
            {isShowingActiveRoles && showMultiAprrovalDropdown && (
              <MultipleActiveApprovalDropdown
                delete={() =>
                  this.setConfirmationContext("deleteRole", null, null, true)
                }
              />
            )}
            {total > 0 && (
              <ExportDropdown
                module="roles"
                status={isShowingActiveRoles ? "approved" : "pending-approval"}
                hasMoreThanOnePage={lastPage > 1}
              />
            )}
            {isShowingActiveRoles && canCreate && (
              <button className="setup-inner__button" onClick={this.createRole}>
                <img
                  src={require("@/assets/icons/plus.svg").default}
                  alt="plus icon"
                  className="setup-inner__button-img"
                />
                Create New Role
              </button>
            )}
          </div>
        )}

        <Modal
          open={confirmationContext}
          outsideClose={confirmationSuccess}
          close={() => this.setState({ confirmationContext: "" })}
        >
          <div className="confirmation-modal text-center">
            {confirmationSuccess ? (
              <Fragment>
                <img
                  src={require("@/assets/icons/pace-success.svg").default}
                  className="confirmation-modal__icon"
                  alt="success icon"
                />
                <p className="text-small text-bold">Successful</p>
              </Fragment>
            ) : (
              <Fragment>
                <h2 className="confirmation-modal__heading">{prompt?.title}</h2>
                <p className="confirmation-modal__question">
                  {prompt?.question}
                </p>
              </Fragment>
            )}
            {!confirmationSuccess && (
              <div className="dual-button-box">
                <button
                  className="cp-button-blue confirmation-modal__button"
                  onClick={() => this.setState({ confirmationContext: "" })}
                >
                  Cancel
                </button>
                <button
                  className="cp-button-blue confirmation-modal__button"
                  onClick={prompt?.action}
                >
                  {prompt?.title} 
                  {confirmationLoading && (
                    <div className="spinner-border spinner-border-white spinner-border-sm ml-2 mb-2"></div>
                  )}
                </button>
              </div>
            )}
          </div>
        </Modal>
        {fetching ? (
          <div className="text-center text-primary">
            <div className="spinner-border" role="status"></div>
          </div>
        ) : (
          <>
            {total > 0 && !this.state.rowHide && (
              <Pagination
                totalPages={lastPage}
                page={page}
                limit={perPage}
                changePageHandler={(page, limit) =>
                  this.getRoles(page, limit, searchTerm)
                }
              />
            )}
            {this.state.rowHide ? null : (
              <div className="setup-inner__main">
                <Switch>
                  <Route
                    exact
                    path={path}
                    render={() => this.rederActiveRoles()}
                  />
                  {canApprove && (
                    <Route
                      exact
                      path={`${path}/pending`}
                      render={() => this.renderPendingRoles()}
                    />
                  )}
                </Switch>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fetching: getActionLoadingState(state, actionTypes.GET_ROLE_REQUEST),
    pageRequestError: getActionErrorState(state, actionTypes.ROLE_PAGE_ERROR),
    confirmationLoading: getActionLoadingState(
      state,
      actionTypes.ROLE_PAGE_REQUEST
    ),
    permissions: state.user.permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchRoles: (page, limit, status, searchTerm) =>
      dispatch(fetchRoles(page, limit, status, searchTerm)),
    createOrEditRole: (data, roleId) =>
      dispatch(createOrEditRole(data, roleId)),
    fetchPermissions: () => dispatch(fetchPermissions()),
    changeRoleStatus: (roleId, status) =>
      dispatch(changeRoleStatus(roleId, status)),
    deleteRole: (roleId) => dispatch(deleteRole(roleId)),
    rejectRole: (roleId) => dispatch(rejectRole(roleId)),
    clearError: () => dispatch(clearError()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Roles));
