import CONFIG from "@/config";
import * as actions from "./actionTypes";
import { apiRequestHandler, apiDownloadHandler } from "@/utils";

const getSegmentAnalyticsRequest = () => {
  return {
    type: actions.GET_SEGMENT_ANALYTICS_REQUEST,
  };
};

const getSegmentAnalyticsError = () => {
  return {
    type: actions.GET_SEGMENT_ANALYTICS_ERROR,
  };
};

export const fetchSegmentsAnalytics = (view, startDate, endDate) => {
  const path1 =
    view === "average-investment-per-segment"
      ? `&avgInvestmentPerSegmentStartDate=${startDate}&avgInvestmentPerSegmentEndDate=${endDate}`
      : "";
  const path2 =
    view === "average-no-investment-per-segment"
      ? `&avgNoInvestmentPerSegmentStartDate=${startDate}&avgNoInvestmentPerSegmentEndDate=${endDate}`
      : "";
  const path3 =
    view === "count-users-per-segment"
      ? `&usersPerSegmentDate=${startDate}`
      : "";
  const path4 =
    view === "active-users-per-segment"
      ? `&activeUsersPerSegmentDate=${startDate}`
      : "";

  const config = {
    urlPath: `admin/analytics/segment?view=${view}${path1}${path2}${path3}${path4}`,
    requestAction: getSegmentAnalyticsRequest,
    errorAction: getSegmentAnalyticsError,
  };
  return apiRequestHandler(config);
};

export const exportSegmentsAnalytics = (
  _,
  format,
  view,
  avgInvestmentPerSegmentStartDate,
  avgInvestmentPerSegmentEndDate,
  avgNoInvestmentPerSegmentStartDate,
  avgNoInvestmentPerSegmentEndDate,
  usersPerSegmentDate,
  activeUsersPerSegmentDate
) => {
  const path1 =
    (view === "average-investment-per-segment"|| view==="default")
      ? `&avgInvestmentPerSegmentStartDate=${avgInvestmentPerSegmentStartDate}&avgInvestmentPerSegmentEndDate=${avgInvestmentPerSegmentEndDate}`
      : "";
  const path2 =
    (view === "average-no-investment-per-segment"|| view==="default")
      ? `&avgNoInvestmentPerSegmentStartDate=${avgNoInvestmentPerSegmentStartDate}&avgNoInvestmentPerSegmentEndDate=${avgNoInvestmentPerSegmentEndDate}`
      : "";
  const path3 =
    (view === "count-users-per-segment"|| view==="default") && usersPerSegmentDate
      ? `&usersPerSegmentDate=${usersPerSegmentDate}`
      : "";
  const path4 =
    (view === "active-users-per-segment"|| view==="default") && activeUsersPerSegmentDate
      ? `&activeUsersPerSegmentDate=${activeUsersPerSegmentDate}`
      : "";

  let exportView = view;
  

  const fullUrlPath = `${CONFIG.BASE_URL}/admin/analytics/segment/download?type=${format}&view=${exportView}${path1}${path2}${path3}${path4}`;
  return apiDownloadHandler(fullUrlPath);
};
