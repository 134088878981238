import React from 'react';

class FinancialInstruments extends React.Component {

  render() {
    return (
      <div className="portfolio-financial-instruments">
        <div className="text-center w-100">
          <p className="font-light text-grey mt-3">
            Coming Soon
          </p>
        </div>
      </div>
    )
  }
}

export default FinancialInstruments;
