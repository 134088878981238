export default [
  {
    title: "First Name",
    type: "text",
    placeholder: "First name",
    name: "firstName",
  },
  {
    title: "Last Name",
    type: "text",
    placeholder: "Last name",
    name: "lastName",
  },
  {
    title: "Phone Number",
    type: "number",
    placeholder: "08094890453",
    name: "phoneNumber",
  },
  {
    title: "Gender",
    type: "select",
    placeholder: "Gender",
    name: "gender",
    options: ["Male", "Female", "Rather not say"],
  },
  {
    title: "Address",
    type: "text",
    placeholder: "Address",
    name: "address",
  },
  {
    title: "Nationality",
    type: "text",
    placeholder: "Nationality",
    name: "Nationality",
  },
  {
    title: "State of Origin",
    type: "text",
    placeholder: "State of Origin",
    name: "stateOfOrigin",
  },
  {
    title: "Bank Name",
    name: "bankName",
    placeholder: "Bank Name",
    type: "select",
    options: [],
  },
  {
    title: "Account Number",
    placeholder: "Account Number",
    name: "accountNumber",
    type: "number",
  },
  {
    title: "BVN",
    type: "number",
    placeholder: "3478959904",
    name: "bvn",
  },
  {
    title: "Date of birth",
    placeholder: "Date of birth",
    name: "DateOfBirth",
    type: "date",
  },
  {
    title: "Email",
    type: "text",
    placeholder: "email",
    name: "email",
  },
  {
    title: "Subscription Amount",
    type: "number",
    placeholder: "Subscription Amount",
    name: "subscriptionAmount",
  },
];
