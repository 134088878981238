module.exports = {
  CLEAR_DATA: "ppi/CLEAR_DATA",
  CLEAR_ERROR: "ppi/CLEAR_ERROR",
  GET_FUNDS_BY_STATUS_REQUEST: "ppi/GET_FUNDS_BY_STATUS_REQUEST",
  GET_FUNDS_BY_STATUS_ERROR: "ppi/GET_FUNDS_BY_STATUS_ERROR",
  GET_FUNDS_BY_STATUS_SUCCESS: "ppi/GET_FUNDS_BY_STATUS_SUCCESS",
  GET_FUNDS_REPORT_REQUEST: "ppi/GET_FUNDS_REPORT_REQUEST",
  GET_FUNDS_REPORT_ERROR: "ppi/GET_FUNDS_REPORT_ERROR",
  GET_FUNDS_REPORT_SUCCESS: "ppi/GET_FUNDS_REPORT_SUCCESS",
  GET_REPORT_SUMMARY_REQUEST: "ppi/GET_REPORT_SUMMARY_REQUEST",
  GET_REPORT_SUMMARY_ERROR: "ppi/GET_REPORT_SUMMARY_ERROR",
  GET_REPORT_SUMMARY_SUCCESS: "ppi/GET_REPORT_SUMMARY_SUCCESS",
  GET_UNIQUE_CUSTOMER_REPORT_REQUEST: "ppi/GET_UNIQUE_CUSTOMER_REPORT_REQUEST",
  GET_UNIQUE_CUSTOMER_REPORT_ERROR: "ppi/GET_UNIQUE_CUSTOMER_REPORT_ERROR",
  GET_UNIQUE_CUSTOMER_REPORT_SUCCESS: "ppi/GET_UNIQUE_CUSTOMER_REPORT_SUCCESS",
  GET_CUSTOMER_CLICKS_REPORT_REQUEST: "ppi/GET_CUSTOMER_CLICKS_REPORT_REQUEST",
  GET_CUSTOMER_CLICKS_REPORT_ERROR: "ppi/GET_CUSTOMER_CLICKS_REPORT_ERROR",
  GET_CUSTOMER_CLICKS_REPORT_SUCCESS: "ppi/GET_CUSTOMER_CLICKS_REPORT_SUCCESS",
  GET_DOWNLOADED_INFO_REPORT_REQUEST: "ppi/GET_DOWNLOADED_INFO_REPORT_REQUEST",
  GET_DOWNLOADED_INFO_REPORT_ERROR: "ppi/GET_DOWNLOADED_INFO_REPORT_ERROR",
  GET_DOWNLOADED_INFO_REPORT_SUCCESS: "ppi/GET_DOWNLOADED_INFO_REPORT_SUCCESS",
  FILTER_PPI_REPORTS_REQUEST: "reports/FILTER_PPI_REPORTS_REQUEST",
  FILTER_PPI_REPORTS_SUCCESS: "reports/FILTER_PPI_REPORTS_SUCCESS",
  FILTER_PPI_REPORTS_ERROR: "reports/FILTER_PPI_REPORTS_ERROR",
};
