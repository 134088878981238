import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getActionLoadingState } from "@/store/selectors";
import { getActivePosts, deletePost } from "@/store/blog/actions";
import actionTypes from "@/store/blog/actionTypes";
import DropdownMenu from "@/components/DropdownMenu";
import Modal from "@/components/PopModal";
import Pagination from "@/components/Pagination";
import { hasPermission } from "@/utils";
import "./style.scss";

class Blogs extends React.Component {
  postRef = React.createRef();

  state = {
    selectedPost: null,
    showConfirmModal: false,
  };

  componentDidMount() {
    if (this.props.posts) {
      this.updateSelected(this.props.posts[0]);
    }
    this.getPosts(1, 10);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.posts !== this.props.posts) {
      this.updateSelected(this.props.posts[0]);
    }
  }

  getPosts = (page, limit) => {
    this.props.getActivePosts(page, limit);
  };

  updateSelected = (data) => {
    this.setState({ selectedPost: data }, () => {
      if (this.state.selectedPost && this.postRef) {
        this.postRef.current.innerHTML = `${this.state.selectedPost?.content}`;
      }
      if (this.state.selectedPost === undefined || !this.state.selectedPost) {
        this.postRef.current.innerHTML = null;
      }
    });
  };

  selectPost = (post) => {
    this.setState({ selectedPost: post }, () => {
      if (this.state.selectedPost && this.postRef) {
        this.postRef.current.innerHTML = `${this.state.selectedPost?.content}`;
      }
    });
  };

  toggleConfirmModal = () => {
    this.setState((prevState) => ({
      showConfirmModal: !prevState.showConfirmModal,
    }));
  };

  handleDelete = () => {
    const { selectedPost } = this.state;
    const { deletePost } = this.props;
    deletePost(selectedPost.slug)
      .then((data) => {
        this.toggleConfirmModal();
        this.props.getActivePosts(1, 10).then((data) => {
          this.updateSelected(data?.data?.data[0]);
        });
      })
      .catch((error) => {
        this.toggleConfirmModal();
      });
  };

  handleEdit = () => {
    const { selectedPost } = this.state;
    const { history } = this.props;
    if (!selectedPost) return;
    history.push({
      pathname: `/dashboard/setup/blog/edit/${selectedPost.slug}`,
      state: {
        blogPost: selectedPost,
        pageTitle: "Edit Post",
      },
    });
  };

  render() {
    const { loading, posts, removeLoading, data, permissions } = this.props;
    const { selectedPost, showConfirmModal } = this.state;
    const canDelete = hasPermission(permissions, "delete_service");
    const canUpdate = hasPermission(permissions, "update_service");

    const menu = [
      canUpdate && {
        name: "Edit blog",
        handler: this.handleEdit,
      },
      canDelete && {
        name: "Delete Blog",
        handler: this.toggleConfirmModal,
      },
    ];

    return (
      <div className="blogs-page">
        {showConfirmModal && (
          <Modal onClose={this.toggleConfirmModal}>
            <div className="text-center">
              <h3>Confirm</h3>
              <p>Are you sure you want to delete this blog post?</p>
              <div className="d-flex">
                <button
                  className="btn btn-sm btn-white btn-block mt-3 mr-2"
                  onClick={() => this.setState({ showConfirmModal: false })}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-sm btn-danger btn-block mt-3 ml-2 d-flex align-items-center justify-content-center"
                  onClick={this.handleDelete}
                >
                  Confirm
                  {removeLoading && (
                    <div className="spinner-border spinner-border-white spinner-border-sm ml-2"></div>
                  )}
                </button>
              </div>
            </div>
          </Modal>
        )}
        <Pagination
          totalPages={data?.lastPage}
          page={data?.page}
          limit={data?.perPage}
          changePageHandler={(page, limit) => this.getPosts(page, limit)}
        />
        <div className="card mb-5">
          <div className="row no-gutters">
            <div className="col-md-3 border-right p-5">
              {posts && posts.length > 0 ? (
                posts.map((post) => (
                  <p
                    className="text-deep-blue font-md cursor-pointer text-capitalize pb-5"
                    key={post.id}
                    onClick={() => this.selectPost(post)}
                  >
                    {post.title}
                  </p>
                ))
              ) : (
                <p className="font-md text-deep-blue">No Posts</p>
              )}
            </div>
            <div className="col-md-9">
              <div className="p-5 d-flex justify-content-between align-items-center border-bottom">
                <h2 className="text-deep-blue text-capitalize">
                  {selectedPost && selectedPost.title}
                  {posts && posts.length < 1 && "No posts"}
                </h2>
                {loading && (
                  <div className="spinner-border spinner-border-blue spinner-border-md ml-2"></div>
                )}
                {selectedPost && (canDelete || canUpdate) && (
                  <DropdownMenu menu={menu}>
                    <img
                      src={require("@/assets/icons/flat-ellipsis.svg").default}
                      alt="dropdown"
                    />
                  </DropdownMenu>
                )}
              </div>
              <div className="post-container p-4">
                {selectedPost?.image && (
                  <div className="img-wrapper">
                    <img
                      src={selectedPost?.image}
                      className="img-fluid"
                      alt="post-img"
                    />
                  </div>
                )}
                <div ref={this.postRef} className="mt-3"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    app: {
      blog: { error, data, posts },
    },
    user: { permissions },
  } = state;
  return {
    loading: getActionLoadingState(state, actionTypes.GET_BLOG_POSTS_REQUEST),
    removeLoading: getActionLoadingState(
      state,
      actionTypes.DELETE_BLOG_POST_REQUEST
    ),
    error,
    data,
    posts,
    permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getActivePosts: (page, limit) => dispatch(getActivePosts(page, limit)),
    deletePost: (slug) => dispatch(deletePost(slug)),
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Blogs));
