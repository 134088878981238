import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, NavLink } from "react-router-dom";
import {
  fetchSegments,
  changeSegmentStatus,
  createOrEditSegment,
  deleteSegment,
  rejectSegment,
} from "@/store/setup/segmentsAction";
import { getAllInvestments } from "@/store/investments/actions";
import { getActionLoadingState, getActionErrorState } from "@/store/selectors";
import actionTypes from "@/store/setup/actionTypes";
import RightPanel from "@/components/RightPanel";
import Dropdown from "@/components/DropdownMenu";
import Modal from "@/components/cpModal";
import InputError from "@/components/InputError";
import ImageUploadInput from "@/components/ImageUploadInput";
import PermissionsDropdown from "@/components/PermissionsDropdown";
import { serializeErrors, hasPermission } from "@/utils";
import Emptystate from "@/components/Emptystate";
import Pagination from "@/components/Pagination";
import ExportDropdown from "@/components/ExportDropdown";
import SearchBox from "@/components/SearchBox";
import MultipleApprovalDropdown from "@/components/MultipleApprovalDropdown";
import "./Segment.scss";

class Segments extends Component {
  defaultState = {
    open: false,
    name: "",
    description: "",
    message: "",
    minAge: "",
    maxAge: "",
    gender: "male",
    icon: "",
    iconUrl: "",
    previewMode: false,
    panelHeading: "Create New Segment",
    addedServices: [],
    selectedRowsIds: [],
    error: "",
    formErrors: {
      name: "",
      description: "",
      message: "",
      minAge: "",
      maxAge: "",
      gender: "",
      icon: "",
      services: ""
    },
    touched: {
      name: false,
      description: false,
      message: false,
      minAge: false,
      maxAge: false,
      gender: false,
      icon: false,
      services: false
    },
  };

  state = {
    ...this.defaultState,
    segments: [],
    pendingSegments: [],
    confirmationContext: "",
    confirmationSuccess: false,
    searchTerm: "",
    shouldSearch: false,
  };

  componentDidMount() {
    this.getSegments(1, 10);
  }

  isShowingActiveSegments = () => {
    if (this.props.location.pathname.indexOf("pending") === -1) {
      return true;
    }
    return false;
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({
        selectedRowsIds: [],
        searchTerm: "",
        shouldSearch: false,
      });
      this.getSegments(1, 10);
    } else if (
      this.state.searchTerm !== prevState.searchTerm &&
      this.state.shouldSearch
    ) {
      this.getSegments(1, 10, this.state.searchTerm);
    }
  }

  getSegments = (page, limit, searchTerm) => {
    if (this.isShowingActiveSegments()) {
      this.props
        .fetchSegments(page, limit, "active", searchTerm)
        .then((segments) => {
          this.setState({ segments: segments.data });
          this.setPageData(segments, searchTerm);
        });
    } else {
      this.props
        .fetchSegments(page, limit, "pending", searchTerm)
        .then((segments) => {
          this.setState({ pendingSegments: segments.data });
          this.setPageData(segments, searchTerm);
        });
    }
  };

  setPageData = ({ lastPage, perPage, page, total }, searchTerm) => {
    this.setState({ lastPage, perPage, page, total, searchTerm });
  };

  changeSegmentStatus = () => {
    const { id, newStatus, segments, isMultipleApproval, selectedRowsIds } =
      this.state;
    this.props
      .changeSegmentStatus(isMultipleApproval ? selectedRowsIds : id, newStatus)
      .then(() => {
        if (this.isShowingActiveSegments()) {
          const allSegments = [...segments];
          const segmentIndex = allSegments.findIndex(
            (segment) => segment.id === id
          );
          const newSegmentInfo = {
            ...allSegments[segmentIndex],
            status: newStatus === "enable" ? "active" : "disabled",
          };
          allSegments[segmentIndex] = newSegmentInfo;
          this.setState({ segments: allSegments, confirmationSuccess: true });
        } else {
          if (isMultipleApproval)
            return this.removeMultipleFromList(selectedRowsIds);
          this.removeFromList("pendingSegments", id);
          this.setState({ confirmationSuccess: true });
        }
      });
  };

  removeMultipleFromList = (selectedRowsIds) => {
    const unchangedItems = this.state.pendingSegments.filter(
      (data) => !selectedRowsIds.includes(data.id)
    );
    this.setState({
      pendingSegments: unchangedItems,
      selectedRowsIds: [],
      confirmationSuccess: true,
      total: this.state.total - selectedRowsIds.length,
    });
  };

  removeFromList = (segmentKey, id) => {
    const list = this.state[segmentKey];
    const allSegments = [...list];
    const segmentIndex = allSegments.findIndex((segment) => segment.id === id);
    if (segmentIndex !== -1) allSegments.splice(segmentIndex, 1);
    this.setState({ [segmentKey]: allSegments });
  };

  deleteSegment = () => {
    const { id } = this.state;
    this.props.deleteSegment(id).then(() => {
      this.removeFromList("segments", id);
      this.setState({ confirmationSuccess: true });
    });
  };

  rejectSegment = () => {
    const { id, isMultipleApproval, selectedRowsIds } = this.state;
    this.props
      .rejectSegment(isMultipleApproval ? selectedRowsIds : id)
      .then(() => {
        if (isMultipleApproval)
          return this.removeMultipleFromList(selectedRowsIds);
        this.removeFromList("pendingSegments", id);
        this.setState({ confirmationSuccess: true });
      });
  };

  confirmationData = () => {
    const { isMultipleApproval, selectedRowsIds } = this.state;
    const addS = isMultipleApproval && selectedRowsIds.length > 1;
    return {
      changeStatus: {
        title: `${this.state.newStatus} segment`,
        question: `Are you sure you want to ${this.state.newStatus} this segment?`,
        action: this.changeSegmentStatus,
      },
      delete: {
        title: "Delete segment",
        question: "Are you sure you want to delete this segment?",
        action: this.deleteSegment,
      },
      approveSegment: {
        title: `Approve segment${addS ? "s" : ""}`,
        question: `Are you sure you want to approve ${
          addS ? "these" : "this"
        } segment${addS ? "s" : ""}?`,
        action: this.changeSegmentStatus,
      },
      rejectSegment: {
        title: `Reject segment${addS ? "s" : ""}`,
        question: `Are you sure you want to reject ${
          addS ? "these" : "this"
        } segment${addS ? "s" : ""}?`,
        action: this.rejectSegment,
      },
    };
  };

  setConfirmationContext = (
    context,
    segmentId,
    newStatus = "",
    isMultipleApproval
  ) => {
    this.setState({
      confirmationContext: context,
      confirmationSuccess: false,
      id: segmentId,
      newStatus,
      isMultipleApproval,
    });
  };

  onEditSegments = (id) => {
    this.props.getAllInvestments();
    const segmentInfo = this.state.segments.find(
      (segment) => segment.id === id
    );
    this.setState({
      name: segmentInfo.name,
      description: segmentInfo.description,
      message: segmentInfo.message,
      minAge: segmentInfo.minAge,
      maxAge: segmentInfo.maxAge,
      gender: segmentInfo.gender,
      iconUrl: segmentInfo.icon,
      addedServices: segmentInfo.services,
      segmentId: id,
      panelHeading: "Edit Segment",
      open: true,
    });
  };

  dropdownmenus = (id, status) => {
    const newStatus = status === "disabled" ? "enable" : "disable";
    const canUpdate = hasPermission(this.props.permissions, "update_segment");
    const canApprove = hasPermission(this.props.permissions, "approve_segment");
    const canDelete = hasPermission(this.props.permissions, "delete_segment");
    return [
      canUpdate
        ? {
            name: "Edit",
            handler: () => this.onEditSegments(id),
          }
        : null,
      canApprove
        ? {
            name: newStatus,
            handler: () =>
              this.setConfirmationContext("changeStatus", id, newStatus),
          }
        : null,
      canDelete
        ? {
            name: "Delete",
            handler: () => this.setConfirmationContext("delete", id),
            textClass: "text-red",
          }
        : null,
    ].filter(Boolean);
  };

  onRowClick = (e, segment) => {
    if (!["BUTTON", "IMG", "LI", "INPUT"].includes(e.target.nodeName)) {
      this.setState({
        name: segment.name,
        description: segment.description,
        message: segment.message,
        minAge: segment.minAge,
        maxAge: segment.maxAge,
        gender: segment.gender,
        iconUrl: segment.icon,
        segmentStatus: segment.status,
        addedServices: segment.services ?? [],
        previewMode: true,
        panelHeading: segment.name,
        open: true,
      });
    }
  };

  onCheckRow = (e, rowId) => {
    const selectedRows = [...this.state.selectedRowsIds];
    if (e.target.checked) {
      selectedRows.push(rowId);
    } else {
      const uncheckedRowIndex = selectedRows.findIndex((id) => id === rowId);
      selectedRows.splice(uncheckedRowIndex, 1);
    }
    this.setState({ selectedRowsIds: selectedRows });
  };

  renderActiveSegments = () => {
    return (
      <table className="custum-table">
        <tbody>
          <tr>
            <th>ID</th>
            <th>Segment name</th>
            <th>Description</th>
            <th>Status</th>
            <th></th>
          </tr>
          {this.state.segments.map((segment) => (
            <tr
              key={segment.id}
              onClick={(e) => this.onRowClick(e, segment)}
              className="cursor-pointer"
            >
              <td>{segment.id}</td>
              <td className="text-capitalize">{segment.name}</td>
              <td className="segment-description">{segment.description}</td>
              <td>
                <span
                  className={`status ${
                    segment.status === "active"
                      ? "status--active"
                      : "status--disabled"
                  }`}
                >
                  {segment.status}
                </span>
              </td>
              <td className="custum-table__ellipsis">
                <Dropdown
                  menu={this.dropdownmenus(segment.id, segment.status)}
                  width="117px"
                  arrow={true}
                >
                  <button className="wrapper-button">
                    <img
                      src={require("@/assets/icons/flat-ellipsis.svg").default}
                      alt="dropdown"
                    />
                  </button>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  renderPendingSegments = () => {
    return (
      <table className="custum-table">
        <tbody>
          <tr>
            <th></th>
            <th>ID</th>
            <th>Segment name</th>
            <th>Description</th>
            <th className="actions-column">Actions</th>
          </tr>
          {this.state.pendingSegments.map((segment) => (
            <tr
              key={segment.id}
              onClick={(e) => this.onRowClick(e, segment)}
              className="cursor-pointer"
            >
              <td className="row-checkbox">
                <input
                  type="checkbox"
                  onChange={(e) => this.onCheckRow(e, segment.id)}
                />
              </td>
              <td>{segment.id}</td>
              <td className="text-capitalize">{segment.name}</td>
              <td className="segment-description">{segment.description}</td>
              <td className="custum-table__button-cell">
                <button
                  className="custum-table__button-blue"
                  onClick={() =>
                    this.setConfirmationContext(
                      "approveSegment",
                      segment.id,
                      "enable"
                    )
                  }
                >
                  Approve
                </button>
                <button
                  className="custum-table__button-plain"
                  onClick={() =>
                    this.setConfirmationContext("rejectSegment", segment.id)
                  }
                >
                  Reject
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  onCreateClick = () => {
    this.props.getAllInvestments();
    this.setState({ open: true });
  };

  validateField = (name, value) => {
    let error = "";
  
    switch (name) {
      case "name":
        if (!value.toString().trim()) {
          error = "Segment name is required";
        } else if (value.length < 2) {
          error = "Segment name must be at least 2 characters";
        }
        break;
  
      case "description":
        if (!value.toString().trim()) {
          error = "Description is required";
        } else if (value.length < 10) {
          error = "Description must be at least 10 characters";
        }
        break;
  
      case "message": 
        if (value && value.length > 240) {
          error = "Message cannot exceed 240 characters";
        }
        break;
  
      case "minAge":
        if (!value.toString().trim()) {
          error = "Minimum age is required";
        } else if (isNaN(value) || value < 0) {
          error = "Minimum age must be a positive number";
        } else if (value > this.state.maxAge && this.state.maxAge) {
          error = "Minimum age cannot be greater than maximum age";
        }
        break;
  
      case "maxAge":
        if (!value.toString().trim()) {
          error = "Maximum age is required";
        } else if (isNaN(value) || value < 0) {
          error = "Maximum age must be a positive number";
        } else if (value < this.state.minAge) {
          error = "Maximum age must be greater than minimum age";
        }
        break;
  
      case "gender":
        if (!value) {
          error = "Gender is required";
        }
        break;
  
      case "icon":
        if (!value && !this.state.iconUrl) {
          error = "Segment icon is required";
        }
        break;
  
      case "services":
        if (!this.state.addedServices.length) {
          error = "At least one service must be selected";
        }
        break;
  
      default:
        break;
    }
  
    return error;
  };

  validateForm = () => {
    const { name, description, message, minAge, maxAge, gender, icon } = this.state;
    
    const errors = {
      name: this.validateField("name", name),
      description: this.validateField("description", description),
      message: this.validateField("message", message),
      minAge: this.validateField("minAge", minAge),
      maxAge: this.validateField("maxAge", maxAge),
      gender: this.validateField("gender", gender),
      icon: this.validateField("icon", icon),
      services: this.validateField("services")
    };
  
    const touched = {
      name: true,
      description: true,
      message: true,
      minAge: true,
      maxAge: true,
      gender: true,
      icon: true,
      services: true
    };
  
    this.setState({ formErrors: errors, touched });
  
    return !Object.values(errors).some(error => error);
  };

  createSegment = (e) => {
    e.preventDefault();

    if (!this.validateForm()) {
      return;
    }

    const {
      name,
      description,
      message,
      minAge,
      maxAge,
      gender,
      icon,
      addedServices,
      segmentId,
    } = this.state;
    const services = addedServices.map((service) => Number(service.id));
    const payload = {
      name,
      description,
      message,
      minAge,
      maxAge,
      gender,
      icon,
      services,
    };
    this.props.createOrEditSegment(payload, segmentId).then(() => {
      this.removeFromList("segments", segmentId);
      this.setState({
        open: false,
        confirmationContext: "confirmed",
        confirmationSuccess: true,
      });
    });
  };
  handleMaxError = () => {
    if (this.state.message?.split("").length > 240) {
      this.setState({ error: "you have reached the 240 character limit" });
    }
  };

  onInputChange = (e) => {
    const { name, value } = e.target;
    
    this.setState(prevState => ({
      [name]: value,
      formErrors: {
        ...prevState.formErrors,
        [name]: this.validateField(name, value)
      },
      touched: {
        ...prevState.touched,
        [name]: true
      }
    }));
  };

  renderError = (fieldName) => {
    const { formErrors, touched } = this.state;
    if (touched[fieldName] && formErrors[fieldName]) {
      return (
        <div className="text-red-500 text-sm -mt-5 mb-4">
          {formErrors[fieldName]}
        </div>
      );
    }
    return null;
  };

  handleFileClick = (event) => {
    event.preventDefault();
    this.imgRef.current.click();
  };

  handleImageSelect = (event) => {
    const { target } = event;
    if (target.files[0]) {
      this.setState({ fileName: target.files[0].name, icon: target.files[0] });
    }
  };

  addService = (newService) => {
    const { addedServices } = this.state;
    //check if service is already selected if yes do nothing
    if (addedServices.find((service) => service.name === newService.name))
      return;
    const currentServices = [...addedServices, newService];
    this.setState({ addedServices: currentServices });
  };

  removeService = (index) => {
    const { addedServices } = this.state;
    const addedServicesCopy = [...addedServices];
    addedServicesCopy.splice(index, 1);
    this.setState({ addedServices: addedServicesCopy });
  };

  closePanel = (e) => {
    e.preventDefault();
    this.setState({ ...this.defaultState });
  };
  const;
  render() {
    const {
      match: { path },
      fetching,
      pageRequestError,
      confirmationLoading,
      investments,
      permissions,
    } = this.props;
    const {
      open,
      name,
      description,
      message,
      minAge,
      maxAge,
      gender,
      iconUrl,
      segmentStatus,
      panelHeading,
      confirmationContext,
      confirmationSuccess,
      previewMode,
      addedServices,
      pendingSegments,
      segments,
      lastPage,
      perPage,
      page,
      total,
      searchTerm,
      error,
    } = this.state;
    const createErrorObject = serializeErrors(pageRequestError?.message);
    const prompt = this.confirmationData()[confirmationContext];
    const isShowingActiveSegments = this.isShowingActiveSegments();
    const dataCount = isShowingActiveSegments
      ? segments.length
      : pendingSegments.length;
    const canApprove = hasPermission(permissions, "approve_segment");
    const canCreate = hasPermission(permissions, "create_segment");
    const showMultiAprrovalDropdown = this.state.selectedRowsIds.length > 0;

    return (
      <div className="setup-inner">
        <RightPanel open={open} onClose={this.closePanel}>
          <h1 className="panel-heading">{panelHeading}</h1>
          {!previewMode ? (
            <form
              className="form employer-onboard__form"
              onSubmit={this.createSegment}
              noValidate
            >
              <label className="panel-label" htmlFor="name">
                Segment name
              </label>
              <input
                type="text"
                name="name"
                value={name}
                onChange={this.onInputChange}
                className={`form-control panel-input ${
                  createErrorObject.name ? "error-input" : ""
                }`}
                placeholder="Enter segment name"
                id="name"
                autoComplete="off"
                required
              />
              {this.renderError("name")}
              <InputError error={createErrorObject.name} />

              <label className="panel-label" htmlFor="description">
                Description
              </label>
              <textarea
                id="description"
                name="description"
                value={description}
                onChange={this.onInputChange}
                placeholder="Manages admin users and system configurations"
                className={`form-control panel-input panel-text-area ${
                  createErrorObject.description ? "error-input" : ""
                }`}
                cols="30"
                rows="5"
                required
              ></textarea>
              {this.renderError("description")}
              <InputError error={createErrorObject.description} />

              <label className="panel-label" htmlFor="message">
                Message
              </label>
              <textarea
                id="message"
                name="message"
                value={message}
                onChange={this.onInputChange}
                placeholder="Leave a message"
                className={`form-control panel-input panel-text-area ${
                  createErrorObject.description ? "error-input" : ""
                }`}
                cols="30"
                rows="5"
                maxLength={error ? 240 : null}
              ></textarea>
              {error ? (
                <small className="error d-flex align-items-center">
                  {error}
                </small>
              ) : null}
              <label className="panel-label" htmlFor="minAge">
                Minimum age
              </label>
              <input
                type="text"
                name="minAge"
                value={minAge}
                onChange={this.onInputChange}
                className={`form-control panel-input ${
                  createErrorObject.minAge ? "error-input" : ""
                }`}
                placeholder="Enter minimum age"
                id="minAge"
                autoComplete="off"
                required
              />
              {this.renderError("minAge")}
              <InputError error={createErrorObject.minAge} />

              <label className="panel-label" htmlFor="maxAge">
                Maximum age
              </label>
              <input
                type="text"
                name="maxAge"
                value={maxAge}
                onChange={this.onInputChange}
                className={`form-control panel-input ${
                  createErrorObject.maxAge ? "error-input" : ""
                }`}
                placeholder="Enter maximum age"
                id="maxAge"
                autoComplete="off"
                required
              />
              {this.renderError("maxAge")}
              <InputError error={createErrorObject.maxAge} />

              <label className="panel-label" htmlFor="gender">
                Gender
              </label>
              <select
                required
                className={`form-control panel-input ${
                  createErrorObject.gender ? "error-input" : ""
                }`}
                id="gender"
                name="gender"
                value={gender}
                onChange={this.onInputChange}
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="all-gender">All gender</option>
              </select>
              {this.renderError("gender")}
              <InputError error={createErrorObject.gender} />

              <label className="panel-label" htmlFor="icon">
                Segment icon
              </label>
              <ImageUploadInput
                id="icon"
                handleFile={(image) => this.setState({ icon: image })}
                currentImageURL={iconUrl}
                removePrevie={!open}
              />
              <InputError error={createErrorObject.icon} />
              <label className="panel-label" htmlFor="servicesSelect">
                Services
              </label>
              {!previewMode && (
                <PermissionsDropdown
                  permissions={investments}
                  id="servicesSelect"
                  passPermissions={this.addService}
                  placeholder="Select services"
                />
              )}
              {this.renderError("services")}
              <InputError error={createErrorObject.services} />
              <div className="dropdown__positions-box">
                {addedServices.map((permission, index) => (
                  <div className="dropdown__position-item" key={permission.id}>
                    {permission.name}
                    <button onClick={() => this.removeService(index)}>
                      <img
                        src={
                          require("@/assets/icons/circle-cancel.svg").default
                        }
                        alt="cancel icon"
                      />
                    </button>
                  </div>
                ))}
              </div>
              <div className="dual-button-box">
                <button
                  type="button"
                  onClick={this.closePanel}
                  className="cp-button-blue"
                >
                  Cancel
                </button>
                <button type="submit" className="cp-button-blue">
                  Request Approval 
                  {confirmationLoading && (
                    <div className="spinner-border spinner-border-white spinner-border-sm ml-2 mb-2"></div>
                  )}
                </button>
              </div>
            </form>
          ) : (
            <div className="panel-info">
              <div className="panel-info__picture">
                <img src={iconUrl} alt="segment icon" />
              </div>
              <div className="panel-info__row">
                <span className="panel-info__field">Segment name</span>
                <span className="panel-info__value">{name}</span>
              </div>
              <div className="panel-info__row">
                <span className="panel-info__field">Status</span>
                <span
                  className={`status ${
                    segmentStatus === "active"
                      ? "status--active"
                      : "status--disabled"
                  }`}
                >
                  {segmentStatus}
                </span>
              </div>
              <div className="panel-info__row">
                <span className="panel-info__field">Description</span>
                <span className="panel-info__value">{description}</span>
              </div>
              <div className="panel-info__row">
                <span className="panel-info__field">Message</span>
                <span className="panel-info__value">{message}</span>
              </div>
              <div className="panel-info__row">
                <span className="panel-info__field">Minimum age</span>
                <span className="panel-info__value">{minAge}</span>
              </div>
              <div className="panel-info__row">
                <span className="panel-info__field">Maximum age</span>
                <span className="panel-info__value">{maxAge}</span>
              </div>
              <div className="panel-info__row">
                <span className="panel-info__field">Gender</span>
                <span className="panel-info__value">{gender}</span>
              </div>
              <div className="panel-info__row">
                <span className="panel-info__field">Services</span>
                <span className="panel-info__value">
                  {addedServices.map((permission) => (
                    <div
                      className="dropdown__position-item"
                      key={permission.id}
                    >
                      {permission.name}
                    </div>
                  ))}
                </span>
              </div>
              <button className="long-button" onClick={this.closePanel}>
                Close
              </button>
            </div>
          )}
        </RightPanel>
        <div className="setup-inner__top">
          <Modal
            open={confirmationContext}
            outsideClose={confirmationSuccess}
            close={() => this.setState({ confirmationContext: "" })}
          >
            <div className="confirmation-modal text-center">
              {confirmationSuccess ? (
                <Fragment>
                  <img
                    src={require("@/assets/icons/pace-success.svg").default}
                    className="confirmation-modal__icon"
                    alt="success icon"
                  />
                  <p className="text-small text-bold">Successful</p>
                </Fragment>
              ) : (
                <Fragment>
                  <h2 className="confirmation-modal__heading">
                    {prompt?.title}
                  </h2>
                  <p className="confirmation-modal__question">
                    {prompt?.question}
                  </p>
                </Fragment>
              )}
              {!confirmationSuccess && (
                <div className="dual-button-box">
                  <button
                    className="cp-button-blue confirmation-modal__button"
                    onClick={() => this.setState({ confirmationContext: "" })}
                  >
                    Cancel
                  </button>
                  <button
                    className="cp-button-blue confirmation-modal__button"
                    onClick={prompt?.action}
                  >
                    {prompt?.title} 
                    {confirmationLoading && (
                      <div className="spinner-border spinner-border-white spinner-border-sm ml-2 mb-2"></div>
                    )}
                  </button>
                </div>
              )}
            </div>
          </Modal>
          <ul className="setup-inner__nav">
            <li className="setup-nav__item">
              <NavLink
                exact
                to={{ pathname: `${path}`, state: { pageTitle: "Setup" } }}
                activeClassName="setup-nav__item--active"
              >
                Segments
              </NavLink>
            </li>
            {canApprove && (
              <li className="setup-nav__item">
                <NavLink
                  to={{
                    pathname: `${path}/pending`,
                    state: { pageTitle: "Setup" },
                  }}
                  activeClassName="setup-nav__item--active"
                >
                  Pending Approval
                </NavLink>
              </li>
            )}
          </ul>
          <SearchBox
            placeholder="Search"
            handleSearch={(searchTerm) =>
              this.setState({ searchTerm, shouldSearch: true })
            }
            isActiveSearch={Boolean(searchTerm)}
            key={this.props.location.pathname}
          />
          {!isShowingActiveSegments && showMultiAprrovalDropdown && (
            <MultipleApprovalDropdown
              approve={() =>
                this.setConfirmationContext(
                  "approveSegment",
                  null,
                  "enable",
                  true
                )
              }
              reject={() =>
                this.setConfirmationContext("rejectSegment", null, null, true)
              }
            />
          )}
          {dataCount > 0 && (
            <ExportDropdown
              module="segments"
              status={isShowingActiveSegments ? "approved" : "pending-approval"}
              page={page}
              limit={perPage}
              hasMoreThanOnePage={lastPage > 1}
            />
          )}
          {isShowingActiveSegments && canCreate && (
            <button
              className="setup-inner__button"
              onClick={this.onCreateClick}
            >
              <img
                src={require("@/assets/icons/plus.svg").default}
                alt="plus icon"
                className="setup-inner__button-img"
              />
              Create Segment
            </button>
          )}
        </div>
        {fetching ? (
          <div className="text-center text-primary">
            <div className="spinner-border" role="status"></div>
          </div>
        ) : (
          <div className="position-relative">
            {!dataCount ? (
              <Emptystate
                title={`${searchTerm ? "No Result Found" : "No Segments"}`}
                icon={require("@/assets/icons/info.svg").default}
              />
            ) : (
              <>
                <Pagination
                  totalPages={lastPage}
                  page={page}
                  limit={perPage}
                  changePageHandler={(page, limit) =>
                    this.getSegments(page, limit, searchTerm)
                  }
                />
                <div className="setup-inner__main">
                  <Switch>
                    <Route
                      exact
                      path={path}
                      render={() => this.renderActiveSegments()}
                    />
                    <Route
                      exact
                      path={`${path}/pending`}
                      render={() => this.renderPendingSegments()}
                    />
                  </Switch>
                </div>
                <div className="data-count">
                  Showing
                  <span className="font-weight-bold mx-2">{`${dataCount} of ${total}`}</span>
                  Segments
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    app: {
      investments: {
        allInvestments: { data },
      },
    },
  } = state;
  return {
    fetching: getActionLoadingState(state, actionTypes.GET_SEGMENT_REQUEST),
    confirmationLoading: getActionLoadingState(
      state,
      actionTypes.SEGMENTS_PAGE_REQUEST
    ),
    pageRequestError: getActionErrorState(
      state,
      actionTypes.SEGMENTS_PAGE_ERROR
    ),
    investments: data.investments.data,
    permissions: state.user.permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSegments: (page, limit, status, searchTerm) =>
      dispatch(fetchSegments(page, limit, status, searchTerm)),
    deleteSegment: (segmentId) => dispatch(deleteSegment(segmentId)),
    rejectSegment: (segmentId) => dispatch(rejectSegment(segmentId)),
    createOrEditSegment: (data, segmentId = "") =>
      dispatch(createOrEditSegment(data, segmentId)),
    changeSegmentStatus: (segmentId, newStatus) =>
      dispatch(changeSegmentStatus(segmentId, newStatus)),
    getAllInvestments: () => dispatch(getAllInvestments(100000)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Segments)
);
