import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import dayjs from "dayjs";
import { getActionLoadingState } from "@/store/selectors";
import { getDownloadedInfoReport } from "@/store/ppi/actions";
import actionTypes from "@/store/ppi/actionTypes";
import Pagination from "@/components/Pagination";
import Emptystate from "@/components/Emptystate";
import "./style.scss";

class DownloadedInformation extends React.Component {
  componentDidMount() {
    this.getDownloadedInfo(1, 10);
  }

  getDownloadedInfo = (page, limit) => {
    const { id } = this.props;
    this.props.getDownloadedInfoReport(id, page, limit);
  };

  render() {
    const { downloaded, loading } = this.props;

    return (
      <div className="downloaded-summary-page">
        <Pagination
          totalPages={downloaded?.lastPage}
          page={downloaded?.page}
          limit={downloaded?.perPage}
          changePageHandler={(page, limit) =>
            this.getDownloadedInfo(page, limit)
          }
        />
        {!downloaded && loading && (
          <div className="text-center p-4">
            <div className="spinner-border spinner-border-primary text-primary spinner-border-md mr-2"></div>
          </div>
        )}
        {downloaded &&
          (downloaded?.mutual?.data?.length > 0 ? (
            <>
              <div className="setup-inner__main">
                <table className="custum-table">
                  <tbody>
                    <tr>
                      <th>Name</th>
                      <th>Phone Number</th>
                      <th>Email Address</th>
                      <th>Date of Click</th>
                    </tr>
                    {downloaded?.mutual?.data.map((item) => (
                      <tr key={Math.random() * 1000}>
                        <td>
                          <span>
                            {item.firstName} {item.lastName}
                          </span>
                        </td>
                        <td>{item.phone}</td>
                        <td>{item.email}</td>
                        <td>{dayjs(item.created_at).format("DD MMM, YYYY")}</td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan="6" className="text-center">
                        Showing {downloaded?.mutual?.data?.length} of{" "}
                        {downloaded?.total}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <Emptystate
              title="No Downloaded data"
              icon={require("@/assets/icons/info.svg").default}
            />
          ))}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    app: {
      ppi: { downloaded },
    },
  } = state;
  return {
    loading: getActionLoadingState(
      state,
      actionTypes.GET_DOWNLOADED_INFO_REPORT_REQUEST
    ),
    downloaded,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDownloadedInfoReport: (id, page, limit) =>
      dispatch(getDownloadedInfoReport(id, page, limit)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DownloadedInformation)
);
