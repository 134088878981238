export const userData = [
  // {
  //   name: 'Title',
  //   value: 'title'
  // },
  {
    name: 'First Name',
    value: 'firstName'
  },
  {
    name: 'Middle Name',
    value: 'middleName'
  },
  {
    name: 'Last Name',
    value: 'lastName'
  },
  {
    name: 'User ID Number',
    value: 'id'
  },
  {
    name: 'Last login',
    value: 'last_login_at'
  }
];

export const status = [
  {
    name: 'User Status Activity',
    value: 'userStatusActivity'
  }
]

export const bioData = [
  {
    name: 'Email',
    value: 'email'
  },
  {
    name: 'Phone',
    value: 'phone'
  },
  {
    name: 'Gender',
    value: 'gender'
  },
  {
    name: 'Country of Birth',
    value: 'placeOfBirth'
  },
  {
    name: 'Marital Status',
    value: 'maritalStatus'
  },
  {
    name: 'State of Origin',
    value: 'stateOfOrigin'
  },
  {
    name: 'ID Type',
    value: 'idType'
  },
  {
    name: 'BVN',
    value: 'bvn'
  },
  // {
  //   name: 'Issue Date',
  //   value: 'issueDate'
  // },
  // {
  //   name: 'Expiry Date',
  //   value: 'expiryDate'
  // },
  {
    name: 'Place of Issue',
    value: 'placeOfIssue'
  },
  {
    name: 'Nationality',
    value: 'nationality'
  },
  {
    name: "Mother's maiden name",
    value: 'motherMaidenName'
  },
  {
    name: 'Residential Address',
    value: 'residentialAddress'
  },
  // {
  //   name: 'Date of Entry into current residence',
  //   value: 'currentResidenceEntry'
  // },
  {
    name: 'Date of Birth',
    value: 'dateOfBirth'
  }
]

export const employment = [
  {
    name: 'Level of qualification',
    value: 'qualification'
  },
  {
    name: 'Employment Status',
    value: 'status'
  },
  {
    name: 'Occupation',
    value: 'occupation'
  },
  {
    name: 'Appointment Date',
    value: 'appointmentDate'
  },
  {
    name: 'Company Name',
    value: 'companyName'
  },
  {
    name: 'Company Address',
    value: 'companyAddress'
  },
  {
    name: 'Official Phone Number',
    value: 'officialPhoneNumber'
  },
  {
    name: 'Official Email Address',
    value: 'officialEmailAddress'
  },
]

export const bankDetails = [
  {
    name: 'Bank Name',
    value: 'bankName'
  },
  {
    name: 'Bank account number',
    value: 'accountNumber'
  },
  {
    name: 'Bank account name',
    value: 'accountName'
  },
  // {
  //   name: 'Source of funds',
  //   value: 'sourceOfFunds'
  // },
  // {
  //   name: 'Annual income',
  //   value: 'annualIncome'
  // },
]

export const nextOfKin = [
  // {
  //   name: 'Title',
  //   value: 'title'
  // },
  {
    name: 'First name',
    value: 'firstName'
  },
  {
    name: 'Last name',
    value: 'lastName'
  },
  {
    name: 'Date of birth',
    value: 'dateOfBirth'
  },
  {
    name: 'Nationality',
    value: 'nationality'
  },
  {
    name: 'Relationship',
    value: 'relationship'
  },
  {
    name: 'Gender',
    value: 'gender'
  },
  {
    name: 'Email',
    value: 'email'
  },
  {
    name: 'Phone number',
    value: 'phone'
  },
  {
    name: 'Contact address',
    value: 'contactAddress'
  }
]

export const others = [
  {
    name: 'Have you occupied any political position',
    value: 'occupiedPoliticalPosition'
  },
  {
    name: 'Have any of your relatives/associates occupied a Political position?',
    value: 'hasPoliticalAssociate'
  },
  {
    name: 'Name of position',
    value: 'positionName'
  },
  {
    name: 'Relationship',
    value: 'relationshipWithAssociate'
  },
  {
    name: 'Date: From',
    value: 'from'
  },
  {
    name: 'Date: To',
    value: 'to'
  }
]

export const portfolio = [
  {
    name: 'Users wallet balance',
    value: 'walletBalance'
  },
  {
    name: 'Users total deposit',
    value: 'totalDeposit'
  },
  {
    name: 'Users total withdrawals',
    value: 'totalWithdrawal'
  },
  {
    name: 'Users termed investment',
    value: 'termedInvestments'
  },
  {
    name: 'Active investments',
    value: 'activeInvestments'
  },
  {
    name: 'Segments',
    value: 'segments'
  },
  {
    name: 'Risk profile',
    value: 'riskProfile'
  },
]