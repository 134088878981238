import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, NavLink, Redirect } from "react-router-dom";
import Deposits from "./Deposits";
import Withdrawals from "./Withdrawals";
import Reversals from "./Reversals";
import Investments from "./Investments";
import InvestmentTransactions from "./InvestmentTransactions";
import Terminations from "./Terminations";
import Bookings from "./Bookings";
import MutualFunds from "./MutualFunds";
import MutualFundPerformance from "./MutualFundPerformance";
import { hasPermission } from "@/utils";

class Transactions extends Component {
  canReadTerminations = hasPermission(this.props.permissions, [
    "read_liquidation",
    "update_liquidation",
  ]);
  canReadWithdrawals = hasPermission(this.props.permissions, [
    "read_withdrawal",
    "approve_withdrawal",
    "hold_withdrawal",
    "confirm_withdrawal",
  ]);
  canReadDeposits = hasPermission(this.props.permissions, [
    "read_deposit",
    "hold_deposit",
    "reverse_deposit",
  ]);
  canReadInvestments = hasPermission(this.props.permissions, [
    "read_investment",
    "create_investment",
    "approve_investment",
    "confirm_investment",
    "update_investment",
  ]);
  // below is temporary pending when permission for this action is created
  canReadReversals = true;

  navItems = [
    this.canReadDeposits ? { name: "Deposits", path: "deposits" } : null,
    this.canReadWithdrawals
      ? { name: "Withdrawals", path: "withdrawals" }
      : null,
    this.canReadReversals ? { name: "Reversals", path: "reversals" } : null,
    this.canReadInvestments
      ? { name: "Investments", path: "investments" }
      : null,
    this.canReadTerminations
      ? { name: "Terminations", path: "terminations" }
      : null,
    this.canReadInvestments ? { name: "Bookings", path: "bookings" } : null,
    { name: "New Offers", path: "mutual-funds" },
  ].filter(Boolean);

  render() {
    const {
      match: { path },
    } = this.props;
    const firstRoute = this.navItems.length ? this.navItems[0].path : "";

    return (
      <div className="setup-area">
        <ul className="setup-nav">
          {this.navItems.map((item, index) => (
            <li key={index} className="setup-nav__item">
              <NavLink
                to={{ pathname: `/dashboard/transactions/${item.path}` }}
                activeClassName="setup-nav__item--active"
              >
                {item.name}
              </NavLink>
            </li>
          ))}
        </ul>
        <Switch>
          <Route exact path={path}>
            <Redirect to={{ pathname: `${path}/${firstRoute}` }} />
          </Route>
          <Route exact path={`${path}/deposits`}>
            <Redirect to={{ pathname: `${path}/deposits/card` }} />
          </Route>
          {this.canReadDeposits && (
            <Route path={`${path}/deposits/:status(card|bank|held)`}>
              <Deposits />
            </Route>
          )}
          <Route exact path={`${path}/withdrawals`}>
            <Redirect to={{ pathname: `${path}/withdrawals/pending` }} />
          </Route>
          {this.canReadWithdrawals && (
            <Route
              path={`${path}/withdrawals/:status(pending|confirmed|active|held)`}
            >
              <Withdrawals />
            </Route>
          )}
          <Route exact path={`${path}/reversals`}>
            <Redirect to={{ pathname: `${path}/reversals/deposits` }} />
          </Route>
          {this.canReadReversals && (
            <Route
              path={`${path}/reversals/:type(deposits|investments|terminations|withdrawals)`}
            >
              <Reversals />
            </Route>
          )}
          <Route exact path={`${path}/investments`}>
            <Redirect to={{ pathname: `${path}/investments/recent` }} />
          </Route>
          {this.canReadInvestments && (
            <Route
              path={`${path}/investments/:status(recent|all|updates|nearing-maturity|snappy-investment)`}
            >
              <Investments />
            </Route>
          )}
          {this.canReadInvestments && (
            <Route
              path={`${path}/investments/:investmentsId(\\d+)/transactions`}
            >
              <InvestmentTransactions />
            </Route>
          )}
          {
            <Route exact path={`${path}/terminations`}>
              <Redirect to={{ pathname: `${path}/terminations/recent` }} />
            </Route>
          }
          {this.canReadTerminations && (
            <Route path={`${path}/terminations/:status(recent|all|updates)`}>
              <Terminations />
            </Route>
          )}
          <Route exact path={`${path}/bookings`}>
            <Redirect to={{ pathname: `${path}/bookings/pending` }} />
          </Route>
          {this.canReadInvestments && (
            <Route path={`${path}/bookings/:status(pending|confirmed|active)`}>
              <Bookings />
            </Route>
          )}
          <Route exact path={`${path}/mutual-funds`}>
            <Redirect
              to={{ pathname: `${path}/mutual-funds/private-placement` }}
            />
          </Route>
          <Route
            path={`${path}/mutual-funds/:type(private-placement|public-issue)`}
          >
            <MutualFunds />
          </Route>
          <Route
            path={`${path}/mutual-funds/:id/performance/:status(recent|all)`}
          >
            <MutualFundPerformance />
          </Route>
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    permissions: state.user.permissions,
  };
};

export default withRouter(connect(mapStateToProps, null)(Transactions));
