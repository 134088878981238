import axios from 'axios';
import CONFIG from '@/config';
import * as actions from "./actionTypes";
import { logout, updateUser } from "@/store/user/actions";
import { showAlert } from '@/store/ui/actions';
import { apiRequestHandler } from '@/utils';

const clear = () => {
  return {
    type: actions.CLEAR,
  }
}

const createNewInvestmentRequest = () => {
  return  {
    type: actions.CREATE_INVESTMENT_REQUEST
  }
}

const createNewInvestmentSuccess = (data) => {
  return  {
    type: actions.CREATE_INVESTMENT_SUCCESS,
    data,
  }
}

const createNewInvestmentError = (error) => {
  return  {
    type: actions.CREATE_INVESTMENT_ERROR,
    error,
  }
}

const createNewFSInvestmentRequest = () => {
  return  {
    type: actions.CREATE_INVESTMENT_REQUEST
  }
}

const createNewFSInvestmentSuccess = (data) => {
  return  {
    type: actions.CREATE_INVESTMENT_SUCCESS,
    data,
  }
}

const createNewFSInvestmentError = (error) => {
  return  {
    type: actions.CREATE_INVESTMENT_ERROR,
    error,
  }
}

export const createNewInvestment = (payload) => {
  return (dispatch, getState) => {
    dispatch(createNewInvestmentRequest());

    const { token } = getState().user;

    axios.post(`${CONFIG.BASE_URL}/admin/services`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: token,
      },
    })
    .then(response => {
      dispatch(updateUser({
        token: response.headers.authorization
      }))
      if ([200, 201].includes(response.status)) {
        dispatch(createNewInvestmentSuccess(response.data));
        setTimeout(() => dispatch(clear()), 3000)
        return dispatch(showAlert({ type: 'success', message: response.data.message }))
      }
      return;
    })
    .catch(({ response }) => {
      response && dispatch(updateUser({
        token: response.headers.authorization
      }))
      if (response && [400, 404, 403].includes(response.status)) {
        response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
        return dispatch(createNewInvestmentError(response.data.error ? response.data.error : response.data.message));
      }
      if (response && [401].includes(response.status)) {
        dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
        return setTimeout(() => dispatch(logout()), 2000) 
      }
      if (response && response.status >= 500) {
        return dispatch(createNewInvestmentError('Oops! We did something wrong.'));
      }
      dispatch(createNewInvestmentError('Oops! We did something wrong.'));
    })
  }
}
export const createNewFSInvestment = (payload) => {
  return (dispatch, getState) => {
    dispatch(createNewFSInvestmentRequest());

    const { token } = getState().user;

    axios.post(`${CONFIG.BASE_URL}/admin/services/flash-deal`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: token,
      },
    })
    .then(response => {
      dispatch(updateUser({
        token: response.headers.authorization
      }))
      if ([200, 201].includes(response.status)) {
        dispatch(createNewFSInvestmentSuccess(response.data));
        setTimeout(() => dispatch(clear()), 3000)
        return dispatch(showAlert({ type: 'success', message: response.data.message }))
      }
      return;
    })
    .catch(({ response }) => {
      response && dispatch(updateUser({
        token: response.headers.authorization
      }))
      if (response && [400, 404, 403].includes(response.status)) {
        response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
        return dispatch(createNewFSInvestmentError(response.data.error ? response.data.error : response.data.message));
      }
      if (response && [401].includes(response.status)) {
        dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
        return setTimeout(() => dispatch(logout()), 2000)
      }
      if (response && response.status >= 500) {
        return dispatch(createNewFSInvestmentError('Oops! We did something wrong.'));
      }
      dispatch(createNewFSInvestmentError('Oops! We did something wrong.'));
    })
  }
}

const getAllInvestmentsRequest = () => {
  return  {
    type: actions.FETCH_ALL_INVESTMENTS_REQUEST
  }
}

const getAllInvestmentsSuccess = (data) => {
  return  {
    type: actions.FETCH_ALL_INVESTMENTS_SUCCESS,
    data,
  }
}

const getAllInvestmentsError = (error) => {
  return  {
    type: actions.FETCH_FLASH_DEALS_INVESTMENTS_ERROR,
    error,
  }
}
const getFlashDealsInvestmentsRequest = () => {
  return  {
    type: actions.FETCH_FLASH_DEALS_INVESTMENTS_REQUEST
  }
}

const getFlashDealsInvestmentsSuccess = (data) => {
  return  {
    type: actions.FETCH_FLASH_DEALS_INVESTMENTS_SUCCESS,
    data,
  }
}

const getFlashDealsInvestmentsError = (error) => {
  return  {
    type: actions.FETCH_ALL_INVESTMENTS_ERROR,
    error,
  }
}

// fetching all flash deals investments without status
export const getAllFlashDealsInvestments = (limit=10, page=1, searchTerm) => {
  return (dispatch, getState) => {
    dispatch(getFlashDealsInvestmentsRequest());

    const { token } = getState().user;
    const path = searchTerm ? `/${searchTerm}/search?status=not-pending&limit=${limit}&page=${page}` : `?limit=${limit}&page=${page}`
    axios.get(`${CONFIG.BASE_URL}/admin/services/get/flash-deals/${path}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
        .then(response => {
          dispatch(updateUser({
            token: response.headers.authorization
          }))
          if ([200, 201].includes(response.status)) {
            dispatch(getFlashDealsInvestmentsSuccess(response.data?.data));
          }
        })
        .catch(({ response }) => {
          response && dispatch(updateUser({
            token: response.headers.authorization
          }))
          if (response && [400, 404, 403].includes(response.status)) {
            response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
            return dispatch(getFlashDealsInvestmentsError(response.data.error ? response.data.error : response.data.message));
          }
          if (response && [401].includes(response.status)) {
            dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
            return dispatch(logout())
          }
          if (response && response.status >= 500) {
            return dispatch(getFlashDealsInvestmentsError('Oops! We did something wrong.'));
          }
          dispatch(getFlashDealsInvestmentsError('Oops! We did something wrong.'));
        })
  }
}

// fetching all termed investments without status
export const getAllInvestments = (limit=10, page=1, searchTerm) => {
  return (dispatch, getState) => {
    dispatch(getAllInvestmentsRequest());

    const { token } = getState().user;
    const path = searchTerm ? `/${searchTerm}/search?status=not-pending&limit=${limit}&page=${page}` : `?limit=${limit}&page=${page}`
    axios.get(`${CONFIG.BASE_URL}/admin/services${path}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
    .then(response => {
      dispatch(updateUser({
        token: response.headers.authorization
      }))
      if ([200, 201].includes(response.status)) {
        dispatch(getAllInvestmentsSuccess(response.data?.data));
      }
    })
    .catch(({ response }) => {
      response && dispatch(updateUser({
        token: response.headers.authorization
      }))
      if (response && [400, 404, 403].includes(response.status)) {
        response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
        return dispatch(getAllInvestmentsError(response.data.error ? response.data.error : response.data.message));
      }
      if (response && [401].includes(response.status)) {
        dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
        return dispatch(logout())
      }
      if (response && response.status >= 500) {
        return dispatch(getAllInvestmentsError('Oops! We did something wrong.'));
      }
      dispatch(getAllInvestmentsError('Oops! We did something wrong.'));
    })
  }
}

const getPendingInvestmentsRequest = () => {
  return  {
    type: actions.FETCH_ALL_PENDING_INVESTMENTS_REQUEST
  }
}

const getPendingInvestmentsSuccess = (data) => {
  return  {
    type: actions.FETCH_ALL_PENDING_INVESTMENTS_SUCCESS,
    data,
  }
}

const getPendingInvestmentsError = (error) => {
  return  {
    type: actions.FETCH_ALL_PENDING_INVESTMENTS_ERROR,
    error,
  }
}
const getPendingFDInvestmentsRequest = () => {
  return  {
    type: actions.FETCH_ALL_PENDING_FLASH_DEALS_INVESTMENTS_REQUEST
  }
}

const getPendingFDInvestmentsSuccess = (data) => {
  return  {
    type: actions.FETCH_ALL_PENDING_FLASH_DEALS_INVESTMENTS_SUCCESS,
    data,
  }
}

const getPendingFDInvestmentsError = (error) => {
  return  {
    type: actions.FETCH_ALL_PENDING_FLASH_DEALS_INVESTMENTS_ERROR,
    error,
  }
}

// fetching all PENDING investments without status
export const getPendingInvestments = (limit=10, page=1, searchTerm) => {
  return (dispatch, getState) => {
    dispatch(getPendingInvestmentsRequest());

    const { token } = getState().user;
    const path = searchTerm ? `${searchTerm}/search?status=pending&limit=${limit}&page=${page}` : `status/pending?limit=${limit}&page=${page}`
    axios.get(`${CONFIG.BASE_URL}/admin/services/${path}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
    .then(response => {
      response.headers.authorization && dispatch(updateUser({
        token: response.headers.authorization
      }))
      if ([200, 201].includes(response.status)) {
        dispatch(getPendingInvestmentsSuccess(response.data?.data));
      }
    })
    .catch(({ response }) => {
      response && response.headers.authorization && dispatch(updateUser({
        token: response.headers.authorization
      }))
      if (response && [400, 404, 403].includes(response.status)) {
        response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
        return dispatch(getPendingInvestmentsError(response.data.error ? response.data.error : response.data.message));
      }
      if (response && [401].includes(response.status)) {
        dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
        return dispatch(logout())
      }
      if (response && response.status >= 500) {
        return dispatch(getPendingInvestmentsError('Oops! We did something wrong.'));
      }
      dispatch(getPendingInvestmentsError('Oops! We did something wrong.'));
    })
  }
}
export const getPendingFDInvestments = (limit=10, page=1, searchTerm) => {
  return (dispatch, getState) => {
    dispatch(getPendingFDInvestmentsRequest());

    const { token } = getState().user;
    const path = searchTerm ? `${searchTerm}/search?status=pending&limit=${limit}&page=${page}` : `?limit=${limit}&page=${page}`
    axios.get(`${CONFIG.BASE_URL}/admin/services/get/deals/pending/${path}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
    .then(response => {
      response.headers.authorization && dispatch(updateUser({
        token: response.headers.authorization
      }))
      if ([200, 201].includes(response.status)) {
        dispatch(getPendingFDInvestmentsSuccess(response.data?.data));
      }
    })
    .catch(({ response }) => {
      response && response.headers.authorization && dispatch(updateUser({
        token: response.headers.authorization
      }))
      if (response && [400, 404, 403].includes(response.status)) {
        response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
        return dispatch(getPendingFDInvestmentsError(response.data.error ? response.data.error : response.data.message));
      }
      if (response && [401].includes(response.status)) {
        dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
        return dispatch(logout())
      }
      if (response && response.status >= 500) {
        return dispatch(getPendingFDInvestmentsError('Oops! We did something wrong.'));
      }
      dispatch(getPendingFDInvestmentsError('Oops! We did something wrong.'));
    })
  }
}

const updateInvestmentStatusRequest = () => {
  return  {
    type: actions.UPDATE_INVESTMENT_STATUS_REQUEST
  }
}

const updateInvestmentStatusSuccess = (data) => {
  return  {
    type: actions.UPDATE_INVESTMENT_STATUS_SUCCESS,
    data,
  }
}

const updateInvestmentStatusError = (error) => {
  return  {
    type: actions.UPDATE_INVESTMENT_STATUS_ERROR,
    error,
  }
}
const updateFDInvestmentStatusRequest = () => {
  return  {
    type: actions.UPDATE_FLASH_DEAL_INVESTMENT_STATUS_REQUEST
  }
}

const updateFDInvestmentStatusSuccess = (data) => {
  return  {
    type: actions.UPDATE_FLASH_DEAL_INVESTMENT_STATUS_SUCCESS,
    data,
  }
}

const updateFDInvestmentStatusError = (error) => {
  return  {
    type: actions.UPDATE_FLASH_DEAL_INVESTMENT_STATUS_ERROR,
    error,
  }
}

// update investment status
export const updateInvestmentStatus = (id, payload) => {
  return (dispatch, getState) => {
    dispatch(updateInvestmentStatusRequest());

    const { token } = getState().user;

    axios.put(`${CONFIG.BASE_URL}/admin/services/${id}/status`, payload, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
    .then(response => {
      dispatch(updateUser({
        token: response.headers.authorization
      }))
      dispatch(showAlert({ type: 'success', message: response.data.message }))
      dispatch(updateInvestmentStatusSuccess(response.data));
    })
    .catch(({ response }) => {
      response && response.headers.authorization && dispatch(updateUser({
        token: response.headers.authorization
      }))
      if (response && [400, 404, 403].includes(response.status)) {
        response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
        return dispatch(updateInvestmentStatusError(response.data.error ? response.data.error : response.data.message));
      }
      if (response && [401].includes(response.status)) {
        dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
        return dispatch(logout())
      }
      if (response && response.status >= 500) {
        return dispatch(updateInvestmentStatusError('Oops! We did something wrong.'));
      }
      dispatch(updateInvestmentStatusError('Oops! We did something wrong.'));
    })
  }
}
export const updateFDInvestmentStatus = (id, payload) => {
  return (dispatch, getState) => {
    dispatch(updateFDInvestmentStatusRequest());

    const { token } = getState().user;

    axios.put(`${CONFIG.BASE_URL}/admin/services/${id}/deal`, payload, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
    .then(response => {
      dispatch(updateUser({
        token: response.headers.authorization
      }))
      dispatch(showAlert({ type: 'success', message: response.data.message }))
      dispatch(updateFDInvestmentStatusSuccess(response.data));
    })
    .catch(({ response }) => {
      response && response.headers.authorization && dispatch(updateUser({
        token: response.headers.authorization
      }))
      if (response && [400, 404, 403].includes(response.status)) {
        response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
        return dispatch(updateFDInvestmentStatusError(response.data.error ? response.data.error : response.data.message));
      }
      if (response && [401].includes(response.status)) {
        dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
        return dispatch(logout())
      }
      if (response && response.status >= 500) {
        return dispatch(updateFDInvestmentStatusError('Oops! We did something wrong.'));
      }
      dispatch(updateFDInvestmentStatusError('Oops! We did something wrong.'));
    })
  }
}

const updateMultipleInvestmentStatusRequest = () => {
  return  {
    type: actions.UPDATE_MULTIPLE_INVESTMENT_STATUS_REQUEST
  }
}

const updateMultipleInvestmentStatusSuccess = (ids) => {
  return  {
    type: actions.UPDATE_MULTIPLE_INVESTMENT_STATUS_SUCCESS,
    data: ids,
  }
}

const updateMultipleInvestmentStatusError = (error) => {
  return  {
    type: actions.UPDATE_MULTIPLE_INVESTMENT_STATUS_ERROR,
    error,
  }
}

export const updateMultipleInvestmentStatus = (ids, status) => {
  let payload = {
      status: status === 'approve' ? 'active' : 'rejected',
      requests: ids,
  }
  const config = {
      urlPath: `admin/services/multiple/${status}`,
      method: 'patch',
      payload,
      requestAction: updateMultipleInvestmentStatusRequest,
      successAction: () => updateMultipleInvestmentStatusSuccess(ids),
      errorAction: updateMultipleInvestmentStatusError,
      alertSuccessMessage: `Investment Service ${status === 'approve' ? 'approved' : 'rejected'} successfully`
  }
  return apiRequestHandler(config);
}

const updateInvestmentRequest = () => {
  return  {
    type: actions.UPDATE_INVESTMENT_REQUEST
  }
}

const updateInvestmentSuccess = (data) => {
  return  {
    type: actions.UPDATE_INVESTMENT_SUCCESS,
    data,
  }
}

const updateInvestmentError = (error) => {
  return  {
    type: actions.UPDATE_INVESTMENT_ERROR,
    error,
  }
}

const updateFDInvestmentRequest = () => {
  return  {
    type: actions.UPDATE_FLASH_DEAL_INVESTMENT_REQUEST
  }
}

const updateFDInvestmentSuccess = (data) => {
  return  {
    type: actions.UPDATE_FLASH_DEAL_INVESTMENT_SUCCESS,
    data,
  }
}

const updateFDInvestmentError = (error) => {
  return  {
    type: actions.UPDATE_FLASH_DEAL_INVESTMENT_ERROR,
    error,
  }
}

// update investment data
export const updateInvestment = (id, payload) => {
  return (dispatch, getState) => {
    dispatch(updateInvestmentRequest());

    const { token } = getState().user;

    axios.put(`${CONFIG.BASE_URL}/admin/services/${id}`, payload, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
    .then(response => {
      dispatch(updateUser({
        token: response.headers.authorization
      }))
      if ([200, 201, 204].includes(response.status)) {
        dispatch(updateInvestmentSuccess(response.data));
        return dispatch(showAlert({ type: 'success', message: response.data.message }))
      }
    })
    .catch(({ response }) => {
      response && dispatch(updateUser({
        token: response && response.headers.authorization
      }))
      if (response && [400, 404, 403].includes(response.status)) {
        response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
        return dispatch(updateInvestmentError(response.data.error ? response.data.error : response.data.message));
      }
      if (response && [401].includes(response.status)) {
        dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
        return dispatch(logout())
      }
      if (response && response.status >= 500) {
        return dispatch(updateInvestmentError('Oops! We did something wrong.'));
      }
      dispatch(updateInvestmentError('Oops! We did something wrong.'));
    })
  }
}
export const updateFDInvestment = (payload) => {
  return (dispatch, getState) => {
    dispatch(updateFDInvestmentRequest());

    const { token } = getState().user;

    axios.post(`${CONFIG.BASE_URL}/admin/services/flash-deal`, payload, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
    .then(response => {
      dispatch(updateUser({
        token: response.headers.authorization
      }))
      if ([200, 201, 204].includes(response.status)) {
        dispatch(updateFDInvestmentSuccess(response.data));
        return dispatch(showAlert({ type: 'success', message: response.data.message }))
      }
    })
    .catch(({ response }) => {
      response && dispatch(updateUser({
        token: response && response.headers.authorization
      }))
      if (response && [400, 404, 403].includes(response.status)) {
        response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
        return dispatch(updateFDInvestmentError(response.data.error ? response.data.error : response.data.message));
      }
      if (response && [401].includes(response.status)) {
        dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
        return dispatch(logout())
      }
      if (response && response.status >= 500) {
        return dispatch(updateFDInvestmentError('Oops! We did something wrong.'));
      }
      dispatch(updateFDInvestmentError('Oops! We did something wrong.'));
    })
  }
}

const removeInvestmentRequest = () => {
  return  {
    type: actions.REMOVE_INVESTMENT_REQUEST
  }
}

const removeInvestmentSuccess = (id) => {
  return  {
    type: actions.REMOVE_INVESTMENT_SUCCESS,
    id,
  }
}

const removeInvestmentError = (error) => {
  return  {
    type: actions.REMOVE_INVESTMENT_ERROR,
    error,
  }
}

// remove investment
export const removeInvestment = (id) => {
  return (dispatch, getState) => {
    dispatch(removeInvestmentRequest());

    const { token } = getState().user;

    axios.patch(`${CONFIG.BASE_URL}/admin/services/${id}/reject?limit=40&page=1`, { status: 'rejected' },  {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
    .then(response => {
      dispatch(updateUser({
        token: response.headers.authorization
      }))
        dispatch(removeInvestmentSuccess(id));
        return dispatch(showAlert({ type: 'success', message: response.data.message }))
    })
    .catch(({ response }) => {
      response && dispatch(updateUser({
        token: response && response.headers.authorization
      }))
      if (response && [400, 404, 403].includes(response.status)) {
        response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
        return dispatch(removeInvestmentError(response.data.error ? response.data.error : response.data.message));
      }
      if (response && [401].includes(response.status)) {
        dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
        return dispatch(logout())
      }
      if (response && response.status >= 500) {
        return dispatch(removeInvestmentError('Oops! We did something wrong.'));
      }
      dispatch(removeInvestmentError('Oops! We did something wrong.'));
      dispatch(showAlert({ type: 'error', message: 'Oops! We did something wrong.' }))
    })
  }
}