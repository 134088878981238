import React, { Component } from 'react'
import './style.scss';

class Dropdown extends Component {
  state = {
    showMenu: false,
  }
  dropdown = React.createRef()

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.hide);
  }

  show = (e) => {
    this.setState((state) => ({showMenu: !state.showMenu}));
    document.addEventListener('mousedown', this.hide);
  }

  hide = (e) => {
    if (!this.dropdown.current.contains(e.target)) {
      this.setState({showMenu: false});
    }
    document.removeEventListener('mousedown', this.hide);
  }

  render() {
    const {menu, width, left, arrow = false} = this.props;
    return (menu.length ?
        <div className="cp-dropdown-menu" ref={this.dropdown} onClick={this.show}>
            {this.props.children}
            {this.state.showMenu && <> 
                {arrow && <span className="cp-dropdown-menu__arrow"></span>}
                <ul className="cp-dropdown-menu__box" style={{width: width ?? '', left: left ? 0 : ''}}>
                    {
                      menu.map((menuItem, index) => (<li 
                        className={`cp-dropdown-menu__item ${menuItem.textClass ?? ''}`} 
                        key={index} 
                        onClick={menuItem.handler}
                      >
                        {menuItem.name}
                      </li>))
                    }
                </ul>
            </>}
        </div> : ''
    )
  }
}
export default Dropdown;