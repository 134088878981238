import CONFIG from "@/config";
import * as actions from "./actionTypes";
import { apiRequestHandler, apiDownloadHandler } from "@/utils";

const getUserAnalyticsRequest = () => {
  return {
    type: actions.GET_USER_ANALYTICS_REQUEST,
  };
};

const getUserAnalyticsError = () => {
  return {
    type: actions.GET_USER_ANALYTICS_ERROR,
  };
};

export const fetchDemographics = (type, date) => {
  const dateType = `${type}Date`; // evaluates to 'ageDate' or 'genderDate' if date is defined
  const config = {
    urlPath: `admin/analytics/user/demographics?view=${type}${
      date ? `&${dateType}=${date}` : ""
    }`,
    requestAction: getUserAnalyticsRequest,
    errorAction: getUserAnalyticsError,
  };
  return apiRequestHandler(config);
};

export const fetchInvestmentValueAnalytics = (view, startDate, endDate) => {
  const config = {
    urlPath: `admin/analytics/user/investment-value?view=${view}&${view}StartDate=${startDate}&${view}EndDate=${endDate}`,
    requestAction: getUserAnalyticsRequest,
    errorAction: getUserAnalyticsError,
  };
  return apiRequestHandler(config);
};

export const fetchUserGrowthAnalytics = (startDate, endDate) => {
  const config = {
    urlPath: `admin/analytics/user/user-growth?startDate=${startDate}&endDate=${endDate}`,
    requestAction: getUserAnalyticsRequest,
    errorAction: getUserAnalyticsError,
  };
  return apiRequestHandler(config);
};

export const exportDemographics = (_, format, view, ageDate, genderDate) => {
  const agePath =
    view !== "gender" ? `${ageDate ? `&ageDate=${ageDate}` : ""}` : ``;
  const genderPath =
    view !== "age" ? `${genderDate ? `&genderDate=${genderDate}` : ""}` : ``;
  const fullUrlPath = `${CONFIG.BASE_URL}/admin/analytics/user/demographics/download?type=${format}&view=${view}${agePath}${genderPath}`;
  return apiDownloadHandler(fullUrlPath);
};

export const exportInvestment = (
  _,
  format,
  view,
  ageStartDate,
  ageEndDate,
  genderStartDate,
  genderEndDate
) => {
  const agePath =
    view !== "gender"
      ? `&ageStartDate=${ageStartDate}&ageEndDate=${ageEndDate}`
      : "";
  const genderPath =
    view !== "age"
      ? `&genderStartDate=${genderStartDate}&genderEndDate=${genderEndDate}`
      : "";
  const fullUrlPath = `${CONFIG.BASE_URL}/admin/analytics/user/investment-value/download?view=${view}&type=${format}${agePath}${genderPath}`;
  return apiDownloadHandler(fullUrlPath);
};

export const exportUserGrowth = (_, format, startDate, endDate) => {
  const fullUrlPath = `${CONFIG.BASE_URL}/admin/analytics/user/user-growth/download?startDate=${startDate}&endDate=${endDate}&type=${format}`;
  return apiDownloadHandler(fullUrlPath);
};
