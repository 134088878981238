import React, { Component } from "react";
import { Line } from "react-chartjs-2";
import { currency } from "@/utils";

class LineGraph extends Component {
  data = () => ({
    labels: ["0", ...(this.props.xAxis ?? [])],
    datasets: [
      {
        label: "value",
        fill: false,
        borderColor: "#48B4FF",
        data: [0, ...(this.props.yAxis ?? [])],
      },
    ],
  });

  options = {
    legend: { display: false },
    tooltips: {
      callbacks: {
        title: function (labels) {
          return "";
        },
        label: (labels) => {
          if (this.props.isCurrency) {
            return "₦" + currency(labels.value, true);
          }
          return labels.value;
        },
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: { display: false },
        },
      ],
      yAxes: [
        {
          ticks: {
            callback: (label) => {
              if (this.props.abbreviateY) {
                return label < 1e6 ? label / 1000 + "k" : currency(label, true);
              }
              if (label % 1 === 0) {
                return label;
              }
              // return label.toFixed(1);
            },
          },
        },
      ],
    },
  };

  render() {
    const data = this.data();

    return (
      <div>
        <Line
          redraw={this.props.redraw ?? true}
          data={data}
          options={this.options}
        />
      </div>
    );
  }
}

export default LineGraph;
