module.exports = {
  UPDATE_USER: "user/UPDATE_USER",
  CHANGE_USER_PICTURE_REQUEST: 'user/CHANGE_USER_PICTURE_REQUEST',
  CHANGE_USER_PICTURE_SUCCESS: 'user/CHANGE_USER_PICTURE_SUCCESS',
  CHANGE_USER_PICTURE_ERROR: 'user/CHANGE_USER_PICTURE_ERROR',
  DISABLE_USER_REQUEST: 'user/DISABLE_USER_REQUEST',
  DISABLE_USER_SUCCESS: 'user/DISABLE_USER_SUCCESS',
  DISABLE_USER_ERROR: 'user/DISABLE_USER_ERROR',
  FIND_USER_REQUEST: 'user/FIND_USER_REQUEST',
  FIND_USER_SUCCESS: 'user/FIND_USER_SUCCESS',
  FIND_USER_ERROR: 'user/FIND_USER_ERROR',
}
