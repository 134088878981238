import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, NavLink } from "react-router-dom";
import {
  fetchConfigurations,
  updateConfiguration,
  updateSnappyConfiguration,
  editConfiguration,
  changeConfiguration,
  fetchSnappyConfigurations,
  createConfiguration,
} from "@/store/setup/configurationsAction";
import { clearError } from "@/store/ui/actions";
import actionTypes from "@/store/setup/actionTypes";
import { getActionLoadingState, getActionErrorState } from "@/store/selectors";
import Alert from "@/components/Alert";
import {
  serializeErrors,
  currency,
  hasPermission,
  validateFields,
} from "@/utils";
import InputError from "@/components/InputError";
import RightPanel from "@/components/RightPanel";
import Emptystate from "@/components/Emptystate";
import ExportDropdown from "@/components/ExportDropdown";
import MultipleApprovalDropdown from "@/components/MultipleApprovalDropdown";
import ConfirmationBox from "@/components/ConfirmationBox";
import Pagination from "@/components/Pagination";
import { PencilAltIcon, PencilIcon, PlusIcon } from "@heroicons/react/outline";

class Configurations extends Component {
  state = {
    open: false,
    createRateOpen: false,
    editing: false,
    currentRateData: {},
    pendingConfigs: [],
    investments: [],
    currentValue: "",
    formErrors: {
      minTenor: "",
      maxTenor: "",
      minAmount: "",
      maxAmount: "",
      rate: "",
      penalty: "",
    },
    touched: {
      minTenor: false,
      maxTenor: false,
      minAmount: false,
      maxAmount: false,
      rate: false,
      penalty: false,
    },
    minTenor: "",
    maxTenor: "",
    maxAmount: "",
    penalty: "",
    minAmount: "",
    rate: "",
    confirmationContext: "",
    selectedRowsIds: [],
    searchTerm: "",
  };

  isShowingActiveConfigurations = () => {
    if (this.props.location.pathname.indexOf("pending") === -1) {
      return true;
    }
    return false;
  };

  componentDidMount() {
    this.getConfigurations();
    this.getSnappyConfigurations(1, 10);
  }

  componentDidUpdate(prevProps, prevState) {
    const { status } = this.props.match.params;
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ selectedRows: [] }, () => {
        this.fetchConfigurations();
      });
    } else if (
      this.state.searchTerm !== prevState.searchTerm &&
      this.state.shouldSearch
    ) {
      this.fetchConfigurations();
    }
  }

  fetchConfigurations() {
    this.getConfigurations();
    this.getSnappyConfigurations(1, 10);
  }

  getConfigurations = () => {
    if (this.isShowingActiveConfigurations()) {
      this.props.fetchConfigurations().then((configs) => {
        this.formatConfigurations(configs);
      });
    } else {
      this.props.fetchConfigurations("pending").then((pendingConfigs) => {
        this.setState({ pendingConfigs });
      });
    }
  };

  getSnappyConfigurations = (page, limit, searchTerm) => {
    this.props
      .fetchSnappyConfigurations(page, limit, searchTerm)
      .then((investments) => {
        this.setPageData(investments, searchTerm, false);
      });
  };

  validateField = (name, value) => {
    let error = "";

    switch (name) {
      case "minTenor":
        if (!value.toString().trim()) {
          error = "Tenor Start is required";
        } else if (isNaN(value) || value < 0) {
          error = "Tenor Start must be a positive number";
        }
        break;

      case "maxTenor":
        if (!value.toString().trim()) {
          error = "Tenor End is required";
        } else if (isNaN(value) || value < 0) {
          error = "Tenor End must be a positive number";
        } else if (Number(value) <= Number(this.state.minTenor)) {
          error = "Tenor End must be greater than Tenor Start";
        }
        break;

      case "minAmount":
        if (!value.toString().trim()) {
          error = "Minimum Amount is required";
        } else if (isNaN(value) || value < 0) {
          error = "Minimum Amount must be a positive number";
        }
        break;

      case "maxAmount":
        if (!value.toString().trim()) {
          error = "Maximum Amount is required";
        } else if (isNaN(value) || value < 0) {
          error = "Maximum Amount must be a positive number";
        } else if (Number(value) <= Number(this.state.minAmount)) {
          error = "Maximum Amount must be greater than Minimum Amount";
        }
        break;

      case "rate":
        if (!value.toString().trim()) {
          error = "Rate is required";
        } else if (isNaN(value) || value < 0) {
          error = "Rate must be a positive number";
        }
        break;

      case "penalty":
        if (!value.toString().trim()) {
          error = "Penalty is required";
        } else if (isNaN(value) || value < 0) {
          error = "Penalty must be a positive number";
        }
        break;

      default:
        break;
    }

    return error;
  };

  validateForm = () => {
    const { minTenor, maxTenor, minAmount, maxAmount, rate, penalty, editing } =
      this.state;
    const errors = {
      minTenor: this.validateField("minTenor", minTenor),
      maxTenor: this.validateField("maxTenor", maxTenor),
      minAmount: this.validateField("minAmount", minAmount),
      maxAmount: this.validateField("maxAmount", maxAmount),
      rate: this.validateField("rate", rate),
    };

    if (!editing) {
      errors.penalty = this.validateField("penalty", penalty);
    }

    // Mark all fields as touched
    const touched = {
      minTenor: true,
      maxTenor: true,
      minAmount: true,
      maxAmount: true,
      rate: true,
      penalty: !editing,
    };

    this.setState({ formErrors: errors, touched });

    return !Object.values(errors).some((error) => error);
  };

  setPageData = (
    { lastPage, perPage, page, total, data: investments },
    searchTerm,
    shouldSearch
  ) => {
    this.setState({
      investments,
      lastPage,
      perPage,
      page,
      total,
      searchTerm,
      shouldSearch,
    });
  };

  formatConfigurations = (configs) => {
    const allConfigs = {};
    configs.systemConfig.forEach(
      (config) => (allConfigs[config.name] = config)
    );
    configs.investmentConfig.forEach(
      (config) => (allConfigs[config.name] = config)
    );
    this.setState({ allConfigs });
  };

  getSystemConfigs = () => {
    const {
      allConfigs: {
        vat,
        withdrawalFees,
        minWithdrawal,
        tax,
        exchangeRate,
        tierOneWithdrawalLimit,
        tierTwoWithdrawalLimit,
        tierThreeWithdrawalLimit,
        tierFourWithdrawalLimit,
      },
    } = this.state;
    return [
      {
        field: "VAT",
        faceValue: `${vat.current}%`,
        value: vat.current,
        id: vat.id,
      },
      {
        field: "Withdrawal fees",
        faceValue: `₦${currency(withdrawalFees.current)}`,
        value: withdrawalFees.current,
        id: withdrawalFees.id,
      },
      {
        field: "Minimum withdrawal",
        faceValue: `₦${currency(minWithdrawal.current)}`,
        value: minWithdrawal.current,
        id: minWithdrawal.id,
      },
      // start
      {
        field: "Tier 1 - Minimum withdrawal",
        faceValue: `₦${currency(tierOneWithdrawalLimit?.current)}`,
        value: tierOneWithdrawalLimit?.current,
        id: tierOneWithdrawalLimit?.id,
      },

      {
        field: "Tier 2 - Minimum withdrawal",
        faceValue: `₦${currency(tierTwoWithdrawalLimit?.current)}`,
        value: tierTwoWithdrawalLimit?.current,
        id: tierTwoWithdrawalLimit?.id,
      },

      {
        field: "Tier 3 - Minimum withdrawal",
        faceValue: `₦${currency(tierThreeWithdrawalLimit?.current)}`,
        value: tierThreeWithdrawalLimit?.current,
        id: tierThreeWithdrawalLimit?.id,
      },

      {
        field: "Tier 4 - Minimum withdrawal",
        faceValue: `₦${currency(tierFourWithdrawalLimit?.current)}`,
        value: tierFourWithdrawalLimit?.current,
        id: tierFourWithdrawalLimit?.id,
      },
      // end
      {
        field: "Withholding Tax",
        faceValue: `${tax.current}%`,
        value: tax.current,
        id: tax.id,
      },
      {
        field: "Exchange rate (USD)",
        faceValue: `₦${exchangeRate.current}/$`,
        value: exchangeRate.current,
        id: exchangeRate.id,
      },
    ];
  };

  getPredefinedConfigs = () => {
    const {
      predefinedMinInvestment,
      predefinedInterestRate,
      predefinedLiquidationPenalty,
      predefinedRate_0_90,
      predefinedRate_91_180,
      predefinedRate_181_270,
      predefinedRate_271_365,
    } = this.state.allConfigs;
    return [
      {
        field: "Minimum Investment",
        faceValue: `₦${currency(predefinedMinInvestment.current)}`,
        value: predefinedMinInvestment.current,
        id: predefinedMinInvestment.id,
      },
      {
        field: "Interest rate",
        faceValue: `${predefinedInterestRate.current}%`,
        value: predefinedInterestRate.current,
        id: predefinedInterestRate.id,
      },
      // start predefined rates
      {
        field: "predefinedRate (0 to 90)",
        faceValue: `${predefinedRate_0_90.current}%`,
        value: predefinedRate_0_90.current,
        id: predefinedRate_0_90.id,
      },

      {
        field: "predefinedRate (91 to 180)",
        faceValue: `${predefinedRate_91_180.current}%`,
        value: predefinedRate_91_180.current,
        id: predefinedRate_91_180.id,
      },

      {
        field: "predefinedRate (181 to 270)",
        faceValue: `${predefinedRate_181_270.current}%`,
        value: predefinedRate_181_270.current,
        id: predefinedRate_181_270.id,
      },
      {
        field: "predefinedRate (271 to 365)",
        faceValue: `${predefinedRate_271_365.current}%`,
        value: predefinedRate_271_365.current,
        id: predefinedRate_271_365.id,
      },
      // end new predefines rates
      {
        field: "Penalty for pre-liquidation",
        faceValue: `${predefinedLiquidationPenalty.current}%`,
        value: predefinedLiquidationPenalty.current,
        id: predefinedLiquidationPenalty.id,
      },
    ];
  };

  getCollectionConfigs = () => {
    const {
      collectionMinInvestment,
      collectionInterestRate,
      collectionRate_0_90,
      collectionRate_91_180,
      collectionRate_181_270,
      collectionRate_271_365,
    } = this.state.allConfigs;
    return [
      {
        field: "Minimum Investment",
        faceValue: `₦${currency(collectionMinInvestment.current)}`,
        value: collectionMinInvestment.current,
        id: collectionMinInvestment.id,
      },
      {
        field: "Interest rate",
        faceValue: `${collectionInterestRate.current}%`,
        value: collectionInterestRate.current,
        id: collectionInterestRate.id,
      },

      // start predefined rates
      {
        field: "predefinedRate (0 to 90)",
        faceValue: `${collectionRate_0_90.current}%`,
        value: collectionRate_0_90.current,
        id: collectionRate_0_90.id,
      },

      {
        field: "collectionRate (91 to 180)",
        faceValue: `${collectionRate_91_180.current}%`,
        value: collectionRate_91_180.current,
        id: collectionRate_91_180.id,
      },

      {
        field: "collectionRate (181 to 270)",
        faceValue: `${collectionRate_181_270.current}%`,
        value: collectionRate_181_270.current,
        id: collectionRate_181_270.id,
      },
      {
        field: "collectionRate (271 to 365)",
        faceValue: `${collectionRate_271_365.current}%`,
        value: collectionRate_271_365.current,
        id: collectionRate_271_365.id,
      },
      // end new predefines rates

      {
        field: "Collection rate",
        faceValue: `${collectionInterestRate.current}%`,
        value: collectionInterestRate.current,
        id: collectionInterestRate.id,
      },

      //
    ];
  };

  getKycSettings = () => {
    const {
      firstKycNotication,
      firstKycInvestment,
      secondKycNotication,
      secondKycInvestment,
      thirdKycNotication,
      thirdKycInvestment,
    } = this.state.allConfigs;
    return [
      {
        field: "First stage KYC threshold notification",
        faceValue: firstKycNotication.current ?? "-",
        value: firstKycNotication.current,
        id: firstKycNotication.id,
      },
      {
        field: "First stage KYC threshold investment",
        faceValue: firstKycInvestment.current,
        value: firstKycInvestment.current,
        id: firstKycInvestment.id,
      },
      {
        field: "Second stage KYC threshold notification",
        faceValue: `Investments → ₦${currency(secondKycNotication.current)}`,
        value: secondKycNotication.current,
        id: secondKycNotication.id,
      },
      {
        field: "Second stage KYC threshold investment",
        faceValue: `Investments → ₦${currency(secondKycInvestment.current)}`,
        value: secondKycInvestment.current,
        id: secondKycInvestment.id,
      },
      {
        field: "Third stage KYC threshold notification",
        faceValue: `Investments → ₦${currency(thirdKycNotication.current)}`,
        value: thirdKycNotication.current,
        id: thirdKycNotication.id,
      },
      {
        field: "Third stage KYC threshold investment",
        faceValue: `Investments → ₦${currency(thirdKycInvestment.current)}`,
        value: thirdKycInvestment.current,
        id: thirdKycInvestment.id,
      },
    ];
  };

  renderCurrentConfig = () => {
    const canUpdate = hasPermission(
      this.props.permissions,
      "snappy_configuration"
    );
    return (
      this.state.allConfigs && (
        <div className="config-main">
          <div className="config-main__table">
            <h1 className="config-main__table-heading text-blue-800 font-bold">
              System Configurations
            </h1>
            {this.getSystemConfigs().map((config) => (
              <div className="config-main__entry" key={config.id}>
                <span className="text-small">{config.field}</span>
                <span className="config-main__entry-value font-heading">
                  {config.faceValue}
                </span>
                {canUpdate && (
                  <button
                    className="w-[80px]"
                    onClick={() =>
                      this.onEditClick(config.field, config.id, config.value)
                    }
                  >
                    {/* <img
                      src={require("@/assets/icons/edit.svg").default}
                      alt="edit icon"
                    /> */}
                    <div className="flex justify-center text-lg transition-all px-2 gap-3 border border-gray-400 font-semibold  hover:!border-blue-900 text-blue-900 p-3 rounded-md">
                      {/* <PencilIcon className="size-7" /> */}
                      <span>Update</span>
                    </div>
                  </button>
                )}
              </div>
            ))}
          </div>

          {/* end */}

          <div className="config-main__table">
            <h1 className="config-main__table-heading text-blue-800 font-bold">
              Investment Configurations
            </h1>

            <span className="config-main__group-title text-blue-800 font-bold">
              (predefined investments)
            </span>
            {this.getPredefinedConfigs().map((config) => (
              <div className="config-main__entry" key={config.id}>
                <span className="text-small">{config.field}</span>
                <span className="config-main__entry-value font-heading">
                  {config.faceValue}
                </span>
                {canUpdate && (
                  <button
                    className="w-[80px]"
                    onClick={() =>
                      this.onEditClick(config.field, config.id, config.value)
                    }
                  >
                    <div className="flex justify-center text-lg transition-all px-2 gap-3 border border-gray-400 font-semibold hover:!border-blue-900 text-blue-900 p-3 rounded-md">
                      <span>Update</span>
                    </div>
                  </button>
                )}
              </div>
            ))}

            <span className="config-main__group-title mt text-blue-800 font-bold">
              (Collection investments)
            </span>
            {this.getCollectionConfigs().map((config) => (
              <div className="config-main__entry" key={config.id}>
                <span className="text-small">{config.field}</span>
                <span className="config-main__entry-value font-heading">
                  {config.faceValue}
                </span>
                {canUpdate && (
                  <button
                    className="w-[80px]"
                    onClick={() =>
                      this.onEditClick(config.field, config.id, config.value)
                    }
                  >
                    <div className="flex justify-center text-lg transition-all px-2 gap-3 border border-gray-400 font-semibold hover:!border-blue-900 text-blue-900 p-3 rounded-md">
                      <span>Update</span>
                    </div>
                  </button>
                )}
              </div>
            ))}

            <span className="config-main__group-title mt text-blue-800 font-bold">
              (KYC settings)
            </span>
            {this.getKycSettings().map((config) => (
              <div className="config-main__entry" key={config.id}>
                <span className="text-small">{config.field}</span>
                <span className="config-main__entry-value font-heading pl-2">
                  {config.faceValue}
                </span>
                {canUpdate && (
                  <button
                    className="w-[80px]"
                    onClick={() =>
                      this.onEditClick(config.field, config.id, config.value)
                    }
                  >
                    <div className="flex justify-center text-lg transition-all px-2 gap-3 border border-gray-400 font-semibold hover:!border-blue-900 text-blue-900 p-3 rounded-md">
                      <span>Update</span>
                    </div>
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>
      )
    );
  };

  onCheckRow = (e, rowId) => {
    const selectedRows = [...this.state.selectedRowsIds];
    if (e.target.checked) {
      selectedRows.push(rowId);
    } else {
      const uncheckedRowIndex = selectedRows.findIndex((id) => id === rowId);
      selectedRows.splice(uncheckedRowIndex, 1);
    }
    this.setState({ selectedRowsIds: selectedRows });
  };

  renderSnappyInvestmentsConfig = () => {
    const { investments } = this.state;
    return !investments.length ? (
      <Emptystate
        title="No snappy Rate Configuration"
        icon={require("@/assets/icons/info.svg")}
      />
    ) : (
      <>
        <div className="mb-0">
          {/* <button
            className="setup-inner__button gap-2"
            onClick={() =>
              this.setState({
                createRateOpen: true,
                editing: false,
                currentRateData: {},
                maxTenor: "",
                minTenor: "",
                maxAmount: "",
                minAmount: "",
                rate: "",
                penalty: "",
              })
            }
          >
            <PlusIcon className="size-6" />
            <span>Create Snappy Rate</span>
          </button> */}
        </div>
        <div className="setup-inner__main">
          <table className="custum-table">
            <tbody>
              <tr>
                <th></th>
                <th>Tenor Start</th>
                <th>Tenor End</th>
                <th>Max Amount</th>
                <th>Min Amount</th>
                <th>Rate</th>
                <th className="actions-column">Actions</th>
              </tr>
              {this.state.investments.map((data) => {
                return (
                  <tr key={data.id}>
                    <td className="row-checkbox">
                      <input
                        type="checkbox"
                        onChange={(e) => this.onCheckRow(e, data.id)}
                      />
                    </td>
                    <td>{data.tenor_start}</td>
                    <td>{data.tenor_end}</td>
                    <td className="!font-heading tracking-wider">
                      ₦{currency(data.max_amount)}
                    </td>
                    <td className="!font-heading tracking-wider">
                      ₦{currency(data.min_amount)}
                    </td>
                    <td className="font-heading tracking-wider">
                      {currency(data.rate)}
                    </td>
                    {data.status === "active" ? (
                      <td className="custum-table__button-cell">
                        <div
                          onClick={() =>
                            this.setState({
                              createRateOpen: true,
                              editing: true,
                              currentRateData: data,
                              maxTenor: data.tenor_end,
                              minTenor: data.tenor_start,
                              maxAmount: data.max_amount,
                              minAmount: data.min_amount,
                              rate: data.rate,
                              penalty: data.penalty,
                            })
                          }
                        >
                          {/* <img
                            src={require("@/assets/icons/edit.svg").default}
                            alt="edit icon"
                          /> */}
                          <div className="inline-block cursor-pointer justify-center text-lg transition-all px-4 gap-3 border border-gray-400 font-semibold  hover:!border-blue-900 text-blue-900 p-3 rounded-md">
                            <PencilIcon className="size-7" />
                            {/* <span>Update</span> */}
                          </div>
                        </div>
                      </td>
                    ) : (
                      <td className="custum-table__button-cell">
                        <button
                          className="custum-table__button-blue"
                          onClick={() =>
                            this.setConfirmationContext("activeConfig", data.id)
                          }
                        >
                          Approve
                        </button>
                        <button
                          className="custum-table__button-plain"
                          onClick={() =>
                            this.setConfirmationContext(
                              "pendingConfig",
                              data.id
                            )
                          }
                        >
                          Reject
                        </button>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  renderPendingConfig = () => {
    const { pendingConfigs } = this.state;
    return !pendingConfigs.length ? (
      <Emptystate
        title="No pending configurations"
        icon={require("@/assets/icons/info.svg")}
      />
    ) : (
      <div className="setup-inner__main">
        <table className="custum-table">
          <tbody>
            <tr>
              <th></th>
              <th>Configurations</th>
              <th>Current data</th>
              <th>New data</th>
              <th className="actions-column">Actions</th>
            </tr>
            {pendingConfigs.map((config) => (
              <tr key={config.id}>
                <td className="row-checkbox">
                  <input
                    type="checkbox"
                    onChange={(e) => this.onCheckRow(e, config.id)}
                  />
                </td>
                <td className="text-capitalize">
                  {config.name
                    .replace(/([A-Z]+)/g, " $1")
                    .replace(/([A-Z][a-z])/g, " $1")}
                </td>
                <td>{config.current}</td>
                <td>{config.value}</td>
                <td className="custum-table__button-cell">
                  <button
                    className="custum-table__button-blue"
                    onClick={() =>
                      this.setConfirmationContext("approveConfig", config.id)
                    }
                  >
                    Approve
                  </button>
                  <button
                    className="custum-table__button-plain"
                    onClick={() =>
                      this.setConfirmationContext("rejectConfig", config.id)
                    }
                  >
                    Reject
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  requestApproval = (e) => {
    e.preventDefault();
    const { configId, newValue } = this.state;
    this.props.changeConfiguration(configId, newValue).then(() => {
      this.setState({
        open: false,
        confirmationSuccess: true,
        confirmationContext: "Success",
      });
    });
  };

  renderError = (fieldName) => {
    const { formErrors, touched } = this.state;
    if (touched[fieldName] && formErrors[fieldName]) {
      return (
        <div className="text-red-500 text-sm -mt-5 mb-4">
          {formErrors[fieldName]}
        </div>
      );
    }
    return null;
  };

  createRate = async (e) => {
    e.preventDefault();

    if (!this.validateForm()) {
      return;
    }

    const { createConfiguration, editConfiguration } = this.props;
    const {
      maxTenor,
      minTenor,
      maxAmount,
      minAmount,
      rate,
      penalty,
      editing,
      currentRateData,
    } = this.state;

    const rateData = {
      minTenor: Number(minTenor),
      maxTenor: Number(maxTenor),
      minAmount: Number(minAmount),
      maxAmount: Number(maxAmount),
      rate: Number(rate),
    };

    const payload = editing
      ? rateData
      : { rates: [rateData], penalty: Number(penalty) };

    try {
      let response;
      if (editing) {
        if (currentRateData && currentRateData.id) {
          response = await editConfiguration(payload, currentRateData.id);
        } else {
          throw new Error("No ID provided for edit");
        }
      } else {
        response = await createConfiguration(payload);
      }

      if (response.success) {
        this.setState({
          createRateOpen: false,
          editing: false,
          currentRateData: {},
          // Reset form state
          minTenor: "",
          maxTenor: "",
          minAmount: "",
          maxAmount: "",
          rate: "",
          penalty: "",
          formErrors: {
            minTenor: "",
            maxTenor: "",
            minAmount: "",
            maxAmount: "",
            rate: "",
            penalty: "",
          },
          touched: {
            minTenor: false,
            maxTenor: false,
            minAmount: false,
            maxAmount: false,
            rate: false,
            penalty: false,
          },
        });
      }
    } catch (error) {
      this.setState({ createRateOpen: false });
    }
  };

  removeFromList = (ids) => {
    let pendingConfigs;
    if (Array.isArray(ids)) {
      pendingConfigs = this.state.pendingConfigs.filter(
        (data) => !ids.includes(data.id)
      );
    } else {
      pendingConfigs = [...this.state.pendingConfigs];
      const configIndex = pendingConfigs.findIndex(
        (config) => config.id === ids
      );
      pendingConfigs.splice(configIndex, 1);
    }
    this.setState({
      pendingConfigs,
      selectedRowsIds: [],
      confirmationSuccess: true,
    });
  };

  updateConfig = (status) => {
    const { configId, isMultipleApproval, selectedRowsIds } = this.state;
    const target = isMultipleApproval ? selectedRowsIds : configId;
    this.props.updateConfiguration(target, status).then(() => {
      this.removeFromList(target);
    });
  };

  updateSnappyConfig = (status) => {
    const { configId, isMultipleApproval, selectedRowsIds } = this.state;
    const target = isMultipleApproval ? selectedRowsIds : configId;
    this.props.updateSnappyConfiguration(target, status).then(() => {
      this.removeFromList(target);
    });
  };

  confirmationData = () => {
    const { isMultipleApproval, selectedRowsIds } = this.state;
    const addS = isMultipleApproval && selectedRowsIds.length > 1;

    return {
      approveConfig: {
        title: `Approve configuration${addS ? "s" : ""}`,
        question: `Are you sure you want to approve ${
          addS ? "these" : "this"
        } configuration${addS ? "s" : ""}?`,
        action: () => this.updateConfig("active"),
      },
      rejectConfig: {
        title: `Reject Configuration${addS ? "s" : ""}`,
        question: `Are you sure you want to reject ${
          addS ? "these" : "this"
        } configuration${addS ? "s" : ""}?`,
        action: () => this.updateConfig("reject"),
      },
      activeConfig: {
        title: `Approve configuration${addS ? "s" : ""}`,
        question: `Are you sure you want to approve ${
          addS ? "these" : "this"
        } configuration${addS ? "s" : ""}?`,
        action: () => this.updateSnappyConfig("active"),
      },
      pendingConfig: {
        title: `Reject Configuration${addS ? "s" : ""}`,
        question: `Are you sure you want to reject ${
          addS ? "these" : "this"
        } configuration${addS ? "s" : ""}?`,
        action: () => this.updateSnappyConfig("pending"),
      },
    };
  };

  setConfirmationContext = (context, configId, isMultipleApproval) => {
    this.setState({
      confirmationContext: context,
      confirmationSuccess: false,
      configId,
      isMultipleApproval,
    });
  };

  onEditClick = (name, configId, currentValue) => {
    this.setState({
      name,
      configId,
      currentValue,
      newValue: currentValue,
      open: true,
    });
  };

  onCreateClick = (minTenor, maxTenor, minAmount, maxAmount, rate, penalty) => {
    this.setState({
      maxTenor,
      minTenor,
      minAmount,
      penalty,
      maxAmount,
      rate,
      createRateOpen: true,
    });
  };

  closePanel = (e) => {
    e.preventDefault();
    this.setState({
      open: false,
      name: "",
      currentValue: "",
      configId: "",
    });
    this.props.clearError();
  };

  closeCreateRatePanel = (e) => {
    e.preventDefault();
    this.setState({
      createRateOpen: false,
    });
    this.props.clearError();
  };

  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const {
      match: { path },
      pageRequestError,
      confirmationLoading,
      permissions,
      fetching,
    } = this.props;
    const {
      name,
      currentValue,
      newValue,
      confirmationSuccess,
      confirmationContext,
      minTenor,
      maxTenor,
      minAmount,
      maxAmount,
      penalty,
      rate,
      searchTerm,
      lastPage,
      page,
      perPage,
      investments,
    } = this.state;
    const createErrorObject = serializeErrors(pageRequestError?.message);
    const prompt = this.confirmationData()[confirmationContext];
    const showMultiAprrovalDropdown = this.state.selectedRowsIds.length > 0;

    const showPagination = this.props.location.pathname.includes(
      "/dashboard/setup/configurations/snappy-investments"
    );

    return (
      <div className="setup-inner">
        <Alert alert={pageRequestError} />
        <RightPanel open={this.state.open} onClose={this.closePanel}>
          <h2 className="panel-heading">{`Edit ${name}`}</h2>
          <form
            className="form employer-onboard__form"
            onSubmit={this.requestApproval}
          >
            <label
              className="panel-label"
              htmlFor="current-value"
            >{`Current ${name}`}</label>
            <input
              type="text"
              className="form-control panel-input"
              id="current-value"
              value={currentValue}
              autoComplete="off"
              disabled
            />
            <label
              className="panel-label"
              htmlFor="new-value"
            >{`New ${name}`}</label>
            <input
              type="text"
              className="form-control panel-input"
              id="new-value"
              name="newValue"
              value={newValue}
              onChange={this.onInputChange}
              autoComplete="off"
              required
            />
            <InputError error={createErrorObject.value} />
            <div className="dual-button-box">
              <button
                type="button"
                onClick={this.closePanel}
                className="cp-button-blue"
              >
                Cancel
              </button>
              <button className="cp-button-blue">
                Request Approval
                {confirmationLoading && (
                  <div className="spinner-border spinner-border-white spinner-border-sm ml-2 mb-2"></div>
                )}
              </button>
            </div>
          </form>
        </RightPanel>

        <RightPanel
          open={this.state.createRateOpen}
          onClose={this.closeCreateRatePanel}
        >
          <h2 className="panel-heading">
            {this.state.editing ? "Edit Snappy Rate" : "Create Snappy Rate"}
          </h2>
          <form
            className="form employer-onboard__form"
            onSubmit={this.createRate}
          >
            <label className="panel-label" htmlFor="tenor_start">
              Tenor Start
            </label>
            <input
              type="text"
              className={`form-control panel-input ${
                this.state.touched.minTenor && this.state.formErrors.minTenor
                  ? "error-input"
                  : ""
              }`}
              id="minTenor"
              name="minTenor"
              value={this.state.minTenor}
              onChange={this.onInputChange}
              autoComplete="off"
            />
            {this.renderError("minTenor")}

            <label className="panel-label" htmlFor="tenor_start">
              Tenor End
            </label>
            <input
              type="text"
              className={`form-control panel-input ${
                this.state.touched.maxTenor && this.state.formErrors.maxTenor
                  ? "error-input"
                  : ""
              }`}
              id="maxTenor"
              name="maxTenor"
              value={this.state.maxTenor}
              onChange={this.onInputChange}
              autoComplete="off"
            />
            {this.renderError("maxTenor")}

            <label className="panel-label" htmlFor="minAmount">
              Min Amount
            </label>
            <input
              type="text"
              className={`form-control panel-input ${
                this.state.touched.minAmount && this.state.formErrors.minAmount
                  ? "error-input"
                  : ""
              }`}
              id="minAmount"
              name="minAmount"
              value={this.state.minAmount}
              onChange={this.onInputChange}
              autoComplete="off"
            />
            {this.renderError("minAmount")}

            <label className="panel-label" htmlFor="maxAmount">
              Max Amount
            </label>
            <input
              type="text"
              className={`form-control panel-input ${
                this.state.touched.maxAmount && this.state.formErrors.maxAmount
                  ? "error-input"
                  : ""
              }`}
              id="maxAmount"
              name="maxAmount"
              value={this.state.maxAmount}
              onChange={this.onInputChange}
              autoComplete="off"
            />
            {this.renderError("maxAmount")}

            <label className="panel-label" htmlFor="rate">
              Rate
            </label>
            <input
              type="text"
              className={`form-control panel-input ${
                this.state.touched.rate && this.state.formErrors.rate
                  ? "error-input"
                  : ""
              }`}
              id="rate"
              name="rate"
              value={this.state.rate}
              onChange={this.onInputChange}
              autoComplete="off"
            />
            {this.renderError("rate")}

            {this.state.editing === false ? (
              <div>
                <label className="panel-label" htmlFor="penalty">
                  Penalty
                </label>
                <input
                  type="text"
                  className={`form-control panel-input ${
                    this.state.touched.penalty && this.state.formErrors.penalty
                      ? "error-input"
                      : ""
                  } `}
                  id="penalty"
                  name="penalty"
                  value={this.state.penalty}
                  onChange={this.onInputChange}
                  autoComplete="off"
                />
                {this.renderError("penalty")}
              </div>
            ) : (
              ""
            )}
            <InputError error={createErrorObject.value} />
            <div className="dual-button-box">
              <button
                type="button"
                onClick={this.closeCreateRatePanel}
                className="cp-button-blue"
              >
                Cancel
              </button>
              <button className="cp-button-blue">
                {this.state.editing ? "Save Changes" : "Request Approval"}
                {confirmationLoading && (
                  <div className="spinner-border spinner-border-white spinner-border-sm ml-2 mb-2"></div>
                )}
              </button>
            </div>
          </form>
        </RightPanel>

        <div className="setup-inner__top">
          <ConfirmationBox
            open={confirmationContext}
            closeHandler={() => this.setState({ confirmationContext: "" })}
            success={confirmationSuccess}
            title={prompt?.title}
            question={prompt?.question}
            action={prompt?.action}
            loading={confirmationLoading}
            requiresReason={prompt?.requiresReason}
          />
          <ul className="setup-inner__nav">
            <li className="setup-nav__item">
              <NavLink
                exact
                to={{ pathname: `${path}`, state: { pageTitle: "Setup" } }}
                activeClassName="setup-nav__item--active"
              >
                Current Settings
              </NavLink>
            </li>
            {hasPermission(permissions, "approve_configuration") && (
              <li className="setup-nav__item">
                <NavLink
                  to={{
                    pathname: `${path}/pending`,
                    state: { pageTitle: "Setup" },
                  }}
                  activeClassName="setup-nav__item--active"
                >
                  Pending Approval
                </NavLink>
              </li>
            )}

            <li className="setup-nav__item">
              <NavLink
                to={{
                  pathname: `${path}/snappy-investments`,
                  state: { pageTitle: "Setup" },
                }}
                activeClassName="setup-nav__item--active"
              >
                Snappy Rate
              </NavLink>
            </li>
          </ul>
          <ExportDropdown
            module="configurations"
            status={
              this.isShowingActiveConfigurations()
                ? "approved"
                : "pending-approval"
            }
          />{" "}
          <button
            className="setup-inner__button gap-2"
            onClick={() =>
              this.setState({
                createRateOpen: true,
                editing: false,
                currentRateData: {},
                maxTenor: "",
                minTenor: "",
                maxAmount: "",
                minAmount: "",
                rate: "",
                penalty: "",
              })
            }
          >
            <PlusIcon className="size-6" />
            <span>Create Snappy Rate</span>
          </button>
          {showMultiAprrovalDropdown && (
            <MultipleApprovalDropdown
              approve={() =>
                this.setConfirmationContext("approveConfig", null, true)
              }
              reject={() =>
                this.setConfirmationContext("rejectConfig", null, true)
              }
            />
          )}
        </div>
        {fetching ? (
          <div className="text-center text-primary">
            <div className="spinner-border" role="status"></div>
          </div>
        ) : (
          <>
            {showPagination && (
              <Pagination
                totalPages={lastPage}
                page={page}
                limit={perPage}
                changePageHandler={(page, limit) =>
                  this.getSnappyConfigurations(page, limit, searchTerm)
                }
              />
            )}
            <Switch>
              <Route
                exact
                path={path}
                render={() => this.renderCurrentConfig()}
              />
              <Route
                exact
                path={`${path}/pending`}
                render={() => this.renderPendingConfig()}
              />
              <Route
                exact
                path={`${path}/snappy-investments`}
                render={() => this.renderSnappyInvestmentsConfig()}
              />
            </Switch>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fetching: getActionLoadingState(state, actionTypes.GET_CONFIG_REQUEST),
    confirmationLoading: getActionLoadingState(
      state,
      actionTypes.CONFIG_PAGE_REQUEST
    ),
    pageRequestError: getActionErrorState(state, actionTypes.CONFIG_PAGE_ERROR),
    permissions: state.user.permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchConfigurations: (status = "") => dispatch(fetchConfigurations(status)),

    fetchSnappyConfigurations: (page, limit, searchTerm) =>
      dispatch(fetchSnappyConfigurations(page, limit, searchTerm)),
    changeConfiguration: (configId, value) =>
      dispatch(changeConfiguration(configId, value)),
    createConfiguration: (payload) => dispatch(createConfiguration(payload)),
    editConfiguration: (payload, id) =>
      dispatch(editConfiguration(payload, id)),
    updateConfiguration: (configId, status) =>
      dispatch(updateConfiguration(configId, status)),
    updateSnappyConfiguration: (configId, status) =>
      dispatch(updateSnappyConfiguration(configId, status)),
    clearError: () => dispatch(clearError()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Configurations)
);
