module.exports = {
    GET_REPORTS_REQUEST: "reports/GET_REPORTS_REQUEST",
    GET_REPORTS_SUCCESS: "reports/GET_REPORTS_SUCCESS",
    GET_REPORTS_ERROR: "reports/GET_REPORTS_ERROR",
    GET_CTR_REPORTS_REQUEST: "reports/GET_CTR_REPORTS_REQUEST",
    GET_CTR_REPORTS_SUCCESS: "reports/GET_CTR_REPORTS_SUCCESS",
    GET_CTR_REPORTS_ERROR: "reports/GET_CTR_REPORTS_ERROR",
    DOWNLOAD_USER_REPORTS_REQUEST: "reports/DOWNLOAD_USER_REPORTS_REQUEST",
    DOWNLOAD_USER_REPORTS_SUCCESS: "reports/DOWNLOAD_USER_REPORTS_SUCCESS",
    DOWNLOAD_USER_REPORTS_ERROR: "reports/DOWNLOAD_USER_REPORTS_ERROR",
    DOWNLOAD_CTR_REPORTS_REQUEST: "reports/DOWNLOAD_CTR_REPORTS_REQUEST",
    DOWNLOAD_CTR_REPORTS_SUCCESS: "reports/DOWNLOAD_CTR_REPORTS_SUCCESS",
    DOWNLOAD_CTR_REPORTS_ERROR: "reports/DOWNLOAD_CTR_REPORTS_ERROR",
}
