import React from 'react';
import { withRouter } from "react-router-dom";
import InvestmentPortfolio from '@/components/InvestmentPortfolio';
import './style.scss';
import Card from '@/components/Card';

class TermedInvestments extends React.Component {
  render() {
    const { investments } = this.props
    return (
      <div className="portfolio-termed-investment">
        <div className="row">
          {
            investments && investments.length > 0 ? investments.map(investment => (
              <div className="col-md-6" key={investment.id}>
                <Card classes="card mb-5">
                  <InvestmentPortfolio item={investment} />
                </Card>
              </div>
            ))
            :
            <div className="text-center w-100">
              <p className="font-light text-grey mt-3">No investments available</p>
            </div>
          }
        </div>
      </div>
    )
  }
}

export default withRouter(TermedInvestments);
