import axios from 'axios';
import CONFIG from '@/config';
import * as actions from "./actionTypes";
import { logout, updateUser } from "@/store/user/actions";
import { showAlert } from '@/store/ui/actions';
import { validate } from "@/utils";
import { apiRequestHandler, apiDownloadHandler } from '@/utils';

const getBookingsRequest = () => {
    return {
        type: actions.GET_BOOKINGS_REQUEST,
    }
}

const getBookingsError = () => {
    return {
        type: actions.GET_BOOKINGS_ERROR,
    }
}

const getBookingsSuccess = () => {
    return {
        type: actions.GET_BOOKINGS_SUCCESS,
    }
}

const bookingsPageRequest = () => {
    return {
        type: actions.BOOKINGS_PAGE_REQUEST,
    }
}

const bookingsPageError = (message) => {
    return {
        type: actions.BOOKINGS_PAGE_ERROR,
        message,
    }
}

const bookingsPageSuccess = () => {
    return {
        type: actions.BOOKINGS_PAGE_SUCCESS,
    }
}

export const fetchBookings = (page, limit, status) => {
    const config = {
        urlPath: `admin/investments/bookings/${status}?page=${page}&limit=${limit}`,
        requestAction: getBookingsRequest,
        successAction: getBookingsSuccess,
        errorAction: getBookingsError,
    }
    return apiRequestHandler(config);
}

export const searchBookings = (page, limit, status, searchTerm) => {
    const config = {
        urlPath: `admin/investments/bookings/${status}/search?searchWord=${searchTerm}&page=${page}&limit=${limit}`,
        requestAction: getBookingsRequest,
        successAction: getBookingsSuccess,
        errorAction: getBookingsError,
    }
    return apiRequestHandler(config);
}

export const updateBookings = (id, newStatus, reason) => {
    const allStatus = {
        confirm: 'confirmed',
        approve: 'active',
        reject: 'rejected',
    }
    const payload = {
        status: allStatus[newStatus],
    }
    if(reason) payload.message = reason;
    return (dispatch, getState) => {
        dispatch(bookingsPageRequest());
        return new Promise((resolve) => {
            axios.patch(`${CONFIG.BASE_URL}/admin/investments/bookings/${id}/${newStatus}`, payload, {
                headers: {
                    "Authorization": getState().user.token,
                },
            })
            .then(response => {
                dispatch(updateUser({
                    token: response.headers.authorization
                }))
                if (response.status === 200) {
                    dispatch(bookingsPageSuccess());
                    resolve();
                }
            })
            .catch(({ response }) => {
                dispatch(bookingsPageError());
                dispatch(updateUser({
                    token: response?.headers.authorization
                }))
                if([400, 404].includes(response?.status)) {
                    response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
                }
                if(response?.status === 403) {
                    return dispatch(showAlert({ type: 'error', message: 'Oops! you are unauthorised' }))
                }
                if (response?.status === 401) {
                    dispatch(showAlert({ type: 'error', message: 'Your session has expired' }));
                    return setTimeout(() => dispatch(logout()), 2000);
                }
                dispatch(showAlert({ type: 'error', message: 'Oops! something went wrong please try again.' }))
            })
        })
    }
}

export const updateMultipleBookings = (ids, status, message) => {
    const statusObject = {
        approve: 'active',
        reject: 'rejected',
        confirm: 'confirmed',
    }
    const payload = {
        status: statusObject[status],
        requests: ids,
        message,
    }
    const config = {
        urlPath: `admin/investments/bookings/multiple/${status}`,
        payload,
        method: 'patch',
        requestAction: bookingsPageRequest,
        successAction: bookingsPageSuccess,
        errorAction: bookingsPageError,
    }
    return apiRequestHandler(config);
}

export const makeBooking = (data) => {
    return (dispatch, getState) => {
        const required = ['serviceId', 'title', 'targetAmount', 'startDate', 'frequency', 'user_id',]
        dispatch(validate(data, required, showAlert))
        .then(() => {
            dispatch(bookingsPageRequest());
            axios.post(`${CONFIG.BASE_URL}/admin/investments/services/${data.serviceId}/${data.type}/bookings`, data, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": getState().user.token,
                },
            })
            .then(response => {
                dispatch(updateUser({
                    token: response.headers.authorization
                }))
                if(response.status === 201) {
                    dispatch(bookingsPageSuccess());
                    dispatch(showAlert({ type: 'success', message: 'Successful' }))
                    window.location.reload();
                }
            })
            .catch(({ response }) => {
                dispatch(bookingsPageError({message: response?.data.error}));
                response && dispatch(updateUser({
                    token: response.headers.authorization
                }))
                if(response?.status === 401) {
                    dispatch(showAlert({ type: 'error', message: 'Your session has expired' }));
                    return setTimeout(() => dispatch(logout()), 2000) ;
                }
                if(response?.status === 403) {
                    return dispatch(showAlert({ type: 'error', message: 'Oops! you are unauthorised' }));
                }
                if(response?.status === 400) {
                    return dispatch(showAlert({ type: 'error', message: response.data.message }));
                }
                return dispatch(showAlert({ type: 'error', message: 'Oops! something wrong please try again.' }));
            })
        });
    }
}

export const exportBookings = (_, format, status, page, limit) => {
    const fullUrlPath = `${CONFIG.BASE_URL}/admin/investments/bookings/${status}/download/${format}?page=${page}&limit=${limit}`;
    return apiDownloadHandler(fullUrlPath);
}
