module.exports = {
    GET_USER_ANALYTICS_REQUEST: "user/GET_USER_ANALYTICS_REQUEST",
    GET_USER_ANALYTICS_ERROR: "user/GET_USER_ANALYTICS_ERROR",
    GET_SEGMENT_ANALYTICS_REQUEST: "segment/GET_SEGMENT_ANALYTICS_REQUEST",
    GET_SEGMENT_ANALYTICS_ERROR: "segment/GET_SEGMENT_ANALYTICS_ERROR",
    GET_TRANSACTION_ANALYTICS_REQUEST:"transaction/GET_TRANSACTION_ANALYTICS_REQUEST",
    GET_TRANSACTION_ANALYTICS_ERROR:"transaction/GET_TRANSACTION_ANALYTICS_ERROR",
    GET_TERMEDINVESTMENTS_REQUEST:"termedinvestment/GET_TERMEDINVESTMENTS_REQUEST",
    GET_TERMEDINVESTMENTS_ERROR:"termedinvestment/GET_TERMEDINVESTMENTS_ERROR"
}
