import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, NavLink, Switch, Route, Redirect } from "react-router-dom";
import dayjs from "dayjs";
import {
  exportMaturitiesAnalytics,
  exportInvestments,
} from "@/store/analytics/termedInvestments";
import Maturities from "./Maturities";
import ValueOfInvestment from "./ValueOfInvestment";
import ExportDropdown from "@/components/ExportDropdown";
import AnalyticsDateInput from "@/components/AnalyticsDateInput";
import MaturitiesAnalyticsDate from "@/components/MaturitiesAnalyticsDate";
import ReloadButton from "@/components/ReloadButton";
import "./style.scss";

class TermedInvestments extends Component {
  state = {
    date: new Date(),
    maturityStartDate: new Date(),
    maturityEndDate: "",
    viewToExport: "default",
    investmentView: "default",
    status: "active",
    tStatus: "predefined",
    invStatus: "predefined",
    avgCatStatus: "predefined",
    tenorStatus: "active",
    invDate: new Date(),
  };

  maturitiesExportOptions = [
    { name: "All Pages", value: "default" },
    {
      name: "Maturities Due & Aggregate Investment Value",
      value: "maturity-due-and-valuation",
    },
    { name: "maturity Due By Month", value: "maturity-due-by-month" },
  ];

  investmentExportOptions = [
    {
      name: "All Pages",
      value: "default",
    },
    { name: "No of investment", value: "no-of-investment" },
    {
      name: "Value of Investment Target by Type",
      value: "value-of-investment-target-by-type",
    },
    {
      name: "Value of Investment by Type",
      value: "value-of-investment-by-type",
    },
    {
      name: "Average Success Rate of Investment by Category",
      value: "average-success-rate-of-investment-by-category",
    },
    {
      name: "Average Tenor of Investment by Category",
      value: "average-tenor-of-investment-by-category",
    },
  ];
  handleMaturitiesStartDate = (date) => {
    this.setState({
      maturityStartDate: date,
    });
  };
  handleMaturitiesEndDate = (date) => {
    this.setState({
      maturityEndDate: date,
    });
  };
  handleMaturitiesDate = (date) => {
    this.setState({
      date: date,
    });
  };
  handleInvestmentDate = (date) => {
    this.setState({
      invDate: date,
    });
  };
  selectedInvestmentValue = (selectedValue) => {
    this.setState({ status: selectedValue });
  };
  selectedValue = (selectedValue) => {
    this.setState({ tStatus: selectedValue });
  };
  selectedInvTypeValue = (selectedValue) => {
    this.setState({ invStatus: selectedValue });
  };
  selectedAvgValue = (selectedValue) => {
    this.setState({ avgCatStatus: selectedValue });
  };
  selectedTenorValue = (selectedValue) => {
    this.setState({ tenorStatus: selectedValue });
  };

  render() {
    const {
      match: { path },
    } = this.props;
    const { pathname } = this.props.location;
    const {
      date,
      viewToExport,
      status,
      invDate,
      tStatus,
      invStatus,
      avgCatStatus,
      tenorStatus,
      investmentView,
      maturityDate,
      maturityStartDate,
      maturityEndDate,
    } = this.state;
    const isMaturities = pathname.includes("maturities");
    const isInvestments = pathname.includes("value-of-investment");
    const EndDate = maturityEndDate
      ? dayjs(maturityEndDate).format("YYYY-MM-DD")
      : "";
    const startDate = maturityStartDate
      ? dayjs(maturityStartDate).format("YYYY-MM-DD")
      : "";

    return (
      <div className="setup-inner">
        <div className="setup-inner__top">
          <ul className="setup-inner__nav">
            <li className="setup-nav__item">
              <NavLink
                to={{ pathname: `${path}/value-of-investment` }}
                activeClassName="setup-nav__item--active"
              >
                Value Of Investments
              </NavLink>
            </li>
            <li className="setup-nav__item">
              <NavLink
                to={{ pathname: `${path}/maturities` }}
                activeClassName="setup-nav__item--active"
              >
                Maturities
              </NavLink>
            </li>
          </ul>

          {isMaturities && (
            <div className="right-menu-analytics">
              <MaturitiesAnalyticsDate
                value={maturityStartDate}
                handleChange={(date) => this.handleMaturitiesStartDate(date)}
                minDate={new Date()}
              />
              <div className="analytics-date-to">To</div>
              <MaturitiesAnalyticsDate
                value={maturityEndDate}
                handleChange={(date) => this.handleMaturitiesEndDate(date)}
                minDate={maturityStartDate}
              />
              <div className="termed-reload">
                <ReloadButton />
              </div>
              <ExportDropdown
                module="termed-investments"
                status="maturities"
                excludeCSV
                includeJpeg
                downloadHandler={(...args) =>
                  this.props.exportMaturitiesAnalytics(
                    EndDate,
                    dayjs(date).format("YYYY-MM-DD"),
                    startDate,

                    viewToExport,
                    ...args
                  )
                }
                analyticsOptions={this.maturitiesExportOptions}
                onAnalyticsViewSelect={(view) =>
                  this.setState({ viewToExport: view })
                }
              />
            </div>
          )}
          {isInvestments && (
            <div className="right-menu">
              <AnalyticsDateInput
                value={invDate}
                handleChange={(date) => this.handleInvestmentDate(date)}
              />
              <div className="termed-reload">
                <ReloadButton />
              </div>
              <ExportDropdown
                module="termed-investments"
                status="value-of-investments"
                excludeCSV
                includeJpeg
                downloadHandler={(...args) => {
                  return this.props.exportInvestments(
                    status,
                    tStatus,
                    invStatus,
                    avgCatStatus,
                    tenorStatus,
                    dayjs(invDate).format("YYYY-MM-DD"),
                    investmentView,
                    ...args
                  );
                }}
                analyticsOptions={this.investmentExportOptions}
                onAnalyticsViewSelect={(view) =>
                  this.setState({ investmentView: view })
                }
              />
            </div>
          )}
        </div>
        <div>
          <Switch>
            <Route exact path={path}>
              <Redirect to={{ pathname: `${path}/value-of-investment` }} />
            </Route>
            <Route exact path={`${path}/maturities`}>
              <Maturities
                maturityStartDate={maturityStartDate}
                maturityEndDate={maturityEndDate}
                date={date}
                handleChange={(date) => this.handleMaturitiesDate(date)}
              />
            </Route>
            <Route exact path={`${path}/value-of-investment`}>
              <ValueOfInvestment
                date={invDate}
                selectedInvestmentValue={this.selectedInvestmentValue}
                selectedValue={this.selectedValue}
                selectedInvTypeValue={this.selectedInvTypeValue}
                selectedAvgValue={this.selectedAvgValue}
                selectedTenorValue={this.selectedTenorValue}
                status={status}
                tStatus={tStatus}
                invStatus={invStatus}
                avgCatStatus={avgCatStatus}
                tenorStatus={tenorStatus}
              />
            </Route>
          </Switch>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    exportMaturitiesAnalytics: (
      viewToExport,
      module,
      format,
      date,
      startDate,
      endDate
    ) =>
      dispatch(
        exportMaturitiesAnalytics(
          startDate,
          endDate,
          date,
          module,
          format,
          viewToExport
        )
      ),
    exportInvestments: (
      status,
      tStatus,
      invStatus,
      avgCatStatus,
      tenorStatus,
      date,
      investmentView,
      module,
      format
    ) =>
      dispatch(
        exportInvestments(
          module,
          format,
          investmentView,
          date,
          status,
          tStatus,
          invStatus,
          avgCatStatus,
          tenorStatus
        )
      ),
  };
};

export default withRouter(connect(null, mapDispatchToProps)(TermedInvestments));
