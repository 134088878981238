import React, { Component } from "react";
import Modal from "@/components/cpModal";
import Dropdown from "@/components/DropdownMenu";
import "./style.scss";

class AnalyticsExportPromt extends Component {
  state = {
    value: "default",
  };

  close = () => {
    this.props.closeHandler();
  };

  selectedValue = (selectedValue) => {
    this.setState({ value: selectedValue });
    this.props.onSelectOption(selectedValue);
  };

  render() {
    const { open, options, handler } = this.props;
    const { value } = this.state;
    const exportOptions = options?.map((option) => ({
      name: option.name,
      handler: () => this.selectedValue(option.value),
    }));

    return (
      <Modal open={open && options} outsideClose close={this.close}>
        <div className="confirmation-modal analytics-prompt">
          <h2 className="confirmation-modal__heading">Choose page to export</h2>

          <Dropdown menu={exportOptions || []} width="406px" left>
            <div className="analytics-prompt__dropdown">
              <span className="text-capitalize">
                {value === "default" ? "All pages" : value.replaceAll("-", " ")}
              </span>
              <img
                src={require("@/assets/icons/dropdown-caret.svg").default}
                alt="arrow down"
                className="ml-auto"
              />
            </div>
          </Dropdown>

          <div className="pt-5 pb-5"></div>
          <div className="dual-button-box mt-5">
            <button
              onClick={this.props.closeHandler}
              className="cp-button-blue"
            >
              Cancel
            </button>
            <button className="cp-button-blue" onClick={() => handler(value)}>
              Export
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default AnalyticsExportPromt;
