import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { fetchRoles, fetchPermissions } from "@/store/setup/rolesAction";
import { clearError } from "@/store/ui/actions";
import actionTypes from "@/store/setup/actionTypes";
import { getActionLoadingState, getActionErrorState } from "@/store/selectors";
import { serializeErrors, hasPermission } from "@/utils";
import InputError from "@/components/InputError";
import { ReactComponent as BackArrrow } from "@/assets/icons/previous-page-arrow.svg";

import "./Role.scss";

class SingleRole extends Component {
  defaultState = {
    permissionGroup: "Setup",
  };

  state = {
    ...this.defaultState,
    pendingRoles: [],
    allPermissions: [],
    searchTerm: "",
    roles: [],
  };
  reload = () => {
    setTimeout(() => {
      window.location.reload();
    }, 5000);
  };
  onApproveClickRoute = () => {
    this.props.onApproveClick();
  };
  onRejectClickRoute = () => {
    this.props.onRejectClick();
  };
  modules = [
    {
      name: "Setup",
      value: "setup",
    },
    { name: "Client Mgt.", value: "Client Mgt." },
    {
      name: "Transaction",
      value: "transaaction",
    },
    {
      name: "Download",
      value: "download",
    },
    {
      name: "Report",
      value: "report",
    },
    {
      name: "Analytics",
      value: "analytics",
    },
  ];

  goBack = () => {
    this.props.goPrevious();
  };
  render() {
    const {
      onEditClick,

      onInputChange,
      createOrEdit,
      name,
      description,
      addedPermissions,
      previewMode,
      allPermissions,
      createErrorObject,
      removePermission,
      addPermission,
    } = this.props;
    let { permissionGroup } = this.state;

    const canUpdate = hasPermission(this.props.permissions, "update_role");
    const isPending = this.props.location.pathname.includes("pending");
    const checkPermission = (id) => {
      let checkedPermission = false;
      let permissions;
      permissions = addedPermissions?.map((record) => {
        if (record.id === id) {
          checkedPermission = true;
        }

        return checkedPermission;
      });
      return permissions[permissions.length - 1];
    };

    const renderPermissions = (module) => {
      const newPermission =
        allPermissions &&
        allPermissions?.filter(
          (p) => p.module?.toLowerCase() === module?.toLowerCase()
        );
      return (
        <div className="permissions_container">
          {newPermission?.map((permission) => (
            <div className="permissions_item" key={permission.id}>
              <input
                className="permissions_box"
                disabled={previewMode ? true : false}
                name="role"
                type="checkbox"
                defaultChecked={checkPermission(permission.id) || ""}
                onChange={(e) =>
                  e.target.checked
                    ? addPermission(permission)
                    : removePermission(permission.id)
                }
              />
              <label htmlFor="role">{permission.name}</label>
            </div>
          ))}
        </div>
      );
    };

    return (
      <div className="preview-role">
        <form className="form employer-onboard__form" onSubmit={createOrEdit}>
          <div className="d-flex align-items-center">
            <BackArrrow
              className="cp-pagination__arrow--active"
              onClick={() => this.goBack()}
            />
            <span
              className="font-md mx-3 font-weight-bold cp-pagination__arrow--active"
              onClick={() => this.goBack()}
            >
              {previewMode ? (
                <h2 className="mt-2 admin-name">{name}</h2>
              ) : (
                "Back"
              )}
            </span>
            {canUpdate && !previewMode ? (
              <button
                className="setup-inner__button px-4 ml-auto"
                type="submit"
              >
                Save Role
              </button>
            ) : this.props.location.pathname.includes("pending") &&
              previewMode ? (
              <div className="role-box">
                <button
                  type="button"
                  className="first-button"
                  onClick={() => this.onApproveClickRoute()}
                >
                  Approve
                </button>
                <button
                  type="button"
                  onClick={() => this.onRejectClickRoute()}
                  className="second-button"
                >
                  Reject
                </button>
              </div>
            ) : null}
            {previewMode && !isPending && (
              <button
                className="setup-inner__button px-4 ml-auto"
                type="button"
                onClick={onEditClick}
              >
                Edit Role
              </button>
            )}
          </div>
          <div className="preview-input">
            <div className="preview-name">
              <label className="panel-label" htmlFor="role-name">
                Role Name
              </label>
              <input
                type="text"
                name="name"
                value={name}
                onChange={onInputChange}
                className={`first-input  ${
                  createErrorObject.name ? "error-input" : ""
                }`}
                placeholder="Admin Initiator"
                id="role-name"
                autoComplete="off"
                disabled={previewMode ? true : false}
              />
              <InputError error={createErrorObject.description} />
            </div>
            <div>
              <label className="panel-label" htmlFor="role-description">
                Description
              </label>
              <input
                id="role-description"
                name="description"
                value={description}
                onChange={onInputChange}
                placeholder="Manages admin users and system configurations"
                className={` second-input${
                  createErrorObject.description ? "error-input" : ""
                }`}
                cols="30"
                rows="5"
                disabled={previewMode ? true : false}
              ></input>
              <InputError error={createErrorObject.description} />
            </div>
          </div>

          <div className="permissions-container">
            <div className="left-setup" key={module.name}>
              {this.modules.map((module) => (
                <div
                  onClick={() =>
                    this.setState({ permissionGroup: module.name })
                  }
                  className={
                    this.state.permissionGroup === module.name
                      ? "setup-item_active"
                      : "setup-item"
                  }
                  key={module.name}
                >
                  {module.name}
                </div>
              ))}
            </div>
            <div className="right-setup">
              {permissionGroup === "Setup" && renderPermissions("Setup")}
              {permissionGroup === "Client Mgt." &&
                renderPermissions("Client Mgt.")}
              {permissionGroup === "Transaction" &&
                renderPermissions("Transaction")}
              {permissionGroup === "Download" && renderPermissions("Download")}
              {permissionGroup === "Report" && renderPermissions("Report")}
              {permissionGroup === "Analytics" &&
                renderPermissions("Analytics")}
              <InputError error={createErrorObject.description} />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pageRequestError: getActionErrorState(state, actionTypes.ROLE_PAGE_ERROR),
    confirmationLoading: getActionLoadingState(
      state,
      actionTypes.ROLE_PAGE_REQUEST
    ),
    permissions: state.user.permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchRoles: (page, limit, status, searchTerm) =>
      dispatch(fetchRoles(page, limit, status, searchTerm)),

    fetchPermissions: () => dispatch(fetchPermissions()),

    clearError: () => dispatch(clearError()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SingleRole)
);
