import * as actions from "./actionTypes";

const supportDefaultState = {
  data: null,
  error: false,
  loading: false,
  tickets: [],
  metadata: null,
  ticket: null,
  message: null,
  guides: null,
  faq: null,
  userGuides: null,
  pendingGuides: null,
  pendingCategories: null,
}

export default (state = supportDefaultState, action) => {
  switch (action.type) {
    case actions.CLEAR_DATA:
      return { ...state, data: null };
    case actions.CLEAR_ERROR:
      return { ...state, error: false };
    case actions.GET_TICKETS_SUCCESS:
      return { ...state, loading: false, error: false, tickets: action.data?.data, metadata: action.data }
    case actions.GET_TICKETS_ERROR:
      return { ...state, loading: false, error: action.error };
    case actions.SEARCH_TICKETS_SUCCESS:
      return { ...state, loading: false, error: false, tickets: action.data?.tickets?.data, data: action.data }
    case actions.SEARCH_TICKETS_ERROR:
      return { ...state, loading: false, error: action.error };
    case actions.GET_SINGLE_TICKET_SUCCESS:
      return { ...state, loading: false, error: false, ticket: action.data?.ticket }
    case actions.GET_SINGLE_TICKET_ERROR:
      return { ...state, loading: false, error: action.error };
    case actions.SEND_MESSAGE_SUCCESS:
      return { ...state, loading: false, error: false, message: action.data }
    case actions.SEND_MESSAGE_ERROR:
      return { ...state, loading: false, error: action.error };
    case actions.UPDATE_TICKET_STATUS_SUCCESS:
      return { ...state, loading: false, error: false, data: action.data }
    case actions.UPDATE_TICKET_STATUS_ERROR:
      return { ...state, loading: false, error: action.error };
    case actions.GET_USER_GUIDES_SUCCESS:
      return { ...state, loading: false, error: false, userGuides: action.data, data: action.data }
    case actions.GET_USER_GUIDES_ERROR:
      return { ...state, loading: false, error: action.error };
    case actions.ADD_GUIDE_CATEGORY_SUCCESS:
      return { ...state, loading: false, error: false, data: action.data }
    case actions.ADD_GUIDE_CATEGORY_ERROR:
      return { ...state, loading: false, error: action.error };
    case actions.ADD_USER_GUIDES_SUCCESS:
      return { ...state, loading: false, error: false, data: action.data }
    case actions.ADD_USER_GUIDES_ERROR:
      return { ...state, loading: false, error: action.error };
    case actions.GET_USER_GUIDE_BY_STATUS_SUCCESS:
      return { ...state, loading: false, error: false, pendingGuides: action.data }
    case actions.GET_USER_GUIDE_BY_STATUS_ERROR:
      return { ...state, loading: false, error: action.error };
    case actions.GET_GUIDE_CATEGORY_BY_STATUS_SUCCESS:
      return { ...state, loading: false, error: false, pendingCategories: action.data }
    case actions.GET_GUIDE_CATEGORY_BY_STATUS_ERROR:
      return { ...state, loading: false, error: action.error };
    case actions.UPDATE_GUIDE_CATEGORY_STATUS_SUCCESS:
      return { ...state, loading: false, error: false, data: action.data }
    case actions.UPDATE_GUIDE_CATEGORY_STATUS_ERROR:
      return { ...state, loading: false, error: action.error };
    case actions.UPDATE_USER_GUIDES_SUCCESS:
      return { ...state, loading: false, error: false, data: action.data }
    case actions.UPDATE_USER_GUIDES_ERROR:
      return { ...state, loading: false, error: action.error };
    case actions.DELETE_USER_GUIDES_SUCCESS:
      return { ...state, loading: false, error: false, data: action.data }
    case actions.DELETE_USER_GUIDES_ERROR:
      return { ...state, loading: false, error: action.error };
    case actions.UPDATE_GUIDE_CATEGORY_SUCCESS:
      return { ...state, loading: false, error: false, data: action.data }
    case actions.UPDATE_GUIDE_CATEGORY_ERROR:
      return { ...state, loading: false, error: action.error };
    case actions.UPDATE_GUIDE_STATUS_SUCCESS:
      return { ...state, loading: false, error: false, data: action.data }
    case actions.UPDATE_GUIDE_STATUS_ERROR:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
}