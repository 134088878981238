import axios from "axios";
import CONFIG from "@/config";
import * as actions from "./actionTypes";
import { logout, updateUser } from "@/store/user/actions";
import { showAlert } from "@/store/ui/actions";
import { validate, apiRequestHandler } from "@/utils";

const segmentsPageRequest = () => {
  return {
    type: actions.SEGMENTS_PAGE_REQUEST,
  };
};

const segmentsPageError = (message) => {
  return {
    type: actions.SEGMENTS_PAGE_ERROR,
    message,
  };
};

const segmentsPageSuccess = () => {
  return {
    type: actions.SEGMENTS_PAGE_SUCCESS,
  };
};

const getSegmentRequest = () => {
  return {
    type: actions.GET_SEGMENT_REQUEST,
  };
};

const getSegmentError = () => {
  return {
    type: actions.GET_SEGMENT_ERROR,
  };
};

const getSegmentSuccess = () => {
  return {
    type: actions.GET_SEGMENT_SUCCESS,
  };
};

export const fetchSegments = (page, limit, status, searchTerm) => {
  let path = `${
    status === "active" ? "" : "/status/pending"
  }?page=${page}&limit=${limit}`;
  if (searchTerm)
    path = `/${searchTerm}/search?status=${
      status === "active" ? "not-pending" : "pending"
    }&page=${page}&limit=${limit}`;
  return (dispatch, getState) => {
    dispatch(getSegmentRequest());
    return new Promise((resolve) => {
      axios
        .get(`${CONFIG.BASE_URL}/admin/segments${path}`, {
          headers: {
            Authorization: getState().user.token,
          },
        })
        .then((response) => {
          dispatch(
            updateUser({
              token: response.headers.authorization,
            })
          );
          if (response.status === 200) {
            dispatch(getSegmentSuccess());
            resolve(response.data.data?.segments);
          }
        })
        .catch(({ response }) => {
          dispatch(getSegmentError());
          response &&
            dispatch(
              updateUser({
                token: response.headers.authorization,
              })
            );
          if (response?.status === 401) {
            dispatch(
              showAlert({ type: "error", message: "Your session has expired" })
            );
            return setTimeout(() => dispatch(logout()), 2000);
          }
          if (response?.status === 403) {
            return dispatch(
              showAlert({
                type: "error",
                message: "Oops! you are unauthorised",
              })
            );
          }
          dispatch(
            showAlert({
              type: "error",
              message: "Oops! something went wrong please try again.",
            })
          );
        });
    });
  };
};

export const changeSegmentStatus = (segmentId, newStatus) => {
  const isMultipleApproval = Array.isArray(segmentId);
  const status = newStatus === "enable" ? "active" : "disabled";
  const path = isMultipleApproval
    ? `admin/segments/multiple/status`
    : `admin/segments/${segmentId}/status`;
  const payload = { status };
  if (isMultipleApproval) {
    payload.requests = segmentId;
  }
  const config = {
    urlPath: path,
    method: isMultipleApproval ? "put" : "patch",
    payload,
    requestAction: segmentsPageRequest,
    successAction: segmentsPageSuccess,
    errorAction: segmentsPageError,
  };
  return apiRequestHandler(config);
};

export const deleteSegment = (segmentId) => {
  const config = {
    urlPath: `admin/segments/${segmentId}`,
    method: "delete",
    requestAction: segmentsPageRequest,
    successAction: segmentsPageSuccess,
    errorAction: segmentsPageError,
  };
  return apiRequestHandler(config);
};

export const createOrEditSegment = (data, segmentId) => {
  const path = segmentId ? `admin/segments/${segmentId}` : "admin/segments";
  return (dispatch, getState) => {
    const required = [
      "name",
      "description",
      "minAge",
      "maxAge",
      "gender",
      "services",
    ];
    if (!segmentId) required.push("icon");
    return new Promise((resolve) => {
      //validate data
      dispatch(validate(data, required, showAlert)).then(() => {
        data.services = JSON.stringify(data.services);
        dispatch(segmentsPageRequest());
        const formData = new FormData();
        for (const field in data) {
          formData.append(`${field}`, data[field]);
        }
        fetch(`${CONFIG.BASE_URL}/${path}`, {
          method: `${segmentId ? "PUT" : "POST"}`,
          headers: {
            Authorization: getState().user.token,
          },
          body: formData,
        })
          .then((response) => {
            if ([200, 201].includes(response.status)) {
              response.json().then(() => {
                dispatch(segmentsPageSuccess());
                resolve();
              });
            }
            if (response.status === 401) {
              dispatch(
                showAlert({
                  type: "error",
                  message: "Your session has expired",
                })
              );
              return setTimeout(() => dispatch(logout()), 2000);
            }
            if (response.status === 403) {
              dispatch(segmentsPageError());
              dispatch(
                showAlert({
                  type: "error",
                  message: "Oops! you are unauthorised",
                })
              );
            }
            if (response.status === 400) {
              response.json().then((response) => {
                dispatch(
                  segmentsPageError({ type: "danger", message: response.error })
                );
                dispatch(
                  showAlert({ type: "error", message: response.message })
                );
              });
            }
          })
          .catch(() => {
            dispatch(segmentsPageError());
            dispatch(
              showAlert({
                type: "error",
                message: "Oops! something wrong please try again.",
              })
            );
          });
      });
    });
  };
};

export const rejectSegment = (id) => {
  const path = Array.isArray(id)
    ? `admin/segments/multiple/reject`
    : `admin/segments/${id}/reject`;
  const payload = { requests: id };
  const config = {
    urlPath: path,
    method: "patch",
    payload,
    requestAction: segmentsPageRequest,
    successAction: segmentsPageSuccess,
    errorAction: segmentsPageError,
  };
  return apiRequestHandler(config);
};
