import CONFIG from "@/config";
import * as actions from "./actionTypes";
import { apiRequestHandler, apiDownloadHandler } from "@/utils";

const getTermedInvestmentRequest = () => {
  return {
    type: actions.GET_TERMEDINVESTMENTS_REQUEST,
  };
};

const getTermedInvestmentError = () => {
  return {
    type: actions.GET_TERMEDINVESTMENTS_ERROR,
  };
};

export const fetchMaturitiesAnalytics = (view, date, startDate, endDate) => {
  const config = {
    urlPath: `admin/analytics/investment/maturity?date=${date}&startDate=${startDate}&endDate=${endDate}&view=${view}`,
    requestAction: getTermedInvestmentRequest,
    errorAction: getTermedInvestmentError,
  };
  return apiRequestHandler(config);
};

export const fetchInvestments = (view, date, status) => {
  let queryParam = "";
  if (view === "no-of-investment") {
    queryParam = `noOfInvestmentOrderStatus=${status}&view=${view}`;
  }
  if (view === "value-of-investment-target-by-type") {
    queryParam = `valueOfInvestmentTargetByTypeServiceType=${status}&view=${view}`;
  }
  if (view === "value-of-investment-by-type") {
    queryParam = `valueOfInvestmentByTypeServiceType=${status}&view=${view}`;
  }
  if (view === "average-success-rate-of-investment-by-category") {
    queryParam = `avgSuccessRateOfInvestmentByCategoryServiceType=${status}&view=${view}`;
  }
  if (view === "average-tenor-of-investment-by-category") {
    queryParam = `avgTenorOfInvestmentByCategoryOrderStatus=${status}&view=${view}`;
  }

  const config = {
    urlPath: `admin/analytics/investment/value-of-investment?date=${date}&${queryParam}`,
    requestAction: getTermedInvestmentRequest,
    errorAction: getTermedInvestmentError,
  };
  return apiRequestHandler(config);
};

export const exportMaturitiesAnalytics = (
  _,
  format,
  view,
  date,
  startDate,
  endDate
) => {
  const fullUrlPath = `${CONFIG.BASE_URL}/admin/analytics/investment/maturity/download?date=${date}&startDate=${startDate}&endDate=${endDate}&view=${view}&type=${format}`;
  return apiDownloadHandler(fullUrlPath);
};

export const exportInvestments = (
  _,
  format,
  view,
  date,
  status,
  tStatus,
  invStatus,
  avgCatStatus,
  tenorStatus
) => {
  const path1 =
    view === "no-of-investment" ? `&noOfInvestmentOrderStatus=${status}` : "";
  const path2 =
    view === "value-of-investment-target-by-type"
      ? `&valueOfInvestmentTargetByTypeServiceType=${tStatus}`
      : "";
  const path3 =
    view === "value-of-investment-by-type"
      ? `&valueOfInvestmentByTypeServiceType=${invStatus}`
      : "";
  const path4 =
    view === "average-success-rate-of-investment-by-category"
      ? `&avgSuccessRateOfInvestmentByCategoryServiceType=${avgCatStatus}`
      : "";
  const path5 =
    view === "average-tenor-of-investment-by-category"
      ? `&avgTenorOfInvestmentByCategoryOrderStatus=${tenorStatus}`
      : "";
  const path6 =
    view === "default"
      ? `&noOfInvestmentOrderStatus=${status}&valueOfInvestmentTargetByTypeServiceType=${tStatus}&valueOfInvestmentByTypeServiceType=${invStatus}&avgSuccessRateOfInvestmentByCategoryServiceType=${avgCatStatus}&avgTenorOfInvestmentByCategoryOrderStatus=${tenorStatus}`
      : "";

  const fullUrlPath = `${CONFIG.BASE_URL}/admin/analytics/investment/value-of-investment/download?date=${date}${path1}${path2}${path3}${path4}${path5}${path6}&view=${view}&type=${format} `;

  return apiDownloadHandler(fullUrlPath);
};
