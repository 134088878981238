import React, { Component } from "react";
import "./style.scss";
import { InformationCircleIcon } from "@heroicons/react/outline";

class EmptyState extends Component {
  render() {
    const { icon, title, isSmall } = this.props;
    return (
      <div className="empty-state">
        {/* <img src={icon} alt="icon" className="empty-state__icon"/> */}
        <InformationCircleIcon className="size-14 stroke-1 text-blue-900" />
        <h4
          className={` !font-primary !font-light text-gray-500 empty-state__title ${
            isSmall && "empty-state__title-small"
          }`}
        >
          {title}
        </h4>
        <p
          className={`empty-state__info ${
            isSmall && "empty-state__info-small"
          } !font-primary`}
        >
          {this.props.children}
        </p>
      </div>
    );
  }
}

export default EmptyState;
